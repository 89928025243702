import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { HOME_ROUTE } from '@common/constants/routes.constants';

@Injectable({
  providedIn: 'root'
})
export class WebMobileGuard implements CanActivate {

  constructor(private router: Router) { }

  public canActivate(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = (/iPhone|Android/i.test(userAgent));
    if (isMobile) {
      return true;
    } else {
      this.router.navigateByUrl(HOME_ROUTE);
    }
    return isMobile;
  }
}
