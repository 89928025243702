import * as forge from 'node-forge';
import { Injectable } from '@angular/core';
import { FirebaseService } from '@services/firebase/firebase.service';
import * as CryptoJS from 'crypto-js';
type encryptionFlow = 'ga4';
@Injectable()
export class AnalyticsUtilsService {
  private encryptionConfig: any;
  private paddedEncryptionKey: any;
  constructor(
    private firebase: FirebaseService,
  ) { }
  public encryptData (payload: string) {
    if(payload) {
      const hash = CryptoJS.SHA256(payload).toString();
      return hash;
    } else {
      return 0;
    }
    
  }
  public getEncryptionConfig(): any {
    let encriptionConfig: Promise<any> = Promise.resolve(this.encryptionConfig);
    if (!this.encryptionConfig) {
      encriptionConfig = this.firebase.getConfig('encryptionConfig').then((data) => {
        this.encryptionConfig = data;
        return Promise.resolve(data);
      }).catch(() => {
        this.encryptionConfig = {};
        return Promise.resolve({});
      });
    }
    return encriptionConfig;
  }
  public async setPaddedEncryptionKey() {
    if (!this.paddedEncryptionKey) {
      //const data = await this.getEncryptionConfig();
      this.paddedEncryptionKey = forge.pkcs5.pbkdf2(
        "ripley-pe-user_id",
        "ripley-pe-user_id",
        65536,
        32,
        forge.md.sha256.create()
      );
    }
  }
  public async encryptData2(payload: string): Promise<string | false> {
    await this.setPaddedEncryptionKey();
    const decipher = forge.cipher.createCipher(
      'AES-ECB',
      this.paddedEncryptionKey
    );
    decipher.start();
    decipher.update(forge.util.createBuffer(payload));
    if (decipher.finish()) {
      return Promise.resolve(Buffer.from(decipher.output.data, 'binary').toString('base64'));
    }
    return Promise.reject(false);
  }
  public async decryptData(payload64: string): Promise<string | false> {
    await this.setPaddedEncryptionKey();
    const payload = forge.util.decode64(payload64);
    const decipher = forge.cipher.createDecipher(
      'AES-ECB',
      this.paddedEncryptionKey
    );
    decipher.start();
    decipher.update(forge.util.createBuffer(payload));
    if (decipher.finish()) {
      return Promise.resolve(Buffer.from(decipher.output.data, 'binary').toString('utf8'));
    }
    return Promise.reject(false);
  }
}