import { Component, Input, OnInit } from '@angular/core';
import { MOBILE_BREAKPOINT } from '@common/constants/misc.constants';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {

  public showCompleteText: boolean;
  @Input() displayedText?: string;
  @Input() hiddenText?: string;
  @Input() hasHideText?: boolean = false;

  constructor() { }

  ngOnInit() {
    if (window.innerWidth > MOBILE_BREAKPOINT) {
      this.showCompleteText = true; // Muestra el texto completo en desktop
    }
  }

  toggleCompleteText(): void {
    this.showCompleteText = !this.showCompleteText;
  }
}
