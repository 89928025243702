import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appNumericOnly]'
})
export class NumericOnlyDirective {
    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event']) onInput(event: Event) {
        const input = event.target as HTMLInputElement;
        const value = input.value;
        const formattedValue = value.replace(/\D/g, '');
        input.value = formattedValue;
    }
}
