import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { LEAD_EXECUTION_CODE, PRODUCT_NAMES } from '@common/constants/offerings.constants';
import { ILeadModalBody, ILoanLead } from '@common/interfaces/loan-lead.interface';
import { LoanLeadService } from '@services/loan-lead/loan-lead.service';
import { ModalController } from '@ionic/angular';
import { LEAD_EXIST_ERROR } from '@common/constants/efex.constants';
import { LeadExistModalComponent } from '../lead-exist-modal/lead-exist-modal.component';
import { CREDITS_ROUTE } from '@common/constants/routes.constants';

@Component({
  selector: 'app-lead-generic-modal',
  templateUrl: './lead-generic-modal.component.html',
  styleUrls: ['./lead-generic-modal.component.scss'],
})
export class LeadGenericModalComponent implements OnInit {

  @Input() leadModalBody: ILeadModalBody;
  @Input() loanLeadData: ILoanLead;
  isLoading: boolean;

  constructor(private modalCtrl: ModalController,
              private router: Router,
              private loanLeadService: LoanLeadService) {
    this.isLoading = false;
  }

  ngOnInit() {}

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  async saveLead() {
    this.isLoading = true;
    const executionCode = sessionStorage.getItem(LEAD_EXECUTION_CODE);
    if (executionCode !== null && executionCode !== undefined) {
      try {
        await this.loanLeadService.registerLoanLead(this.loanLeadData);
        let loan = await this.loanLeadService.registerLoanLead(this.loanLeadData);
        this.isLoading = false;
        this.modalCtrl.dismiss();
        if (loan.code === LEAD_EXIST_ERROR) {
          this.showExistLeadModal();
        }
        if (this.leadModalBody.routeToRedirect) {
          this.router.navigate([this.leadModalBody.routeToRedirect, { replaceUrl: true}]);
        }
      } catch (err) {
        this.isLoading = false;
        this.modalCtrl.dismiss();
      }
    }
  }

  async showExistLeadModal() {
    const props = {
      productName: PRODUCT_NAMES[this.loanLeadData.codeProduct].prettyName
    };

    const modal = await this.modalCtrl.create({
      component: LeadExistModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();

    modal.onDidDismiss().then(() => {
      return  this.router.navigate([CREDITS_ROUTE, { replaceUrl: true}]);
    });
  }



}
