export const CHECK_ENROLLMENT_MOCK = {
  success: [],
  failures: [],
};

export const ENROLL_USER_MOCK = {
  success: [],
  failures: [],
};

export const ENROLLMENT_TERMS_AND_CONDITIONS = {
  success: [],
  failures: [],
};

export const ENROLLMENT_DATA_PROTECTION = {
  success: [],
  failures: [],
};
