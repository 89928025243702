import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CardsWelcomepackPage } from './cards-welcomepack';
import { CommonModule } from '@angular/common';
import { WaveModule } from '@components/wave/wave.module';

@NgModule({
  declarations: [
    CardsWelcomepackPage,
  ],
  imports: [
    WaveModule,
    CommonModule,
  ],
  exports: [
    CardsWelcomepackPage,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CardsWelcomepackPageModule {}
