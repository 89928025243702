import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ON_OF_ROUTE } from '@common/constants/routes.constants';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-on-off-modal',
  templateUrl: './on-off-modal-cancel.component.html',
  styleUrls: ['./on-off-modal-cancel.component.scss'],
})
export class OnOffModalCancelComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() time: number;
  @Input() toHome: boolean;
  @Input() toProductList: boolean;

  constructor(private viewController: ModalController,
              private router: Router,) { }

  ngOnInit() {
  }

  public dismissModal() {
   
    this.dismissModal();
     
  }
  public goToHome(){
    if(this.toHome){
      this.viewController.dismiss();
      return this.router.navigateByUrl(`/${ON_OF_ROUTE}`);
    }else{
      this.viewController.dismiss();
    }  
  }

}
