import { Injectable } from '@angular/core';
import { HttpService } from '@services/http/http.service';
import { environment } from '@environments/environment';
import { IDetailsSavingAccount, IMovementSavingAccount, IPdfUrlSavings } from '@common/interfaces/savings.interface';
import {
  DETAILS_SAVINGS_MOCK,
  MOVEMENTS_SAVINGS_MOCK,
  SAVINGS_PDF_URL_MOCK,
  DETAILS_CTS_MOCK,
  DETAILS_CTS_SAVINGS_MOCK,
  MOVEMENTS_CTS_MOCK
} from '@common/mocks/savings.mock';
import { SAVINGS_ACCOUNTS_TYPES } from '@common/constants/saving-benefits';
import { HttpHeaders } from '@angular/common/http';
import { UtilsService } from '@services/utils/utils';
import { NavigationCheckerService } from '@services/navigation/navigation-checker.service';
import { shareReplay } from 'rxjs/operators';
import { SAVINGS_TYPE_MAPPING } from '@common/constants/open-account.constants';

@Injectable({
  providedIn: 'root'
})
export class SavingsService {

  detailSaving: Promise<IDetailsSavingAccount[]>;
  detailPassiveCts: Promise<IDetailsSavingAccount[]>;

  constructor(
    private http: HttpService,
    private navCheckerSrv: NavigationCheckerService
  ) { }

  private setIdentifierHeader(): HttpHeaders {
    let headers = {} as HttpHeaders;
    const identifier = UtilsService.getIdentifier();
    return headers = new HttpHeaders().set('Identifier', identifier.toString());
  }

  public getDetailPassivesSaving(forceRequest?: boolean): Promise<IDetailsSavingAccount[]> {
    if(this.navCheckerSrv.InHome() && this.detailSaving && !forceRequest) {
      return this.detailSaving;
    }

    const headers = this.setIdentifierHeader();
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/detail/saving';
    const request = this.http.get(url, DETAILS_SAVINGS_MOCK, headers).pipe(shareReplay(1)).toPromise();

    this.detailSaving = request;
    return this.detailSaving;

  }

  public getDetailPassivesCts(forceRequest?: boolean): Promise<IDetailsSavingAccount[]> {

    if(this.navCheckerSrv.InHome() && this.detailPassiveCts && !forceRequest) {
      return this.detailPassiveCts;
    }

    const headers = this.setIdentifierHeader();
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/detail/cts';
    const request = this.http.get(url, DETAILS_CTS_MOCK, headers).pipe(shareReplay(1)).toPromise();
    this.detailPassiveCts = request;
    return this.detailPassiveCts;

  }

  public getDetailPassivesCtsSaving(): Promise<IDetailsSavingAccount[]> {
    const headers = this.setIdentifierHeader();
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/detail/ctssaving';
    return this.http.get(url, DETAILS_CTS_SAVINGS_MOCK, headers).toPromise();
  }

  public getMovementsSaving(account: string, codeCurrency: string): Promise<IMovementSavingAccount[]> {
    const headers = this.setIdentifierHeader();
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/movements/saving';
    const data = {
      account,
      codeCurrency
    };
    return this.http.post(url, data, MOVEMENTS_SAVINGS_MOCK, headers).toPromise();
  }

  public getMovementsCts(account: string, codeCurrency: string): Promise<IMovementSavingAccount[]> {
    const headers = this.setIdentifierHeader();
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/movements/cts';
    const data = {
      account,
      codeCurrency
    };
    return this.http.post(url, data, MOVEMENTS_CTS_MOCK, headers).toPromise();
  }

  getTypeCode(mapping, key, defaultValue) {
    return mapping[key] || defaultValue;
  }

  public getRatesURL(productName: string): Promise<IPdfUrlSavings> {
    const code = this.getTypeCode(SAVINGS_TYPE_MAPPING, productName, productName);
    if (!code) {
      return Promise.reject('Invalid product');
    }
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/' + code;
    return this.http.get(url, SAVINGS_PDF_URL_MOCK).toPromise();
  }
}
