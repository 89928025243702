import { IComponentProp } from '@common/interfaces/modal.interface';

export const ONBOARDING_ELEMENTS = [
  {
    title: 'Consulta tu tarjeta Ripley',
    icon: 'img-credit-card-details',
    message: 'Visualiza el disponible de tu tarjeta y revisa tu estado de cuenta'
  },
  {
    title: 'Transfiere a otros bancos desde tu Banca por Internet',
    icon: 'img-transfers',
    message: 'Ahora puedes transferir a cuentas de otros bancos.'
  },
  {
    title: 'Visualiza tus RipleyPuntos Go',
    icon: 'i-ripleypoints-star',
    message: 'Consulta tus puntos acumulados y conoce tus beneficios'
  },
  {
    title: 'Disfruta las promociones que tenemos para ti',
    icon: 'i-promotions-highlight',
    message: 'Revisa todas las promociones \n y beneficios de usar tu Tarjeta Ripley'
  },
] as IComponentProp[];

export const ONBOARDING_STORAGE_KEY = 'onboarding';

