import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {

  @Input()
  set amountOfSteps(amount: number) {
    this.steps = Array.from(Array(amount).keys());
  }
  @Input() currentStep: number;
  steps: number[];

  constructor() { }

}
