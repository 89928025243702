import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PromotionsWelcomepackPage } from './promotions-welcomepack';
import { CommonModule } from '@angular/common';
import { WaveModule } from '@components/wave/wave.module';

@NgModule({
  declarations: [
    PromotionsWelcomepackPage,
  ],
  imports: [
    WaveModule,
    CommonModule,
  ],
  exports: [
    PromotionsWelcomepackPage,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PromotionsWelcomepackPageModule {}
