import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  IFinancialInstitution
} from '@common/interfaces/transfers.interface';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { TransferService } from '@services/transfer/transfer.service';
import { TransferErrorModalComponent } from '@components/transfer-error-modal/transfer-error-modal.component';
import { IDetailsSavingAccount } from '@common/interfaces/savings.interface';
import { ISegmentSelector } from '@common/interfaces/default.interface';
import { CCI_ACCOUNT_NUMBER_MAX_LENGTH, CCI_ACCOUNT_NUMBER_MIN_LENGTH, CTS_CODE, NUMBER_PATTERN_OTHER_BANK, PEN_BASE_CURRENCY, PEN_SEGMENT, USD_BASE_CURRENCY } from '@common/constants/transfers.constants';
import { UtilsService } from '@services/utils/utils';


@Component({
  selector: 'app-transfer-modal-options',
  templateUrl: './transfer-modal-options.component.html',
  styleUrls: ['./transfer-modal-options.component.scss'],
})
export class TransferModalOptionsComponent implements OnInit, AfterViewInit {
  utils = UtilsService;
  accountNumberLength: number = 20;
  selectedAmountAccount: number;
  isValidAccount: boolean; 
  isKeyEnterDown: boolean;
  isDeferredTransfer: boolean;
  isCTSOriginAccount: boolean;
  isDataChanged: boolean;
  isTransfering: boolean;
  notCTS: boolean;
  financialInstitutionCode: string;
  financialInstitution: IFinancialInstitution;
  accountForm: FormGroup;
  errorModal: HTMLIonModalElement; 
  originAccountInit: IDetailsSavingAccount;
  destinationAccountDesInit: string;
  patternOriginAccount: RegExp;
  @Input() originAccountSelected:IDetailsSavingAccount;
  @Input() originAccounts:IDetailsSavingAccount[];
  @Input() destinationAccountDes: string; 
 
 
  constructor(
    private modalController: ModalController,
    private transferService: TransferService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.isValidAccount=true;
    this.isTransfering = false;
    this.patternOriginAccount = NUMBER_PATTERN_OTHER_BANK;
    }

  async ngOnInit() {  
    this.createAccountForm(); 
  }
 
  async ngAfterViewInit() {
    console.log("transfer option", this.originAccountSelected);
    this.originAccountInit = this.originAccountSelected;  
    this.destinationAccountDesInit = this.destinationAccountDes;
  }
  get accountLengthError(): boolean {   
    return this.destinationAccount.dirty &&
      (this.destinationAccount.hasError('minlength') || this.destinationAccount.hasError('maxlength'));
  }
  get peruvianCurrencySegment(): ISegmentSelector { return PEN_SEGMENT; }

  get accountFinancialInstitutionError(): boolean {  
    if (!this.destinationAccount.value || this.accountLengthError) { return false; }
    return this.destinationAccount.dirty && (this.financialInstitution===null);
  }

  get sameFinancialInstitution(): boolean {
    return this.destinationAccount.value  && this.destinationAccount.value.toString().substring(0, 3) === '055';
  }

  
  get originAccountName(): string {
    const origin = this.accountDetail(this.originAccount.value);
    return '';
   /* this.infoDetailsTransfer.originAccountName = origin ? origin.productName : '';
    return this.infoDetailsTransfer.originAccountName;*/
  }

  get originAccount() { return this.accountForm.controls.originAccount; }
  set originAccountSet(value:any) { this.accountForm.controls.originAccount.value(value); }
  get destinationAccountNumberMinLength(): number { return CCI_ACCOUNT_NUMBER_MIN_LENGTH;}
  get destinationAccountNumberMaxLength(): number {return CCI_ACCOUNT_NUMBER_MAX_LENGTH;}
  get baseCurrency() { return this.accountForm.controls.baseCurrency; }
  get destinationAccount() { return this.accountForm.controls.destinationAccount; }
  set destinationAccountSet(value:any) { this.accountForm.controls.destinationAccount.value(value); }

  private createAccountForm() {
    this.accountForm = this.formBuilder.group({
      originAccount: [null, [
        Validators.required
      ]],
      destinationAccount: ['', [
        Validators.required,
        Validators.minLength(CCI_ACCOUNT_NUMBER_MIN_LENGTH),
        Validators.maxLength(CCI_ACCOUNT_NUMBER_MAX_LENGTH),
        Validators.pattern(this.patternOriginAccount),
      ]],
       baseCurrency: [this.peruvianCurrencySegment.value, Validators.required],
    });

    this.accountForm.controls.originAccount.setValue(this.originAccountSelected.account);
    this.accountForm.controls.destinationAccount.setValue(this.destinationAccountDes);
       // this.originAccountSet(this.originAccountSelected.account);
    this.subscribeTodestinationAccount();
   
  }

  isValidCciAccount():boolean{
    this.isValidAccount =  (!(this.accountLengthError 
      && !this.destinationAccount.hasError('pattern')) 
      && !this.destinationAccount.hasError('pattern')
      && !this.sameFinancialInstitution);
    return this.isValidAccount;
  }

  public minMaxAccountLength(): string {
    if (this.destinationAccountNumberMaxLength === this.destinationAccountNumberMinLength) {
      return this.destinationAccountNumberMaxLength.toString();
    } else {
      return `entre ${this.destinationAccountNumberMinLength} y ${this.destinationAccountNumberMaxLength}`;
    }
  }

  private subscribeTodestinationAccount() {
    console.log("Subscribe");
    this.destinationAccount.valueChanges.subscribe((cciValue: string) => {   
      console.log("hola",cciValue);
      this.isValidCciAccount();  
      if(this.isValidAccount){
        this.isDataChanged  = (cciValue!== this.destinationAccountDesInit)?true:false;
      }    
    });
    //this.setFinancialInstitution();
  }

  public async openTransferErrorModal(prop) {
    const modal = await this.modalController.create({
      component: TransferErrorModalComponent,
      componentProps: prop
    });
    await modal.present();
  } 

  public changeCurrency(selectedBaseCurrency: string) {
    this.baseCurrency.setValue(selectedBaseCurrency);
  }
  private accountDetail(accountNumber: string): IDetailsSavingAccount {
    return this.originAccounts.find(savingAccount => savingAccount.account === accountNumber);
  }
  public getValueBaseCurrencyConst(symbol: string): string {
   return (symbol === PEN_SEGMENT.symbol) ? PEN_BASE_CURRENCY : USD_BASE_CURRENCY;
  }

  saveAccountInfo(){
    let infoData = {
      originSelect: this.originAccountSelected,
      destinationAccount:this.destinationAccountDes
    }
    this.modalController.dismiss(infoData);
  }

  public AccountSelected(account: string) {
    if (account != null) {
      const origin = this.accountDetail(account);
      this.originAccountSelected = origin;      
      this.isCTSOriginAccount = origin.benefits.includes("CTS");     
      this.selectedAmountAccount = origin.availableAmount;
      this.changeCurrency(this.getValueBaseCurrencyConst(origin.symbolCurrency));    
      this.notCTS = origin.benefits !== CTS_CODE;
      this.isDataChanged  = (this.originAccountSelected.account!== this.originAccountInit.account)?true:false;
    
      /*if (!this.transferForm.controls[LABEL_FORM_OWN_ACCOUNT].disabled) {
        this.notCTS = origin.benefits !== CTS_CODE;
        this.transferForm.controls[LABEL_FORM_OWN_ACCOUNT].setValue(true);
      }*/
    }
  }


  dismissModal(){
    this.dismissModal();
  }
}
