import { Subscription } from 'rxjs';

import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  OTP_LENGTH, OTP_MAX_TIME, SEND_OTP_SUCCESSFUL
} from '@common/constants/enrollment.constants';
import { MILLISECONDS_TO_SECOND } from '@common/constants/misc.constants';
import { IHashInformation } from '@common/interfaces/general.interface';
import { IUserHashInformation, IUserInformation } from '@common/interfaces/user.interface';
import { ModalController } from '@ionic/angular';
import { HideTextPipe } from '@pipes/hide-text-number/hide-text-number.pipe';
import { AlertService } from '@services/alert/alert.service';
import { OtpService } from '@services/otp/otp.service';
import { UserService } from '@services/user/user.service';
import { UtilsService } from '@services/utils/utils';
import { AlternativeEfexComponent } from '@components/efex/modals/alternative-efex/alternative-efex.component';
import { EFEX_S4_SMS, EFEX_TIMEOUT } from '@common/constants/tag/tag-efex.constants';
import { SUCCESS } from '@common/constants/error.constants';


@Component({
  selector: 'app-sms-validation-input-efex',
  templateUrl: './sms-validation-input-efex.component.html',
  styleUrls: ['./sms-validation-input-efex.component.scss'],
})
export class SmsValidationInputEfexComponent implements OnDestroy {

  @Input() phoneNumber: string;
  @Input() tagManagerEventLabel: string;
  @Input() userInformation: IUserHashInformation;
  @Input() checkingSMSMessage: boolean;
  @Output() otpCodeValidation: EventEmitter<boolean>;
  @Output() otpCodeValue: EventEmitter<string>;
  @Output() canPayEfex: EventEmitter<boolean>;
  otpCodeForm: FormGroup;
  maxTime: number;
  showTimerSection: boolean;
  showNotYetSection: boolean;
  hideTimer: boolean;
  hideText: HideTextPipe;
  subscription: Subscription;
  user: IUserInformation;
  validatingOtp: boolean;
  url: string;
  timer: NodeJS.Timeout;
  errorTimes: number;
  smsCheckMessage = '';
  constructor(
    private validationService: OtpService,
    private alertService: AlertService,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private router: Router,
    private utilsService: UtilsService,
    private userService: UserService,
  ) {
    this.otpCodeValidation = new EventEmitter();
    this.otpCodeValue = new EventEmitter();
    this.hideText = new HideTextPipe();
    this.subscription = new Subscription();
    this.maxTime = OTP_MAX_TIME;
    this.showTimerSection = false;
    this.showNotYetSection = false;
    this.hideTimer = false;
    this.validatingOtp = false;
    this.canPayEfex = new EventEmitter();
    this.otpCodeForm =  this.formBuilder.group({
      otpCode: new FormControl('', Validators.compose([
        Validators.required, Validators.maxLength(OTP_LENGTH),
        Validators.required, Validators.minLength(OTP_LENGTH),
      ])),
    });
    this.subscription.add(this.otpCode.valueChanges.subscribe((code: string) => {
      if (code.length === OTP_LENGTH && !this.validatingOtp) {
        this.emitOtp();
        this.emitEnabler(true);
      } else {
        this.emitEnabler(false);
      }
    }));
    this.url = this.router.url;
    this.user = this.userService.userInformation || {} as IUserInformation;
  }


  get otpCode() {
    return this.otpCodeForm.controls.otpCode;
  }

  get showTimerLoading() {
    return this.showTimerSection && this.maxTime === OTP_MAX_TIME;
  }

  public emitEnabler(value: boolean) {
    this.canPayEfex.emit(value);
  }
  public emitOtp() {
    this.validatingOtp = false;
    this.showTimerSection = false;
    this.showNotYetSection = false;
    this.otpCodeValidation.emit(true);
    const otpValue = this.otpCode.value;
    this.otpCodeValue.emit(otpValue);
  }

  private setOtpTimer() {
    clearTimeout(this.timer);
    const MAX_TIME = 30;
    this.timer = setTimeout(() => {
      if (this.maxTime <= 0) {
        this.showTimerSection = false;
      }
      this.maxTime -= 1;
      if (this.maxTime > 0) {
        if (this.maxTime === MAX_TIME) {
          this.showNotYetSection = true;
        }
        this.hideTimer = false;
        this.setOtpTimer();
      } else {
        this.hideTimer = true;
      }
    }, MILLISECONDS_TO_SECOND);
  }

  private sendError(message: string) { throw new Error(message); }


  public async resendOTP() {
    try {
      this.otpCodeForm.controls['otpCode'].setValue('');
      this.cleanCheckMessage();
      this.errorTimes = 0;
      this.sendOTP(true);
    } catch (err) {

      const alertMessage = await this.alertService.openErrorAlert(this.url, true, null, null, err.errorMessage);
      if (alertMessage) {
        alertMessage.present();
        const response = await alertMessage.onDidDismiss();
        if (response) { await this.resendOTP(); }
      }
    }
  }

  public async sendOTP(isResend: boolean = false) {
    if (isResend) {  }
    try {
      const hashInfo: IHashInformation = {
        hashCode: this.userInformation.hashCode
      };
      const otpResponse = await this.validationService.sendOTPEfex(hashInfo);
      if (otpResponse.code === SUCCESS) {
          this.maxTime = OTP_MAX_TIME;
          this.showTimerSection = true;
          this.setOtpTimer();
      } else {
        this.sendError(otpResponse.message);
      }
    } catch (err) {
      const alertMessage = await this.alertService.openErrorAlert(this.url, true, null, null, err.errorMessage);
      if (alertMessage) {
        alertMessage.present();
        const response = await alertMessage.onDidDismiss();
        if (response) { await this.sendOTP(); }
      }
    }
  }


  public showItIsNotMyNumberModal = async () => {

    const props = {
      title: 'Detalles de tu ',
      subtitle: 'Monto a desembolsar',
      primaryButtonText: 'Volver'
    };
    const modal = await this.modalCtrl.create({
      component: AlternativeEfexComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

  public updateCheckMessage(option: number, serviceMessage: string) {
    const message = document.getElementById('sms-check-message');
    switch (option) {
      case 1: {
        this.smsCheckMessage = 'Código Correcto';
        message.style.color = '#008000';
        message.style.display = 'block';
        break;
      }
      case 2: {
        this.smsCheckMessage = serviceMessage;
        message.style.color = '#ff0000';
        message.style.display = 'block';
        this.showTimerSection = true;
        break;
      }
      case 3: {
        this.smsCheckMessage = serviceMessage;
        message.style.color = '#ff0000';
        message.style.display = 'block';
        this.emitEnabler(false);
        clearTimeout(this.timer);
        this.showTimerSection = true;
        this.hideTimer = true;
        break;
      }
    }
  }

  public cleanCheckMessage() {
    const message = document.getElementById('sms-check-message');
    message.style.display = 'none';
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
