export const CREDIT_CARD_PRODUCT_CODE = 'CSTC';

export const SEF_PRODUCT_CODE = 'CSSE';

export const LAST_DATE_PAYMENT_LABEL = 'Último día de pago';

export const LAST_DATE_PAYMENT_LABEL_MOBILE = 'Vencimiento';

export const TOTAL_PAYMENT_LABEL = 'Pago total mes';

export const TOTAL_PAYMENT_LABEL_MOBILE = 'Total mes';

export const MINIMUM_PAYMENT_LABEL = 'Pago mínimo mes';

export const MINIMUM_PAYMENT_LABEL_MOBILE = 'Mínimo mes';

export const QUOTA_VALUE_LABEL = 'Valor cuota';

export const QUOTA_AMOUNT_LABEL = 'Cuotas';

export const MOVEMENTS_DATE_CLASS = 'date';

export const TC_MOVEMENTS_DATE_CLASS = 'tc_date';

export const MOVEMENTS_DESCRIPTION_CLASS = 'description';

export const MOVEMENTS_DESCRIPTION_SEF_CLASS = 'description-sef';

export const MOVEMENTS_DESCRIPTION2_CLASS = 'description2';

export const MOVEMENTS_DESCRIPTION_SAVING_CLASS = 'description-saving';

export const MOVEMENTS_AMOUNT_CLASS = 'value';

export const MOVEMENTS_AMOUNT_SAVING_CLASS = 'value-saving';

export const MOVEMENTS_ICON_CLASS = 'icon';

export const DETAIL_CLASS = 'detail';

export const STATEMENT_DOCUMENT_ERROR = 'No se ha podido cargar el estado de cuenta. Intenta más tarde.';

export const STATEMENT_EMPTY = 'Este mes no tuviste consumos, por lo tanto no se generó un estado de cuenta';

export const DAYS_FOR_CALENDAR_LINK = 15;

export const CLOSED_MASTERCARD_SUB_PRODUCT_CODES = ['11', '12', '13', '14'];

export const OPEN_MASTERCARD_SUB_PRODUCT_CODES = ['01', '02', '03', '04', '05', '06', '10', '15', '18'];

export const CALENDAR_EVENT_TEMPLATE = {
  title: 'Último día de pago',
  location: '',
  description: '¡Hola! Recuerda pagar tu tarjeta Ripley antes de que venza el plazo',
};

export const STATEMENT_VALE_CENCOSUD_EMPTY = 'No se pudo cargar el canje, por lo tanto no se generó el vale Cencosud';

export const STATEMENT_VALE_CENCOSUD_ERROR = 'No se ha podido cargar el vale Cencosud. Intenta más tarde.';

export const TypeReportCodes = {
  Todos: 'Todos',
  Vigentes: 'Vigentes',
  Expirados: 'Expirados'
};