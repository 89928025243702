import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CompiledAppGuard implements CanActivate {

  constructor(
    private platform: Platform,
    private router: Router,
  ) { }

  public canActivate(): boolean {
    const isNative = this.platform.is('cordova');
    if (isNative) { return true; }
    this.router.navigateByUrl('/home');
    return false;
  }
}
