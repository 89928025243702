import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-benefits-efex',
  templateUrl: './benefits-efex.component.html',
  styleUrls: ['./benefits-efex.component.scss'],
})
export class BenefitsEfexComponent implements OnInit {

  constructor(private viewController: ModalController) {

  }

  ngOnInit() {}

  dismissModal() {
    this.viewController.dismiss();
  }

}
