import { ContextData } from "@common/interfaces/digital-auth.interface";
import { environment } from "@environments/environment";

export class CommonParamsRequest {
    appId: string;
    channel: string;
    forwaredIp: string;
    userAgent: string;
    constructor(appId: string, channel: string, forwaredIp: string, userAgent: string) {
        this.appId = appId;
        this.channel = channel;
        this.forwaredIp = forwaredIp;
        this.userAgent = userAgent;
    }
}

export class CommonUserDataRequest {
    enterpriseCode: string;
    userId: string;
    userType: string;
    constructor(enterpriseCode: string, userId: string, userType: string) {
        this.enterpriseCode = enterpriseCode;
        this.userId = userId;
        this.userType = userType;
    }
}

export class RpassConfig {
    faq: RPassFAQ[];
    steps: RPassEnrollStep[];
    politic: RPassPolitic;
    unrollAlert: RPassUnrollAlert;
    enrollQR: RPassEnrollQR;
    initiatives: RPassInitiative[];
    invalidRpassConfiguration?: InvalidRpassConfiguration;
    constructor(
        faq: RPassFAQ[],
        steps: RPassEnrollStep[],
        politic: RPassPolitic,
        unrollAlert: RPassUnrollAlert,
        enrollQR: RPassEnrollQR,
        initiatives: RPassInitiative[],
        invalidRpassConfiguration: InvalidRpassConfiguration,
    ) {
        this.faq = faq;
        this.steps = steps;
        this.politic = politic;
        this.unrollAlert = unrollAlert;
        this.enrollQR = enrollQR;
        this.initiatives = initiatives;
        this.invalidRpassConfiguration = invalidRpassConfiguration;
    }
}

export class RPassFAQ {
    order: number;
    question: string;
    answer: string;
    constructor(order: number, question: string, answer: string) {
        this.order = order;
        this.question = question;
        this.answer = answer;
    }
}

export class RPassEnrollStep {
    order: number;
    step: string;
    constructor(order: number, step: string) {
        this.order = order;
        this.step = step;
    }
}
export class RPassEnrollQR {
    qrUrl: string;
    version: string;
    constructor(qrUrl: string, version: string) {
        this.qrUrl = qrUrl;
        this.version = version;
    }
}

export class RPassPolitic {
    terms: string;
    version: string;
    constructor(terms: string, version: string) {
        this.terms = terms;
        this.version = version;
    }
}

export class RpassDeviceInfo {
    model: string;
    enrolledAt: Date;
}

export class RPassUnrollAlert {
    public buttonPrimaryText: string;
    public message: string;
    public title: string;
    public icon: string;
}

export class RPassInitiative {
    enabled: boolean;
    featureKey: string;
    title: string;
}

export class InvalidRpassConfiguration {
    maxIntents: number;
    mustBlockDevice: boolean;
    errorMessageOnLoginRpass: string;
}

export class OnBoardingApiBaseRequest {
    token: string;
    commonParamsRequest: CommonParamsRequest;
    commonUserDataRequest: CommonUserDataRequest;
    uuidDevice: string;
    constructor(
        token: string,
        commonParamsRequest: CommonParamsRequest,
        commonUserDataRequest: CommonUserDataRequest,
        uuidDevice: string
    ) {
        this.token = token;
        this.commonParamsRequest = commonParamsRequest;
        this.commonUserDataRequest = commonUserDataRequest;
        this.uuidDevice = uuidDevice;
    }
}

export class OnBoardingApiBaseResponse {
    rut?: string;
    userType?: string;
    deviceType?: string;
    evento?: string;
    localizacion?: string;
    ip?: string;
    userAgent?: string;
    os?: string;
    uuidDevice?: string;
    stepsSize?: number;
    contextData: ContextData[];
}

export class GetDeviceStatusRequest extends OnBoardingApiBaseRequest {
    public static readonly PATH = environment.WIDGET_ONB_API + '/get-device-status';
    constructor(
        token: string,
        commonParamsRequest: CommonParamsRequest,
        commonUserDataRequest: CommonUserDataRequest,
        uuidDevice: string
    ) {
        super(token, commonParamsRequest, commonUserDataRequest, uuidDevice);
    }
}

export class BlockDeviceRequest extends OnBoardingApiBaseRequest {
    public static readonly PATH = environment.WIDGET_ONB_API + '/block-device';
    constructor(
        token: string,
        commonParamsRequest: CommonParamsRequest,
        commonUserDataRequest: CommonUserDataRequest,
        uuidDevice: string
    ) {
        super(token, commonParamsRequest, commonUserDataRequest, uuidDevice);
    }
}
