import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HOME_ROUTE, TRANSFERS_ROUTE } from '@common/constants/routes.constants';

@Component({
  selector: 'app-transfer-error-modal',
  templateUrl: './transfer-error-modal.component.html',
  styleUrls: ['./transfer-error-modal.component.scss'],
})
export class TransferErrorModalComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() primaryButtonText: string;
  @Input() secondaryButtonText: string;
  @Input() thirdButtonText: string;
  @Input() icon: string;

  constructor(
    private modalController: ModalController,
    private router: Router
  ) {
    this.title = '';
    this.primaryButtonText = 'Aceptar';
    this.secondaryButtonText = '';
  }

  public dismissModal() {
    this.modalController.dismiss();
  }

  public goToTransferOptions() {
    this.dismissModal();
    return this.router.navigate([TRANSFERS_ROUTE], { replaceUrl: true });
  }

  public goToHome() {
    this.dismissModal();
    return this.router.navigate([HOME_ROUTE], { replaceUrl: true });
  }

  public capitalize(text: string): string {
    return text[0].toUpperCase() + text.slice(1).toLowerCase();
  }
}
