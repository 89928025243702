import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { EMAIL_REGEX } from '@common/constants/misc.constants';
import { ACCOUNT_NUMBER_MAX_LENGTH, ACCOUNT_NUMBER_MIN_LENGTH, MAX_TRANSFER_AMOUNT_PEN, MAX_TRANSFER_AMOUNT_USD, MAX_TRANSFER_IMMEDIATE_AMOUNT_PEN, MAX_TRANSFER_IMMEDIATE_AMOUNT_USD, PEN_BASE_CURRENCY, PEN_CODE_CURRENCY, PEN_SEGMENT, POSITIVE_DECIMAL_NUMBER_PATTERN, TRANSFER_MAX_AMOUNT_CODE_APP_PEN, TRANSFER_MAX_AMOUNT_CODE_APP_USD, TRANSFER_MAX_AMOUNT_CODE_PEN, TRANSFER_MAX_AMOUNT_CODE_USD, TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_APP_PEN, TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_APP_USD, TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_PEN, TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_USD, TRANSFER_MIN_AMOUNT, USD_SEGMENT } from '@common/constants/transfers.constants';
import { ISegmentSelector } from '@common/interfaces/default.interface';
import { IDetailsSavingAccount } from '@common/interfaces/savings.interface';
import { IInfoDetailTransfer } from '@common/interfaces/transfers.interface';
import { ModalController, Platform } from '@ionic/angular';
import { PeruvianCurrencyPipe } from '@pipes/peruvian-currency/peruvian-currency.pipe';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { TransferService } from '@services/transfer/transfer.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-step-two-user-data-ob',
  templateUrl: './step-two-user-data-ob.component.html',
  styleUrls: ['./step-two-user-data-ob.component.scss'],
})
export class StepTwoUserDataObComponent implements OnInit {

  accountNumberLength: number;
  transferMaxAmount: number;
  transferMinAmount: number;
  maxTransferAmountPEN: number;
  maxTransferAmountUSD: number;
  maxTransferImmediateAmountPEN: number;
  maxTransferImmediateAmountUSD: number;
  selectedAmountAccount: number;
  availableAmounSelected: number;
  isValidAccount: boolean; 
  isKeyEnterDown: boolean; 
  isMaxAmountValidAmount: boolean; 
  isDeferredTransfer: boolean; //Falta establecer en qué momento será diferida
  desktop: boolean = false;
  numCharacters: string;
  emailVoucher:string;
  limitAmmount: string;
  errorTransferMessage:string;
  originAccountDes: string;
  destinationAccount: string;
  availableAmounSelectedDes: string;
  originAccountSubscription: Subscription;
  changeAmountSubscription: Subscription;
  userDataForm: FormGroup; 
  errorModal: HTMLIonModalElement; 
  @Input() originAccountSelected:IDetailsSavingAccount;
  @Input() originAccounts:IDetailsSavingAccount[];
  @Input() transferInfo: IInfoDetailTransfer; 
  @Output() sendInfoStepTwo = new EventEmitter<{transferInfo:IInfoDetailTransfer, valid:boolean}>();

  constructor(
    private modalErrorCtrl: ModalController,
    private formBuilder: FormBuilder, 
    private peruvianCurrency: PeruvianCurrencyPipe,
    private currencyPipe: CurrencyPipe,
    private transferService: TransferService,
    private platform: Platform,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    
    this.initializeVar();
 }

 initializeVar(){   
  console.log("Origin accounts ",this.originAccounts );
  console.log("Transfer info ",this.transferInfo );
  this.numCharacters = "0";
  this.errorTransferMessage = '';
  this.selectedAmountAccount = -1;
  this.isMaxAmountValidAmount = true;
  this.isDeferredTransfer = false; //Por defecto es inmediata
  this.maxTransferImmediateAmountPEN = MAX_TRANSFER_IMMEDIATE_AMOUNT_PEN;
  this.transferMinAmount = TRANSFER_MIN_AMOUNT;
  this.desktop = this.platform.is('desktop') || this.platform.is('mobileweb');
 }

  async ngOnInit() {
    this.createUserDataForm();    
    console.log("Form");
  } 

  async ngAfterViewInit() {
    try {   
        await this.setMaxTransfersAmmount(); 

        if(!this.originAccountSelected){
          this.originAccountSelected = this.originAccounts[0];
        }       
        console.log("two ",this.originAccountSelected);
        this.availableAmounSelectedDes = this.formatAmount(this.originAccountSelected.availableAmount);
        this.availableAmounSelected = this.originAccountSelected.availableAmount;
        this.transferInfo.originAccount = this.originAccountSelected.account;
        this.transferInfo.originAccountName = this.originAccountSelected.productName;
        this.transferInfo.baseCurrency = this.updateCurrency(this.originAccountSelected.codeCurrency);
        console.log("monto disponible",this.availableAmounSelected);
        //this.EventListenetDomInitTag(); 
    } catch (e) {
      console.error(e);     
      this.maxTransferImmediateAmountPEN = MAX_TRANSFER_IMMEDIATE_AMOUNT_PEN;
      this.maxTransferImmediateAmountUSD = MAX_TRANSFER_IMMEDIATE_AMOUNT_USD;
    }finally{
      this.transferMaxAmount = this.originAccountSelected.codeCurrency === PEN_CODE_CURRENCY ?  this.maxTransferImmediateAmountPEN : this.maxTransferImmediateAmountUSD;
    }
  }

  get originAccount() { return this.userDataForm.controls.originAccount; }
  get email() { return this.userDataForm.controls.email; } 
  get description() { return this.userDataForm.controls.description; } 
  get amount() { return this.userDataForm.controls.amount; }
  get baseCurrency() { return this.userDataForm.controls.baseCurrency; }
  get peruvianCurrencySegment(): ISegmentSelector { return PEN_SEGMENT; }
  get dollarsCurrencySegment(): ISegmentSelector { return USD_SEGMENT; }

  public get isValidAmount(): boolean {
    if ((this.amount.valid && this.amount.value <= this.availableAmounSelected)
          || this.availableAmounSelected === -1 ) {
          return true;
    }
    
    return false;   
  }

  isValidStep(){
    let isValid = this.isValidAmount 
                  && this.email.valid; 
    this.sendInfoStepTwo.emit({transferInfo:this.transferInfo, valid:isValid});
  }
  
  private updateCurrency(codeCurrency: string):string {
    return codeCurrency === PEN_CODE_CURRENCY ? this.peruvianCurrencySegment.value : this.dollarsCurrencySegment.value; 
  }

  async setMaxTransfersAmmount(){
     if(this.desktop){
        const maxTransferImmediateAmountPEN = await this.transferService.getMaxTransferAmount(TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_PEN);
        const maxTransferImmediateAmountUSD = await this.transferService.getMaxTransferAmount(TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_USD);
        if (maxTransferImmediateAmountPEN.value && maxTransferImmediateAmountUSD.value) {
          this.maxTransferImmediateAmountPEN =  Number(maxTransferImmediateAmountPEN.value);
          this.maxTransferImmediateAmountUSD =  Number(maxTransferImmediateAmountUSD.value);
        } else { 
          throwError('Value not found in parameter'); 
        }
      }else{
        const maxTransferImmediateAmountAPPPEN = await this.transferService.getMaxTransferAmount(TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_APP_PEN);
        const maxTransferImmediateAmountAPPUSD = await this.transferService.getMaxTransferAmount(TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_APP_USD);
        if (maxTransferImmediateAmountAPPPEN.value && maxTransferImmediateAmountAPPUSD.value) {
          this.maxTransferImmediateAmountPEN = Number(maxTransferImmediateAmountAPPPEN.value);
          this.maxTransferImmediateAmountUSD = Number(maxTransferImmediateAmountAPPUSD.value);
        } else { 
          throwError('Value not found in parameter'); 
        }
      }   
  }

  private get amountValidators(): ValidatorFn[] {
      console.log("Max amount", this.transferMaxAmount);
      return [
        Validators.required,
        Validators.min(this.transferMinAmount),
        Validators.max(this.transferMaxAmount),
        Validators.pattern(POSITIVE_DECIMAL_NUMBER_PATTERN),
      ];
  }

  public get formattedAmount() {
    return this.formatAmount(this.amount.value);
  }
  public get selectedCurrencySymbol(): string {
    return this.baseCurrency.value === PEN_BASE_CURRENCY ? PEN_SEGMENT.symbol : USD_SEGMENT.symbol;
  }
  
  private createUserDataForm() { 
    this.userDataForm = this.formBuilder.group({
      baseCurrency: [this.peruvianCurrencySegment.value, Validators.required],
      amount: [null, this.amountValidators],     
      originAccount: [null, [
        Validators.required,
        Validators.minLength(ACCOUNT_NUMBER_MIN_LENGTH),
        Validators.maxLength(ACCOUNT_NUMBER_MAX_LENGTH),
      ]],
      /*ownAccount: [{ value: true, disabled: false }, Validators.required],
      name: [{ value: null, disabled: false }, Validators.required],
      lastName: [{ value: null, disabled: false }, Validators.required], 
      beneficiaryName: [{ value: null, disabled: false }, Validators.required],
      beneficiaryLastName: [{ value: null, disabled: false }, Validators.required],
      documentTypeBeneficiary: [{ value: null, disabled: true }, Validators.required],
      documentNumberBeneficiary: [{ value: null, disabled: false }, this.documentNumberValidators],*/
      description: [null],
      email: [
        { value: null, disabled: false },
        [Validators.required, Validators.pattern(EMAIL_REGEX)]
      ],
    });
    
    this.email.setValue(this.transferInfo.email);
    this.subscribeToOriginAccount();  
    this.subscribeToChangeAmount();  
    this.subscribeToTransferEmail();
    this.subscribeToTransferDescription();

  }

  private subscribeToTransferDescription(){
    this.description.valueChanges.subscribe((character)=> {
      this.numCharacters = character.length.toString();
    });
  }

  private subscribeToTransferEmail(){
    this.email.valueChanges.subscribe((email)=> {
      this.emailVoucher = email;
      this.isValidStep();
    });
  }

  private accountDetail(accountNumber: string): IDetailsSavingAccount {
    return this.originAccounts.find(savingAccount => savingAccount.account === accountNumber);
  }
  
  private subscribeToOriginAccount() {
    this.originAccountSubscription = this.originAccount.valueChanges.subscribe((accountNumberSelected: string) => {
      //this.infoDetailsTransfer.originAccount = accountNumberSelected;
      const originAccount = this.accountDetail(accountNumberSelected);  
     // this.ownAccount.setValue(this.infoDetailsTransfer.ownAccount);
      //console.log("Own Account",this.ownAccount.value,this.infoDetailsTransfer.ownAccount);
      //this.updateCurrency(originAccount ? originAccount.codeCurrency : PEN_CODE_CURRENCY);
      if (originAccount) {
        this.transferMaxAmount = originAccount.codeCurrency === PEN_CODE_CURRENCY ?  this.maxTransferImmediateAmountPEN : this.maxTransferImmediateAmountUSD;
        this.limitAmmount  = '(máximo S/ ' +  this.formatAmount(this.maxTransferImmediateAmountPEN, PEN_BASE_CURRENCY); //+ ' o $ ' + this.formatAmount(this.maxTransferImmediateAmountUSD, USD_BASE_CURRENCY) + ')';
        this.updateTransferMaxAmount();
      }
    });
    
  }
  private formatAmount(value:any, baseCurrency?:string): any{   
    return ((baseCurrency!=undefined)?baseCurrency:this.baseCurrency.value) === PEN_BASE_CURRENCY ?
    this.peruvianCurrency.transform(value, 2) : this.currencyPipe.transform(value, USD_SEGMENT.symbol, USD_SEGMENT.symbol, '1.2-2');
  }
  private updateTransferMaxAmount() {
    this.amount.setValidators(this.amountValidators);
    this.amount.updateValueAndValidity();
  }


  private subscribeToChangeAmount() {
    this.changeAmountSubscription = this.amount.valueChanges.subscribe((amountChanged: string) => {
      this.transferInfo.transferAmount = amountChanged;
      this.isMaxAmountValidAmount = true;
      this.isValidStep();
    });
  }
  public get myOriginAccounts() {   
    let list = this.originAccounts.filter(s => (s.isAvailableForInterbankTransfer===true));
    if(!this.isDeferredTransfer){
      //return this.savingAccountsInformation;      
      let listAux = list.filter(s => (s.isAvailableForImmediateInterbankTransfer===true));      
      return listAux;
      }
    return list; 
  }

  public minMaxRange(min: number, max: number, _isValidAmount: boolean): string {
    let currencyType = (this.baseCurrency.value === PEN_BASE_CURRENCY) ? PEN_SEGMENT.symbol : USD_SEGMENT.symbol;
 
      return  (this.amount.value > this.availableAmounSelected) ?
        'Saldo insuficiente. El monto excede tu saldo disponible' :
        (min > this.amount.value ? `El monto mínimo de transferencia es debe ser ${currencyType} ${min.toString()}.` :
        (this.amount.value> this.transferMaxAmount? `El límite diario de transferencia es ${currencyType} ${this.transferMaxAmount}.`:''));
   
  }
 

  updateEventClick(value:any){
    console.log("Update click");
    this.availableAmounSelectedDes = this.formatAmount(value.originAccount.availableAmount);
    this.transferInfo.originAccountName = value.originAccount.productName;
    this.transferInfo.destinationAccount = value.destinationAccount;
  }

  public dismissModal() {
    this.modalErrorCtrl.dismiss();
  }

  @HostListener('keydown.enter', ['$event']) onEnter(e) {  
    this.isKeyEnterDown = true;
    console.log("Key down enter");
  }
  @HostListener('click', ['$event']) onClick(e) {  
    this.isKeyEnterDown = false;
    console.log("Key down enter");
  }
  
}
