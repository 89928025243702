import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RENEWAL, SEF } from '@common/constants/misc.constants';
import { LEAD_HOT, PRODUCT_SEF_REENGANCHE } from '@common/constants/offerings.constants';
import { SEF_ROUTE } from '@common/constants/routes.constants';
import { FUNCTION_TAG_FORMAT, GENERIC_TAG_POP_UP } from '@common/constants/tag/tag-generic.constants';
import { IOfferState, IOffering } from '@common/interfaces/offerings.interface';
import { IDetailDebitRSefOutput, IGenerateLoanData } from '@common/interfaces/simulator.interface';
import { OfferingModalComponent } from '@components/offering-modal/offering-modal.component';
import { ModalController } from '@ionic/angular';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { SimulatorService } from '@services/simulator/simulator.service';

@Component({
  selector: 'app-reengagement-detail',
  templateUrl: './reengagement-detail.component.html',
  styleUrls: ['./reengagement-detail.component.scss'],
})
export class ReengagementDetailComponent implements OnInit {

  @Input() title: string;
  @Input() amountIcon: string;
  @Input() offer: IOffering;
  @Input() tca: string;
  @Input() disclaimer: string;
  @Input() primaryButtonText: string;
  @Input() origin: string;
  detailDebit: IDetailDebitRSefOutput;
  secondaryButtonText: string;
  isLoading: boolean = false;
  hasData: boolean = true;

  constructor(
    private modalController: ModalController,
    private simulatorService: SimulatorService,
    private modalCtrl: ModalController,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
  ) {
    this.isLoading = false;
  }

  async ngOnInit() {
    const TAG = FUNCTION_TAG_FORMAT('detalle actual sef', 'pageview', SEF, 'step detalle actual sef', '', 0, 0, RENEWAL);
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_POP_UP, TAG);
    await this.callDetailDebitRSef();
  }

  async callDetailDebitRSef() {
    try {
      this.isLoading = true;
      this.detailDebit = await this.simulatorService.detailDebitRSef();
      if (this.detailDebit.code != 0 || this.detailDebit.loansInformation.originLoan != '2') {
        this.hasData = false;
        this.dismissModal();
        this.showSefReengancheModal();
      } else {
        this.hasData = true;
      }
    } catch (error) {
      this.hasData = false;
      this.dismissModal();
      this.showSefReengancheModal();
    } finally { this.isLoading = false; }
  }

  goToRSefFlow() {
    const TAG = FUNCTION_TAG_FORMAT('detalle actual sef', 'click', SEF, 'step detalle actual sef', '', 0, 0, RENEWAL);
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_POP_UP, TAG);
    const generateLead:IGenerateLoanData = {
      step: LEAD_HOT.reengagement,codeProduct: PRODUCT_SEF_REENGANCHE
    }
    this.simulatorService.GenerateLoan(generateLead);
    const prop: IOfferState = {
      isEfexPromotional: false,
      offeringData: this.offer,
      reengagement: this.detailDebit
    };
    this.router.navigate([SEF_ROUTE], { state: prop });
    this.dismissModal();
  }

  async showSefReengancheModal() {
    const props = {
      title: 'Detalles de tu Renovación Súper Efectivo',
      subtitle: 'Monto a desembolsar',
      primaryButtonText: 'Volver',
      offer: this.offer,
      amountIcon: 'i-money-bill-pink',
      tca: this.formattedTCA(this.offer),
      disclaimer: this.offer.customerOfferReference
    };
    const modal = await this.modalCtrl.create({
      component: OfferingModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

  public formattedTCA(offer: IOffering) {
    const timeMonth = Math.max(offer.minimumTimeMonth, offer.maximumTimeMonth);
    return 'TCEA* ' + Number(offer.rateTCA).toFixed(2) + '% a ' + timeMonth + ' cuotas.';
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  closeModal() {
    this.modalController.dismiss('primaryButtonPressed');
  }

}
