import { Coordinates } from '@ionic-native/geolocation/ngx';

export const DEFAULT_MAPS_OPTIONS = {
  zoom: 13,
  panControl: true,
  mapTypeControl: false,
  zoomControl: true,
  fullscreenControl: false,
  scaleControl: false,
  streetViewControl: false,
};

export const DEFAULT_LATITUDE = -12.094094;

export const DEFAULT_LONGITUDE = -77.024895;

export const PURPLE_PIN_ICON_LOCATION = '../../assets/icons/pin-purple.svg';

export const YELLOW_PIN_ICON_LOCATION = '../../assets/icons/pin-yellow.svg';

export const CURRENT_ICON_LOCATION = '../../assets/icons/blue-dot.png';

export const DEFAULT_MAP_CENTER = { latitude: DEFAULT_LATITUDE, longitude: DEFAULT_LONGITUDE } as Coordinates;

export const TEST_LOCATION = { latitude: -33.425420, longitude: -70.614120 } as Coordinates;

export const DISTANCE_CENTER_TEST = 2459.8;

export const DISTANCE_MOCK = 2.456;

export const DEFAULT_ADDRESS = ['Perú', 'Lima'];

export const DEFAULT_VICINITY = 'Lima, Perú';
