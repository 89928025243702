import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ERROR_GENERIC, ERROR_READ_DOCUMENT, ERROR_READ_FACE, ERROR_INITIALIZATION_FAILED, } from '@common/constants/digital-auth.constants';
import { CustomAlertsService } from '@services/custom-alerts/custom-alerts.service';
@Injectable({
  providedIn: 'root'
})
export class RpassErrorHandlerService {
  public repeatEvent;
  constructor(
    private customAlertsService: CustomAlertsService,
    private router: Router
  ) { }

  public async handleEvent(event) {
    this.repeatEvent = false;
    let template = ERROR_GENERIC;
    let modalData = event.data.mensaje || event.data.message;
    const buttons = [{
      cssClass: 'button-quit-for-rpass', text: 'Aceptar',
      handler: () => this.router.navigateByUrl('/login', { replaceUrl: true })
    }];
    switch (event.data.code) {
      case 'FC05':
        await new Promise((resolve) => {
          template = ERROR_READ_DOCUMENT;
          const buttons = [{ cssClass: 'button-quit-for-rpass', text: 'Aceptar', handler: () => resolve(true) }];
          this.repeatEvent = 'DNI';
          this.customAlertsService.promptForCustomAction(template, 'warning-br-pass', buttons);
        });
        break;
      case 'FC06':
        await new Promise((resolve) => {
          template = ERROR_READ_FACE;
          const buttons = [{ cssClass: 'button-quit-for-rpass', text: 'Aceptar', handler: () => resolve(true) }];
          this.repeatEvent = 'SELFIE';
          this.customAlertsService.promptForCustomAction(template, 'warning-br-pass', buttons);
        });
        break;
      case '03':
        await this.customAlertsService.promptForCustomAction(template, 'warning-br-pass', buttons);
        break;
      case '01':
        template = ERROR_INITIALIZATION_FAILED;
        await this.customAlertsService.promptForCustomAction(template, 'warning-br-pass', buttons);
        break;
      case '04':
        modalData = modalData ? modalData.split('|') : '';
        template.icon = false;
        template.title = modalData[0] || ERROR_GENERIC.title;
        template.message = modalData[1] || ERROR_GENERIC.message;
        await this.customAlertsService.promptForCustomAction(template, 'warning-br-pass', buttons);
        break;
      case '07':
        modalData = modalData ? modalData.split('|') : '';
        template.icon = false;
        template.title = modalData[0] || ERROR_GENERIC.title;
        template.message = modalData[1] || ERROR_GENERIC.message;
        await this.customAlertsService.promptForCustomAction(template, 'warning-br-pass', buttons);
        break;
      default:
        await this.customAlertsService.promptForCustomAction(template, 'warning-br-pass', buttons);
        break;
    }
  }
}
