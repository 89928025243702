import { environment } from '@environments/environment';

export const DNI_SEGMENT = {
  label: 'DNI',
  value:  environment.DNI_CODE,
};

export const FOREIGN_CARD_SEGMENT = {
  label: 'Carné Extranjería',
  value: environment.FOREIGN_CARD_CODE,
};

export const DNI_LENGTH = 8;

export const FOREIGN_CARD_LENGTH = 9;
