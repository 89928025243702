import { PINPAN_PUBLICKEY_XML } from '@common/constants/data-pinpan.constants';
export const GET_PINPAN_PRIVATE_KEY = {
    success: [
        {
            code: 0,
            message: 'OK',
            privateKey: PINPAN_PUBLICKEY_XML,
        }
    ],
    failures: [
        {
            code: 1,
            message: 'Error en el recaptcha, asegúrese de ser un humano'
        },
        {
            code: 3,
            message: 'Los dígitos ingresados no corresponden a su tarjeta o no es cliente Ripley'
        }
    ]
};