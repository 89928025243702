import { Component, Input, OnInit } from "@angular/core";
import { SCREEN_WIDTH } from "@common/constants/misc.constants";
import { GenericModalComponent } from "@components/generic-modal/generic-modal.component";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-help-message",
  templateUrl: "./help-message.component.html",
  styleUrls: ["./help-message.component.scss"],
})
export class HelpMessageComponent implements OnInit {
  @Input() message: string;
  constructor(public modalController: ModalController) {}
  async presentModal() {
    const modal = await this.modalController.create({
      component: GenericModalComponent,
      cssClass : 'help-message-modal',
      componentProps: {
        message: this.message,
        icon: '',
        showCloseButton: true
      },
    });
    return await modal.present();
  }
  ngOnInit() {}

  get isMobile() {
    return window.innerWidth < SCREEN_WIDTH.TABLET;
  }
}
