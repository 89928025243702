import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDetailsSavingAccount } from '@common/interfaces/savings.interface';
import { TransferModalOptionsComponent } from '@components/transfer-modal-options/transfer-modal-options.component';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-header-accounts',
  templateUrl: './header-accounts.component.html',
  styleUrls: ['./header-accounts.component.scss'],
})
export class HeaderAccountsComponent implements OnInit {

  optionModal: HTMLIonModalElement;
  @Input() originAccounts: IDetailsSavingAccount[];
  @Input() originAccount: IDetailsSavingAccount;
  @Input() destinationAccount: string;
  @Output() updateEvent = new EventEmitter<{destinationAccount:string,originAccount:IDetailsSavingAccount}>();
 
  constructor(
    private modalErrorCtrl: ModalController
  ) {
    
 }

  ngOnInit() {
   
    console.log("accounts", this.originAccounts);
    console.log("account", this.originAccount);
    console.log("destination", this.destinationAccount);
    
  }
  
  updateClick(){
    console.log("accounts", this.originAccounts);
    console.log("account", this.originAccount);
    console.log("destination", this.destinationAccount);
    this.openTransferOptionModal();
   
  }

  public async openTransferOptionModal() {   
 
    console.log(this.originAccount);
    console.log( this.originAccounts);
    console.log( this.destinationAccount);
    this.optionModal = await this.modalErrorCtrl.create({
      component: TransferModalOptionsComponent,
      cssClass: 'form-modal transfer-options',
      componentProps: {
        originAccountSelected: this.originAccount,
        originAccounts: this.originAccounts,
        destinationAccountDes: this.destinationAccount       
      }
    });
   
    this.optionModal.present();     
    this.optionModal.onDidDismiss()
    .then(async (data) => {
      if (data.data!== undefined){
        console.log(data);
        this.destinationAccount = data.data.destinationAccount;
        this.originAccount = data.data.originSelect;
        this.updateEvent.emit({destinationAccount:this.destinationAccount,originAccount:this.originAccount});
       // this.isDeferredTransfer = !data.data;        
       // if(this.isDeferredTransfer){//Agregar validación cuando cambie el CCI
        //  await this.setFinancialInstitution(); Se llama al servicio que trae info del cco
        //Si el servicio falla, entonces se tiene que realizar un reintento
       // }
      }
    }).catch((err) =>{
      if (err === 'cci'){//Muestra error general si falla todo, no se puede continuar con el flujo

       // this.isAccountFinancialInstitutionError = true;
      } 
    });
  }

  
}
