export const GTM_STEPS_GENERAL = {
  TC: {
    event: 'pagoTarjeta_event',
    event_category: 'flujoPago_tarjetaCredito',
  },
  SEF: {
    event: 'PagoSefPwa_event',
    event_category: 'flujoPagosSefPwa'
  },
  PRES: {
    event: 'PrestamoPago_event',
    event_category: 'flujoPrestamoPago'
  }
};

export const FLOW_RIPLEY_CARD = 'PAY_RIPLEY_CARD';

export const TAG_RIPLEY_CARD = {
    event: 'pagoTarjeta_event',
    event_category: 'flujoPago_tarjetaCredito',
    event_action: 'step_1.1',
    event_label: 'seleccion_tarjeta_credito'
};

// PASO 1
export const TAG_PAY_RIPLEY_CARD_STEP1_OPTIONS = {
    event: 'pagoTarjeta_event',
    event_category: 'flujoPago_tarjetaCredito',
    event_action: 'step_1.2',
    event_label: 'seleccion_opciones_pago'
};

export const TAG_PAY_RIPLEY_CARD_STEP1_NEXT = {
    event: 'pagoTarjeta_event',
    event_category: 'flujoPago_tarjetaCredito',
    event_action: 'step_1.3',
    event_label: 'opcionesPago_continuar'
};

// PASO 2
export const TAG_PAY_RIPLEY_CARD_STEP2_WEB_PAYMENT = {
    event: 'pagoTarjeta_event',
    event_category: 'flujoPago_tarjetaCredito',
    event_action: 'step_2',
    event_label: 'seleccion_pago_web'
};

export const TAG_PAY_RIPLEY_CARD_STEP2_CONDITION1 = {
    event: 'pagoTarjeta_event',
    event_category: 'flujoPago_tarjetaCredito',
    event_action: 'step_2.2',
    event_label: 'aceptar_tratamiento_datos'
};

export const TAG_PAY_RIPLEY_CARD_STEP2_CONDITION2 = {
    event: 'pagoTarjeta_event',
    event_category: 'flujoPago_tarjetaCredito',
    event_action: 'step_2.3',
    event_label: 'aceptar_terminos_condiciones'
};

export const TAG_PAY_RIPLEY_CARD_STEP2_NEXT = {
    event: 'pagoTarjeta_event',
    event_category: 'flujoPago_tarjetaCredito',
    event_action: 'step_2.4',
    event_label: 'continuar_siguiente_paso'
};

export const TAG_PAY_RIPLEY_CARD_STEP2_CANCEL = {
    event: 'pagoTarjeta_event',
    event_category: 'flujoPago_tarjetaCredito',
    event_action: 'step_2.5',
    event_label: 'cancelar_pago_web'
};

// Modal: datos de tarjeta
export const TAG_PAY_RIPLEY_CARD_DO_PAYMENT = {
    event: 'pagoTarjeta_event',
    event_category: 'flujoPago_tarjetaCredito',
    event_action: 'step_3',
    event_label: 'pagar_tarjeta'
};

export const TAG_NO_DEUDA_INICIO = {
  event: 'pagoTarjeta_event',
  event_category: 'flujoPago_tarjetaCredito',
  event_action: 'step_0.4',
  event_label: 'modal_no_tienes_deuda_desde_boton_pagar_tc_inicio'
};

export const TAG_NO_DEUDA_CREDITO = {
  event: 'pagoTarjeta_event',
  event_category: 'flujoPago_tarjetaCredito',
  event_action: 'step_0.5',
  event_label: 'modal_no_tienes_deuda_en_seleccion_tarjeta_credito'

};

export const TAG_NO_DEUDA_MOV = {
  event: 'pagoTarjeta_event',
  event_category: 'flujoPago_tarjetaCredito',
  event_action: 'step_0.6',
  event_label: 'modal_no_tienes_deuda_desde_movimientos_pagar_tc'
};


export const TAG_CUANTO_QUIERES_PAGAR = {
  event: 'pagoTarjeta_event',
  event_category: 'flujoPago_tarjetaCredito',
  event_action: 'step_0.3',
  event_label: 'despliega_paso_1_cuanto_quieres_pagar'
};

export const TAG_ERROR_SCRIPT = {
  event: 'error_pagoTarjeta_event',
  event_category: 'error_flujoPago_tarjetaCredito',
  event_action: 'error',
  event_label: 'error_incrustación_script'
};

export const TAG_ERROR_RECAPTCHA = {
  event: 'error_pagoTarjeta_event',
  event_category: 'error_flujoPago_tarjetaCredito',
  event_action: 'error',
  event_label: 'error_recaptcha_pago'
};

export const TAG_SHOW_TIMER_MODAL = {
  event: 'pagoTarjeta_event',
  event_category: 'flujoPago_tarjetaCredito',
  event_action: 'step_2.7',
  event_label: 'ventana_modal_tiempo_extra'
};

export const TAG_BUTTON_TIMER_MODAL = {
  event: 'pagoTarjeta_event',
  event_category: 'flujoPago_tarjetaCredito',
  event_action: 'step_3.3',
};

export const GTM_STEPS = {
  TC: {
    // error 4
    cuantoQuieresPagar : {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_0.3',
      event_label: 'despliega_paso_1_cuanto_quieres_pagar'
    },
    // errores de Pago tc 1,2 y 3
    modalNoDeudaTarjetaInicio : {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_0.4',
      event_label: 'modal_no_tienes_deuda_desde_boton_pagar_tc_inicio'
    },
    modalNoDeudaTarjetaCredito : {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_0.5',
      event_label: 'modal_no_tienes_deuda_en_seleccion_tarjeta_credito'
    },
    modalNoDeudaMovimientos : {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_0.6',
      event_label: 'modal_no_tienes_deuda_desde_movimientos_pagar_tc'
    },
    selectCard: {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_1.1',
      event_label: 'seleccion_tarjeta_credito'
    },
    selectAmount: {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_1.2',
      event_label: 'seleccion_opciones_pago'
    },
    termsAndConditions: {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_2.3',
      event_label: 'aceptar_terminos_condiciones'
    },
    continue: {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_2.4',
      event_label: 'continuar_siguiente_paso'
    },
    cancel: {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_2.5',
      event_label: 'cancelar_pago_web'
    },
    showSdk: {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_2.6',
      event_label: 'ventana_pago_tarjeta_credito'
    },
    doPayment: {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_3',
      event_label: 'pagar_tarjeta'
    },
    cancelPayment: {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_3.1',
      event_label: 'cancelar_pago_tarjeta'
    },
    closeModal: {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_3.2',
      event_label: 'cerrar_sdk_niubiz'
    },
    voucherView: {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_4',
      event_label: 'exito_pago_tarjeta'
    },
    downloadVoucher: {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_4.1',
      event_label: 'exito_descargar_comprobante'
    },
    goToPayment: {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_4.2',
      event_label: 'exito_ver_tarjetas'
    },
    goToHome: {
      ...GTM_STEPS_GENERAL.TC,
      event_action: 'step_4.3',
      event_label: 'exito_volver_inicio'
    }
  },
  SEF: {
    movements: {
      ...GTM_STEPS_GENERAL.SEF,
      event_action: 'step_0.2',
      event_label: 'movimientos_pagar_super_efectivo'
    },
    selectCard: {
      ...GTM_STEPS_GENERAL.SEF,
      event_action: 'step_0',
      event_label: 'seleccion_super_efectivo_pwa'
    },
    termsAndConditions: {
      ...GTM_STEPS_GENERAL.SEF,
      event_action: 'step_2',
      event_label: 'acepto_terminos_sefPwa'
    },
    continue: {
      ...GTM_STEPS_GENERAL.SEF,
      event_action: 'step_3',
      event_label: 'continuar_pago_sefPwa'
    },
    cancel: {
      ...GTM_STEPS_GENERAL.SEF,
      event_action: 'step_4',
      event_label: 'cancelar_pago_sefPwa'
    },
    showSdk: {
      ...GTM_STEPS_GENERAL.SEF,
      event_action: 'step_5',
      event_label: 'ventana_pagos_sefPwa'
    },
    doPayment: {
      ...GTM_STEPS_GENERAL.SEF,
      event_action: 'step_6',
      event_label: 'pagar_super_efectivo_pwa'
    },
    cancelPayment: {
      ...GTM_STEPS_GENERAL.SEF,
      event_action: 'step_7',
      event_label: 'cancelar_super_efectivo_pwa'
    },
    closeModal: {
      ...GTM_STEPS_GENERAL.SEF,
      event_action: 'step_7.1',
      event_label: 'cerrar_sdk_niubiz'
    },
    voucherView: {
      ...GTM_STEPS_GENERAL.SEF,
      event_action: 'step_8',
      event_label: 'exito_pago_super_efectivo_pwa'
    },
    downloadVoucher: {
      ...GTM_STEPS_GENERAL.SEF,
      event_action: 'step_9',
      event_label: 'descargar_comprobante_superEfectivo_pwa'
    },
    goToPayment: {
      ...GTM_STEPS_GENERAL.SEF,
      event_action: 'step_10',
      event_label: 'ver_tarjeta_sefPwa'
    },
    goToHome: {
      ...GTM_STEPS_GENERAL.SEF,
      event_action: 'step_11',
      event_label: 'volver_inicio_desde_sefPwa'
    }
  },
  PRESTAMO: {
    selectCard: {
      ...GTM_STEPS_GENERAL.PRES,
      event_action: 'step_0.1',
      event_label: 'seleccionar_prestamo_convenio'
    },
    showPage: {
      ...GTM_STEPS_GENERAL.PRES,
      event_action: 'step_1.1',
      event_label: 'pantalla_inicio_pago_prestamo'
    },
    termsAndConditions: {
      ...GTM_STEPS_GENERAL.PRES,
      event_action: 'step_1.2',
      event_label: 'aceptar_terminos_condiciones_prestamo'
    },
    continue: {
      ...GTM_STEPS_GENERAL.PRES,
      event_action: 'step_1.3',
      event_label: 'continuar_prestamo_pago'
    },
    cancel: {
      ...GTM_STEPS_GENERAL.PRES,
      event_action: 'step_1.4',
      event_label: 'cancelar_prestamo_pago'
    },
    showSdk: {
      ...GTM_STEPS_GENERAL.PRES,
      event_action: 'step_2.1',
      event_label: 'pantalla_pago_prestamo'
    },
    doPayment: {
      ...GTM_STEPS_GENERAL.PRES,
      event_action: 'step_2.2',
      event_label: 'continuar_pantalla_prestamo_pago'
    },
    cancelPayment: {
      ...GTM_STEPS_GENERAL.PRES,
      event_action: 'step_2.3',
      event_label: 'cancelar_pantalla_prestamo_pago'
    },
    closeModal: {
      ...GTM_STEPS_GENERAL.PRES,
      event_action: 'step_2.3',
      vent_label: 'cerrar_sdk_niubiz'
    },
    voucherView: {
      ...GTM_STEPS_GENERAL.PRES,
      event_action: 'step_3.1',
      event_label: 'exito_prestamo_pago'
    },
    downloadVoucher: {
      ...GTM_STEPS_GENERAL.PRES,
      event_action: 'step_3.2',
      event_label: 'descargar_comprobante_prestamo_pago'
    },
    goToPayment: {
      ...GTM_STEPS_GENERAL.PRES,
      event_action: 'step_3.3',
      event_label: 'volver_al_pago_desde_exito_prestamo'
    },
    goToHome: {
      ...GTM_STEPS_GENERAL.PRES,
      event_action: 'step_3.4',
      event_label: 'volver_a_inicio_desde_exito_prestamo'
    }
  },
};

export const TAG_PAY_RIPLEY_CARD_MOVEMENTS = {
    event: 'pagoTarjeta_event',
    event_category: 'flujoPago_tarjetaCredito',
    event_action: 'step_0.2',
    event_label: 'movimientos_pagar_tarjeta'
};


export const TAG_RIPLEY_CARD_BUTTON = {
    event: 'pagoTarjeta_event',
    event_category: 'flujoPago_tarjetaCredito',
    event_action: 'step_1',
    event_label: 'boton_pagarTarjeta'
};

export const TAG_SEF_BUTTON = {
    event: 'PagoSefPwa_event',
    event_category: 'flujoPagosSefPwa',
    event_action: 'step_1',
    event_label: 'boton_pagarsefhome'
};

export const TAG_PWA_APK_DOWNLOADVOUCHER = {
  event: 'pagoTarjeta_event',
  event_category: 'flujoPago_tarjetaCredito',
  event_action: 'step_0.1',
  event_label: ' exito_descargar_comprobante'
};

export const TAG_PWA_APK_HOME = {
  event: 'pagoTarjeta_event',
  event_category: 'flujoPago_tarjetaCredito',
  event_action: 'step_0.1',
  event_label: ' exito_volver_inicio'
};

export const TAG_PWA_APK_BACK = {
  event: 'pagoTarjeta_event',
  event_category: 'flujoPago_tarjetaCredito',
  event_action: 'step_0.1',
  event_label: 'exito_ver_tarjetas'
};

export const GA4_CONSTANTS = {
  INIT_PAGE: {
    EVENT: 'virtual_page',
    TAGS: {
      action: 'pageview',
      category: 'pagos',
      step: 'step inicio pagos'
    }
  },
  PRESTAMO: {
    PAY_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        product: 'pagar',
        action: 'click',
        category: 'pagos',
        sub_category: 'prestamos',
        step: 'step inicio pagos'
      }
    },
    DETAILS_PAGE: {
      EVENT: 'virtual_page',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'prestamos',
        step: 'step detalle deuda',
        type: ''
      }
    },
    CONTINUE_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        product: 'continuar',
        action: 'click',
        category: 'pagos',
        sub_category: 'prestamos',
        step: 'step detalle deuda',
        type: ''
      }
    },
    NIUBIZ_MODAL: {
      EVENT: 'virtual_page',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'prestamos',
        step: 'step realizar pago'
      }
    },
    NIUBIZ_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        action: 'click',
        category: 'pagos',
        sub_category: 'prestamos',
        step: 'step realizar pago'
      }
    },
    ERROR_PAYMENT: {
      EVENT: 'functional_error',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'prestamos',
        step: 'step realizar pago',
        step_error: 'error - operacion',
        type: ''
      }
    },
    VOUCHER_PAGE: {
      EVENT: 'end_flow',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'prestamos',
        step: 'step pago exitoso',
        pay_selection: '',
        type: ''
      }
    },
    VOUCHER_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        product: 'volver al inicio',
        action: 'click',
        category: 'pagos',
        sub_category: 'prestamos',
        step: 'step pago exitoso',
        type: ''
      }
    },
    DOWNLOAD_VOUCHER_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        product: 'descargar comprobante',
        action: 'click',
        category: 'pagos',
        sub_category: 'prestamos',
        step: 'step pago exitoso',
        type: ''
      }
    },
    NEXT_STEP_VP: {
      EVENT: 'virtual_page',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'prestamos',
        step: 'step seleccionar metodo de pago',
        type: ''
      }
    },
    NEXT_STEP_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        action: 'click',
        category: 'pagos',
        sub_category: 'prestamos',
        step: 'step seleccionar metodo de pago',
        type: '',
        pay_selection: ''
      }
    },
  },
  SEF: {
    PAY_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        product: 'pagar',
        action: 'click',
        category: 'pagos',
        sub_category: 'super efectivo',
        step: 'step inicio pagos'
      }
    },
    DETAILS_PAGE: {
      EVENT: 'virtual_page',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'super efectivo',
        step: 'step detalle deuda'
      }
    },
    CONTINUE_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        product: 'continuar',
        action: 'click',
        category: 'pagos',
        sub_category: 'super efectivo',
        step: 'step detalle deuda',
        type: ''
      }
    },
    NIUBIZ_MODAL: {
      EVENT: 'virtual_page',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'super efectivo',
        step: 'step realizar pago',
        type: ''
      }
    },
    NIUBIZ_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        product: '',
        action: 'click',
        category: 'pagos',
        sub_category: 'super efectivo',
        step: 'step realizar pago',
        type: ''
      }
    },
    ERROR_PAYMENT: {
      EVENT: 'functional_error',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'super efectivo',
        step: 'step realizar pago',
        step_error: 'error - operacion',
        type: ''
      }
    },
    VOUCHER_PAGE: {
      EVENT: 'end_flow',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'super efectivo',
        step: 'step pago exitoso',
        type: '',
        pay_selection: ''
      }
    },
    VOUCHER_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        action: 'click',
        category: 'pagos',
        sub_category: 'super efectivo',
        step: 'step pago exitoso',
        type: '',
        product: ''
      }
    },
    DOWNLOAD_VOUCHER_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        product: 'descargar comprobante',
        action: 'click',
        category: 'pagos',
        sub_category: 'super efectivo',
        step: 'step pago exitoso',
        type: ''
      }
    },
    NEXT_STEP_VP: {
      EVENT: 'virtual_page',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'tarjeta de credito',
        step: 'step seleccionar metodo de pago',
        type: ''
      }
    },
    NEXT_STEP_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        action: 'click',
        category: 'pagos',
        sub_category: 'tarjeta de credito',
        step: 'step seleccionar metodo de pago',
        type: '',
        pay_selection: ''
      }
    },
  },
  TC: {
    PAY_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        product: 'pagar',
        action: 'click',
        category: 'pagos',
        sub_category: 'tarjeta de credito',
        step: 'step inicio pagos'
      }
    },
    DETAILS_PAGE: {
      EVENT: 'virtual_page',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'tarjeta de credito',
        step: 'step detalle deuda'
      }
    },
    CONTINUE_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        product: 'continuar',
        action: 'click',
        category: 'pagos',
        sub_category: 'tarjeta de credito',
        step: 'step detalle deuda',
        type: ''
      }
    },
    NIUBIZ_MODAL: {
      EVENT: 'virtual_page',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'tarjeta de credito',
        step: 'step realizar pago',
        type: ''
      }
    },
    NIUBIZ_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        product: '',
        action: 'click',
        category: 'pagos',
        sub_category: 'tarjeta de credito',
        step: 'step realizar pago',
        type: ''
      }
    },
    ERROR_PAYMENT: {
      EVENT: 'functional_error',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'tarjeta de credito',
        step: 'step realizar pago',
        step_error: 'error - operacion',
        type: ''
      }
    },
    VOUCHER_PAGE: {
      EVENT: 'end_flow',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'tarjeta de credito',
        step: 'step pago exitoso',
        type: '',
        pay_selection: ''
      }
    },
    VOUCHER_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        action: 'click',
        category: 'pagos',
        sub_category: 'tarjeta de credito',
        step: 'step pago exitoso',
        type: '',
        product: ''
      }
    },
    DOWNLOAD_VOUCHER_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        product: 'descargar comprobante',
        action: 'click',
        category: 'pagos',
        sub_category: 'tarjeta de credito',
        step: 'step pago exitoso',
        type: ''
      }
    },
    NEXT_STEP_VP: {
      EVENT: 'virtual_page',
      TAGS: {
        action: 'pageview',
        category: 'pagos',
        sub_category: 'tarjeta de credito',
        step: 'step seleccionar metodo de pago',
        type: ''
      }
    },
    NEXT_STEP_CLICK: {
      EVENT: 'btn_click',
      TAGS: {
        action: 'click',
        category: 'pagos',
        sub_category: 'tarjeta de credito',
        step: 'step seleccionar metodo de pago',
        type: '',
        pay_selection: ''
      }
    },
  }
}