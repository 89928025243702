import { PeruvianCurrencyPipe } from '@pipes/peruvian-currency/peruvian-currency.pipe';
import { NgModule } from '@angular/core';
import { DynamicMoneyInputDirective } from '@directives/dynamic-money-directive/dynamic-money.directive';
import { AlphaInputDirective } from '@directives/alpha-directive/alpha.directive';
import { CurrencyPipe } from '@angular/common';
import { MoneyInputDirective } from '@directives/money-directive/money.directive';
import { MoneyDecInputDirective } from './money-directive/moneyDec.directive';
import { MoneyWithoutDecInputDirective } from './money-directive/moneyWithoutDec.directive';
import { NumericOnlyDirective } from './numeric-only/numeric-only.directive';
import { NumericOnlyIonDirective } from './numeric-only-ion/numeric-only.directive';

@NgModule({
  declarations: [
    MoneyInputDirective,
    MoneyDecInputDirective,
    DynamicMoneyInputDirective,
    MoneyWithoutDecInputDirective,
    AlphaInputDirective,
    NumericOnlyDirective,
    NumericOnlyIonDirective
  ],
  exports: [
    MoneyInputDirective,
    MoneyDecInputDirective,
    DynamicMoneyInputDirective,
    MoneyWithoutDecInputDirective,
    AlphaInputDirective,
    NumericOnlyDirective,
    NumericOnlyIonDirective
  ],
  providers: [
    PeruvianCurrencyPipe,
    CurrencyPipe,
  ]
})
export class DirectivesModule {}
