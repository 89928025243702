import { ChangeDetectorRef, Component,EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  ONLY_NUMBERS_REGEX,
  ENTER_KEY,
  BACKSPACE_KEY,
  SPECIAL_CHARACTER_KEY,
  NON_CONSECUTIVE_RPASS_PIN_REGEX,
  NON_REPEATED_RPASS_PIN_REGEX, PIN_RPASS_LENGTH_DEFAULT,
} from '@common/constants/digital-auth.constants';
import { DeviceService } from '@services/device/device.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { KeyboardService } from '@services/keyboard/keyboard.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-prepare-rpass',
  templateUrl: './prepare-rpass.component.html',
  styleUrls: ['./prepare-rpass.component.scss'],
})
export class PrepareRpassComponent implements OnInit, OnDestroy {
  @Input() public pin: string[] = [];
  @Input() public repeatPin: string[] = [];
  @Input() public initiativeState: boolean;
  @Input() public firstStep: boolean;
  @Output() saveRPassFactor = new EventEmitter();
  public showFirstPinCode: boolean = false;
  public showSecondPinCode: boolean = false;
  public isValidByConsecutiveNumbers: boolean = false;
  public isValidByRepeatedNumbers: boolean = false;
  public isValidByMismatchedNumbers: boolean = false;
  public subscription: Subscription;
  public isKeyboardOpen: boolean = false;
  public isInputValid: boolean;
  public stateFactor: boolean = false;
  public pinStatus: boolean = false;
  public isCheckedTerms: boolean= false;
  public channelOs = '';
  constructor(
      private keyboardService: KeyboardService,
      private changeDetectorRef: ChangeDetectorRef,
      private firebaseService: FirebaseService,
      private deviceService: DeviceService,
  ) {
    this.pinStatus = false;
  }
  async ngOnInit() {
    this.channelOs = this.deviceService.getPlatformName().toUpperCase();
    this.subscription = new Subscription();
    this.setKeyboardListeners();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  public createRPass() {
    this.saveRPassFactor.emit();
  }


  public get isAvailableButtonDNISelfie() {
    return this.firstStep ? this.isCheckedTerms : true
  }

  public validateCheckbox(value: boolean){
    if ( value ){
        this.isCheckedTerms= true;
    } else{
      this.isCheckedTerms= false;
    }
  }

  public setKeyboardListeners() {
    this.subscription.add(this.keyboardService.keyboardWillShow().subscribe(() => {
      this.isKeyboardOpen = true;
      this.changeDetectorRef.detectChanges();
    }));
    this.subscription.add(this.keyboardService.keyboardWillHide().subscribe(() => {
      setTimeout(() => this.isKeyboardOpen = false, 100);
      this.changeDetectorRef.detectChanges();
    }));
  }
  get isFirstPinFieldsIncomplete() { return this.pin.join('').length < PIN_RPASS_LENGTH_DEFAULT; }
  get isSecondPinFieldsIncomplete() { return this.repeatPin.join('').length < PIN_RPASS_LENGTH_DEFAULT; }
  get isFirstPinFieldsValid() { return this.isValidByConsecutiveNumbers && this.isValidByRepeatedNumbers; }
  get isValidatorsValid() {
    return this.isValidByConsecutiveNumbers && this.isValidByRepeatedNumbers && this.isValidByMismatchedNumbers;
  }
  public toggleShowPinCode(fieldsRowIndex: number) {
    if (fieldsRowIndex === 1) { this.showFirstPinCode = !this.showFirstPinCode; }
    if (fieldsRowIndex === 2) { this.showSecondPinCode = !this.showSecondPinCode; }
  }
  public noConsecutiveNumbersValidator(pinValue: string) {
    return NON_CONSECUTIVE_RPASS_PIN_REGEX.test(pinValue);
  }
  public noRepeatedNumbersValidator(pinValue: string) {
    return NON_REPEATED_RPASS_PIN_REGEX.test(pinValue);
  }
  public noMismatchedNumbersValidator(pinValue: string, repeatPinValue: string) {
    return (
        pinValue.length === PIN_RPASS_LENGTH_DEFAULT
        && repeatPinValue.length === PIN_RPASS_LENGTH_DEFAULT
        && pinValue === repeatPinValue);
  }
  public checkOnlyNumbers($event: any): string {
    const resp = $event.target.value.match(ONLY_NUMBERS_REGEX);
    return $event.target.value = resp ? resp.join('') : '';
  }
  public handleModelChange(newValue, inputIndex, rowIndex) {
    const resp = newValue.match(ONLY_NUMBERS_REGEX);
    const result = resp ? resp.join('') : '';
    rowIndex[inputIndex] = result;
    return result;
  }
  public onKeyboard(event: KeyboardEvent, index: number, rowIndex: number): void {
    const currentTarget = event.target as HTMLInputElement;
    this.handleConsecutiveAndRepeatedNumbers(rowIndex);
    this.isCheckedTerms = false;
    this.isValidByMismatchedNumbers = this.validateMismatchedNumbers();
    if (this.isSpecialKey(event)) { return; }
    this.handleKeyPress(event, index, currentTarget);
  }
  private validateMismatchedNumbers(): boolean {
    return this.noMismatchedNumbersValidator(this.pin.join(''), this.repeatPin.join(''));
  }
  private handleKeyPress(event: KeyboardEvent, index: number, currentTarget: HTMLInputElement): void {
    this.isBackspaceKey(event) ? this.handleBackspaceKey(index, currentTarget) : this.handleNonBackspaceKey(index, currentTarget);
  }
  private handleConsecutiveAndRepeatedNumbers(rowIndex: number): void {
    const FIRST_RPASS_ROW_INDEX = 1;
    if (rowIndex === FIRST_RPASS_ROW_INDEX) {
      this.isValidByConsecutiveNumbers = this.noConsecutiveNumbersValidator(this.pin.join(''));
      this.isValidByRepeatedNumbers = this.noRepeatedNumbersValidator(this.pin.join(''));
    }
  }
  private isSpecialKey(event: KeyboardEvent): boolean {
    return event.key === SPECIAL_CHARACTER_KEY || event.key === ENTER_KEY;
  }
  private isBackspaceKey(event: KeyboardEvent): boolean {
    return event.key === BACKSPACE_KEY;
  }
  private handleBackspaceKey(index: number, currentTarget: HTMLInputElement ): void {
    const nextElementIndex = index;
    this.focusNextElement(nextElementIndex, currentTarget);
  }
  private handleNonBackspaceKey(index: number, currentTarget: HTMLInputElement): void {
    if (index === 5) {
      currentTarget.blur();
      return;
    }
    const nextElementIndex = index + 2;
    this.focusNextElement(nextElementIndex, currentTarget);
  }
  private focusNextElement(nextElementIndex: number, currentTarget: HTMLInputElement): void {
    const parentElement = currentTarget.parentElement;
    if (parentElement) {
      const grandparentElement = parentElement.parentElement;
      if (grandparentElement) {
        const nextElement = grandparentElement.querySelector(`div.code-box:nth-of-type(${nextElementIndex}) input`) as HTMLInputElement;
        if (nextElement) {
          nextElement.focus();
        }
      }
    }
  }
}
