import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IStatementListMonths } from '@common/interfaces/product.interface';

@Component({
  selector: 'app-desktop-card-statements',
  templateUrl: './desktop-card-statements.component.html',
  styleUrls: ['./desktop-card-statements.component.scss'],
})
export class DesktopCardStatementsComponent {

  @Input() monthList: IStatementListMonths[];
  @Input() monthsForm: FormGroup;
  @Input() loadingPdf: boolean;
  @Input() secureStatementPdfUrl: string;

  constructor() {}

  get selectedMonth() { return this.monthsForm.controls.date; }

}
