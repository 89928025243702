export enum AuthWidgetV3Messages {
  initialized = 'WIDAUT-INICIADO',
  authorized = 'AUTENTICACION_APROBADA',
  rejected = 'AUTENTICACION_RECHAZADA',
  canceled = 'OPERACION_CANCELADA',
  activate_rpass = 'ACTIVAR_RPASS',
  activate_rpass_qr = 'ACTIVAR_RPASS_QR',
}

export enum AuthWidgetV3Status {
  success = 'OK',
  failure = 'ERROR',
}

export const IDNOW_SERVICES_DATA = {
  appId: 'RIPLEY',
  userType: 'CPA',
  country: 'PERU',
  channel: 'HBANK',
  enterpriseCode: '',
};
