export const PAYMENT_INFORMATION_MOCK = {
  success: [],
  failures: [],
};

export const PAYMENT_VALIDATION_MOCK = {
  success: [],
  failures: [],
};

export const PAYMENT_EXECUTION_MOCK = {
  success: [],
  failures: [],
};

export const PAYMENT_NIUBIZ_WAIT_TIME = {
  success: [],
  failures: [],
};

export const PAYMENT_CUT_OFF_TIME = {
  success: [],
  failures: [],
};
