import { Injectable } from '@angular/core';
import { FACTOR_KEYS, RPASS_INITIATIVES, USER_RPASS_ACTIVATION_KEY } from '@common/constants/digital-auth.constants';
import { DNI_SEGMENT } from '@common/constants/document-type.constants';
import { AccountManagerService } from '@services/account-manager/account-manager.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { IdnowService } from '@services/idnow/idnow.service';
import { StorageService } from '@services/storage/storage.service';
import { UtilsService } from '@services/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class RPassService {
  public hasActiveRPassPhone: boolean = false;
  public isSamePhone: boolean = false;

  constructor(
    private idnowService: IdnowService,
    private firebaseService: FirebaseService,
    private accountService: AccountManagerService,
    private storageService: StorageService,
    private utilsService: UtilsService

  ) { }

  public async getRPassFactor(): Promise<FACTOR_KEYS | null> {
    if (!this.utilsService.isNative()) throw new Error('method only available in native platforms');
    for (const factorValue of Object.values(FACTOR_KEYS)) {
      const isMatchedFactor = await this.idnowService.compareSecureData(factorValue, FACTOR_KEYS.factor);
      if (isMatchedFactor) return factorValue;
    }
    return null;
  }


  public async unsubscribeActiveDevice(documentNumber?: string): Promise<void> {
    const unformatDocumentNumber = documentNumber || await this.getUserPreLoggedForRPass();
    this.idnowService.clearEnrollmentIdNow();
    const currentTokenRpass = await this.firebaseService.getCurrentTokenRpass({ dType: DNI_SEGMENT.value, dNumber: unformatDocumentNumber });
    if (!currentTokenRpass) return;
    await this.idnowService.blockDevice(unformatDocumentNumber, currentTokenRpass.uuidDevice);
    this.firebaseService.deleteCurrentTokenRpass({ dType: DNI_SEGMENT.value, dNumber: unformatDocumentNumber });
    this.storageService.remove(USER_RPASS_ACTIVATION_KEY);
  }

  public async getUserPreLoggedForRPass(): Promise<string | null> {
    return await this.storageService.get(USER_RPASS_ACTIVATION_KEY) || await this.accountService.accountManager.getUserDNI() || null;
  }

  public async hasRPassOnPhone(dni: string) {
    this.hasActiveRPassPhone = false;
    this.isSamePhone = false;
    const isNewDeviceImprovementInitiative = await this
      .checkRPassInitiative(RPASS_INITIATIVES.NEW_DEVICE_IMPROVEMENT);
    if (!isNewDeviceImprovementInitiative) return;
    const { isEnrolled: isEnrollmentDevice, uuidDeviceReturn } = await this.idnowService.getDeviceStatus(dni);
    if (isEnrollmentDevice) {
      this.hasActiveRPassPhone = true;
      this.isSamePhone = true;
      return;
    }
    const currentTokenRpass = await this.firebaseService.getCurrentTokenRpass({ dType: DNI_SEGMENT.value, dNumber: dni });
    if (!currentTokenRpass) return;
    if (uuidDeviceReturn === currentTokenRpass.uuidDevice) this.isSamePhone = true;
    const { isEnrolled: isEnrolledOnOtherDevice } = await this.idnowService.getDeviceStatus(dni, currentTokenRpass.uuidDevice);
    if (isEnrolledOnOtherDevice) this.hasActiveRPassPhone = true;
  }

  private async checkRPassInitiative(initiative: string): Promise<boolean> {
    const initiativeOnOff = !!(await this.firebaseService.getInitiativeRpass(initiative)).enabled;
    return initiativeOnOff;
  }


}
