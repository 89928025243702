import {
  ENCRYPTION_BITS_BLOCK_LENGTH,
  ENCRYPTION_BYTES_BLOCK_LENGTH,
  DEFAULT_INITIALIZATION_VECTOR
} from '@common/constants/encryption.constants';
import { UtilsService } from '@services/utils/utils';
import * as forge from 'node-forge';
import { AuthService } from '@services/auth/auth.service';
import { environment } from '@environments/environment';
import { from, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ROUTES_WITH_BASIC_AUTHENTICATION, EXTERNAL_ROUTE } from '@common/constants/auth.constants';
import { HomePreloaderService } from '@services/home-preloader/home-preloader.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(public router: Router, 
              private authService: AuthService,
              private homePreloaderService: HomePreloaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(request, next)).pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  private async handle(request: HttpRequest<any>, next: HttpHandler) {
    let authorizationValue = this.selectAuthorizationType(null);
    const isExternalRoute = EXTERNAL_ROUTE.filter(route => request.url.includes(route));
    const matches = ROUTES_WITH_BASIC_AUTHENTICATION.filter(route => request.url.includes(route));
    if (!request.url.includes('/admin/user/connect/token') &&
        !request.url.includes('/admin/user/refresh/token') &&
        !request.url.includes('/admin/user/revoke/token')) {
      const cannotLoadService = await this.authService.refreshIfTokenIsInvalid();
      if (cannotLoadService) { throw new Error('Cannot load service'); }
    }

    // if() {
    //   throw new Error('Errooooorr');
    // }

    if (matches.length === 0 && this.authService.currentToken) {
      authorizationValue = this.encryptAuth(this.selectAuthorizationType(this.authService.currentToken.accessToken));
    }
    if (isExternalRoute.length === 0) {
      request = request.clone({
          setHeaders: {
            Authorization: authorizationValue,
          }
      });
    }

    this.homePreloaderService.preloadCallHomeRequests(request.url);   

    return next.handle(request).toPromise();
  }

  private selectAuthorizationType(token: string): string {
    return token ? `Bearer ${token}` : `Basic ${this.getBasicCredentials()}`;
  }

  private getBasicCredentials(): string {
    return btoa(environment.CLIENT_ID + ':' + environment.CLIENT_SECRET);
  }  

  private encryptAuth(body: string): string {
    const pem = environment.PUBLIC_KEY_PEM;
    const salt = forge.random.getBytesSync(ENCRYPTION_BITS_BLOCK_LENGTH);
    const randomString = UtilsService.getRandomString(ENCRYPTION_BYTES_BLOCK_LENGTH);
    const binaryEncodedKey = forge.pkcs5.pbkdf2(randomString, salt, ENCRYPTION_BYTES_BLOCK_LENGTH, ENCRYPTION_BYTES_BLOCK_LENGTH);
    const key = forge.util.encode64(binaryEncodedKey).substring(0, ENCRYPTION_BYTES_BLOCK_LENGTH);
    const encryptedContent = this.encryptContent(body, key);
    const encryptedKey = this.encryptKey(pem, key);
    return encryptedKey + encryptedContent;
  }

  private encryptContent(body: any, key: string): string {
    const cipher = forge.cipher.createCipher('AES-CBC', key);
    cipher.start({iv: DEFAULT_INITIALIZATION_VECTOR});
    if (typeof body === 'string') {
      cipher.update(forge.util.createBuffer(body));
    } else {
      cipher.update(forge.util.createBuffer(JSON.stringify(body),'utf8'));
    }
    cipher.finish();
    const encryptedAES = cipher.output.getBytes();
    return forge.util.encode64(encryptedAES);
  }

  private encryptKey(pem: string, key: string): string {
    const publicKey = forge.pki.publicKeyFromPem(pem);
    const buffer = forge.util.createBuffer(key, 'utf8');
    const bytes = buffer.getBytes();
    const encrypted = (publicKey as forge.pki.rsa.PublicKey).encrypt(bytes, 'RSA-OAEP');
    return forge.util.encode64(encrypted);
  }
}
