export const HEIGHT_TEMPLATE_DESKTOP_3 = 354;

export const HEIGHT_TEMPLATE_MOBILE_3 = 641;

export const HEIGHT_TEMPLATE_MOBILE_4 = 400;

export const HEIGHT_RESIZE_FULL_BACKGROUND = 556;

export const HEIGHT_RESIZE_MOBILE_3 = 668;

export const RESIZE_PERCENT_HEIGHT = 0.95;

export const FULL_BACKGROUND_TEMPLATES = [
  'Mobile_Template_1',
  'Mobile_Template_2',
  'Mobile_Template_3',
  'Web_Template_1',
  'Web_Template_2',
  'Web_Template_3'
];

export const BOX_TEMPLATES = [];

export const DARK_BACKGROUND_TEMPLATES = [];

export const NO_RESIZE_HEIGHT_TEMPLATES = [
  'Web_Template_1',
  'Web_Template_2',
  'Web_Template_3',
];

export const CENTERED_BACKGROUND_TEMPLATES = [
  'Mobile_Template_1',
  'Mobile_Template_2',
  'Mobile_Template_3'
];

export const SMALL_MOBILE_TEMPLATES = [];

export const MODAL_TYPE_VENTAS = 'VENTAS';
export const MODAL_ORIGIN_RUO = 'RUO';
