import { Component, HostListener, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MOBILE_BREAKPOINT } from '@common/utils/utils.constants';
import { DESKTOP_PLATFORM_TYPE, MOBILE_PLATFORM_TYPE, MODAL_PRIMARY_ACTION, MODAL_SECONDARY_ACTION } from '@common/constants/misc.constants';

@Component({
  selector: 'app-generic-rpass-modal',
  templateUrl: './generic-rpass-modal.component.html',
  styleUrls: ['./generic-rpass-modal.component.scss'],
})
export class GenericRpassModalComponent {
  @Input() public title: string = '';
  @Input() public message: string = '';
  @Input() public primaryButtonText: string = '';
  @Input() public secondaryButtonText: string = '';
  @Input() public icon: string = '';

  public screenType: string = window.innerWidth <= MOBILE_BREAKPOINT ? MOBILE_PLATFORM_TYPE : DESKTOP_PLATFORM_TYPE;
  constructor(
    private viewController: ModalController,
  ) { }

  @HostListener('window:resize', ['$event'])
  public onWindowResize() {
    this.screenType = window.innerWidth <= MOBILE_BREAKPOINT ? MOBILE_PLATFORM_TYPE : DESKTOP_PLATFORM_TYPE;
  }

  public get isDesktop() { return this.screenType === DESKTOP_PLATFORM_TYPE; }

  public get isMobile() { return this.screenType === MOBILE_PLATFORM_TYPE; }

  public dismissModal() {
    this.viewController.dismiss();
  }

  public onPrimaryClick() {
    this.viewController.dismiss(MODAL_PRIMARY_ACTION);
  }

  public onSecondaryClick() {
    this.viewController.dismiss(MODAL_SECONDARY_ACTION);
  }
}