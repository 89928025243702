import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BIOMETRIC_RPASS_AUTH_ERROR_MODAL_PROPS, PIN_RPASS_AUTH_ERROR_MODAL_PROPS, DEFAULT_RPASS_AUTH_ERROR_MODAL_PROPS } from '@common/constants/modal-props.constants';
import { HOME_ROUTE } from '@common/constants/routes.constants';
import { BIOMETRIC_RPASS_AUTH_CODES, BIOMETRIC_RPASS_RESULT_CODES, FACTOR_KEYS, PIN_RPASS_MAX_LENGTH, USE_RPASS_GA4 } from '@common/constants/digital-auth.constants';
import { MODAL_PRIMARY_ACTION } from "@common/constants/misc.constants";
import { AuthorizationStatus, IAuthTransactionData } from '@common/interfaces/digital-auth.interface';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { ModalController } from '@ionic/angular';
import { BiometricService } from '@services/biometric/biometric.service';
import { UtilsService } from '@services/utils/utils';
import { ForgottenRpassModalComponent } from '@components/forgotten-rpass-modal/forgotten-rpass-modal.component';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { DigitalAuthService } from '@services/digital-auth/digital-auth.service';
import { IDNOW_SERVICES_DATA } from '@common/constants/digital-auth-form.constants';
import { DeviceService } from '@services/device/device.service';
import { IdnowService } from '@services/idnow/idnow.service';

@Component({
  selector: 'app-r-pass-authentication',
  templateUrl: './r-pass-authentication.component.html',
  styleUrls: ['./r-pass-authentication.component.scss'],
})
export class RPassAuthenticationComponent {
  @Input() public didIdNowSecuritySuiteMethodsFailed: boolean;
  @Input() public isInputValid: boolean;
  @Input() public factorRPass: string = '';
  @Input() public oAuthToken: string;
  @Input() public transactionData: IAuthTransactionData;
  @Output() public didIdNowSecuritySuiteMethodsFailedChange = new EventEmitter<boolean>();
  @Output() public isInputValidChange = new EventEmitter<boolean>();

  public passwords: string[];
  public tried = 1;
  public channelOs = 'ANDROID';
  public isInvalidPinMatching: boolean;
  private transactionStatus: AuthorizationStatus;

  constructor(
    private utils: UtilsService,
    private modalController: ModalController,
    private biometricService : BiometricService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private digitalAuthService: DigitalAuthService,
    private deviceService: DeviceService,
    private idnowService:IdnowService
  ) {
    this.channelOs = this.utils.getDeviceOS().toUpperCase();
    this.passwords = [];
    this.transactionStatus = 'pendingAuthorization';
    this.factorRPass = '';
    this.isInvalidPinMatching = false;
  }

  public get isBiometricFactor(): boolean { 
    return this.factorRPass === FACTOR_KEYS.biometric || this.factorRPass === FACTOR_KEYS.finger || this.factorRPass === FACTOR_KEYS.face 
  }

  public onKey(event, index: number): void {
    this.validateInput();
    const { target }: { target: HTMLInputElement } = event;
    let nextElementIndex: number;
    if (event.keyCode === 8 || event.keyCode === 229) {
      if (index === 0) { this.passwords = []; return; ; } else { this.isInvalidPinMatching = false; this.passwords = []; nextElementIndex = 1; }
    } else {
      if (index === 5) { target.blur(); return; } else { nextElementIndex = index + 2; }
    }
    const nextElement = target.parentElement.parentElement
      .querySelector(`div.code-box:nth-of-type(${nextElementIndex}) input`) as HTMLInputElement;
    nextElement.focus();
  }

  public async validateTransaction(tried: number): Promise<any> {
    this.tried = tried;
    if(this.isBiometricFactor){
      this.googleAnalyticsService.pushGAEventTracker(USE_RPASS_GA4.MAIN_AUTH.STEP_BIOMETRIC);
      const biometricAuthResult = await this.biometricService.showRPassDialog();
      console.log(`${this.factorRPass} result: `, { biometricAuthResult , tried });
      const biometricAuthCodes = BIOMETRIC_RPASS_AUTH_CODES[this.factorRPass];
      this.transactionStatus = biometricAuthCodes[biometricAuthResult.code] || 'pendingServiceRejection';
      if(biometricAuthResult.code != BIOMETRIC_RPASS_RESULT_CODES.SUCESSFUL && biometricAuthResult.code !=  BIOMETRIC_RPASS_RESULT_CODES.DISMISSED){
        const biometricAuthErrorProps = BIOMETRIC_RPASS_AUTH_ERROR_MODAL_PROPS[this.factorRPass];
        const modalProps = biometricAuthErrorProps[biometricAuthResult.code] || DEFAULT_RPASS_AUTH_ERROR_MODAL_PROPS;
        await new Promise(async (resolve) => {
          const modalAction = () => resolve(true);
          await this.showErrorModal(modalProps, modalAction);
       });
      }
    } else { 
      await this.compareSecureData(this.passwords.join('-'));
    }
    return this.transactionStatus;
  }

  public async showErrorModal(modalProps, modalAction?) {
    const modal = await this.modalController.create({
        component: GenericModalComponent,
        cssClass: 'rpass-activation-modal',
        componentProps: modalProps,
        backdropDismiss: false
    });
    await modal.present();
    await modal.onDidDismiss().then(() => {
      return modalAction ? modalAction() : this.router.navigate([HOME_ROUTE], { replaceUrl: true });
    });
  }

  public async openForgotRPassModal() {
    this.googleAnalyticsService.pushGAEventTracker(USE_RPASS_GA4.MAIN_AUTH.STEP_FORGOT);
    const modal = await this.modalController.create({
      component: ForgottenRpassModalComponent,
      cssClass: 'rpass-forgotten-modal',
      backdropDismiss: false
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data === MODAL_PRIMARY_ACTION) {
      this.getTrx().then(() => {
        this.rejectTransaction();
      }).catch(() => {
        this.rejectTransaction();
      });
    }
  }

  private async getTrx(): Promise<void> {
    await this.getUserAndDeviceData();
    await this.getDeviceStatusRequest();
  }

  private async compareSecureData(pinValue: any) {
    await new Promise((resolve) => {
      window['idNowSecuritySuite'].compareSecureData(pinValue, FACTOR_KEYS.pin, 'secret', async (response) => {
        console.log('compareSecureDataResponse: ', response);
        response = JSON.parse(response);
        if (response) {
          this.transactionStatus = 'pendingServiceAuthorization';
          this.isInvalidPinMatching = false;
        } else {
          this.transactionStatus = 'pending';
          this.isInvalidPinMatching = true;
          if (this.tried > 2) {
            await new Promise(async (resolve) => {
              const modalAction = () => resolve(true);
              await this.showErrorModal(PIN_RPASS_AUTH_ERROR_MODAL_PROPS, modalAction);
            });
          }
        }
        resolve(true);
      }, async (err) => {
        console.error('compareSecureData', err);
        this.transactionStatus = 'pending';
        this.didIdNowSecuritySuiteMethodsFailedChange.emit(true);
        await new Promise(async (resolve) => {
          const modalAction = () => resolve(true);
          this.showErrorModal(DEFAULT_RPASS_AUTH_ERROR_MODAL_PROPS, modalAction);
        });
        resolve(false);
      });
    });
  }

  private validateInput() {
    this.isInputValid = this.passwords.length === PIN_RPASS_MAX_LENGTH;
    for (const password of this.passwords) {
      if (!password || password.length < 1) { this.isInputValid = false; }
    }
    this.isInputValidChange.emit(this.isInputValid);
  }

  private async getUserAndDeviceData(): Promise<void> {
    const { appId, userType, enterpriseCode, country, channel } = IDNOW_SERVICES_DATA;
    const userAndDeviceData = {
      appId,
      forwardedIp: (await this.deviceService.getIP()).ip,
      userAgent: navigator.userAgent,
      userType,
      enterpriseCode,
      country,
      channel
    };
    this.transactionData = { ...this.transactionData, ...userAndDeviceData };
  }

  private async getDeviceStatusRequest() {
    const { userId } = this.transactionData;
    const deviceStatusSdkData = await this.idnowService.getDeviceStatusSDK(userId);
    this.transactionData.uuidDevice = deviceStatusSdkData.uuidDevice;
  }

  private rejectTransaction(): void {
    this.digitalAuthService.rejectTransaction(this.transactionData, this.oAuthToken).subscribe();
  }
}
