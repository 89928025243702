export const TC_NO_ASSOCIATED = 715;
export const TC_UNREGISTERED = 716;
export const SESSION_DOESNT_EXIST = 3;
export const OTP_MAX_ATTEMPTS = 16;
export const OTP_CODE_ERROR = 630;
export const ERROR_QUESTION_REGISTER = 30;
export const ID_QUESTIONS_NOT_FOUND = 31;
export const ID_QUOTA_NOT_FOUND = 32;
export const ERROR_FOR_PAY = 35;
export const AMOUNT_LESS_THAN_FIVEHUNDRED  = 36;
export const RESCHEDULING_SCHEDULE  = 40;
export const RESCHEDULING_SMS_ERROR_CODE  = 750;
export const RESCHEDULING_CODE_PRODUCT = '006';
export const RESCHEDULING_SEF_CODE_PRODUCT = '019';
export const RESCHEDULING_SMS_ERROR_GENERAL  = 'Error al procesar la reprogramación.';
export const RESCHEDULING_OTP_ERROR = 'El código no es correcto';

export const SESSION_DOESNT_EXIST_PROPS = {
    title: '¡Ups!',
    message: 'No existe la sesión consultada.',
    primaryButtonText: 'Aceptar',
    icon: 'i-alert-purple'
};

export const ERROR_QUESTION_REGISTER_PROPS = {
    title: '¡Ups!',
    message: 'Error al registrar resultados de la encuesta.',
    primaryButtonText: 'Aceptar',
    icon: 'i-alert-purple'
};

export const ID_QUESTIONS_NOT_FOUND_PROPS = {
    title: '¡Ups!',
    message: 'No se encontró los id de las preguntas y/o respuestas.',
    primaryButtonText: 'Aceptar',
    icon: 'i-alert-purple'
};

export const ID_QUOTA_NOT_FOUND_PROPS = {
    title: '¡Ups!',
    message: 'No existe el id de la cuota',
    primaryButtonText: 'Aceptar',
    icon: 'i-alert-purple'
};

export const TC_NO_ASSOCIATED_PROPS = {
    title: '¡Ups!',
    message: 'No se encontraron registros de tarjetas de crédito asociadas a cuentas del cliente.',
    primaryButtonText: 'Aceptar',
    icon: 'i-alert-purple'
};

export const TC_UNREGISTERED_PROPS = {
    title: '¡Ups!',
    message: 'No se encontraron tarjetas de crédito registradas como titular.',
    primaryButtonText: 'Aceptar',
    icon: 'i-alert-purple'
};

export const ERROR_FOR_PAY_PROPS = {
    title: '¡Lo sentimos!',
    message: 'Por el momento no es posible realizar una reprogramación debido a que estamos procesando un abono realizado '
    + 'recientemente a tu tarjeta.',
    primaryButtonText: 'Salir',
    icon: 'paddingOff',
    extraClass: 'title-purple'
};

export const AMOUNT_LESS_THAN_FIVEHUNDRED_PROPS = {
    title: '¡Lo sentimos!',
    message: 'No es posible realizar una reprogramación debido a que tu deuda es menor al monto mínimo (S/ 500).',
    primaryButtonText: 'Salir',
    icon: 'paddingOff',
    extraClass: 'title-purple'
};

export const RESCHELUDING_WITHOUT_SALARY_PROPS = {
    title: '',
    message: 'Lo sentimos, por el momento no tenemos una facilidad de pago para ti.',
    primaryButtonText: 'Salir',
    icon: ''
};

export const RESCHEDULING_SCHEDULE_PROPS = {
    title: '¡Lo sentimos!',
    message: 'En estos momentos no es posible reprogramar tu deuda, vuelve a intentarlo entre las 8:00 y las 23:30 hrs por este canal.',
    primaryButtonText: 'Salir',
    icon: 'paddingOff',
    extraClass: 'title-purple'
};

export const RESCHEDULING_GENERAL_ERROR_PROPS = {
    title: '¡Lo sentimos!',
    message: 'Lo sentimos, en este momento no podemos realizar la operación. ' +
    'Contáctanos al <b>(01) 611-5757</b>, un asesor te ayudará a finalizar el proceso de reprogramación.',
    primaryButtonText: 'Cerrar',
    icon: 'paddingOff',
    extraClass: 'title-purple'
};
