import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-toggle-item',
  templateUrl: './toggle-item.component.html',
  styleUrls: ['./toggle-item.component.scss'],
})
export class ToggleItemComponent {

  @Input() public label: string;
  @Input() public toggleControl: FormControl;
  @Input() public isDisabled: boolean;

  constructor() {
    this.label = '';
  }

  public onChange(newValue: boolean) {
    this.toggleControl.setValue(newValue);
  }

}
