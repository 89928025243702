import { Component, OnInit } from '@angular/core';
import { ACCOUNT_PLUS_CODE, TYPE_ACCOUNT_SELECTED } from '@common/constants/open-account.constants';
import { OP_MORE_INFORMATION_MODAL } from '@common/constants/tag/tag-open-account.constants';
import { ModalController } from '@ionic/angular';
import { AHORROPLUS_OPEN_MODAL_MORE_INFORMATION } from '@common/constants/tag/tag-ahorroplus.constants';

@Component({
  selector: 'app-pep-information',
  templateUrl: './pep-information.component.html',
  styleUrls: ['./pep-information.component.scss'],
})
export class PepInformationComponent implements OnInit {

  constructor(private viewController: ModalController) {

   }

  ngOnInit() {
    const typeAccount = sessionStorage.getItem(TYPE_ACCOUNT_SELECTED);
    if (typeAccount === ACCOUNT_PLUS_CODE) {
    }
  }

  dismissModal() {
    this.viewController.dismiss();
  }

  ionViewDidEnter() {
  }
}
