import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirm-insurance',
  templateUrl: './confirm-insurance.component.html',
  styleUrls: ['./confirm-insurance.component.scss'],
})
export class ConfirmInsuranceComponent implements OnInit {

  @Input() value: string;
  isLoading: boolean;
  
  constructor(
    private modalController: ModalController,
  ) {
    this.isLoading = false;
  }

  dismissModal(type = '') {
    type != '' ? this.modalController.dismiss(type) : this.modalController.dismiss('');
  }

  ngOnInit() {}

}