import { Subscription } from 'rxjs';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  EFEX_FEEDBACK_MAXIMUN_CHARACTERS, EFEX_QUALIFICATION_CODE
} from '@common/constants/efex.constants';
import { RIPLEY_PHONE, SCREEN_WIDTH } from '@common/constants/misc.constants';
import { EFEX_FAILED_MODAL_PROPS } from '@common/constants/modal-props.constants';
import { EFEX_CANCEL_FEEDBACK, EFEX_SEND_FEEDBACK } from '@common/constants/tag/tag-efex.constants';
import {
  IEfexCualificationListIN, IEfexCualificationOptionsOut, IEfexCualificationSave
} from '@common/interfaces/efex.interface';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { ModalController } from '@ionic/angular';
import { EfexService } from '@services/efex/efex.service';
import { UtilsService } from '@services/utils/utils';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})

export class FeedbackComponent implements OnInit, OnDestroy {
  @Input() dateEnd: string;
  @Input() primaryButtonText: string;
  @Input() secondaryButtonText: string;
  title: string;
  message: string;
  TextAreaPlaceHolder: string;
  efexList: string;
  comment: string;
  loading: boolean;
  showComments: boolean;
  itemSelected: boolean;
  validating: boolean;
  response: number;
  otherComments: FormControl;
  radioGroup: FormControl;
  subscription: Subscription;
  iEfexQualificationParam: IEfexCualificationSave;
  optionListOut: IEfexCualificationOptionsOut[];


  constructor(
    private viewController: ModalController,
    private efexServices: EfexService,
    private modalCtrl: ModalController,
    private utilsService: UtilsService,
  ) {
    this.loading = true;
    this.otherComments = new FormControl('', [Validators.required, Validators.maxLength(EFEX_FEEDBACK_MAXIMUN_CHARACTERS)]);
    this.radioGroup = new FormControl('', [Validators.required]);
    this.subscription = new Subscription();
    this.TextAreaPlaceHolder = 'Escríbenos para mejorar tu experiencia';
  }

  ngOnInit() {
    this.getFeedBackList();
    this.subscription.add(this.otherComments.valueChanges.subscribe(() => {
      if (this.otherComments.valid) {
        this.itemSelected = true;
      } else { this.itemSelected = false; }
    }));
  }

  async getFeedBackList() {
    const iEfexIn: IEfexCualificationListIN = {
      qualificationCode: EFEX_QUALIFICATION_CODE
    };
    try {
      const response = await this.efexServices.FeedbackList(iEfexIn);
      this.title = response.questions[0].title;
      this.message = response.questions[0].description;
      this.optionListOut = response.questions[0].options;
      this.loading = false;
    } catch (err) {
      this.dismissModal();
      await this.openErrorAlert();
    }
  }

  dismissModal() {
    this.viewController.dismiss();
  }

  async onPrimaryClick() {
    this.iEfexQualificationParam.detailInput = this.otherComments.value;
    try {
      this.itemSelected = false;
      this.validating = true;
      this.response = await this.efexServices.FeedSave(this.iEfexQualificationParam);
      this.dismissModal();
    } catch (err) {
      this.dismissModal();
      await this.openErrorAlert();
    }
  }

  onchange(item: IEfexCualificationOptionsOut) {
    if (item.value === 4) {
      this.showComments = true;
      this.itemSelected = false;
      this.otherComments.reset();
    } else {
      this.itemSelected = true;
      this.showComments = false;
    }
    this.comment = '';
    const itemValues: IEfexCualificationSave = {
        value: item.value,
        description: item.description,
        dateEnd: this.dateEnd,
        reference: window.location.href,
        detailInput: this.comment,
        utmCampaing: '',
        utmContent: '',
        utmMedium: '',
        utmSource: '',
    };
    this.iEfexQualificationParam = itemValues;
  }

  onSecondaryClick() {
    this.viewController.dismiss('secondaryButtonPressed');
  }

  get ripleyPhone() {
    return RIPLEY_PHONE;
  }

  get isMobile() {
    return window.innerWidth < SCREEN_WIDTH.TABLET;
  }

  public openPhoneNumber(phoneNumber: string) {
    if (this.isMobile) {
      document.location.href = `tel:${phoneNumber}`;
    }
  }

  async openErrorAlert() {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: EFEX_FAILED_MODAL_PROPS,
    });
    await modal.present();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
