import { Injectable } from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SwUpdateService {
    private updateSubject = new BehaviorSubject(null);
    public update$:Observable<any> = this.updateSubject.asObservable();
    constructor(
        private swUpdateService: SwUpdate,
    ) {}

    public initializeSwUpdate(){
        this.swUpdateService.available.pipe(
          map(event=>{
            if(event.current && event.available){
                return true;
            }
            return false;
          })
        ).subscribe(res=>{
            this.updateSubject.next(res);
        });
    }
}
