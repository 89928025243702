import { Injectable } from '@angular/core';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Platform } from '@ionic/angular';
import { UserService } from '@services/user/user.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { IGoogleTagManagerEvent } from '@common/interfaces/google-tag-manager.interface';

@Injectable({
  providedIn: 'root'
})
export class FirebaseLoggerService {
  private isNative: boolean;

  constructor(
    private firebaseAnalytics: FirebaseX,
    private platform: Platform,
    private userService: UserService,
    private firebaseService: FirebaseService,
  ) {
    this.isNative = this.platform.is('cordova');
  }

  public logEvent(tag: string, data: any = {}): void {
    if (this.userService.userInformation) { data.user = this.userService.userInformation.userId; }
    if (this.isNative) {
      data.origin = 'app-pwa';
      this.firebaseAnalytics.logEvent(tag, data)
        .catch((err) => console.error('FirebaseLogger Error:', err));
    } else {
      data.origin = 'web-pwa';
      this.firebaseService.logEvent(tag, data);
    }
  }

  public logEventFromTagItem(item: IGoogleTagManagerEvent) {
    const { event, additionalInformation, ...data } = item;
    const event_name = event.toLowerCase().trim().replace(/\s|-/g, '_');
    this.logEvent(event_name, data);
  }
}
