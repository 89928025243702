export const EXTERNAL_NOTIFICATION_TYPE = 'openLink';

export const INTERNAL_NOTIFICATION_TYPE = 'openApp';

export const FIRESTORE_PWA_TOKEN_KEY = 'tokenPWA';

export const FIRESTORE_APP_TOKEN_KEY = 'tokenApp';

export const FIREBASE_SW_PATH = '/firebase-messaging-sw.js';

export const GRANTED_BROWSER_NOTIFICATION_PERMISSION = 'granted';

export const DEFAULT_BROWSER_NOTIFICATION_PERMISSION = 'default';

export const NOTIFICATIONS_APP_REDIRECTIONS = {
    payments: 'home/payments',
    rpassAuth: 'rpass-auth'

};

export const FIRESTORE_APP_CURRENT_TOKEN_RPASS = 'currentTokenRpass';
