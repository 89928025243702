export const EFEX_PROGRESS_ADDITION = 0.20;
export const EFEX_PROGRESS_SUCCESS = 1;
export const EFEX_QUALIFICATION_CODE = 'DEFEX';
export const EFEX_FEEDBACK_MAXIMUN_CHARACTERS = 250;
export const EFEX_SECONDS = 59;
export const EFEX_MINUTES = 29;
export const EFEX_SCHEDULE = 16;

export const EFEX_REGISTER_GENERAL_ERROR = 620;
export const EFEX_REGISTER_MAX_RETRIES_ERROR = 630;
export const EFEX_SMS_MAX_RETRIES_ERROR = 632;
export const OFFER_INACTIVE = 622;
export const OFFER_AMOUNT_LIMIT_ERROR = 624;
export const LEAD_EXIST_ERROR = 660;
export const LEAD_EXIST_ERROR_V2 = 600;

export const EFEX_PROGRESS_DECLARATION = 0.60;
export const EFEX_PROGRESS_DETAIL = 0.80;
export const EFEX_PROGRESS_VOUCHER = 1.00;
