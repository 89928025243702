import { UtilsService } from './../utils/utils';
import * as firebase from 'firebase/app';

import { Injectable } from '@angular/core';
import { USER_INFO } from '@common/constants/auth.constants';
import { SEF_EFEX_ON_OFF_DOCUMENT_V2 } from '@common/constants/firebase.constants';
import { PRODUCT_EFEX, PRODUCT_SEF } from '@common/constants/offerings.constants';
import { ISefEfexOnOff, ISefEfexUser } from '@common/interfaces/firebase-on-off.interface';
import { ILoan, ILoanOutput, IOffering } from '@common/interfaces/offerings.interface';
import { OFFERINGS_MOCK, START_LOAN_MOCK } from '@common/mocks/offerings.mock';
import { environment } from '@environments/environment';
import { FirebaseService } from '@services/firebase/firebase.service';
import { HttpService } from '@services/http/http.service';
import { StorageService } from '@services/storage/storage.service';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OfferingsService {

    constructor(
        private http: HttpService,
        private firebaseService: FirebaseService,
        private storage: StorageService,
        private utilsService: UtilsService
    ) { }

    public getRuoOffers(): Promise<IOffering[]> {
        const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/ruooffers';
        return this.http.get(url, OFFERINGS_MOCK).toPromise();
    }

    public async getRuoOffersOnOff(): Promise<IOffering[]> {
        let ruoOffer = await this.getRuoOffers();
        const sefEfexParams = await this.firebaseService.getData(SEF_EFEX_ON_OFF_DOCUMENT_V2) as ISefEfexOnOff;
        if (!sefEfexParams) { return ruoOffer; }
        if (sefEfexParams.overRideService) { ruoOffer = this.overwriteParams(ruoOffer, sefEfexParams); }

        if (!this.utilsService.isNative() && sefEfexParams.enableWhiteList) { ruoOffer = await this.whitelistValidation(ruoOffer); }
        if (this.utilsService.isNative() && sefEfexParams.enableWhiteListApp) { ruoOffer = await this.whitelistValidation(ruoOffer); }

        ruoOffer.forEach(
            (offer) => {
                if (offer.codeProduct === PRODUCT_EFEX) {
                    if (!this.utilsService.isNative()) { offer.loansV1 = Boolean(offer.loansV1 || !sefEfexParams.efexEnabled); }
                    if (this.utilsService.isNative()) { offer.loansV1 = Boolean(offer.loansV1 || !sefEfexParams.efexEnabledApp); }
                }
                if (offer.codeProduct === PRODUCT_SEF) {
                    if (!this.utilsService.isNative()) { offer.loansV1 = Boolean(offer.loansV1 || !sefEfexParams.sefEnabled); }
                    if (this.utilsService.isNative()) { offer.loansV1 = Boolean(offer.loansV1 || !sefEfexParams.sefEnabledApp); }
                }
            }
        );

        return ruoOffer;
    }

    private async whitelistValidation(ruoOffer: IOffering[]): Promise<IOffering[]> {
        const userInfo = await this.storage.get(USER_INFO);
        const dniSearchFormat = userInfo.documentType + '-' + userInfo.documentNumber;
        const dniEnabled = await this.firebaseService.getUsersSefEfex(dniSearchFormat) as ISefEfexUser;

        ruoOffer.forEach(
            (offer) => {
                if (offer.codeProduct === PRODUCT_EFEX) {
                    offer.loansV1 = !!dniEnabled ? Boolean(offer.loansV1 && !!dniEnabled || !dniEnabled.efexEnabled) : true;
                }
                if (offer.codeProduct === PRODUCT_SEF) {
                    offer.loansV1 = !!dniEnabled ? Boolean(offer.loansV1 && !!dniEnabled || !dniEnabled.sefEnabled) : true;
                }
            }
        );
        return ruoOffer;
    }


    private overwriteParams(ruoOffer: IOffering[], sefEfexParams: ISefEfexOnOff): IOffering[] {
        const sef = sefEfexParams.sefAmount;
        const efex = sefEfexParams.efexAmount;

        ruoOffer.forEach(
            (offer) => {
                if (offer.codeProduct === PRODUCT_EFEX) {
                    offer.loansV1 = !this.betweenValues(offer.maximumAmount, efex.minAmount, efex.maxAmount);
                }
                if (offer.codeProduct === PRODUCT_SEF) {
                    offer.loansV1 = !this.betweenValues(offer.maximumAmount, sef.minAmount, sef.maxAmount);
                }
            }
        );
        return ruoOffer;
    }

    private betweenValues(value: number, valueMin: number, valueMax: number): boolean {
        return value >= valueMin && value <= valueMax;
    }

    public async loadOfferService(ruoOffer: IOffering[]) {
        const userInfo = await this.storage.get(USER_INFO);
        const dniSearchFormat = userInfo.documentType + '-' + userInfo.documentNumber;
        const hashToken = ''; // this.authService.currentToken.refreshToken;  TODO: hashear token con blake2
        this.firebaseService.addSefEfexServiceResponse(
            {
                token: hashToken,
                userId: dniSearchFormat,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                ...ruoOffer
            },
            'offeringResponses');
    }

    public startLoanRegister(loanSaveParams: ILoan): Promise<ILoanOutput> {
        const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/pre-registerV2';
        return this.http.post(url, loanSaveParams, START_LOAN_MOCK).toPromise();
    }

    public async loadCustomerOffersService(ruoOffer: IOffering[]) {
        const userInfo = await this.storage.get(USER_INFO);
        const dniSearchFormat = userInfo.documentType + '-' + userInfo.documentNumber;
        const sefOffer = ruoOffer.find(offer => offer.codeProduct === PRODUCT_SEF);
        const efexOffer = ruoOffer.find(offer => offer.codeProduct === PRODUCT_EFEX);
        this.firebaseService.addSefEfexServiceResponse(
            {
                userId: dniSearchFormat,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                sefAmount: sefOffer ? sefOffer.maximumAmount : null,
                efexAmount: efexOffer ? efexOffer.maximumAmount : null,
            },
            'customerOffersResponses');
    }
}
