import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  GOOGLE_TAG_MANAGER_ID, GOOGLE_TAG_MANAGER_ID_SECOND,   GA4_ID,
  IDLE_EXIT_AFTER_WARNING_TIMEOUT, IDLE_WARNING_TIMEOUT
} from '@common/constants/misc.constants';
import { AuthorizationInterceptor } from '@common/interceptors/authorization.interceptor';
import { EncryptionInterceptor } from '@common/interceptors/encryption.interceptor';
import { environment } from '@environments/environment';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { File } from '@ionic-native/file/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { FirebaseService } from '@services/firebase/firebase.service';
import { BiometricService } from '@services/biometric/biometric.service';
import { AccountManagerService } from '@services/account-manager/account-manager.service';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Calendar } from '@ionic-native/calendar/ngx';
import { UtilsService } from '@services/utils/utils';
import { LocationService } from '@services/location/location.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotificationsService } from '@services/notifications/notifications.service';
import { NotificationsServiceMock } from '@services/notifications/notifications.service.mocks';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Device } from '@ionic-native/device/ngx';
import { WelcomeCampaignModule } from '@components/welcome-campaign/welcome-campaign.module';
import { WaveModule } from '@components/wave/wave.module';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { ComponentsModule } from '@components/components.module';
import { HTTP } from '@ionic-native/http/ngx';
import { CloudFlareInterceptor } from '@common/interceptors/cloudflare.Interceptor';
import { CloudFlareService } from '@services/cloudflare/cloudflare.service';
import { RewardPointsService } from '@services/reward-points/reward-points.service';
import { Market } from '@ionic-native/market/ngx';
import { StorageService } from '@services/storage/storage.service';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { DigitalAuthService } from '@services/digital-auth/digital-auth.service';
import { LoginService } from '@services/login/login.service';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { AnalyticsUtilsService } from '@services/utils-analytics/utils-analytics.service';
import { LottieAnimationViewModule } from 'ng-lottie';
import { PayRipleyModule } from '@components/pay-ripley/pay-ripley.module';

import { ErrorInterceptor } from '@common/interceptors/error.interceptor';

import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Contacts } from '@ionic-native/contacts/ngx';
import {KeyboardService} from "@services/keyboard/keyboard.service";

import { GenericModalWidgetComponent } from '@components/generic-modal-widget/generic-modal-widget.component';
import { ModalWidgetService } from '@services/modal-widget/modal-widget.service';
import { SyneriseServiceService } from '@services/synerise/synerise-service.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    PayRipleyModule,
    ComponentsModule,
    BrowserModule,
    WaveModule,
    WelcomeCampaignModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    RecaptchaV3Module,
    LottieAnimationViewModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.ENV === 'production' || environment.ENV === 'qa' }),
  ],
  providers: [
    StatusBar,
    InAppBrowser,
    GenericModalWidgetComponent,
    Contacts,
    FileOpener,
    File,
    SplashScreen,
    FirebaseService,
    FirebaseX,
    AccountManagerService,
    BiometricService,
    FingerprintAIO,
    Geolocation,
    Device,
    UtilsService,
    Calendar,
    AppVersion,
    SocialSharing,
    LocationService,
    RewardPointsService,
    HTTP,
    StorageService,
    LocationAccuracy,
    Market,
    Diagnostic,
    OpenNativeSettings,
    LoginService,
    DigitalAuthService,
    BackgroundMode,
    GoogleAnalyticsService,
    AnalyticsUtilsService,
    KeyboardService,
    ModalWidgetService,
    SyneriseServiceService,
    CloudFlareService,
    {
      provide: APP_INITIALIZER,
      useFactory: (cloudFlareService: CloudFlareService) => async () => {
        try {
          const realConfig = await cloudFlareService.getRemoteConfig();
          environment.MAINTENANCE = realConfig.MAINTENANCE;
          environment.MAINTENANCE_MESSAGE = realConfig.MAINTENANCE_MESSAGE;
          const publicKey = await cloudFlareService.getRemotePublicKey();
          environment.PUBLIC_KEY_PEM = publicKey.PUBLIC_KEY;
        } catch (err) {
          environment.MAINTENANCE = true;
          console.log('Error fetching the remote config: ', err);
        }
      },
      deps: [CloudFlareService],
      multi: true
    },
    {
      provide: NotificationsService, useClass: environment.ENV !== 'local' ?
        NotificationsService : NotificationsServiceMock
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.RECAPTCHA_SITE_KEY
    },
    {
      provide: 'googleTagManagerId',
      useValue: GOOGLE_TAG_MANAGER_ID
    },
    {
      provide: 'googleTagManagerId_Second',
      useValue: GOOGLE_TAG_MANAGER_ID_SECOND
    },
    {
      provide: 'ga4_id',
      useValue: GA4_ID
    },
    {
      provide: 'idleConfig', useValue: {
        idle: IDLE_WARNING_TIMEOUT,
        timeout: IDLE_EXIT_AFTER_WARNING_TIMEOUT,
      }
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CloudFlareInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncryptionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },

  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor() {
    // Do nothing
  }
}
