import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SIMPLE_BENEFITS, PLUS_BENEFITS, LEGAL_URL, SAVINGS_ACCOUNTS_TYPES, CTS_BENEFITS, AFP_BENEFITS, NEW_BENEFITS_PLUS } from '@common/constants/saving-benefits';
import { ListModalComponent } from '@components/list-modal/list-modal.component';
import { ACCOUNT_AFP_CODE, ACCOUNT_VIP_CODE, RIPLEY_BANK_SALARY_ACCOUNT_CODE } from '@common/constants/open-account.constants';

@Component({
  selector: 'app-saving-account-details-header',
  templateUrl: './saving-account-details-header.component.html',
  styleUrls: ['./saving-account-details-header.component.scss'],
})
export class SavingAccountDetailsHeaderComponent {
  @Input() headerLabel: string;
  @Input() availableAmount: string;
  @Input() availableLabel: string;
  @Input() totalAmount: string;
  @Input() totalLabel: string;
  @Input() cardNumber: string;
  @Input() cci: string;
  @Input() iconClass: string;
  @Input() benefits: string;

  constructor(
    private modalCtrl: ModalController
  ) { }

  get formattedCopyText() {
    return this.headerLabel + '\n' + this.cardNumber + '\n' + this.cci;
  }

  openLegalURL() {
    window.open(LEGAL_URL, '_blank');
  }

  public async showMovementDetailModal() {
    const props = {
      title: 'Tus beneficios',
      productName: this.headerLabel,
      benefits: [],
      benefitsType: this.benefits,
    };
    const productTypes = [RIPLEY_BANK_SALARY_ACCOUNT_CODE, ACCOUNT_VIP_CODE, ACCOUNT_AFP_CODE];
    if (productTypes.includes(this.benefits)) {
      props.benefits = this.benefits === ACCOUNT_AFP_CODE ? AFP_BENEFITS : NEW_BENEFITS_PLUS;
    } else {
      switch (this.benefits) {
        case SAVINGS_ACCOUNTS_TYPES.SIMPLE: {
          props.benefits = SIMPLE_BENEFITS;
          break;
        }
        case SAVINGS_ACCOUNTS_TYPES.PLUS: {
          props.benefits = PLUS_BENEFITS;
          break;
        }
        case SAVINGS_ACCOUNTS_TYPES.CTS: {
          props.benefits = CTS_BENEFITS;
          break;
        }
      }
    }
    const modal = await this.modalCtrl.create({
      component: ListModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }
}
