import { Component, Input } from '@angular/core';
import { PRODUCT_TYPE_MAPPING } from '@common/constants/open-account.constants';
import { ModalController } from '@ionic/angular';
import { SavingsService } from '@services/savings/savings.service';

@Component({
  selector: 'app-list-modal',
  templateUrl: './list-modal.component.html',
  styleUrls: ['./list-modal.component.scss'],
})
export class ListModalComponent {
  @Input() title: string;
  @Input() benefits;
  @Input() productName: string;
  @Input() benefitsType: string;
  constructor(
    private modalController: ModalController,
    private savingsService: SavingsService
  ) { }

  dismissModal() {
    this.modalController.dismiss();
  }

  async getRatesURL() {    
    const type = this.savingsService.getTypeCode(PRODUCT_TYPE_MAPPING, this.benefitsType, this.benefitsType);
    const res = await this.savingsService.getRatesURL(type);
    window.open(res.value, '_blank');
  }
}
