import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RipleyStore } from '@common/models/store-product';
import { LOTTIE_CONFIG } from '@common/utils/utils.constants';
import { ModalController } from '@ionic/angular';
import { Location } from '@angular/common';

@Component({
  selector: 'app-branch-store',
  templateUrl: './branch-store.component.html',
  styleUrls: ['./branch-store.component.scss'],
})
export class BranchStoreComponent {

  @Input() public branchList: RipleyStore[];
  @Input() public firstAgency: boolean;
  @Input() public firstBranch: RipleyStore;
  @Input() public firstBranchDistance: string;
  @Input() public remainingBranchDistances: string[];
  @Input() public title: string;
  @Input() public firstBranchLabel: string;
  @Input() public branchIcon: string;
  @Input() public remainingBranchLabel: string;
  @Input() public withAddress: boolean;
  @Input() public withMenu: boolean;
  @Input() public withHeader: boolean;
  @Input() public distance: boolean;
  @Input() public isLoading: boolean;
  @Output() public clickedBranch: EventEmitter<RipleyStore>;
  public readonly lottieConfig: object;
  public readonly lottieHeight: number;
  public readonly lottieWidth: number;
  constructor(
    private modalController: ModalController,
    private location: Location
  ) {
    this.branchList = [];
    this.withAddress = false;
    this.withMenu = false;
    this.withHeader = false;
    this.isLoading = false;
    this.isLoading = true;
    this.title = 'Selecciona Tienda';
    this.firstBranchLabel = 'Tienda mÃƒÂ¡s cercana';
    this.remainingBranchLabel = 'Otras tiendas';
    this.branchIcon = 'i-shop-purple';
    this.lottieConfig = LOTTIE_CONFIG;
    this.lottieHeight = LOTTIE_CONFIG.sizes.big.height;
    this.lottieWidth = LOTTIE_CONFIG.sizes.big.width;
    this.clickedBranch = new EventEmitter();
  }

  public clickBranch(branch: RipleyStore) {
    this.clickedBranch.emit(branch);
  }

  public goBackModal(): void {
    this.location.back();
    this.modalController.dismiss({ backButton: true });
  }
}