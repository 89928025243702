import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  ICommonResponse, IOtpValidateInformation,
  IOtpValidateInformationRedeem, IOtpValidateRedeemResponse, IResponseSendSms,
  ICommonResponseBack,IOtpValidateInformationRedeemBack
} from '@common/interfaces/otp.interface';
import { IHashInformation } from '@common/interfaces/general.interface';
import { HttpService } from '@services/http/http.service';
import { SEND_OTP_MOCK, SEND_REDEEM_OTP_MOCK, VALIDATE_OTP } from '@common/mocks/otp.mocks';
import { IOtpEnrollmentInformation } from '@common/interfaces/otp.interface';
import { HttpHeaders } from '@angular/common/http';
import { UtilsService } from '@services/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  constructor(
    private http: HttpService,
    private utilsService: UtilsService
    ) { }

  public async sendOTPEnrollment(enrollmentInformation: IOtpEnrollmentInformation): Promise<any> {
    const url = environment.BASE_BACKEND_WORKER + '/enrollment/accountuserhb/SendOTP';
    return this.http.post(url, enrollmentInformation, SEND_OTP_MOCK).toPromise();
  }

  public async sendOTPIncrementLine(): Promise<any> {
    const url = environment.BASE_BACKEND_WORKER + '/hbk/promotion/incrementlinehb/sendOTP';
    return this.http.post(url, {}, SEND_OTP_MOCK).toPromise();
  }

  public async sendOTPEfex(hashInformation: IHashInformation): Promise<IResponseSendSms> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/efex/send-smsV2';
    return this.http.post(url, hashInformation, SEND_OTP_MOCK).toPromise();
  }

  public async validateOTP(validateInformation: IOtpValidateInformation): Promise<any> {
    const url = environment.BASE_BACKEND_WORKER + '/enrollment/accountuserhb/ValidateOTP';
    let headers = {} as HttpHeaders;
    headers = new HttpHeaders().set('Channel', this.utilsService.getChannelCode());
    return await this.http.post(url, validateInformation, VALIDATE_OTP, headers).toPromise();
  }

  public async sentOTPRedeem(enrollmentInformation: IOtpEnrollmentInformation): Promise<IOtpValidateRedeemResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/benefits/customer/sendOTP';
    let headers = {} as HttpHeaders;
    headers = new HttpHeaders().set('Channel', this.utilsService.getChannelCode());
    return await this.http.post(url, enrollmentInformation, SEND_REDEEM_OTP_MOCK, headers).toPromise();
  }

  public async validateOTPRedeem(validateInformation: IOtpValidateInformationRedeem): Promise<ICommonResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/benefits/customer/validateOTP';
    let headers = {} as HttpHeaders;
    headers = new HttpHeaders().set('Channel', this.utilsService.getChannelCode());
    return await this.http.post(url, validateInformation, VALIDATE_OTP, headers).toPromise();
  }
 public async validateOTPRedeemBack(validateInformation: IOtpValidateInformationRedeemBack): Promise<ICommonResponseBack> {
    const url = environment.BASE_BACKEND_WORKER + '/benefits/customer/validateOTPRedeem';
    let headers = {} as HttpHeaders;
    headers = new HttpHeaders().set('Channel', this.utilsService.getChannelCode());
    return await this.http.post(url, validateInformation, VALIDATE_OTP, headers).toPromise();
 }    
}
