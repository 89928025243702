import { Subscription } from 'rxjs';

import { Component, Input, OnInit, } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LOTTIE_CONFIG } from '@services/utils/utils';

@Component({
  selector: 'app-generic-modal-wait',
  templateUrl: './generic-modal-wait.component.html',
  styleUrls: ['./generic-modal-wait.component.scss'],
})
export class GenericModalWaitComponent implements OnInit{
    
  subscription: Subscription;
  fadeOut: boolean;
  isActiveModal: boolean;
  hide: boolean;
  fromRoute;
  @Input() title: string;
  @Input() message: string;
  public lottieConfig: object = LOTTIE_CONFIG;
  public lottieHeight: number = LOTTIE_CONFIG.sizes.big.height;
  public lottieWidth: number = LOTTIE_CONFIG.sizes.big.width;
  
  constructor(
    private viewController: ModalController,
  ) {
    this.title =  '';
    this.message = '';
  }

  ngOnInit() {

  }

  dismissModal() {
    this.viewController.dismiss();
    const second = 300;
    this.fadeOut = true;
    setTimeout(() => {
      this.hide = true;
    }, second);
    this.isActiveModal = false;
  }

}
