import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
    selector: '[appNumericOnlyIon]'
})
export class NumericOnlyIonDirective {

    @Input('appNumericOnlyIon') formGroup: FormGroup;
    element: HTMLInputElement;

    constructor(
        private el: ElementRef
      ) {
        this.element = this.el.nativeElement;
      }
    

    @HostListener('input', ['$event']) onInput(event: Event) {
        const input = event.target as HTMLInputElement;
        const value = this.element.value;
        const formattedValue = value.replace(/\D/g, '');
        this.element.value = formattedValue;    
    }
}
