import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {IFinantialInstitutionDetails, IInfoDetailTransfer, ITransferGetTokenWidgetInteropResponse} from '@common/interfaces/transfers.interface';
import { EMAIL_REGEX } from '@common/constants/misc.constants';
import { ModalController } from '@ionic/angular';
import {
  ACCOUNT_NUMBER_MAX_LENGTH,
  ACCOUNT_NUMBER_MIN_LENGTH,
  INTEROP_SUCCESS,
  MAX_TRANSFER_INTEROP_AMOUNT_PEN,
  PEN_BASE_CURRENCY,
  PEN_CODE_CURRENCY,
  PEN_SEGMENT,
  POSITIVE_DECIMAL_NUMBER_PATTERN,
  STATUS_CODE,
  TRANSFER_FLOW,
  TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_PEN,
  TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_USD,
  TRANSFER_MAX_AMOUNT_INTEROP_CODE_APP_PEN,
  TRANSFER_MIN_AMOUNT,
  TRANSFER_PROGRESS_ADDITION,
  TRANSFER_SERVICE_INFO,
  USD_BASE_CURRENCY,
  USD_SEGMENT
} from '@common/constants/transfers.constants';
import { Router } from '@angular/router';
import { TransferService } from '@services/transfer/transfer.service';
import { UtilsService } from '@services/utils/utils';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { ISegmentSelector } from '@common/interfaces/default.interface';
import { IDetailsSavingAccount} from '@common/interfaces/savings.interface';
import { PeruvianCurrencyPipe } from '@pipes/peruvian-currency/peruvian-currency.pipe';
import { CurrencyPipe } from '@angular/common';
import { throwError } from 'rxjs';
import { TransferErrorModalComponent } from '@components/transfer-error-modal/transfer-error-modal.component';
import { TRANSFER_ERROR_GENERAL } from '@common/constants/modal-props.constants';
import { FirebaseService } from '@services/firebase/firebase.service';
import { ISelectSearch } from '@common/interfaces/control.interface';



@Component({
  selector: 'app-set-destinatary-account',
  templateUrl: './set-destinatary-account.component.html',
  styleUrls: ['./set-destinatary-account.component.scss'],
})
export class SetDestinataryAccountComponent implements OnInit {

  contactNumberLength: number;
  transferMaxAmount: number;
  selectedAmountAccount: number;
  maxTransferImmediateAmountPEN: number;
  maxTransferImmediateAmountUSD: number;
  maxTransferInteropAmountPEN: number; 
  isSearchTextChange:boolean;
  isMaxAmountValidAmount: boolean;
  isOtherMobileNumber: boolean;
  isValidNumber: boolean;
  isFinantialEntitySelected: boolean;
  isVisibleSearchList: boolean;
  isSubStepOne: boolean
  isSubStepTwo: boolean;
  isValidData:boolean;
  isGetWidgetTokenError:boolean;
  isWidgetLoading:boolean;
  inputMessageTest:string;
  finantialEntitySelected:string;
  errorTransferMessage:string;
  emailVoucher:string;
  firebaseDocument: any;
  contactForm: FormGroup;  
  selectDataList: ISelectSearch[];
  savingAccountsInformation: IDetailsSavingAccount[];
  finantialInstitutionDetail: IFinantialInstitutionDetails[];
  @Input() currentSubStep: number;
  @Input() infoDetailsTransfer: IInfoDetailTransfer;
  @Output() setDataTransferEvent = new EventEmitter<{info:IInfoDetailTransfer,substep:number}>();
  @ViewChild('select') someSelect: ElementRef;
  
  constructor(
    private modalController: ModalController,
    private modalErrorCtrl: ModalController,
    private transferService: TransferService,
    private formBuilder: FormBuilder,
    private peruvianCurrency: PeruvianCurrencyPipe,
    private currencyPipe: CurrencyPipe,
    private googleAnalyticsService: GoogleAnalyticsService,
    private firebaseService: FirebaseService
  ) {    
    this.initialize();
 }

 get percentageProgress(): number { return TRANSFER_PROGRESS_ADDITION; }
 get peruvianCurrencySegment(): ISegmentSelector { return PEN_SEGMENT; }
 get dollarsCurrencySegment(): ISegmentSelector { return USD_SEGMENT; }
 get transferMinAmount(): number { return TRANSFER_MIN_AMOUNT; } 
 get originAccount() { return this.contactForm.controls.originAccount; }
 get description() { return this.contactForm.controls.description; }
 get baseCurrency() { return this.contactForm.controls.baseCurrency; }
 get amount() { return this.contactForm.controls.amount; }
 get email() { return this.contactForm.controls.email; }
 set email(value) { this.contactForm.controls.email.setValue(value);}
 set destinationAccount(value) { this.contactForm.controls.inputDestinationAccount.setValue(value); }
 
 get isFirstSubStep():boolean {return this.currentSubStep == 1;}
 get isSecondSubStep():boolean {return this.currentSubStep == 2;}

 
 get isValidAmount(): boolean {
  if ((this.amount.valid || this.errorTransferMessage==='') && ((this.amount.value <= this.selectedAmountAccount) 
  || this.selectedAmountAccount<0)) {    
        return true;
  }
  return false;   
 }

 get isValidSubStepOne(): boolean {
   return this.infoDetailsTransfer!==undefined?(this.infoDetailsTransfer.finantialInstitution!==''?true:false):false;
 }

 get isValidSubStepTwo(): boolean {
  if(this.infoDetailsTransfer!=undefined){
    return (this.infoDetailsTransfer.originAccount!=='') &&
    (this.infoDetailsTransfer.transferAmount!==""?
    (Number.parseFloat(this.infoDetailsTransfer.transferAmount)>0
    && Number.parseFloat(this.infoDetailsTransfer.transferAmount)>=1):false) &&
    (this.infoDetailsTransfer.email.trim()!='')?true:false;
  }
  return false;
 }
 get formattedAmount() {
  return this.formatAmount(this.amount.value);
 }

 get selectedCurrencySymbol(): string {
  return this.baseCurrency.value === PEN_BASE_CURRENCY ? PEN_SEGMENT.symbol : USD_SEGMENT.symbol;
 }

 get selectDataListAux():ISelectSearch[]{
  if(this.infoDetailsTransfer!=undefined && this.selectDataList.length==0){
    this.infoDetailsTransfer.finantialInstitutionList.forEach((item)=>{     
      let selectData: ISelectSearch = {
        code:item.receiverBankCode,
        name:item.receiverBankName,
        shortName:item.receiverBankShortName
      }
      this.selectDataList.push(selectData);
     });      
  } 
  return this.selectDataList;
 }

  initialize(){
    this.maxTransferInteropAmountPEN = MAX_TRANSFER_INTEROP_AMOUNT_PEN;
    this.transferMaxAmount = TRANSFER_MIN_AMOUNT;
    this.savingAccountsInformation = [];    
    this.finantialInstitutionDetail = [];
    this.selectDataList = [];
    this.selectedAmountAccount = -1;
    this.isMaxAmountValidAmount = true;
    this.isSearchTextChange = false;
    this.isVisibleSearchList = false;
    this.isValidData = false;
    this.isSubStepOne = true;
    this.isSubStepTwo = false;
    this.isWidgetLoading = false;
    this.isGetWidgetTokenError = false;
    this.errorTransferMessage = '';    
   
  }

  async ngOnInit() {
    this.createContactForm();
    this.setControlsValue();
  }

  async ngAfterViewInit() {
    try {      
        await this.setMaxTransfersAmmount(); 
    } catch (e) {
      console.error(e);
      this.maxTransferInteropAmountPEN = MAX_TRANSFER_INTEROP_AMOUNT_PEN;    
    }
  }
  ionViewWillEnter() { 
    UtilsService.showRecaptchaBadge();
  }

  ionViewWillLeave() {
    UtilsService.hideRecaptchaBadge();
  }
  
  private createContactForm() {
    this.contactForm = this.formBuilder.group({  
      baseCurrency: [this.peruvianCurrencySegment.value, Validators.required],
      amount: [null, this.amountValidators],
      originAccount: [null, [
        Validators.required,
        Validators.minLength(ACCOUNT_NUMBER_MIN_LENGTH),
        Validators.maxLength(ACCOUNT_NUMBER_MAX_LENGTH),
      ]],
      description: [null],
      inputDestinationAccount: [null],
      email: [
        { value: null, disabled: false },
        [Validators.required, Validators.pattern(EMAIL_REGEX)]
      ],
    });    
  
    this.subscribeToOriginAccount();
    this.subscribeToChangeAmount();
    this.subscribeToDescription();
    this.subscribeToTransferAmount();
    this.subscribeToTransferEmail();    
    //this.EventListenerDomInit(); 
  }

  private setControlsValue(){
    if(this.infoDetailsTransfer !== undefined){
      this.finantialInstitutionDetail = this.infoDetailsTransfer.finantialInstitutionList;   
      this.email.setValue(this.infoDetailsTransfer.email);   
    }  
  }
  private get amountValidators(): ValidatorFn[] {
      return [
        Validators.required,
        Validators.min(this.transferMinAmount),
        Validators.max(this.transferMaxAmount),
        Validators.pattern(POSITIVE_DECIMAL_NUMBER_PATTERN),
      ];
  }


  private subscribeToOriginAccount() {
    this.originAccount.valueChanges.subscribe((accountNumberSelected: string) => {
      const originAccount = this.accountDetail(accountNumberSelected);  
      if (originAccount) {
       this.transferMaxAmount = this.maxTransferInteropAmountPEN;
       this.selectedAmountAccount = originAccount.availableAmount;
       this.infoDetailsTransfer.originAccount = originAccount.account;
       this.infoDetailsTransfer.originAccountName = originAccount.productName;
       this.changeCurrency(this.getValueBaseCurrencyConst(originAccount.symbolCurrency)); 
       this.updateTransferMaxAmount();
      
      }
    });    
  }

  public AccountSelected(account: string) {
    if (account != null) {
      const origin = this.accountDetail(account);
         
      
      /*if (!this.transferForm.controls[LABEL_FORM_OWN_ACCOUNT].disabled) {
        this.notCTS = origin.benefits !== CTS_CODE;
        this.transferForm.controls[LABEL_FORM_OWN_ACCOUNT].setValue(true);
      }*/
    }
  }


  private subscribeToChangeAmount() {
    this.amount.valueChanges.subscribe((amountChanged: string) => {
      
    });
  }

  private subscribeToTransferAmount(){
    this.amount.valueChanges.subscribe((amount)=> {
      this.infoDetailsTransfer.transferAmount = amount;  
     //this.isMaxAmountValidAmount = true;
    });
  }

  private subscribeToTransferEmail(){
    this.email.valueChanges.subscribe((email)=> {
      this.emailVoucher = email;
      this.infoDetailsTransfer.transferAmount = email;
    });
  }

  private subscribeToDescription(){
    this.description.valueChanges.subscribe((description) =>{
    });
  }

  private formatAmount(value:any, baseCurrency?:string): any{   
    return ((baseCurrency!=undefined)?baseCurrency:this.baseCurrency.value) === PEN_BASE_CURRENCY ?
    this.peruvianCurrency.transform(value, 2) : this.currencyPipe.transform(value, USD_SEGMENT.symbol, USD_SEGMENT.symbol, '1.2-2');
  }

  public changeCurrency(selectedBaseCurrency: string) {
    this.baseCurrency.setValue(selectedBaseCurrency);
  }

  public getValueBaseCurrencyConst(symbol: string): string {
    return (symbol === PEN_SEGMENT.symbol) ? PEN_BASE_CURRENCY : USD_BASE_CURRENCY;
  }

  private accountDetail(accountNumber: string): IDetailsSavingAccount {
    return this.infoDetailsTransfer.accountOriginList.find(savingAccount => savingAccount.account === accountNumber);
  }

  private updateTransferMaxAmount() {
    this.amount.setValidators(this.amountValidators);
    this.amount.updateValueAndValidity();
  }

  async setMaxTransfersAmmount(){

    const maxTransferInteropAmountPEN = await this.transferService.getMaxTransferAmount(TRANSFER_MAX_AMOUNT_INTEROP_CODE_APP_PEN);
    // const maxTransferInteropAmountUSD = await this.transferService.getMaxTransferAmount(TRANSFER_MAX_AMOUNT_INTEROP_CODE_APP_PEN);
     if (maxTransferInteropAmountPEN.value) {
       this.maxTransferInteropAmountPEN =  Number(maxTransferInteropAmountPEN.value);
     } else { 
       throwError('Value not found in parameter'); 
     }

  }

  public minMaxRange(min: number, _isValidAmount: boolean): string {
    //Por el momento todo en interoperabilidad será en soles.

     let currencyType = PEN_SEGMENT.label_lc;
     let max = this.maxTransferInteropAmountPEN;    
     this.updateTransferMaxAmount();
     if(this.selectedAmountAccount<0 || (this.selectedAmountAccount>0 && this.amount.value < this.selectedAmountAccount)){
        
       this.errorTransferMessage =  (min === max ? `Max ${min.toString()} ${currencyType} por operación` :
       (this.amount.value>max? `Max ${max.toString()} ${currencyType} por operación`:
       (this.amount.value>=min?'':`El monto debe ser mayor o igual a ${min}`)))   

     }else if(this.selectedAmountAccount>0 && this.amount.value > this.selectedAmountAccount){
       this.errorTransferMessage = 'Saldo insuficiente. El monto excede tu saldo disponible';
     }

     return this.errorTransferMessage;
 }



 
  setFinantialinstitution(value:ISelectSearch){
    if(value!==null){
      this.infoDetailsTransfer.finantialInstitution = value.shortName;
      this.infoDetailsTransfer.finantialInstitutionCode = value.code;     
      
    }else{
      this.infoDetailsTransfer.finantialInstitution = '';
      this.infoDetailsTransfer.finantialInstitutionCode = '';
    }
    this.email.setValue(this.infoDetailsTransfer.email);   
  }

  getvalueOptions(ownAccount:boolean, destinatarybankCode:string): string{ 
    switch (destinatarybankCode) {
      case '055':
        return ownAccount?'01':'03';          
      default:
        return ownAccount?'02':'04';
      }
  }
  async goToWidget(){
    if (this.isSecondSubStep && this.isValidSubStepTwo){
      await this.getWidgetToken().then(async (response)=>{
        if(response.code==INTEROP_SUCCESS){
          this.isGetWidgetTokenError = false;
          this.infoDetailsTransfer.transfersOptions =  this.getvalueOptions(response.sameCustomerFlag
                                                                            , this.infoDetailsTransfer.finantialInstitutionCode);
          this.fillInfoDetailTransferWithResponse(response);
          this.currentSubStep ++;
        }else{
          this.isGetWidgetTokenError = true;
          let result = await this.firebaseService.getErrorTransferMessageFromFirebase(this.firebaseDocument
            ,String(response.code)
            ,TRANSFER_FLOW
            ,TRANSFER_SERVICE_INFO.GET_WIDGET_TOKEN_IOP);         
          console.log(result);
          result==null? this.openTransferErrorModal(TRANSFER_ERROR_GENERAL):this.openTransferErrorModal(result);      
          // this.openTransferErrorModal(STATUS_CODE_GET_INFO_NUMBER.find((code)=>(code.CODE == responseDirectory.code)).PROPMODAL);
          console.log(response.code);                        
        }
      });
    }

    if (!this.isGetWidgetTokenError){
      this.currentSubStep ++;
      (this.currentSubStep<3)?this.setDataTransferEvent.emit({info:null,substep:this.currentSubStep})
      :this.setDataTransferEvent.emit({info:this.infoDetailsTransfer,substep:this.currentSubStep})
    }
  }

  async getWidgetToken():Promise<ITransferGetTokenWidgetInteropResponse>{
    this.isWidgetLoading = true;
    try {
      let request ={
        description:  this.infoDetailsTransfer.description,
        amount: Number(this.infoDetailsTransfer.transferAmount),
        originAccount: this.infoDetailsTransfer.originAccount,
        receiverBankCode: this.infoDetailsTransfer.finantialInstitutionCode,
        sessionId: this.infoDetailsTransfer.sessionKey
      }     
    
      let response = await this.transferService.getWidgetTokenInteropV2(request);
    
      return response;
    } catch (error) {
        this.openTransferErrorModal(TRANSFER_ERROR_GENERAL);
    }finally{      
      this.isWidgetLoading = false;
    }
  }

  public async openTransferErrorModal(prop) {
    const modal = await this.modalErrorCtrl.create({
      component: TransferErrorModalComponent,
      cssClass: 'form-modal transfer-interop',
      componentProps: prop
    });
    modal.onDidDismiss()
    .then(()=>{
      this.dismissModal();
    })
    await modal.present();
  } 
  
  public dismissModal() {
    this.modalErrorCtrl.dismiss();
  }


  fillInfoDetailTransferWithResponse(value:ITransferGetTokenWidgetInteropResponse){
  
    this.infoDetailsTransfer.accessToken = value.acccessToken;
    this.infoDetailsTransfer.sessionKey = value.sessionKey;
    this.infoDetailsTransfer.destinataryName = value.receiverCustomerName;
    this.infoDetailsTransfer.finantialInstitution = value.receiverBankShortName;
    this.infoDetailsTransfer.finantialInstitutionFullName = value.receiverBankName;
    this.infoDetailsTransfer.commisionAmount = value.commisionAmount.toString();
    this.infoDetailsTransfer.transferAmount = value.transferAmount.toString();
    this.infoDetailsTransfer.codeCurrency = value.currencyCode;
    this.infoDetailsTransfer.itfAmount =value.itfAmount.toString();
    this.infoDetailsTransfer.sameCustomerFlag = value.sameCustomerFlag;
    this.infoDetailsTransfer.operation = value.operationName;
    this.infoDetailsTransfer.transferAmountTotal = (value.transferAmount + value.commisionAmount + value.itfAmount).toString();
    this.infoDetailsTransfer.baseCurrency = this.baseCurrency.value;
  }

  @HostListener('click', ['$event']) onClick(e) {   
    if(e!==undefined) {   
      if(e.target.localName.toLowerCase()!='input' 
         && e.target.id!='iconSelectSearch'
         && e.target.form===undefined){          
          this.isVisibleSearchList=false;         
          this.inputMessageTest = e.target.localName;
      }
    }}

}
