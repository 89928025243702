import { Injectable } from '@angular/core';
import { HttpService } from '@services/http/http.service';
import { environment } from '@environments/environment';
import { IOffering } from '@common/interfaces/offerings.interface';
import { RESCHEDULING_CODE_PRODUCT } from '@common/constants/rescheduling.constans';
import { OfferingsService } from '@services/offerings/offerings.service';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  bannerOffers: IOffering[];

  constructor(private http: HttpService,
              private offering: OfferingsService) { }


  public async getBannerOffers(): Promise<IOffering[]> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/ruooffers';
    const response = await this.offering.getRuoOffersOnOff();
    this.bannerOffers = response;
    if (this.bannerOffers.find((m) => m.codeProduct == RESCHEDULING_CODE_PRODUCT)) {
      this.bannerOffers = this.bannerOffers.filter((x) => x.codeProduct == RESCHEDULING_CODE_PRODUCT);
    }
    return response;
  }
}
