import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[alphaInput]'
})
export class AlphaInputDirective {

  @Input('alphaInput') formControl: FormControl;
  element: HTMLInputElement;

  constructor(
    private el: ElementRef,
  ) {
    this.element = this.el.nativeElement;
  }

  @HostListener('input', ['$event.target.value']) onChange(typedValue: string) {
    if (typedValue) {
      this.element.value = this.sanitizeText(typedValue);
    }
  }

  @HostListener('blur', ['$event.target.value']) onBlur() {
    this.formControl.markAsTouched();
  }

  private sanitizeText(value: string) {
    return value.replace(/[^a-zA-Z ]/g, '');
  }
}
