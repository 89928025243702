import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpService } from '@services/http/http.service';
import { CONTACT_DETAIL_MOCK, CHANGE_PASSWORD_MOCK, UPDATE_CONTACT_DATA_MOCK } from '@common/mocks/account.mocks';
import { IContactEnrollmentInformation, IPasswordChange } from '@common/interfaces/account.interface';
import { IUserIdentification } from '@common/interfaces/user.interface';
import { HttpHeaders } from '@angular/common/http';
import { UtilsService } from '@services/utils/utils';
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private http: HttpService,
    private utilsService: UtilsService,
  ) {}

  public async getContactDataFromFISA(accountDocument: IUserIdentification): Promise<IContactEnrollmentInformation> {
    const url = environment.BASE_BACKEND_WORKER + '/enrollment/accountuserhb/AccountUserData';
    return this.http.post(url, accountDocument, CONTACT_DETAIL_MOCK).toPromise();
  }

  public async getContactDetail(accountDocument: IUserIdentification): Promise<IContactEnrollmentInformation> {
    const url = environment.BASE_BACKEND_WORKER + '/enrollment/accountuserhb/contactdetail';
    return this.http.post(url, accountDocument, CONTACT_DETAIL_MOCK).toPromise();
  }

  public async getContactDetailRedeem(): Promise<IContactEnrollmentInformation> {
    const url = environment.BASE_BACKEND_WORKER + '/benefits/customer/accountuserdata';
    return await this.http.post(url, {} , CONTACT_DETAIL_MOCK).toPromise();
  }

  public async changePassword(passwordChangeInformation: IPasswordChange, identifier: number): Promise<any> {
    let headers = {} as HttpHeaders;
    headers = new HttpHeaders().set('Identifier', identifier.toString());
    headers = headers.set('Channel', this.utilsService.getChannelCode());
    const url = environment.BASE_BACKEND_WORKER + '/enrollment/accountuserhb/update';
    return this.http.post(url, passwordChangeInformation, CHANGE_PASSWORD_MOCK, headers).toPromise();
  }

  public async updateContactData(contactInformation: any): Promise<any> {
    const url = environment.BASE_BACKEND_WORKER + '/hbk/managementapp/userdigital/savedata';
    return this.http.post(url, contactInformation, UPDATE_CONTACT_DATA_MOCK).toPromise();
  }
}
