import { Injectable } from '@angular/core';
import {
  IRequestGetRestrictionsTemporaryBlock, IRequestUpdateRestrictionList,
  IRequestUpdateTemporaryBlock, IResponseOnOffRestrictionDetail,
  IResponseUpdateTemporaryRestrictionBlock
} from '@common/interfaces/on-off-restriction';
import {
  RESPONSE_TEMPORARY_BLOCK, RESPONSE_UPDATE_RESTRICTION_BLOCK_MOCK,
  RESPONSE_UPDATE_TEMPORARY_BLOCK_MOCK,
  RESTRICTION_LIST_MOCK
} from '@common/mocks/on-off-restriction.mock';
import { environment } from '@environments/environment';
import { HttpService } from '@services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class OnOffRestriction {

  constructor(
    private http: HttpService
  ) { }

  public async getRestrictionList(
    requestRestrictions: IRequestGetRestrictionsTemporaryBlock
  ): Promise<IResponseOnOffRestrictionDetail[]> {
    const url = environment.BASE_BACKEND_WORKER + '/product/creditcardoperation/restrictions/retrieve';
    return this.http.post(url, requestRestrictions, RESTRICTION_LIST_MOCK).toPromise();
  }

  public async updateRestrictions(
    requestUpdateRestriction: IRequestUpdateRestrictionList
  ): Promise<IResponseUpdateTemporaryRestrictionBlock> {
    const url = environment.BASE_BACKEND_WORKER + '/product/creditcardoperation/restrictions/update';
    return this.http.post(url, requestUpdateRestriction, RESPONSE_UPDATE_RESTRICTION_BLOCK_MOCK).toPromise();
  }

  public async getTemporaryBlockInfo(
    requestTemporaryBlock: IRequestGetRestrictionsTemporaryBlock
  ): Promise<IResponseOnOffRestrictionDetail> {
    const url = environment.BASE_BACKEND_WORKER + '/product/creditcardoperation/temporary-blocking/retrieve';
    return this.http.post(url, requestTemporaryBlock, RESPONSE_TEMPORARY_BLOCK).toPromise();
  }

  public async updateTemporaryBlock(
    requestUpdateTemporaryBlock: IRequestUpdateTemporaryBlock
  ): Promise<IResponseUpdateTemporaryRestrictionBlock> {
    const url = environment.BASE_BACKEND_WORKER + '/product/creditcardoperation/temporary-blocking/update';
    return this.http.post(url, requestUpdateTemporaryBlock, RESPONSE_UPDATE_TEMPORARY_BLOCK_MOCK).toPromise();
  }


}
