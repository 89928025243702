

export const TEMPORARY_BLOCK = {
    label: 'ESTADO_TARJETA',
    label_save: 'ESTADO_TARJETA',
    title: 'Tarjeta ON',
    title_save: 'Bloqueo Tarjeta ON/OFF',
    text:  'Tu tarjeta se encuentra activa',
    active: true
  };
export const pastValuesBlock ={
  temporaryBlock:true,
  temporaryBlockPresent:true
}
export const RESTRICTION_LIST = [
  {
    label: 'LINEA_ACTIVA',
    label_save: 'LINEA_ACTIVA',
    title: 'Disposición de efectivo',
    title_save: 'Disposición de efectivo',
    text:  'retiros de efectivo en Banco Ripley, Tiendas Ripley, ATM, etc.',
    active: false
  },
  {
    label: 'COMPRAS_EXTRANJERO_ACTIVO',
    label_save: 'COMPRAS_EXTRANJERO',
    title: 'Compras en el exterior',
    title_save: 'Compras en el exterior',
    text:  'realizar compras en el extranjero.',
    active: false
  },
  {
    label: 'COMPRAS_INTERNET_ACTIVO',
    label_save: 'COMPRAS_INTERNET',
    title: 'Compras por internet',
    title_save: 'Compras por internet',
    text:  'realizar compras en tiendas online.',
    active: false
  }
];
