import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LOTTIE_CONFIG } from '@common/constants/lottie-spinner.constants';
import { FACE_BACKEND, IDNOWSTEPS, JWT_SECRET_KEY, JWT_SECRET_V2 } from '@common/constants/digital-auth.constants';
import { WidgetResponse } from '@common/interfaces/widget.interface';
import { environment } from '@environments/environment';
import { DeviceService } from '@services/device/device.service';
import { UtilsService } from '@services/utils/utils';
import { WidgetService } from '@services/widget/widget.service';

const nJwt = require('njwt');

declare function onLoadV2(header: any, data: any, signature: any, tsFunction): any;
@Component({
  selector: 'app-widget-activation',
  templateUrl: './widget-activation.component.html',
  styleUrls: ['./widget-activation.component.scss'],
})
export class WidgetActivationComponent implements OnInit {
  @Input() set clientId(value: string) { this._clientId = value; }
  get clientId() { return this._clientId; }
  @Input() set version(value: number) { this._version = value; }
  get version() { return this._version; }
  @Input() set step(value: string) { this._step = value; }
  get step() { return this._step; }
  @Input() set flow(value: string) { this._flow = value; }
  get flow() { return this._flow; }

  public get urlJsV2(): string {
    return environment.WIDGET_ONB + '/bundle.js';
  }

  @Output() public methodValidity = new EventEmitter<{ data: WidgetResponse }>();


  public lottieConfig: object = LOTTIE_CONFIG;
  public lottieHeight: number = LOTTIE_CONFIG.sizes.small.height;
  public lottieWidth: number = LOTTIE_CONFIG.sizes.small.width;

  public result: WidgetResponse;
  public loading: boolean = false;
  public header: any;
  public data: any;
  public signature: string;
  public _os: any;
  private _clientId: string;
  private _version: number;
  private _step: string;
  private enrollmentType: string;
  private facemap: string;
  private sessionId: string;
  private appId: string;
  private country: string;
  private _flow: string;
  private deviceFingerprint: string;

  constructor(
    private utils: UtilsService,
    private widgetService: WidgetService,
    private deviceService: DeviceService,
  ) { }

  public get deviceOS() { return this.widgetService.os ? this.widgetService.os : this.utils.getDeviceOS().toUpperCase(); }

  public async ngOnInit() {
    this.appId = this.widgetService.appId;
    this.country = this.widgetService.country;
    const token = this.widgetService.token;
    const tokenIntrospect = this.widgetService.access_token;
    const stepId = this.widgetService.stepId;
    const definitionWorkflow = this.widgetService.definitionWorkflow;
    const sequenceNumber = this.widgetService.sequenceNumber;
    if (!this.isJsLoaded()) {
      this.addScriptTag();
    }
    const boundOnResult = function(result) {
      this.result = result;
      const response = { data: result };
      this.methodValidity.emit(response);
      if (this._step === IDNOWSTEPS.REGISTERPATH) {
        this.widgetService.notifyAgain({ rut: this.rut });
      }
    }.bind(this);
    let payload;
    switch (this._step) {
      case IDNOWSTEPS.DEVICE_STATUS:
        payload = await this.widgetService.getDeviceStatus;
        const pay = JSON.parse(payload);
        this.widgetService.deviceFingerprint = pay ? pay.deviceFingerprint : '-';
        break;
      case IDNOWSTEPS.ENROLLMENT_BY_CONTEXT:
        this.deviceFingerprint = await this.widgetService.deviceFingerprint;
        break;
      case IDNOWSTEPS.INITENROLLMENT:
        payload = await this.widgetService.initEnrollment;
        const json = JSON.parse(payload);
        json.faceBackend = FACE_BACKEND;
        payload = JSON.stringify(json);
        break;
      case IDNOWSTEPS.REGISTERPATH:
        payload = await this.widgetService.processInitEnrollment;
        break;
      case IDNOWSTEPS.OCR_DNI:
        const frontDni = await this.widgetService.finalAnalizeDNIFront;
        const backDni = await this.widgetService.finalAnalizeDNIBack;
        payload = {
          finalAnalizeDNI: [
            frontDni,
            backDni
          ]
        };
        break;
      case IDNOWSTEPS.BIOMETRIA:
        payload = await this.widgetService.retrieveZoom;
        break;
      case IDNOWSTEPS.RPASS:
        this.deviceFingerprint = '';
        break;
      case IDNOWSTEPS.FINISH_ENROLLEMENT:
        payload = await this.widgetService.finishEnrollment;
        this._os = this.deviceOS;
        if (this._os === 'IOS') {
          const payloadJSON = JSON.parse(payload);
          const payloadIOS = JSON.parse(payloadJSON.body);
          payload = payloadIOS;
          payload = JSON.stringify(payload);
        }
        break;
    }
    this._os = this.deviceOS;
    const uuidDevice = await this.widgetService.uuidDevice;
    const ip = (await this.deviceService.getIP()).ip;
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (this._step !== IDNOWSTEPS.OCR_DNI && this._step !== IDNOWSTEPS.BIOMETRIA) {
      payload = btoa(payload);
    }
    const rut = this.widgetService.rut;
    const uuidTransaction = this.widgetService.uuidTransaction;
    this.enrollmentType = this.widgetService.enrollmentType;
    if (this.widgetService.sessionId !== undefined && this.widgetService.faceMap !== undefined) {
      this.sessionId = this.widgetService.sessionId;
      this.facemap = this.widgetService.faceMap;
    }
    const additionalsParams = [{
      key: 'sessionId',
      value: this.sessionId
    }, {
      key: 'facemap',
      value: this.facemap
    }];
    const config = {
      token,
      rut,
      ip,
      userType: this.widgetService.documentType ? this.widgetService.documentType: 'CPA',
      deviceType: UtilsService.viewportLabel().toLowerCase(),
      event: this._step,
      enrollmentType: this.enrollmentType,
      localizacion: 'localizacion',
      userAgent,
      os: this._os,
      uuidDevice,
      uuidTransaction,
      payload,
      additionalsParams,
      appId: this.appId ? this.appId : this.country,
      stepId,
      channel: 'HBANK',
      definitionWorkflow,
      sequenceNumber,
      version: 'version2',
      tokenIntrospect,
      deviceFingerprint: this.deviceFingerprint
    };
    const header = {
      alg: 'HS256',
      typ: 'JWT'
    };

    const jwt = nJwt.create(config, JWT_SECRET_V2);
    this.data = jwt.compact();
    this.signature = JWT_SECRET_KEY;
    this.header = header;

    while (!(await onLoadV2(this.header, this.data, this.signature, boundOnResult))) {
      await this.utils.delay(500);
    }
    this.loading = false;

  }

  public async getResult(): Promise<any> {
    while (this.result === undefined) {
      await this.utils.delay(500);
    }

    const response = { data: this.result };
    this.methodValidity.emit(response);
  }

  public isJsLoaded() {
    return Array.from(document.getElementsByTagName('head')[0].getElementsByTagName('script')).some(script => script.src === this.urlJsV2);
  }

  private addScriptTag() {
    const scriptTag = document.createElement('script');
    const styleTag = document.createElement('link');

    const bootstrapJs = document.createElement('script');
    const bootstrapCss = document.createElement('link');
    const jqueryJs = document.createElement('script');

    scriptTag.type = 'text/javascript';
    styleTag.rel = 'stylesheet';

    bootstrapCss.rel = 'stylesheet';
    bootstrapJs.type = 'text/javascript';
    jqueryJs.type = 'text/javascript';
    scriptTag.src = this.urlJsV2;
    scriptTag.async = true;

    document.getElementsByTagName('head')[0].appendChild(scriptTag);
    document.getElementsByTagName('head')[0].appendChild(styleTag);
  }
}
