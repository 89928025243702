import { Animation } from '@ionic/core';

export function slideOutAnimation(AnimationC: Animation, baseEl: HTMLElement): Promise<Animation> {

  const timeInMilliseconds = 600;
  const fromValue = 0.4;
  const toValue = 0.0;
  const baseAnimation = new AnimationC();
  const backdropAnimation = new AnimationC();
  backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

  const wrapperAnimation = new AnimationC();
  const wrapperEl = baseEl.querySelector('.modal-wrapper');
  wrapperAnimation.addElement(wrapperEl);
  // tslint:disable-next-line: no-non-null-assertion
  const wrapperElRect = wrapperEl.getBoundingClientRect();

  wrapperAnimation.beforeStyles({ opacity: 1 })
                  .fromTo('translateX', '0%', `${window.innerHeight - wrapperElRect.top}px`);

  backdropAnimation.fromTo('opacity', fromValue, toValue);

  return Promise.resolve(baseAnimation
    .addElement(baseEl)
    .easing('cubic-bezier(0.36,0.66,0.04,1)')
    .duration(timeInMilliseconds)
    .add(backdropAnimation)
    .add(wrapperAnimation));
}
