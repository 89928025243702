export const SEF_REAL_NAME = 'Súper Efectivo';

export const SEF_MAIN_VIEW = 'SEF_MAIN_VIEW';

export const SEF_SIMULATOR_MAX_ATTEMPS = 6;

export const SEF_RSAT_POLICIES = 600;
export const SEF_T24_POLICIES = 601;

export const INSURANCE_LIFE = {
    OK: 200,
    EMAIL_PHONENUMBER_NOTFOUND: 600, //El usuario no tiene ni email ni teléfono
    GEOGRAPHIC_LOCATION: 601, //Error en servicio geografic location, desactivar seguro de vida
    RENIEC: 602, //Error en servicio de reniec, desactivar seguro de vida
    NOT_INSURANCE_LIFE: 603, //No tiene seguro de vida, desactivar seguro de vida
    ERROR_INSURANCE_LIFE: 604, //Error en servicio de seguro de vida, desactivar seguro de vida
}

export const TOOLTIP_TCEA_SEF = 'La TCEA se calcula considerando el seguro de desgravamen porcentual sobre el saldo insoluto de la deuda según tarifario vigente. Tasas,' + 
    'Tarifas y Contratos en: www.bancoripley.com.pe, sección: Tasas, Tarifas y Contratos, seleccionando el producto contratado.';