import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class WidgetServiceService {
  constructor(private http: HttpClient) {}

  async getWidgetToken(): Promise<any> {
    let data;
    try {
      data = await this.http
        .get(environment.BASE_BACKEND_WORKER + "/parameterhbk/get-widget-token")
        .toPromise();
      
    } catch (e) {
      
    }
    return JSON.parse(data);
  }

  async sendIncrease(params: any): Promise<any | any> {
    let data: any | any;
    
      data = await this.http
        .post(
          environment.BASE_BACKEND_WORKER + "/creditlinehbk/increase",
          JSON.stringify(params))
        .pipe(timeout(15000)).toPromise()
        
    
    return data;
  }
}


