import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { NotificationsBellComponent } from './notifications-bell.component';
import { NotificationsFeedComponent } from '@components/notifications-feed/notifications-feed.component';
import { NotificationsFeedComponentModule } from '@components/notifications-feed/notifications-feed.module';

@NgModule({
  declarations: [NotificationsBellComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NotificationsFeedComponentModule,
  ],
  entryComponents: [NotificationsFeedComponent],
  exports: [NotificationsBellComponent],
})
export class NotificationsBellComponentModule { }
