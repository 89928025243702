import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HOME_ROUTE, ON_OF_ROUTE } from '@common/constants/routes.constants';
import { IRestrictionList } from '@common/interfaces/on-off-restriction';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-on-off-modal',
  templateUrl: './on-off-modal.component.html',
  styleUrls: ['./on-off-modal.component.scss'],
})
export class OnOffModalComponent implements OnInit {

  @Input() icon: string;
  @Input() title: string;
  @Input() message: string;
  @Input() listOptionSaved: IRestrictionList[];
  @Input() messageDetail: string;
  @Input() time: number;
  @Input() acceptProcessAction: number; // 0: Save; 1: call to service ; 2: stay
  @Input() cancelProcessAction: number; // 0: home; 1: productList ;
  @Input() secondaryButtonName: string;
  @Input() primaryButtonName: string;
  @Input() toProductList: boolean;
  @Output() doEvent: EventEmitter<string> = new EventEmitter();


  constructor(private viewController: ModalController,
              private router: Router, ) { }

  ngOnInit() {
  }

  public closeModal() {

    this.viewController.dismiss();
    if (this.acceptProcessAction === 2) {     
        this.router.navigateByUrl(`/${ON_OF_ROUTE}`); // listado de tarjetas
    }
  }
  public acceptProcess() {
    this.viewController.dismiss();
    switch (this.acceptProcessAction) {
      case 0:
        this.doEvent.emit('save'); // Save changes
        break;
      case 1:
        this.doEvent.emit('recall'); // Recall service
        break;
      case 2:
        this.router.navigateByUrl(`/${ON_OF_ROUTE}`); // listado de tarjetas
        break;
    }
  }
  public cancelProcess() {
    this.viewController.dismiss();
    switch (this.cancelProcessAction) {
      case 0:
        this.router.navigateByUrl(`/${ON_OF_ROUTE}`);
        break;
      case 1:
        this.router.navigateByUrl(`/${HOME_ROUTE}`);
        break;
    }

  }

}
