import { Injectable } from '@angular/core';
import { IOnOff, IOnOffParams } from '@common/interfaces/onoff.interface';
import { ONOFF_MOCK } from '@common/mocks/onoff.mocks';
import { environment } from '@environments/environment';
import { HttpService } from '@services/http/http.service';


@Injectable({
  providedIn: 'root'
})

export class OnOffService {

  constructor(
    private http: HttpService
    ) { }

    public getOnOffInformation(processType: IOnOffParams): Promise<IOnOff> {
        const url = environment.BASE_BACKEND_WORKER + '/enrollment/accountuserhb/onoff';
        return this.http.post(url, processType, ONOFF_MOCK).toPromise();
    }
    
}