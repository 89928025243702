import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProductList } from '@common/interfaces/payment.interface';
import { HideTextPipe } from '@pipes/hide-text-number/hide-text-number.pipe';

@Component({
  selector: 'app-card-payment-list',
  templateUrl: './card-payment-list.component.html',
  styleUrls: ['./card-payment-list.component.scss'],
})
export class CardPaymentListComponent {
  
  productSelected: any;
  hideText: HideTextPipe;
  @Input() productList?: IProductList[];
  @Output() buttonEnabled: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    this.buttonEnabled = new EventEmitter();
    this.hideText = new HideTextPipe();
  }

  onProductChecked() {
    this.buttonEnabled.emit(this.productSelected);
  }

  selectProduct(product: any) {
    this.productSelected = product;
  }
}
