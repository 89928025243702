import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-benefits-sef',
  templateUrl: './benefits-sef.component.html',
  styleUrls: ['./benefits-sef.component.scss'],
})
export class BenefitsSefComponent implements OnInit {

  constructor(private viewController: ModalController) {

  }

  ngOnInit() {}

  dismissModal() {
    this.viewController.dismiss();
  }

}
