import { Component, Input, OnDestroy } from '@angular/core';
import { HOME_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { slideInAnimation } from '@common/utils/animations/slide-in-animation';
import { slideOutAnimation } from '@common/utils/animations/slide-out-animation';
import { NotificationsFeedComponent } from '@components/notifications-feed/notifications-feed.component';
import { ModalController } from '@ionic/angular';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { NotificationsService } from '@services/notifications/notifications.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notifications-bell',
  templateUrl: './notifications-bell.component.html',
  styleUrls: ['./notifications-bell.component.scss'],
})
export class NotificationsBellComponent implements OnDestroy {

  public subscription: Subscription;
  public currentCssClass: string;
  @Input() public inverseColor: boolean;

  constructor(
    private notificationsService: NotificationsService,
    private modalController: ModalController,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.subscription = new Subscription();
    this.inverseColor = false;
    this.currentCssClass = 'i-notification-off';
    this.listenToNotificationUpdate();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async openFeed() {
    this.googleAnalyticsService.gtagPushEventWithId(HOME_GA4_CONSTANTS.HOME_NOTIFICATIONS.EVENT, {
      path_destino: HOME_GA4_CONSTANTS.HOME_NOTIFICATIONS.PATH_DESTINO,
      product: HOME_GA4_CONSTANTS.HOME_NOTIFICATIONS.PRODUCT,
      action: HOME_GA4_CONSTANTS.HOME_NOTIFICATIONS.ACTION,
      category: HOME_GA4_CONSTANTS.HOME_NOTIFICATIONS.CATEGORY,
      sub_category: HOME_GA4_CONSTANTS.HOME_NOTIFICATIONS.SUB_CATEGORY,
    });

    const modal = await this.modalController.create({
      component: NotificationsFeedComponent,
      cssClass: 'side-modal',
      enterAnimation: slideInAnimation,
      leaveAnimation: slideOutAnimation
    });
    await modal.present();
  }

  private listenToNotificationUpdate(): void {
    this.subscription.add(this.notificationsService.hasNewNotifications.subscribe((newNotifications) => {
     this.currentCssClass = newNotifications ? 'i-notification-on' : 'i-notification-off';
    }));
  }

}
