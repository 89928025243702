import { NgModule } from '@angular/core';
import { FlexPage } from './flex';
import { CommonModule } from '@angular/common';
import { WaveModule } from '@components/wave/wave.module';

@NgModule({
  declarations: [
    FlexPage,
  ],
  imports: [
    WaveModule,
    CommonModule,
  ],
  exports: [
    FlexPage,
  ],
})
export class FlexPageModule {}
