import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NotificationsFeedComponent } from './notifications-feed.component';
import { UtilsService } from '@services/utils/utils';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  providers: [UtilsService],
  declarations: [NotificationsFeedComponent],
  exports: [NotificationsFeedComponent]
})
export class NotificationsFeedComponentModule {}
