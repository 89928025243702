import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderRoutesAF } from '@common/models/HeaderRoutesAF.model';


@Component({
  selector: 'app-generic-header-control',
  templateUrl: './generic-header-control.component.html',
  styleUrls: ['./generic-header-control.component.scss']
})
export class GenericHeaderControlComponent implements OnInit {
  @Input() public totalStep: number = 0;
  @Input() public totalActiveStep: number = 0;
  @Input() public totalActiveStepInText: number = 0;
  @Input() public descriptionStep: string;
  @Input() public customBackEvent?: boolean = false;
  @Input() public headerRoutes: HeaderRoutesAF;
  @Output() backButtonClick?:EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  public goTo(path: string): void {
    if(!this.customBackEvent){
      this.router.navigateByUrl(path, { replaceUrl: true });
    }else{
      this.backButtonClick.emit();
    }
  }
}
