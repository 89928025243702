import { NAME_EFEX, NAME_SEF, NAME_SEF_REENGANCHE, ORIGIN_LOAN_PWA, ORIGIN_LOAN_PWA_OPT, PRODUCT_SEF, PRODUCT_SEF_REENGANCHE } from './../../common/constants/offerings.constants';
import { ModalController } from '@ionic/angular';
import { CREDITS_ROUTE, EFEX_AUTO_ROUTE, SEF_ROUTE } from './../../common/constants/routes.constants';
import { Component, Input, OnInit } from '@angular/core';
import { ILoan, IOffering, IOfferState } from '@common/interfaces/offerings.interface';
import { Router } from '@angular/router';
import { OfferingsService } from '@services/offerings/offerings.service';
import { LEAD_EXECUTION_CODE, OFFERS_LOAN, PRODUCT_EFEX } from '@common/constants/offerings.constants';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import {
  DYNAMIC_MESSAGE_MODAL_PROPS,
  END_TO_END_OFF_MODAL_PROPS, LOAN_LEAD_FAILED_MODAL_PROPS,
  OFFER_EFEX_INACTIVE_MODAL_PROPS, OFFER_RSEF_INACTIVE_MODAL_PROPS, OFFER_SEF_INACTIVE_MODAL_PROPS
} from '@common/constants/modal-props.constants';
import { ILeadModalBody, ILoanLead } from '@common/interfaces/loan-lead.interface';
import { LeadGenericModalComponent } from '@components/leads-modal/lead-generic-modal/lead-generic-modal.component';
import { OfferingModalComponent } from '@components/offering-modal/offering-modal.component';
import { FirebaseService } from '@services/firebase/firebase.service';
import { StorageService } from '@services/storage/storage.service';
import { USER_INFO } from '@common/constants/auth.constants';
import { SUCCESS } from '@common/constants/error.constants';
import { EFEX, SCREEN_WIDTH, SEF } from '@common/constants/misc.constants';
import { HOME_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { GENERIC_TAG_BTN_CLICK } from '@common/constants/tag/tag-generic.constants';
import { SyneriseServiceService } from '@services/synerise/synerise-service.service';
@Component({
  selector: 'app-offer-card-purple-dark',
  templateUrl: './offer-card-purple-dark.component.html',
  styleUrls: ['./offer-card-purple-dark.component.scss'],
})
export class OfferCardPurpleDarkComponent implements OnInit {

  @Input() title: string;
  @Input() amount: string;
  @Input() routerStateInformation: object;
  @Input() validity: string;
  isLoading: boolean;
  offer: IOffering[];

  constructor(private router: Router,
              private modalCtrl: ModalController,
              private offeringService: OfferingsService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private firebaseService: FirebaseService,
              private storage: StorageService,
              private syneriseService: SyneriseServiceService,) {
    this.isLoading = false;
  }

  get isMobile() { return window.innerWidth < SCREEN_WIDTH.TABLET; }
  ngOnInit() { }

  async goToRoute() {
    this.isLoading = true;
    await this.checkStorage();
    if(await this.delayValidation()) return
    this.isLoading = false;

    if (!(this.routerStateInformation as IOffering).endToEnd) {
      this.showEndToEndOffModal((this.routerStateInformation as IOffering).codeProduct);
      return;
    }
    if ((this.routerStateInformation as IOffering).offerInactive) {
      this.showOfferInactiveModal();
    } else {
      const routerStateData: IOfferState = {
        offeringData: this.routerStateInformation as IOffering,
        isEfexPromotional: false
      };
      const userInfo = await this.storage.get(USER_INFO);
      const dniSearchFormat =  userInfo.documentType + '-' + userInfo.documentNumber;

      if ((this.routerStateInformation as IOffering).nameProduct === NAME_SEF ||
        (this.routerStateInformation as IOffering).nameProduct === NAME_EFEX ||
        (this.routerStateInformation as IOffering).nameProduct === NAME_SEF_REENGANCHE){
        
        HOME_GA4_CONSTANTS.HOME_LOANS_BTN.product = 'simular';
        HOME_GA4_CONSTANTS.HOME_LOANS_BTN.sub_category = (this.routerStateInformation as IOffering).nameProduct === NAME_EFEX ? EFEX : SEF;
        this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_BTN_CLICK, HOME_GA4_CONSTANTS.HOME_LOANS_BTN);
        this.syneriseService.trackEvent(GENERIC_TAG_BTN_CLICK, HOME_GA4_CONSTANTS.HOME_LOANS_BTN)
      }

      switch ((this.routerStateInformation as IOffering).nameProduct) {
        case NAME_SEF:
          this.firebaseService.registerStepSefEfex('sef automatico', {origen: 'OfferCardPurpleDarkComponent', dniSearchFormat});
          this.router.navigate([SEF_ROUTE], { state: routerStateData });
          break;
        case NAME_EFEX:
          this.firebaseService.registerStepSefEfex('efex automatico', {origen: 'OfferCardPurpleDarkComponent', dniSearchFormat});
          this.router.navigate([EFEX_AUTO_ROUTE], { state: routerStateData});
          break;
        case NAME_SEF_REENGANCHE:
          this.firebaseService.registerStepSefEfex('sef reenganche modal', {origen: 'OfferCardPurpleDarkComponent', dniSearchFormat});
          this.showSefReengancheModal(this.routerStateInformation as IOffering);
          break;
        default:
          this.router.navigate([CREDITS_ROUTE], { state: this.routerStateInformation });
          break;
      }
    }
  }

  private async checkStorage() {
    const offers_loan = sessionStorage.getItem(OFFERS_LOAN);
    if (offers_loan === null || offers_loan === undefined) {
      const offeringData = this.routerStateInformation as IOffering;
      await this.startLoan(offeringData.codeProduct);
    }
  }

  public async delayValidation(){
    this.isLoading = false;
    if((this.routerStateInformation as IOffering).isPopUpsDynamic){
      DYNAMIC_MESSAGE_MODAL_PROPS['message'] = (this.routerStateInformation as IOffering).messagePopUpsDynamic;
      const modal = await this.modalCtrl.create({
        component: GenericModalComponent,
        cssClass: 'form-modal small',
        componentProps: DYNAMIC_MESSAGE_MODAL_PROPS,
      });
      await modal.present();
      return true;
    }
    return false;
  }
  
  private async startLoan(codePorduct: string) {
    sessionStorage.setItem(ORIGIN_LOAN_PWA, ORIGIN_LOAN_PWA_OPT.MENU);
    try {
      const loanRequest: ILoan = {
        codeProduct: codePorduct,
        reference: window.location.href,
        utmCampaing: '',
        utmContent: '',
        utmMedium: '',
        utmSource: '',
        isFeedback: false,
        originLoanPwa: ORIGIN_LOAN_PWA_OPT.MENU
      };
      const result = await this.offeringService.startLoanRegister(loanRequest);
      sessionStorage.setItem(OFFERS_LOAN, 'true');
      sessionStorage.setItem(LEAD_EXECUTION_CODE, result.executionCode);
    } catch (err) { }
  }


  public async showOfferInactiveModal() {
    let props = {};
    switch ((this.routerStateInformation as IOffering).codeProduct) {
      case PRODUCT_EFEX:
        props = OFFER_EFEX_INACTIVE_MODAL_PROPS;
        break;
      case PRODUCT_SEF:
        props = OFFER_SEF_INACTIVE_MODAL_PROPS;
        break;
      case PRODUCT_SEF_REENGANCHE:
        props = OFFER_RSEF_INACTIVE_MODAL_PROPS;
        break;
    }
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

  public async showGenericErrorModal() {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: LOAN_LEAD_FAILED_MODAL_PROPS,
    });
    modal.present();
  }

  public async showEndToEndOffModal(codeProduct: string) {
    const executionCode = sessionStorage.getItem(LEAD_EXECUTION_CODE);
    if (executionCode !== null && executionCode !== undefined) {
      const loanLead: ILoanLead = {
        codeProduct,
        executionCode,
        reference: window.location.href,
        callCenterReason: ''
      };

      const leadModalBody: ILeadModalBody = END_TO_END_OFF_MODAL_PROPS;
      const props = {
        leadModalBody,
        loanLeadData: loanLead
      };
      const modal = await this.modalCtrl.create({
        component: LeadGenericModalComponent,
        cssClass: 'form-modal',
        componentProps: props,
      });
      await modal.present();
    }
  }

  public formattedTCA(offer: IOffering) {
    const timeMonth = Math.max(offer.minimumTimeMonth, offer.maximumTimeMonth);
    return 'TCEA ' + Number(offer.rateTCA).toFixed(2) + '% a ' + timeMonth + ' cuotas.';
  }

  private async showSefReengancheModal(offer: IOffering) {
    const props = {
      title: 'Detalles de tu Renovación de Súper Efectivo',
      subtitle: 'Monto a desembolsar',
      primaryButtonText: 'Volver',
      offer: offer,
      amountIcon: 'i-money-bill-pink',
      tca: this.formattedTCA(offer),
      disclaimer: offer.customerOfferReference
    };
    const modal = await this.modalCtrl.create({
      component: OfferingModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

}
