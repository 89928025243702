import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LOGIN_ROUTE } from '@common/constants/routes.constants';

@Component({
  selector: 'app-check-unsubscribe-rpass',
  templateUrl: './check-unsubscribe-rpass.component.html',
  styleUrls: ['./check-unsubscribe-rpass.component.scss'],
})
export class CheckUnsubscribeRpassComponent implements OnInit {
  public primaryButtonText: string = '';

  constructor(
    private router: Router
  ) { }

  public ngOnInit() {
    this.setupIntro();
  }

  public setupIntro() {
      this.primaryButtonText = 'Volver al inicio';
  }

  public navigateToRoute() {
    this.router.navigateByUrl(`/${LOGIN_ROUTE}`, { replaceUrl: true });
  }

}
