export const ObjectResponse: any[] = [
    {
        error: '15',
        message: 'Tu cuenta ha sido bloqueada'
    },
    {
        error: '6',
        message: 'Usuario y/o clave incorrectas'
    },
    {
        error: '3',
        message: 'No te encuentras afiliado a Banco Ripley'
    },
    {
        error: '16',
        message: 'Usuario bloqueado por el administrador'
    },
    {
        error: '17',
        message: 'Ocurrió un error inesperado. Estamos trabajando en solucionarlo a la brevedad.'
    },
    {
        error: '20',
        message: 'Close Sessions'
    },
    {
        code: 726,
        message: 'Aún no eres parte de Banco Ripley'
    }
]

export const HttpError: any = {
    BadRequest: 400,
    Unauthorized: 401,
    Forbidden: 403,
    NotFound: 404,
    TimeOut: 408,
    Conflict: 409,
    InternalServerError: 500,
    GatewayTimeout: 504,
}