import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UtilsService } from '@services/utils/utils';
import { CardStoreService } from '@services/card-store/card-store.service';

@Component({
  selector: 'page-offer-modal',
  templateUrl: 'offer-modal.html',
})
export class OfferModalPage {
  public static readonly pageName = 'OfferModalPage';
  @Input() public user: any;
  @Input() public finalScreen: boolean;
  @Input() public screen: any;
  @Input() public desktopMode: boolean;
  @Output() public goalCompleted: EventEmitter<boolean>;
  @Output() public goTo: EventEmitter<any>;
  @Output() public exit: EventEmitter<any>;
  public campaign: any;
  public nextButtonText: string;

  constructor(
    public utils: UtilsService,
    private storeService: CardStoreService,
  ) {
    this.goalCompleted = new EventEmitter();
    this.goTo = new EventEmitter();
    this.exit = new EventEmitter();
  }

  get showFixedButton() {
    return !!this.screen.button.show && !!this.screen.button.fixed;
  }

  get imageURL() {
    return this.desktopMode ? this.screen.imageURLDesktop : this.screen.imageURL;
  }

  public triggerGoTo(page, params) {
    this.goalCompleted.emit(true);
    this.goTo.emit({page, params});
  }

  public triggerExit() {
    this.storeService.setCloseState(true);
    this.exit.emit();
  }

}
