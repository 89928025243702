import {
  IConsolidatedProducts,
  ICreditCardDetails,
  IConsolidatedProductsDetails,
  ISEFDetails,
  IProductMovements,
  IStatementListParam,
  IStatementListMonths,
  IStatementDocument,
  IActiveProductResponse,
  IProductsDetail,
  IProductDetailRequest,
  IProductMovRequest,
  IProductConsolidateRequest,
} from '@common/interfaces/product.interface';
import {
  CREDIT_CARD_TYPE_MOCK,
  CONSOLIDATED_PRODUCTS_MOCK,
  SEF_TYPE_MOCK,
  CREDIT_CARD_DETAILS_MOCK,
  SEF_DETAILS_MOCK,
  PRODUCT_MOVEMENTS_MOCK,
  STATEMENT_MONTH_LIST_MOCK,
  STATEMENT_DOCUMENT_MOCK,
  CONSOLIDATED_AUTHENTICATED_PRODUCTS_MOCK,
  CONSOLIDATED_CARDS_PRODUCTS_MOCK,
  PRODUCTS_CARDS_DETAILS_MOCK,
  PRODUCT_MOVEMENTS_DETAIL_MOCK
} from '@common/mocks/product.mocks';
import { IUserIdentification } from '@common/interfaces/user.interface';
import { HttpService } from '@services/http/http.service';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { SEF_PRODUCT_CODE, CREDIT_CARD_PRODUCT_CODE } from '@common/constants/product.constants';
import { IMockup } from '@common/interfaces/http.interface';
import { IGenericNameValueResponse } from '@common/interfaces/default.interface';
import { NavigationCheckerService } from '@services/navigation/navigation-checker.service';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  tcDetails: any;
  consolidatedProducts: Promise<IConsolidatedProducts>;
  consolidatedProductsSEF: Promise<IConsolidatedProducts>;
  parameterHbkTC: Promise<IGenericNameValueResponse>;
  parameterHbkSef: Promise<IGenericNameValueResponse>;

  constructor(
    private http: HttpService,
    private navCheckerSrv: NavigationCheckerService    
  ) { }

 /* first service - Solo habilitado para sef*/
  public getAuthenticatedConsolidatedProductsSEF(forceRequest?: boolean): Promise<IConsolidatedProducts> {
    if(this.navCheckerSrv.InHome() && this.consolidatedProductsSEF && !forceRequest) {
      return this.consolidatedProductsSEF;
    } else {
      const url = environment.BASE_BACKEND_WORKER + '/productsapm/product/active/consolidate';
      const request = this.http.get(url, CONSOLIDATED_AUTHENTICATED_PRODUCTS_MOCK).pipe(shareReplay(1)).toPromise();
      
      this.consolidatedProductsSEF = request;
      return this.consolidatedProductsSEF;
    }
  }

  // Tarjetas Adicionales
  public getAuthenticatedConsolidatedProducts(forceRequest?: boolean): Promise<IConsolidatedProducts> {
        
    if(this.navCheckerSrv.InHome() && this.consolidatedProducts && !forceRequest) {
      return this.consolidatedProducts;
    } else {
      
      const url = environment.BASE_BACKEND_WORKER + '/productshbk/product/active/consolidate';
      const request = this.http.post(url, null, CONSOLIDATED_CARDS_PRODUCTS_MOCK).pipe(shareReplay(1)).toPromise();

      this.consolidatedProducts = request;
      return this.consolidatedProducts;
    }    
  }

  // Servicio para traer tarjetas solo WEB
  public getAuthenticatedConsolidatedProductsWebPwa(consolidateRequest:IProductConsolidateRequest, forceRequest?: boolean): Promise<IConsolidatedProducts> {
    if(this.navCheckerSrv.InHome() && this.consolidatedProducts && !forceRequest) {
      return this.consolidatedProducts;
    }

    const url = environment.BASE_BACKEND_WORKER + '/productshbk/product/active/v2/consolidate';
    const request = this.http.post(url, consolidateRequest, CONSOLIDATED_CARDS_PRODUCTS_MOCK).toPromise();
    
    this.consolidatedProducts = request;
    return this.consolidatedProducts;
  }
  
  public hasActiveProducts(accountDocument: IUserIdentification): Promise<IActiveProductResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/product/active/validate';
    return this.http.post(url, accountDocument, CONSOLIDATED_PRODUCTS_MOCK).toPromise();
  }

  /* Consolidate old - Solo para SEF */
  public getActiveMovementsSEF(cardDetails: ICreditCardDetails | ISEFDetails): Promise<IProductMovements[]> {
    const url = environment.BASE_BACKEND_WORKER + '/productsapm/product/active/movements';
    return this.http.post(url, cardDetails, PRODUCT_MOVEMENTS_MOCK).toPromise();
  }

  // Tarjetas adicionales
  public getActiveMovements(cardDetails: IProductMovRequest): Promise<IProductMovements[]> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/product/active/movements';
    return this.http.post(url, cardDetails, PRODUCT_MOVEMENTS_DETAIL_MOCK).toPromise();
  }

  
  public getListOfMonthsForStatement(dateSearch: IStatementListParam): Promise<IStatementListMonths[]> {
    const url = environment.BASE_BACKEND_WORKER + '/hbk/managementapp/parameter/listmonthpayment';
    return this.http.post(url, dateSearch, STATEMENT_MONTH_LIST_MOCK).toPromise();
  }

  public getStatementByMonth(dateSearch: IStatementListParam): Promise<IStatementDocument> {
    const url = environment.BASE_BACKEND_WORKER + '/hbk/products/accountstate/bymonth';
    return this.http.post(url, dateSearch, STATEMENT_DOCUMENT_MOCK).toPromise();
  }

  public getCreditCardTypes(): Promise<IGenericNameValueResponse> {
    if(this.navCheckerSrv.InLogin()){
      this.parameterHbkTC = null;      
    }   

    if(!this.parameterHbkTC) {
      this.parameterHbkTC = this.getProductType(CREDIT_CARD_PRODUCT_CODE, CREDIT_CARD_TYPE_MOCK);
    }
    return this.parameterHbkTC;
  }

  // Tarjetas adicionales
  public async getCreditCardInformation(product: IProductDetailRequest): Promise<IProductsDetail> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/product/active/detail/tc';
    return this.http.post(url, product, PRODUCTS_CARDS_DETAILS_MOCK).toPromise();
  }

  // Consolidate old
  // public async getCreditCardInformationSEFTC(product: IConsolidatedProductsDetails): Promise<ICreditCardDetails> {
  //   const url = environment.BASE_BACKEND_WORKER + '/productsapm/product/active/detail/tc';
  //   return this.http.post(url, product, CREDIT_CARD_DETAILS_MOCK).toPromise();
  // }

  public async getSefInformation(product: IConsolidatedProductsDetails): Promise<ISEFDetails> {
    const url = environment.BASE_BACKEND_WORKER + '/productsapm/product/active/detail/sef';
    return this.http.post(url, product, SEF_DETAILS_MOCK).toPromise();
  }

  public getSefTypes(): Promise<IGenericNameValueResponse> {
    if(this.navCheckerSrv.InLogin()){
      this.parameterHbkSef = null;      
    }   

    if(!this.parameterHbkSef) {
      this.parameterHbkSef = this.getProductType(SEF_PRODUCT_CODE, SEF_TYPE_MOCK);
    }

    return this.parameterHbkSef;    
  }

  private getProductType(code: string, mock: IMockup) {
    const url = environment.BASE_BACKEND_WORKER + `/parameterhbk/${code}`;
    return this.http.get(url, mock).pipe(shareReplay(1)).toPromise();
  }

  public getProducts(): Promise<IProductsDetail[]> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/product/payment/get-products';
    return this.http.post(url, null, CONSOLIDATED_CARDS_PRODUCTS_MOCK).toPromise();
  }
}
