import { Component, HostListener, OnInit, ViewChild, Input } from '@angular/core';

import { Router } from '@angular/router';

import {
  RIPLEY_POINTS_GOLD_BENEFITS_ROUTE,
  RIPLEY_POINTS_GOLD_SILVER_BENEFITS_ROUTE,
  RIPLEY_POINTS_SILVER_BENEFITS_ROUTE,
  RIPLEY_POINTS_PLUS_BENEFITS_ROUTE,
  RIPLEY_POINTS_DASHBOARD_ROUTE
} from '@common/constants/routes.constants';
import { CLIENTES_PRIME_SITE } from '@common/constants/misc.constants';
import { ICardMessage, IRipleySegmentDirection } from '@common/interfaces/reward-points.interface';
import {
  congratsGold,
  pursuitGold,
  pursuitSilver,
  pursuitSilverGold,
  transformToAmount,
  pursuitSilverGoldSinPlus
} from '@common/utils/category-points/category-points';
import {
  ALERT_SILVER_GOLD,
  DEFAULT_REQUEST_TIMEOUT,
  DEFAULT_SERVICE_ERROR_MESSAGE,
  RIPLEY_POINTS_SITE
} from '@common/constants/misc.constants';
import { RewardPointsService } from '@services/reward-points/reward-points.service';
import { UtilsService } from '@services/utils/utils';
import {
  CUSTOMER_POINTS_TO_BE_PLUS,
  CUSTOMER_POINTS_TO_BE_GOLD,
  CUSTOMER_POINTS_TO_BE_SILVER,
  CUSTOMER_TYPE_SEGMENT_GOLD,
  CUSTOMER_TYPE_SEGMENT_PLUS,
  CUSTOMER_TYPE_SEGMENT_RIPLEY,
  CUSTOMER_TYPE_SEGMENT_SILVER
} from '@common/constants/reward-points.constants';

import { IonSlides, ModalController } from '@ionic/angular';

import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import {
  TOOLTIP_RIPLEY_POINTS_DASHBOARD
} from '@common/constants/modal-props.constants';


@Component({
  selector: 'app-perseguidora',
  templateUrl: './perseguidora.component.html',
  styleUrls: ['./perseguidora.component.scss'],
})
export class PerseguidoraComponent implements OnInit {

  public alert: any;
  public cardMessages: ICardMessage[];
  public category: string;
  public categoryCardClass: string;
  public categoryPointsConfig: any;
  public qualifyingPoints: number;
  public pendingPoints: number;
  public segmentDirection: IRipleySegmentDirection;
  public date = new Date();
  public year: any;
  public dateToShow: Date;
  public hasToShowAlert = true;
  public categoryError = false;
  public loading = true;
  public slideOptions: any;
  public transitionBreakpoint = 600;
  public pointsToShow = 0;
  public transformedPoints: string;
  public progressBackgroundColor = '#824295';
  public progressBackgroundColorSilver = '#58595B';
  public progressBackgroundColorGold = '#FFB81C';
  public progressGoldColor = '#FFD882';
  public progressSilverColor = '#B7BBBF';
  public progressSilverPlus = '#9D71CF';
  public defaultErrorMessage = DEFAULT_SERVICE_ERROR_MESSAGE;

  @ViewChild(IonSlides) public slides: IonSlides;

  public isBeginningSlide = true;

  public isEndSlide = false;

  public showTooltip = false;

  public valorRuedaPlus = 0;
  public valorRuedaSilver = 0;
  public valorRuedaGold = 0;

  @Input() fechaEntrada: any;

  public categoria_src: string = '';
  slideOpts = {
    initialSlide: 0,
    spaceBetween: 5,
    speed: 400,
  };
  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private rewardPointsService: RewardPointsService,
    private modalCtrl: ModalController,
  ) {
    this.category = 'ripley';
    this.alert = { message: '', icon: '', case: '', enabled: false };
    this.slideOptions = {
      initialSlide: 0,
      centeredSlides: true,
      grabCursor: true,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
      slidesPerView: 1.15,
      spaceBetween: 8,
      zoom: { toggle: false }
    };
  }



  public obtainProgressBarColor(card: ICardMessage): string {
    return card.category === CUSTOMER_TYPE_SEGMENT_SILVER ? this.progressSilverColor : this.progressGoldColor;
  }

  public obtainProgressBarMaxPoints(card: ICardMessage): number {
    if (card.category === CUSTOMER_TYPE_SEGMENT_PLUS) {
      return CUSTOMER_POINTS_TO_BE_PLUS;
    }

    if (card.category === CUSTOMER_TYPE_SEGMENT_SILVER) {
      return CUSTOMER_POINTS_TO_BE_SILVER;
    }

    return CUSTOMER_POINTS_TO_BE_GOLD;
  }

  public format(date: Date, formatStr: string) {
    return this.utilsService.formatDate(date, formatStr);
  }

  public async ngOnInit() {
    this.loading = true;
    // Mocks
    this.year = this.date.getFullYear();
    this.categoryPointsConfig = {
      pointToBe: { plus: CUSTOMER_POINTS_TO_BE_PLUS, silver: CUSTOMER_POINTS_TO_BE_SILVER, gold: CUSTOMER_POINTS_TO_BE_GOLD },
      alerts: [
        {
          case: 'pursuitSilverGold',
          message: '¡Acumula al menos 5.000 Puntos Categoria y disfruta los beneficios de ser un cliente Silver!',
          icon: 'https://firebasestorage.googleapis.com/v0/b/banco-ripley-app.appspot.com/o/icons%2Fi-warning-pink.svg?alt=media',
          enabled: true,
        },
      ],
    };
    this.valorRuedaPlus = CUSTOMER_POINTS_TO_BE_PLUS
    this.valorRuedaSilver = CUSTOMER_POINTS_TO_BE_SILVER
    this.valorRuedaGold = CUSTOMER_POINTS_TO_BE_GOLD

    var date = new Date();
    var primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
    var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.dateToShow = new Date(date.getFullYear(), date.getMonth(), 0);
    await this.getCategoryDescription();
    this.calculateCase();
    if (this.categoryPointsConfig.alerts) { this.generateAlert(); }
    this.loading = false;

    this.categoryPointsConfig = {
      tooltipMessage: `Son los puntos acumulados entre el 01 de enero y el 31 de diciembre de cada año que te permiten
      acceder a las categorías Plus, Silver y Gold. No se consideran los puntos adquiridos por campañas promocionales.`
    };
    this.onResize();
  }

  public generateAlert() {
    this.alert = this.obtainAlert();
    this.hasToShowAlert = this.alert && this.alert.message && this.alert.enabled;
  }

  private obtainAlert() {
    const alertObtained = ALERT_SILVER_GOLD.find((a) => a.case === this.category);
    alertObtained['message'] = alertObtained['message'].concat(this.obtainNextYear().toString());
    return alertObtained;
  }

  private obtainNextYear() {
    const date = new Date();
    const nextYear = date.getFullYear() + 1;
    return nextYear;
  }

  public async calculateCase() {

    //console.log(" ver tipo  ",this.category)
    if (this.category === CUSTOMER_TYPE_SEGMENT_RIPLEY) {
      this.cardMessages = pursuitSilverGold(this.pointsToShow);
    }
    if (this.category === CUSTOMER_TYPE_SEGMENT_PLUS) {
      this.cardMessages = pursuitSilverGoldSinPlus(this.pointsToShow);
    }
    if (this.category === CUSTOMER_TYPE_SEGMENT_SILVER) {
      this.cardMessages = pursuitGold(this.pointsToShow);
    }
    if (this.category === CUSTOMER_TYPE_SEGMENT_GOLD) {
      this.cardMessages = congratsGold();
    }
  }

  private async getCategoryDescription(): Promise<void> {
    this.category = CUSTOMER_TYPE_SEGMENT_RIPLEY;
    try {
      const response = await this.rewardPointsService.getSegmentDirection();
      if (response && (response as any).responseCode === 'X1000') {
        this.categoryError = true;
      } else {
        this.segmentDirection = response;
      }
    } catch (_err) {
      //this.categoryError = true;
      this.segmentDirection = null;
      setTimeout(this.getCategoryDescription, DEFAULT_REQUEST_TIMEOUT);
    }
    if (this.segmentDirection && this.segmentDirection['retrieveSegmentStrategyCustomerResponse']) {
      this.category =
        this.segmentDirection['retrieveSegmentStrategyCustomerResponse']['customer']['type']['description'].toString().toLowerCase();
      this.pointsToShow = Number(this.segmentDirection['retrieveSegmentStrategyCustomerResponse']['account']['nOfPointsQualifying']);
      this.transformedPoints = transformToAmount(this.pointsToShow);
      this.pendingPoints =
        Number(this.segmentDirection['retrieveSegmentStrategyCustomerResponse']['account']['nOfPendingPoints']);

      this.categoria_src = '../assets/img/logo-categoria-' + this.category + '.svg';
    }
    this.setCategoryCardImage();
  }

  public goToCategoryBenefitsPage(categorySegment?: string) {
    const segment = categorySegment ? categorySegment : this.category;
    localStorage.setItem('origin', 'detail');
    let url = `/${RIPLEY_POINTS_GOLD_SILVER_BENEFITS_ROUTE}`;
    switch (segment) {
      case 'gold':
        url = `/${RIPLEY_POINTS_GOLD_BENEFITS_ROUTE}`;
        break;
      case 'silver':
        url = `/${RIPLEY_POINTS_SILVER_BENEFITS_ROUTE}`;
        break;
      case 'plus':
        url = `/${RIPLEY_POINTS_PLUS_BENEFITS_ROUTE}`;
        break;
    }
    this.router.navigateByUrl(url);
  }

  public viewConditions() {
    this.utilsService.openRedirectPath(RIPLEY_POINTS_SITE);
  }

  public get isPastTransitionBreakpoint(): boolean {
    return window.innerWidth <= this.transitionBreakpoint;
  }

  public setCategoryCardImage() {
    if (this.category === CUSTOMER_TYPE_SEGMENT_RIPLEY) {
      this.categoryCardClass = 'img-gold-and-silver-card';
    }
    if (this.category === CUSTOMER_TYPE_SEGMENT_SILVER) {
      this.categoryCardClass = 'img-card-silver-small';
    }
    if (this.category === CUSTOMER_TYPE_SEGMENT_GOLD) {
      this.categoryCardClass = 'img-card-gold-small';
    }
    if (this.category === CUSTOMER_TYPE_SEGMENT_PLUS) {
      this.categoryCardClass = 'img-card-plus-small';
    }
  }

  public get isSilverGoldCategory(): boolean {
    return this.category !== CUSTOMER_TYPE_SEGMENT_RIPLEY;
  }


  public async verifySlideIcons() {

    if (!this.slides) { return; }

    this.isBeginningSlide = await this.slides.isBeginning();

    this.isEndSlide = await this.slides.isEnd();

    const currentIndex = await this.slides.getActiveIndex();

    this.cardMessages[currentIndex];

    // this.activeCategoryButton = this.cardMessages[currentIndex];

  }



  public async previousSlide() {

    await this.slides.slidePrev();

    this.verifySlideIcons();

  }



  public async nextSlide() {

    await this.slides.slideNext();

    this.verifySlideIcons();

  }



  @HostListener('window:resize', ['$event'])

  public async onResize() {

    const milliseconds = 100;

    if (this.slides) { setTimeout(() => this.slides.update(), milliseconds); }

    this.verifySlideIcons();

  }

  public async openSignoutModal() {
    const signoutModal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: TOOLTIP_RIPLEY_POINTS_DASHBOARD,
    });
    await signoutModal.present();
    const option = await signoutModal.onDidDismiss();
  }
  public goToDashboard() {
    this.router.navigateByUrl(`/${RIPLEY_POINTS_DASHBOARD_ROUTE}`);
  }
  public verCondiciones() {
    this.utilsService.openRedirectPath(CLIENTES_PRIME_SITE);
  }
}
