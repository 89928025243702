import { Component, Input, OnInit } from '@angular/core';
import { NODEBT_PROPS } from '@common/constants/modal-props.constants';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-nodebt-modal',
  templateUrl: './nodebt-modal.component.html',
  styleUrls: ['./nodebt-modal.component.scss'],
})
export class NodebtModalComponent implements OnInit {

  title: string;
  message: string;
  @Input() product?: string;
  constructor(private viewController: ModalController) { }

  ngOnInit() {
    try {
      this.title = this.product === 'TC' ? 
      NODEBT_PROPS.newTitle : NODEBT_PROPS.title;
      this.message = this.product === 'TC' ? 
      NODEBT_PROPS.newMessage : NODEBT_PROPS.message;
    } catch (error) {
      this.title = NODEBT_PROPS.title;
      this.message = NODEBT_PROPS.message;
    }
  }

  public dismissModal() {
    this.viewController.dismiss();
  }

  public async GoToHome() {
    this.dismissModal();
  }
}
