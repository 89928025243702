import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DNI_LENGTH, DNI_SEGMENT, FOREIGN_CARD_LENGTH, FOREIGN_CARD_SEGMENT } from '@common/constants/document-type.constants';
import { EMAIL_REGEX } from '@common/constants/misc.constants';
import { TRANSFER_IMMEDIATE_ERROR_GENERAL, TRANSFERS_SCHEDULE_MODAL_PROPS } from '@common/constants/modal-props.constants';
import { ACCOUNT_NUMBER_MAX_LENGTH, ACCOUNT_NUMBER_MIN_LENGTH, MAX_TRANSFER_AMOUNT_PEN, MAX_TRANSFER_AMOUNT_USD, MAX_TRANSFER_IMMEDIATE_AMOUNT_PEN, MAX_TRANSFER_IMMEDIATE_AMOUNT_USD, PEN_BASE_CURRENCY, PEN_CODE_CURRENCY, PEN_SEGMENT, POSITIVE_DECIMAL_NUMBER_PATTERN, TRANSFER_MAX_AMOUNT_CODE_APP_PEN, TRANSFER_MAX_AMOUNT_CODE_APP_USD, TRANSFER_MAX_AMOUNT_CODE_PEN, TRANSFER_MAX_AMOUNT_CODE_USD, TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_APP_PEN, TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_APP_USD, TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_PEN, TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_USD, USD_SEGMENT } from '@common/constants/transfers.constants';
import { ISegmentSelector } from '@common/interfaces/default.interface';
import { IDetailsSavingAccount } from '@common/interfaces/savings.interface';
import { IInfoDetailTransfer, ITransferShiftSchedule } from '@common/interfaces/transfers.interface';
import { IUserInformation } from '@common/interfaces/user.interface';
import { GenericModalFooterComponent } from '@components/generic-modal-footer/generic-modal-footer.component';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { TransferModalOptionsComponent } from '@components/transfer-modal-options/transfer-modal-options.component';
import { TransfersScheduleModalComponent } from '@components/transfers-schedule-modal/transfers-schedule-modal.component';
import { ModalController, Platform } from '@ionic/angular';
import { PeruvianCurrencyPipe } from '@pipes/peruvian-currency/peruvian-currency.pipe';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { TransferService } from '@services/transfer/transfer.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-step-one-data-deferred-ob',
  templateUrl: './step-one-data-deferred-ob.component.html',
  styleUrls: ['./step-one-data-deferred-ob.component.scss'],
})
export class StepOneDataDeferredObComponent implements OnInit {

  accountNumberLength: number;
  transferMaxAmount: number;
  transferMinAmount: number;
  maxTransferAmountPEN: number;
  maxTransferAmountUSD: number;
  maxTransferImmediateAmountPEN: number;
  maxTransferImmediateAmountUSD: number;
  selectedAmountAccount: number;
  isValidAccount: boolean; 
  isKeyEnterDown: boolean; 
  isMaxAmountValidAmount: boolean; 
  isDeferredTransfer: boolean; //Falta establecer en qué momento será diferida
  desktop: boolean = false;
  notCTS: boolean;
  isValid:boolean;
  numCharacters: string;
  emailVoucher:string;
  limitAmmount: string;
  errorTransferMessage:string;
  originAccountDes: string;
  destinationAccount: string;
  documentNumberLength: number;
  documentNumberPlaceholder: string;
  dniSegment: ISegmentSelector;
  foreignCardSegment: ISegmentSelector;
  ownAccountSubscription: Subscription;
  beneficiaryLastNameSubscription: Subscription;
  beneficiaryNameSubscription: Subscription;
  beneficiaryDocumentNumber: Subscription;
  userDataForm: FormGroup; 
  errorModal: HTMLIonModalElement; 
  optionModal: HTMLIonModalElement;
  @Input() originAccounts:IDetailsSavingAccount[];
  @Input() transferInfo: IInfoDetailTransfer; 
  @Input() userInformation: IUserInformation; 
  @Output() sendInformationDeferred = new EventEmitter<{originAccountUpdate:IDetailsSavingAccount,transferInfo:IInfoDetailTransfer, valid:boolean}>();


  originAccountSelected:IDetailsSavingAccount;
  loadingSchedule: boolean;
  shiftsSchedule: ITransferShiftSchedule[];

  constructor(
    private modalErrorCtrl: ModalController,
    private formBuilder: FormBuilder, 
    private peruvianCurrency: PeruvianCurrencyPipe,
    private currencyPipe: CurrencyPipe,
    private transferService: TransferService,
    private platform: Platform,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    
    this.initializeVar();
 }

 initializeVar(){   
  this.numCharacters = "0"; 
  this.isDeferredTransfer = false; //Por defecto es inmediata
  this.notCTS = true; 
  this.isValid = false;
  this.dniSegment = DNI_SEGMENT;
  this.foreignCardSegment = FOREIGN_CARD_SEGMENT;
  this.maxTransferImmediateAmountPEN = MAX_TRANSFER_IMMEDIATE_AMOUNT_PEN;
  this.desktop = this.platform.is('desktop') || this.platform.is('mobileweb');
 }

  async ngOnInit() {
    this.createUserDataForm(); 
    console.log("Form");
  } 

  async ngAfterViewInit() {
    try {      
      console.log(" diferidas ",this.originAccounts);
      this.originAccountSelected = this.originAccounts[0]; 
      console.log(this.originAccountSelected);
      this.transferInfo.baseCurrency = this.updateCurrency(this.originAccountSelected.codeCurrency);
      this.transferInfo.originAccountName = this.originAccountSelected.productName;
      this.transferInfo.originAccount = this.originAccountSelected?this.originAccountSelected.account:'';
    } catch (e) {
      //console.error(e);
    }
  }

  get beneficiaryName() { return this.userDataForm.controls.beneficiaryName; }
  get beneficiaryLastName() { return this.userDataForm.controls.beneficiaryLastName; }
  get documentNumberBeneficiary() { return this.userDataForm.controls.documentNumberBeneficiary; }
  get documentType() { return this.userDataForm.controls.DocumentType; }
  get documentNumber() { return this.userDataForm.controls.DocumentNumber; }
  get originAccount() { return this.userDataForm.controls.originAccount; }
  get email() { return this.userDataForm.controls.email; } 
  get amount() { return this.userDataForm.controls.amount; }
  get baseCurrency() { return this.userDataForm.controls.baseCurrency; }
  get ownAccount() { return this.userDataForm.controls.ownAccount; }
  get peruvianCurrencySegment(): ISegmentSelector { return PEN_SEGMENT; }
  get dollarsCurrencySegment(): ISegmentSelector { return USD_SEGMENT; }

  isValidStep() {
     this.isValid = this.ownAccount.value=='0' ||
                   (this.ownAccount.value=='1' 
                    && this.documentNumberBeneficiary.valid
                    && this.beneficiaryLastName.value!=='' 
                    && this.beneficiaryName.value!==''
                    && this.documentNumberBeneficiary.value!=='');
     this.sendInformationDeferred.emit({originAccountUpdate:this.originAccountSelected,transferInfo:this.transferInfo, valid: this.isValid})
  }
  
    
  private updateCurrency(codeCurrency: string):string {
    return codeCurrency === PEN_CODE_CURRENCY ? this.peruvianCurrencySegment.value : this.dollarsCurrencySegment.value; 
  }

  private createUserDataForm() { 
     
    this.userDataForm = this.formBuilder.group({
      DocumentNumber: new FormControl('', Validators.compose([Validators.required, Validators.nullValidator])),
      DocumentType: new FormControl('', Validators.compose([Validators.required, Validators.nullValidator])),
      ownAccount: [{ value: null, disabled: false }, Validators.required],
      documentNumberBeneficiary: [{ value: '', disabled: false }, this.documentNumberValidators],
      beneficiaryName: [{ value: '', disabled: false }, Validators.required],
      beneficiaryLastName: [{ value: '', disabled: false }, Validators.required]    
    });
    console.log("Forms");
    this.changeCredentialType(this.dniSegment.value);
    this.subscribeToOwnAccount();
    this.subscribeBeneficiaryDocumentNumber();
    this.subscribeBeneficiaryName();
    this.subscribeBeneficiaryLastName();
  }

  private subscribeBeneficiaryName(){
    this.beneficiaryNameSubscription = this.beneficiaryName.valueChanges.subscribe((beneficiaryName: string) => {
      this.transferInfo.beneficiaryName = beneficiaryName;
      this.isValidStep();
    });
  }

  private subscribeBeneficiaryLastName(){
    this.beneficiaryLastNameSubscription = this.beneficiaryLastName.valueChanges.subscribe((beneficiaryLastName: string) => {
      this.transferInfo.beneficiaryLastName = beneficiaryLastName;
      this.isValidStep();
    });
  }
  private subscribeBeneficiaryDocumentNumber(){
    this.beneficiaryDocumentNumber= this.documentNumberBeneficiary.valueChanges.subscribe((beneficiaryDocumentNumber: string) => {
      this.transferInfo.documentNumberBeneficiary = beneficiaryDocumentNumber;
      this.isValidStep();
    });
  }

  private subscribeToOwnAccount() {
    this.ownAccountSubscription = this.ownAccount.valueChanges.subscribe((ownAccount: string) => {
      console.log(ownAccount); 
      if(ownAccount=='0'){
        this.transferInfo.beneficiaryName = this.userInformation.names;
        this.transferInfo.beneficiaryLastName = this.userInformation.lastName;
        this.transferInfo.documentTypeBeneficiary = this.userInformation.documentType;
        this.transferInfo.documentNumberBeneficiary = this.userInformation.documentNumber;
        this.transferInfo.ownAccount = true;
      }else{
        this.transferInfo.beneficiaryName = '';
        this.transferInfo.beneficiaryLastName = '';
        this.transferInfo.documentTypeBeneficiary = '';
        this.transferInfo.documentNumberBeneficiary = '';
        this.transferInfo.ownAccount = false;
      }
      this.isValidStep();
      // this.trasnferTypeCodeW = ownAccount?'01':'02'; Esto se tiene que guardar
       console.log("CHANGE OWN ACCOUNT", ownAccount);
     /*  if (ownAccount) {
         this.beneficiaryName.disable();
         this.beneficiaryLastName.disable();
         this.documentNumberBeneficiary.disable();
       } else {
         this.beneficiaryName.enable();
         this.beneficiaryLastName.enable();
         this.documentNumberBeneficiary.enable();
       }*/
     });
   }

   public async changeCredentialType(selectedType: string) {
    if (selectedType === this.dniSegment.value) {
      this.documentNumberLength = DNI_LENGTH;
      this.documentNumberPlaceholder = '12345678';
    } else {
      this.documentNumberLength = FOREIGN_CARD_LENGTH;
      this.documentNumberPlaceholder = '123456789';
    }
    this.documentType.patchValue(selectedType);
    this.transferInfo.documentTypeBeneficiary = selectedType;
  }


 
  private get documentNumberValidators(): ValidatorFn[] {
    console.log("document number lenght",this.documentNumberLength);
    return [
      Validators.required,
      Validators.minLength(this.documentNumberLength),
      Validators.maxLength(this.documentNumberLength),
    ];
  }

  public get myOriginAccounts() {   
    let list = this.originAccounts.filter(s => (s.isAvailableForInterbankTransfer===true));
    if(!this.isDeferredTransfer){
      //return this.savingAccountsInformation;      
      let listAux = list.filter(s => (s.isAvailableForImmediateInterbankTransfer===true));      
      return listAux;
      }
    return list; 
  }

 
  private formatAmount(value:any, baseCurrency?:string): any{   
    return ((baseCurrency!=undefined)?baseCurrency:this.baseCurrency.value) === PEN_BASE_CURRENCY ?
    this.peruvianCurrency.transform(value, 2) : this.currencyPipe.transform(value, USD_SEGMENT.symbol, USD_SEGMENT.symbol, '1.2-2');
  }

  public dismissModal() {
    this.modalErrorCtrl.dismiss();
  }

  updateClick(){
    console.log("CLICK");
   // this.updateEvent.emit(true);
  }

 updateEventClick(value:any){
    console.log("Update click");
    this.originAccountSelected = value.originAccount;
    this.transferInfo.originAccountName = value.originAccount.productName;
    this.transferInfo.destinationAccount = value.destinationAccount;   
    this.sendInformationDeferred.emit({originAccountUpdate:this.originAccountSelected,transferInfo:this.transferInfo, valid: this.isValid})
  }

  @HostListener('keydown.enter', ['$event']) onEnter(e) {  
    this.isKeyEnterDown = true;
    console.log("Key down enter");
  }
  @HostListener('click', ['$event']) onClick(e) {  
    this.isKeyEnterDown = false;
    console.log("Key down enter");
    
   // this.openTransferGenericFailModal();
  }
  
}
