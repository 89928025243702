import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferModalPage } from './offer-modal';

@NgModule({
  declarations: [
    OfferModalPage,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    OfferModalPage,
  ],
})
export class OfferModalPageModule {}
