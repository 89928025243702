import { Component, EventEmitter, Output, Input } from '@angular/core';
import { ITokenizedKeyboard } from '@common/interfaces/device.interface';
import { FormGroup, FormControl } from '@angular/forms';
import { LOGIN_PASSWORD_MAX_LENGTH } from '@common/constants/misc.constants';

@Component({
  selector: 'app-tokenized-keyboard',
  templateUrl: './tokenized-keyboard.component.html',
  styleUrls: ['./tokenized-keyboard.component.scss'],
})
export class TokenizedKeyboardComponent {

  @Input() tokenizedKeyboard: ITokenizedKeyboard;
  @Input() mainContainerClass: string;
  @Input() elementClass: string;
  @Input() hashCodeLabel: string;
  @Input() cleanClass: string;
  @Input() loginForm: FormGroup;
  @Input() fakePassword: FormControl;
  @Output() cleanValue: EventEmitter<void>;
  @Output() finishTypingEvent: EventEmitter<void>;

  constructor() {
    this.cleanValue = new EventEmitter();
    this.finishTypingEvent = new EventEmitter();
  }

  public emitCleanEvent() {
    this.cleanValue.emit();
  }

  public updateForm(hash: string) {
    this.fakePassword.patchValue(this.fakePassword.value + 0);
    this.loginForm.patchValue({
      [`${this.hashCodeLabel}${this.fakePassword.value.length}`]: hash,
    });
    if (this.fakePassword.value.length === LOGIN_PASSWORD_MAX_LENGTH) {
      this.finishTypingEvent.emit();
    }
  }

}
