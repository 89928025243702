import { Injectable } from '@angular/core';
import { ALPHANUMERIC_LENGTH } from '@common/constants/encryption.constants';
import * as _ from 'lodash';
import { RESCUE_IDENTIFIER } from '@common/constants/auth.constants';
import {
  EARTH_DIAMETER_IN_KM,
  MATH_RADIANS,
  INFORMATIVE_MODALS_VIEWED,
  MOBILE_BREAKPOINT,
  CHANNEL_CODE,
  CHANNEL_CODE_PWA_APP
} from '@common/constants/misc.constants';
import { ICalendarEvent } from '@common/interfaces/calendar.interface';
import { PdfService } from '@services/pdf/pdf.service';
import { AlertController } from '@ionic/angular';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { AlertService } from '@services/alert/alert.service';
import { Router } from '@angular/router';
import { DEFAULT_MSG_ERROR, HEADER_ERROR } from '@common/utils/utils.constants';
import { FirebaseLoggerService } from '@services/firebase-logger/firebase-logger.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Platform, ToastController } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { CLOSED_MASTERCARD_SUB_PRODUCT_CODES } from '@common/constants/product.constants';
import { Coordinates } from '@ionic-native/geolocation/ngx';
import { format } from 'date-fns';
import { Platforms } from '@ionic/core/dist/types/utils/platform';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@ionic-native/file/ngx';
import { formatDate } from '@angular/common';
import { IChannelfunctionality } from '@common/interfaces/user.interface';
import { RPassInitiative } from '@common/models/digital-auth';
import { IPHONE_GENERATION_INFO } from '@common/models/iphone-generation-info';

const FOUR = 4;

@Injectable()
export class UtilsService {

  static pdfService: PdfService;
  public nativeAndroid: boolean = false;
  public nativeIos: boolean = false;
  public iosWeb: boolean = false;
  public desktop: boolean = false;
  public androidWeb: boolean = false;

  constructor(
    private platform: Platform,
    private fileOpener: FileOpener,
    private alertService: AlertService,
    private alertCtrl: AlertController,
    private sanitizer: DomSanitizer,
    private router: Router,
    private firebaseLoggerService: FirebaseLoggerService,
    private toastController: ToastController,
    private socialSharing: SocialSharing,
    private device: Device,
    private file: File,
  ) {
    this.nativeAndroid = (this.platform.is('cordova') && this.platform.is('android'));
    this.nativeIos = (this.platform.is('cordova') && this.platform.is('ios'));
    this.iosWeb = (this.platform.is('ios') && this.platform.is('mobileweb'));
    this.androidWeb = (this.platform.is('android') && this.platform.is('mobileweb'));
    this.desktop = this.platform.is('desktop');
    
  }

  static getRandomString(size: number) {
    return _.times(
      size,
      () => _.random(ALPHANUMERIC_LENGTH - 1).toString(ALPHANUMERIC_LENGTH)
    ).join('');
  }

  static encodeJSONToWWWUrlForm(object: object, extraFixedOptions: string = '') {
    let urlEncoded = '';
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        urlEncoded += encodeURIComponent(key) + '=' + encodeURIComponent(object[key]) + '&';
      }
    }
    urlEncoded += extraFixedOptions;
    return urlEncoded;
  }

  // tslint:disable:no-magic-numbers no-bitwise
  static getUUIDV4() {
    let date = new Date().getTime();
    let newDate = (performance && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let random = Math.random() * 16;
        if (date > 0) {
          random = (date + random) % 16 | 0;
          date = Math.floor(date / 16);
        } else {
          random = (newDate + random) % 16 | 0;
          newDate = Math.floor(newDate / 16);
        }
        return (c === 'x' ? random : (random & 0x3 | 0x8)).toString(16);
    });
  }

  static getDistanceFromLatLonInKm(firstCoordinate: Coordinates, secondCoordinate: Coordinates) {
    const cos = Math.cos;
    const haversine = 0.5 - cos((secondCoordinate.latitude - firstCoordinate.latitude) * MATH_RADIANS) / 2 +
      cos(firstCoordinate.latitude * MATH_RADIANS) * cos(secondCoordinate.latitude * MATH_RADIANS) *
      (1 - cos((secondCoordinate.longitude - firstCoordinate.longitude) * MATH_RADIANS)) / 2;
    return EARTH_DIAMETER_IN_KM * Math.asin(Math.sqrt(haversine));
  }

  static addDistanceUnit(distance: number) {
    const unit = distance < 1 ? ' mts' : ' km';
    const kmInMeters = 1000;
    const fixedDistance = distance < 1 ? Math.ceil((distance * kmInMeters)) : Number(distance.toFixed(1));
    return fixedDistance + unit;
  }

  static formatDateForGoogleCalendar(paymentDate) {
    const DATE_REGEX_FOR_G_CALENDAR = /(-)|(T.*)/g;
    const convertedDate = UtilsService.dateConvert(paymentDate);
    const dateEnd = new Date(convertedDate.getTime() + 24 * 60 * 60 * 1000);
    return `${convertedDate.toJSON().replace(DATE_REGEX_FOR_G_CALENDAR, '')}` +
      `/${dateEnd.toJSON().replace(DATE_REGEX_FOR_G_CALENDAR, '')}`;
  }
  // tslint:enable:no-magic-numbers no-bitwise

  static setInformativeModalsViewed() {
    sessionStorage.setItem(INFORMATIVE_MODALS_VIEWED, 'true');
  }

  static getIdentifier(): number {
    return JSON.parse(sessionStorage.getItem(RESCUE_IDENTIFIER)) || '';
  }

  static getGoogleEventLink(calendarEvent: ICalendarEvent) {
    return 'https://calendar.google.com/calendar/render' +
      '?action=TEMPLATE' +
      `&text=${encodeURIComponent(calendarEvent.title)}` +
      `&details=${encodeURIComponent(calendarEvent.description)}` +
      `&dates=${this.formatDateForGoogleCalendar(calendarEvent.start)}` +
      '&trp=false';
  }

  static dateConvert(dateString) {
    const [day, month, year] = dateString.split('/');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    return date;
  }

  static showRecaptchaBadge() {
    document.body.classList.add('recaptcha');
  }

  static hideRecaptchaBadge() {
    document.body.classList.remove('recaptcha');
  }

  static isPdfUrl(url: string): boolean {
    return url.includes('.pdf');
  }

  static viewportLabel(): string {
    return window.innerWidth > MOBILE_BREAKPOINT ? 'Desktop' : 'Mobile';
  }

  static isDesktop(): boolean {
    return window.innerWidth > MOBILE_BREAKPOINT;
  }

  static checkOnlyNumbers($event: any) {
    const regex = /[0-9]+/g;
    const resp = $event.target.value.match(regex);
    return $event.target.value = resp ? resp.join('') : '';
  }

  static OStype() {
    let OSName = 'Unknown OS';
    if (navigator.appVersion.indexOf('Win') !== -1) {
      return 'Windows';
    }
    if (navigator.appVersion.indexOf('Mac') !== -1) {
      return 'MacOS';
    }
    if (navigator.appVersion.indexOf('X11') !== -1) {
      return 'UNIX';
    }
    OSName = navigator.userAgent || navigator.vendor;
    if (/windows phone/i.test(OSName)) {
      return 'Windows Phone';
    }
    if (/android/i.test(OSName)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(OSName)) {
      return 'iOS';
    }
    if (navigator.appVersion.indexOf('Linux') !== -1) {
      return 'Linux';
    }
    return OSName;
  }

  static getIphoneGenerationInfo(model: string): string {
    return IPHONE_GENERATION_INFO.find(x => x.identifier.toLowerCase() === model.toLowerCase()).generation || model;
  }

  static capitalizeWords(text: string) {
    return text.toLowerCase().replace(/(?:^|\s)\S/g, (word) => word.toUpperCase());
  }

  static formatUserId(dNumber: string, dType: string) {
    return `${dType}-${dNumber}`;
  }

  static sortArrayByKey<T extends { [K in keyof T]: number }>(array: T[], key: keyof T): T[] {
    return [...array].sort((a, b) => a[key] - b[key]);
  }

  static isClosedMastercard(subProductCode: string) {
    return CLOSED_MASTERCARD_SUB_PRODUCT_CODES.includes(subProductCode);
  }

  static ofuscateCardNumber(cardNumber: string) {
    return 'XXXX XXXX XXXX ' + cardNumber.substr(-FOUR);
  }

  public async showError(error?: string, header?: string, button?: string) {
    const alert = await this.alertCtrl.create({
      header: header || HEADER_ERROR,
      subHeader: error || DEFAULT_MSG_ERROR,
      buttons: [button || 'Cerrar']
    });
    await alert.present();
  }
  public async showErrorCustomAction(error: string, customAction, button?: string): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: HEADER_ERROR,
      subHeader: error || DEFAULT_MSG_ERROR,
      buttons: [button || 'Cerrar']
    });
    alert.onDidDismiss().then(() => customAction());
    await alert.present();
  }
  public formatDate(date: Date, formatDate: string = 'DD/MM/YYYY'): string {
    return format(date, formatDate);
  }

  public changeTimezone(date, ianatz = 'America/Santiago', reverse: boolean = false) {
    const invdate = new Date(date.toLocaleString('en-US', {
      timeZone: ianatz
    }));
    const diff = date.getTime() - invdate.getTime();
    return reverse ? new Date(date.getTime() - diff) : new Date(date.getTime() + diff);
  }

  public openRedirectPath(url: string) {
    UtilsService.isPdfUrl(url) ? this.openPdf(url) : window.open(url, '_blank');
  }

  public openPdf(pdfUrl: string): void {
    if (!this.nativeAndroid) {
      window.open(pdfUrl, '_blank');
    } else {
      if (pdfUrl.includes('https://')) {
        window['nativeOpen'](pdfUrl, '_system');
      } else {
        this.fileOpener.open(pdfUrl, 'application/pdf')
          .catch(() => {
            this.alertService.openErrorAlert(this.router.url, false, true);
          });
      }
    }
  }

  public isNative(): boolean {
    return this.platform.is('cordova');
  }

  public isNativePlatform(platform: Platforms): boolean {
    return this.platform.is('cordova') && this.platform.is(platform);
  }

  public isIpadDevice() {
    return this.device.model ? this.device.model.toLowerCase().indexOf('ipad') != -1 && this.device.platform.toLowerCase() == 'ios' : false;
  }

  public getNativeStoreUrl(): string {
    if (this.isNativePlatform('android')) {
      return 'com.ripley.banco.peru';
    } else if (this.isNativePlatform('ios')) {
      return 'id1425352352';
    }
  }

  public getChannelCode(): string {
    return this.isNative() === true ? CHANNEL_CODE_PWA_APP : CHANNEL_CODE;
  }

  public sanitizeHtml(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public getHtmlContent(html) {
    return html.replace(/<\/?[^>]+(>|$)/g, '');
  }

  public getScssUrl(url: string) {
    if (url) {
      return 'url(\'' + url.trim() + '\')';
    }
    return 'url(undefined)';
  }

  public async shareData(data: string, title: string = null, file: string = null, url: string = null): Promise<void> {
    if (this.platform.is('cordova')) {
      this.socialSharing.share(data, title, file, url)
        .catch(() => {
          this.alertService.simpleMessageError('Error', 'Ocurrió un error al momento de compartir los datos.');
        });
    } else {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = url ? data + '\n' + url : data;
      document.body.appendChild(selBox);
      selBox.select();
      selBox.focus();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      const toast = await this.toastController.create({
        message: 'Tus datos han sido copiados al portapapeles.',
        duration: 2500
      });
      toast.present();
    }
  }
  
  public delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public getDeviceOS(): string {
    return this.device.platform || '';
  }

  public async safePromise(promise: Promise<any>) {
    return promise.then((data) => [ data ]).catch((error) => [ null, error ]);
  }

  public async downloadVoucher(data){
    if(this.androidWeb || this.iosWeb || this.desktop){
      //código para Web
     
      const link = document.createElement('a');
      link.download = 'voucher.png';
      link.href = data;
      link.click();
    } else if(this.nativeIos){
      //código para iOS
      
      this.socialSharing.saveToPhotoAlbum(data)
      .then(() => {
        this.showMessage(1);
      })
      .catch(() => {
        this.showMessage(0);
      })
    } else if(this.nativeAndroid){
      //código para android
    

       try{
           
          let blopp = this.base64toBlob(data,'image/png');
          let path = this.file.externalDataDirectory || this.file.dataDirectory +  '/voucher.png'; 
         
          let files:File = new File();                  
         
           files.writeFile(path,'voucher.png',blopp, { replace: true } ).then(()=>{        
              let newPath = path.replace('data','media');            
                this.file.createDir(newPath.substring(0,newPath.indexOf('files')-1),'files',true).then(()=>{
                  let newFileName ='voucher' + formatDate(Date.now(),'yyyyMMddhhmmss','en-US') + '.png';     
                  files.writeFile(newPath,newFileName,blopp, { replace: true } ).then(()=>{
                    this.showMessage(1);               
                    this.file.removeFile(path,'voucher.png'); 
                  });

                }).catch((err)=>{
                  this.showMessage(0);
                });
                       
            }).catch((e)=>{
              this.showMessage(0);
            });      
    
          }catch(err){
            this.showMessage(0);
          }
    } 
} 

public async showMessage(success: number){
    switch (success)
    {
      case 0:{
        this.showError(
          'No se pudo guardar la constancia en tu dispositivo.',
          'Error',
          'Cerrar'
          );
          break;
      }
      case 1:{
        this.showError(
          'La constancia se guardó en tu dispositivo.',
          'Éxito',
          'Entendido'
          );
          break;
      }
    }

}

 base64toBlob(b64Data:string, contentType): Blob { 
    contentType = contentType || "";
    const sliceSize = 1024;
    let cadena: string[] = b64Data.split(',');
    
    var byteCharacters = atob(cadena[1]);
    var byteArrays = [];
  
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
  
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      var byteArray = new Uint8Array(byteNumbers);
  
      byteArrays.push(byteArray);
    }
  
  
    return new Blob(byteArrays, { type: contentType });
    //return new File(byteArrays, "pot", { type: contentType });
 }

 getUserPermission(channelFunctionality: IChannelfunctionality[], userPermissions: string, codefuntionality:string):boolean{

  try {
    let functionality = channelFunctionality.filter(funtionality => funtionality.shortName === codefuntionality)
    if(userPermissions!==null){
      let listPermisionCode = userPermissions.split(','); 
      let permissionCode = listPermisionCode.find(item => item === codefuntionality);
      if (functionality.length>0){
        return ((permissionCode!=undefined && functionality[0].testState)||(permissionCode===undefined && !functionality[0].testState));
      }else{
        return true;
      }    
    }else if(functionality.length>0){
      //Si el estado de la funcionalidad es true( Está en prueba) 
      //entonces no tiene permisos para esa funcionalidad   
      return functionality[0].testState?false:true;
    }  
    return false;
    
  } catch (error) {
    false;
  }
  
}

TakeOffAccentMark(cadena:string){
  const tildes = {
    'á': 'a',
    'é': 'e',
    'í': 'i',
    'ó': 'o',
    'ú': 'u',
    'ü': 'u',
    'ñ': 'n',
    'Á': 'A',
    'É': 'E',
    'Í': 'I',
    'Ó': 'O',
    'Ú': 'U',
    'Ü': 'U',
    'Ñ': 'N',
  };
  return cadena.replace(/[áéíóúüñÁÉÍÓÚÜÑ]/g, (match) => tildes[match] || match);
}

  public getRpassInitiativeState(featureKey: string, initiatives: RPassInitiative[]): boolean {
    return !!(initiatives.find((initiative: RPassInitiative) => initiative.featureKey === featureKey).enabled);
  }

    public async until(conditionFunction) {
      const poll = resolve => {
        if(conditionFunction()) resolve();
        else setTimeout(_ => poll(resolve), 100);
      }
      return new Promise(poll);
    }

}

export const LOTTIE_CONFIG = {
  path: 'assets/json/loading-spinner-pwa.json',
  autoplay: true,
  loop: true,
  renderer: 'svg',
  sizes: {
    big: {
      height: 120,
      width: 120
    },
    small: {
      height: 94,
      width: 94
    }
  }
};

