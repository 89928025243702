import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-tcea',
  templateUrl: './tcea.component.html',
  styleUrls: ['./tcea.component.scss'],
})
export class TceaComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() message: string;
  @Input() dynamicSource: Observable<string>;
  @Input() extraTitleClass?: string;
  subscription: Subscription;
  fadeOut: boolean;
  isActiveModal: boolean;
  hide: boolean;

  constructor(
    private viewController: ModalController,
    ) {
      this.title =  '';
      this.message = '';
     }

     ngOnInit() {
      this.subscription = new Subscription();
      if (this.dynamicSource) {
        this.subscription.add(
          this.dynamicSource.subscribe((message) => {
            this.message = message;
          })
        );
      }
    }

    dismissModal() {
      this.viewController.dismiss();
      const second = 1000;
      this.fadeOut = true;
      setTimeout(() => {
        this.hide = true;
      }, second);
      this.isActiveModal = false;
    }

    ngOnDestroy() {
      this.subscription.unsubscribe();
    }
}
