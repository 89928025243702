import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ONBOARDING_ELEMENTS } from '@common/constants/onboarding.constants';
import { ENROLLMENT_ROUTE } from '@common/constants/routes.constants';
import { ENROLL_REGISTER } from '@common/constants/tag/tag-enrollment.constants';
import { IComponentProp } from '@common/interfaces/modal.interface';
import { IonSlides } from '@ionic/angular';
import { UtilsService } from '@services/utils/utils';

@Component({
  selector: 'app-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss'],
})
export class OnboardingModalComponent {

  fadeOut: boolean;
  hide: boolean;
  slideIsMoving: boolean;
  currentElement: number;
  onboardingElements: IComponentProp[];
  slideOptions: object;
  @Output() showOnboarding: EventEmitter<boolean>;
  @ViewChild(IonSlides) slides: IonSlides;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
  ) {
    this.fadeOut = false;
    this.hide = false;
    this.currentElement = 0;
    this.showOnboarding = new EventEmitter();
    this.slideIsMoving = false;
    this.onboardingElements = ONBOARDING_ELEMENTS;
    this.createSliderOptions();
  }

  get isLastElement() {
    return (this.onboardingElements.length - 1) === this.currentElement;
  }

  get isFirstElement() {
    return this.currentElement === 0;
  }

  get isEvenElement() {
    return this.currentElement % 2 === 0;
  }

  public goToEnrollment() {
    this.router.navigateByUrl(ENROLLMENT_ROUTE);
    this.dismissModal();
  }

  public updateElement(amount: number) {
    const newElement = this.currentElement + amount;
    if (newElement < this.onboardingElements.length && newElement >= 0) {
      this.currentElement = newElement;
    }
  }

  public async changeSlideManually(action: string) {
    if (!this.slideIsMoving) {
      if (action === 'right' && !this.isLastElement) {
        this.slideIsMoving = true;
        await this.slides.slideNext();
      } else if (action === 'left' && !this.isFirstElement) {
        this.slideIsMoving = true;
        await this.slides.slidePrev();
      }
    }
  }

  public async changeSlide() {
    const [previous, current] = await Promise.all([
      this.slides.getPreviousIndex(),
      this.slides.getActiveIndex(),
    ]);
    const direction = previous - current;
    direction < 0 ? this.updateElement(1) : this.updateElement(-1);
    this.slideIsMoving = false;
  }

  public dismissModal() {
    const second = 1000;
    this.fadeOut = true;
    this.showOnboarding.emit(true);
    setTimeout(() => {
      this.hide = true;
    }, second);
  }

  private createSliderOptions() {
    this.slideOptions = {
      breakpointsInverse: true,
      allowTouchMove: true,
      touchReleaseOnEdges: true,
      breakpoints: {
        901: {
          allowTouchMove: false
        }
      }
    };
  }
}
