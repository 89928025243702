import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable()
export class StorageService {

  constructor(private ionicStorage: Storage) {}

  public get(id: string) {
    return this.ionicStorage.get(id);
  }

  public set(id: string, data: any) {
    return this.ionicStorage.set(id, data);
  }

  public remove(id: string) {
    return this.ionicStorage.remove(id);
  }

  public clear() {
    return this.ionicStorage.clear();
  }

}
