import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-summary',
  templateUrl: './card-summary.component.html',
  styleUrls: ['./card-summary.component.scss'],
})
export class CardSummaryComponent implements OnInit {

  @Input() mainDetailLabel: string;
  @Input() mainDetailValue: string;
  @Input() subDetailLabel: string;
  @Input() subDetailValue: string;

  constructor() { }

  ngOnInit() {}

}
