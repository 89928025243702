import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'aditional-terms-modal',
  templateUrl: './aditional-terms-modal.component.html',
  styleUrls: ['./aditional-terms-modal.component.scss'],
})
export class AditionalTermsModalComponent {


  mensaje = 'Recuerda que al realizar tu pago del mes, tambien estarás cubriendo la deuda de tus adicionales';
  @Input() title: string;
  @Input() account: string;
  @Input() primaryButtonText: string;
  @Input() secondaryButtonText: string;
  constructor(
    private modalController: ModalController,
  ) { }

  dismissModal() {
    this.modalController.dismiss();
  }

  onPrimaryClick() {
    this.modalController.dismiss('primaryButtonPressed');
  }


}
