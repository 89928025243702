import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UtilsService } from '@services/utils/utils';

@Component({
  selector: 'page-flex',
  templateUrl: 'flex.html',
})
export class FlexPage {
  public static readonly pageName = 'FlexPage';
  @Input() public user: any;
  @Input() public finalScreen: boolean;
  @Input() public screen: any;
  @Output() public goTo: EventEmitter<any>;
  @Output() public exit: EventEmitter<any>;
  public campaign: any;
  public nextButtonText: string;

  constructor(
    public utils: UtilsService,
  ) {
    this.goTo = new EventEmitter();
    this.exit = new EventEmitter();
  }

  get showFixedButton() {
    return !!this.screen.button.show && !!this.screen.button.fixed;
  }

  public triggerGoTo(page, params) {
    this.goTo.emit({page, params});
  }

  public triggerExit() {
    this.exit.emit();
  }

}
