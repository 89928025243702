import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { createPopper, VirtualElement } from '@popperjs/core';
import { SCREEN_WIDTH } from '@common/constants/misc.constants';
import { ModalController, PopoverController } from '@ionic/angular';
import { CvvHelperCardComponent } from '../cvv-helper-card/cvv-helper-card.component';
import { CvvHelperPopoverComponent } from '../cvv-helper-popover/cvv-helper-popover.component';
@Component({
  selector: 'app-cvv-helper',
  templateUrl: './cvv-helper.component.html',
  styleUrls: ['./cvv-helper.component.scss'],
})
export class CvvHelperComponent implements OnInit, OnDestroy {

  @Input() element: Element; // decorate the property with @Input()
  @Input() event: Event; // decorate the property with @Input()

  ngOnInit(): void {
  }

  modal: HTMLIonModalElement
  popover: HTMLIonPopoverElement

  showPopover = false
  closeSheetTimeout = 1000*3

  constructor(public popoverController: PopoverController, public modalController: ModalController) {

  }
  get isMobile() {
    return window.innerWidth < SCREEN_WIDTH.TABLET;
  }
  openHelper() {
    if (this.isMobile) {
      this.openSheetModal()
    } else {
      this.presentPopover()
    }
  }

  async openSheetModal() {
    this.modal = await this.modalController.create({
      component: CvvHelperCardComponent,
      showBackdrop: true,
      mode: "ios",
      cssClass: 'cvv-helper-sheet-modal'
    });

    await this.modal.present();

    const closeTimeout=  setTimeout(()=>{
      this.modal.dismiss()
    }, this.closeSheetTimeout)
    
    await this.modal.onDidDismiss()
    clearTimeout(closeTimeout)
  }
  async presentPopover() {
    this.popover = await this.popoverController.create({
      component: CvvHelperPopoverComponent,
      cssClass: 'ripley-popover',
      componentProps: {
        element: this.element
      },
      mode: 'ios',
      showBackdrop: true,
    });
    await this.popover.present();
  }

  ngOnDestroy(): void {
    if(this.popover){
      this.popover.dismiss()
    }
    if(this.modal){
      this.modal.dismiss()
    }
  }

}

