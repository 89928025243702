import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { IUserInformation } from '@common/interfaces/user.interface';
import { ModalController } from '@ionic/angular';
import { UserService } from '@services/user/user.service';
import { CREDITS_ROUTE } from '@common/constants/routes.constants';

@Component({
  selector: 'app-lead-success',
  templateUrl: './lead-success.component.html',
  styleUrls: ['./lead-success.component.scss'],
})
export class LeadSuccessComponent implements OnInit {

  userInformation: IUserInformation;
  @Input() showIcon: boolean;
  @Input() title: string;
  @Input() productName: string;

  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private router: Router
  ) {
    this.userInformation = this.userService.userInformation || {} as IUserInformation;
  }

  dismissModal() {
    this.modalController.dismiss();
    return this.router.navigateByUrl(`/${CREDITS_ROUTE}`);
  }

  ngOnInit() {}

}
