import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FirebaseService } from '@services/firebase/firebase.service';
import { StorageService } from '@services/storage/storage.service';
import { UtilsService } from '@services/utils/utils';
import { version } from 'package.json';
import {
  PLATFORMS,
  PRIORITY,
  NAVIGATE_TYPE
} from '@common/utils/utils.constants';
import { Router } from '@angular/router';
import { FOOTER_BUTTONS_DEFAULT_VALUE } from '@common/constants/firebase.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-footer-login',
  templateUrl: './footer-login.component.html',
  styleUrls: ['./footer-login.component.scss'],
})
export class FooterLoginComponent implements OnInit {
  isNative: boolean;
  isNativeAndroid: boolean;
  isMobileWeb: boolean;
  buttonFooter: any[] = [];
  loadingDemo: Boolean = true;
  slideOptions = {
    slidesPerView: 3,
    spaceBetween: 30,
    slidesPerGroup: 3,
    loop: false,
    loopFillGroupWithBlank: true
  }
  nfcAccepted: any;
  isTotem: any;
  menuBarIsLoading: boolean;

  footerOptionsAll: any[] = [];

  constructor(
    private firebaseService: FirebaseService,  
    private cdr: ChangeDetectorRef,
    private storage: StorageService,
    private utils: UtilsService,
    private platform: Platform,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
    ) {
      this.isNative = this.platform.is('cordova');
      this.isMobileWeb = this.platform.is('mobileweb');
      this.isNativeAndroid = this.utils.nativeAndroid;
   }

  async ngOnInit() {
  }

  async ngAfterViewInit() {
    await this.loadButtons();
    this.loadingDemo = false;
    this.cdr.detectChanges();
}

  public isNfcLoading(button: any) {
    return button.navigateTo === 'nfc' && this.menuBarIsLoading;
  }

  private getPrioritytoUse(): string {
    if (this.utils.nativeIos) { return PRIORITY.DEFAULT; }
    if (this.utils.nativeAndroid) {
      if (this.nfcAccepted) {
        return PRIORITY.ANDROID;
      }
      return PRIORITY.ANDROID_NO_NFC;
    }
    if (this.isMobileWeb) { return PRIORITY.WEB; }
    return 'priority';
  }

  private isFooterButtonAllowed() {
    if (this.utils.nativeAndroid) {
      if (this.nfcAccepted) {
        return PLATFORMS.NFC;
      }
      return PLATFORMS.ANDROID;
    }
    if (this.utils.nativeIos) {
      return PLATFORMS.IOS;
    }
    if (this.platform.is('mobileweb') && !this.isNative) {
      return PLATFORMS.WEB;
    }
    return '';
  }

  public getClassButton(icon) {
    return 'icon ' + icon;
  }

  public async loadButtons() {
    try {
      if (this.isNativeAndroid) {
        this.nfcAccepted = await this.storage.get('nfcAccepted');  
      }
      const nVersion = Number(version.replace(/\./g, ''));      
      const footerGetButtons = await this.firebaseService.getFirebaseCollection('appData/footer-mobile/buttons-v2')
        .where('footer-version.version', '<=', nVersion)
        .where('footer-version.visible', '==', true)
        .get();  
      
      let footerButtonsData = footerGetButtons.docs.map(el => {
        return el.data();
      });

      if(footerButtonsData.length == 0) {
        this.setButtonFooterData(FOOTER_BUTTONS_DEFAULT_VALUE);  
      } else {
        this.setButtonFooterData(footerButtonsData);
      }
      
    } catch (error) {
      this.setButtonFooterData(FOOTER_BUTTONS_DEFAULT_VALUE);
    }
  }

  setButtonFooterData(inputDataFooter) {
    this.footerOptionsAll = inputDataFooter;
    let priority = this.getPrioritytoUse()

    this.footerOptionsAll = this.footerOptionsAll.sort(function(a, b){
      return a[priority] - b[priority];
    });

    this.buttonFooter = this.footerOptionsAll.filter(e => e.platform.includes(this.isFooterButtonAllowed()));
  }

  /*
  public async startPayment() {
    this.menuBarIsLoading = true;
    const button = this.buttonFooter.find((button) => button.navigateTo === 'nfc');
    const auxTitle = button.title;
    button.title = 'Cargando ...';
    const nfcAccepted = await this.storage.get('nfcAccepted');
    if (!nfcAccepted) {
      this.handlePaymentCardNotFound();
      return;
    }
    await this.virtualCardService.initialize();
    await this.virtualCardService.authenticate();
    const canActivate = await this.virtualCardService.checkCanActivatePaymentExternal(this.defaultNfcPayment, true);
    this.menuBarIsLoading = false;
    button.title = auxTitle;
    if (!canActivate) { return; }
    this.showNfcPaymentModal();
  }
  */

  public navigateToRoute(footerButton: any) {

    this.googleAnalyticsService.footerTag(footerButton.navigateTo);

    if (footerButton.navigateType === NAVIGATE_TYPE.INTERNAL) {
      this.router.navigate([footerButton.navigateTo]);
    } else if (footerButton.navigateType === NAVIGATE_TYPE.EXTERNAL) {
      window.open(footerButton.navigateTo, '_system');
    } else if (footerButton.navigateType === NAVIGATE_TYPE.MODAL) {
      if (footerButton.navigateTo === 'nfc') {
        if (this.menuBarIsLoading) { return; }
        /*
        this.utils.pushEventToDataLayer(
          'flujo_nfc_event', 'PagoSinContactoLogin', 'step_1', 'clic_btn_pago_sin_contacto'
        );
        */
        //this.startPayment();
        return;
      }
    }
    if (footerButton.dataLayer) {
      /*
      this.utils.pushEventToDataLayer(
        footerButton.dataLayer.event,
        footerButton.dataLayer.category,
        footerButton.dataLayer.action,
        footerButton.dataLayer.label
      );
      */
    }
  }

}

