export const IDNOW_SDK_NAME = 'idNowSecuritySuite';

export const IDNOW_SDK_SECRET = 'secret';

export const IDNOW_SUCCESS_CODE = '00';

export const IDNOW_DEVICE_STATUS = {
    ENROLLED: 'R',
    BLOCKED: 'B',
    NOT_FOUND: 'NF',
    INCOMPLETE: 'I',
};