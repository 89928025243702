import { Component, Input } from '@angular/core';
import { ITableData } from '@common/interfaces/default.interface';
import { ModalController } from '@ionic/angular';
import { SAVINGS_MOVEMENTS_MODAL_PROPS } from '@common/constants/modal-props.constants';
import { IMovementSavingAccount } from '@common/interfaces/savings.interface';
import { SCREEN_WIDTH } from '@common/constants/misc.constants';
import { SavingsModalComponent } from '@components/savings-modal/savings-modal.component';

@Component({
  selector: 'app-description-table',
  templateUrl: './description-table.component.html',
  styleUrls: ['./description-table.component.scss'],
})
export class DescriptionTableComponent {

  @Input() tableHeader: ITableData[];
  @Input() tableData: ITableData[][];
  @Input() emptyMessage: string;
  @Input() account: string;
  @Input() cardType: string;
  @Input() isAditional: boolean;

  constructor(
    private modalCtrl: ModalController,
  ) {
    this.tableHeader = [];
    this.tableData = [[]];
  }

  
  public getColorMovement(information: any) {   
      return 'default-color';   
  }

  async ionViewWillEnter() {

  }

  get isMobile() {
    return window.innerWidth < SCREEN_WIDTH.TABLET;
  }

  public async showMovementDetailModal(data: string) {    
    const dataJson: IMovementSavingAccount = JSON.parse(data);   
    const props = {
      title: SAVINGS_MOVEMENTS_MODAL_PROPS.title,
      primaryButtonText: SAVINGS_MOVEMENTS_MODAL_PROPS.primaryButtonText,
      movement: dataJson,
      account: this.account
    };
    const modal = await this.modalCtrl.create({
      component: SavingsModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

  showInfodetail(info){
   /* let jsonstring = JSON.stringify(info);
    jsonstring=jsonstring.replace("\\","");
    console.log(jsonstring);
    console.log(document.getElementById("detailInfo"));*/
    this.showMovementDetailModal(info);
  }

  get isLargeTable() { return this.tableHeader.length > 3; }
}
