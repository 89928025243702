import { USER_INFORMATION_MOCK, HASH_USER_INFORMATION_MOCK } from '@common/mocks/user.mocks';
import { IUserInformation, IUserHashInformation } from '@common/interfaces/user.interface';
import { IHashInformation } from '@common/interfaces/general.interface';
import { HttpService } from '@services/http/http.service';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TitleCasePipe } from '@pipes/title-case/title-case.pipes';
import { FirebaseService } from '@services/firebase/firebase.service';
import { IDevice } from '@common/interfaces/device.interface';
import * as firebase from 'firebase/app';
import { NavigationCheckerService } from '@services/navigation/navigation-checker.service';
import { IUserLogin } from '@common/interfaces/auth.interface';
import { USER_INFO } from '@common/constants/auth.constants';
import { Storage } from '@ionic/storage';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  userInformation: IUserInformation;
  userInformationPromise: Promise<IUserInformation>;

  constructor(
    private http: HttpService,
    private firebaseService: FirebaseService,
    private navCheckerSrv: NavigationCheckerService,
    private storage: Storage
  ) { }

  public async getUserInformation() {
    if(this.navCheckerSrv.InLogin()){
      this.userInformation = undefined;
      this.userInformationPromise = undefined;
    }

    if(this.userInformation) {
      return this.userInformation;     
    }

    const url = environment.BASE_BACKEND_WORKER + '/admin/user/dataprofile';
            
    if(!this.userInformationPromise){
      this.userInformationPromise = this.http.post(url, {}, USER_INFORMATION_MOCK).toPromise();
    }

    const user = await this.userInformationPromise;    
    this.userInformation = user;

    const loginInfo: IUserLogin = await this.storage.get(USER_INFO)

    if (loginInfo && loginInfo) {
      this.userInformation.documentNumber = loginInfo.documentNumber
      this.userInformation.documentType = loginInfo.documentType
    }
    
    return this.userInformation;    
  }


  public getContactData(): Promise<IUserInformation> {
    const url = environment.BASE_BACKEND_WORKER + '/hbk/promotion/incrementlinehb/contactdataincrement';
    return this.http.post(url, {}, USER_INFORMATION_MOCK).toPromise();
  }

  public getDataProfile(userHashParams: IHashInformation): Promise<IUserHashInformation> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/efex/contact-dataV2';
    return this.http.post(url, userHashParams, HASH_USER_INFORMATION_MOCK).toPromise();
  }

  public getFullName() {
    const name = this.userInformation.names ?
    ' ' + new TitleCasePipe().transform(this.userInformation.names.split(' ')[0]) : '';
    const lastName = this.userInformation.lastName ?
    ' ' + new TitleCasePipe().transform(this.userInformation.lastName) : '';
    return name + lastName;
  }
  public async loadUserInformation(user: any, userDNI: string, deviceInformation: IDevice) {
    const hashToken = ''; // this.authService.currentToken.refreshToken;  TODO: hashear token con blake2

    this.firebaseService.addSefEfexServiceResponse(
      {
        token: hashToken,
        sessionDNI: userDNI,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        customerId: user.customerId,
        userEmail: user.email,
        userName:  user.names.toUpperCase(),
        userLastName: user.lastName.toUpperCase(),
        mobileNumber: user.mobileNumber,
        device: deviceInformation
      },
      'userInformationResponses');
  }

  public async getUserInformationForce() {
    const url = environment.BASE_BACKEND_WORKER + '/admin/user/dataprofile';
    return this.http.post(url, {}, USER_INFORMATION_MOCK).toPromise();
  }

}
