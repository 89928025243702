import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  TAG_BUTTON_TIMER_MODAL,
  TAG_SHOW_TIMER_MODAL,
} from '@common/constants/tag/tag-payments.constants';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-session-expire-soon',
  templateUrl: './session-expire-soon.component.html',
  styleUrls: ['./session-expire-soon.component.scss'],
})
export class SessionExpireSoonComponent implements OnInit, OnDestroy {
  closeTimeout: NodeJS.Timeout;
  closeInterval: NodeJS.Timeout;
  remainingTime = 0;
  constructor(
    public modalController: ModalController,
  ) {}
  ngOnInit(): void {}
  ngOnDestroy(): void {
    clearInterval(this.closeInterval);
    clearTimeout(this.closeTimeout);
  }

  timeClose = 30;

  async ionViewDidEnter() {
    await this.initExpiredTime();
  }

  async initExpiredTime() {
    this.closeTimeout = setTimeout(async () => {
      this.dismiss(false);
    }, this.timeClose * 1000);

    this.remainingTime = this.timeClose;
    this.closeInterval = setInterval(async () => {
      this.remainingTime--;
      if (this.remainingTime <= 0) {
        clearInterval(this.closeInterval);
      }
    }, 1000);
  }

  dismiss(renovate: boolean, isCloseByIcon: boolean = false) {
    // using the injected ModalController this page
    // can 'dismiss' itself and optionally pass back data

    if (isCloseByIcon) {
      const tag = {
        ...TAG_BUTTON_TIMER_MODAL,
        event_label: 'cerrar_timer_modal',
      };
    } else {
      if (renovate) {
        const tag = {
          ...TAG_BUTTON_TIMER_MODAL,
          event_label: 'continuar_timer_modal',
        };
      } else {
        const tag = {
          ...TAG_BUTTON_TIMER_MODAL,
          event_label: 'cancelar_timer_modal',
        };
      }
    }

    this.modalController.dismiss({
      renovate,
    });
  }
}
