import { Injectable } from '@angular/core';
import { HttpService } from '@services/http/http.service';

import { environment } from '@environments/environment';
import { IRemoteConfig } from '@common/interfaces/remote-config.interface';

@Injectable({
  providedIn: 'root',
})
export class CloudFlareService {
  constructor(
    private http: HttpService
    ) {}

  public async getRemoteConfig(): Promise<IRemoteConfig> {
    const url = environment.BASE_BACKEND_WORKER + '/config';
    return this.http.get(url, null).toPromise();
  }

  public async getRemotePublicKey(): Promise<IRemoteConfig> {
    const url = environment.BASE_BACKEND_WORKER + '/rsa';
    return this.http.get(url, null).toPromise();
  }
}
