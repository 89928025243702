import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
@Injectable()
export class KeyboardService {
  constructor() {/**/}
  public keyboardWillShow() {
    return fromEvent(window, 'keyboardWillShow');
  }
  public keyboardWillHide() {
    return fromEvent(window, 'keyboardWillHide');
  }
  public keyboardDidHide() {
    return fromEvent(window, 'keyboardDidHide');
  }
  public keyboardDidShow() {
    return fromEvent(window, 'keyboardDidShow');
  }
}