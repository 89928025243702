import { Component, EventEmitter, Output } from '@angular/core';
import { PERSONAL_DATA_PROCESSING_POLICY_TEXT, PRIVACY_POLICY_URL, RPASS_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { MODAL_PRIMARY_ACTION, TARGET_BLANK } from '@common/constants/misc.constants';
import { RPASS_POLICY_MODAL_PROPS } from '@common/constants/modal-props.constants';
import { PoliticModalComponent } from '@components/politic-modal/politic-modal.component';
import { ModalController } from '@ionic/angular';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-checkbox-policies',
  templateUrl: './checkbox-policies.component.html',
  styleUrls: ['./checkbox-policies.component.scss'],
})
export class CheckboxPoliciesComponent {
  @Output() public checkValue = new EventEmitter<boolean>();
  public terms: any = { isChecked: false };
  public policy: any = { isChecked: false };

  constructor(
    private modalController: ModalController,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) { }

  public async openModal() {
    const modal = await this.modalController.create({
      component: PoliticModalComponent,
      cssClass: 'politic-r-pass',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        ...RPASS_POLICY_MODAL_PROPS,
        message: RPASS_POLICY_MODAL_PROPS.message(PERSONAL_DATA_PROCESSING_POLICY_TEXT),
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data === MODAL_PRIMARY_ACTION) {
      this.terms = { isChecked: true };
    } else {
      this.terms = { isChecked: false };
    }
    this.checkValue.emit(this.terms.isChecked);
  }

  public async openPrivacyModal() {
    window.open(PRIVACY_POLICY_URL, TARGET_BLANK);
  }

  public changePolicyState() {
    this.policy.isChecked = !this.policy.isChecked;
    if (this.policy.isChecked) {
      const eventGA4 = {
        product: 'acepto políticas de privacidad',
        action: RPASS_GA4_CONSTANTS.LOGIN_RPASS_BTN.ACTION,
        step: RPASS_GA4_CONSTANTS.LOGIN_RPASS.STEP,
        step_part: RPASS_GA4_CONSTANTS.LOGIN_RPASS.STEP_PART,
        category: RPASS_GA4_CONSTANTS.LOGIN_RPASS.CATEGORY,
      };
      this.googleAnalyticsService.gtagPushEvent(RPASS_GA4_CONSTANTS.LOGIN_RPASS_BTN.EVENT, eventGA4);
    }
  }

  public changeTermsState() {
    this.terms.isChecked = !this.terms.isChecked;
    if (this.terms.isChecked) {
      const eventGA4 = {
        product: 'acepto terminos y condiciones',
        action: RPASS_GA4_CONSTANTS.LOGIN_RPASS_BTN.ACTION,
        step: RPASS_GA4_CONSTANTS.LOGIN_RPASS.STEP,
        step_part: RPASS_GA4_CONSTANTS.LOGIN_RPASS.STEP_PART,
        category: RPASS_GA4_CONSTANTS.LOGIN_RPASS.CATEGORY,
      };
      this.googleAnalyticsService.gtagPushEvent(RPASS_GA4_CONSTANTS.LOGIN_RPASS_BTN.EVENT, eventGA4);
    }
    this.checkValue.emit(this.terms.isChecked);
  }
}
