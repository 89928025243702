import { ITransactionInfo } from '@common/interfaces/digital-auth.interface';
import { SAVINGS_ACCOUNTS_TYPES } from './saving-benefits';

export const OPEN_ACCOUNT_PROGRESS_ADDITION = 0.20;
export const YES = 'SI';
export const NO = 'NO';
export const REGISTER_ACCOUNT_SUCCESS_CODE = 0;
export const REGISTER_ACCOUNT_SUCCESS_CODE_SERVICE = 1;
export const ERROR_CODE_SMS = 630;
export const ACCOUNT_PLUS_CODE = 'AC001002'; // AHORRO PLUS CLIENTE  // cliente // trabajador
export const ACCOUNT_PLUS_WORKER_CODE = 'AC001004'; // AHORRO PLUS COLABORADOR // trabajador
export const ACCOUNT_SIMPLE_CODE = 'AC001006'; // CUENTA SIMPLE
export const ACCOUNT_AFP_CODE = 'AC001008'; // CUENTA SIMPLE AFP
export const ACCOUNT_VIP_CODE = 'AC001009'; // CUENTA Ripley Max // cliente // trabajador
export const RIPLEY_VIP_ACCOUNT_CODE = 'AC001009';
export const ACCOUNT_SALARY_CLIENT = 'AC001011'; // CUENTA SUELDO CLIENTE // cuenta sueldo // cliente
export const RIPLEY_STORE_SALARY_ACCOUNT_CODE = 'AC001012'; // CUENTA SUELDO TIENDA //
export const RIPLEY_BANK_SALARY_ACCOUNT_CODE = 'AC001013'; // CUENTA SUELDO BANCO // trabajador
export const SOLES = 'PEN';
export const DOLLARS = 'USD';
export const PRE_REGISTER_KEY = 'preRegister';
export const TYPE_ACCOUNT_SELECTED = 'typeAccountSelected';
export const OPEN_ACCOUNT_SUCCESS_KEY = 'openAccount';
export const RUC_LENGHT = 11;
export const NANE_RAZON_SOCIAL_LENGHT = 100;
export const STATUS_ACCOUNT = 'Activa';

export const TOOLTIP_SWORN_DECLARATION = 'Domicilio para efectos tributarios. ' +
    '(El domicilio fiscal es el fijado por el contribuyente dentro del territorio ' +
    'nacional para efectos tributarios)';

export const TYPE_SAVING_PLUS = 'CUENTA AHORRO PLUS';
export const TYPE_VIP_ACCOUNT = 'CUENTA RIPLEY MAX';
export const TYPE_SALARY_ACCOUNT = 'CUENTA SUELDO';
export const TYPE_AFP_ACCOUNT = 'CUENTA SIMPLE AFP';

export const SAVING_PLUS_PAGE_TITLE = 'Apertura cuenta Ahorro Plus';
export const VIP_ACCOUNT_PAGE_TITLE = 'Apertura cuenta Ripley Max';
export const STORE_SALARY_ACCOUNT_PAGE_TITLE = 'Apertura Cuenta Sueldo Tienda';
export const BANK_SALARY_ACCOUNT_PAGE_TITLE = 'Apertura Cuenta Sueldo Banco';
export const SALARY_ACCOUNT_PAGE_TITLE = 'Apertura Cuenta Sueldo';

export const ACCOUNTS_PRIORITY = {
    AC001002: 1,
    AC001004: 1,
    AC001009: 1,
    AC001011: 2,
    AC001012: 2,
    AC001013: 2
};

export const PRODUCT_TYPE_MAPPING = {
    [RIPLEY_BANK_SALARY_ACCOUNT_CODE]: 'PCSB',
    [ACCOUNT_VIP_CODE]: 'PTAPM',
    [ACCOUNT_AFP_CODE]: 'CSAFP'
  };
  
export  const SAVINGS_TYPE_MAPPING = {
    [SAVINGS_ACCOUNTS_TYPES.SIMPLE]: 'TTCS',
    [SAVINGS_ACCOUNTS_TYPES.PLUS]: 'PTAP',
    [SAVINGS_ACCOUNTS_TYPES.CTS]: 'TCTS',
    [SAVINGS_ACCOUNTS_TYPES.TERMINOS]: 'TCPROPAS'
  };

export const STEP_LOGS = {
    SELECCION_CUENTA: 'st0A',
    TERMINOS_CONDICIONES: 'st1',
    INFORMACION_SALARIO: 'st2',
    RESUMEN_CUENTA: 'st3',
    BENEFICIO_CUENTA: 'st0B',
};

export const STORAGE_LOG_OA = {
    CODE_OPEN_ACCOUNT: 'CODE',
    STEP_OPEN_ACCOUNT: 'STEP'
};
export const REASON_LOGS = {
    CANCELAR: 'rsn1',
    CERRAR_VENTANA: 'rsn2',
    CANCELAR_SMS: 'rsn3',
    PRE_REGISTER: 'rsn4',
    CERRAR_SESION: 'rsn5'
};
export const ACTION = {
    ATRAS: 0,
    CANCELAR: 1
};

export const ACCOUNT_OFFERS = [
    {
        accountCode: 'AC001002',
        accountType: 'CUENTA AHORRO PLUS',
        image: 'saving-plus-offer',
        sessionId: '',
        imageMobile: 'saving-plus-offer-mb',
        description: 'El costo de mantenimiento de cuenta queda exonerado para todos los clientes de Ahorro Plus hasta el 31/12/2024.',
        buttons: [
            {
                buttonName: 'Ver beneficios  >',
                buttonRoute: 'benefits',
                buttonClass: 'type-link',
                isVisible: true
            },
            {
                buttonName: 'Solicitar',
                buttonRoute: 'sworn-declaration',
                buttonClass: 'type-button',
                isVisible: true
            }
        ]
    },
    {
        accountCode: 'AC001004',
        accountType: 'CUENTA AHORRO PLUS',
        image: 'saving-plus-offer',
        sessionId: '',
        imageMobile: 'saving-plus-offer-mb',
        description: 'Manteniendo un saldo mayor o igual a S/ 500 a fin de período de cada mes.',
        buttons: [
            {
                buttonName: 'Ver beneficios  >',
                buttonRoute: 'benefits',
                buttonClass: 'type-link',
                isVisible: true
            },
            {
                buttonName: 'Solicitar',
                buttonRoute: 'sworn-declaration',
                buttonClass: 'type-button',
                isVisible: true
            },
        ]
    },
    {
        accountCode: 'AC001008',
        accountType: 'CUENTA SIMPLE AFP',
        sessionId: '',
        image: 'salary-plus-offer-mb',
        imageMobile: 'saving-plus-offer-mb',
        description: 'Sin importar el saldo que tengas.',
        buttons: [
            {
                buttonName: 'Ver beneficios  >',
                buttonRoute: 'benefits',
                buttonClass: 'type-link',
                isVisible: true
            },
            {
                buttonName: 'Solicitar',
                buttonRoute: 'sworn-declaration',
                buttonClass: 'type-button',
                isVisible: true
            },
        ]
    },
    {
        accountCode: 'AC001009',
        accountType: 'CUENTA RIPLEY MAX',
        sessionId: '',
        image: 'salary-plus-offer-mb',
        imageMobile: 'saving-plus-offer-mb',
        description: 'Ingresa a un mundo de beneficios exclusivos.',
        buttons: [
            {
                buttonName: 'Ver beneficios  >',
                buttonRoute: 'benefits',
                buttonClass: 'type-link',
                isVisible: true
            },
            {
                buttonName: 'Solicitar',
                buttonRoute: 'sworn-declaration',
                buttonClass: 'type-button',
                isVisible: true
            },
        ]
    },
    {
        accountCode: 'AC001011',
        accountType: 'CUENTA SUELDO CLIENTE',
        sessionId: '',
        image: 'salary-account-offer',
        imageMobile: 'salary-account-offer-mb',
        description: 'Recibiendo tus remuneraciones mes a mes con nosotros.',
        buttons: [
            {
                buttonName: 'Ver beneficios  >',
                buttonRoute: 'benefits',
                buttonClass: 'type-link',
                isVisible: true
            },
            {
                buttonName: 'Solicitar',
                buttonRoute: 'sworn-declaration',
                buttonClass: 'type-button',
                isVisible: true
            },
        ]
    },
    {
        accountCode: 'AC001012',
        accountType: 'CUENTA SUELDO TIENDA',
        sessionId: '',
        image: 'salary-account-offer',
        imageMobile: 'salary-account-offer-mb',
        description: 'Recibiendo tus remuneraciones mes a mes con nosotros. ',
        buttons: [
            {
                buttonName: 'Ver beneficios  >',
                buttonRoute: 'benefits',
                buttonClass: 'type-link',
                isVisible: true
            },
            {
                buttonName: 'Solicitar',
                buttonRoute: 'sworn-declaration',
                buttonClass: 'type-button',
                isVisible: true
            }
        ]
    },
    {
        accountCode: 'AC001013',
        accountType: 'CUENTA SUELDO BANCO',
        sessionId: '',
        image: 'salary-account-offer',
        imageMobile: 'salary-account-offer-mb',
        description: 'Recibiendo tus remuneraciones mes a mes con nosotros. ',
        buttons: [
            {
                buttonName: 'Ver beneficios  >',
                buttonRoute: 'benefits',
                buttonClass: 'type-link',
                isVisible: true
            },
            {
                buttonName: 'Solicitar',
                buttonRoute: 'sworn-declaration',
                buttonClass: 'type-button',
                isVisible: true
            },
        ]
    }
];

export const BENEFITS_SAVING_PLUS = {
    title: 'Beneficios de tu cuenta Ahorro Plus',
    subtitle: 'Ahorro fácil y seguro, operaciones digitales mediante la Banca por Internet, ' +
        'retiro de efectivo en cualquier cajero Mastercard, súper tasa de ahorro y más con tu cuenta Ahorro Plus.',
    mainBenefits: [
        {
            benefitIcon: 'i-coins-yellow',
            title: 'Ahorra con una tasa de 3%',
            description: 'Tasa efectiva anual de 3.00% en soles.'
        },
        {
            benefitIcon: 'i-card-yellow', title: 'Costo S/ 0 de mantenimiento*',
            description: 'El costo de mantenimiento de cuenta queda exonerado para todos los clientes de Ahorro Plus hasta el 31/12/2024.'
        },
        {
            benefitIcon: 'i-bag-yellow',
            title: 'Tarjeta de Débito para realizar compras en el Perú y en el extranjero.',
            description: 'Obtén descuentos exclusivos en Tiendas Ripley y comercios afiliados.'
        }
    ],
    secondaryBenfitsHeader: 'Disfruta los beneficios de tu cuenta Ahorro Plus:',
    secondaryBenefits: [
        {
            benefitIcon: 'i-statistics',
            title: 'Tasa de interés Efectiva Anual en soles.',
            description: 'Realizar transferencias o pagos a tu Tarjeta de Crédito ingresando a la Banca por Internet.'
        },
        {
            benefitIcon: 'i-no-cost',
            title: 'El costo de mantenimiento de cuenta queda exonerado para todos los clientes de Ahorro Plus hasta el 31/12/2024.',
            description: 'Retirar efectivo desde cualquier cajero MasterCard y agencias de Banco Ripley.'
        },
        {
            benefitIcon: 'i-atm-soles',
            title: 'Dos retiros gratuitos en cualquier cajero de la red Mastercard.',
            description: ''
        },
        {
            benefitIcon: 'i-debito-card',
            title: 'Tarjeta de Débito para realizar compras en el Perú y en el extranjero.',
            description: ''
        },
        {
            benefitIcon: 'i-smartphone',
            title: 'Acceso a la Banca por internet para realizar tus pagos y transferencias.',
            description: ''
        },
        {
            benefitIcon: 'i-question-open',
            title: 'Atención en horario extendido de Lunes a Domingo en las agencias.',
            description: ''
        }
    ],
    btnMobile: 'Quiero mi cuenta Ahorro Plus'
};

export const BENEFITS_SALARY_ACCOUNT = {
    title: 'Beneficios cuenta Sueldo',
    subtitle: 'Descubre todos nuestros beneficios y abre 100% online tu <span class="info-text-bold">cuenta Sueldo</span>',
    mainBenefits: [
        {
            benefitIcon: 'i-coins-yellow',
            title: 'Tasas preferenciales',
            description: 'Accede a tasas más bajas y condiciones preferenciales en Préstamos y tarjetas de crédito. ' +
                'Y si tienes o trasladas tu CTS a Banco Ripley, puedes obtener una súper tasa.'
        },
        {
            benefitIcon: 'i-bag-yellow', title: 'Descuentos exclusivos',
            description: 'En Tienda Ripley por tener tu Cuenta Sueldo por recibir tus remuneraciones mes a mes con nosotros.'
        },
        {
            benefitIcon: 'i-card-yellow', title: 'Cero comisiones por transacciones Digitales',
            description: 'Te ofrecemos comisión gratis para tus operaciones en todos nuestros canales: Banca por Internet y App.'
        }
    ],
    secondaryBenfitsHeader: 'Disfruta los beneficios de tu cuenta Sueldo',
    secondaryBenefits: [
        {
            benefitIcon: 'i-card-yellow',
            title: 'Acceso a la Banca por Internet para realizar tus pagos y transferencias.',
            description: 'Realizar transferencias o pagos a tu Tarjeta de Crédito ingresando a la Banca por Internet.'
        },
        {
            benefitIcon: 'i-atm-yellow', title: 'Dos retiros gratuitos en cualquier cajero de la red MasterCard.',
            description: 'Retirar efectivo desde cualquier cajero MasterCard y agencias de Banco Ripley.'
        },
        {
            benefitIcon: 'i-clock-yellow', title: 'Atención en horario extendido de lunes a domingo en las agencias.',
            description: ''
        }
    ],
    btnMobile: 'Quiero mi cuenta Sueldo'
};

export const BENEFITS_BANK_SALARY_ACCOUNT = {
    title: 'Beneficios cuenta Sueldo Banco',
    subtitle: 'Descubre todos nuestros beneficios y abre 100% online tu <span class="info-text-bold">cuenta Sueldo</span>',
    mainBenefits: [
        {
            benefitIcon: 'i-coins-yellow',
            title: 'Tasas preferenciales',
            description: 'Accede a tasas más bajas y condiciones preferenciales en Préstamos y tarjetas de crédito. ' +
                'Y si tienes o trasladas tu CTS a Banco Ripley, puedes obtener una súper tasa.'
        },
        {
            benefitIcon: 'i-bag-yellow', title: 'Descuentos exclusivos',
            description: 'En Tienda Ripley por tener tu Cuenta Sueldo por recibir tus remuneraciones mes a mes con nosotros.'
        },
        {
            benefitIcon: 'i-card-yellow', title: 'Cero comisiones por transacciones Digitales',
            description: 'Te ofrecemos comisión gratis para tus operaciones en todos nuestros canales: Banca por Internet y App.'
        }
    ],
    secondaryBenfitsHeader: 'Disfruta los beneficios de tu cuenta Sueldo',
    secondaryBenefits: [
        {
            benefitIcon: 'i-card-yellow',
            title: 'Acceso a la Banca por Internet para realizar tus pagos y transferencias.',
            description: 'Realizar transferencias o pagos a tu Tarjeta de Crédito ingresando a la Banca por Internet.'
        },
        {
            benefitIcon: 'i-atm-yellow', title: 'Retiros ilimitados y gratuitos en cualquier cajero de la red Mastercard.',
            description: 'Retirar efectivo desde cualquier cajero MasterCard y agencias de Banco Ripley.'
        },
        {
            benefitIcon: 'i-clock-yellow', title: 'Atención en horario extendido de lunes a domingo en las agencias.',
            description: ''
        }
    ],
    btnMobile: 'Quiero mi cuenta Sueldo'
};

export const BENEFITS_STORE_SALARY_ACCOUNT = {
    title: 'Beneficios cuenta Sueldo Tienda',
    subtitle: 'Descubre todos nuestros beneficios y abre 100% online tu <span class="info-text-bold">cuenta Sueldo</span>',
    mainBenefits: [
        {
            benefitIcon: 'i-coins-yellow',
            title: 'Tasas preferenciales',
            description: 'Accede a tasas más bajas y condiciones preferenciales en Préstamos y tarjetas de crédito. ' +
                'Y si tienes o trasladas tu CTS a Banco Ripley, puedes obtener una súper tasa.'
        },
        {
            benefitIcon: 'i-bag-yellow', title: 'Descuentos exclusivos',
            description: 'En Tienda Ripley por tener tu Cuenta Sueldo por recibir tus remuneraciones mes a mes con nosotros.'
        },
        {
            benefitIcon: 'i-card-yellow', title: 'Cero comisiones por transacciones Digitales',
            description: 'Te ofrecemos comisión gratis para tus operaciones en todos nuestros canales: Banca por Internet y App.'
        }
    ],
    secondaryBenfitsHeader: 'Disfruta los beneficios de tu cuenta Sueldo',
    secondaryBenefits: [
        {
            benefitIcon: 'i-card-yellow',
            title: 'Acceso a la Banca por Internet para realizar tus pagos y transferencias.',
            description: 'Realizar transferencias o pagos a tu Tarjeta de Crédito ingresando a la Banca por Internet.'
        },
        {
            benefitIcon: 'i-atm-yellow', title: 'Dos retiros gratuitos en cualquier cajero de la red MasterCard.',
            description: 'Retirar efectivo desde cualquier cajero MasterCard y agencias de Banco Ripley.'
        },
        {
            benefitIcon: 'i-clock-yellow', title: 'Atención en horario extendido de lunes a domingo en las agencias.',
            description: ''
        }
    ],
    btnMobile: 'Quiero mi cuenta Sueldo'
};


export const BENEFITS_RIPLEY_MAX_ACCOUNT = {
    title: 'Beneficios cuenta Ripley Max',
    subtitle: 'Descubre todos nuestros beneficios y abre 100% online tu <span class="info-text-bold">cuenta Ripley Max</span>',
    mainBenefits: [
        {
            benefitIcon: 'i-coins-yellow',
            title: 'Tasas preferenciales',
            description: 'Accede a tasas preferentes de CTS si tienes o trasladas tu cuenta CTS soles a Banco Ripley.'
        },
        {
            benefitIcon: 'i-bag-yellow', title: 'Descuentos exclusivos',
            description: 'En Tienda Ripley por tener tu Cuenta Ripley Max en Banco Ripley y abonar o transferir S/1000 de manera mensual.'
        },
        {
            benefitIcon: 'i-card-yellow', title: 'Cero comisiones por transacciones Digitales',
            description: 'Te ofrecemos comisión gratis para tus operaciones en todos nuestros canales: Banca por Internet y App.'
        }
    ],
    secondaryBenfitsHeader: 'Disfruta los beneficios de tu cuenta Ripley Max',
    secondaryBenefits: [
        {
            benefitIcon: 'i-statistics',
            title: 'Acceso a tasa CTS preferente para tu cuenta CTS en Banco Ripley al tener abonos mensuales de S/ 1000 en tu Cuenta Ripley Max.',
            description: ''
        },
        {
            benefitIcon: 'i-no-cost',
            title: 'Sin costo de mantenimiento.',
            description: ''
        },
        {
            benefitIcon: 'i-atm-soles',
            title: 'Retiros ilimitados y gratuitos en cualquier cajero de la red Mastercard.',
            description: ''
        },
        {
            benefitIcon: 'i-debito-card',
            title: 'Tarjeta de Débito para realizar compras en el Perú y en el extranjero.',
            description: ''
        },
        {
            benefitIcon: 'i-smartphone',
            title: 'Acceso a la Banca por internet para realizar tus pagos y transferencias.',
            description: ''
        },
        {
            benefitIcon: 'i-question-open',
            title: 'Atención en horario extendido de Lunes a Domingo en las agencias.',
            description: 'Realizar transferencias o pagos a tu Tarjeta de Crédito ingresando a la Banca por Internet.'
        },
        {
            benefitIcon: 'i-resto-fans',
            title: 'Descuentos exclusivos Restofans.',
            description: 'Realizar transferencias o pagos a tu Tarjeta de Crédito ingresando a la Banca por Internet.'
        }
    ],
    btnMobile: 'Quiero mi cuenta Ripley Max'
};

export const BENEFITS_AFP_ACCOUNT = {
    title: 'Beneficios de tu cuenta Simple AFP',
    subtitle: 'Ahorro fácil y seguro, operaciones digitales mediante la Banca por Internet, ' +
        'retiro de efectivo en cualquier cajero Mastercard, súper tasa de ahorro y más con tu cuenta Simple AFP.',
    mainBenefits: [
        {
            benefitIcon: 'i-coins-yellow',
            title: 'Ahorra con una tasa de 3%',
            description: 'Tasa efectiva anual de 3.00% en soles.'
        },
        {
            benefitIcon: 'i-card-yellow', title: 'Costo S/ 0 de mantenimiento*',
            description: 'El costo de mantenimiento de cuenta queda exonerado para todos los clientes de Simple AFP hasta el 31/12/2024.'
        },
        {
            benefitIcon: 'i-bag-yellow',
            title: 'Tarjeta de Débito para realizar compras en el Perú y en el extranjero.',
            description: 'Obtén descuentos exclusivos en Tiendas Ripley y comercios afiliados.'
        }
    ],
    secondaryBenfitsHeader: 'Disfruta los beneficios de tu cuenta Simple AFP:',
    secondaryBenefits: [
        {
            benefitIcon: 'i-statistics',
            title: 'Tasa de interés Efectiva Anual en soles.',
            description: 'Realizar transferencias o pagos a tu Tarjeta de Crédito ingresando a la Banca por Internet.'
        },
        {
            benefitIcon: 'i-no-cost',
            title: 'Intangibilidad garantizada de tu dinero.',
            description: 'Retirar efectivo desde cualquier cajero MasterCard y agencias de Banco Ripley.'
        },
        {
            benefitIcon: 'i-atm-soles',
            title: 'Dos retiros gratuitos en cualquier cajero de la red Mastercard.',
            description: ''
        },
        {
            benefitIcon: 'i-debito-card',
            title: 'Tarjeta de Débito para realizar compras en el Perú y en el extranjero.',
            description: ''
        },
        {
            benefitIcon: 'i-smartphone',
            title: 'Acceso a la Banca por internet para realizar tus pagos y transferencias.',
            description: ''
        },
        {
            benefitIcon: 'i-question-open',
            title: 'Atención en horario extendido de Lunes a Domingo en las agencias.',
            description: ''
        }
    ],
    btnMobile: 'Quiero mi cuenta Simple AFP'
};

export const SUCCESS_SAVING_PLUS = {
    mobileHeader: 'Apertura cuenta Ahorro Plus',
    header: '<p><b>¡Todo listo!</b> Tu cuenta Ahorro Plus ya está activa</p>',
    title: '¡Felicidades! Ya puedes usar tu cuenta Ahorro Plus',
    accountLabel: 'Nº Cuenta Ahorro Plus:',
    usageInfo: [
        'Recibir transferencias desde otros bancos',
        'Realizar transferencias hacia otros bancos'
    ],
    btnDesktop: 'Ir a tu cuenta Ahorro Plus'
};

export const SUCCESS_SALARY_ACCOUNT = {
    mobileHeader: 'Apertura Cuenta Sueldo',
    header: '<p><b>¡Todo listo!</b> Tu cuenta Sueldo ya está activa</p>',
    title: '¡Felicidades! Ya puedes usar tu cuenta Sueldo.',
    accountLabel: 'Nº Cuenta Sueldo:',
    usageInfo: [
        'Recibir transferencias desde otros bancos',
        'Realizar transferencias hacia otros bancos'
    ],
    btnDesktop: 'Ir a tu cuenta Sueldo'
};

export const SUCCESS_STORE_SALARY_ACCOUNT = {
    mobileHeader: 'Apertura Cuenta Sueldo Tienda',
    header: '<p><b>¡Todo listo!</b> Tu cuenta Sueldo ya está activa</p>',
    title: '¡Felicidades! Ya puedes usar tu cuenta Sueldo.',
    accountLabel: 'Nº Cuenta Sueldo:',
    usageInfo: [
        'Recibir transferencias desde otros bancos',
        'Realizar transferencias hacia otros bancos'
    ],
    btnDesktop: 'Ir a tu cuenta Sueldo'
};

export const SUCCESS_BANK_SALARY_ACCOUNT = {
    mobileHeader: 'Apertura Cuenta Sueldo Banco',
    header: '<p><b>¡Todo listo!</b> Tu cuenta Sueldo ya está activa</p>',
    title: '¡Felicidades! Ya puedes usar tu cuenta Sueldo.',
    accountLabel: 'Nº Cuenta Sueldo:',
    usageInfo: [
        'Recibir transferencias desde otros bancos',
        'Realizar transferencias hacia otros bancos'
    ],
    btnDesktop: 'Ir a tu cuenta Sueldo'
};

export const SUCCESS_VIP_ACCOUNT = {
    mobileHeader: 'Apertura Cuenta Ripley Max',
    header: '<p><b>¡Todo listo!</b> Tu cuenta Ripley Max ya está activa</p>',
    title: '¡Felicidades! Ya puedes usar tu cuenta Ripley Max.',
    accountLabel: 'Nº Cuenta Ripley Max:',
    usageInfo: [
        'Recibir transferencias desde otros bancos',
        'Realizar transferencias hacia otros bancos'
    ],
    btnDesktop: 'Ir a tu cuenta Ripley Max'
};

export const TRANSACTION_INFO_OPEN_ACCOUNT: ITransactionInfo = {
    operation: 'CTAHORRO',
    transactionType: 'CTAHORRO',
    transactionName: 'APERTURA_CUENTA_AHORRO',
    subTypeTransaction: 'APERTURA_CUENTA_AHORRO',
};
