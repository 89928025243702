import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export enum eWidgetModalState {
  show = "show",
  notshow = "notshow",
}

@Injectable({
  providedIn: "root",
})
export class ModalWidgetService {
  private statusWidgetBehavior: BehaviorSubject<eWidgetModalState> =
    new BehaviorSubject(eWidgetModalState.show);

  public saveStatusWidget(state: eWidgetModalState) {
    this.statusWidgetBehavior.next(state);
  }

  public $statusWidget(): Observable<eWidgetModalState> {
    return this.statusWidgetBehavior.asObservable();
  }

  public getStatusWidget(): eWidgetModalState {
    return this.statusWidgetBehavior.value;
  }

  constructor() {}
}
