import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disabled-virtual-card-popover',
  templateUrl: './disabled-virtual-card-popover.component.html',
  styleUrls: ['./disabled-virtual-card-popover.component.scss'],
})
export class DisabledVirtualCardPopoverComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
