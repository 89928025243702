// EFEX
// Enter to option enrollment

export const ENROLL_REGISTER = {
  variable: 'REGISTRO',
  event:  'Regístrate',
  action:  'click'
};

// STEP 1

export const ENROLL_DOC_VERIFICATION = {
  variable: 'REGISTRO',
  event: 'Paso_1-Ingreso_Documento',
  action:  'click'
};

export const ENROLL_MODAL_NO_PRODUCTS = {
  variable: 'REGISTRO',
  event: 'Paso_1-Ingreso_Documento',
    action: 'visualización',
    info1: '0',
    info2: '1'
};
export const ENROLL_CHECK_FOR_CARD = {
  variable: 'REGISTRO',
  event: 'Paso_1-Ver_si_tengo_tarjeta',
    action:  'click'
};

export const ENROLL_MODAL_PRODUCTS_FOUND = {
  variable: 'REGISTRO',
  event: 'Paso_1-Ya_registrado',
    action: 'visualización',
    info1: '0',
    info2: '1'
};

export const ENROLL_FORGOT_PASS = {
  variable: 'REGISTRO',
  event: 'Olvidé mi clave',
    action: 'click'
};

export const ENROLL_LOGIN = {
  variable: 'REGISTRO',
  event: 'Ingresar',
    action: 'click'
};

// Paso 2

export const ENROLL_CONFIRM_SMS = {
  variable: 'REGISTRO',
  event: 'Paso_2-Confirmar',
    action: 'click'
};

export const ENROLL_TIMEOUT_SMS = {
  variable: 'REGISTRO',
  event: 'Paso_2-TimeoutSMS',
    action: 'click'
};
export const ENROLL_NOT_NUMBER = {
  variable: 'REGISTRO',
  event: 'Paso_2-No_Es_Tu_Numero',
    action:  'click'
};
export const ENROLL_MODAL_NOT_NUMBER = {
  variable: 'REGISTRO',
  event: 'Paso_2- No_Es_Tu_Numero',
    action: 'visualización',
    info1: '0',
    info2: '1'
};
export const ENROLL_GO_RIPLEY = {
  variable: 'REGISTRO',
  event: 'Paso_2-Ir-abancoripley',
    action: 'click',
    info1: '0',
    info2: '1'

};

// SECURY METHOD CLIENT
export const ENROLL_SCREEN_SHOW = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_1.1',
  event_label: 'home_enrolamiento_mobile'
};

export const ENROLL_TO_REGISTER = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_1.2',
  event_label: 'inciar_registro_mobile'
};

export const ENROLL_INPUT_DOCUMENT_NUMBER = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_1.3',
  event_label: 'ingresar_numero_documento_enrolamiento_mobile'
};

export const ENROLL_PRESS_CONTINUE_DOCUMENT_NUMBER = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_1.4',
  event_label: 'continuar_enrolamiento_mobile'
};

export const ENROLL_PRESS_CONTINUE = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_2.3',
  event_label: 'continuar_sms_enrolamiento_mobile'
};

export const ENROLL_PRESS_CANCEL = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_1.5',
  event_label: 'cancelar_enrolamiento_mobile'
};

export const ENROLL_PRESS_NOT_SMS_NUMBER = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_2.0',
  event_label: 'no_es_numero_sms_enrolamiento_mobile'​
};

export const ENROLL_SET_SMS_IMPUT = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_2.1',
  event_label: 'agregar_codigo_sms_enrolamiento_mobile'​
};

export const ENROLL_SMS_VALIDATE_SUCCESFULL = {
  event: 'enrolamientoMobile_event',​
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_2.2',
  event_label: 'codigo_valido_sms_enrolamiento_mobile'​
};

export const ENROLL_PAGE_CREATE_PASSWORD = {
  event: 'enrolamientoMobile_event',​
  event_category: 'flujoEnrolamientoMobile',​
  event_action: 'step_3.1',
  event_label: 'pantalla_ingresar_clave_enrolamiento_mobile'​
};

export const ENROLL_ACCEPT_TERMS = {
  event: 'enrolamientoMobile_event',​
  event_category: 'flujoEnrolamientoMobile',​
  event_action: 'step_3.2',
  event_label: 'acepto_terminos_condiciones_enrolamiento_mobile'​
};

export const ENROLL_REGISTER_USER = {
  event: 'enrolamientoMobile_event',​
  event_category: 'flujoEnrolamientoMobile',​
  event_action: 'step_3.3',
  event_label: 'agregar_registrarse_mobile'
};

export const ENROLL_CANCEL_SUBMIT_FORM = {
  event: 'enrolamientoMobile_event',​
  event_category: 'flujoEnrolamientoMobile',​
  event_action: 'step_3.4',
  event_label: 'cancelar_registro_clave_mobile'
};

export const ENROLL_USER_CREATE_OK = {
  event: 'enrolamientoMobile_event',​
  event_category: 'flujoEnrolamientoMobile',​
  event_action: 'step_4.1',
  event_label: 'exito_enrolamiento_mobile'
};

export const ENROLL_CLICK_RIPLEY_BANK = {
  event: 'enrolamientoMobile_event',​
  event_category: 'flujoEnrolamientoMobile',​
  event_action: 'step_4.2',
  event_label: 'inicio_sesion_desde_enrolamiento_mobile'​
};

export const ENROLL_CLICK_RIPLEY_URL = {
  event: 'enrolamientoMobile_event',​
  event_category: 'flujoEnrolamientoMobile',​
  event_action: 'step_4.3',
  event_label: 'ir_a_bancoripleyComPe_mobile'​
};

export const ENROLL_SCREEN_SHOW_PWA = {
  event: 'enrolamiento_event',​
  event_category: 'flujoEnrolamiento',​
  event_action: 'step_1.1',
  event_label: 'home_enrolamiento'
};

export const ENROLL_TO_REGISTER_PWA = {
  event: 'enrolamiento_event',​
  event_category: 'flujoEnrolamiento',​
  event_action: 'step_1.2',
  event_label: 'inciar_registro'
};

export const ENROLL_INPUT_DOCUMENT_NUMBER_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_1.3',
  event_label: 'ingresar_numero_documento_enrolamiento'​
};

export const ENROLL_PRESS_CONTINUE_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_1.6',
  event_label: 'continuar_enrolamiento'
};

export const ENROLL_PRESS_CANCEL_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_1.7',
  event_label: 'cancelar_enrolamiento'
};

export const ENROLL_SCREEN_ENTER_DNI = {

  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_1.8',
  event_label: 'vista_ingreso_dni_enrolamiento'
  };


export const ENROLL_SCREEN_ENTER_SMS = {

    event: 'enrolamiento_event',
    event_category: 'flujoEnrolamiento',
    event_action: 'step_1.9',
    event_label: 'vista_ingreso_sms_enrolamiento'
    };


export const ENROLL_PRESS_NOT_SMS_NUMBER_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_2.0',
  event_label: 'no_es_numero_sms_enrolamiento'​
};

export const ENROLL_SET_SMS_IMPUT_PWA = {
  event: 'enrolamiento_event',​
  event_category: 'flujoEnrolamiento',​
  event_action: 'step_2.1',
  event_label: 'agregar_codigo_sms_enrolamiento'
};

export const ENROLL_SMS_VALIDATE_SUCCESFULL_PWA = {
  event: 'enrolamiento_event',​
  event_category: 'flujoEnrolamiento',​
  event_action: 'step_2.2',
  event_label: 'codigo_valido_sms_enrolamiento'
};

export const ENROLL_CLIENT_PRESS_CONTINUE_SMS_PWA = {

  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_2.3',
  event_label: 'continuar_sms_enrolamiento'

};

export const ENROLL_CLIENT_PRESS_CANCEL_SMS_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_2.4',
  event_label: 'cancelar_sms_enrolamiento'

};

export const ENROLL_CODIGO_SMS_INCORRECTO = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_2.5',
  event_label: 'codigo_sms_incorrecto_enrolamiento'
};

export const ENROLL_PAGE_CREATE_PASSWORD_PWA = {
  event: 'enrolamiento_event',​
  event_category: 'flujoEnrolamiento',​
  event_action: 'step_3.1',
  event_label: 'pantalla_ingresar_clave_enrolamiento'​
};

export const ENROLL_ACCEPT_TERMS_PWA = {
  event: 'enrolamiento_event',​
  event_category: 'flujoEnrolamiento',​
  event_action: 'step_3.2',
  event_label: 'acepto_terminos_condiciones_enrolamiento'​
};

export const ENROLL_REGISTER_USER_PWA = {
  event: 'enrolamiento_event',​
  event_category: 'flujoEnrolamiento',​
  event_action: 'step_3.3',
  event_label: 'agregar_registrarse'​
};

export const ENROLL_CANCEL_SUBMIT_FORM_PWA = {
  event: 'enrolamiento_event',​
  event_category: 'flujoEnrolamiento',​
  event_action: 'step_3.4',
  event_label: 'cancelar_registro_clave'​
};

export const ENROLL_USER_CREATE_OK_PWA = {
  event: 'enrolamiento_event',​
  event_category: 'flujoEnrolamiento',​
  event_action: 'step_4.1',
  event_label: 'exito_enrolamiento'
};

export const ENROLL_CLICK_RIPLEY_BANK_PWA = {
  event: 'enrolamiento_event',​
  event_category: 'flujoEnrolamiento',​
  event_action: 'step_4.2',
  event_label: 'inicio_sesion_desde_enrolamiento'​
};

export const ENROLL_CLICK_RIPLEY_URL_PWA = {
  event: 'enrolamiento_event',​
  event_category: 'flujoEnrolamiento',​
  event_action: 'step_4.3',
  event_label: 'ir_a_bancoripleyComPe'​
};



//pin + pan 


//SALE AL VER LA PANTALLA DE DNI
export const VIEW_DNI_ENROLL =   {
  event: 'enrolamiento_event',
  event_category: 'FlujoEnrolamiento',
  event_action: 'step_1.2.1',
  event_label: 'vista_ingreso_DNI-CE'
  }

//SALE AL VER LA PANTALLA DE PIN + PAN
export const VIEW_SCREEN_PIN_PAN = {

  event: 'enrolamiento_event',
event_category: 'FlujoEnrolamiento',
event_action: 'step_1.8',
event_label: 'vista_pinpan'
}  


// AL ESCRIBIR EL NÚMERO DE TARJETA

export const INSERT_CREDI_NUMBER = {

event: 'enrolamiento_event',
event_category: 'FlujoEnrolamiento',
event_action: 'step_1.9',
event_label: 'ingresar_tarjeta'

}

// AL PONER EL PIN DE 4 DÍGITOS

export const INSERT_PIN_NUMBER = {


event: 'enrolamiento_event',
event_category: 'FlujoEnrolamiento',
event_action: 'step_1.10',
event_label: 'ingresar_pin'

}

// AL PRESIONAR EL BOTON ACEPTAR EN PIN + PAN

export const PRESS_BUTTON_ACCEPT_PIN_PAN = {

event: 'enrolamiento_event',
event_category: 'FlujoEnrolamiento',
event_action: 'step_1.11',
event_label: 'continuar_enrolamiento_pinpan'
}


// AL PRESIONAR EL BOTON CANCELAR 

export const PRESS_BUTTON_CANCEL_PIN_PAN ={

event: 'enrolamiento_event',
event_category: 'FlujoEnrolamiento',
event_action: 'step_1.12',
event_label: 'cancelar_enrolamiento_pinpan'

}

// ERROR DE NUMERO DE TARJETA INVALIDO 

export const MESSAGE_ERROR_INVALID_CARD = {

event: 'enrolamiento_event',
event_category: 'FlujoEnrolamiento',
event_action: 'step_1.13',
event_label: 'error_num_tarjeta_invalida'
}


// MODAL DE VISTA DESPUES DE BOTTON CANCELAR

export const MODAL_BOTTON_CANCEL = {

event: 'enrolamiento_event',
event_category: 'FlujoEnrolamiento',
event_action: 'step_1.14',
event_label: 'vista_pregunta_cancelar_registro'
}

export const HOME_CREATE_RECOVER= {

  event: 'crear_recuperar_clave_event',
  event_category: 'FlujoCrearRecuperarClave',
  event_action: 'step_1.1',
  event_label: 'home_CrearRecuperarClave'
  }

//click crear o recuperar
export const LOGIN_CLICK_CREATE_RECOVER= {

  event: 'crear_recuperar_clave_event',
  event_category: 'FlujoCrearRecuperarClave',
  event_action: 'step_1.2',
  event_label: 'iniciar_CrearRecuperarClave'
    }

//ver la pantalla de dni
export const DNI_SCREEN_CREATE_RECOVER= {

  event: 'crear_recuperar_clave_event',
  event_category: 'FlujoCrearRecuperarClave',
  event_action: 'step_1.3',
  event_label: 'vista_ingreso_DNI-CE'
      }

//Escribir el dni en el label
export const DNI_WRITE_CREATE_RECOVER= {

  event: 'crear_recuperar_clave_event',
  event_category: 'FlujoCrearRecuperarClave',
  event_action: 'step_1.4',
  event_label: 'agregar_DNI-CE'
      } 

export const CONTINUE_CREATE_RECOVER= {

  event: 'crear_recuperar_clave_event',
  event_category: 'FlujoCrearRecuperarClave',
  event_action: 'step_1.5',
  event_label: 'continuar_CrearRecuperarClave'
      } 

export const CANCEL_CREATE_RECOVER= {

  event: 'crear_recuperar_clave_event',
  event_category: 'FlujoCrearRecuperarClave',
  event_action: 'step_1.6',
  event_label: 'cancelar_CrearRecuperarClave'
      } 

//vista de selecciona metodo crear clave 
export const SELECC_SCREEN_CREATE= {
event:'crear_recuperar_clave_event',
event_category: 'flujoCrearClave',
event_action: 'step_2.1',
event_label: 'vista_autenticacion_enrolamiento'
}

export const FACIAL_BOTTON_CREATE= {
  event:'crear_recuperar_clave_event',
  event_category: 'flujoCrearClave',
  event_action: 'step_2.2',
  event_label: 'seleccion_reconocimientofacial'

  }

export const SMS_BOTTON_CREATE= {
  event:'crear_recuperar_clave_event',
  event_category: 'flujoCrearClave',
  event_action: 'step_2.3',
  event_label: 'seleccion_sms'

  }


export const PINPAN_BOTTON_CREATE= {
  event:'crear_recuperar_clave_event',
  event_category: 'flujoCrearClave',
  event_action: 'step_2.4',
  event_label: 'seleccion_pinpan'

  } 


  //taggueos SMS crear contraseña 
  export const SMS_SCREEN_CREATE= {
    event:'crear_recuperar_clave_event',
    event_category: 'flujoCrear_SMS',
    event_action: 'step_4.1',
    event_label: 'vista_crearclave_sms'
  
    } 

    export const SMS_WRITE_CODE_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrear_SMS',
      event_action: 'step_4.2',
      event_label: 'agregar_clavesms'
    
    } 



  export const SMS_AUTH_BOTTON_CREATE= {
    event: 'crear_recuperar_clave_event',
    event_category: 'flujoCrear_SMS',
    event_action: 'step_4.3',
    event_label: 'autorizar_clavesms'
  
  } 

    export const SMS_RESEND_BOTTON_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrear_SMS',
      event_action: 'step_4.4',
      event_label: 'reenviar_clavesms'
    
      } 

    export const SMS_NO_NUMBER_BOTTON_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrear_SMS',
      event_action: 'step_4.5',
      event_label: 'noestunumero_clavesms'
    
      } 

    export const SMS_OTHER_METHOD_BOTTON_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrear_SMS',
      event_action: 'step_4.6',
      event_label: 'otrometodo_clavesms'
    
      }

  //taggueos PINPAN crear contraseña

    export const PINPAN_SCREEN_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrear_PIN',
      event_action: 'step_5.1',
      event_label: 'vista_crearclave_pinpan'
  
    } 

    export const PAN_WRITE_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrear_PIN',
      event_action: 'step_5.2',
      event_label: 'agregar_numerotarjeta_pinpan'
    
      } 
  
    export const PIN_WRITE_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrear_PIN',
      event_action: 'step_5.3',
      event_label: 'agregar_pin_pinpan'
      
        } 

    export const PINPAN_CONTINUE_BOTTON_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrear_PIN',
      event_action: 'step_5.4',
      event_label: 'continuar_pinpan'
      
        } 
    
    export const PINPAN_CANCEL_BOTTON_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrear_PIN',
      event_action: 'step_5.5',
      event_label: 'cancelar_pinpan'
      
        }
  
//CREAR CLAVE DE 6 DÍGITOS
    export const PASSW6_SCREEN_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrearClave',
      event_action: 'step_7.1',
      event_label: 'vista_creación_clave'
      
        }

    export const PASSW6_CHECK_PROTECT_DATA_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrearClave',
      event_action: 'step_7.2',
      event_label: 'check_datos_personales_creacionclave'
      
        }

    export const PASSW6_CHECK_TERMS_COND_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrearClave',
      event_action: 'step_7.3',
      event_label: 'check_tyc_creacionclave'
      
        }
      
    export const PASSW6_BOTON_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrearClave',
      event_action: 'step_7.4',
      event_label: 'crear_clave_final'
      
        }

    export const PASSW6_BOTON_CANCEL_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrearClave',
      event_action: 'step_7.5',
      event_label: 'cancelar_creacionclave'
      
        }

    export const PASSW6_SCREEN_SUCCESS_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrearClave',
      event_action: 'step_7.6',
      event_label: 'vista_validación_exito_creacionclave'
      
        }
      
    export const PASSW6_BOTON_ENTER_CREATE= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoCrearClave',
      event_action: 'step_7.7',
      event_label: 'ingresar_éxito_creacionclave'
      
        }


//vista de selecciona metodo recuperar clave 
export const SELECC_SCREEN_RECOVER= {
  event: 'crear_recuperar_clave_event',
  event_category: 'flujoRecuperarClave',
  event_action: 'step_2.1',
  event_label: 'vista_autenticacion_recupero'
  }

  export const FACIAL_BOTTON_RECOVER= {
  event: 'crear_recuperar_clave_event',
  event_category: 'flujoRecuperarClave',
  event_action: 'step_2.2',
  event_label: 'seleccion_reconocimientofacial'

  }

export const SMS_BOTTON_RECOVER= {
  event: 'crear_recuperar_clave_event',
  event_category: 'flujoRecuperarClave',
  event_action: 'step_2.3',
  event_label: 'seleccion_sms'

  }


export const PINPAN_BOTTON_RECOVER= {
  event: 'crear_recuperar_clave_event',
  event_category: 'flujoRecuperarClave',
  event_action: 'step_2.4',
  event_label: 'seleccion_pinpan'

  } 

  //taggueos SMS crear contraseña 
  export const SMS_SCREEN_RECOVER= {
    event: 'crear_recuperar_clave_event',
    event_category: 'flujoRecuperarClave',
    event_action: 'step_4.1',
    event_label: 'vista_recuperarclave_sms'
  
    } 
  

    export const SMS_WRITE_CODE_RECOVER= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoRecuperarClave',
      event_action: 'step_4.2',
      event_label: 'agregar_clavesms'
    
    } 

  

  export const SMS_AUTH_BOTTON_RECOVER= {
    event: 'crear_recuperar_clave_event',
    event_category: 'flujoRecuperarClave',
    event_action: 'step_4.3',
    event_label: 'autorizar_clavesms'
  
    } 

    export const SMS_RESEND_BOTTON_RECOVER= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoRecuperarClave',
      event_action: 'step_4.4',
      event_label: 'reenviar_clavesms'
    
      } 

    export const SMS_NO_NUMBER_BOTTON_RECOVER= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoRecuperarClave',
      event_action: 'step_4.5',
      event_label: 'noestunumero_clavesms'
    
      } 

    export const SMS_OTHER_METHOD_BOTTON_RECOVER= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoRecuperarClave',
      event_action: 'step_4.6',
      event_label: 'otrometodo_clavesms'
    
      }

  //taggueos PINPAN crear contraseña

  export const PINPAN_SCREEN_RECOVER= {
    event: 'crear_recuperar_clave_event',
    event_category: 'flujoRecuperarClave',
    event_action: 'step_5.1',
    event_label: 'vista_recuperarclave_pinpan'
  
    } 

    export const PAN_WRITE_RECOVER= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoRecuperarClave',
      event_action: 'step_5.2',
      event_label: 'agregar_numerotarjeta_pinpan'
    
      } 
  
    export const PIN_WRITE_RECOVER= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoRecuperarClave',
      event_action: 'step_5.3',
      event_label: 'agregar_pin_pinpan'
      
        } 

    export const PINPAN_CONTINUE_BOTTON_RECOVER= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoRecuperarClave',
      event_action: 'step_5.4',
      event_label: 'continuar_pinpan'
      
        } 
    
    export const PINPAN_CANCEL_BOTTON_RECOVER= {
      event: 'crear_recuperar_clave_event',
      event_category: 'flujoRecuperarClave',
      event_action: 'step_5.5',
      event_label: 'cancelar_pinpan'
      
        }

//CREAR CLAVE DE 6 DÍGITOS
export const PASSW6_SCREEN_RECOVER= {
  event:'crear_recuperar_clave_event',
  event_category:'flujoRecuperarClave',
  event_action: 'step_7.1',
  event_label: 'vista_recuperar_clave'
  
    }

export const PASSW6_CHECK_PROTECT_DATA_RECOVER= {
  event:'crear_recuperar_clave_event',
  event_category: 'flujoRecuperarClave',
  event_action: 'step_7.2',
  event_label: 'check_datos_personales_recuperarclave'
  
    }

export const PASSW6_CHECK_TERMS_COND_RECOVER= {
  event:'crear_recuperar_clave_event',
  event_category: 'flujoRecuperarClave',
  event_action: 'step_7.3',
  event_label: 'check_tyc_recuperarclave'
  
    }
  
export const PASSW6_BOTON_RECOVER= {
  event:'crear_recuperar_clave_event',
  event_category: 'flujoRecuperarClave',
  event_action: 'step_7.4',
  event_label: 'recuperar_clave_final'
  
    }

export const PASSW6_BOTON_CANCEL_RECOVER= {
  event:'crear_recuperar_clave_event',
  event_category: 'flujoRecuperarClave',
  event_action: 'step_7.5',
  event_label: 'cancelar_recuperarclave'
  
    }

export const PASSW6_SCREEN_SUCCESS_RECOVER= {
  event:'crear_recuperar_clave_event',
  event_category: 'flujoRecuperarClave',
  event_action: 'step_7.6',
  event_label: 'vista_validación_exito_recuperarclave'
  
    }
  
export const PASSW6_BOTON_ENTER_RECOVER= {
  event:'crear_recuperar_clave_event',
  event_category: 'flujoRecuperarClave',
  event_action: 'step_7.7',
  event_label: 'ingresar_éxito_recuperarclave'
  
    }




