import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { ObjectResponse, HttpError } from '@common/response/error.response';
import { ERROR_RESPONSE } from '@common/types/error.types';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    private msgError: string;

    constructor(private router: Router) {}

    handlerError(err: HttpErrorResponse): ERROR_RESPONSE {
        let handled: ERROR_RESPONSE;
        const url = this.router.url;
        if(url === '/login' || url === '/login?action=logout') {
            switch(err.status) {

                case HttpError.Unauthorized:
                    handled = {
                        error: {
                            error: '401',
                            message: 'Es necesario autenticar para obtener la respuesta solicitada'
                        },
                    };
                    break;
                case HttpError.BadRequest:
                    // Error con estructura del backend
                    // Buscamos en el objeto el codigo de error
                    const result = ObjectResponse.find(e => e.error === err.error.error);

                    if(err.error.error === '20') {
                        result.message = err.error.error_description;
                    }
                    
                    handled = {
                        error: {
                            error: result.error || '100',
                            message: result.message || 'Error no mapeado'
                        }
                    };
                    break  
                case HttpError.Forbidden:
                    handled = {
                        error: {
                            error: '403',
                            message: 'El cliente no posee los permisos necesarios para cierto contenido'
                        }
                    }
                    break;    
                case HttpError.NotFound:
                    handled = {
                        error: {
                            error: '404',
                            message: 'El servidor no pudo encontrar el contenido solicitado'
                        }
                    }
                    break;   
                case HttpError.TimeOut:
                    handled = {
                        error: {
                            error: '408',
                            message: 'El tiempo de respuesta ha caducado'
                        }
                    }   
                    
                case HttpError.InternalServerError:
                    handled = {
                        error: {
                            error: '500',
                            message: 'Error interno del servidor'
                        }
                    }   
                    break;    

                case HttpError.GatewayTimeout:
                    handled = {
                        error: {
                            error: '504',
                            message: 'Exceso de tiempo en Gateway'
                        }
                    } 
                    break;
            }
            
        } else {
            // Errores que no han sido integrados en el interceptor de errores
            handled = {
                error: err.error
            }
        }
        return handled;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let handle: ERROR_RESPONSE;

        return next.handle(req).pipe(
            //retry(1), Repite la consulta cuando existe un error (refresh token)
            map(res => {
                // solo para modo dev
                // console.log('Pasó a través del interceptor en respuesta');
                return res;
            }),
            catchError((err) => {
                if(err.error instanceof ErrorEvent) {
                    //console.log('Esto es un error del lado del cliente');
                    this.msgError = `Error: ${err.error.message}`;
                } else if(err instanceof HttpErrorResponse) {
                    this.msgError = `Error Code: ${err.status},  Message: ${err.message}`;
                    handle = this.handlerError(err)
                }

                if(!handle) {
                    if(this.msgError) {
                        return throwError(this.msgError)
                    } else {
                        return throwError('Problema no esperado')
                    }
                } else {
                    return throwError(handle)
                }
            })
        )
    }
}