export class IphoneGenerationInfo {
    identifier: string;
    generation: string;
}

export const IPHONE_GENERATION_INFO: IphoneGenerationInfo[] = [
    { identifier: 'iPhone1,1', generation: 'iPhone' },
    { identifier: 'iPhone1,2', generation: 'iPhone 3G' },
    { identifier: 'iPhone2,1', generation: 'iPhone 3GS' },
    { identifier: 'iPhone3,1', generation: 'iPhone 4' },
    { identifier: 'iPhone3,2', generation: 'iPhone 4 GSM Rev A' },
    { identifier: 'iPhone3,3', generation: 'iPhone 4 CDMA' },
    { identifier: 'iPhone4,1', generation: 'iPhone 4S' },
    { identifier: 'iPhone5,1', generation: 'iPhone 5 (GSM)' },
    { identifier: 'iPhone5,2', generation: 'iPhone 5 (GSM+CDMA)' },
    { identifier: 'iPhone5,3', generation: 'iPhone 5C (GSM)' },
    { identifier: 'iPhone5,4', generation: 'iPhone 5C (Global)' },
    { identifier: 'iPhone6,1', generation: 'iPhone 5S (GSM)' },
    { identifier: 'iPhone6,2', generation: 'iPhone 5S (Global)' },
    { identifier: 'iPhone7,1', generation: 'iPhone 6 Plus' },
    { identifier: 'iPhone7,2', generation: 'iPhone 6' },
    { identifier: 'iPhone8,1', generation: 'iPhone 6s' },
    { identifier: 'iPhone8,2', generation: 'iPhone 6s Plus' },
    { identifier: 'iPhone8,4', generation: 'iPhone SE (GSM)' },
    { identifier: 'iPhone9,1', generation: 'iPhone 7' },
    { identifier: 'iPhone9,2', generation: 'iPhone 7 Plus' },
    { identifier: 'iPhone9,3', generation: 'iPhone 7' },
    { identifier: 'iPhone9,4', generation: 'iPhone 7 Plus' },
    { identifier: 'iPhone10,1', generation: 'iPhone 8' },
    { identifier: 'iPhone10,2', generation: 'iPhone 8 Plus' },
    { identifier: 'iPhone10,3', generation: 'iPhone X Global' },
    { identifier: 'iPhone10,4', generation: 'iPhone 8' },
    { identifier: 'iPhone10,5', generation: 'iPhone 8 Plus' },
    { identifier: 'iPhone10,6', generation: 'iPhone X GSM' },
    { identifier: 'iPhone11,2', generation: 'iPhone XS' },
    { identifier: 'iPhone11,4', generation: 'iPhone XS Max' },
    { identifier: 'iPhone11,6', generation: 'iPhone XS Max Global' },
    { identifier: 'iPhone11,8', generation: 'iPhone XR' },
    { identifier: 'iPhone12,1', generation: 'iPhone 11' },
    { identifier: 'iPhone12,3', generation: 'iPhone 11 Pro' },
    { identifier: 'iPhone12,5', generation: 'iPhone 11 Pro Max' },
    { identifier: 'iPhone12,8', generation: 'iPhone SE 2nd Gen' },
    { identifier: 'iPhone13,1', generation: 'iPhone 12 Mini' },
    { identifier: 'iPhone13,2', generation: 'iPhone 12' },
    { identifier: 'iPhone13,3', generation: 'iPhone 12 Pro' },
    { identifier: 'iPhone13,4', generation: 'iPhone 12 Pro Max' },
    { identifier: 'iPhone14,2', generation: 'iPhone 13 Pro' },
    { identifier: 'iPhone14,3', generation: 'iPhone 13 Pro Max' },
    { identifier: 'iPhone14,4', generation: 'iPhone 13 Mini' },
    { identifier: 'iPhone14,5', generation: 'iPhone 13' },
    { identifier: 'iPhone14,6', generation: 'iPhone SE 3rd Gen' },
    { identifier: 'iPhone14,7', generation: 'iPhone 14' },
    { identifier: 'iPhone14,8', generation: 'iPhone 14 Plus' },
    { identifier: 'iPhone15,2', generation: 'iPhone 14 Pro' },
    { identifier: 'iPhone15,3', generation: 'iPhone 14 Pro Max' },
    { identifier: 'iPhone15,4', generation: 'iPhone 15' },
    { identifier: 'iPhone15,5', generation: 'iPhone 15 Plus' },
    { identifier: 'iPhone16,1', generation: 'iPhone 15 Pro' },
    { identifier: 'iPhone16,2', generation: 'iPhone 15 Pro Max' },
];