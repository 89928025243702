import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { FACE_ID_PLATFORM_ID, INVALID_CREDENTIALS_CODE, LOCKED_USER_BY_ADMIN_CODE, LOCKED_USER_CODE, MULTIPLE_SESSIONS_CODE, NOT_ENROLLED_CODE } from "@common/constants/auth.constants";
import { RPASS_GA4_CONSTANTS } from "@common/constants/digital-auth.constants";
import { DNI_LENGTH, DNI_SEGMENT } from "@common/constants/document-type.constants";
import { RIPLEY_BANK_CARD_SITE, RIPLEY_BANK_PUP_SITE } from "@common/constants/misc.constants";
import { ENROLLED_LOCKED_LOGIN_RPASS, GENERIC_ERROR_LOGIN_RPASS, LOCKED_USER_BY_ADMIN_LOGIN_RPASS, MULTIPLE_SESSIONS_LOGIN_RPASS, NOT_RIPLEY_USER_PROPS } from "@common/constants/modal-props.constants";
import { ENROLLMENT_ROUTE, PASSWORD_RESET_ROUTE } from "@common/constants/routes.constants";
import { ENROLL_REGISTER } from "@common/constants/tag/tag-enrollment.constants";
import { GenericModalComponent } from "@components/generic-modal/generic-modal.component";
import { PoliticModalComponent } from "@components/politic-modal/politic-modal.component";
import { ModalController, Platform } from "@ionic/angular";
import { AccountManagerService } from "@services/account-manager/account-manager.service";
import { AuthService } from "@services/auth/auth.service";
import { BiometricService } from "@services/biometric/biometric.service";
import { FirebaseLoggerService } from "@services/firebase-logger/firebase-logger.service";
import { LoginService } from "@services/login/login.service";
import { NotificationsService } from "@services/notifications/notifications.service";
import { StorageService } from "@services/storage/storage.service";
import { UtilsService } from "@services/utils/utils";
import { WidgetService } from "@services/widget/widget.service";
import { Subscription } from "rxjs";

import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-scanner-qr-prompt',
  templateUrl: './scanner-qr-prompt.component.html',
  styleUrls: ['./scanner-qr-prompt.component.scss'],
})
export class ScannerQrPromptComponent implements OnInit {

  @Output() public newItemEvent = new EventEmitter<boolean>();
  @Output() public showTitle = new EventEmitter<boolean>();

  public showLogin: boolean = true;
  public authenticationError: string;
  public userDni: string;
  public userPassword: string;
  public userName: string;
  public isLoading: boolean = false;
  public loginText : string = 'Continuar';
  public isNative: boolean;
  public hasBiometricSetUp: boolean;
  public hasBiometricEnabled: boolean;
  public subscription: Subscription;
  public documentLoginCode : string;
  public documentTypeLength : number;
  public documentTypePlaceholder : string;
  public documentTypes: any;
  public documentTypeSelected : string;

  constructor(
    private widgetService: WidgetService,
    private authService: AuthService,
    private router: Router,
    private storage: StorageService,
    private modalController: ModalController,
    private biometricService: BiometricService,
    private accountService: AccountManagerService,
    private firebaseLoggerService: FirebaseLoggerService,
    private utilsService: UtilsService,
    private loginService: LoginService,
    private notificationService: NotificationsService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.hasBiometricSetUp = false;
    this.hasBiometricEnabled = false;
  }

  public checkOnlyNumbers($event) : string { return UtilsService.checkOnlyNumbers($event); }

  public goToScanner() {
    this.newItemEvent.emit(true);
  }

  public goToLogin() {
    this.showTitle.emit(true);
    this.showLogin = true;
  }

  public async ngOnInit() {
    this.isNative = this.utilsService.isNative();
    this.documentTypePlaceholder = '12345678';
    this.documentLoginCode = DNI_SEGMENT.value;
    this.documentTypeLength  = DNI_LENGTH;
    const eventGA4 = {
      product: RPASS_GA4_CONSTANTS.LOGIN_RPASS.PRODUCT,
      action: RPASS_GA4_CONSTANTS.LOGIN_RPASS.ACTION,
      step: RPASS_GA4_CONSTANTS.LOGIN_RPASS.STEP,
      step_part: RPASS_GA4_CONSTANTS.LOGIN_RPASS.STEP_PART,
      category: RPASS_GA4_CONSTANTS.LOGIN_RPASS.CATEGORY,
    };
    this.googleAnalyticsService.gtagPushEvent(RPASS_GA4_CONSTANTS.LOGIN_RPASS.EVENT, eventGA4);
  }

  public async ionViewWillEnter() {
    this.subscription = new Subscription();
  }

  public async ionViewWillLeave() {
    if (this.isNative && !this.hasBiometricSetUp && !!this.authService.isAuthenticated()) {
      this.openBiometricSuggestionModal();
    }
    this.subscription.unsubscribe();
  }

  public async getApplicationLoginInformation() {
    [this.userName, this.userDni, this.hasBiometricSetUp, this.hasBiometricEnabled] = await Promise.all([
      this.storage.get('userName'),
      this.accountService.accountManager.getUserDNI(),
      this.biometricService.isBiometricSetUp(),
      this.biometricService.isBiometricEnabled()
    ]);
  }

  public async openBiometricSuggestionModal() {
    try {
      const type = await this.biometricService.availableHardware();
      const biometricText = this.createModalBiometricTexts(type);
      const modal = await this.modalController.create({
        component: GenericModalComponent,
        cssClass: 'alert-modal',
        componentProps: {
          message: `<b>¡Ingresa fácilmente con ${biometricText.title}!</b><br>
          ¿Te gustaría habilitar el ingreso con ${biometricText.subtitle}? Lo puedes cambiar más tarde en Ajustes.`,
          primaryButtonText: '¡Si!',
          secondaryButtonText: 'No, gracias',
          icon: biometricText.icon
        }
      });

      modal.present();
      const { data } = await modal.onDidDismiss();
      if (data === 'primaryButtonPressed') {
        this.biometricService.enableBiometricConfig();
      } else {
        this.biometricService.disableBiometricConfig();
      }
    } catch (err) {
      this.biometricService.disableBiometricConfig();
      this.hasBiometricEnabled = false;
    }
  }

  public async login(loginForm: NgForm) {
    const eventGA4 = {
      product: 'continuar',
      action: RPASS_GA4_CONSTANTS.LOGIN_RPASS_BTN.ACTION,
      step: RPASS_GA4_CONSTANTS.LOGIN_RPASS.STEP,
      step_part: RPASS_GA4_CONSTANTS.LOGIN_RPASS.STEP_PART,
      category: RPASS_GA4_CONSTANTS.LOGIN_RPASS.CATEGORY,
    };
    this.googleAnalyticsService.gtagPushEvent(RPASS_GA4_CONSTANTS.LOGIN_RPASS_BTN.EVENT, eventGA4);
    await this.loginService.logoutWithoutRedirection();
    this.clearErrorMessage();
    this.showLoadingButton();
    this.loginService.simpleLogin(this.documentLoginCode, this.userDni, this.userPassword + '')
      .then(async (response) => {
        this.hideLoadingButton();
        this.firebaseLoggerService.logEvent('log_in');
        this.widgetService.jwToken = response.jwt;
        this.widgetService.rut = this.userDni;
        this.newItemEvent.emit(true);
        this.storeNotificationToken();
      })
      .catch((error) => {
        this.hideLoadingButton();
        switch (error.code) {
          case NOT_ENROLLED_CODE:
            this.showErrorModal(NOT_RIPLEY_USER_PROPS, () => window.open(RIPLEY_BANK_CARD_SITE));
            break;
          case INVALID_CREDENTIALS_CODE:
            if (!loginForm) {
              this.biometricService.disableBiometricConfig();
              this.hasBiometricEnabled = false;
              this.userPassword = null;
              this.utilsService.showError('Cambiaste tu contraseña. Por favor, escríbela de nuevo.');
            } else {
              this.authenticationError = "Clave incorrecta";
            }
            break;
          case LOCKED_USER_CODE:
            this.showErrorModal(ENROLLED_LOCKED_LOGIN_RPASS, () => this.router.navigateByUrl(`/${PASSWORD_RESET_ROUTE}`))
            break;
          case LOCKED_USER_BY_ADMIN_CODE:
            this.showErrorModal(LOCKED_USER_BY_ADMIN_LOGIN_RPASS, () => window.open(RIPLEY_BANK_PUP_SITE));
            break;
          case MULTIPLE_SESSIONS_CODE:
            this.showErrorModal(MULTIPLE_SESSIONS_LOGIN_RPASS);
            break;
          default:
            this.showErrorModal(GENERIC_ERROR_LOGIN_RPASS);
        }
      }).finally(async () => {
        await this.loginService.logoutWithoutRedirection();
      });
  }

  public async registerAccount() {
    if (this.isNative && !this.hasBiometricEnabled) {
      await this.accountService.accountManager.unregister();
      await this.accountService.accountManager.registerAccount(this.userDni, this.userPassword);
    }
  }

  public clearErrorMessage() {
    this.authenticationError = '';
  }

  public showLoadingButton() {
    this.isLoading = true;
    this.loginText = 'Cargando...';
  }

  public hideLoadingButton() {
    this.isLoading = false;
    this.loginText = 'Continuar';
  }

  public createModalBiometricTexts(type: string) {
    const biometricText = {
      title: 'tu huella',
      subtitle: 'huella digital',
      icon: 'i-fingerprint'
    };
    if (type === FACE_ID_PLATFORM_ID) {
      biometricText.title = 'Face ID';
      biometricText.subtitle = 'Face ID';
      biometricText.icon = 'i-faceid';
    }
    return biometricText;
  }

  public async showErrorModal(modalProps, customAction?) {
    const modal = await this.modalController.create({
        component: GenericModalComponent,
        cssClass: 'rpass-activation-modal',
        componentProps: modalProps,
        backdropDismiss: false
    });
    await modal.present();
    if(customAction) {
      const { data } = await modal.onDidDismiss();
      if(data === "primaryButtonPressed") return customAction();
    }
  }


  public goToEnrollment() {
    this.router.navigateByUrl(ENROLLMENT_ROUTE);
    const eventGA4 = {
      product: 'crear o recuperar clave',
      action: RPASS_GA4_CONSTANTS.LOGIN_RPASS_BTN.ACTION,
      step: RPASS_GA4_CONSTANTS.LOGIN_RPASS.STEP,
      step_part: RPASS_GA4_CONSTANTS.LOGIN_RPASS.STEP_PART,
      category: RPASS_GA4_CONSTANTS.LOGIN_RPASS.CATEGORY,
    };
    this.googleAnalyticsService.gtagPushEvent(RPASS_GA4_CONSTANTS.LOGIN_RPASS_BTN.EVENT, eventGA4);
  }

  public storeNotificationToken(){
    this.notificationService.requestPermission(this.documentLoginCode , this.userDni);
  }
}
