import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { createPopper, Instance, VirtualElement } from '@popperjs/core';

@Component({
  selector: 'app-cvv-helper-popover',
  templateUrl: './cvv-helper-popover.component.html',
  styleUrls: ['./cvv-helper-popover.component.scss'],
})
export class CvvHelperPopoverComponent implements OnInit, OnDestroy {
  ngOnInit(): void {
  }
  constructor(private popoverController: PopoverController) { }
  popperInstance: Instance

  @ViewChild('container') container: ElementRef;
  @ViewChild('tooltip') tooltip: ElementRef;
  @Input() element: Element; // decorate the property with @Input()
  showPopover = false

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: { target: any; }): void {
    if (!this.container.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.dismiss()
    }
  }
  ionViewWillEnter() {
    this.showPopover = true
    this.openPopover(this.element)
  }
  async dismiss() {
    this.showPopover = false
    await this.popoverController.dismiss();
  }
  async openPopover(ev: Element | VirtualElement) {
    this.popperInstance = createPopper(ev, this.tooltip.nativeElement, {
      placement: 'right',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        }
      ],
    });
    // Update its position
    setTimeout(() => {
      this.popperInstance.update();
    })
  }

  ngOnDestroy(): void {

    this.popperInstance.destroy();
  }
}
