import { CanLoad, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { LOGIN_ROUTE } from '@common/constants/routes.constants';

@Injectable({
  providedIn: 'root'
})
export class NoMaintenanceGuard implements CanLoad {
  isInMaintenance: boolean;

  constructor(private router: Router) {
    this.isInMaintenance = environment.MAINTENANCE;
  }

  canLoad(): boolean {
    if (this.isInMaintenance) { return true; }
    this.router.navigateByUrl(LOGIN_ROUTE);
    return false;
  }
}
