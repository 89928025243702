import { ISegmentSelector } from '@common/interfaces/default.interface';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-faq-columns',
  templateUrl: './faq-columns.component.html',
  styleUrls: ['./faq-columns.component.scss'],
})
export class FaqColumnsComponent {

  @Input() extraClass: string;
  @Input() faqs: ISegmentSelector[];

  constructor() { }
}
