export const REDEEM_GIFT_CARDS_SLIDER = {
  breakpointsInverse: true,
  slidesOffsetBefore: 0,
  slidesOffsetAfter: 0,
  grabCursor: true,
  zoom: {
    toggle: false,
  },
  breakpoints: {
    320: {
      slidesPerView: 1.3,
      freeMode: false,
      spaceBetween: 0,
      centeredSlides: true
    },
    340: {
      slidesPerView: 1.3,
      freeMode: false,
      spaceBetween: 0,
      centeredSlides: true
    },
    360: {
      slidesPerView: 1.8,
      freeMode: false,
      spaceBetween: 0,
      centeredSlides: true
    },
    380: {
      slidesPerView: 1.8,
      freeMode: false,
      spaceBetween: 0,
      centeredSlides: true
    },
    400: {
      slidesPerView: 1.8,
      freeMode: false,
      spaceBetween: 0,
      centeredSlides: true
    },
    460: {
      slidesPerView: 1.8,
      freeMode: false,
      spaceBetween: 0,
      centeredSlides: true
    },
    600: {
      slidesPerView: 1.7,
      freeMode: false,
      spaceBetween: 0,
      centeredSlides: true
    },
    769: {
      slidesPerView: 'auto',
      freeMode: true,
      spaceBetween: 10,
      centeredSlides: false
    }
  }
};

export const RIPLEY_POINTS_SLIDER = {
  breakpointsInverse: true,
  slidesOffsetBefore: 0,
  slidesOffsetAfter: 0,
  grabCursor: true,
  zoom: { toggle: false },
  breakpoints: {
    320: {
      slidesPerView: 1.3,
      freeMode: false,
      spaceBetween: 0,
      centeredSlides: true
    },
    340: {
      slidesPerView: 1.3,
      freeMode: false,
      spaceBetween: 0,
      centeredSlides: true
    },
    360: {
      slidesPerView: 1.8,
      freeMode: false,
      spaceBetween: 0,
      centeredSlides: true
    },
    380: {
      slidesPerView: 1.8,
      freeMode: false,
      spaceBetween: 0,
      centeredSlides: true
    },
    400: {
      slidesPerView: 1.8,
      freeMode: false,
      spaceBetween: 0,
      centeredSlides: true
    },
    460: {
      slidesPerView: 1.8,
      freeMode: false,
      spaceBetween: 0,
      centeredSlides: true
    },
    600: {
      slidesPerView: 1.7,
      freeMode: false,
      spaceBetween: 0,
      centeredSlides: true
    },
    769: {
      slidesPerView: 'auto',
      freeMode: true,
      spaceBetween: 33,
      centeredSlides: false
    }
  }
};
