import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAuthTransactionData, IMonitorRpassData} from '@common/interfaces/digital-auth.interface';
import { environment } from '@environments/environment';
import { IBiometriaAccount, IBiometricOut } from '@common/interfaces/account.interface';
import { HttpService } from '@services/http/http.service';
import { CHECK_ENROLLMENT_MOCK } from '@common/mocks/enrollment.mocks';
import {BlockDeviceRequest, GetDeviceStatusRequest, OnBoardingApiBaseResponse} from "@common/models/digital-auth";

@Injectable()
export class DigitalAuthService {
  constructor(
    private http: HttpClient,
    private httpService: HttpService,
  ) { }

  public authorizeTransaction(authTransactionData: IAuthTransactionData, oAuthToken: string): Observable<any> {
    const { authId, challengeData, channel, uuidDevice, uuidTransaction, appId, forwardedIp, userAgent, userId, userType, country } = authTransactionData;
    const body = {
      token: oAuthToken,
      country,
      uuidDevice,
      commonParamsRequest: {
        channel,
        appId,
        forwaredIp: forwardedIp,
        userAgent
      },
      commonUserDataRequest: {
        userId,
        userType
      },
      uuidTransaction,
      challengeData,
      authId,
      additionalAuthValues: null,
      additionalChallengeData: null,
      externalSessionId: null,
      signData: null,
      riskEngineData: null
    };

    return this.http.post<any>(
      environment.WIDGET_AUTH + '/api/v1/autorizar-transaccion',
      body, { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) }
    );
  }

  public rejectTransaction(authTransactionData: IAuthTransactionData, oAuthToken: string): Observable<any> {
    const { channel, uuidDevice, uuidTransaction, appId, forwardedIp, userAgent, userId, userType } = authTransactionData;
    const body = {
      token: oAuthToken,
      country : 'CHILE',
      uuidDevice,
      commonParamsRequest: {
        channel,
        appId,
        forwaredIp: forwardedIp,
        userAgent
      },
      commonUserDataRequest: {
        userId,
        userType,
        enterpriseCode: 'RIPLEY'
      },
      uuidTransaction
    };
    return this.http.post<any>(
      environment.WIDGET_AUTH  + '/api/v1/rechazar-transaccion',
      body, { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) }
    );
  }

  public async getOAuthToken(biometriaAccount: IBiometriaAccount): Promise<IBiometricOut>{
    let headers = {} as HttpHeaders;
    const url = environment.BASE_BACKEND_WORKER + '/enrollment/biometrics/token';
    return this.httpService.post(url, biometriaAccount, CHECK_ENROLLMENT_MOCK, headers).toPromise();
  }

  public async saveRpassData(monitorRpassData: IMonitorRpassData) {
    const url = environment.BASE_BACKEND_WORKER + '/rpass/save-auth-data';
    return this.http.post(url, monitorRpassData, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }).toPromise();
  }

  public getDeviceStatusOnBoardingApi(
    getDeviceStatusRequest: GetDeviceStatusRequest): Observable<OnBoardingApiBaseResponse> {
    return this.postOnBoardingApi(GetDeviceStatusRequest.PATH, getDeviceStatusRequest);
  }

  public blockDeviceOnBoardingApi(
    blockDeviceRequest: BlockDeviceRequest): Observable<OnBoardingApiBaseResponse> {
    return this.postOnBoardingApi(BlockDeviceRequest.PATH, blockDeviceRequest);
  }

  private postOnBoardingApi(path: string, body: any): Observable<OnBoardingApiBaseResponse> {
    return this.http.post<OnBoardingApiBaseResponse>(path, body, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }
}
