export const GENERIC_TAG_VIRTUAL_PAGE = 'virtual_page';
export const GENERIC_TAG_BTN_CLICK = 'btn_click';
export const GENERIC_TAG_POP_UP = 'pop_up';
export const GENERIC_TAG_FUNCTIONAL_ERROR = 'functional_error';
export const GENERIC_TAG_END_FLOW = 'end_flow';
export const GENERIC_TAG_PURCHASE = 'purchase';
export const GENERIC_TAG_OTRO_BANCO = 'otro banco';
export const GENERIC_TAG_BANCO_RIPLEY = 'banco ripley';
export const GENERIC_TAG_RPASS = 'rpass';
export const GENERIC_TAG_SMS = 'sms';
export const GENERIC_TAG_DESGRAVAMEN = 'desgravamen';
export const GENERIC_TAG_DESGRAVAMEN_CON_DEVOLUCION = 'desgravamen con devolucion';
export const GENERIC_TAG_CON_SEGURO_VIDA = 'con seguro de vida';
export const GENERIC_TAG_SIN_SEGURO_VIDA = 'sin seguro de vida';
export interface IGENERIC_TAG_FORMAT {
    product: string;
    action: string;
    category: string;
    sub_category: string;
    sub_category2?: string;
    step: string;
    ensurance_type: string;
    amount: number;
    fee: number;
};

export interface IALL_TAG_FORMAT extends IGENERIC_TAG_FORMAT {
    type?: string;
    step_part?: string;
    destination_bank?: string;
};

export const FUNCTION_TAG_FORMAT = (
    product: string, action: string, sub_category: string, step: string, 
    ensurance_type: string, amount: number, fee: number, sub_category2:string = ''
    ) : IGENERIC_TAG_FORMAT => {
    return {
        product, action, category: 'prestamos', sub_category, sub_category2, step, ensurance_type, amount, fee
    }
}

export const FUNCTION_TAG_ALL_FORMAT = (
    product: string, action: string, sub_category: string, step: string,
    step_part: string = null, ensurance_type: string, amount: number, fee: number,
    type: string = null, destination_bank: string = null, sub_category2: string = null
    ) : IALL_TAG_FORMAT => {
    return {
        product, action, category: 'prestamos', sub_category, sub_category2, step, step_part, ensurance_type, amount, fee, type, destination_bank
    }
}