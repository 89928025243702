export const RESPONSE_TEMPORARY_BLOCK = {
  success: [],
  failures: [],
};

export const RESPONSE_UPDATE_TEMPORARY_BLOCK_MOCK = {
  success: [],
  failures: [],
};

export const RESPONSE_UPDATE_RESTRICTION_BLOCK_MOCK = {
  success: [],
  failures: [],
};

export const RESTRICTION_LIST_MOCK = {
  success: [],
  failures: [],
};
