import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IOffering } from '@common/interfaces/offerings.interface';
import { LEAD_EXECUTION_CODE, ORIGIN_LOAN_PWA, PRODUCT_SEF_REENGANCHE, PRODUCT_SEF } from '@common/constants/offerings.constants';
import { LoanLeadService } from '@services/loan-lead/loan-lead.service';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { LOAN_LEAD_FAILED_MODAL_PROPS } from '@common/constants/modal-props.constants';
import { LeadExistModalComponent } from '@components/leads-modal/lead-exist-modal/lead-exist-modal.component';
import { OfferingModalSefSuccessComponent } from '@components/offering-modal-sef-success/offering-modal-sef-success.component';
import { HOME_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { GENERIC_TAG_BTN_CLICK, GENERIC_TAG_PURCHASE, GENERIC_TAG_VIRTUAL_PAGE } from '@common/constants/tag/tag-generic.constants';
import { SUCCESS } from '@common/constants/error.constants';
import { LEAD_EXIST_ERROR, LEAD_EXIST_ERROR_V2 } from '@common/constants/efex.constants';
import { EFEX, EFEX_CODE, SEF, SEF_CODE } from '@common/constants/misc.constants';
import { SyneriseServiceService } from '@services/synerise/synerise-service.service';

@Component({
  selector: 'app-offering-modal',
  templateUrl: './offering-modal.component.html',
  styleUrls: ['./offering-modal.component.scss'],
})
export class OfferingModalComponent {
  @Input() title: string;
  @Input() amountIcon: string;
  @Input() offer: IOffering;
  @Input() tca: string;
  @Input() disclaimer: string;
  @Input() primaryButtonText: string;
  @Input() origin: string;
  secondaryButtonText: string;
  isLoading: boolean;
  constructor(
    private modalController: ModalController,
    private loanLeadService: LoanLeadService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private syneriseService: SyneriseServiceService
  ) {
    this.isLoading = false;
  }

  ngOnInit(){
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_POPUP.product = 'detalles de tu';
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_POPUP.sub_category2 = this.offer.codeProduct === PRODUCT_SEF ? 'solicitud - aprobacion' : 'solicitud - renovacion';
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_POPUP.step = 'step detalles';
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_POPUP.amount = this.offer.maximumAmount;
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_POPUP.fee = this.offer.maximumTimeMonth;
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_VIRTUAL_PAGE, HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_POPUP);
  }

  public isEfex(offer: IOffering) {
    return offer.codeProduct === '005';
  }

  public isSef(offer: IOffering) {
    if (offer.codeProduct === PRODUCT_SEF) {
      this.primaryButtonText = '¡Solicítalo aquí!';
      this.secondaryButtonText = 'Cancelar';
      return true;
    }
    if (offer.codeProduct === PRODUCT_SEF_REENGANCHE) {
      this.primaryButtonText = '¡Solicítalo aquí!';
      this.secondaryButtonText = 'Cancelar';
      return true;
    }
    return false;
  }

  get isReenganche() { return this.offer.codeProduct === PRODUCT_SEF_REENGANCHE}

  public getTimeMonth(offer: IOffering) {
    return Math.max(offer.minimumTimeMonth, offer.maximumTimeMonth);
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  async onPrimaryClick() {
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_BTN.product = 'solicitalo aqui';
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_BTN.sub_category2 = this.offer.codeProduct === PRODUCT_SEF ? 'solicitud - aprobacion' : 'solicitud - renovacion';
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_BTN.step = 'step detalles';
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_BTN.amount = this.offer.maximumAmount;
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_BTN.fee = this.offer.maximumTimeMonth;
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_BTN_CLICK, HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_BTN);
    this.syneriseService.trackEvent(GENERIC_TAG_BTN_CLICK, HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_BTN);

    HOME_GA4_CONSTANTS.HOME_LOANS_PURCHASE.transaction_id = this.offer.sessionId;
    HOME_GA4_CONSTANTS.HOME_LOANS_PURCHASE.value = this.offer.maximumAmountLimit;
    HOME_GA4_CONSTANTS.HOME_LOANS_PURCHASE.items[0].price = this.offer.maximumAmountLimit;
    HOME_GA4_CONSTANTS.HOME_LOANS_PURCHASE.items[0].item_id = `${this.offer.codeProduct == SEF_CODE ? SEF : this.offer.codeProduct == EFEX_CODE ? EFEX : 'sef_reenganche'}_${this.offer.sessionId}`;
    HOME_GA4_CONSTANTS.HOME_LOANS_PURCHASE.items[0].item_name = `${this.offer.codeProduct == SEF_CODE ? SEF : this.offer.codeProduct == EFEX_CODE ? EFEX : 'sef_reenganche'}_lead`;
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_PURCHASE, HOME_GA4_CONSTANTS.HOME_LOANS_PURCHASE);

    if (this.offer.codeProduct === PRODUCT_SEF || this.offer.codeProduct === PRODUCT_SEF_REENGANCHE) {
      this.isLoading = true;
      const executionCode = sessionStorage.getItem(LEAD_EXECUTION_CODE);
      if (executionCode !== null && executionCode !== undefined) {
        const loanLead = {
          codeProduct: this.isReenganche ? PRODUCT_SEF_REENGANCHE : PRODUCT_SEF,
          executionCode,
          reference: window.location.href,
          callCenterReason: '',
          originLoanPwa: sessionStorage.getItem(ORIGIN_LOAN_PWA)
        };
        try {
          const loanLeadResponse = await this.loanLeadService.registerLoanLead(loanLead);
          this.isLoading = false;
          this.modalController.dismiss('primaryButtonPressed');
          if (loanLeadResponse.code === SUCCESS) {
            const modal = await this.modalController.create({
              component: OfferingModalSefSuccessComponent,
              cssClass: 'form-modal',
              componentProps: {
                productName: this.offer.nameProduct,
                offer: this.offer
              },
            });
            await modal.present();
          }else if (loanLeadResponse.code === LEAD_EXIST_ERROR || loanLeadResponse.code === LEAD_EXIST_ERROR_V2) {
            this.openLeadExistModal();
          }
        } catch (err) {
          this.isLoading = false;
          this.modalController.dismiss('primaryButtonPressed');
          this.openFailedModal();
        }
      } else {
        this.modalController.dismiss('primaryButtonPressed');
        this.openFailedModal();
      }
    } else {
      this.modalController.dismiss('primaryButtonPressed');
    }
  }

  closeModal() {
    // TAG VENTAS: SEF
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_BTN.product = 'cancelar';
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_BTN.sub_category2 = this.offer.codeProduct === PRODUCT_SEF ? 'solicitud - aprobacion' : 'solicitud - renovacion';
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_BTN.step = 'step detalles';
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_BTN.amount = this.offer.maximumAmount;
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_BTN.fee = this.offer.maximumTimeMonth;
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_BTN_CLICK, HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_BTN);
    this.syneriseService.trackEvent(GENERIC_TAG_BTN_CLICK, HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_BTN);
    this.modalController.dismiss('primaryButtonPressed');
  }

  public async openFailedModal() {
    const modal = await this.modalController.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: LOAN_LEAD_FAILED_MODAL_PROPS,
    });
    modal.present();
  }

  public async openLeadExistModal() {
    const props = {
      productName: this.offer.productRealName
    };

    const modal = await this.modalController.create({
      component: LeadExistModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    modal.present();
  }

}
