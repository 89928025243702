import { NgModule } from '@angular/core';
import { WaveComponent } from './wave.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    WaveComponent,
  ],
  exports: [
    WaveComponent,
  ],
  entryComponents: [
    WaveComponent,
  ],
})
export class WaveModule {}
