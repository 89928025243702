import { Component, Input, OnDestroy } from '@angular/core';
import { IPromotionals } from '@common/interfaces/efex.interface';
import { MILLISECONDS_TO_SECOND } from '@common/constants/misc.constants';
import { ModalController } from '@ionic/angular';
import { EFEX_TCEA_MODAL_PROPS } from '@common/constants/modal-props.constants';
import { TceaComponent } from '../modals/tcea/tcea.component';
import { EFEX_SECONDS, EFEX_MINUTES, EFEX_SCHEDULE } from '@common/constants/efex.constants';
import { PeruvianCurrencyPipe } from '@pipes/peruvian-currency/peruvian-currency.pipe';

@Component({
  selector: 'app-details-efex',
  templateUrl: './details-efex.component.html',
  styleUrls: ['./details-efex.component.scss'],
})
export class DetailsEfexComponent implements OnDestroy {
  @Input() info: IPromotionals;
  @Input() timer: Date;
  tooltipText: string;
  minutes: number;
  seconds: number;
  timerText: string;
  showTimer: boolean;
  timerId: NodeJS.Timeout;
  peruvianCurrency: PeruvianCurrencyPipe;
  constructor(private modalCtrl: ModalController) {
    // tslint:disable-next-line: max-line-length
    this.tooltipText = 'La TCEA es calculada considerando el seguro de desgravamen porcentual sobre el saldo insoluto de la deuda según tarifario vigente. Tasas, Tarifas y Contratos en: www.bancoripley.com.pe, sección: Tasas, Tarifas y Contratos, seleccionando el producto contratado.';
    this.info = {} as IPromotionals;
  }

  checkTime() {
    const timeNow = new Date();
    const timeEfex = new Date();
    const difference = new Date();

    timeEfex.setHours(EFEX_SCHEDULE);
    timeEfex.setMinutes(0);
    timeEfex.setSeconds(0);

    difference.setHours(timeEfex.getHours() - timeNow.getHours());
    difference.setMinutes(timeEfex.getMinutes() - timeNow.getMinutes());
    difference.setSeconds(timeEfex.getSeconds() - timeNow.getSeconds());

    if (difference.getHours() === 0 && difference.getMinutes() <= EFEX_MINUTES && difference.getSeconds() >= 0) {
      this.minutes = difference.getMinutes();
      this.minutes <= 0 ? this.timerText = ' seg' : this.timerText = ' min';
      this.seconds = difference.getSeconds();
      this.timerId = setInterval(() => this.tick(), MILLISECONDS_TO_SECOND);
      this.showTimer = true;
    } else {
      this.showTimer = false;
    }
  }

  tick(): void {
    if (--this.seconds < 0) {
      this.seconds = EFEX_SECONDS;
      if (--this.minutes <= 0) {
        this.minutes < 0 ? this.stopTimer() : this.timerText = ' seg';
      }
    }
  }

  stopTimer() {
    this.showTimer = false;
    clearInterval(this.timerId);
  }

  async showTceaModal() {
    const modal = await this.modalCtrl.create({
      component: TceaComponent,
      cssClass: 'form-modal',
      componentProps: EFEX_TCEA_MODAL_PROPS,
    });
    await modal.present();
  }

  ngOnDestroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }
}
