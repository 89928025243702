export const RIPLEY_SERVICE_ERROR_STATUS = 450;

export const MOBILE_APP_BACKEND_ERROR_STATUS = 440;

export const CLIENT_WITHOUT_CARD = 709;

export const GET_RIPLEY_PRODUCTS_ERROR = 719;

export const FORBIDDEN_STATUS = 403;

export const DEFAULT_ERROR_ALERT_HEADER = 'Error';

export const DEFAULT_ERROR_ALERT_SUBHEADER = 'En estos momentos no podemos atender tu solicitud, inténtalo nuevamente más tarde.';

export const NO_TERMS_AND_CONDITION_MESSAGE = 'Los términos y condiciones no se encuentran disponibles, inténtalo nuevamente más tarde';

export const NO_ACCOUNT_AUTHORIZATIONS_MESSAGE =
  'Los términos de autorización de cuenta no se encuentran disponibles, inténtalo nuevamente más tarde';

export const GET_DOCUMENT_ERROR = 'No se ha podido obtener el documento';

export const ERROR_INESPERADO = 'Ocurrió un error inesperado, inténtalo nuevamente mas tarde.';

export const RECAPTCHA_ERROR_CODE = 1;

export const TIME_OUT_WEB_CODE = 2;

export const DATA_WRONG_CODE = 28;

export const SUCCESS = 0;