import { IEnvironment } from '@common/interfaces/environments.interface';

export const environment: IEnvironment = {
  ENV: 'development',
  BASE_BACKEND_WORKER: 'https://workersqa.qa.bancoripley.com.pe/homebanking-qa',
  TRANSFERS_ENABLED: true,
  CHANNEL: '85',
  USING_MOCKS: false,
  SERVICES_TIMEOUT: 60000,
  API_KEY: 'REMOTE',
  MAINTENANCE: false,
  MAINTENANCE_MESSAGE: '¡Volveremos pronto con mejores servicios para ti! Puedes realizar tus operaciones desde tu Banca Móvil.',
  MOCK_SUCCESS_RATE: 1,
  MOCK_DELAY_MILISECONDS: 1000,
  DNI_CODE: 'C',
  CLIENT_ID: 'appmobile',
  CLIENT_SECRET: 'IZrYHjPv',
  FOREIGN_CARD_CODE: '2',
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyAjSUPwifIbaLlnVn6ppR3tPhsZtrDlAoY',
    authDomain: 'hbk-qa.firebaseapp.com',
    databaseURL: 'https://hbk-qa.firebaseio.com',
    projectId: 'hbk-qa',
    storageBucket: 'hbk-qa.appspot.com',
    messagingSenderId: '157876645931',
    appId: '1:157876645931:web:3fccd79a47088ace293a6e',
    vapidKey: 'BBltGvl8TkGvL7VHv_Zq1iPH4bSKzWitP6QJLt8GQNcaZZQGyWOPlYDurYMZ0MeXP7WZh9congTa_7a2QmsQYn8',
    measurementId: 'G-9T5XTVHJM9'
  },
  PUSH_NOTIFICATIONS_API_PATH: '',
  PUBLIC_KEY_PEM: '-----BEGIN PUBLIC KEY-----\
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5fVOcIa7mA7Vmfl11jT2\
    ENADm8vASlkWXsgnMnh6WIDQrKU/ldXnBmtDLKlUPcImfsR9jW6f3+8++n1GWwSi\
    F1iSOGAB6k1p/4nrfQcytEsb57KDBpIpHOOE079AnEGK2jdGTGQo0vDNuCZJO7zG\
    90DNvOuLaaLPfb5fAkIH18See8h2cz2zJjHg978EfD/WII4th+rnKi+7PVOQ7H2l\
    VcXMVcRBAI+z2bVpDGxv/zAHadtdUbe8wJX+9zWLfHbmNSd+9ZDVCV3vD2kzH7To\
    o2jqmD3sSKzh57WS3htdDt5RYxJTD/M0YoUWN2PQ0y90IP9VqSj+W5ZT9oqWYMSl\
    1QIDAQAB\
    -----END PUBLIC KEY-----',
  RECAPTCHA_SITE_KEY: '6LeXhccUAAAAADl4VkIiUmT4oZogNcC5aapxMjBu',
  PAYMENTS_CONFIG: {
    CHANNEL: 'web',
    ENDPOINT_JS: 'https://pocpaymentserve.s3.amazonaws.com/payform.min.js',
    ENDPOINT_CSS: 'https://pocpaymentserve.s3.amazonaws.com/payform.min.css',
    LANGUAGE: 'es',
    FONT: 'https://fonts.googleapis.com/css?family=Montserrat:600&display=swap'
  },
  SSL_PINNING_ENABLED: false,
  IP_PROVIDERS:[
    "https://workersqa.qa.bancoripley.com.pe/homebanking-qa/ip"
  ],
  URL_QR:"../../../assets/img/qr-qa.png",  
  GA4_ID: 'G-3DG44WF1HB',
  WIDGET_ONB: 'https://corpwidonbpp.bancoripley.cl',
  WIDGET_ONB_API: 'https://corpwidonbapipp.bancoripley.cl',
  WIDGET_AUTH: 'https://corpwidautapipp.bancoripley.cl',
  WIDGET_AUTH_V3: 'https://corpwidautpp.bancoripley.cl',
  JWT_SECRET_WID_AUTH_V3: 'H@McQfTjWnZq4t7w!z%C*F-JaNdRgUkX',
  WIDGET_CLIENT_ID: '0f32526f4056b4ad6f7e511c1ecdbce7',
  URL_BANK: 'https://www.bancoripley.com.pe',
  TRACKER_KEY: 'c73e2cba-4650-4418-834d-d6c4df2c9c00',
};
