import { Component, Input, OnInit } from '@angular/core';
import { moduleDef } from '@angular/core/src/view';
import { ModalController } from '@ionic/angular';
import { concat } from 'rxjs';

@Component({
  selector: 'app-on-off-modal-timer',
  templateUrl: './on-off-modal-timer.component.html',
  styleUrls: ['./on-off-modal-timer.component.scss'],
})
export class OnOffModalTimerComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() time: number;
  @Input() timeString:string;

  constructor(private viewController: ModalController) {  
    this.startTimer();
   }

  ngOnInit() {
    
  }

  public dismissModal() {
    this.viewController.dismiss();
  }

  public async GoToHome() {
    this.dismissModal();
  }

  interval:any;

  startTimer() {

    this.interval = setInterval(() => {
     
      if(this.time > 0) {
        this.time--;
        this.timeString = this.getTimeString();
      } else {
        this.time = 120;
        /*this.showBlockButton = false;
        this.timerCounter = 0;*/
        this.pauseTimer();
        this.dismissModal();
      }

   },1000)
}

getTimeString(): string{

  let segundos = (this.time % 60).toString();
  let minutos = Math.trunc(this.time/60).toString();
  const segundosCadena = segundos.length>1?segundos:('0' + segundos);
  const minutosCadena = minutos.length>1?minutos:('0' + minutos);

  return minutosCadena + ":" + segundosCadena;

}

pauseTimer() {
  clearInterval(this.interval);
}


}
