export const PRODUCT_TYPE = 'TC';

export const GTM_STEPS_GENERAL = {
  TC: {
    event: 'visualizacion_datos_event',
    event_category: 'flujoVisualizacionDeDatos',
  },
  //si se desea taggear otros productos para visualización de tarjeta
  //ejem: tarjeta de débito
  //añadir código aquí
};

export const VDT_CONSTANTS = {
  HOME_PAGE: {
    EVENT: 'virtual_page',
    TAGS: {
      action: 'pageview',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step vista home'
    }
  },
  SHOW_VDT_HOME: {
    EVENT: 'btn_click',
    TAGS: {
      product: 'ver datos',
      action: 'click',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step vista home'
    }
  },
  GO_TO_MOVEMENTS: {
    EVENT: 'btn_click',
    TAGS: {
      product: 'ver detalle',
      action: 'click',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step vista home',
    }
  },
  MOVEMENTS_PAGE: {
    EVENT: 'virtual_page',
    TAGS: {
      action: 'pageview',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step vista movimientos'
    }
  },
  SHOW_VDT_MOVEMENTS: {
    EVENT: 'btn_click',
    TAGS: {
      product: 'ver datos de tarjeta',
      action: 'click',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step vista movimientos'
    }
  },
  MODAL_ONLINE_PURCHARSE_OFF: {
    EVENT: 'virtual_page',
    TAGS: {
      action: 'pageview',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step opcion compras por internet'
    }
  },
  GO_TO_SETTINGS: {
    EVENT: 'btn_click',
    TAGS: {
      product: 'ir a ajustes',
      action: 'click',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step opcion compras por internet'
    }
  },
  MODAL_OPENED: {
    EVENT: 'virtual_page',
    TAGS: {
      action: 'pageview',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step seleccion metodo autorizacion',
    }
  },
  MODAL_CLOSED: {
    EVENT: 'btn_click',
    TAGS: {
      product: 'cerrar',
      action: 'click',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step seleccion metodo autorizacion'
    }
  },
  RPASS_SELECTED: {
    EVENT: 'btn_click',
    TAGS: {
      product: 'rpass',
      action: 'click',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step seleccion metodo autorizacion'
    }
  },
  SMS_SELECTED: {
    EVENT: 'btn_click',
    TAGS: {
      product: 'codigo sms',
      action: 'click',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step seleccion metodo autorizacion'
    }
  },
  VDT_SUCCESSFUL: {
    EVENT: 'end_flow',
    TAGS: {
      action: 'pageview',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step mostrando datos',
      step_part: 'rpass'
    }
  },
  VDT_CLOSED_SUCCESSFULLY: {
    EVENT: 'btn_click',
    TAGS: {
      product: 'cerrar',
      action: 'click',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step mostrando datos'
    }
  },
  VDT_CLOSED_PURCHARSE_ONLINE_OFF: {
    EVENT: 'btn_click',
    TAGS: {
      product: 'cerrar',
      action: 'click',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step opcion compras por internet'
    }
  },
  VDT_BLACK_LIST: {
    EVENT: 'virtual_page',
    TAGS: {
      action: 'pageview',
      category: 'visualizacion de tarjeta',
      sub_category: 'tarjeta ripley mastercard',
      step: 'step lista negra',
      step_part: 'rpass'
    }
  }
};
