import { AuthGuard } from '@guards/auth/auth.guard';
import {
  ENROLLMENT_ROUTE,
  LOGIN_ROUTE,
  MAINTENANCE_ROUTE,
  PASSWORD_RESET_ROUTE,
  HOME_ROUTE,
  R_PASS_INTRO_ROUTE,
  R_PASS_AUTH_ROUTE,
  R_PASS_AUTH_FORM_ROUTE,
  R_PASS_QR_REDIRECT_APP,
  R_PASS_HELP_ROUTE_BASELESS,
  R_PASS_CHANGE_RPASS_ROUTE_BASELESS,
  EMERGENCY_ROUTE,
  RIPLEY_AGENCIES_ROUTE_BASELESS
  } from '@common/constants/routes.constants';
import { MaintenanceGuard } from '@guards/maintenance/maintenance.guard';
import { NgModule } from '@angular/core';
import { NoAuthGuard } from '@guards/no-auth/no-auth.guard';
import { NoMaintenanceGuard } from '@guards/no-maintenance/no-maintenance.guard';
import { RouterModule, Routes } from '@angular/router';
import { TamperedDeviceGuard } from '@guards/tampered-device/tampered-device.guard';
import { CompiledAppGuard } from '@guards/compiled-app/compiled-app.guard';
import { WebMobileGuard } from '@guards/web-mobile/web-mobile.guard';

const routes: Routes = [
  {
    path: ENROLLMENT_ROUTE,
    canLoad: [MaintenanceGuard, NoAuthGuard],
    loadChildren: './pages/enrollment/enrollment.module#EnrollmentModule'
  },
  {
    path: PASSWORD_RESET_ROUTE,
    canLoad: [MaintenanceGuard, NoAuthGuard],
    loadChildren: './pages/password-reset/password-reset.module#PasswordResetModule'
  },
  {
    path: LOGIN_ROUTE,
    canLoad: [MaintenanceGuard, NoAuthGuard, TamperedDeviceGuard],
    loadChildren: './pages/login/login.module#LoginModule'
  },
  {
    path: MAINTENANCE_ROUTE,
    canLoad: [NoMaintenanceGuard],
    loadChildren: './pages/maintenance/maintenance.module#MaintenancePageModule'
  },
  {
    path: HOME_ROUTE,
    canLoad: [MaintenanceGuard],
    canActivate: [AuthGuard],
    loadChildren: './pages/main/main.module#MainPageModule'
  },
  {
    path: R_PASS_INTRO_ROUTE,
    canActivate: [CompiledAppGuard],
    loadChildren: './pages/intro-rpass/intro-rpass.module#IntroRpassPageModule'
  },
  {
    path: R_PASS_AUTH_ROUTE,
    canActivate: [CompiledAppGuard],
    loadChildren: './pages/digital-auth-activation/digital-auth-activation.module#DigitalAuthActivationPageModule'
  },
  {
    path: R_PASS_AUTH_FORM_ROUTE,
    canActivate: [CompiledAppGuard],
    loadChildren: './pages/digital-auth-form/digital-auth-form.module#DigitalAuthFormPageModule'
  },
  { 
    path: R_PASS_QR_REDIRECT_APP,
    canActivate: [WebMobileGuard],
    loadChildren: './pages/rpass-qr-redirect/rpass-qr-redirect.module#RpassQrRedirectPageModule'
  },
  { 
    path: R_PASS_HELP_ROUTE_BASELESS,
    canActivate: [CompiledAppGuard],
    loadChildren: './pages/help-rpass/help-rpass.module#HelpRpassPageModule'
  },
  {
    path: R_PASS_CHANGE_RPASS_ROUTE_BASELESS,
    canActivate: [CompiledAppGuard],
    loadChildren: './pages/change-rpass-password/change-rpass-password.module#ChangeRpassPasswordPageModule'
  },
  {
    path: EMERGENCY_ROUTE,
    canLoad: [MaintenanceGuard],
    loadChildren: './pages/emergency/emergency.module#EmergencyModule'
  },
  {
    path:`${RIPLEY_AGENCIES_ROUTE_BASELESS}/mapa`,
    loadChildren: './pages/branch-office/branch-office-map/branch-office-map.module#BranchOfficeMapPageModule',
  },
  {
    path: RIPLEY_AGENCIES_ROUTE_BASELESS,
    loadChildren: './pages/branch-office/branch-office.module#BranchOfficePageModule'
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
