export const ENROLLED_USER = 0;

export const NOT_ENROLLED_USER = 1;

export const OTP_LENGTH = 6;

export const SEND_OTP_SUCCESSFUL = 1;

export const OTP_MAX_TIME = 60;

export const OTP_VALIDATION_SUCCESSFUL = 1;

export const OTP_VALIDATION_ERROR = 711;

export const USER_CREATION_SUCCESSFUL = 1;

export const PASSWORDS_DONT_MATCH = 600;

export const SESSION_EXPIRED = 620;

export const SMS_ENROLLMENT_TYPE = 0;

export const SMS_CHANGE_PASSWORD_TYPE = 2;

export const LAST_DIGITS_LENGTH = 3;

export const ENROLLMENT_ERROR_MESSAGES = {
  650: 'Ha ocurrido un error interno',
  607: 'No se encontró el teclado generado',
  600: 'Las claves ingresadas no son iguales',
  604: 'Su clave ingresada debe ser más segura',
  728: 'Cliente actualmente enrolado en la plataforma'
};

export const USER_WITHOUT_PRODUCTS = 726;

export const USER_ALREADY_ENROLLED = 728;

export const USER_ALREADY_ENROLLED_BLOCK = 737;

export const FIRST_ENROLLMENT_STEP_DESCRIPTION = 'Registra tus datos y confirma tu identidad';

export const SECOND_ENROLLMENT_STEP_DESCRIPTION = 'Crea tu clave de 6 dígitos';

export const MOBILE_STEPS_WIDTH = 568;

export const ENROLLMENT_EXIT_MESSAGE = '¿Estás seguro que deseas salir del registro?';

export const PASSWORD_CHANGE_EXIT_MESSAGE = '¿Estás seguro que deseas salir del olvido de clave?';



//PIN + PAN 
export const PAN_ENROLL_MAX_LENGTH = 16;

export const PIN_VALIDATION_SUCCESSFUL = 1;

export const PIN_VALIDATION_ERROR = 711;

//