import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import {
  AuthWidgetV3Messages,
  AuthWidgetV3Status,
} from "@common/constants/digital-auth-form.constants";
import { TRANSACTION_INFO_INCREASE } from "@common/constants/digital-card.constants";
import { R_PASS_INTRO_ROUTE } from "@common/constants/routes.constants";
import {
  AuthorizationStatus,
  IDetail,
} from "@common/interfaces/digital-auth.interface";
import { IValidityResponse } from "@common/interfaces/widget.interface";
import { environment } from "@environments/environment";
import { ModalController, Platform } from "@ionic/angular";
import { AuthService } from "@services/auth/auth.service";
import { SyneriseServiceService } from "@services/synerise/synerise-service.service";
import { UserService } from "@services/user/user.service";
import { LOTTIE_CONFIG, UtilsService } from "@services/utils/utils";
import { Observable, Subscription } from "rxjs";
import { WidgetServiceService } from "@services/widget-service/widget-service.service";
import {
  ModalWidgetService,
  eWidgetModalState,
} from "@services/modal-widget/modal-widget.service";
import { filter } from "rxjs/operators";
import { CardStoreService } from "@services/card-store/card-store.service";

interface ITitle {
  desktop: string;
  mobile: string;
}

@Component({
  selector: "app-generic-modal-widget",
  templateUrl: "./generic-modal-widget.component.html",
  styleUrls: ["./generic-modal-widget.component.scss"],
})
export class GenericModalWidgetComponent implements OnInit, OnDestroy {
  @Input() title: ITitle;
  @Input() message: string;
  @Input() currentWidget: string;
  @Input() name: string;
  @Input() transaction: string;
  @Input() increase: string;
  @Input() token: string;
  @Input() beforeLine: string;
  @Input() isNative: boolean;

  transactionInfo = TRANSACTION_INFO_INCREASE;
  clientId: string = environment.WIDGET_CLIENT_ID;
  isFetch: boolean = false;

  sendIncreaseResult: any = null;
  stateWidget: Boolean = false;

  creditCardSubject: any = null;

  // Lotie Loader
  public lottieConfig: object = LOTTIE_CONFIG;
  public lottieHeight: number = LOTTIE_CONFIG.sizes.big.height;
  public lottieWidth: number = LOTTIE_CONFIG.sizes.big.width;

  constructor(
    private viewController: ModalController,
    private utilsService: UtilsService,
    private userService: UserService,
    private platform: Platform,
    private authService: AuthService,
    private router: Router,
    private syneriseService: SyneriseServiceService,
    private widgetService: WidgetServiceService,
    private modalWidgetService: ModalWidgetService,
    private cardStoreService: CardStoreService
  ) {
  }

  async ngOnInit() {
    this.creditCardSubject = await this.cardStoreService.getValue(
      this.cardStoreService.getCardProduct
    );
    this.modalWidgetService
      .$statusWidget()
      .subscribe((state: eWidgetModalState) => {
        if (state == eWidgetModalState.show) this.showModal();
        else if (state == eWidgetModalState.notshow) this.hideModal();
      });
      
  }

  public error() {
    this.stateWidget = true;
  }

  ngOnDestroy(): void {
    this.close();
    this.modalWidgetService.saveStatusWidget(eWidgetModalState.show);
  }

  public getTransactionData(): IDetail[] {
    return [
      {
        key: "S.Nombre",
        value:
          this.userService.userInformation.names +
          " " +
          this.userService.userInformation.lastName,
      },
      { key: "S.Transacción", value: this.transaction },
      {
        key: "S.Monto de línea a incrementar",
        value: "S/" + this.numberWithPoints(this.increase),
      },
      {
        key: "S.Monto de línea anterior",
        value: "S/" + this.numberWithPoints(this.beforeLine),
      },
      { key: "flow", value: "ILC" },
      { key: "D.Fecha", value: new Date().toISOString() },
      { key: "userId", value: this.userService.userInformation.documentNumber },
      {
        key: "deviceType",
        value: this.platform.is("cordova") ? "mobile" : "desktop",
      },
    ];
  }

  async verifyShowCard({ data }: IValidityResponse) {
    
    if (data.status === AuthWidgetV3Status.success) {
      if (data.message === AuthWidgetV3Messages.initialized) {
        this.currentWidget = "widget";
        const buttonRpass = document.getElementsByClassName('MuiPaper-root');
        buttonRpass[0].addEventListener('click', () => {
          this.stateWidget = false;
        })
      } else if (data.message === AuthWidgetV3Messages.authorized) {
        this.currentWidget = "authorized";
        const uuid = data.details.uuid;

        try {
          const extraData = {
            channel: this.isNative ? "APP" : "HBK",
            credit: this.creditCardSubject && Object.keys(this.creditCardSubject).length > 0
              ? this.creditCardSubject.card.substr(
                  this.creditCardSubject.card.length - 4
                )
              : "0000",
            date: new Date().toISOString(),
          };
          this.isFetch = true;
          this.sendIncreaseResult = await this.widgetService.sendIncrease({
            userName: `${this.userService.userInformation.names} ${this.userService.userInformation.lastName} ${this.userService.userInformation.secondLastName}`,
            documentNumber: `${this.userService.userInformation.documentNumber}`,
            transaction: this.transaction,
            amount: this.increase,
            isWidget: 1,
            structureWidget: uuid,
            extraData: JSON.stringify(extraData),
          });
          this.sendIncreaseResult.details = this.insert(
            this.sendIncreaseResult.details,
            1,
            {
              label: "Nueva línea",
              value: `S/${this.numberWithPoints(this.increase)}`,
            },
            {
              label: "Línea anterior",
              value: `S/${this.numberWithPoints(this.beforeLine)}`,
            }
          );
          this.sendIncreaseResult.details.push({
            label: "Hora",
            value: `${this.formatAMPM(new Date())}`,
          });

          if (this.sendIncreaseResult.sms == "OK") {
            this.currentWidget = "credit_line_approved";
            this.isFetch = false;
            this.stateWidget = true;
            this.syneriseService.sendFormUpdate({
              line: "0",
            });
            
          } else {
            
            this.currentWidget = "credit_line_rejected";
            this.isFetch = false;
            this.stateWidget = true;
            this.syneriseService.trackEvent('error.ilc', {
              code: this.sendIncreaseResult.Code,
              messageServer: this.sendIncreaseResult.message,
            })
          }
        } catch (e) {
          this.currentWidget = "failed";
          this.isFetch = false;
          this.stateWidget = true;
          let message = '';
          if (typeof e === "string") {
              message = e.toUpperCase() 
          } else if (e instanceof Error) {
              message = e.message 
          }
          this.syneriseService.trackEvent('error.ilc', {
            code: 500,
            messageServer: message,
          })
        }
       
      } else if (data.message === AuthWidgetV3Messages.activate_rpass) {
        this.router.navigateByUrl(R_PASS_INTRO_ROUTE);
        this.close();
      }
    } else if (data.status === AuthWidgetV3Status.failure) {
      if (data.message === AuthWidgetV3Messages.canceled) {
        this.currentWidget = "canceled";
        this.stateWidget = true;
        this.close();
      } else if (data.message === AuthWidgetV3Messages.rejected) {
        this.currentWidget = "rejected";
        this.stateWidget = true;
      }
    } else {
      this.currentWidget = "failed";
      this.stateWidget = true;
    }
  }

  showModal() {
    if (this.isNative) {
      const genericModalWidget = document.getElementById(
        "generic-modal-widget"
      );
      genericModalWidget.classList.remove("widget-modal-hide");
    }
  }

  hideModal() {
    if (this.isNative) {
      const genericModalWidget = document.getElementById(
        "generic-modal-widget"
      );
      genericModalWidget.classList.add("widget-modal-hide");
    }
  }

  public async close() {
    this.currentWidget = 'loading';
    await this.viewController.dismiss(undefined, undefined, 'generic-modal-widget');
  }

  public insert(arr, index, ...newItems) {
    return [...arr.slice(0, index), ...newItems, ...arr.slice(index)];
  }

  public numberWithPoints(val: string) {
    return val.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  public formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  async closeModal(): Promise<void> {
    if (this.viewController) {
      try {
        await this.viewController.dismiss();
      } catch (err) {}
    }
  }
}
