import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpService } from '@services/http/http.service';
import {
  CHECK_ENROLLMENT_MOCK,
  ENROLLMENT_TERMS_AND_CONDITIONS,
  ENROLLMENT_DATA_PROTECTION,
  ENROLL_USER_MOCK
} from '@common/mocks/enrollment.mocks';
import { IAccountCreate, IPinPanAccount, IBiometriaAccount, IBiometricOut, IFailureMonitor } from '@common/interfaces/account.interface';
import { IUserIdentification } from '@common/interfaces/user.interface';
import { IGenericNameValueResponse } from '@common/interfaces/default.interface';
import { HttpHeaders } from '@angular/common/http';
import { UtilsService } from '@services/utils/utils';
@Injectable({
  providedIn: 'root'
})
export class EnrollmentService {

  constructor(
    private http: HttpService,
    private utilsService: UtilsService,
    ) { }

  public async checkUserEnrollment(accountDocument: IUserIdentification, identifier: number): Promise<any> {
    const headers = new HttpHeaders().set('Identifier', identifier.toString());
    const url = environment.BASE_BACKEND_WORKER + '/enrollment/accountuserhb/validate';
    return this.http.post(url, accountDocument, CHECK_ENROLLMENT_MOCK, headers).toPromise();
  }

  public enrollUser(accountInformation: IAccountCreate, identifier: number): Promise<any> {
    let headers = new HttpHeaders().set('Identifier', identifier.toString());
    headers = headers.set('Channel', this.utilsService.getChannelCode());
    const url = environment.BASE_BACKEND_WORKER + '/enrollment/accountuserhb/contactdetail/newuser/createperson';
    return this.http.post(url, accountInformation, ENROLL_USER_MOCK, headers).toPromise();
  }

  public getEnrollmentTermsAndConditions(): Promise<IGenericNameValueResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/DTYC';
    return this.http.get(url, ENROLLMENT_TERMS_AND_CONDITIONS).toPromise();
  }

  public getEnrollmentDataProtection(): Promise<IGenericNameValueResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/DPDP';
    return this.http.get(url, ENROLLMENT_DATA_PROTECTION).toPromise();
  }

  public async checkUserEnrollmentPinPan(accountDocument: IPinPanAccount,identifier: number ): Promise<any> {
    let headers = {} as HttpHeaders;
    headers = new HttpHeaders().set('Identifier', identifier.toString());
    const url = environment.BASE_BACKEND_WORKER + '/enrollment/accountuserhb/ValidatePinV2';
    return this.http.post(url, accountDocument, CHECK_ENROLLMENT_MOCK, headers).toPromise();
  }
  
  public async checkUserBiometriaEnroll(biometriaAccount: IBiometriaAccount): Promise<IBiometricOut>{
    let headers = {} as HttpHeaders;
    const url = environment.BASE_BACKEND_WORKER + '/enrollment/biometrics/token';
    return this.http.post(url, biometriaAccount, CHECK_ENROLLMENT_MOCK, headers).toPromise();
  }
  public async biometriaEnrollFailureMonitor(failure: IFailureMonitor): Promise<any>{
    let headers = {} as HttpHeaders;
    headers = new HttpHeaders().set('Channel', this.utilsService.getChannelCode());
    const url = environment.BASE_BACKEND_WORKER + '/enrollment/biometrics/failure';
    return this.http.post(url, failure, CHECK_ENROLLMENT_MOCK, headers).toPromise();
  }

}
