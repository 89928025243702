import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-generic-button-control',
  templateUrl: './generic-button-control.component.html',
  styleUrls: ['./generic-button-control.component.scss']
})
export class GenericButtonControlComponent implements OnInit {
  @Input() public type: string;
  @Input() public primary: boolean;
  @Input() public disabled: boolean;
  @Input() public text: string;
  @Input() public backgroundColor: string;
  @Input() public textColor: string;
  @Input() public width: string;
  @Input() public border: string;
  @Input() public path: string;
  @Input() public isSpinner: boolean = false;
  @Output() goTo: EventEmitter<string> = new EventEmitter();
  @Output() openModal: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onClick(): void {
    if (this.type === 'route') {
      this.goTo.emit(this.path);
    }
    else if (this.type === 'modal') {
      this.openModal.emit();
    }
  }

}