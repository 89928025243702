import {
  ILogOpenAccount, ILogsParameters,
  IMonitorInfo,
  IResponseDataClient
} from '@common/interfaces/open-account.interface';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ICardImage } from '@common/interfaces/card.interface';
import { AHORRO_ROUTE } from '@common/constants/routes.constants';
import { SCREEN_WIDTH } from '@common/constants/misc.constants';
import { NavController } from '@ionic/angular';
import {
  ACCOUNT_PLUS_CODE,
  ACCOUNT_PLUS_WORKER_CODE,
  ACCOUNT_VIP_CODE,
  REASON_LOGS,
  RIPLEY_BANK_SALARY_ACCOUNT_CODE,
  RIPLEY_STORE_SALARY_ACCOUNT_CODE,
  STEP_LOGS,
  STORAGE_LOG_OA
} from '@common/constants/open-account.constants';
import {
  OP_OPEN_SAVING_ACCOUNT_PLUS, OP_OPEN_SAVING_ACCOUNT_SALARY,
  OP_OPEN_SAVING_ACCOUNT_VIP
} from '@common/constants/tag/tag-open-saving-account.constants';
import { OpenAccountService } from '@services/open-account/open-account.service';
import {Storage} from "@ionic/storage";

@Component({
  selector: 'app-card-image',
  templateUrl: './card-image.component.html',
  styleUrls: ['./card-image.component.scss'],
})
export class CardImageComponent {

  @Input() isDataValid: boolean;
  @Input() cardInfo: ICardImage;
  @Input() responseDataClient: IResponseDataClient;

  constructor(
    private router: Router,
    private ctr: NavController,
    private storage: Storage,
    private openAccountService: OpenAccountService) {
  }
  get isMobile() { return window.innerWidth < SCREEN_WIDTH.TABLET; }
  async goToRoute(route: string) {
    const stateArgs = {
      isDataValid: this.isDataValid,
      accountCode: this.cardInfo.accountCode,
      sessionId: this.cardInfo.sessionId,
      responseDataClient: this.responseDataClient,
    };

    switch (stateArgs.accountCode) {
      case ACCOUNT_PLUS_WORKER_CODE:
        break;
      case ACCOUNT_PLUS_CODE:
        break;
      case ACCOUNT_VIP_CODE:
        break;
      case RIPLEY_STORE_SALARY_ACCOUNT_CODE:
        break;
      case RIPLEY_BANK_SALARY_ACCOUNT_CODE:
        break;
    }

    const requestLog: ILogsParameters = {
      reasonIdentification: REASON_LOGS.PRE_REGISTER,
      stepIdentification: STEP_LOGS.SELECCION_CUENTA,
      typeAccount: stateArgs.accountCode
    };

    if (!route.includes('benefits')) {
      await this.storage.set(STORAGE_LOG_OA.CODE_OPEN_ACCOUNT, stateArgs.accountCode).then(()=>{
        this.storage.set(STORAGE_LOG_OA.STEP_OPEN_ACCOUNT, requestLog.stepIdentification).then(()=>{
          this.openAccountService.logOpenAccount(requestLog).then(()=>{
            this.storage.set(STORAGE_LOG_OA.STEP_OPEN_ACCOUNT, STEP_LOGS.TERMINOS_CONDICIONES)
          });
        })
      })
    }
    return this.router.navigate([`${AHORRO_ROUTE}/${route}`],
                                { state: stateArgs });
  }
}
