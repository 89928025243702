export const REDEEM_ON_OFF_DOCUMENT = 'redeemButtonOnOff';
export const RIPLEY_POINTS_ON_OFF_DOCUMENT = 'ripleyPointsDetailButtonOnOff';
export const SEF_EFEX_ON_OFF_DOCUMENT = 'sefEfexButtonOnOff';
export const SEF_EFEX_ON_OFF_DOCUMENT_V2 = 'sefEfexButtonOnOffV2';
export const NOTIFICATION_TOKENS_DOCUMENT = 'notificationTokens';
export const RPASS_CONFIG_DOCUMENT = 'rpass';

export const CONFIG_COLLECTION = 'config';

export const MAINTENANCE_MODAL_DEFAULT_VALUE = {
    active: false,
    blockDurationSeconds: 10,
    errorCountLimit: 10,
    platformDesktop: false,
    platformMobile: false,
    subtitle: '',
    mode: 'on-error',
    forbiddenDigits: ""
};

export const DATA_SWITCH_CAMPAIN_DEFAULT_VALUE = {
    android: false,
    ios: false,
    web: false,
}

export const FOOTER_BUTTONS_DEFAULT_VALUE = [
    {
        "footer-version": {
            version: 660,
            visible: true
        },
        icon: "i-branch-office",
        navigateTo: "ripley-agencies",
        navigateType: "I",
        platform: [
            "ios",
            "nfc",
            "android",
            "web"
        ],
        priority: 1,
        "priority-android": 1,
        "priority-android-sinNFC": 1,
        "priority-web": 1,
        show: false,
        title: "Agencias"
    },
    {
        "footer-version": {
            version: 660,
            visible: true
        },
        icon: "i-pin-card-rpass",
        navigateTo: "intro-rpass",
        navigateType: "I",
        platform: [
            "android",
            "ios",
            "nfc"
        ],
        priority: 2,
        "priority-android": 2,
        "priority-android-sinNFC": 2,
        "priority-web": 2,
        show: false,
        title: "R Pass"
    },
    {
        "footer-version": {
            version: 660,
            visible: true
        },
        icon: "i-whatsapp",
        navigateTo: "https://api.whatsapp.com/send?phone=5116115757",
        navigateType: "E",
        platform: [
            "ios",
            "nfc",
            "android",
            "web"
        ],
        priority: 3,
        "priority-android": 3,
        "priority-android-sinNFC": 3,
        "priority-web": 3,
        show: false,
        title: "Contáctanos"
    },
    {
        "footer-version": {
            version: 660,
            visible: true
        },
        icon: "i-emergency",
        navigateTo: "emergency",
        navigateType: "I",
        platform: [
            "ios",
            "nfc",
            "android",
            "web"
        ],
        priority: 4,
        "priority-android": 4,
        "priority-android-sinNFC": 4,
        "priority-web": 4,
        show: false,
        title: "Emergencias"
    }
];