import { Component, Input, EventEmitter, Output, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { COMPANY_NAME, CREDIT_CARD, RIPLEY_MASTERCARD_EXTENDED_NAME } from '@common/constants/welcomepack.constants';
import { UtilsService } from '@services/utils/utils';
import { CardStoreService } from '@services/card-store/card-store.service';

@Component({
  selector: 'page-cards-welcomepack',
  templateUrl: 'cards-welcomepack.html',
  styleUrls: ['./cards-welcomepack.scss'],
})
export class CardsWelcomepackPage implements OnInit, AfterViewInit {
  public static readonly pageName = 'CardsWelcomepackPage';
  @Input() public user: any;
  @Input() public finalScreen: boolean;
  @Input() public screen: any;
  @Output() public exit: EventEmitter<any>;
  @Output() public goTo: EventEmitter<any>;
  public campaign: any;
  public nextButtonText: string;
  public loadingWave = true;
  public loadingCard = true;
  public loadingIcon = true;
  public cardType: string;
  @ViewChild('wave') public wave: ElementRef;
  @ViewChild('cardImage') public cardImage: ElementRef;

  constructor(
    public utils: UtilsService,
    private storeService: CardStoreService,
  ) {
    this.exit = new EventEmitter();
    this.goTo = new EventEmitter();
  }

  get showFixedButton() {
    return !!this.screen.button.show && !!this.screen.button.fixed;
  }

  public ngOnInit() {
    this.cardType = this.getCardClass();
    this.filterItems();
  }

  public ngAfterViewInit() {
    this.preLoadImage(this.wave);
    this.preLoadImage(this.cardImage);
  }

  private preLoadImage(image: ElementRef) {
    const src = window.getComputedStyle(image.nativeElement, null)['background-image'];
    const url = src.match(/\((.*?)\)/)[1].replace(/('|")/g, '');

    const img = new Image();
    img.onload = function() {
      image === this.wave ? this.loadingWave = false : this.loadingCard = false;
    }.bind(this);

    img.src = url;
    if (img.complete) {
      img.onload(null);
    }
  }

  public getCardsMessage() {
    const name = !!this.user.name ? UtilsService.capitalizeWords(this.user.name.split(' ')[0]) + ', ' : '';
    const message = this.screen.secondaryTitle.singular;
    let cards = ' <b>';
    if (this.user.newAccounts.length > 0) {
      cards += ((this.user.newAccounts[0].type === CREDIT_CARD) &&
        UtilsService.isClosedMastercard(this.user.newAccounts[0].subProductCode))
        ? COMPANY_NAME : RIPLEY_MASTERCARD_EXTENDED_NAME;
    }
    cards += '!</b>';
    return name + message + cards;
  }

  public getCardClass() {
    if (UtilsService.isClosedMastercard(this.user.newAccounts[0].subProductCode)) {
      return 'credit-ms img-closed-mastercard';  // Tarjeta Ripley
    } else {
      return 'credit-ms img-open-mastercard';  // Tarjeta Ripley Mastercard
    }
  }

  public getPan(card) {
    // tslint:disable-next-line: no-magic-numbers
    return '**** **** **** ' + card.pan.substr(-4);
  }

  public filterItems() {
    this.screen.items = this.screen.items
      .filter((items) =>
        (items.forTRM && !!this.user.newAccounts.find((product) => !UtilsService.isClosedMastercard(product.subProductCode))) ||
        (items.forTR && !!this.user.newAccounts.find((product) => UtilsService.isClosedMastercard(product.subProductCode))))
      .sort((product, b) => product.order - b.order);
  }

  public triggerExit() {
    this.storeService.setCloseState(true);
    this.exit.emit();
  }

  public triggerGoTo(page, params) {
    this.goTo.emit({ page, params });
  }
}
