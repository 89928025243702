export const DEFAULT_PROMOTION_IMAGE = '../assets/icon/rate-sad.svg';

export const OPEN_MASTERCARD = 'OM';

export const CLOSED_MASTERCARD = 'CM';

export const RIPLEY_MASTERCARD_EXTENDED_NAME = 'ripley mastercard';

export const SAVING_ACCOUNT = 'SA';

export const CREDIT_CARD = 'TC';

export const CTS_ACCOUNT = 'CTS';

export const SIMPLE_ACCOUNT = 'SIMPLE';

export const PLUS_ACCOUNT = 'PLUS';

export const COMPANY_NAME = 'ripley';
