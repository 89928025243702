import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IMovementSavingAccount } from '@common/interfaces/savings.interface';
import { DatePipe } from '@angular/common';
import { IQuotaPendingResponse, IValidateNiubizResponse } from '@common/interfaces/loan.interface';
import { environment } from '@environments/environment';
import { DeviceService } from '@services/device/device.service';
import { AlertService } from '@services/alert/alert.service';
import { Router } from '@angular/router';
import { PaymentModalComponent } from '@components/payment-modal/payment-modal.component';
import { PAYMENTS_VOUCHER } from '@common/constants/voucher.constants';
import { IVoucherPage } from '@common/interfaces/voucher.interface';
import { PAYMENTS_ROUTE } from '@common/constants/routes.constants';
import { PaymentService } from '@services/payment/payment.service';

@Component({
  selector: 'app-credit-info-modal',
  templateUrl: './credit-info-modal.component.html',
  styleUrls: ['./credit-info-modal.component.scss'],
})
export class CreditInfoModalComponent {


  mensaje = 'Tú crédito está en mora por más de 90 días';
  @Input() title: string;
  @Input() quotaDetail: IQuotaPendingResponse;
  @Input() dateLoanStart: string;
  @Input() totalLoanAmount: number;
  @Input() account: string;
  @Input() paiddueamount: number;
  @Input() paymentDueDate: string;
  @Input() capitalAmount: number;
  @Input() loanNumber: string;
  @Input() paymentTime: string;
  @Input() totalQuotesAmount: string;
  @Input() currencySymbol: string;
  @Input() quotaPending: number;
  @Input() loanDisbursementDate: string;
  @Input() tcea: number;
  @Input() primaryButtonText: string;
  @Input() secondaryButtonText: string;
  @Input() sesionID: string;
  @Input() email: string;
  @Input() description: string;
  @Input() loanType: string;
  @Input() status: string;


  quotesPending: number;
  loadingPayment = false;
  isVisibleWarning: boolean;
  isDateExpired: boolean;
  showQuotes: boolean;
  daysWarning: number;
  url: string;
  peruvianPipe: any;
  voucher: IVoucherPage;

  constructor(
    private modalController: ModalController,
    private paymentService: PaymentService,
    private deviceService: DeviceService,
    private alertService: AlertService,
    private router: Router,
    private modalCtrl: ModalController,
  ) {

    this.url = this.router.url;
  }


  async ionViewWillEnter() {
    // this.loadingPayment = false;
    this.voucher = PAYMENTS_VOUCHER;
    this.quotesPending = this.getPendingCuote();
    // await this.userService.getUserInformation();
    // this.userInformation = this.userService.userInformation;
    this.getDaysExpiration();
    this.setLoanWarningInformation();
    this.initPaymentSources();
  }

  ionViewWillLeave() {
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  onPrimaryClick() {
    this.initPaymentSources();
    this.loadingPayment = true;
    this.router.navigateByUrl('LOAN');
    this.dismissModal();
    this.router.navigate([`${PAYMENTS_ROUTE}`]);
    // this.showNiubizModal();
    // this.modalController.dismiss('primaryButtonPressed');
  }

  getDaysExpiration() {
    const date: any[] = this.paymentDueDate.split('/');
    const dateDue = new Date(date[2], date[1] - 1, date[0]);
    const difDates = dateDue.getTime() - new Date(Date.now()).getTime();
    const difDays = difDates / (1000 * 3600 * 24);
    this.isDateExpired = difDays > 0 ? false : true;

  }

  setLoanWarningInformation() {
    const date: any[] = this.paymentDueDate.split('/');
    const dateDue = new Date(date[2], date[1] - 1, date[0]);
    const difDates = dateDue.getTime() - new Date(Date.now()).getTime();
    this.daysWarning = difDates / (1000 * 60 * 60 * 24);
    return (this.daysWarning <= -90) ? true : false;
  }


  textToClipboard(data: IMovementSavingAccount) {
    const datePipe = new DatePipe('en-US');
    return this.account + '\n'
      + data.description + '\n' + data.amount + '\n' + datePipe.transform(data.operationDate, 'dd/MM/yyyy');
  }

  public initPaymentSources() {
    const paymentStyleElement: HTMLLinkElement = document.createElement('link');
    paymentStyleElement.rel = 'stylesheet';
    paymentStyleElement.type = 'text/css';
    paymentStyleElement.href = environment.PAYMENTS_CONFIG.ENDPOINT_CSS;

    const paymentScriptElement: HTMLScriptElement = document.createElement('script');
    paymentScriptElement.src = environment.PAYMENTS_CONFIG.ENDPOINT_JS;
    paymentScriptElement.defer = true;

    document.head.append(paymentStyleElement, paymentScriptElement);
  }

  public async getClientIP() {
    return (await this.deviceService.getIP()).ip;
  }


  public async showNiubizModal() {
    try {
      const validationResult = await this.paymentService.validateLoanNiubizProcess({
        clientIp: await this.getClientIP(),
        sessionId: this.quotaDetail.sessionId,
        email: this.email,
        isTermsConditionAccepted: Number(true)
      });

      const modal = await this.getModal(validationResult);
      await modal.present();
      const { data: { renovate } } = await modal.onDidDismiss();
      if(renovate){
         await this.showNiubizModal()
      }
    } catch (err) {
      const alert = await this.alertService.openErrorAlert(
        this.url,
        false,
        true,
        null,
        err.code ? err.message : 'Ocurrió un error al obtener validación de pago. Intente en unos minutos'
      );
      alert.present();
      await alert.onDidDismiss();
    }finally{
      this.loadingPayment = false;
    }
  }

  getPendingCuote() {

    this.showQuotes = (this.quotaDetail.quotaAmount !== 0 && this.status === 'CURRENT' && (this.quotaPending + 1) === 1) ? false : true;

    // Si las cuotas vencidas exceden los 90 dìas
    this.isVisibleWarning = (this.quotaPending > 3) ? true : false;
    // this.quotaDetail.quotaAmount===0?0:(this.status==='CURRENT'?this.quotaPending+1:this.quotaPending);
    return this.quotaDetail.quotaAmount === 0 ? 0 : (this.status === 'CURRENT' ? this.quotaPending + 1 : this.quotaPending);
  }

  private getModal(validationResult: IValidateNiubizResponse) {

    this.modalController.dismiss();
    // primaryButtonText: `Pagar`,
    return this.modalCtrl.create({
      component: PaymentModalComponent,
      cssClass: 'transfers-schedule-modal',
      backdropDismiss: false,
      componentProps: {
        title: '<strong>Confirma tu pago</strong>',
        message:
          'Puedes realizar el pago con tu <strong>tarjeta de débito de cualquier banco</strong>.<br>' +
          '<br>Recuerda tener <strong>activa la opción de “Compras por internet”</strong> de la tarjeta con la que harás el pago.<br>',
        secondaryButtonText: 'Cancelar',
        primaryButtonText: 'Pagar ' + this.quotaDetail.totalAmount,
        validationResult: {
          ...validationResult,
          email: validationResult.email,
        },
        productName: 'Prestamos', // this.description,
        productType: 'PRE', // this.loanType,
        email: 'marily.echegaray@gestionysistemas.com', // this.email,
        amount: this.quotaDetail.totalAmount,
        icon: 'i-confirm-modal',
        userInformation: '',
        isPres: true,
        dues: this.quotaDetail.numberOfDuesPending,
        from: 'credits'
      },
    });
  }

}
