import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ITransferOTPRequest,
  ITransferRequest,
  ITransferResendOTPRequest,
  ITransferResponse,
  ITransferOTPResponse
} from '@common/interfaces/transfers.interface';
import { IUserInformation } from '@common/interfaces/user.interface';
import { IVoucherPage } from '@common/interfaces/voucher.interface';
import { MILLISECONDS_TO_SECOND } from '@common/constants/misc.constants';
import { ModalController } from '@ionic/angular';
import {
  ACCOUNT_NUMBER_MAX_LENGTH,
  ACCOUNT_NUMBER_MIN_LENGTH,
  OTP_CODE_LENGTH,
  PEN_BASE_CURRENCY,
  PEN_SEGMENT,
  TRANSFER_BETWEEN_MY_ACCOUNTS,
  TRANSFER_GA4_CONSTANTS,
  TRANSFER_OTHER_BANKS,
  TRANSFER_PROGRESS_ADDITION,
  TRANSFER_THIRD_PARTIES,
  USD_BASE_CURRENCY
} from '@common/constants/transfers.constants';
import { OTP_MAX_TIME } from '@common/constants/enrollment.constants';
import { Router } from '@angular/router';
import { TRANSFER_ROUTE, VOUCHER_ROUTE } from '@common/constants/routes.constants';
import { TransferService } from '@services/transfer/transfer.service';
import { UserService } from '@services/user/user.service';
import { TransferErrorModalComponent } from '@components/transfer-error-modal/transfer-error-modal.component';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { EFEX_FAILED_MODAL_PROPS, TRANFER_CTS_FAILED_MODAL_PROPS, TRANSFER_ERROR_GENERAL, TRANSFER_ERROR_INSUFFICIENT_BALANCE, TRANSFER_ERROR_INSUFFICIENT_BALANCE_ITF } from '@common/constants/modal-props.constants';
import { UtilsService } from '@services/utils/utils';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { IDetailsSavingAccount } from '@common/interfaces/savings.interface';
import { IDataLoaded, ISegmentSelector } from '@common/interfaces/default.interface';
import { SavingsService } from '@services/savings/savings.service';

@Component({
  selector: 'app-set-account-type',
  templateUrl: './set-account-type.component.html',
  styleUrls: ['./set-account-type.component.scss'],
})
export class SetAccountTypeComponent implements OnInit {

  isCTSOriginAccount: boolean;
  selectedAmountAccount: number;
  phoneNumber: string;
  savingAccountsInformation: IDetailsSavingAccount[];
  userDataLoaded: IDataLoaded;
  userInformation: IUserInformation;
  accountForm: FormGroup;

  constructor(
    private modalController: ModalController,
    private modalErrorCtrl: ModalController,
    private transferService: TransferService,
    private savingsService: SavingsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private utilsService: UtilsService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    
      this.savingAccountsInformation = [];
      this.userDataLoaded = {
        isLoaded: false,
        hasErrors: false,
      };
 }

 public get percentageProgress(): number { return TRANSFER_PROGRESS_ADDITION; }
 public get peruvianCurrencySegment(): ISegmentSelector { return PEN_SEGMENT; }
 get baseCurrency() { return this.accountForm.controls.baseCurrency; }
 
  async ngOnInit() {   
    this.createContactForm();
  }

  ionViewWillEnter() {
    UtilsService.showRecaptchaBadge();
  }

  ionViewWillLeave() {
    UtilsService.hideRecaptchaBadge();
  }

  get myOriginAccounts(){return this.accountForm.controls.originAccount; }

  private createContactForm() {
    this.accountForm = this.formBuilder.group({
      baseCurrency: [this.peruvianCurrencySegment.value, Validators.required],
      originAccount: [null, [
        Validators.required,
        Validators.minLength(ACCOUNT_NUMBER_MIN_LENGTH),
        Validators.maxLength(ACCOUNT_NUMBER_MAX_LENGTH),
      ]]
    });
  }

  public AccountSelected(account: string) {
    if (account != null) {
      const origin = this.accountDetail(account);   
      this.isCTSOriginAccount = origin.benefits.includes("CTS");     
      this.selectedAmountAccount = origin.availableAmount;
      this.changeCurrency(this.getValueBaseCurrencyConst(origin.symbolCurrency));    
    }
  }
  private accountDetail(accountNumber: string): IDetailsSavingAccount {
    return this.savingAccountsInformation.find(savingAccount => savingAccount.account === accountNumber);
  }
  public changeCurrency(selectedBaseCurrency: string) {
    this.baseCurrency.setValue(selectedBaseCurrency);
  }
  public getValueBaseCurrencyConst(symbol: string): string {
    return (symbol === PEN_SEGMENT.symbol) ? PEN_BASE_CURRENCY : USD_BASE_CURRENCY;
  }


 /* async getDevicePhoneContacts(){   
      const projection = {
        // Specify which fields should be retrieved.
        name: true,
        phones: true,
        postalAddresses: false,
      };
    
      const result = await Contacts.getContacts({
        projection,
      });
      
      this.contacts = result.contacts;
   
  }*/
}
