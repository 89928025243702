import { Injectable } from '@angular/core';
import { HttpService } from '@services/http/http.service';
import { environment } from '@environments/environment';
import {
  EFEX_PROMOTIONALS_MOCK,
  EFEX_CUALIFICATIONS_MOCK,
  EFEX_TERMS_AND_CONDITIONS,
  EFEX_ACCOUNT_AUTHORIZATION
} from '@common/mocks/efex.mocks';
import {
  IEfexDetails,
  IPromotionals,
  IEfexRegister,
  IEfexCualificationListIN,
  IEfexCualificationListOut,
  IEfexCualificationSave,
  IResponseRequisition
} from '@common/interfaces/efex.interface';
import { IGenericNameValueResponse } from '@common/interfaces/default.interface';
import { FirebaseService } from '@services/firebase/firebase.service';
import { StorageService } from '@services/storage/storage.service';
import { USER_INFO } from '@common/constants/auth.constants';
import * as firebase from 'firebase/app';
import { GENERIC_ERROR_MESSAGE } from '@common/constants/misc.constants';

@Injectable({
  providedIn: 'root'
})

export class EfexService {

  constructor(
    private http: HttpService,
    private storage: StorageService,
    private firebaseService: FirebaseService,
  ) { }

  public async getPromotionalsDetails(efexDetails: IEfexDetails): Promise<IPromotionals[]> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/efex/promotionalsV2';
    const responsePromise = this.http.post(url, efexDetails, EFEX_PROMOTIONALS_MOCK).toPromise();
    const userInfo = await this.storage.get(USER_INFO);
    const dniSearchFormat =  userInfo.documentType + '-' + userInfo.documentNumber;
    return responsePromise.then(async (response) => {
      this.firebaseService.addSefEfexServiceResponse(
        {
          userId: dniSearchFormat,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          requestBody: efexDetails,
          ...response
        },
        'promotionalResponses');
      return response;
    });
  }

  public async RegisterRequisition(efexRegisterParams: IEfexRegister): Promise<IResponseRequisition> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/efex/register-requisitionV2';
    const responsePromise = this.http.post(url, efexRegisterParams, EFEX_PROMOTIONALS_MOCK).toPromise();
    const userInfo = await this.storage.get(USER_INFO);
    const dniSearchFormat =  userInfo.documentType + '-' + userInfo.documentNumber;
    return responsePromise.then(async (response) => {
      this.firebaseService.addSefEfexServiceResponse(
        {
          userId: dniSearchFormat,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          requestBody: efexRegisterParams,
          ...response
        },
        'requisitionResponses');
      return response;
    }).catch((error) => {
      this.firebaseService.addSefEfexServiceResponse(
        {
          failed: true,
          userId: dniSearchFormat,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          requestBody: efexRegisterParams,
          error: GENERIC_ERROR_MESSAGE //error.message
        },
        'requisitionResponses');
      throw error;
    });
  }

  public FeedbackList(efexCualificationParams: IEfexCualificationListIN): Promise<IEfexCualificationListOut> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/efex/qualification/list';
    return this.http.post(url, efexCualificationParams, EFEX_CUALIFICATIONS_MOCK).toPromise();
  }

  public FeedSave(efexCualificationSaveParams: IEfexCualificationSave): Promise<number> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/efex/qualification/save';
    return this.http.post(url, efexCualificationSaveParams, EFEX_CUALIFICATIONS_MOCK).toPromise();
  }


  public async getExtensionTermsAndConditions(): Promise<IGenericNameValueResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/TCPD';
    return this.http.get(url, EFEX_TERMS_AND_CONDITIONS).toPromise();
  }

  public async getExtensionAccountAuthorizationConditions(): Promise<IGenericNameValueResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/CSCA';
    return this.http.get(url, EFEX_ACCOUNT_AUTHORIZATION).toPromise();
  }

}
