import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forEach } from '@angular/router/src/utils/collection';
import { LOAN_FAILED_MODAL_PROPS } from '@common/constants/modal-props.constants';
import { pastValuesBlock, RESTRICTION_LIST, TEMPORARY_BLOCK } from '@common/constants/on-off-temporary-block';
import {
  IRequestPastValues,
  IRequestUpdateRestrictionList,
  IRequestUpdateTemporaryBlock,
  IResponseOnOffRestrictionDetail,
  IResponseUpdateTemporaryRestrictionBlock,
  IRestrictionList
} from '@common/interfaces/on-off-restriction';
import { CONSOLIDATED_AUTHENTICATED_PRODUCTS_MOCK } from '@common/mocks/product.mocks';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { ModalController } from '@ionic/angular';
import { AuthService } from '@services/auth/auth.service';
import { OnOffRestriction } from '@services/on-off-restriction/on-off-restriction.service';
import { OnOffModalTimerComponent } from '../modal-timer/on-off-modal-timer.component';
import { OnOffModalComponent } from '../modal/on-off-modal.component';
// import { ProductService } from '@services/product/product.service';

const ONE_THOUSAND = 1000;
const ONE_HUNDRED_AND_TWENTY = 120;
const SIXTY = 60;

@Component({
  selector: 'app-on-off-options',
  templateUrl: './on-off-options.component.html',
  styleUrls: ['./on-off-options.component.scss'],
})
export class OnOffOptionsComponent implements OnInit {
  @Input() temporaryBlock: IResponseOnOffRestrictionDetail;
  @Input() restrictionList: IResponseOnOffRestrictionDetail[];
  @Input() listRestrictionConst: any;
  @Input() temporaryBlockData: any;
  @Input() sessionId: string;
  @Input() cardNumber: string;
  @Input() isServiceList: boolean;
  @Input() dataIsLoaded: boolean;
  @Input() isError: boolean;
  @Output() switchEvent = new EventEmitter<{ isContinueDisable: boolean, isCancelDisable: boolean, isNotDataSaved: boolean }>();
  @Output() switchEventReload = new EventEmitter<{ isCardReload: boolean }>();

  public optionsForm: FormGroup;
  listRestrinctionAux: IRestrictionList[];
  temporaryBlockDataAux: IRestrictionList; 
  listAllOptionAux: IRestrictionList[];
  tittleCard:string = "Bloqueo temporal de tarjeta";
  tittleService: string = "On/Off de Servicios Adicionales";
  subtitleCard:string = '';
  subtitleService:string = "Usa los interruptores para cambiar el estado de los servicios adicionales de tu tarjeta y presiona el botón"
  timeString: string;   
  inactive_card:boolean;
  active: boolean;
  showBlockButton: boolean;
  isServicesBlocked: boolean;
  restrictionChanged: boolean;
  cardBlockChanged: boolean;
  startTime: Date;
  stopTime: Date;
  index = 0;
  timerCounter: number;
  timeLeft = 120;
  interval: any;
  statusCardLabel ="ESTADO_TARJETA";
  pastValues:IRequestPastValues;
  get display() { return (this.startTime && this.stopTime) ? +this.stopTime - +this.startTime : 0; }

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private onOffRestrictionService: OnOffRestriction,
    private authService: AuthService
    // private productService: ProductService
  ) { }

  async ngOnInit() {

    this.timerCounter = 0;
    this.showBlockButton = false;
    this.active = false;
    this.isServicesBlocked = false;
    this.restrictionChanged = false;
    this.cardBlockChanged = false;
    this.initListServicesAux();
    this.createOptionsForm();

  }

  timer() {
    if (this.active) {
      this.stopTime = new Date();
      setTimeout(() => {
        this.timer();
      }, ONE_THOUSAND);
    }
  }

  start() {
    this.startTime = new Date();
    this.stopTime = this.stopTime;
    this.active = true;
    this.timer();
  }

  stop() {
    this.stopTime = new Date();
    this.active = false;
  }

  startTimer() {
    this.interval = setInterval(() => {

      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.timeString = this.getTimeString();
        // console.log("TIME " + this.timeLeft);
      } else {
        this.timeLeft = ONE_HUNDRED_AND_TWENTY;
        this.showBlockButton = false;
        this.timerCounter = 0;
        // console.log("TIME " + this.timeLeft);
        this.pauseTimer();
      }

    }, ONE_THOUSAND);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  getTimeString(): string {
    /* console.log('MIN ',Math.trunc(this.timeLeft/60));
     console.log('SEC ',(this.timeLeft % 60));*/
    const segundos = (this.timeLeft % SIXTY).toString();
    const minutos = Math.trunc(this.timeLeft / SIXTY).toString();
    const segundosCadena = segundos.length > 1 ? segundos : ('0' + segundos);
    const minutosCadena = minutos.length > 1 ? minutos : ('0' + minutos);
    return minutosCadena + ':' + segundosCadena;

  }

  private createOptionsForm() {
    this.optionsForm = this.formBuilder.group({

    });
  }

  initListServicesAux() {

    this.listAllOptionAux = [];
    this.inactive_card = this.temporaryBlockData.value;
       
    // Si la tarjeta está desbloqueada, todas las opciones aparecen desbloqueadas.
    if (!this.temporaryBlockData.active) {
      this.OnOffServicesInactive(false);
      this.isServicesBlocked = true;
    }

    // Se asigna el valor del bloqueo a una variable auxiliar
    const cardBlockData: IRestrictionList = {
      label: this.temporaryBlockData.label,
      label_save:TEMPORARY_BLOCK.label_save,
      active: this.temporaryBlockData.active,
      text: this.temporaryBlockData.text,
      title: this.temporaryBlockData.title,
      title_save: TEMPORARY_BLOCK.title_save,
    };

    this.temporaryBlockDataAux = cardBlockData;
    this.listAllOptionAux.push(this.temporaryBlockDataAux);

    // Se asigna el valor de la lista a una variable para comparar
    // CNo se puede asignar diréctamente porque ocupan el mismo espacio de memoria
    this.listRestrinctionAux = [];
    this.listRestrictionConst.forEach(element => {
     
      const itemRestriction: IRestrictionList = {
        label: element.label,
        label_save: RESTRICTION_LIST.find((item) => (item.label === element.label)).label_save,
        active: element.active,
        text: element.text,
        title: element.title,
        title_save: RESTRICTION_LIST.find((item) => (item.label === element.label)).title_save,
      };

      this.isServicesBlocked = this.isServicesBlocked && !element.active;
    //  console.log("Services");
     // console.log(this.isServicesBlocked);
      this.listRestrinctionAux.push(itemRestriction);
      this.listAllOptionAux.push(itemRestriction);

    });   

  }

  async clickOffOnCard() {
    if (this.timerCounter === 1) {
      this.inactive_card = this.inactive_card;
      /* console.log("COUNTER click " + this.inactive_card );*/
      const props = {
        message: 'En unos minutos podrás volver a activar tu tarjeta.',
        time: this.timeLeft,
        timeString: this.timeString
      };
      const modal = await this.modalCtrl.create({
        component: OnOffModalTimerComponent,
        cssClass: 'on-off-form-modal-pagos',
        componentProps: props,
      });
      await modal.present();
    }

  }

  enableUpdateButton() {
    let buttonActive = false;
    for (let index = 0; index < this.listRestrinctionAux.length; index++) {
      if (this.listRestrinctionAux[index].active !== this.listRestrictionConst[index].active) {
        buttonActive = true;
        this.restrictionChanged = true;
        break;
      }
    }
    buttonActive = buttonActive || (this.temporaryBlockDataAux.active !== this.temporaryBlockData.active) ? true : false;
    this.cardBlockChanged = (this.temporaryBlockDataAux.active !== this.temporaryBlockData.active) ? true : false;
    this.switchEvent.emit({ isContinueDisable: !buttonActive, isCancelDisable: false, isNotDataSaved: false });
  }



  OnOffServicesInactive(value: boolean) {
    this.listRestrictionConst.forEach(element => {
      element.active = value;
    });
  }

  async OnOffServices(label: string) {
    this.enableUpdateButton();
  }

  async OnOffCard() {

    
    if (!this.temporaryBlockData.active) {
      this.OnOffServicesInactive(false);
      this.isServicesBlocked = true;
    } else {
      this.isServicesBlocked = false;
    }
    this.enableUpdateButton();

    /* console.log("COUNTER " + this.timerCounter );
     this.timerCounter = 1;
     console.log("COUNTER AAA " + this.active );
     const props = {
       title:  'Tu tarjeta Ripley ahora está en  ' +((this.temporaryBlockData.active === true)?'On.':'Off.'),
       message: 'Tu tarjeta ' + ((this.temporaryBlockData.active)?'':'no ') + 'estará habilitada para hacer compras o giros.',
       primaryButtonName: "Ok",
       acceptProcessAction: 3,
       secondaryButtonName: '',
       icon: 'i-confirm-check-modal'
     };

    // this.temporaryBlockData.active = !this.temporaryBlockData.active;
     const modal = await this.modalCtrl.create({
       component: OnOffModalComponent,
       cssClass: 'on-off-form-modal-pagos',
       componentProps: props,
     });
     //this.active = true;
     await modal.present();
    // this.startTimer();
   //}*/
  }

  async updateChanges(sesionId: string) {

    try {
      
    /* console.log('UPDATE CHANGES 2');
     console.log(sesionId);*/
      this.switchEvent.emit({ isContinueDisable: true, isCancelDisable: true, isNotDataSaved: true });
      // console.log('from parent');

    // Llenando datos para guardar restricciones operativas
     const updatedRestrictionList: IResponseOnOffRestrictionDetail[] = [];
      this.listRestrictionConst.forEach(element => {
        const updatedRestriction: IResponseOnOffRestrictionDetail = {
          label: element.label,
          value: element.active,
          description:''
        };      
        updatedRestrictionList.push(updatedRestriction);
     });

     this.pastValues= pastValuesBlock;
     this.listAllOptionAux.forEach(element => {
  
      if(element.label==this.statusCardLabel){
        this.pastValues.temporaryBlock = element.active;
      }
     });
     this.pastValues.temporaryBlockPresent = this.temporaryBlockData.active;
  
     const requestUpdateRestrictions: IRequestUpdateRestrictionList = {
        sessionId: sesionId,
        cardNumber: this.cardNumber,
        restrictions: updatedRestrictionList,
        userId: this.authService.getUserIdToken(),
        isCardBlockChanged: this.cardBlockChanged,
        pastValues: this.pastValues
     };

     let updateServiceBlockResponse: any = {};
     updateServiceBlockResponse.code = null;

     if(this.restrictionChanged){
       updateServiceBlockResponse =  await this.onOffRestrictionService.updateRestrictions(requestUpdateRestrictions);
     }

     let updateCardBlockResponse: any = {};

     updateCardBlockResponse.code = this.cardBlockChanged?400:null;

     if (this.cardBlockChanged && ((updateServiceBlockResponse.code === 425 && this.temporaryBlockData.active) //Si los servicios se actualizan con algún error y el card se activó
     || (updateServiceBlockResponse.code === 200 || updateServiceBlockResponse.code === null))) //Si solo cambió el bloqueo y actualizar restricciones OK ){
      {
       
          // Se obtienen los datos para bloqueo de tarjeta
          const requestUpdateTemporaryBlock: IRequestUpdateTemporaryBlock = {
            sessionId: sesionId,
            cardNumber: this.cardNumber,
            cardActive: this.temporaryBlockData.active,
            userId: this.authService.getUserIdToken()
          };
          
          updateCardBlockResponse =  await this.onOffRestrictionService.updateTemporaryBlock(requestUpdateTemporaryBlock);
      }
      
     //Verificando el resultado de guardar los cambios
     await this.savingVerify(updateServiceBlockResponse,updateCardBlockResponse);      

    } catch (error) {
      await this.showErrorModal();
    } finally {
      this.switchEvent.emit({ isContinueDisable: false, isCancelDisable: false, isNotDataSaved: false });
    }

  }

  async savingVerify(updateServiceBlockResponse: IResponseUpdateTemporaryRestrictionBlock,
    updateCardBlockResponse: IResponseUpdateTemporaryRestrictionBlock) {
   
    if (updateServiceBlockResponse.code === 425) { //Si alguno de los servicios no se guardó
      let mapResult = new Map<string, boolean>()
      let someServiceSuccess: boolean = false;
      //Recibiendo el Json como Map
      for (var value in updateServiceBlockResponse.restrictionsResult) {
        mapResult.set(value, updateServiceBlockResponse.restrictionsResult[value])
        someServiceSuccess = someServiceSuccess || updateServiceBlockResponse.restrictionsResult[value];
      }
    
      if (updateCardBlockResponse.code != 200 && !someServiceSuccess ) { //Si todo falla
        await this.showErrorModal();
      }
      else if(updateCardBlockResponse.code != 200 && someServiceSuccess){ //No se guardó bloqueo, pero al menos un servicio si 
        
        if(updateCardBlockResponse.code!= null){
          mapResult.set(this.temporaryBlockData.label, false);
        }

        this.showErrorSavingMessage(mapResult);
      }
      else {
        mapResult.set(this.temporaryBlockData.label, true); //Se guardó bien el bloqueo, pero hay servicios que
        this.showErrorSavingMessage(mapResult);
      }
      
    } else { //Si se guardaron bien todos los servicios
      let updatecardBlockMap: Map<string, boolean> = new Map<string, boolean>() ;
      if (updateCardBlockResponse.code != 200 && updateCardBlockResponse.code!= null) {//No se guardó el bloqueo    
          updatecardBlockMap.set(this.temporaryBlockData.label, false);        
          this.showErrorSavingMessage(updatecardBlockMap);
      } else  { //Si todo fue exitoso
        await this.showSuccessModal();
      }
    }

  }

 reeplaceTextBlockRestrictionResult(restrictionBLockResult: Map<string,boolean>) {

 
  //Este se va a usar para ver cuáles se han guardado y cuales no
  this.listAllOptionAux.forEach(element => {      
    element.active = (restrictionBLockResult.get(element.label_save) === undefined)?true:restrictionBLockResult.get(element.label_save);
  });
    

 }

 async showErrorSavingMessage(restrictionBLockResult: Map<string,boolean>){

   this.reeplaceTextBlockRestrictionResult(restrictionBLockResult);
    
      const props = {
        title: 'Importante',
        message: 'Solo los siguientes cambios se guardaron correctamente. Para guardar los cambios pendientes, por favor, inténtalo nuevamente. ',
        listOptionSaved: this.listAllOptionAux,       
        primaryButtonName: 'Aceptar',
        secondaryButtonName: '',
        acceptProcessAction: 2,
        icon: ''
      }; 
     
      const modal = await this.modalCtrl.create({
        component: OnOffModalComponent,
        cssClass: 'form-modal',
        componentProps: props,
      });
           
      await modal.present();    
  }

  async showErrorModal() {
    /*const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: LOAN_FAILED_MODAL_PROPS
    });
    await modal.present();
    // const option = await modal.onDidDismiss();
    await modal.onDidDismiss();*/

    const props = {
      title: '¡Ups! ocurrió un error',
      message: 'No pudimos aplicar los cambios.',
      primaryButtonName: 'Volver',
      secondaryButtonName: '',
      acceptProcessAction: 0,
      icon: 'alert'
    };

   
    const modal = await this.modalCtrl.create({
      component: OnOffModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
      
    // this.active = true;
    await modal.present();

  }

  async showSuccessModal() {

    const props = {
      message: 'Haz guardado los cambios con éxito',
      primaryButtonName: 'Aceptar',
      acceptProcessAction: 2,
      secondaryButtonName: '',
      icon: 'check'
    };

    const modal = await this.modalCtrl.create({
      component: OnOffModalComponent,
      cssClass: 'on-off-form-modal-pagos',
      componentProps: props,
    });
    // this.active = true;
    await modal.present();
  }

}

function COMPRAS_INTERNET(COMPRAS_INTERNET: any): any {
  throw new Error('Function not implemented.');
}

