import { Injectable } from '@angular/core';
import { HttpService } from '@services/http/http.service';
import {
  GET_CARD_DATA,
  RESPONSE_OTP,
  VALIDATE_OTP_DISPLAY_DATA,
  GET_PRIVATE_KEY,
  WIDGET_TOKEN_MOCK,
} from '@common/mocks/card.mocks';
import { Storage } from '@ionic/storage';
import { DeviceService } from '@services/device/device.service';
import {
  IContactData, IDigitalCardData, IOtpDigitalCard,
  IOtpValidateDigitalCard,
  IVisualizatonActivateResponse,
  IWidgetToken,
  IDigitalCardValidate,
  IDigitalCardValidateRequest
} from '@common/interfaces/digital-card.interface';
import {
  CODE_SUCCESSFUL, DATA_CARD_PRIVATE_KEY_XML,
  DECRYPT_BUFFER_SIZE, WIDGET_CODE_SUCCESSFUL
} from '@common/constants/digital-card.constants';
import { environment } from '@environments/environment';
import { UtilsService } from '@services/utils/utils';
const forge = require('node-forge');
const { parseStringPromise } = require('xml2js');

@Injectable({
  providedIn: 'root'
})
export class DatacardService {
  private privateKey;
  public visualizationEnabled;
  public keyCard;
  public originalCardNumber;
  public sessionId;
  public token;
  public data: IVisualizatonActivateResponse;

  constructor(
    private deviceService: DeviceService,
    private http: HttpService,
    private storage: Storage,
  ) { }

  public async getContactData(sessionId: string): Promise<IContactData> {
    const body = {
      sessionId
    };
    const url = environment.BASE_BACKEND_WORKER + '/card/credit-card/visualization/contact-data';
    return this.http.post(url, body , GET_CARD_DATA).toPromise();
  }

  public async sendOtp(sessionId: string, actionTypeOTP: string): Promise<IOtpDigitalCard> {
    const body = {
      sessionId,
      actionTypeOTP,
      clientIp: await this.getClientIP(),
    };
    return this.http.post(environment.BASE_BACKEND_WORKER + '/card/credit-card/visualization/send-otp', body, RESPONSE_OTP).toPromise();
  }

  public async validateOTP(sessionId: string, pin: string): Promise<IOtpValidateDigitalCard> {
    const body = {
      sessionId,
      pin,
      clientIp: await this.getClientIP(),
    };
    return this.http.post(environment.BASE_BACKEND_WORKER + '/card/credit-card/visualization/validate-otp', body, VALIDATE_OTP_DISPLAY_DATA)
      .toPromise();
  }

  public async checkVisualizationActivate(keyCard: string): Promise<IVisualizatonActivateResponse> {
    this.data = await this.verifyActivate(keyCard);
    if (this.data.code !== CODE_SUCCESSFUL) {
      throw this.data
    }
    this.visualizationEnabled = true;
    return this.data;
  }

  public async decryptCard(encriptedCard: IDigitalCardValidate): Promise<IDigitalCardData> {
    const decryptedCard: IDigitalCardData = { code: '', expirationDate: '', cvv: '' };
    [decryptedCard.code, decryptedCard.expirationDate, decryptedCard.cvv] = await Promise.all([
      this.decryptDigitalCard(encriptedCard.cardNumber),
      this.decryptDigitalCard(encriptedCard.expirationDate),
      this.decryptDigitalCard(encriptedCard.cvv2),
    ]);

    if (!decryptedCard.code) {
      throw new Error()
    }
    return decryptedCard;
  }

  private async getClientIP() {
    return (await this.deviceService.getIP()).ip;
  }

  private async getDeviceId() {
    return UtilsService.getIdentifier();
  }

  private async verifyActivate(keyCard: string): Promise<IVisualizatonActivateResponse> {
    const body = {
      keyCard,
      deviceId: await this.getDeviceId(),
      clientIp: await this.getClientIP(),
    };
    return this.http.post(environment.BASE_BACKEND_WORKER + '/card/credit-card/visualization/activate', body, GET_PRIVATE_KEY).toPromise();
  }

  private parseBigInteger(b64) {
    const buffer = forge.util.createBuffer(forge.util.decode64(b64)).toHex();
    return new forge.jsbn.BigInteger(buffer, DECRYPT_BUFFER_SIZE);
  }

  private async setDataCardPrivateKey(privateKeyXML) {
    const result = await parseStringPromise(privateKeyXML);
    const RSAParameters = result.RSAParameters;
    const privateKey = forge.pki.rsa.setPrivateKey(
      this.parseBigInteger(RSAParameters.Modulus[0]),
      this.parseBigInteger(RSAParameters.Exponent[0]),
      this.parseBigInteger(RSAParameters.D[0]),
      this.parseBigInteger(RSAParameters.P[0]),
      this.parseBigInteger(RSAParameters.Q[0]),
      this.parseBigInteger(RSAParameters.DP[0]),
      this.parseBigInteger(RSAParameters.DQ[0]),
      this.parseBigInteger(RSAParameters.InverseQ[0])
    );
    this.privateKey = privateKey;
  }

  private async decryptDigitalCard(encryptedBody: string): Promise<string> {
    if (!this.privateKey) {
      throw new Error()
    }
    const encryptedBodyBytes = forge.util.decode64(encryptedBody);
    return this.privateKey.decrypt(encryptedBodyBytes, 'RSAES-PKCS1-V1_5');
  }

  public async getWidgetToken(sessionId: string, keyCard: string): Promise<IWidgetToken> {
    const url = environment.BASE_BACKEND_WORKER + '/card/credit-card/visualization/widget-token';
    const response: IWidgetToken = await this.http.post(url, { sessionId, keyCard }, WIDGET_TOKEN_MOCK).toPromise();
    if(response.code == 26){
      return response;
    }
    const privateKeyXML = response.privateKey;
    this.storage.set(DATA_CARD_PRIVATE_KEY_XML, privateKeyXML);
    await this.setDataCardPrivateKey(privateKeyXML);
    return response;
  }

  public async validateWidget(data: IDigitalCardValidateRequest): Promise<IDigitalCardValidate> {
    const { sessionId, uuidTransaction, authToken, keyCard, typeAuth} = data
    const body = {
      sessionId,
      uuidDevice: await this.getDeviceId(),
      uuidTransaction,
      authToken,
      keyCard,
      userAgent: navigator.userAgent,
      clientIp: await this.getClientIP(),
      typeAuth
    };
    const response: IDigitalCardValidate = await this.http.post(
      environment.BASE_BACKEND_WORKER + '/card/credit-card/visualization/widget-validate',
      body,
      VALIDATE_OTP_DISPLAY_DATA)
      .toPromise();
    return response;
  }

  public async monitorViewCardFailure(data: IDigitalCardValidateRequest): Promise<IDigitalCardValidate> {
    const { sessionId, uuidTransaction, authToken, keyCard, typeAuth} = data
    const body = {
      sessionId,
      uuidDevice: await this.getDeviceId(),
      uuidTransaction,
      authToken,
      keyCard,
      userAgent: navigator.userAgent,
      clientIp: await this.getClientIP(),
      typeAuth
    };
    const response: IDigitalCardValidate = await this.http.post(
      environment.BASE_BACKEND_WORKER + '/card/credit-card/visualization/monitor-viewcard-failure',
      body,
      VALIDATE_OTP_DISPLAY_DATA)
      .toPromise();
    if (response.code === WIDGET_CODE_SUCCESSFUL) {
      return response
    } else {
      throw new Error()
    }
  }
}
