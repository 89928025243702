import { ModalController } from '@ionic/angular';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-promotions-terms-and-conditions-modal',
  templateUrl: './promotions-terms-and-conditions-modal.component.html',
  styleUrls: ['./promotions-terms-and-conditions-modal.component.scss'],
})
export class PromotionsTermsAndConditionsModalComponent {

  @Input() title: string;
  @Input() termsHighlight: string;
  @Input() termsExample: string;
  @Input() termsDescription: string;
  @Input() conditions: string;

  constructor(
    private viewController: ModalController,
  ) {
    this.title =  '';
  }

  dismissModal() {
    this.viewController.dismiss();
  }

}
