import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CREDITS_ROUTE, RIPLEY_AGENCIES_ROUTE } from '@common/constants/routes.constants';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alternative-disbursement',
  templateUrl: './alternative-disbursement.component.html',
  styleUrls: ['./alternative-disbursement.component.scss'],
})
export class AlternativeDisbursementComponent implements OnInit {

  @Input() productName: string;
  constructor(
    private viewController: ModalController,
    private router: Router
    ) { }

  ngOnInit() {}

  public redirectToOffers() {
    this.viewController.dismiss();
    return this.router.navigate([CREDITS_ROUTE], { replaceUrl: true });
  }

  public goToAgencies() {
    this.viewController.dismiss();
    return this.router.navigate([RIPLEY_AGENCIES_ROUTE], { replaceUrl: true });
  }

  dismissModal() {
    this.viewController.dismiss();
    return this.router.navigate([CREDITS_ROUTE], { replaceUrl: true });
  }
}
