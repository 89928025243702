import { Injectable } from '@angular/core';
import * as MCEPlugin from 'custom-plugins/co.acoustic.mobile.push.sdk/www/js/MCEPlugin';
@Injectable({
    providedIn: 'root'
})
export class AcousticService {
    constructor() { }

    getRegistrationDetailsPromise(): Promise<{ userId: string, channelId: string }> {
        return new Promise((resolve, reject) => {
            MCEPlugin.getRegistrationDetails(function(details) {
                if (typeof details.userId === 'undefined' || typeof details.channelId === 'undefined') {
                    reject(new Error('Not yet registered, no user/channel'));
                } else {
                    resolve({ userId: details.userId, channelId: details.channelId });
                }
            });
        });
    }
}
