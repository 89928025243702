import {
  IInformativeModal,
  IPromotion,
} from '@common/interfaces/promotion.interface';

export const EXTEND_CREDIT_LINE_DETAILS_MOCK = {
  success: [],
  failures: [],
};

export const EXTEND_CREDIT_LINE_CREATION_MOCK = {
  success: [],
  failures: [],
};

export const EXTEND_LINE_TERMS_AND_CONDITIONS = {
  success: [],
  failures: [],
};

export const INFORMATIVE_MODALS_MOCK = {
  success: [],
  failures: [],
};

export const ACTIVE_PROMOTION = {
  active: true,
  id: '',
  expirationDate: { toDate: () => new Date('01/01/3000') },
  isGlobal: true,
  location: {
    lat: '',
    lng: '',
    address: '',
  },
  details: null,
  list: null,
  promotionType: '',
  searchKeyword: '',
  hasCode: false,
  isSearchable: false,
} as IPromotion;
