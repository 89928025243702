import {
  CREDIT_CARDS_DETAILS_ROUTE, TRANSFERS_ROUTE, HOME_ROUTE, PAYMENTS_ROUTE, RIPLEY_POINTS_ROUTE
} from '@common/constants/routes.constants';
import { IVoucherPage } from '@common/interfaces/voucher.interface';

export const EXTEND_CREDIT_LINE_VOUCHER_TYPE = 'EXTEND_CREDIT_LINE_VOUCHER';
export const TRANSFER_VOUCHER_TYPE = 'TRANSFER_VOUCHER';
export const PAYMENTS_VOUCHER_TYPE = 'PAYMENTS_VOUCHER';
export const REDEEM_VOUCHER_TYPE = 'REDEEM_VOUCHER';

export const EXTEND_CREDIT_LINE_VOUCHER = {
  type: EXTEND_CREDIT_LINE_VOUCHER_TYPE,
  title: '<strong>¡Todo Listo!</strong> Incremento de línea exitoso',
  mobileTitle: 'Incremento de línea exitoso',
  card: {
    header: {
      staticText: 'Has incrementado la línea de crédito de tu Tarjeta Ripley Mastercard a ',
      dynamicText: '',
    },
    subHeader: {
      staticText: 'Hemos enviado la constancia a ',
      dynamicText: '',
      hidden: false,
    },
    leftSide: [
      {
        class: 'description',
        content: 'N° Tarjeta Ripley Mastercard ',
      },
      {
        class: 'description hidden-mobile',
        content: 'Línea de crédito anterior',
      },
      {
        class: 'description hidden-mobile',
        content: 'Línea de crédito nueva',
      }
    ],
    rightSide: [
      {
        class: 'title',
        content: '',
      },
      {
        class: 'title hidden-mobile',
        content: '',
      },
      {
        class: 'title hidden-mobile',
        content: '',
      }
    ],
    footerLeftSide: [
      {
        class: 'title',
        content: 'Fecha',
      },
      {
        class: 'description',
        content: '',
      },
    ],
    footerRightSide: []
  },
  leftButton: {
    title: 'Volver al Inicio',
    route: HOME_ROUTE,
    customState: {},
  },
  rightButton: {
    title: 'Ir a tu Tarjeta Ripley',
    route: CREDIT_CARDS_DETAILS_ROUTE,
    customState: {},
  }
} as IVoucherPage;

export const TRANSFER_VOUCHER_OLD = {
  type: TRANSFER_VOUCHER_TYPE,
  title: '',
  mobileTitle: 'Transferencia realizada',
  card: {
    header: {
      staticText: 'Has transferido ',
      staticText2: ' desde tu ',
      staticText3: ' a ',
      dynamicText: '',
      dynamicText2: '',
      dynamicText3: '',
    },
    subHeader: {
      staticText: 'Hemos enviado la constancia a ',
      dynamicText: '',
      hidden: false,
    },
    leftSide: [
      {
        class: 'title',
        content: 'Origen',
      },
      {
        class: 'description',
        content: '',
      },
      {
        class: 'description',
        content: '',
      }
    ],
    rightSide: [
      {
        class: 'title right',
        content: 'Destino',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      }
    ],
    footerLeftSide: [
      {
        class: 'title',
        content: 'Fecha',
      },
      {
        class: 'description',
        content: '',
      },
    ],
    footerRightSide: [
      {
        class: 'title right',
        content: 'Nº Transacción',
      },
      {
        class: 'description right',
        content: '',
      },
    ]
  },
  leftButton: {
    title: 'Volver al inicio',
    route: HOME_ROUTE,
    customState: {},
  },
  rightButton: {
    title: 'Volver a transferencias',
    route: TRANSFERS_ROUTE,
    customState: {},
  }
} as IVoucherPage;

export const TRANSFER_VOUCHER_NEW = {
  type: TRANSFER_VOUCHER_TYPE,
  title: '',
  mobileTitle: 'Transferencia realizada',
  card: {
    header: {
      staticText: 'Transferiste ',
      staticText2: ' desde tu ',
      staticText3: ' a ',
      dynamicText: '',
      dynamicText2: '',
      dynamicText3: '',
    },
    subHeader: {
      staticText: 'Enviaremos el comprobante de transferencias a ',
      dynamicText: '',
      hidden: false,
    },
    leftSide: [
      {
        class: 'title',
        content: 'Cuenta de origen',
      },
      {
        class: 'title',
        content: 'Cuenta de destino',
      },
      {
        class: 'title',
        content: 'Banco destinatario',
      },
      {
        class: 'title',
        content: 'Comisión',
      },
      {
        class: 'title',
        content: 'ITF',
      },
      {
        class: 'title',
        content: 'Fecha',
      },
      {
        class: 'title',
        content: 'Hora',
      },
      {
        class: 'title',
        content: 'Canal',
      },
      {
        class: 'title',
        content: 'Operación',
      },
      {
        class: 'title',
        content: 'N° Transacción',
      }
      
    ],
    rightSide: [
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      }
    ],
    footerLeftSide: [
      {
        class: 'title',
        content: 'Fecha',
      },
      {
        class: 'description',
        content: '',
      },
    ],
    footerRightSide: [
      {
        class: 'title right',
        content: 'Nº Transacción',
      },
      {
        class: 'description right',
        content: '',
      },
    ]       
  },
  leftButton: {
    title: 'Ir al inicio',
    route: HOME_ROUTE,
    customState: {},
  },
  rightButton: {
    title: 'Volver a transferencias',
    route: TRANSFERS_ROUTE,
    customState: {},
  }
} as IVoucherPage;


export const TRANSFER_VOUCHER = {
  type: TRANSFER_VOUCHER_TYPE,
  title: '',
  mobileTitle: 'Transferencia realizada',
  card: {
    header: {
      staticText: 'Transferiste ',
      staticText2: ' desde tu ',
      staticText3: ' a ',
      dynamicText: '',
      dynamicText2: '',
      dynamicText3: '',
    },
    subHeader: {
      staticText: 'Enviamos el comprobante de transferencia a ',
      dynamicText: '',
      hidden: false,
    },
    leftSide: [
      {
        class: 'title',
        content: 'Cuenta de origen',
      },
      {
        class: 'title',
        content: 'Cuenta de destino',
      },
      {
        class: 'title',
        content: 'Banco destinatario',
      },
      {
        class: 'title',
        content: 'Comisión',
      },
      {
        class: 'title',
        content: 'ITF',
      },
      {
        class: 'title',
        content: 'Fecha',
      },
      {
        class: 'title',
        content: 'Hora',
      },
      {
        class: 'title',
        content: 'Canal',
      },
      {
        class: 'title',
        content: 'Operación',
      },
      {
        class: 'title',
        content: 'N° Transacción',
      }
      
    ],
    rightSide: [
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      }
    ],
    footerLeftSide: [
      {
        class: 'title',
        content: 'Fecha',
      },
      {
        class: 'description',
        content: '',
      },
    ],
    footerRightSide: [
      {
        class: 'title right',
        content: 'Nº Transacción',
      },
      {
        class: 'description right',
        content: '',
      },
    ]      
  },
  leftButton: {
    title: 'Volver al inicio',
    route: HOME_ROUTE,
    customState: {},
  },
  rightButton: {
    title: 'Volver a transferencias',
    route: TRANSFERS_ROUTE,
    customState: {},
  }
} as IVoucherPage;


export const TRANSFER_VOUCHER_INTEROP = {
  type: TRANSFER_VOUCHER_TYPE,
  title: '',
  mobileTitle: 'Transferencia realizada',
  card: {
    header: {
      staticText: 'Transferiste ',
      staticText2: ' desde tu ',
      staticText3: ' a ',
      staticText4: ' de ',
      dynamicText: '',
      dynamicText2: '',
      dynamicText3: '',
      dynamicText4: '',
    },
    subHeader: {
      staticText: 'Enviamos el comprobante de transferencia a ',
      dynamicText: '',
      hidden: false,
    },
    leftSide: [
      {
        class: 'title',
        content: 'Teléfono destinatario',
      },
      {
        class: 'title',
        content: 'Banco destinatario',
      },
      {
        class: 'title',
        content: 'Fecha',
      },
      {
        class: 'title',
        content: 'Hora',
      },
      {
        class: 'title',
        content: 'Monto',
      },
      {
        class: 'title',
        content: 'N° Transacción',
      },
      {
        class: 'title',
        content: 'ITF',
      },
      {
        class: 'title',
        content: 'Comisión',
      },
      {
        class: 'title',
        content: 'Monto total',
      }
    ],
    rightSide: [
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
      {
        class: 'description right',
        content: '',
      },
    ],
    footerLeftSide: [
      {
        class: 'title',
        content: 'Fecha',
      },
      {
        class: 'description',
        content: '',
      },
    ],
    footerRightSide: [
      {
        class: 'title right',
        content: 'Nº Transacción',
      },
      {
        class: 'description right',
        content: '',
      },
    ]      
  },
  leftButton: {
    title: 'Volver al inicio',
    route: HOME_ROUTE,
    customState: {},
  },
  rightButton: {
    title: 'Volver a transferencias',
    route: TRANSFERS_ROUTE,
    customState: {},
  }
} as IVoucherPage;

export const PAYMENTS_VOUCHER = {
  type: PAYMENTS_VOUCHER_TYPE,
  productType: '',
  title: '<strong>¡Todo Listo!</strong> El pago fue exitoso.',
  mobileTitle: 'Pago exitoso',
  card: {
    header: {
      staticText: '',
      dynamicText: '',
    },
    subHeader: {
      staticText: 'Hemos enviado el comprobante de pago a ',
      dynamicText: '',
      hidden: false,
    },
    leftSide: [],
    rightSide: [],
    footerLeftSide: [
      {
        class: 'title',
        content: 'Fecha',
      },
      {
        class: 'description',
        content: '',
      },
    ],
    footerRightSide: [
      {
        class: 'title',
        content: 'Nº Transacción',
      },
      {
        class: 'description',
        content: '',
      },
    ]
  },
  leftButton: {
    title: 'Volver a Pagos',
    route: PAYMENTS_ROUTE,
    customState: {},
  },
  rightButton: {
    title: 'Volver a inicio',
    route: HOME_ROUTE,
    customState: {},
  }
} as IVoucherPage;

export const REDEEM_VOUCHER = {
  type: REDEEM_VOUCHER_TYPE,
  title: '<strong>¡Todo Listo!</strong> El canje fue exitoso.',
  mobileTitle: 'Canje exitoso',
  card: {
    header: {
      staticText: 'Has canjeado tu ',
      staticText2: ' por ',
      staticText3: ' RipleyPuntos Go',
      dynamicText: '',
      dynamicText2: '',
    },
    subHeader: {
      staticText: '',
      dynamicText: '',
      hidden: true,
    },
    leftSide: [],
    rightSide: [],
    footerLeftSide: [
      {
        class: 'title',
        content: 'Fecha de emisión',
      },
      {
        class: 'description',
        content: '',
      },
    ],
    footerRightSide: [
      {
        class: 'title',
        content: 'Fecha de expiración',
      },
      {
        class: 'description',
        content: '',
      },
    ]
  },
  leftButton: {
    title: 'Ver mis canjes',
    route: RIPLEY_POINTS_ROUTE,
    customState: {},
  },
  rightButton: {
    title: 'Volver al inicio',
    route: HOME_ROUTE,
    customState: {},
  }
} as IVoucherPage;
