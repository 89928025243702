import { UtilsService } from '@services/utils/utils';
import { Router } from '@angular/router';
import { IEfexSefState, IExtraAccount, ISimulatorOption } from './../../../../common/interfaces/efex-sef-automatic.interface';
import { EfexService } from './../../../../services/efex/efex.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { PepInformationComponent } from '@components/open-account/modals/pep-information/pep-information.component';
import { ModalController } from '@ionic/angular';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import {
  EFEX_FAILED_MODAL_PROPS,
  EFEX_SEF_FAILED_POLICY_MODAL,
  OPEN_SIMPLE_ACCOUNT_FAILED_MODAL_PROPS,
  PEP_MODAL_PROPS,
  PEP_TOOLTIP,
} from '@common/constants/modal-props.constants';
import { IUserInformation } from '@common/interfaces/user.interface';
import { UserService } from '@services/user/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IBank } from '@common/interfaces/bank.interface';
import { SimulatorService } from '@services/simulator/simulator.service';
import { EFEX, EMAIL_REGEX, RSEF, SCREEN_WIDTH, SEF, SEF_CODE } from '@common/constants/misc.constants';
import { CREDITS_ROUTE, EFEX_AUTO_ROUTE, EFEX_DETAIL_ROUTE_BASELESS, SEF_DETAIL_ROUTE_BASELESS, SEF_ROUTE } from '@common/constants/routes.constants';
import { CCI_ACCOUNT_NUMBER_MAX_LENGTH, CCI_ACCOUNT_NUMBER_MIN_LENGTH } from '@common/constants/transfers.constants';
import { IOffering } from '@common/interfaces/offerings.interface';
import { AlternativeDisbursementComponent } from '../alternative-disbursement/alternative-disbursement.component';
import { EFEX_MAIN_VIEW } from '@common/constants/efex-automatic.constants';
import { SEF_MAIN_VIEW } from '@common/constants/sef-automatic.constants';
import { TceaComponent } from '@components/efex/modals/tcea/tcea.component';
import { IDetailDebitRSefOutput, IGenerateLoanData, ILoanSimulator } from '@common/interfaces/simulator.interface';
import { LEAD_HOT, PRODUCT_EFEX, PRODUCT_SEF, PRODUCT_SEF_REENGANCHE } from '@common/constants/offerings.constants';
import { TransferService } from '@services/transfer/transfer.service';
import { ITransferShiftSchedule, IResultFinancialEntityInfoResponse } from '@common/interfaces/transfers.interface';
import { SUCCESS } from '@common/constants/error.constants';
import { HOME_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { GENERIC_TAG_BANCO_RIPLEY, GENERIC_TAG_CON_SEGURO_VIDA, GENERIC_TAG_DESGRAVAMEN, GENERIC_TAG_DESGRAVAMEN_CON_DEVOLUCION, GENERIC_TAG_OTRO_BANCO, GENERIC_TAG_POP_UP, GENERIC_TAG_SIN_SEGURO_VIDA } from '@common/constants/tag/tag-generic.constants';

@Component({
  selector: 'app-declaration',
  templateUrl: './declaration.component.html',
  styleUrls: ['./declaration.component.scss'],
})
export class DeclarationComponent implements OnInit, OnDestroy {

  utils = UtilsService;
  currentStep: number;
  privacyPoliciesLink: string;
  user: IUserInformation;
  extraAccountForm: FormGroup;
  extraAccount: IExtraAccount;
  subscription: Subscription = new Subscription();
  firstFormHasErrors: boolean;
  secondFormHasErrors: boolean;
  conditionsFormGroup: FormGroup;
  isCreatingAccount: boolean;
  @Input() offerType: string;
  @Input() extraAccounts: IExtraAccount[];
  @Input() offer: IOffering;
  @Input() simulatorSelectedKey: string;
  @Input() haveBrAccount: boolean;
  @Input() accountCCI: boolean;
  @Input() simulatorOption: ISimulatorOption;
  @Input() loanSimulator: ILoanSimulator;
  @Input() keySimulator: string = '';
  @Input() isEfexPromotional: boolean = false;
  @Input() tinEnabled: boolean = false;
  @Input() ensuranceType: boolean = false;
  @Input() chkLifeInsurance: boolean = false;
  @Input() reengagement: IDetailDebitRSefOutput;
  bankList: IBank[];
  loading: boolean;
  cciError = false;
  isDisabledEmail = true;
  bankSelected: IBank;
  errorModal: HTMLIonModalElement;
  shiftsSchedule: ITransferShiftSchedule[];
  cciMessageError: string;
  inmediateBlocked: boolean = false;
  resultFinancialEntityInfoResponse: IResultFinancialEntityInfoResponse;
  isLoading: boolean = false;
  isDisabled: boolean = false;
  cciblocked: boolean = true;
  hasReengagement: boolean = false;

  constructor(private viewController: ModalController,
    private modalCtrl: ModalController,
    private efexservice: EfexService,
    private userService: UserService,
    private simulatorService: SimulatorService,
    private formBuilder: FormBuilder,
    private router: Router,
    private utilsService: UtilsService,
    private transferService: TransferService,
    private googleAnalyticsService: GoogleAnalyticsService) {
    this.currentStep = 1;
    this.user = this.userService.userInformation || {} as IUserInformation;
    this.firstFormHasErrors = false;
    this.secondFormHasErrors = false;
  }

  get isFirstStep() { return this.currentStep === 1; }
  get isSecondStep() { return this.currentStep === 2; }
  get bank() { return this.extraAccountForm.controls.bank; }
  get cci() { return this.extraAccountForm.controls.cci; }
  get email() { return this.extraAccountForm.controls.email; }
  //get ownAccount() { return this.extraAccountForm.controls.ownAccount; }
  get checkPEP() { return this.conditionsFormGroup.controls.checkPEP; }
  get checkResidence() { return this.conditionsFormGroup.controls.checkResidence; }
  get checkPolicyPrivacy() { return this.conditionsFormGroup.controls.checkPolicyPrivacy; }
  get isMobile() { return window.innerWidth < SCREEN_WIDTH.TABLET; }
  get tooltipText() { return this.isMobile ? '' : PEP_TOOLTIP.message; }
  get isIos() {
    var ua = navigator.userAgent;
    if (/iPhone|iPad|iPod/i.test(ua)) return true;
    return false;
  }

  editEmail() {
    this.isDisabledEmail = false;
    this.email.enable();
    this.email.setValue('');
  }

  cleanCCI() {
    this.cciblocked = false;
    this.cci.setValue(null);
  }

  onChangeCCI($event: any): void {
    this.utils.checkOnlyNumbers($event);
    if (!this.tinEnabled) return;
    this.isDisabled = true;
    if ($event.target.value.length == 20) {
      this.getFinancialEntityAndFees($event.target.value);
    }
  }

  async getFinancialEntityAndFees(cci: string) {
    this.isLoading = true;
    this.cciError = false;
    this.inmediateBlocked = false;
    this.secondFormHasErrors = false;
    try {
      const inputFinancialEntity = {
        hashCodeSimulator: sessionStorage.getItem("keySimulator"),
        productCode: this.offerType,
        IBAN: cci,
      }
      this.resultFinancialEntityInfoResponse = await this.transferService.getFinancialEntityAndFees(inputFinancialEntity);
      var validatecci = this.validateCci();
      if (!validatecci) return;
      else if (this.resultFinancialEntityInfoResponse.code != SUCCESS) {
        this.inmediateBlocked = true;
        //this.ownAccount.setValue('deferred');
      } else {
        //this.ownAccount.setValue('inmediate');
      }
      this.isDisabled = false;
      this.isLoading = false;
    } catch (error) {
      this.inmediateBlocked = true;
      //this.ownAccount.setValue('deferred');
      this.isLoading = false;
      this.isDisabled = false;
    }
  }

  validateCci(): boolean {
    this.bankSelected = this.bank.value;
    this.cciError = false;
    this.secondFormHasErrors = false;
    if (this.cci.value.toString().substring(0, 3) !== this.bankSelected.code) {
      this.isLoading = false;
      this.cciError = true;
      this.secondFormHasErrors = true;
      this.isDisabled = true;
      this.cciMessageError = 'El número de cci no corresponde al banco seleccionado';
      return false;
    }
    return true;
  }

  ngOnInit() {
    this.hasReengagement = this.reengagement ? true : false;
    HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_CCI_ABRIRCUENTA_LOAD.product = this.accountCCI ? 'antes de ingresar la cuenta de otro banco recuerda' : 'abre tu cuenta ahorro simple y obten';
    HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_CCI_ABRIRCUENTA_LOAD.sub_category = this.offerType === PRODUCT_EFEX ? EFEX : this.hasReengagement ? RSEF : SEF;
    HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_CCI_ABRIRCUENTA_LOAD.step = this.accountCCI ? 'step ingresar cuenta otro banco' : 'step abrir cuenta ahorro';
    HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_CCI_ABRIRCUENTA_LOAD.ensurance_type = this.offerType === PRODUCT_EFEX ? '' : `${!this.ensuranceType ? GENERIC_TAG_DESGRAVAMEN : GENERIC_TAG_DESGRAVAMEN_CON_DEVOLUCION} ${this.chkLifeInsurance ? GENERIC_TAG_CON_SEGURO_VIDA : GENERIC_TAG_SIN_SEGURO_VIDA}`;
    HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_CCI_ABRIRCUENTA_LOAD.amount = this.simulatorOption.amount,
    HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_CCI_ABRIRCUENTA_LOAD.fee = this.simulatorOption.term,
    HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_CCI_ABRIRCUENTA_LOAD.type = this.accountCCI ? GENERIC_TAG_OTRO_BANCO : GENERIC_TAG_BANCO_RIPLEY;
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_POP_UP, HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_CCI_ABRIRCUENTA_LOAD);

    this.getPolicyPrivacy();
    this.getBanks();
    this.isDisabled = this.tinEnabled && this.accountCCI;
    this.extraAccountForm = this.formBuilder.group({
      bank: [null, [Validators.required]],
      cci: ['', Validators.compose([
        Validators.required,
        Validators.minLength(CCI_ACCOUNT_NUMBER_MIN_LENGTH),
        Validators.maxLength(CCI_ACCOUNT_NUMBER_MAX_LENGTH)
      ])],
      email: [
        { value: this.user.email ? this.user.email.toLocaleLowerCase() : '', disabled: true },
        Validators.compose([Validators.required, Validators.pattern(EMAIL_REGEX)])
      ],
      //ownAccount: ['inmediate', [Validators.required]],
    });

    this.extraAccountForm.valueChanges.subscribe(() => {
      this.firstFormHasErrors = false;
      this.secondFormHasErrors = false;
    });

    this.conditionsFormGroup = this.formBuilder.group({
      checkPEP: [false, [Validators.requiredTrue]],
      checkResidence: [false, [Validators.requiredTrue]],
      checkPolicyPrivacy: [false, [Validators.requiredTrue]],
      insuranceCheck: [false],
    });

  }

  ionViewWillEnter() {
    UtilsService.showRecaptchaBadge();
  }

  ionViewWillLeave() {
    UtilsService.hideRecaptchaBadge();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async getPolicyPrivacy() {
    const urlAC = await this.efexservice.getExtensionAccountAuthorizationConditions();
    this.privacyPoliciesLink = urlAC.value;
  }

  async setPolicyPrivacy() {
    if (this.privacyPoliciesLink) {
      window.open(this.privacyPoliciesLink, '_blank');
    } else {
      await this.openErrorAlertPolicyPrivacy();
      await this.getPolicyPrivacy();
    }
  }

  async setPEP() {
    if (this.privacyPoliciesLink) {
      window.open(this.privacyPoliciesLink, '_blank');
    } else {
      await this.openErrorAlertPolicyPrivacy();
      await this.getPolicyPrivacy();
    }
  }

  async getBanks() {
    try {
      this.loading = true;
      this.bankList = await this.simulatorService.getBanks();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      await this.openErrorAlert(err);
    }
  }

  async openErrorAlert(dataObject?: any) {
    const props = EFEX_FAILED_MODAL_PROPS;
    props.dataString = JSON.stringify(dataObject, null, 2);
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

  async showPEPModal() {
    if (this.isMobile) {
      const modal = await this.modalCtrl.create({
        component: TceaComponent,
        cssClass: 'form-modal',
        componentProps: PEP_MODAL_PROPS,
      });
      await modal.present();
    }
  }

  async validate(type = '') {
    if (type == 'cci' && !this.tinEnabled) {
      var validator = this.validateCci();
      if (!validator) return;
    }
    HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_BTN_CCI_AGREGARCUENTA.product = 'agregar cuenta';
    HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_BTN_CCI_AGREGARCUENTA.sub_category = this.offerType === PRODUCT_EFEX ? EFEX : this.hasReengagement ? RSEF : SEF;
    HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_BTN_CCI_AGREGARCUENTA.step = type == 'cci' ? 'step ingresar cuenta otro banco' : 'step abrir cuenta ahorro',
    HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_BTN_CCI_AGREGARCUENTA.ensurance_type = this.offerType === PRODUCT_EFEX ? '' : `${!this.ensuranceType ? GENERIC_TAG_DESGRAVAMEN : GENERIC_TAG_DESGRAVAMEN_CON_DEVOLUCION} ${this.chkLifeInsurance ? GENERIC_TAG_CON_SEGURO_VIDA : GENERIC_TAG_SIN_SEGURO_VIDA}`;
    HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_BTN_CCI_AGREGARCUENTA.amount = this.simulatorOption.amount;
    HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_BTN_CCI_AGREGARCUENTA.fee = this.simulatorOption.term;
    HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_BTN_CCI_AGREGARCUENTA.type = type == 'cci' ? GENERIC_TAG_OTRO_BANCO : GENERIC_TAG_BANCO_RIPLEY
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_POP_UP, HOME_GA4_CONSTANTS.HOME_LOANS_POPUP_BTN_CCI_AGREGARCUENTA);

    if (!(this.checkPEP && this.checkResidence && this.checkPolicyPrivacy)) {
      this.showAlternativeDisbursement();
      return;
    }
    if (this.accountCCI) {
      this.isCreatingAccount = false;
      this.addExtraAccount(type);
      //return;
    }
    this.isCreatingAccount = false;
    if (this.offerType == SEF_CODE || this.offerType == PRODUCT_SEF_REENGANCHE) {
      this.goToSefDetail();
    } else {
      this.goToEfexDetail();
    }
  }

  addExtraAccount(type) {
    this.bankSelected = this.bank.value;
    this.extraAccount = {
      bankName: this.bankSelected.shortName,
      bankCode: this.bankSelected.code,
      cci: this.cci.value,
      email: this.email.value,
      transactionType: 'deferred', //this.ownAccount.value,
      hashAccountNumber: '',
      hashInterbankAccountCode: '',
      editEmail: !this.isDisabledEmail,
      inmediateBlocked: this.inmediateBlocked
    };
    this.extraAccounts.push(this.extraAccount);
  }

  goToSefDetail() {
    this.dismissModal();
    const stateArgs: IEfexSefState = {
      originView: SEF_MAIN_VIEW,
      offerCode: this.offer.codeProduct,
      isDestinationBrAccount: this.accountCCI ? false : true,
      isNewSimpleAccount: this.accountCCI ? false : true,
      simulatorOption: this.simulatorOption,
      loanSimulator: this.loanSimulator,
      otherBankDestinationAccount: this.accountCCI ? this.extraAccount : undefined,
      keySimulator: this.keySimulator,
      sessionId: this.offer.sessionId,
      widgetEnabled: true,
      tinEnabled: this.offer.tinEnabled,
      ensuranceType: this.ensuranceType,
      chkLifeInsurance: this.chkLifeInsurance,
      reengagement: this.reengagement,
      offeringData: this.offer
    };
    const generateLead: IGenerateLoanData = {
      step: LEAD_HOT.detail, codeProduct: this.hasReengagement ? PRODUCT_SEF_REENGANCHE : PRODUCT_SEF
    }
    this.simulatorService.GenerateLoan(generateLead);
    this.router.navigate([`${SEF_ROUTE}/${SEF_DETAIL_ROUTE_BASELESS}`],
      { replaceUrl: true, state: stateArgs });
  }

  goToEfexDetail() {
    this.dismissModal();
    const stateArgs: IEfexSefState = {
      originView: EFEX_MAIN_VIEW,
      offerCode: this.offer.codeProduct,
      isDestinationBrAccount: this.accountCCI ? false : true,
      isNewSimpleAccount: this.accountCCI ? false : true,
      simulatorOption: this.simulatorOption,
      loanSimulator: this.loanSimulator,
      otherBankDestinationAccount: this.accountCCI ? this.extraAccount : undefined,
      keySimulator: this.keySimulator,
      isEfexPromotional: this.isEfexPromotional,
      sessionId: this.offer.sessionId,
      widgetEnabled: true,
      tinEnabled: this.offer.tinEnabled,
      offeringData: this.offer
    };

    const generateLead: IGenerateLoanData = {
      step: LEAD_HOT.detail, codeProduct: PRODUCT_EFEX
    }
    this.simulatorService.GenerateLoan(generateLead);
    this.router.navigate([`${EFEX_AUTO_ROUTE}/${EFEX_DETAIL_ROUTE_BASELESS}`],
      { replaceUrl: true, state: stateArgs });
  }

  async openErrorAlertPolicyPrivacy() {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: EFEX_SEF_FAILED_POLICY_MODAL,
    });
    await modal.present();
  }

  public async showPepModal() {
    const modal = await this.modalCtrl.create({
      component: PepInformationComponent,
      cssClass: ['form-modal', 'sworn-declaration'],
      componentProps: []
    });
    await modal.present();
  }

  public showAlternativeDisbursement = async () => {
    this.dismissModal();
    const props = {
      productName: this.offerType
    };
    const modal = await this.modalCtrl.create({
      component: AlternativeDisbursementComponent,
      cssClass: ['form-modal', 'sworn-declaration'],
      backdropDismiss: false,
      componentProps: props,
    });
    await modal.present();
  }

  public async openFailedModal() {
    this.dismissModal();
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: OPEN_SIMPLE_ACCOUNT_FAILED_MODAL_PROPS,
    });

    modal.present();
    modal.onDidDismiss().then(() => {
      return this.router.navigateByUrl(`/${CREDITS_ROUTE}`);
    });
  }

  viewPDF() {
    window.open('../../../../../assets/CuentaDeAhorro.pdf', '_blank');
  }

  dismissModal(type = '') {
    type != '' ? this.viewController.dismiss(type) : this.viewController.dismiss('');
  }

  public async openTransferGenericFailModal() {
    if (!this.errorModal) {
      this.errorModal = await this.modalCtrl.create({
        component: GenericModalComponent,
        cssClass: 'form-modal',
        componentProps: EFEX_FAILED_MODAL_PROPS
      });
      this.errorModal.onDidDismiss()
        .then((data) => {
          this.dismissModal();
        });
      this.errorModal.present();
    }
  }

  /*FIN MODAL VER HORARIOS*/

}