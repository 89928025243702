import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from '@services/utils/utils';

@Component({
  selector: 'app-visor-account-state',
  templateUrl: './visor-account-state.component.html',
  styleUrls: ['./visor-account-state.component.scss'],
})
export class VisorAccountStateComponent implements AfterViewInit {

  @ViewChild('pdfViewer') public pdfViewer: any;
  @Input() public documentBlobData: Blob;
  public nativeIos: boolean = false;

  constructor(
    private modalController: ModalController,
    private utils: UtilsService
  ) {
    this.nativeIos = this.utils.nativeIos;
  }

  public ngAfterViewInit(): void {
    this.pdfViewer.pdfSrc = this.documentBlobData;
    this.pdfViewer.refresh();
  }

  public hideModal(): void {
    this.modalController.dismiss();
  }

}