import { NgModule } from '@angular/core';
import { PeruvianCurrencyPipe } from '@pipes/peruvian-currency/peruvian-currency.pipe';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { HideTextPipe } from '@pipes/hide-text-number/hide-text-number.pipe';
import { TruncateDecimalsPipe } from '@pipes/truncate-decimals/truncate-decimals.pipe';
import { MaskEmailPipe } from '@pipes/mask-email/mask-email.pipe';
import { TitleCasePipe } from '@pipes/title-case/title-case.pipes';
import { RoundDownPipe } from './round-down/round-down.pipe';
import { DolarCurrencyPipe } from './dolar-currency/dolar-currency.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    PeruvianCurrencyPipe,
    HideTextPipe,
    TruncateDecimalsPipe,
    MaskEmailPipe,
    TitleCasePipe ,
    RoundDownPipe,
    DolarCurrencyPipe,
  ],
  exports: [
    PeruvianCurrencyPipe,
    HideTextPipe,
    TruncateDecimalsPipe,
    MaskEmailPipe,
    TitleCasePipe ,
    RoundDownPipe,
    DolarCurrencyPipe,
  ],
  providers: [
    CurrencyPipe
  ]
})
export class PipesModule {}
