import { Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MAIN_CATEGORY, MOBILE_BREAKPOINT } from '@common/constants/misc.constants';
import { RIPLEY_POINTS_GIFTCARD_ROUTE, RIPLEY_POINTS_REDEEM_ROUTE } from '@common/constants/routes.constants';
import { RIPLEY_POINTS_SLIDER } from '@common/constants/sliders.constants';
import { IUserLogin } from '@common/interfaces/auth.interface';
import { IDataLoaded } from '@common/interfaces/default.interface';
import { IProductCategory } from '@common/interfaces/redeem-ripley-points.interface';
import { IConsolidatedRewardPoints, IRewardPoints } from '@common/interfaces/reward-points.interface';
import { IonSlides, ModalController } from '@ionic/angular';
import { FirebaseService } from '@services/firebase/firebase.service';
import { RewardPointsService } from '@services/reward-points/reward-points.service';
import { LOTTIE_CONFIG, UtilsService } from '@services/utils/utils';
import { Storage } from '@ionic/storage';
import { USER_INFO } from '@common/constants/auth.constants';
import { RedeemDetailsModalComponent } from '@components/redeem-details-modal/redeem-details-modal.component';
import { Subscription } from 'rxjs';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { TOOLTIP_RIPLEY_POINTS_DASHBOARD_CANJES } from '@common/constants/modal-props.constants';

@Component({
  selector: 'app-canje-product',
  templateUrl: './canje-product.component.html',
  styleUrls: ['./canje-product.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CanjeProductComponent implements OnInit, OnDestroy {

  public arePointsConsolidatedLoaded: IDataLoaded;
  public arePointsPendingLoaded: IDataLoaded;
  public rewardPointConsolidated: IConsolidatedRewardPoints;
  public rewardPointPending: IRewardPoints;
  public loading = true;
  public customerType: string;
  public myCodeList: any[] = [];
  public myCodeListDis: any[] = [];
  public myCodeListVen: any[] = [];
  public allCategories: IProductCategory[] = [];
  public categoryList: IProductCategory[] = [];
  public slideOptions: any;
  public isBeginningSlide = true;
  public isEndSlide = true;
  public activeCategory: IProductCategory;
  public activeCategoryButton: IProductCategory;
  public tabindex: number = 1;
  public p: number = 1;
  userInfo: IUserLogin;
  @ViewChild(IonSlides) public slides: IonSlides;

  //Lottie config
  lottieConfig: object = LOTTIE_CONFIG;
  lottieHeight: number = LOTTIE_CONFIG.sizes.big.height;
  lottieWidth: number = LOTTIE_CONFIG.sizes.big.width;

  private updateSubscription: Subscription;
  private tooltipMessage: string = 'Ahora tus canjes se visualizan en dos secciones. <strong>Disponibles:</strong> Canjes por utilizar / <strong>No Disponibles:</strong> Canjes vencidos o marcados como utilizado.';

  constructor(
    private firebaseService: FirebaseService,
    private storage: Storage,
    private rewardPointsService: RewardPointsService,
    private modalController: ModalController,
    private router: Router,
    private utils: UtilsService,
  ) {
    this.setViewVariables();
    //this.getRewardConsolidated();
    //this.getPendingPoints();
    this.customerType = 'ripley';
    this.slideOptions = RIPLEY_POINTS_SLIDER;
    this.updateSubscription = this.rewardPointsService.updateMisCanjes$.subscribe(() => {
      this.getExchanges();
    }); 
  }

  @HostListener('window:resize', ['$event'])
  public async onResize() {
    const milliseconds = 100;
    if (this.slides) { setTimeout(() => this.slides.update(), milliseconds); }
    this.verifySlideIcons();
  }

  async ngOnInit() {
    this.userInfo = await this.storage.get(USER_INFO);
    this.loading = true;
    await this.getExchanges();
    this.loading = false;
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }
 
  public get isDesktop(): boolean {
    return window.innerWidth > MOBILE_BREAKPOINT;
  }
  public get showPendingPoints(): boolean {
    return !!this.rewardPointConsolidated && this.isPointsPendingLoaded && !this.isPointsPendingErrors && !this.isPointsPendingZero;
  }

  public get isPointsPendingLoaded(): boolean {
    return !!this.arePointsPendingLoaded.isLoaded;
  }

  public get isPointsPendingErrors(): boolean {
    return !!this.arePointsPendingLoaded.hasErrors;
  }

  public get isPointsPendingZero(): boolean {
    return this.rewardPointPending ? Number(this.rewardPointPending.Account.nOfPendingPoints) === 0 : false;
  }

  private async getRewardConsolidated() {
    try {
      const rewardConsolidatedArray = await this.rewardPointsService.getConsolidatedPoints();
      this.rewardPointConsolidated = rewardConsolidatedArray.length > 0 ? rewardConsolidatedArray[0] : null;
      if (this.rewardPointConsolidated && this.rewardPointConsolidated.availablePoints) {
        this.arePointsConsolidatedLoaded.isLoaded = true;
      }
    } catch (err) {
      this.arePointsConsolidatedLoaded.hasErrors = true;
      this.rewardPointConsolidated = null;
    }
  }

  private async getPendingPoints() {
    try {
      const pendingPointsArray = await this.rewardPointsService.getPendingPoints();
      this.rewardPointPending = pendingPointsArray.length > 0 ? pendingPointsArray[0] : null;
    } catch (err) {
      this.arePointsPendingLoaded.hasErrors = true;
      this.rewardPointPending = null;
    }
    this.arePointsPendingLoaded.isLoaded = true;
  }

  private setViewVariables() {
    this.arePointsConsolidatedLoaded = {
      isLoaded: false,
      hasErrors: false
    };
    this.arePointsPendingLoaded = {
      isLoaded: false,
      hasErrors: false
    };
  }

  public async verifySlideIcons() {
    if (!this.slides) { return; }
    this.isBeginningSlide = await this.slides.isBeginning();
    this.isEndSlide = await this.slides.isEnd();
    const currentIndex = await this.slides.getActiveIndex();
    this.activeCategoryButton = this.categoryList[currentIndex];
  }

  public async previousSlide() {
    await this.slides.slidePrev();
    this.verifySlideIcons();
  }

  public async nextSlide() {
    await this.slides.slideNext();
    this.verifySlideIcons();
  }

  private async getCategoriesList() {
    this.categoryList = [];
    const categories = await this.firebaseService.getFirebaseCollection('productCategories').orderBy('order').get();
    categories.forEach((category) => {
      this.allCategories.push(category.data() as IProductCategory);
      if (category.data().active && category.data().categoryId !== MAIN_CATEGORY.LATAM) {
        this.categoryList.push(category.data() as IProductCategory);
      }
    });
    this.activeCategory = this.categoryList[0];
  }

  private async getExchanges() {
    this.myCodeListDis = [];
    this.myCodeListVen = [];
    const userId = UtilsService.formatUserId(this.userInfo.documentNumber, this.userInfo.documentType);
    const codeList = await this.firebaseService.getFirebaseCollection('productTransactions')
      .where('userId', '==', userId)
      .where('status', '==', 'completed')
      .orderBy('createdAt')
      .get();

    const currentTimestamp = new Date().getTime();
    codeList.forEach((code) => {
      if (code.data().expirationDate && code.data().category !== MAIN_CATEGORY.LATAM) {
        let fechaExpiracion = null;
        if (code.data().expirationDate.hasOwnProperty('seconds')) {
          fechaExpiracion = new Date(code.data().expirationDate.toDate());
        } else {
          fechaExpiracion = new Date(code.data().expirationDate);
        }
        let markAsUsed: boolean = code.data().markAsUsed != null ? code.data().markAsUsed : false;
        if (fechaExpiracion.getTime() < currentTimestamp || markAsUsed) {
          this.myCodeListVen.push(code.data());
        } else {
          this.myCodeListDis.push(code.data());
        }
      }
    });
    this.myCodeListVen.reverse();
    this.myCodeListDis.reverse();
  }

  public formatDate(date: Date) {
    date = new Date(date);
    return this.utils.formatDate(date, 'dd/MM/yyyy');
  }

  public async showCode(code: any) {
    const category = this.allCategories.find((c) => c.categoryId === code.category);
    if (!category) { return; }
    this.rewardPointsService.code = code;
    this.rewardPointsService.category = category;
    const modal = await this.modalController.create({
      component: RedeemDetailsModalComponent,
      cssClass: 'ripley-points-redeem-modal',
      componentProps: {
        code,
        category
      }
    });
    modal.present();
  }

  public goTo(mainCategory: string) {
    this.rewardPointsService.mainCategory = mainCategory;
    this.rewardPointsService.categoryList = this.categoryList.filter((category) => {
      return category.categoryId === mainCategory;
    });
    this.router.navigateByUrl(`/${RIPLEY_POINTS_REDEEM_ROUTE}`);
  }

  public goToRedeem(product: any) {
    const sku = product.sku;
    const code = product.code;
    const category = product.category;
    const confirmation = false;
    const fullName=product.fullName;
    //console.log(product);
    this.router.navigate([`/${RIPLEY_POINTS_GIFTCARD_ROUTE}`, { sku, code, category,fullName }]);

  }

  public getExpirationDate(myCode: any) {
    if (myCode.expirationDate) {
      const currentTimestamp = new Date().getTime();
      let fechaExpiracion = null;
      if (myCode.expirationDate.hasOwnProperty('seconds')) {
        fechaExpiracion = new Date(myCode.expirationDate.toDate());
      } else {
        fechaExpiracion = new Date(myCode.expirationDate);
      }
      let markAsUsed: boolean = myCode.markAsUsed != null ? myCode.markAsUsed : false;
      if (fechaExpiracion.getTime() < currentTimestamp) {
        return "Vencido";
      } else if (markAsUsed) {
        return "Utilizado";
      }
      else {
        const diferenciaMilisegundos = fechaExpiracion.getTime() - currentTimestamp;
        const milisegundosPorMes = 1000 * 60 * 60 * 24 * 30.44; // Promedio de días por mes
        const diferenciaMeses = Math.floor(diferenciaMilisegundos / milisegundosPorMes);
        if (diferenciaMeses == 1) {
          return "Vence en " + diferenciaMeses + " mes";
        } else {
          return "Vence en " + diferenciaMeses + " meses";
        }
      }
    }
    return "";
  }
  public SeleccionarCanje(index: number) {
    this.tabindex = index;
    this.p = 1;
  }
  public async showTceaModal() {
    const signoutModal = await this.modalController.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: TOOLTIP_RIPLEY_POINTS_DASHBOARD_CANJES,
    });
    await signoutModal.present();
    const option = await signoutModal.onDidDismiss();
  }
}
