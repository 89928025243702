import { Injectable } from '@angular/core';
import { IDetailsSavingAccount } from '@common/interfaces/savings.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { IConsolidatedProductListResponse, IProductsDetail } from '../../common/interfaces/product.interface';
import { TypeRuoffers } from '../../common/types/ruoffers.types';

@Injectable({
  providedIn: 'root'
})
export class CardStoreService {

  private dataCrediCard$ = new BehaviorSubject<IProductsDetail>(Object.assign({}));
  private dataSavingCts$ = new BehaviorSubject<IDetailsSavingAccount[]>([]);
  private dataCardSef$ = new BehaviorSubject<IConsolidatedProductListResponse[]>([]);
  private dataRuoffers$ = new BehaviorSubject<TypeRuoffers[]>([]);

  private closeState$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  get getCloseState(): Observable<boolean> {
    return this.closeState$.asObservable();
  }

  get getCardProduct(): Observable<IProductsDetail | {}> {
    return this.dataCrediCard$.asObservable();
  }

  get getSavingCts(): Observable<IDetailsSavingAccount[] | []> {
    return this.dataSavingCts$.asObservable();
  }

  get getCardSef(): Observable<IConsolidatedProductListResponse[] | []> {
    return this.dataCardSef$.asObservable();
  }

  get getRuoffers(): Observable<TypeRuoffers[]> {
    return this.dataRuoffers$.asObservable();
  }

  setCloseState(state: boolean) {
    this.closeState$.next(state);
  }

  setCardProduct(object: IProductsDetail) {
    this.dataCrediCard$.next(object)
  }

  setCardSavingCts(object: IDetailsSavingAccount[]) {
    this.dataSavingCts$.next(object)
  }

  setCardSef(object: IConsolidatedProductListResponse[]) {
    this.dataCardSef$.next(object)
  }

  setRuoffers(object: TypeRuoffers[]) {
    this.dataRuoffers$.next(object)
  }

  hasValue(value: any) {
    return value !== null && value !== undefined;
  }

  getValue<T>(observable: Observable<T>): Promise<T> {
    return observable
      .pipe(
        filter(this.hasValue),
        first()
      )
      .toPromise();
  }


}


