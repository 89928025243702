import { ISegmentSelector } from '@common/interfaces/default.interface';
import { ModalController } from '@ionic/angular';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss'],
})
export class InformationModalComponent {

  @Input() title: string;
  @Input() information: ISegmentSelector[];

  constructor(
    private viewController: ModalController,
  ) {
    this.title =  '';
  }

  dismissModal() {
    this.viewController.dismiss();
  }
}
