import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RPassService } from "@services/rpass/rpass.service";
import { R_PASS_INTRO_ROUTE } from "@common/constants/routes.constants";
import { MODAL_PRIMARY_ACTION } from "@common/constants/misc.constants";
import { ModalController } from "@ionic/angular";
import { USE_RPASS_GA4 } from '@common/constants/digital-auth.constants';
import { GoogleAnalyticsService } from "@services/google-analytics/google-analytics.service";

@Component({
  selector: "app-forgotten-rpass-modal",
  templateUrl: "./forgotten-rpass-modal.component.html",
  styleUrls: ["./forgotten-rpass-modal.component.scss"],
})
export class ForgottenRpassModalComponent implements OnInit {
  public loading: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private rpassService: RPassService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    const { STEP_FORGOT_PAGEVIEW } = USE_RPASS_GA4.MAIN_AUTH;
    this.googleAnalyticsService.pushGAEventTracker(STEP_FORGOT_PAGEVIEW);
  }

  public dismissModal() {
    const { STEP_CANCEL_CLICK } = USE_RPASS_GA4.MAIN_AUTH;
    this.googleAnalyticsService.pushGAEventTracker(STEP_CANCEL_CLICK);
    return this.modalCtrl.dismiss();
  }

  public async goToActivationRpass() {
    const { STEP_FORGOT_CLICK } = USE_RPASS_GA4.MAIN_AUTH;
    this.loading = true;
    await this.rpassService
      .unsubscribeActiveDevice()
      .then((response) => {
        this.modalCtrl.dismiss(MODAL_PRIMARY_ACTION);
        this.googleAnalyticsService.pushGAEventTracker(STEP_FORGOT_CLICK);
        return this.router.navigateByUrl(R_PASS_INTRO_ROUTE);
      })
      .catch((error) => {
        this.modalCtrl.dismiss();
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
