import { Injectable } from '@angular/core';
import { HttpService } from '@services/http/http.service';
import { ILoanLead, ILoanLeadResponse } from '@common/interfaces/loan-lead.interface';
import { environment } from '@environments/environment';
import { LOAN_LEAD_MOCK } from '@common/mocks/loan-lead.mock';
import { StorageService } from '@services/storage/storage.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { USER_INFO } from '@common/constants/auth.constants';
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class LoanLeadService {

  constructor(
    private http: HttpService,
    private storage: StorageService,
    private firebaseService: FirebaseService,
  ) { }

  public async registerLoanLead(loanLead: ILoanLead): Promise<ILoanLeadResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/leadV2';
    const responsePromise = this.http.post(url, loanLead, LOAN_LEAD_MOCK).toPromise();
    const userInfo = await this.storage.get(USER_INFO);
    const dniSearchFormat = userInfo.documentType + '-' + userInfo.documentNumber;
    return responsePromise.then(async (response) => {
      this.firebaseService.addSefEfexServiceResponse(
        {
          userId: dniSearchFormat,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          requestBody: loanLead,
          ...response
        },
        'leadResponses');
      return response;
    }).catch((error) => {
      this.firebaseService.addSefEfexServiceResponse(
        {
          failed: true,
          userId: dniSearchFormat,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          requestBody: loanLead,
          error: error.message
        },
        'leadResponses');
      throw error;
    });
  }
}
