export const CHECK_BLACK_LIST_MOCK = {
  success: [],
  failures: [],
};

export const DATA_CLIENT_MOCK = {
  success: [],
  failures: [],
};

export const REGISTER_PRE_REGISTER_MOCK = {
  success: [],
  failures: [],
};

export const SEND_SMS_MOCK = {
  success: [],
  failures: [],
};

export const VALIDATE_SMS_MOCK = {
  success: [],
  failures: [],
};

export const REGISTER_ACCOUNT_MOCK = {
  success: [],
  failures: [],
};

export const TOKEN_ACCOUNT_MOCK = {
  success: [
    {
      token_type: "Bearer",
      access_token: "AAIgZjIyNTQ2NWIzMzY1ZDdmOGM0MWE3OWQ5NjYyYzEyMjABms0cBRzfLMsVqF5mP__FwHI55GYq2HUHQ754JMpUanCV-mD5Bl3E12-i6LZGjufaw5Z_0kGyiYE5Z6CZziUHe3f9DzHBnE0vbL5cAKcD2ZqExeRbVrRxt95Ch98LnKppq_SRrWM8D8ssbzhVFeyF",
      expires_in: "3600",
      consented_on: "1690931502",
      scope: "scope1"
    }
  ],
  failures: []
};
