import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ripley-card',
  templateUrl: './ripley-card.component.html',
  styleUrls: ['./ripley-card.component.scss'],
})
export class RipleyCardComponent {

  @Input() name: string;
  @Input() descriptionLabel: string;
  @Input() description: string;
  @Input() footerLabel: string;
  @Input() footer: string;
  @Input() cardNumber: string;
  @Input() icon: string;
  @Input() secondaryClass: string;
  @Input() isNotAditional = true;

  constructor() {}

  get isLargeCardNumber() {
    // tslint:disable-next-line: no-magic-numbers
    return this.description && this.description.length > 13 && !this.description.includes('*');
  }

  get nameCard() {
    // tslint:disable-next-line: no-magic-numbers
    return this.name;
  }
}
