import { Observable, from, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import {
  RECAPTCHA_ACTION_KEY, ROUTES_WITHOUT_RECAPTCHA_HEADER
} from '@common/constants/recaptcha.constants';
import { environment } from '@environments/environment';
import { EXTERNAL_ROUTE, RESCUE_SESSION_TOKEN } from '@common/constants/auth.constants';
import { AuthService } from '@services/auth/auth.service';
import { DEFAULT_REQUEST_TIMEOUT } from '@common/constants/misc.constants';
import { FORBIDDEN_STATUS, MOBILE_APP_BACKEND_ERROR_STATUS, RIPLEY_SERVICE_ERROR_STATUS } from '@common/constants/error.constants';
import { IErrorResponse } from '@common/interfaces/error-response.interface';
import { LOGIN_ROUTE } from '@common/constants/routes.constants';

@Injectable()
export class CloudFlareInterceptor implements HttpInterceptor {

  constructor(
    public router: Router,
    private authService: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // servicios apuntan workers
    if (request.url.indexOf(environment.BASE_BACKEND_WORKER) === 0 ) {
      const [clientId, clientSecret] = [environment.CLIENT_ID, environment.CLIENT_SECRET];
      const matches = ROUTES_WITHOUT_RECAPTCHA_HEADER.filter(route => request.url.includes(route));
      if (matches.length > 0) { return next.handle(request); }
      const authToken = btoa(`${clientId}:${clientSecret}`);
      const _token = JSON.parse(sessionStorage.getItem(RESCUE_SESSION_TOKEN));
      let requestClone;
      if (_token !== undefined && _token !== null ) {
        requestClone = request.clone({
          setHeaders: {
            'x-auth-token': `Basic ${authToken}`,
            Authorization: `bearer  ${_token.accessToken}`,
          },
        });
      } else {
        requestClone = request.clone({
          setHeaders: {
            'x-auth-token': `Basic ${authToken}`,
          },
        });
      }
      return next.handle(requestClone);
    } else {
      // servicios apuntan api gateway
      const isExternalRoute = EXTERNAL_ROUTE.filter(route => request.url.includes(route));
      if (isExternalRoute.length === 0) {
        request = request.clone({
          setHeaders: {
            'x-api-key': environment.API_KEY,
          }
        });
      }
      return next.handle(request).pipe(
        timeout(DEFAULT_REQUEST_TIMEOUT),
        map((event) => {
          return event;
        }),
        catchError((error) => {
          return this.handleError(error);
        })
      );

    }
  }  

  private handleError(error: any) {
    if (error.status) {
      switch (error.status) {
        case MOBILE_APP_BACKEND_ERROR_STATUS:
        case RIPLEY_SERVICE_ERROR_STATUS:
          const parsedError = JSON.parse(error.error.messages);
          const newError: IErrorResponse = {
            code: parsedError.Code,
            errorMessage: parsedError.ErrorMessage
          };
          return throwError(newError);
        case FORBIDDEN_STATUS:
          this.authService.signOut().then(() => {
            this.router.navigateByUrl(`/${LOGIN_ROUTE}`, { replaceUrl: true });
          });
          return throwError(newError);
        default:
          return throwError(error.error);
      }
    } else {
      return throwError(error);
    }
  }
}
