export const HEADER_ERROR = 'Lo sentimos';
export const DEFAULT_MSG_ERROR = 'En estos momentos no podemos atender tu solicitud';
export const MOBILE_BREAKPOINT: number = 768;
export const DOCUMENT_NUMBER_DNI_PLACEHOLDER: string = '12345678';
export const DOCUMENT_NUMBER_CE_PLACEHOLDER: string = '123456789';
export const PHONE_NUMBER_PLACEHOLDER: string = '987654321';

export const LOTTIE_CONFIG = {
    path: 'assets/json/loading-spinner-pwa.json',
    autoplay: true,
    loop: true,
    renderer: 'svg',
    sizes: {
        big: {
            height: 120,
            width: 120
        },
        small: {
            height: 94,
            width: 94
        }
    }
};

export const NAVIGATE_TYPE = {
    MODAL: 'M',
    INTERNAL: 'I',
    EXTERNAL: 'E',
  };



export const PLATFORMS = {
    NFC: 'nfc',
    ANDROID: 'android',
    IOS: 'ios',
    WEB: 'web',
    ALL: 'all',
};
      
export const PRIORITY = {
    DEFAULT: 'priority',
    ANDROID: 'priority-android',
    ANDROID_NO_NFC: 'priority-android-sinNFC',
    WEB: 'priority-web',
};
