export const SIMULATOR_MOCK = {
  success: [],
  failures: [],
};

export const CUSTOMER_DATA_LOAN_MOCK = {
  success: [],
  failures: [],
};

export const SMS_DISBURSEMENT_MOCK = {
  success: [],
  failures: [],
};

export const DISBURSEMENT_MOCK = {
  success: [],
  failures: [],
};

export const COMMISIONS_MOCK = {
  success: [],
  failures: [],
};

export const CUSTOMER_AGREEMENT_MOCK = {
  success: [],
  failures: [],
};
