import { Observable, Subscription } from 'rxjs';

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ALREADY_ENROLLED_PROPS, CONTACT_WITH_CALL_CENTER_MODAL_PROPS, NOT_RIPLEY_USER_PROPS
} from '@common/constants/modal-props.constants';
import {
  ENROLL_CHECK_FOR_CARD, ENROLL_FORGOT_PASS, ENROLL_GO_RIPLEY, ENROLL_LOGIN
} from '@common/constants/tag/tag-enrollment.constants';
import { ModalController } from '@ionic/angular';
import { UtilsService } from '@services/utils/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { ICategoryTermsCondition, IModalContentText } from '@common/interfaces/redeem-ripley-points.interface';


@Component({
  selector: 'app-ripley-points-modal',
  templateUrl: './ripley-points-modal.component.html',
  styleUrls: ['./ripley-points-modal.component.scss'],
})
export class RipleyPointsModalComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() message: string;
  @Input() listData: ICategoryTermsCondition[];
  @Input() typeModal: string;
  @Input() primaryButtonText: string;
  @Input() secondaryButtonText: string;
  @Input() icon: string;
  @Input() dynamicSource: Observable<string>;
  @Input() extraClass?: string;
  @Input() showCloseButton: boolean;
  @ViewChild('secondButton') secondButton: ElementRef;
  subscription: Subscription;
  fadeOut: boolean;
  isActiveModal: boolean;
  hide: boolean;
  fromRoute;

  public modalContentToken: IModalContentText[];
  public modalContentTerms: IModalContentText[];
  
  constructor(
    private viewController: ModalController,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.title =  '';
    this.message = '';
    this.listData = [];
    this.typeModal = '';
    this.primaryButtonText = '';
    this.secondaryButtonText = '';
    this.icon = this.icon ? this.icon : 'i-smartphone-purple';
    this.showCloseButton = true;
    this.fromRoute = this.route.snapshot.queryParams;
  }

  ngAfterViewInit(): void {
    if(this.router.url.split('?')[0] === '/login') {
      if(this.secondButton) {
        this.secondButton.nativeElement.hidden = true
      }
    }
  }

  ngOnInit() {
    this.getContentModalToken();
    this.getContentModalTerms();
    this.subscription = new Subscription();
    if (this.dynamicSource) {
      this.subscription.add(
        this.dynamicSource.subscribe((message) => {
          this.message = message;
        })
      );
    }
  }

  dismissModal() {
    this.viewController.dismiss();
    const second = 1000;
    this.fadeOut = true;
    setTimeout(() => {
      this.hide = true;
    }, second);
    this.isActiveModal = false;
  }

  onPrimaryClick() {
    // this.tag();
    this.viewController.dismiss('primaryButtonPressed');
  }

  onSecondaryClick() {
    this.viewController.dismiss('secondaryButtonPressed');
  }

  tag() {
      switch (this.primaryButtonText) {
        case CONTACT_WITH_CALL_CENTER_MODAL_PROPS.primaryButtonText:
          const informAditional: any[] = [];
          informAditional[0] = ENROLL_GO_RIPLEY.info1;
          informAditional[1] = ENROLL_GO_RIPLEY.info2;
          break;
        case NOT_RIPLEY_USER_PROPS.primaryButtonText:
          break;
        case ALREADY_ENROLLED_PROPS.primaryButtonText:
          break;
      }
  }
  tagSecond() {
      switch (this.secondaryButtonText) {
        case ALREADY_ENROLLED_PROPS.secondaryButtonText:
          break;
      }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getContentModalToken(){
    const item_contentList = [  
      {  
        title : '¿Qué es el Token Digital R Pass?',
        text:'El Token Digital R Pass es un método de seguridad que te permitirá autorizar tus transacciones directo desde tu app Banco Ripley.',
      }
    ]
    this.modalContentToken = item_contentList;  
  } 
  getContentModalTerms(){
    // const contentList = [  
    //   {
    //     title : 'Condiciones para uso en Ripley.com',
    //     text:'Agregar el producto a la bolsa de compras, seleccionar continuar, en Medio de Pago, elegir la opción “Tarjetas de crédito, débito y Gift Card Ripley” e ingresar el número de la Gift Card, el mes y año de vencimiento y el CVV. Solo podrás realizar compras con el saldo disponible en tu Gift Card. Si el saldo de la Gift Card es menor al valor del producto elegido, no se podrá culminar la compra ni completarla con otro medio de pago. El cliente puede hacer uso del saldo en una transacción posterior, ya sea en Ripley.com o en tienda física Ripley con las mismas condiciones. En caso el cliente desee devolver por Ripleyfono o Rebeca, un producto comprado en Ripley.com, el monto de la compra será devuelto a la misma Gift Card con la que se adquirió el producto. Para conocer el estado del proceso de devolución, comunicarse al Servicio al cliente de Tiendas Ripley (WhatsApp: 970 311 999, Asistente virtual Rebeca: https://simple.ripley.com.pe/, Correo: servicioalcliente@ripley.com.pe, Libro de reclamaciones físico / virtual)',
    //   },
    //   {
    //     title : 'Condiciones para uso en Tiendas Ripley',
    //     text:'La Gift Card se podrá utilizar en todas las tiendas físicas Ripley a nivel nacional. No válido para compras en Self Checkout y/o Ripley Pago. No son aplicables para la compra de productos vendidos por concesionarios de Ripley, ej. Perfumerías Unidas, OPI, etc. (concesionario: proveedores que promueven y venden sus productos en nuestras tiendas). Para poder usarla se deberá elegir el producto, acercarse a caja y mostrar el código de barra al momento de pagar. Si el saldo de la Gift Card es menor al producto elegido, se puede pagar la diferencia con cualquier medio de pago. En caso se devuelva un producto comprado con Gift Card, se emitirá una nota de crédito a favor del cliente con el monto del producto devuelto. No se aceptan cambios ni devoluciones de la Gift Card.',
    //   },
    //   {
    //     title : 'Condiciones Generales',
    //     text:'La Gift Card es de uso al PORTADOR. La vigencia se reflejará en la misma Gift Card. Al ser la Gift Card un medio de pago, se podrá acceder a las promociones y descuentos en tienda física y Ripley.com, según los términos y condiciones de las ofertas y promociones vigentes al momento de tu compra. No aplican para ofertas y/o promociones exclusivas con Tarjeta Ripley (AON). Compras con Gift Card no pueden acceder a la promoción de despacho Costo S/0. Solo puede utilizarse una Gift Card por compra (no son acumulables). El saldo de la Gift Card Ripley no podrá ser utilizado para realizar pagos de Tarjetas de Crédito del Banco Ripley. El uso de la tarjeta no da derecho ha vuelto ni puede ser canjeada por dinero en efectivo. En caso de pérdida, deterioro, robo, vencimiento o hurto de la Gift Card, comunicarse a: Servicio al cliente de Tiendas Ripley (WhatsApp: 970 311 999, Asistente virtual Rebeca: https://simple.ripley.com.pe/, Correo: servicioalcliente@ripley.com.pe, Libro de reclamaciones físico / virtual) y cumplir con los requerimientos de validación de identidad y compra.',
    //   },
    //   {
    //     title : 'Condiciones del canje de Gift cards',
    //     text:'Canje exclusivo a través de la Banca por Internet de Banco Ripley www.bancoripley.com.pe o App Banco Ripley. Tope máximo de canje de Gift Cards por cliente en el año (enero - diciembre): 1 Gift Card de S/ 30, 1 Gift Card de S/ 100; 1 Gift Card de S/ 350; 1 Gift Card de S/ 1,100 y 1 Gift Card de S/ 2,500. Stock máximo de Gift Cards: 1,000 Gift Cards de S/30; 1,000 Gift Cards de S/100; 200 Gift Cards de S/350; 40 Gift Cards S/1,100; 20 Gift Cards S/ 2,500. Mayor información de las condiciones y uso de Gift cards en www.ripleypuntos.com.pe.',
    //   }
    // ]
    this.modalContentTerms = this.listData;
  } 

}
