
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RPASS_INITIATIVES } from '@common/constants/digital-auth.constants';
import { MODAL_PRIMARY_ACTION } from '@common/constants/misc.constants';
import { HOME_ROUTE } from '@common/constants/routes.constants';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { AskNotificationPermissionListenerService } from '@services/ask-notification-permission-listener/ask-notification-permission-listener.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { NotificationsService } from '@services/notifications/notifications.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ask-notification-permission',
  template: ''
})
export class AskNotificationPermissionComponent {

  public isNotificationEnable: boolean= true;
  isInitiativeOn: boolean= true;
  @Input() validateAtStart: boolean= true;
  @Input() goHomeOnClose: boolean= false;
  @Output() notificationPermissionEmitter: EventEmitter<boolean>= new EventEmitter();
  @Output() notificationPermissionInitiativeEmitter: EventEmitter<boolean>= new EventEmitter();
  @Output() isModalOpenedEmitter: EventEmitter<boolean>= new EventEmitter();
  private askNotificationPermissionListenerSubscription: Subscription;
  private platformSubscription: Subscription;
  private requestedPermissionsFlag: boolean= false;
  constructor(
    private notificationService: NotificationsService,
    private modalController: ModalController,
    private router: Router,
    private diagnostic: Diagnostic,
    private plt: Platform,
    private askNotificationPermissionListener: AskNotificationPermissionListenerService,
    private firebaseService: FirebaseService
  ) {}
  initListeners(){
      this.platformSubscription= this.plt.resume.subscribe(()=>{
        this.validatePermissions(true);
      });
      this.askNotificationPermissionListenerSubscription=
        this.askNotificationPermissionListener.askNotificationEmitter.subscribe(()=>{
        this.openModal();
      });
  }
  async init() {
    this.initListeners();
    await this.validateInitiative();
    await this.validatePermissions();
    if (this.validateAtStart && !this.isNotificationEnable)
      await this.openModal();
  }
  destroy(): void {
    if(this.platformSubscription)
      this.platformSubscription.unsubscribe();
    if(this.askNotificationPermissionListenerSubscription)
        this.askNotificationPermissionListenerSubscription.unsubscribe();
  }
  async validatePermissions(openModal?: boolean){
    if (!this.isInitiativeOn)
      return;
    this.isNotificationEnable= await this.notificationService.hasPermission();
    if ( !this.isNotificationEnable ){
      this.notificationPermissionEmitter.emit(false);
      if ( openModal && (this.requestedPermissionsFlag || this.validateAtStart ) )
        this.openModal();
    } else if ( openModal ){
      this.notificationPermissionEmitter.emit(true);
    }
  }
  async openModal(){
    const isModalOpened= await this.isThisPopUpOpened();
    if (this.isNotificationEnable || isModalOpened)
      return;
    const modal = await this.modalController.create(this.getModalOptions());
    modal.present();
    this.isModalOpenedEmitter.emit(true);
    await modal.onDidDismiss().then(async (resp)=>{
      if (resp.data===MODAL_PRIMARY_ACTION){
        this.requestedPermissionsFlag= true;
        await this.diagnostic.switchToSettings();
      } else if (this.goHomeOnClose){
          this.router.navigateByUrl(HOME_ROUTE, { replaceUrl: true });
      } else{
        this.requestedPermissionsFlag= false;
      }
      this.modalController.dismiss();
      this.isModalOpenedEmitter.emit(false);
    });
  }
  getModalOptions(): any{
    return {
      component: GenericModalComponent,
      cssClass: ['ask-notification-permission-modal'],
      componentProps: {
        message: `<b>Activa tus notificaciones</b>
        <p>Enciende las notificaciones de Banco Ripley y así podrás autorizar tus operaciones bancarias con tu Token Digital.</p>`,
        primaryButtonText: 'Activar',
        icon: 'i-notification-off'
      },
    }
  }
  async validateInitiative(): Promise<void>{
    const validatePermissionInitiativeFlag = !!(await this.firebaseService.getInitiativeRpass(RPASS_INITIATIVES.VALIDAR_PERMISO_NOTIFICACION)).enabled;
    this.notificationPermissionInitiativeEmitter.emit(validatePermissionInitiativeFlag);
    this.isInitiativeOn= validatePermissionInitiativeFlag;
  }
  async isThisPopUpOpened(): Promise<boolean>{
    const getTop= await this.modalController.getTop();
    if ( getTop===undefined )
      return false;
    return getTop.className.includes('ask-notification-permission-modal');
  }
}