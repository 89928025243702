import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RECAPTCHA_ERROR_CODE } from '@common/constants/error.constants';
import { RECAPTCHA_MODAL_PROPS } from '@common/constants/modal-props.constants';
import {
  AMOUNT_LESS_THAN_FIVEHUNDRED,
  AMOUNT_LESS_THAN_FIVEHUNDRED_PROPS,
  ERROR_FOR_PAY,
  ERROR_FOR_PAY_PROPS,
  ERROR_QUESTION_REGISTER,
  ERROR_QUESTION_REGISTER_PROPS,
  ID_QUESTIONS_NOT_FOUND,
  ID_QUESTIONS_NOT_FOUND_PROPS,
  RESCHEDULING_CODE_PRODUCT,
  RESCHEDULING_GENERAL_ERROR_PROPS,
  RESCHEDULING_SCHEDULE,
  RESCHEDULING_SCHEDULE_PROPS,
  RESCHELUDING_WITHOUT_SALARY_PROPS,
  RESCHEDULING_SEF_CODE_PRODUCT,
  TC_NO_ASSOCIATED,
  TC_NO_ASSOCIATED_PROPS,
  TC_UNREGISTERED,
  TC_UNREGISTERED_PROPS
} from '@common/constants/rescheduling.constans';
import { HOME_ROUTE } from '@common/constants/routes.constants';
import { GTM_STEPS } from '@common/constants/tag/tag-rescheduling.constanst';
import {
  IQualificationResponse,
  IQuestionsOptions,
  IReschedulingConsultRequest,
  IReschedulingConsultResponse,
  IReschedulingQuestionsRequest,
  IReschedulingQuestionsResponse
} from '@common/interfaces/rescheduling';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { ModalController } from '@ionic/angular';
import { BannerService } from '@services/banner/banner.service';
import { ReschedulingService } from '@services/rescheduling/rescheduling.service';


import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RuoffersService } from '@services/ruoffers/ruoffers.service';
import { TypeRuoffers } from '@common/types/ruoffers.types';

const NO_EARNINGS_CODE = 20;

@Component({
  selector: 'app-reprogramming-questions',
  templateUrl: './reprogramming-questions.component.html',
  styleUrls: ['./reprogramming-questions.component.scss'],
})
export class ReprogrammingQuestionsComponent implements OnInit, OnDestroy {

  questionsList: IReschedulingQuestionsResponse;
  earningOptions: IQuestionsOptions[];
  reasonOptions: IQuestionsOptions[];
  questions: FormGroup;
  isLoading = true;
  isConsultLoading: boolean;
  subscriptionQuestionOne: Subscription;
  subscriptionQuestionTwo: Subscription;
  @Output() questionsValidation: EventEmitter<IReschedulingConsultResponse>;
  private destroyed$ = new Subject();
  dataRuoffers: TypeRuoffers[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private service: ReschedulingService,
    private modalCtrl: ModalController,
    private bannerService: BannerService,
    private ruofferService: RuoffersService
    ) {
    this.questionsValidation = new EventEmitter();
    this.earningOptions = [];
    this.reasonOptions = [];
  }

  async ngOnInit() {
    const requestRuoffer = await this.ruofferService.getRuoOffers().pipe(takeUntil(this.destroyed$)).toPromise();
    this.dataRuoffers = requestRuoffer.filter((x: TypeRuoffers) => x.codeProduct === RESCHEDULING_SEF_CODE_PRODUCT || x.codeProduct === RESCHEDULING_CODE_PRODUCT);
    this.questions = this.formBuilder.group({
      question1: ['', Validators.required],
      question2: ['', Validators.required]
    });
    this.subscriptionQuestionOne = this.question1.valueChanges.subscribe((s) => {
      if (s.sequenceNumber === this.questionsList.questions[0].options.length) {
      }
    });
    this.subscriptionQuestionTwo = this.question2.valueChanges.subscribe((s) => {
      if (s.sequenceNumber === this.questionsList.questions[1].options.length) {
      }
    });
    this.getQuestions();
  }

  async getQuestions() {
    try {
      const request: IReschedulingQuestionsRequest = {
        customerOfferReference: this.dataRuoffers[0].codeCampaign,
      };
      this.questionsList = await this.service.getReschedulingQuestions(request);
    } catch (e) {
      let props;
      switch (e.code) {
        case RECAPTCHA_ERROR_CODE:
          props = RECAPTCHA_MODAL_PROPS;
          break;
        case RESCHEDULING_SCHEDULE:
          props = RESCHEDULING_SCHEDULE_PROPS;
          break;
        default:
          props = RESCHEDULING_GENERAL_ERROR_PROPS;
          break;
      }
      const modal = await this.modalCtrl.create({
        component: GenericModalComponent,
        cssClass: 'form-modal',
        componentProps: props
      });
      await modal.present();
      await modal.onDidDismiss().then(() => {
        return this.router.navigate([HOME_ROUTE], { replaceUrl: true });
      });
    } finally {
      this.isLoading = false;
    }
  }

  get question1() { return this.questions.controls.question1; }
  get question2() { return this.questions.controls.question2; }
  get valid() { return this.question1.valid && this.question2.valid; }

  async reschedulingConsult() {
    try {
      this.isConsultLoading = true;
      const answersList: IQualificationResponse[] = [
        {
          questionId: this.questionsList.questions[0].questionId,
          optionId: this.question1.value.optionId,
          optionValue: this.question1.value.value,
          optionDetailDescription: ''
        }, {
          questionId: this.questionsList.questions[1].questionId,
          optionId: this.question2.value.optionId,
          optionValue: this.question2.value.value,
          optionDetailDescription: ''
        }
      ];

      const consultReschedulingRequest: IReschedulingConsultRequest = {
        customerOfferReference: this.dataRuoffers[0].codeCampaign,
        qualificationData: {
          qualificationId: this.questionsList.id,
          qualificationResponse: answersList
        }
      };

      const response = await this.service.getReschedulingConsult(consultReschedulingRequest);
      switch (response.code) {
        case 0:
          this.questionsValidation.emit(response);
          break;
        case NO_EARNINGS_CODE:
          this.showNoEarningsModal();
          break;
        default:
          this.showErrorModal(response);
          break;
      }
    } catch (e) {
      this.showErrorModal(e);
    } finally {
      this.isConsultLoading = false;
    }
  }

  async showNoEarningsModal() {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal cancel-reprogramming with-out-title',
      componentProps: RESCHELUDING_WITHOUT_SALARY_PROPS
    });
    await modal.present();
    await modal.onDidDismiss().then(() => {
      return this.router.navigateByUrl(`/${HOME_ROUTE}`);
    });
  }

  async showErrorModal(e) {
    let props;
    switch (e.code) {
      case RECAPTCHA_ERROR_CODE:
        props = RECAPTCHA_MODAL_PROPS;
        break;
      case ERROR_QUESTION_REGISTER:
        props = ERROR_QUESTION_REGISTER_PROPS;
        break;
      case ID_QUESTIONS_NOT_FOUND:
        props = ID_QUESTIONS_NOT_FOUND_PROPS;
        break;
      case TC_NO_ASSOCIATED:
        props = TC_NO_ASSOCIATED_PROPS;
        break;
      case TC_UNREGISTERED:
        props = TC_UNREGISTERED_PROPS;
        break;
      case ERROR_FOR_PAY:
        props = ERROR_FOR_PAY_PROPS;
        break;
      case AMOUNT_LESS_THAN_FIVEHUNDRED:
        props = AMOUNT_LESS_THAN_FIVEHUNDRED_PROPS;
        break;
      case RESCHEDULING_SCHEDULE:
        props = RESCHEDULING_SCHEDULE_PROPS;
        break;
      default:
        props = RESCHEDULING_GENERAL_ERROR_PROPS;
        break;
    }
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal cancel-reprogramming',
      componentProps: props
    });
    await modal.present();
    await modal.onDidDismiss().then(() => {
      if (e.code == RESCHEDULING_SCHEDULE) {
        return this.router.navigate([HOME_ROUTE], { replaceUrl: true });
      }
    });
  }

  cancel() {
    this.router.navigateByUrl(HOME_ROUTE);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.subscriptionQuestionOne.unsubscribe();
    this.subscriptionQuestionTwo.unsubscribe();
  }
}
