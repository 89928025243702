import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
})
export class CopyButtonComponent {

  @Input() textToCopy: string;
  @Input() icon = 'fill-container';
  @Input() customColor = 'i-copy-content-white';
  @Input() textLabel = 'Copiar datos';

  tooltipOptions = {
    trigger: 'click',
    placement: 'bottom',
    theme: 'light'
  };
  constructor() { }

  copyToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.textToCopy;
    document.body.appendChild(selBox);
    selBox.select();
    selBox.focus();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
