import { Injectable } from '@angular/core';
import {
  IDataClientInput,
  IGenericOpenAccountOutput,
  ILogOpenAccount,
  ILogsParameters,
  IPreRegisterSave,
  IRegisterAccount,
  IRegisterAccountOutput,
  IResponseDataClient,
  ISendSmsOutput,
  ITokenOpenAccount,
  IValidateSms
} from '@common/interfaces/open-account.interface';
import {
  CHECK_BLACK_LIST_MOCK,
  DATA_CLIENT_MOCK,
  REGISTER_ACCOUNT_MOCK,
  REGISTER_PRE_REGISTER_MOCK,
  SEND_SMS_MOCK,
  TOKEN_ACCOUNT_MOCK,
  VALIDATE_SMS_MOCK
} from '@common/mocks/open-account.mocks';
import { environment } from '@environments/environment';
import { HttpService } from '@services/http/http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DeviceService } from '@services/device/device.service';
import { UtilsService } from '@services/utils/utils';
import { REASON_LOGS, STORAGE_LOG_OA } from "@common/constants/open-account.constants";
import { Storage } from "@ionic/storage";

@Injectable({
    providedIn: 'root'
})
export class OpenAccountService {
  blackList: Promise<any>;
  constructor(
    private http: HttpService,
    private httpClient: HttpClient,
    private deviceService: DeviceService,
    private storage: Storage,
    private utilsService: UtilsService,
  ) { }
  public async checkBlackList(): Promise<any> {
      const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/openAccount/checkBlackList';
      let headers = new HttpHeaders();
      headers = headers.set('Channel', this.utilsService.getChannelCode());

      const datosMonitor = {
        captchaResponse: '',
        monitorInput: {
          url: window.location.host === undefined ? '' : window.location.host ,
          device: this.deviceService.getWildField1() === undefined ? '' : this.deviceService.getWildField1(),
          firmware: this.deviceService.getPlatformName() === undefined ? '' : this.deviceService.getPlatformName(),
          docName: window.location.pathname === undefined ? '' : window.location.pathname,
          cpuAbi: '',
          finger: '0',
          mobileNumber: '',
          hostIP: (await (this.deviceService.getIP())).ip === undefined ? '' : (await (this.deviceService.getIP())).ip,
          navegadorIdenticator: this.deviceService.getNavegadorIdentifier() === undefined ? 1 : this.deviceService.getNavegadorIdentifier(),
          deviceVersion: this.deviceService.getDeviceVersion() === undefined ? '' : this.deviceService.getDeviceVersion() ,
          deviceId: this.deviceService.getDeviceId() === undefined ? '' : this.deviceService.getDeviceId() ,
          deviceName: this.deviceService.getDeviceManufacturer() === undefined ? '' : this.deviceService.getDeviceManufacturer(),
          deviceModel: this.deviceService.getDevice() === undefined ? '' : this.deviceService.getDevice(),
          imei: this.deviceService.getImei() === undefined ? '' : this.deviceService.getImei(),
          numberHardwareProcessor: this.deviceService.getNumberHardwareProcessor() === undefined ? '' :
            this.deviceService.getNumberHardwareProcessor(),
          memoryRam: this.deviceService.getNumberMemoryRam() === undefined ? '' : this.deviceService.getNumberMemoryRam(),
          videoCardVendor: this.deviceService.getVideoCardVendor() === undefined ? '' :  this.deviceService.getVideoCardVendor(),
          videoCardRenderer: this.deviceService.getVideoCardRenderer() === undefined ? '' : this.deviceService.getVideoCardRenderer(),
          country: await this.deviceService.getCountry() === undefined ? '' : await this.deviceService.getCountry(),
          city: await this.deviceService.getCity() === undefined ? '' : await this.deviceService.getCity(),
          isp: await this.deviceService.getISP() === undefined ? '' : await this.deviceService.getISP(),
          macAddress: this.deviceService.getMacAddress() === undefined ? '' : this.deviceService.getMacAddress(),
          navegadorVersion: this.deviceService.getNavegadorVersion() === undefined
            ? '0' : this.deviceService.getNavegadorVersion(),
          navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor() === undefined ||
          this.deviceService.getNavegadorVersionMajor() === '' ? '0'
            : this.deviceService.getNavegadorVersionMajor(),
          engineName: this.deviceService.getEngineName() === undefined ? '' : this.deviceService.getEngineName(),
          engineVersion: this.deviceService.getEngineVersion() === undefined ? '' : this.deviceService.getEngineVersion(),
          terminalID: this.deviceService.getWildField1() === undefined ? '' : this.deviceService.getWildField1(),
          language: navigator.language === undefined ? '' : navigator.language
        }
      };
      if (this.blackList) {
          return this.blackList;
      }
    this.blackList = this.http.post(url, datosMonitor, CHECK_BLACK_LIST_MOCK, headers).toPromise();
      return this.blackList;
  }
  public resetVars() {
    this.blackList = null;
  }
  public async getDataClient(dataClientInput: IDataClientInput): Promise<IResponseDataClient> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/openAccount/getDataClient';
    let headers = new HttpHeaders();
    headers = headers.set('Channel', this.utilsService.getChannelCode());
    dataClientInput.monitorInput = {
      url: window.location.host === undefined ? '' : window.location.host ,
      device: this.deviceService.getWildField1() === undefined ? '' : this.deviceService.getWildField1(),
      firmware: this.deviceService.getPlatformName() === undefined ? '' : this.deviceService.getPlatformName(),
      docName: window.location.pathname === undefined ? '' : window.location.pathname,
      cpuAbi: '',
      finger: '0',
      mobileNumber: '',
      hostIP: (await (this.deviceService.getIP())).ip === undefined ? '' : (await (this.deviceService.getIP())).ip,
      navegadorIdenticator: this.deviceService.getNavegadorIdentifier() === undefined ? '' : this.deviceService.getNavegadorIdentifier(),
      deviceVersion: this.deviceService.getDeviceVersion() === undefined ? '' : this.deviceService.getDeviceVersion() ,
      deviceId: this.deviceService.getDeviceId() === undefined ? '' : this.deviceService.getDeviceId() ,
      deviceName: this.deviceService.getDeviceManufacturer() === undefined ? '' : this.deviceService.getDeviceManufacturer(),
      deviceModel: this.deviceService.getDevice() === undefined ? '' : this.deviceService.getDevice(),
      imei: this.deviceService.getImei() === undefined ? '' : this.deviceService.getImei(),
      numberHardwareProcessor: this.deviceService.getNumberHardwareProcessor() === undefined ? 1 :
        this.deviceService.getNumberHardwareProcessor(),
      memoryRam: this.deviceService.getNumberMemoryRam() === undefined ? '' : this.deviceService.getNumberMemoryRam(),
      videoCardVendor: this.deviceService.getVideoCardVendor() === undefined ? '' :  this.deviceService.getVideoCardVendor(),
      videoCardRenderer: this.deviceService.getVideoCardRenderer() === undefined ? '' : this.deviceService.getVideoCardRenderer(),
      country: await this.deviceService.getCountry() === undefined ? '' : await this.deviceService.getCountry(),
      city: await this.deviceService.getCity() === undefined ? '' : await this.deviceService.getCity(),
      isp: await this.deviceService.getISP() === undefined ? '' : await this.deviceService.getISP(),
      macAddress: this.deviceService.getMacAddress() === undefined ? '' : this.deviceService.getMacAddress(),
      navegadorVersion: this.deviceService.getNavegadorVersion() === undefined
        ? '0' : this.deviceService.getNavegadorVersion(),
      navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor() === undefined ||
      this.deviceService.getNavegadorVersionMajor() === '' ? '0'
        : this.deviceService.getNavegadorVersionMajor(),
      engineName: this.deviceService.getEngineName() === undefined ? '' : this.deviceService.getEngineName(),
      engineVersion: this.deviceService.getEngineVersion() === undefined ? '' : this.deviceService.getEngineVersion(),
      terminalID: this.deviceService.getWildField1() === undefined ? '' : this.deviceService.getWildField1(),
      language: navigator.language === undefined ? '' : navigator.language
    };
    return this.http.post(url, dataClientInput, DATA_CLIENT_MOCK, headers).toPromise();
  }
  // public async getDataClient(): Promise<IDataClient> {
  //  const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/openAccount/getDataClient';
  //  return this.http.post(url, null, DATA_CLIENT_MOCK).toPromise();
  // }
  public async preRegister(preRegisterSave: IPreRegisterSave): Promise<IGenericOpenAccountOutput> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/openAccount/pre-register-account';
    let headers = new HttpHeaders();
    headers = headers.set('Channel', this.utilsService.getChannelCode());
    return this.http.post(url, preRegisterSave, REGISTER_PRE_REGISTER_MOCK, headers).toPromise();
  }
  public async logOpenAccount(parameters: ILogsParameters): Promise<IGenericOpenAccountOutput> {
    const requestLog: ILogOpenAccount = {
      holdImportantPublicOfficeNacInt: true,
      indicatorAcceptanceSendingDocumentsEmail: true,
      indicatorPrivacyPolicy: true,
      taxResidenceOnlyPeru: true,
      indicatorTermsConditions: true,
      isValWidget: false,
      reference: '',
      utmSource: '',
      utmMedium: '',
      utmCampaing: '',
      utmContent: '',
      productIdentification: parameters.typeAccount,
      currency: 'PEN',
      pinSMS: '',
      stepIdentification: parameters.stepIdentification,
      reasonIdentification: parameters.reasonIdentification
    };
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/openAccount/log';

    await this.http.post(url, requestLog, REGISTER_ACCOUNT_MOCK).toPromise().then(()=>{
      if (parameters.reasonIdentification != REASON_LOGS.PRE_REGISTER){
        this.storage.set(STORAGE_LOG_OA.CODE_OPEN_ACCOUNT, '');
        this.storage.set(STORAGE_LOG_OA.STEP_OPEN_ACCOUNT, '');
      }
    });
    return null
  }
  public async sendSms(): Promise<ISendSmsOutput> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/openAccount/send-sms';
    let headers = new HttpHeaders();
    headers = headers.set('Channel', this.utilsService.getChannelCode());
    return this.http.post(url, null, SEND_SMS_MOCK, headers).toPromise();
  }
  public async cancelSms(): Promise<ISendSmsOutput> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/openAccount/cancel-sms';
    let headers = new HttpHeaders();
    const datosMonitor = {
      captchaResponse: '',
      monitorInput : {
        url: window.location.host === undefined ? '' : window.location.host ,
        device: this.deviceService.getWildField1() === undefined ? '' : this.deviceService.getWildField1(),
        firmware: this.deviceService.getPlatformName() === undefined ? '' : this.deviceService.getPlatformName(),
        docName: window.location.pathname === undefined ? '' : window.location.pathname,
        cpuAbi: '',
        finger: '0',
        mobileNumber: '',
        hostIP: (await (this.deviceService.getIP())).ip === undefined ? '' : (await (this.deviceService.getIP())).ip,
        navegadorIdenticator: this.deviceService.getNavegadorIdentifier() === undefined ? '' : this.deviceService.getNavegadorIdentifier(),
        deviceVersion: this.deviceService.getDeviceVersion() === undefined ? '' : this.deviceService.getDeviceVersion() ,
        deviceId: this.deviceService.getDeviceId() === undefined ? '' : this.deviceService.getDeviceId() ,
        deviceName: this.deviceService.getDeviceManufacturer() === undefined ? '' : this.deviceService.getDeviceManufacturer(),
        deviceModel: this.deviceService.getDevice() === undefined ? '' : this.deviceService.getDevice(),
        imei: this.deviceService.getImei() === undefined ? '' : this.deviceService.getImei(),
        numberHardwareProcessor: this.deviceService.getNumberHardwareProcessor() === undefined ? '' :
          this.deviceService.getNumberHardwareProcessor(),
        memoryRam: this.deviceService.getNumberMemoryRam() === undefined ? '' : this.deviceService.getNumberMemoryRam(),
        videoCardVendor: this.deviceService.getVideoCardVendor() === undefined ? '' :  this.deviceService.getVideoCardVendor(),
        videoCardRenderer: this.deviceService.getVideoCardRenderer() === undefined ? '' : this.deviceService.getVideoCardRenderer(),
        country: await this.deviceService.getCountry() === undefined ? '' : await this.deviceService.getCountry(),
        city: await this.deviceService.getCity() === undefined ? '' : await this.deviceService.getCity(),
        isp: await this.deviceService.getISP() === undefined ? '' : await this.deviceService.getISP(),
        macAddress: this.deviceService.getMacAddress() === undefined ? '' : this.deviceService.getMacAddress(),
        navegadorVersion: this.deviceService.getNavegadorVersion() === undefined
          ? '0' : this.deviceService.getNavegadorVersion(),
        navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor() === undefined ||
          this.deviceService.getNavegadorVersionMajor() === '' ? '0'
          : this.deviceService.getNavegadorVersionMajor(),
        engineName: this.deviceService.getEngineName() === undefined ? '' : this.deviceService.getEngineName(),
        engineVersion: this.deviceService.getEngineVersion() === undefined ? '' : this.deviceService.getEngineVersion(),
        terminalID: this.deviceService.getWildField1() === undefined ? '' : this.deviceService.getWildField1(),
        language: navigator.language === undefined ? '' : navigator.language
      }
    };
    headers = headers.set('Channel', this.utilsService.getChannelCode());
    return this.http.post(url, datosMonitor, SEND_SMS_MOCK, headers).toPromise();
  }
  public async errorRpass(code: string): Promise<ISendSmsOutput> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/openAccount/register-error-rpass';
    let headers = new HttpHeaders();
    const datosMonitor = {
      captchaResponse: '',
      monitorInput : {
        url: window.location.host === undefined ? '' : window.location.host ,
        device: this.deviceService.getWildField1() === undefined ? '' : this.deviceService.getWildField1(),
        firmware: this.deviceService.getPlatformName() === undefined ? '' : this.deviceService.getPlatformName(),
        docName: window.location.pathname === undefined ? '' : window.location.pathname,
        cpuAbi: '',
        finger: '0',
        mobileNumber: '',
        hostIP: (await (this.deviceService.getIP())).ip === undefined ? '' : (await (this.deviceService.getIP())).ip,
        navegadorIdenticator: this.deviceService.getNavegadorIdentifier() === undefined ? '' : this.deviceService.getNavegadorIdentifier(),
        deviceVersion: this.deviceService.getDeviceVersion() === undefined ? '' : this.deviceService.getDeviceVersion() ,
        deviceId: this.deviceService.getDeviceId() === undefined ? '' : this.deviceService.getDeviceId() ,
        deviceName: this.deviceService.getDeviceManufacturer() === undefined ? '' : this.deviceService.getDeviceManufacturer(),
        deviceModel: this.deviceService.getDevice() === undefined ? '' : this.deviceService.getDevice(),
        imei: this.deviceService.getImei() === undefined ? '' : this.deviceService.getImei(),
        numberHardwareProcessor: this.deviceService.getNumberHardwareProcessor() === undefined ? '' :
          this.deviceService.getNumberHardwareProcessor(),
        memoryRam: this.deviceService.getNumberMemoryRam() === undefined ? '' : this.deviceService.getNumberMemoryRam(),
        videoCardVendor: this.deviceService.getVideoCardVendor() === undefined ? '' :  this.deviceService.getVideoCardVendor(),
        videoCardRenderer: this.deviceService.getVideoCardRenderer() === undefined ? '' : this.deviceService.getVideoCardRenderer(),
        country: await this.deviceService.getCountry() === undefined ? '' : await this.deviceService.getCountry(),
        city: await this.deviceService.getCity() === undefined ? '' : await this.deviceService.getCity(),
        isp: await this.deviceService.getISP() === undefined ? '' : await this.deviceService.getISP(),
        macAddress: this.deviceService.getMacAddress() === undefined ? '' : this.deviceService.getMacAddress(),
        navegadorVersion: this.deviceService.getNavegadorVersion() === undefined
          ? '0' : this.deviceService.getNavegadorVersion(),
        navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor() === undefined ||
        this.deviceService.getNavegadorVersionMajor() === '' ? '0'
          : this.deviceService.getNavegadorVersionMajor(),
        engineName: this.deviceService.getEngineName() === undefined ? '' : this.deviceService.getEngineName(),
        engineVersion: this.deviceService.getEngineVersion() === undefined ? '' : this.deviceService.getEngineVersion(),
        terminalID: this.deviceService.getWildField1() === undefined ? '' : this.deviceService.getWildField1(),
        language: navigator.language === undefined ? '' : navigator.language,
        code: Number(code)
      }
    };
    headers = headers.set('Channel', this.utilsService.getChannelCode());
    return this.http.post(url, datosMonitor, SEND_SMS_MOCK, headers).toPromise();
  }
  public async validateSms(validateSms: IValidateSms): Promise<IGenericOpenAccountOutput> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/openAccount/validate-sms';
    return this.http.post(url, validateSms, VALIDATE_SMS_MOCK).toPromise();
  }
  public async registerAccount(registerAccount: IRegisterAccount): Promise<IRegisterAccountOutput> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/openAccount/register-account';
    let headers = new HttpHeaders();
    headers = headers.set('Channel', this.utilsService.getChannelCode());
    registerAccount.monitorInput = {
      url: window.location.host === undefined ? '' : window.location.host ,
      device: this.deviceService.getWildField1() === undefined ? '' : this.deviceService.getWildField1(),
      firmware: this.deviceService.getPlatformName() === undefined ? '' : this.deviceService.getPlatformName(),
      docName: window.location.pathname === undefined ? '' : window.location.pathname,
      cpuAbi: '',
      finger: '0',
      mobileNumber: '',
      hostIP: (await (this.deviceService.getIP())).ip === undefined ? '' : (await (this.deviceService.getIP())).ip,
      navegadorIdenticator: this.deviceService.getNavegadorIdentifier() === undefined ? '' : this.deviceService.getNavegadorIdentifier(),
      deviceVersion: this.deviceService.getDeviceVersion() === undefined ? '' : this.deviceService.getDeviceVersion() ,
      deviceId: this.deviceService.getDeviceId() === undefined ? '' : this.deviceService.getDeviceId() ,
      deviceName: this.deviceService.getDeviceManufacturer() === undefined ? '' : this.deviceService.getDeviceManufacturer(),
      deviceModel: this.deviceService.getDevice() === undefined ? '' : this.deviceService.getDevice(),
      imei: this.deviceService.getImei() === undefined ? '' : this.deviceService.getImei(),
      numberHardwareProcessor: this.deviceService.getNumberHardwareProcessor() === undefined ? 0 :
        this.deviceService.getNumberHardwareProcessor(),
      memoryRam: this.deviceService.getNumberMemoryRam() === undefined ? '' : this.deviceService.getNumberMemoryRam(),
      videoCardVendor: this.deviceService.getVideoCardVendor() === undefined ? '' :  this.deviceService.getVideoCardVendor(),
      videoCardRenderer: this.deviceService.getVideoCardRenderer() === undefined ? '' : this.deviceService.getVideoCardRenderer(),
      country: await this.deviceService.getCountry() === undefined ? '' : await this.deviceService.getCountry(),
      city: await this.deviceService.getCity() === undefined ? '' : await this.deviceService.getCity(),
      isp: await this.deviceService.getISP() === undefined ? '' : await this.deviceService.getISP(),
      macAddress: this.deviceService.getMacAddress() === undefined ? '' : this.deviceService.getMacAddress(),
      navegadorVersion: this.deviceService.getNavegadorVersion() === undefined
        ? '0' : this.deviceService.getNavegadorVersion(),
      navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor() === undefined ||
      this.deviceService.getNavegadorVersionMajor() === '' ? '0'
        : this.deviceService.getNavegadorVersionMajor(),
      engineName: this.deviceService.getEngineName() === undefined ? '' : this.deviceService.getEngineName(),
      engineVersion: this.deviceService.getEngineVersion() === undefined ? '' : this.deviceService.getEngineVersion(),
      terminalID: this.deviceService.getWildField1() === undefined ? '' : this.deviceService.getWildField1(),
      language: navigator.language === undefined ? '' : navigator.language
    };
    return this.http.post(url, registerAccount, REGISTER_ACCOUNT_MOCK, headers).toPromise();
  }

  public async getWidgetToken(sessionId:string):Promise<ITokenOpenAccount>{
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/passive/openAccount/widget-token';
    const response: ITokenOpenAccount = await this.http.post(url, { sessionId, captchaResponse: '' }, TOKEN_ACCOUNT_MOCK).toPromise();
    return response;
  }
}
