import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-bottom-sheet",
  templateUrl: "./bottom-sheet.component.html",
  styleUrls: ["./bottom-sheet.component.scss"],
})
export class BottomSheetComponent implements OnInit {
  
  message?: string;
  @Input() title?: string;
  @Input() buttonText?: string;
  @Input() paymentInfo?: any;
  constructor(
    private viewController: ModalController
  ) {}

  ngOnInit() { 
    this.message = this.createPaymentSummary();
  }

  createPaymentSummary(): string {
    return `
      <div class="payment-bs-summary">
        <div class="first-column">Método de pago:</div>
        <div class="second-column">${this.paymentInfo.productName}</div>
        <div class="first-column">Producto a pagar:</div>
        <div class="second-column">${this.paymentInfo.description}</div>
        <div class="first-column bold">Monto a pagar:</div>
        <div class="second-column bold">${this.paymentInfo.totalAmount}</div>
      </div>
    `;
  }

  buttonPressed() {
    this.viewController.dismiss(this.buttonText);
  }

  closeModal() {
    this.viewController.dismiss();
  }
}
