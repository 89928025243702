import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IMovementSavingAccount } from '@common/interfaces/savings.interface';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-savings-modal',
  templateUrl: './savings-modal.component.html',
  styleUrls: ['./savings-modal.component.scss'],
})
export class SavingsModalComponent {
  @Input() title: string;
  @Input() movement: IMovementSavingAccount;
  @Input() account: string;
  @Input() primaryButtonText: string;
  constructor(
    private modalController: ModalController,
  ) { }

  dismissModal() {
    this.modalController.dismiss();
  }

  onPrimaryClick() {
    this.modalController.dismiss('primaryButtonPressed');
  }

  textToClipboard(data: IMovementSavingAccount) {
    const datePipe = new DatePipe('en-US');
    return this.account + '\n'
      + data.description + '\n' + data.amount + '\n' + data.operationDate;
  }
}
