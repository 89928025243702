export const LOAN_PAYMENT_DATA = {
  success: [],
  failures: [],
};

export const QUOTA_PENDING_DATA = {
  success: [],
  failures: [],
};

export const VALIDATE_NIUBIZ_PROCESS = {
  success: [],
  failures: [],
};

export const VALIDATE_PAYMENT_PROCESS = {
  success: [],
  failures: [],
};
