import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FirebaseService } from '@services/firebase/firebase.service';
import { UtilsService } from '@services/utils/utils';
import { Subject } from 'rxjs';
import { CardStoreService } from '@services/card-store/card-store.service';
import { Market } from '@ionic-native/market/ngx';

@Component({
  selector: 'page-welcome-campaign',
  templateUrl: 'welcome-campaign.component.html',
  styleUrls: ['./welcome-campaign.component.scss'],
})

export class WelcomeCampaignComponent implements OnInit {
  public static readonly pageName = 'WelcomeCampaignComponent';
  @Input() public text? = '';
  @Input() public campaigns?: any;
  @Input() public campaignsUserDataRef?: any;
  @Input() public campaignsUserData?: any;
  @Input() public user? = '';
  @Input() public desktopMode? = false;
  @ViewChild('slides') public slides?: ElementRef;

  public loading: boolean;
  public completeAction: boolean;
  public activeIndex: number;
  public screens: any[];
  public nextButtonText: string;
  public pager: { next: string, done: string };
  public slideOptions: any;
  public goalIndexReached = false;
  public desktopView: boolean = false;
  private close = new Subject<boolean>();

  constructor(
    private storeService: CardStoreService,
    private viewController?: ModalController,
    private firebase?: FirebaseService,
    private utilsService?: UtilsService,
    private market?: Market,
  ) {
    this.screens = [];
    this.loading = true;
    this.completeAction = false;
    this.activeIndex = 0;
    this.close.next(false);
  }

  public async ngOnInit() {
    this.desktopView = this.utilsService.desktop;
    this.slideOptions = { allowTouchMove: true, zoom: { toggle: false } };
    await this.getScreens();
    this.pager = {
      next: 'Siguiente',
      done: 'Listo'
    };
    this.nextButtonText = this.screens.length > 1 ? this.pager.next : this.pager.done;
    this.loading = false;
    this.activeIndex = await this.slides.nativeElement.getActiveIndex(); 
    if (this.activeIndex == 0) {
      this.addCount('views', this.activeIndex);
    }
    this.lockSwipes(true);
  }

  public async triggerSlide(direction: 'prev' | 'next') {
    if (this.nextButtonText === this.pager.done && await this.isFinalScreenShowing() && direction === 'next') {
      this.slidePage('out');
      this.completeAction = true;
    } else {
      this.slidePage(direction);
    }
  }

  public isFinalScreen(index: number) {
    return index === this.screens.length - 1;
  }

  public async goTo(pageAndParams) {
    if (await this.isFinalScreenShowing()) {
      this.completeAction = true;
    }
    this.dismiss(pageAndParams);
  }

  public async onChangeSlide() {
    this.changeNextButtonText();
    this.activeIndex = await this.slides.nativeElement.getActiveIndex();  
    if (this.activeIndex > 0) {
      this.addCount('views', this.activeIndex);
    }
  }

  public isSlideActive(index: number) {
    return index === this.activeIndex;
  }

  public async exit() {
    if (await this.isFinalScreenShowing()) {
      this.completeAction = true;
    }
    this.dismiss();
  }

  public async changeNextButtonText() {
    if (await this.isFinalScreenShowing()) {
      this.nextButtonText = this.pager.done;
    } else {
      this.nextButtonText = this.pager.next;
    }
  }

  public async dismiss(pageAndParams?: any) {
    if (!!pageAndParams) {
      if (pageAndParams.page != '/home' &&  pageAndParams.params != 'store') {
        this.activeIndex = await this.slides.nativeElement.getActiveIndex();  
        this.addCount('goals', this.activeIndex);
        this.viewController.dismiss(pageAndParams);
      } else{
        if(this.utilsService.isNative()){
          const storeUrl = this.utilsService.getNativeStoreUrl();
          this.market.open(storeUrl);
          this.activeIndex = await this.slides.nativeElement.getActiveIndex();  
          this.addCount('goals', this.activeIndex);
          this.viewController.dismiss(pageAndParams);
        }
        else {
          this.viewController.dismiss();
        }
      }
    } else {
      this.viewController.dismiss();
    }
  }

  public async nextPage() {
    this.lockSwipes(true);
    await this.slides.nativeElement.slideNext();
    this.lockSwipes(false);
  }

  public async slidePage(direction: 'prev' | 'next' | 'out') {
    switch (direction) {
      case 'next':
        await this.nextPage();
        break;
      case 'prev':
        await this.prevPage();
        break;
      default:
        this.dismiss();
    }
  }

  public async getScreens() {
    for (const campaign of this.campaigns) {
      for (const screen of campaign.screens) {
        const campaignScreen = await this.firebase.getFirebaseCollection('welcomeCampaignScreens').doc(screen).get();
        this.screens.push(campaignScreen.data());
      }
    }
  }

  public async addCount(counter: 'views' | 'goals', index: number) {
    const data = await this.storeService.getValue(this.storeService.getCloseState);
    if(!data) {
      for (let i=0; i < this.campaigns.length; i++) {
        const campaign = this.campaigns[i]
        if (i == index) {
          
        }
      }
    }
  }

  public async isFinalScreenShowing() {
    return await this.slides.nativeElement.getActiveIndex() === this.screens.length - 1;
  }  

  public lockSwipes(lock: boolean) {
    this.slideOptions.allowTouchMove = lock;
  }

  private async prevPage() {
    this.lockSwipes(true);
    await this.slides.nativeElement.slidePrev();
    this.lockSwipes(false);
  }

}
