import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from '@angular/router';
import { HOME_ROUTE, LOGIN_ROUTE } from '@common/constants/routes.constants'; // HOME_ROUTE
import { AuthService } from '@services/auth/auth.service';
import { UserService } from '@services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private userService: UserService) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.isAuthenticated(state)) {
      return false;
    }
    const finalUrl: string = this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().finalUrl &&
      this.router.getCurrentNavigation().finalUrl.toString() || '';
    if (!this.userService.userInformation && finalUrl !== `/${HOME_ROUTE}`) {
      try {
        await this.userService.getUserInformation();
      } catch (e) {
        await this.authService.signOut();
        this.router.navigateByUrl(`${HOME_ROUTE}`);
      }
    }
    return true;
  }

  private isAuthenticated(state: RouterStateSnapshot): boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate([`${LOGIN_ROUTE}`], { replaceUrl: true, queryParams: { returnUrl: state.url }});
      return false;
    }
    return true;
  }
}
