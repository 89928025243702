export const PAYMENTS_DISCLAIMER = `Los pagos realizados después de las 10:00 p.m. hora Perú
se ejecutarán el siguiente día útil. Los pagos anticipados solo se realizan en nuestras agencias.`;

export const PAYMENTS_ANTICIPATED_DISCLAIMER = `Los pagos anticipados solo se realizan en nuestras agencias.`;

export const PAY_OPTION = 'pay';

export const DETAILS_OPTION = 'details';

export const MAX_AMOUNT_ABSOLUTE = 999999999.99;

export const LOAN_TYPE = 'PRESTAMO';

export const LOAN_PENDING_DISCLAIMER = 'La cuota a pagar corresponde a la más antigua registrada en tu cronograma, ' +
'si tienes cuotas posteriores acumuladas por pagar, repite el proceso de pago para cancelar una a una.';

export const LOAN_DISCLAIMER = 'Este es el monto de tu cuota por pagar.';

const DENIED_OPERATION = {
  title: 'Operación Denegada',
  message: 'Te invitamos a comunicarte con nosotros <br> a través de RipleyFono <strong>(01) 611-5757.</strong>',
  route: '/home',
  primaryButton: 'Ir a inicio'
};

const INCORRECT_DATA = {
  title: 'Operación denegada',
  message: 'Verifica que los datos ingresados de tu tarjeta de débito sean correctos.' +
    ' Si el problema persiste, contacta al banco de tu tarjeta de débito',
  route: '/home/payments',
  primaryButton: 'Aceptar'
};

const MISSING_CVV = {
  title: 'Vuelve a intentarlo',
  message: 'No olvides ingresar el CVV de la tarjeta de débito',
  route: '/home/payments',
  primaryButton: 'Ir a inicio',
};

const CREDIT_CARD_USED = {
  title: '',
  message: 'La tarjeta para realizar el pago debe ser débito',
  route: '/home/payments',
  primaryButton: 'Aceptar',
};

const INACTIVITY_TIME = {
  title: '',
  message: 'El tiempo para realizar el pago ha expirado',
  route: '/home/payments',
  primaryButton: 'Aceptar'
};

const CONNECTION_PROBLEM = {
  title: '',
  message: 'Ofrecemos disculpas. En este momento tenemos problemas en la comunicación.' +
    ' Por favor, intenta en unos minutos',
  route: '/home',
  primaryButton: 'Cerrar'
};

const CANCELED_PROCESS = {
  title: '',
  message: 'Se canceló el proceso de pago. Por favor, ingresa nuevamente',
  route: '/home/payments',
  primaryButton: 'Aceptar'
};

const EXPIRED_CARD = {
  title: '',
  message: 'La tarjeta de débito se encuentra vencida o la fecha de vencimiento ingresada es incorrecta',
  route: '/home/payments',
  primaryButton: 'Aceptar'
};

const OPERATION_NOT_ALLOWED = {
  title: 'Operación no permitida para esta tarjeta',
  message: 'Verifica que los datos de la tarjeta de débito con la que estás realizando el pago sean correctos e intenta de nuevo. ' +
    ' Si el problema persiste contacta al banco de tu tarjeta de débito.',
  route: '/home',
  primaryButton: 'Aceptar'
};

const CARD_WITH_NO_FUNDS = {
  title: '',
  message: 'La tarjeta de débito ingresada no tiene fondos suficientes para realizar el pago',
  route: '/home/payments',
  secondaryRoute: '/home',
  primaryButton: 'Ingresar otra tarjeta',
  secondaryButton: 'Ir a Inicio'
};

const INVALID_SECURITY_CODE = {
  title: 'Código de seguridad inválido',
  message: 'Verifica e intenta de nuevo. Si el problema persiste, contacta al banco emisor de tu tarjeta de débito',
  route: '/home/payments',
  primaryButton: 'Aceptar'
};

const INSUFFICIENT_FUNDS = {
  title: 'Tarjeta con restricciones de crédito',
  message: 'Verifica que tu tarjeta de débito con la que estás realizando el pago tenga saldo suficiente o valida los datos ingresados.' +
    ' Si el problema persiste, contacta al banco de tu tarjeta de débito.',
  route: '/home/payments',
  primaryButton: 'Aceptar'
};

const BLOCKED_CARD = {
  title: '',
  message: 'La tarjeta de débito ingresada se encuentra bloqueada',
  route: '/home',
  primaryButton: 'Ir a inicio'
};

const DISABLED_INTERNET_PURCHASING = {
  title: 'Tarjeta inválida',
  message: 'Verifica que los datos de tu tarjeta de débito con la que estás realizando el pago estén correctos y' +
    ' que se encuentre activa para compras por internet. Si el problema persiste contacta al banco de tu tarjeta de débito.',
  route: '/home',
  primaryButton: 'Ir a inicio'
};

const BANK_EMISOR_UNAVAILABLE = {
  title: 'Banco emisor no disponible',
  message: 'Verifica que tu tarjeta de débito con la que estás realizando el pago se encuentre activa para compras por internet.' +
    ' Si el problema persiste, contacta al banco de tu tarjeta de débito.',
  route: '/home',
  primaryButton: 'Ir a inicio'
};

const NEW_MESSAGE_REMEMBER = 'Recuerda activar la opción de “Compras por Internet” de la tarjeta de débito con la que realizas el pago.';

const NEW_MESSAGE_RELOAD = 'Por favor, ingresa nuevamente. Si el problema persiste, contacta al banco de tu tarjeta de débito.';

const NEW_MESSAGE_LIMIT = 'Superaste el límite de consumo de la tarjeta de débito. Si el problema persiste, contacta al banco de tu tarjeta de débito.';

const NEW_DENIED_OPERATION = {
  title: 'Lo sentimos',
  message: 'No ha sido posible efectuar la operación. Si el problema persiste, contacta al banco de tu tarjeta de débito.',
  route: '/home/payments',
  primaryButton: 'Cerrar'
}

export const PAYMENT_ERROR_MESSAGES = {
  401: DENIED_OPERATION,
  402: DENIED_OPERATION,
  404: DENIED_OPERATION,
  405: DENIED_OPERATION,
  406: DENIED_OPERATION,
  407: DENIED_OPERATION,
  414: DENIED_OPERATION,
  415: DENIED_OPERATION,
  416: DENIED_OPERATION,
  417: DENIED_OPERATION,
  418: DENIED_OPERATION,
  419: DENIED_OPERATION,
  421: DENIED_OPERATION,
  424: DENIED_OPERATION,
  619: DENIED_OPERATION,
  668: DENIED_OPERATION,
  670: NEW_DENIED_OPERATION,
  672: DENIED_OPERATION,
  673: DENIED_OPERATION,
  674: DENIED_OPERATION,
  678: DENIED_OPERATION,
  683: DENIED_OPERATION,
  684: DENIED_OPERATION,
  685: DENIED_OPERATION,
  190: {
    ...DENIED_OPERATION,
    message: NEW_MESSAGE_REMEMBER
  },
  191: {
    ...DENIED_OPERATION,
    message: NEW_MESSAGE_RELOAD
  },
  192: DENIED_OPERATION,
  119: DENIED_OPERATION,
  942: DENIED_OPERATION,
  199: DENIED_OPERATION,
  940: DENIED_OPERATION,
  941: DENIED_OPERATION,
  945: DENIED_OPERATION,
  111: DENIED_OPERATION,
  121: {
    ...DENIED_OPERATION,
    message: NEW_MESSAGE_LIMIT
  },
  410: MISSING_CVV,
  408: INCORRECT_DATA,
  409: INCORRECT_DATA,
  403: INCORRECT_DATA,
  108: INCORRECT_DATA,
  411: INCORRECT_DATA,
  412: INCORRECT_DATA,
  102: INCORRECT_DATA,
  107: INCORRECT_DATA,
  413: INCORRECT_DATA,
  202: INCORRECT_DATA,
  290: INCORRECT_DATA,
  306: INCORRECT_DATA,
  916: INCORRECT_DATA,
  928: INCORRECT_DATA,
  948: INCORRECT_DATA,
  949: INCORRECT_DATA,
  682: INACTIVITY_TIME,
  666: CONNECTION_PROBLEM,
  183: CONNECTION_PROBLEM,
  909: CONNECTION_PROBLEM,
  910: CONNECTION_PROBLEM,
  947: CONNECTION_PROBLEM,
  904: CONNECTION_PROBLEM,
  423: CANCELED_PROCESS,
  667: CANCELED_PROCESS,
  420: CREDIT_CARD_USED,
  226: EXPIRED_CARD,
  201: EXPIRED_CARD,
  101: EXPIRED_CARD,
  204: OPERATION_NOT_ALLOWED,
  110: OPERATION_NOT_ALLOWED,
  104: OPERATION_NOT_ALLOWED,
  207: OPERATION_NOT_ALLOWED,
  116: CARD_WITH_NO_FUNDS,
  129: INVALID_SECURITY_CODE,
  181: INSUFFICIENT_FUNDS,
  182: INSUFFICIENT_FUNDS,
  208: BLOCKED_CARD,
  209: BLOCKED_CARD,
  263: BLOCKED_CARD,
  180: DISABLED_INTERNET_PURCHASING,
  118: DISABLED_INTERNET_PURCHASING,
  913: BANK_EMISOR_UNAVAILABLE,
  912: BANK_EMISOR_UNAVAILABLE,
  264: BANK_EMISOR_UNAVAILABLE,
};

export const PAYMENT_MODAL_PROPS = {
  title: '<strong>Confirma tu pago</strong>',
  message: 'Recuerda tener <strong> activa la opción de “Compras por Internet” </strong> '
  + 'de la tarjeta de débito con la que harás el pago.',
  secondaryButtonText: 'Cancelar'
};

export const PAYMENT_TERMS_INFORMATION = `<div class="payment-terms">Tasa de interés moratorio nominal anual, que se cobra por los días de atraso y se calcula sobre el capital de la deuda vencida. Consulta las tasas en nuestros tarifarios:<br>www.bancoripley.com.pe/tasas-tarifas-contratos.html</div>`

export const NIUBIZ_MESSAGE_BIN_VALIDATION = 'Debes ingresar una TARJETA DE DÉBITO válida';

export const PAYMENT_PROGRESS_ADDITION = 0.50;

export const FIRST_ANTICIPATED_DISCLAIMER = 'El cobro del seguro de desgravamen se realiza sobre el promedio de los saldos deudores diarios';

export const COMPLETE_ANTICIPATED_DISCLAIMER = ' del periodo de facturación, con lo cual podría tener cargo por este concepto en su siguiente estado de cuenta.';

export const MORE_INFORMATION_PROPS = {
  title: '¿Qué es Niubiz?',
  message: 'Puedes pagar tu deuda de Banco Ripley con tarjetas de débito de otros bancos',
  buttonText: 'Entendido'
}

export const CONTINUE_TEXT = 'Continuar';

export const AHR_CODE = 'AHR';

export const NBZ_CODE = 'NBZ';

