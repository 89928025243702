import { Injectable } from '@angular/core';
import { HttpService } from '@services/http/http.service';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';
import { IGenericNameValueResponse } from '@common/interfaces/default.interface';
import { environment } from '@environments/environment';
import { EFEX_TERMS_AND_CONDITIONS } from '@common/mocks/efex.mocks';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  public nativeAndroid = false;

  constructor(
    private http: HttpService,
    private platform: Platform,
    private fileOpener: FileOpener,
    private file: File,
  ) {
    this.nativeAndroid = this.platform.is('cordova') && this.platform.is('android');
  }

  public async getPdf(name: string): Promise<IGenericNameValueResponse> {
    const url = environment.BASE_BACKEND_WORKER + `/parameterhbk/${name}`;
    return this.http.get(url, EFEX_TERMS_AND_CONDITIONS).toPromise();
  }

  public async decodeBase64DataToObjectUrl(base64data: string, fileName: string): Promise<string> {
    const blob = this.transformBase64toBlob(base64data);
    if (!this.nativeAndroid) {
      return window.URL.createObjectURL(blob);
    }
    const createdFileName = fileName + '.pdf';
    await this.file.writeFile(this.file.externalCacheDirectory, createdFileName, blob, { replace: true });
    return this.file.externalCacheDirectory + createdFileName;
  }

  public transformBase64toBlob(base64Data: string) {
    const byteArray = new Uint8Array(atob(base64Data).split('').map(char => char.charCodeAt(0)));
    return new Blob([byteArray], { type: 'application/pdf' });
  }

  public decodeBase64DataToSanitizedUrl(base64data: string, sanitizer: DomSanitizer) {
    const blob = this.transformBase64toBlob(base64data);
    return sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  public openPdf(pdfUrl: string): void {
    if (!this.nativeAndroid) {
      window.open(pdfUrl, '_blank');
      return;
    }
    if (pdfUrl.includes('https://')) {
      window['nativeOpen'](pdfUrl, '_system');
    } else {
      this.fileOpener.open(pdfUrl, 'application/pdf');
    }
  }
}
