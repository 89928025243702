import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-politic-modal',
  templateUrl: './politic-modal.component.html',
  styleUrls: ['./politic-modal.component.scss'],
})
export class PoliticModalComponent {
  @Input() public title = '';
  @Input() public message = '';
  @Input() public primaryButtonText = '';
  @Input() public secondaryButtonText = '';
  @Input() public icon = 'i-smartphone-purple';
  @Input() public image = '';

  constructor(
    private modalController: ModalController,
  ) { }

  public dismissModal() {
    this.modalController.dismiss();
  }

  public onPrimaryClick() {
    this.modalController.dismiss('primaryButtonPressed');
  }

  public onSecondaryClick() {
    this.modalController.dismiss('secondaryButtonPressed');
  }
}
