import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-new-global-header',
  templateUrl: './new-global-header.component.html',
  styleUrls: ['./new-global-header.component.scss'],
})
export class NewGlobalHeaderComponent {

  @Input() title?: string;
  @Input() stepNumber?: number;
  @Input() totalStep?: number;
  @Output() goBackStep: EventEmitter<number> = new EventEmitter();

  constructor(
    private location: Location,
  ) { 
    this.goBackStep = new EventEmitter();
  }

  public async nextStep() { 
    ++this.stepNumber;
  }

  goBack() {
    if(this.stepNumber !== 1) {
      --this.stepNumber;
      this.goBackStep.emit(this.stepNumber);
    } else {
      this.location.back();  
    }
  }
}
