import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ENROLL_DOCUMENT_NUMBER, USER_INFO } from '@common/constants/auth.constants';
import { Platform } from '@ionic/angular';
import { AnalyticsUtilsService } from '@services/utils-analytics/utils-analytics.service';
import { Storage } from '@ionic/storage';
import { version } from 'package.json';
import { FirebaseService } from '@services/firebase/firebase.service';

// tslint:disable-next-line:ban-types
declare const SR: any;
declare const SyneriseTC: any

@Injectable({
  providedIn: 'root'
})
export class SyneriseServiceService {

  constructor(
    private analyticsService: AnalyticsUtilsService,
    private platform: Platform,
    private router: Router,
    private storage: Storage,
    private firebaseService: FirebaseService,
    ) { }

  private async setUserId() {
    const { documentNumber = 0 } = (await this.storage.get(USER_INFO)) || {}; 
    const enrollDocumentNumber = await this.storage.get(ENROLL_DOCUMENT_NUMBER);
    const encrypt = this.analyticsService.encryptData(enrollDocumentNumber ? enrollDocumentNumber : documentNumber);
    return encrypt;
  }  

  pageVisit(): void {
    this.firebaseService.initializeRemoteConfig().then((data) => {
      if(data.PAGE_VISIT.includes(this.router.url)) {
        SR.event.pageVisit().then(function () {
          SR.dynamicContent.get();
        })
      } else {
        SR.dynamicContent.get();
      }
    }).catch(() => {
      SR.event.pageVisit().then(function () {
        SR.dynamicContent.get();
      })
    })
    
  }

  async trackEvent(event: string, params: object) {
    SR.event.trackCustomEvent(
      event, 
      {
        path: !params['path'] ? this.router.url : params['path'],
        channel: this.setChannel,
        embedded: '0',
        status: '0',
        time: Date.now(),
        ...params
      },
      SyneriseTC.uuid
    )
  }

  sendFormUpdate(data: object) {
    SR.event.sendFormData('', data)
  }

  sendFormData(user: any) {
      SR.event.sendFormData('form.submit', {
        lastname: user.lastName.normalize("NFD").toLowerCase().replace(/\b[a-z](?=[a-z]{2})/g, (letter) => letter.toUpperCase()),
        firstname: user.names.normalize("NFD").toLowerCase().replace(/\b[a-z](?=[a-z]{2})/g, (letter) => letter.toUpperCase()),
        email_address: user.email,
        city: user.homeAddress,
        platform: this.setChannel,
        amountAvailable: user.amountAvailable,
        amountUsed: user.amountUsed,
        totalMonthPayment: user.totalMonthPayment,
        totalDebt: user.totalDebt,
        lastDatePayment: user.lastDatePayment,
        creditLine: user.creditLine,
        sefAmount: user.sefAmount === "-" ? 0 : user.sefAmount,
        efexAmount: user.efexAmount === "-" ? 0 : user.efexAmount,
        rpoint: user.rpoint,
        category: user.category,
        custom_identify: user.customId,
        version: version
      })
  }

  get setChannel() {
    let channel: string;
    if (this.platform.is('desktop')) {
      channel = 'desktop';
    } else if ( this.platform.is('android') && this.platform.is('mobileweb')) {
      channel = 'androidweb';
    } else if (this.platform.is('cordova') && this.platform.is('android')) {
      channel = 'androidapp';
    } else if (this.platform.is('ios') && this.platform.is('mobileweb')) {
      channel = 'iosWeb';
    } else if (this.platform.is('cordova') && this.platform.is('ios')) {
      channel = 'iosapp';
    } else {
      channel = 'desktop';
    }
    return channel;
  }

  private currencyFormatter({ currency, value}) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      currency
    }) 
    return formatter.format(value)
  }
  
}
