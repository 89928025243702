import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PeruvianCurrencyPipe } from '@pipes/peruvian-currency/peruvian-currency.pipe';

@Directive({
  selector: '[moneyWithoutDecInput]'
})
export class MoneyWithoutDecInputDirective {

  @Input('moneyWithoutDecInput') formControl: FormControl;
  element: HTMLInputElement;

  constructor(
    private el: ElementRef,
    private peruvianCurrency: PeruvianCurrencyPipe
  ) {
    this.element = this.el.nativeElement;
  }

  @HostListener('input', ['$event.target.value']) onChange(typedValue: string) {
    if (typedValue) {
      const numericValue = this.unformattCurrency(typedValue);
      this.formControl.setValue( !isNaN(numericValue) ? numericValue : null);
      this.element.value = this.peruvianCurrency.transform(numericValue, 0);
    }
  }

  @HostListener('blur', ['$event.target.value']) onBlur() {
    this.formControl.markAsTouched();
  }

  private unformattCurrency(value: string): number {
    const clean = this.clearNumber(value);
    if (isNaN(clean)) { return 0; }
    return clean;
  }

  private clearNumber(value: string): number {
    return Number(value.replace(/\D+/g, ''));
  }
}
