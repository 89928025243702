import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationCheckerService {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(private router: Router) { 
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  public InHomeFromLogin() {
    return (this.previousUrl == '/' || this.previousUrl == '/login') && this.currentUrl == '/home';
  }

  public InLogin() {
    return this.currentUrl == '/login' || this.currentUrl == '/';          
  }

  public InHome() {
    return this.currentUrl == '/home';
  }
}
