import { Component, Input } from '@angular/core';
import { ITransferShiftSchedule } from '@common/interfaces/transfers.interface';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-transfers-schedule-moda-old',
  templateUrl: './transfers-schedule-modal-old.component.html',
  styleUrls: ['./transfers-schedule-modal-old.component.scss'],
})
export class TransfersScheduleModalComponentOld {
  @Input() title: string;
  @Input() shiftsSchedule: ITransferShiftSchedule[];
  @Input() nonWorkingDayMessage: string;
  @Input() primaryButtonText: string;
  @Input() icon: string;

  constructor(
    private modalController: ModalController,
  ) {
    this.title = '';
    this.shiftsSchedule = [];
    this.nonWorkingDayMessage = '';
    this.primaryButtonText = '';
    this.icon = 'i-question';
  }

  public dismissModal() {
    this.modalController.dismiss();
  }

  public capitalize(text: string): string {
    return text[0].toUpperCase() + text.slice(1).toLowerCase();
  }

  public transferTime(startTime: string, endTime: string): string {
    if (!endTime) { return `antes de las ${startTime}`; }
    if (!startTime) { return `después de la ${endTime}`; }
    return `entre las ${startTime} y la ${endTime}`;
  }

}
