import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { PdfService } from '@services/pdf/pdf.service';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnChanges {

  @Input() base64String: string;
  @Input() elementClass: string;
  secureStatementPdfUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private pdfService: PdfService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.secureStatementPdfUrl =
      this.pdfService.decodeBase64DataToSanitizedUrl(changes.base64String.currentValue, this.sanitizer);
  }
}
