import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import {AHORRO_ROUTE, CREDITS_ROUTE} from '@common/constants/routes.constants';
import { OP_NOT_MY_NUMBER_MODAL } from '@common/constants/tag/tag-open-account.constants';

@Component({
  selector: 'app-update-info',
  templateUrl: './update-info.component.html',
  styleUrls: ['./update-info.component.scss'],
})
export class UpdateInfoComponent implements OnInit {

  constructor(
    private viewController: ModalController,
    private router: Router,
    ) { }

  ngOnInit() {
  }

  public dismissModal() {
    this.viewController.dismiss();
  }

  public async GoToOffers() {
    this.dismissModal();
    return this.router.navigate([AHORRO_ROUTE], { replaceUrl: true });
  }

}
