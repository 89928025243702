import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ERROR_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-disbursement-error',
  templateUrl: './disbursement-error.component.html',
  styleUrls: ['./disbursement-error.component.scss'],
})
export class DisbursementErrorComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() primaryButton: string;
  @Input() secondaryButton: string;
  @Input() dataString: string;
  @Input() flow: string;

  constructor(
    private viewController: ModalController,
    private googleAnalyticsService: GoogleAnalyticsService,
    ) { }

  ngOnInit() {
    if (this.flow) {
      this.googleAnalyticsService.gtagPushEventWithId(ERROR_GA4_CONSTANTS.DISBURMENT_ERROR.EVENT, { 
        title: !this.title ? ERROR_GA4_CONSTANTS.DISBURMENT_ERROR.TITLE : this.title,
        message: !this.message ? ERROR_GA4_CONSTANTS.DISBURMENT_ERROR.MESSAGE : this.message, 
        error: !this.dataString ? ERROR_GA4_CONSTANTS.DISBURMENT_ERROR.ERROR : this.dataString,
        flow: this.flow,
      });
    }
  }

  dismissModal() {
    this.viewController.dismiss();
  }

  onPrimaryClick() {
    this.viewController.dismiss('primaryButtonPressed');
  }

  onSecondaryClick() {
    this.viewController.dismiss('secondaryButtonPressed');
  }

}
