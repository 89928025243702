import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AskNotificationPermissionListenerService {

  askNotificationEmitter: EventEmitter<any>=new EventEmitter();

  constructor() { }

  askNotificationEventEmitter(): void{
    this.askNotificationEmitter.emit(true);
  }
}
