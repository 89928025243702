import { OTP_VALIDATION_ERROR } from "@common/constants/enrollment.constants";

export const SEND_OTP_MOCK = {
  success: [],
  failures: [],
};

export const SEND_REDEEM_OTP_MOCK = {
  success: [],
  failures: [],
};

export const VALIDATE_OTP = {
  success: [],
  failures: [],
};