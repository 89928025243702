import { Component, Input } from '@angular/core';
import { CALENDAR_EVENT_TEMPLATE } from '@common/constants/product.constants';
import { ISegmentSelector } from '@common/interfaces/default.interface';
import { UtilsService } from '@services/utils/utils';
import { Calendar } from '@ionic-native/calendar/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { IProductsDetail, ISEFDetails } from '@common/interfaces/product.interface';
import { TAG_NO_DEUDA_MOV, TAG_PAY_RIPLEY_CARD_MOVEMENTS } from '@common/constants/tag/tag-payments.constants';
import { Router } from '@angular/router';
import { PAYMENTS_ROUTE } from '@common/constants/routes.constants';
import { NodebtModalComponent } from '@components/payment-modal/nodebt-modal/nodebt-modal/nodebt-modal.component';

@Component({
  selector: 'app-card-details-box',
  templateUrl: './card-details-box.component.html',
  styleUrls: ['./card-details-box.component.scss'],
})
export class CardDetailsBoxComponent {

  @Input() containerClass: string;
  @Input() boxInformation: ISegmentSelector[];
  @Input() withPayButton: boolean;
  @Input() withIcon: boolean;
  @Input() lastDatePaymentRaw: string;
  @Input() showLink: boolean;
  @Input() infoPayment: IProductsDetail | ISEFDetails;
  @Input() tabNameChange: string;
  public calendarLink: HTMLAnchorElement;
  withIconValue: boolean;
  paymentVisible: boolean;

  constructor(
    public platform: Platform,
    private calendar: Calendar,
    public router: Router,
    private modalCtrl: ModalController,


  ) {
    this.withIconValue = this.withIcon;
    this.paymentVisible = true;
  }

  public saveOnCalendar() {
    this.platform.is('ios') ? this.createCalendarEvent() : this.createCalendarLink() ;
  }

  private createCalendarLink() {
    const { title, description } = CALENDAR_EVENT_TEMPLATE;
    const calendarUrl = UtilsService.getGoogleEventLink({
      title,
      description,
      start: this.lastDatePaymentRaw
    });
    this.calendarLink = document.createElement('a');
    this.calendarLink.href = calendarUrl;
    this.calendarLink.target = '_blank';
    document.body.appendChild(this.calendarLink);
    this.calendarLink.click();
    this.calendarLink.remove();
  }

  private createCalendarEvent() {
    const { title, location, description } = CALENDAR_EVENT_TEMPLATE;
    const startDate = UtilsService.dateConvert(this.lastDatePaymentRaw);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 1);
    this.calendar.createEventInteractively(title, location, description, startDate, endDate);
  }

  public async goToPayment() {
    if (this.infoPayment.totalMonthPayment !== 0) {
      this.router.navigate([`${PAYMENTS_ROUTE}/ripley-card`, this.infoPayment.productType], { replaceUrl: true, state: this.infoPayment });
    } else {
      await this.NoDebtModal();
    }
  }

  public NoDebtModal = async () => {
    const modal = await this.modalCtrl.create({
      component: NodebtModalComponent,
      cssClass: 'form-modal-pagos',
      componentProps: {
        product: this.infoPayment.productType
      },
    });
    await modal.present();
  }

  ngOnChanges() {
     this.paymentVisible = this.tabNameChange != 'movements' ? false : true;
   }


}
