export const DETAILS_SAVINGS_MOCK = {
  success: [],
  failures: [],
};

export const DETAILS_CTS_MOCK = {
  success: [],
  failures: [],
};

export const DETAILS_CTS_SAVINGS_MOCK = {
  success: [],
  failures: [],
};

export const MOVEMENTS_SAVINGS_MOCK = {
  success: [],
  failures: [],
};

export const MOVEMENTS_CTS_MOCK = {
  success: [],
  failures: [],
};

export const SAVINGS_PDF_URL_MOCK = {
  success: [],
  failures: [],
};
