import { Injectable } from '@angular/core';
import { HttpService } from '@services/http/http.service';
import { GET_PINPAN_PRIVATE_KEY } from '@common/mocks/pinpan.mocks';
import { IPinPanData, IPinPanDataEncrypt } from '@common/interfaces/data-pinpan.interface';
import { environment } from '@environments/environment';

const forge = require('node-forge');
const { parseStringPromise } = require('xml2js');

export const ERROR_CODE_WITH_RESPONSE = 26;

@Injectable({
    providedIn: 'root'
})

export class DataPinPanService {

    constructor(
        private http: HttpService,
    ) { }

    public async getNewPublicKey(documentNumber: string): Promise<string> {
        const body = {
            documentNumber
        }
        return this.http.post(environment.BASE_BACKEND_WORKER + '/enrollment/accountuserhb/registerkeyrsa', body, GET_PINPAN_PRIVATE_KEY).toPromise();
    }

    public async encryptPublicKey(dataPinPan: IPinPanData, publicKeyPEM: string): Promise<[boolean, string]> {
        try {
            const pinPanEncrypt: IPinPanDataEncrypt = {
                pan: dataPinPan.pan,
                pin: {
                    KeyboardId: dataPinPan.pinKeyboardId,
                    HashCodes: dataPinPan.pinHashCodes
                }
            }
            var pinPanDataJSON = JSON.stringify(pinPanEncrypt); // Genera JSON con la data a encriptar
            var pem = publicKeyPEM; // Llave pública en formato PEM
            var rsa = forge.pki.publicKeyFromPem(pem); // Declara variable RSA con llave pública
            var encriptedPinPan = forge.util.encode64(rsa.encrypt(pinPanDataJSON)); // Encripta la data
        }
        catch (error)
        {
            return [false, error.code];
        }
        return [true, encriptedPinPan];
    }

}
