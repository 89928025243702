import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ITermsDeposit } from '@common/interfaces/invest.interface';
import { INVEST_MESSAGE_MODAL_VIEW_FOOTER_DESKTOP } from '@common/constants/investment.constants';
import { SCREEN_WIDTH } from '@common/constants/misc.constants';


@Component({
  selector: 'app-invest-detail-modal',
  templateUrl: './invest-detail-modal.component.html',
  styleUrls: ['./invest-detail-modal.component.scss'],
})
export class InvestDetailModalComponent {

  mensaje: string = '';
  @Input() termsDetail: ITermsDeposit;
  investDetailInfo: ITermsDeposit;

  constructor(
    private modalController: ModalController
  ) { }

  get isMobile() { return window.innerWidth < SCREEN_WIDTH.TABLET; }

  ngOnInit() 
  {
    this.mensaje = INVEST_MESSAGE_MODAL_VIEW_FOOTER_DESKTOP;
    this.investDetailInfo = this.termsDetail;    
  }

  dismissModal() {
    this.modalController.dismiss();
  }

}
