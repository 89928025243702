import { IConsolidatedRewardPoints, IRipleySegmentDirection } from '@common/interfaces/reward-points.interface';
import { RewardPointsService } from '@services/reward-points/reward-points.service';
import { Component, OnInit, HostListener, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MenuController, ModalController } from '@ionic/angular';
import { AuthService } from '@services/auth/auth.service';
import { DEFAULT_REQUEST_TIMEOUT, SCREEN_WIDTH } from '@common/constants/misc.constants';
import { HOME_ROUTE, LOGIN_ROUTE, RIPLEY_POINTS_DASHBOARD_ROUTE } from '@common/constants/routes.constants';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { SIGNOUT_WARNING_MODAL_PROPS } from '@common/constants/modal-props.constants';
import { CUSTOMER_TYPE_SEGMENT_RIPLEY } from '@common/constants/reward-points.constants';
import { CLOSE_LOGIN } from '@common/constants/tag/tag-login.constants';
import { POINT_RIPLEY } from '@common/constants/tag/tag-home.constants'
import { INACTIVITY_CLOSED_SESSION_STORAGE_KEY } from '@common/constants/remember-user.constants';
import { Storage } from '@ionic/storage';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { HOME_GA4_CONSTANTS } from "@common/constants/digital-auth.constants";

@Component({
  selector: 'app-global-header-rpgo',
  templateUrl: './global-header-rpgo.component.html',
  styleUrls: ['./global-header-rpgo.component.scss'],
})
export class GlobalHeaderRpgoComponent implements OnInit {

  currentPath: string;
  showBackButton: boolean;
  showPoints: boolean;
  customerType: string;
  customerTypeLogo: string;
  ripleyPoints: IConsolidatedRewardPoints[];
  segmentDirection: IRipleySegmentDirection;
  signoutModal: HTMLIonModalElement;
  fromRoute: string;
  
  @Input() hideMenu: boolean;
  @Input() hideBackButton: boolean;
  @Input() title: string;
  @Input() customBackEvent: boolean;
  @Input() public sessionLess: boolean = false;
  @Input() classType: string;
  @Output() backButtonClick = new EventEmitter<void>();

  

  @HostListener('window:resize', ['$event'])
  onResize() {
    if(!this.sessionLess){
      this.getCategoryDescription();
    }
    this.showBackButton = this.showBackButtonValidation();
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private location: Location,
    private menu: MenuController,
    private rewardPointsService: RewardPointsService,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    private storage: Storage,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.customerType = this.title = this.currentPath = CUSTOMER_TYPE_SEGMENT_RIPLEY;
    this.customerTypeLogo = `logo-ripleypoints-${this.customerType}`;
    this.hideMenu = this.hideBackButton = this.customBackEvent = this.showBackButton = false;
    this.backButtonClick = new EventEmitter();
    this.showPoints = false;
    this.ripleyPoints = [];
    this.sessionLess = false;
    this.route.params.subscribe(params => {
      this.fromRoute = params['from'];
    });

  }

  public get ripleyPointsAvailable(): number { return this.rewardPointsService.availableRipleyPoints; }

  public get showPointsRipley(): boolean { return this.rewardPointsService.mostrarPuntos; }

  public get ripleyPointsRoute(): string { return `/${RIPLEY_POINTS_DASHBOARD_ROUTE}`; }

  private showBackButtonValidation(): boolean {
    return this.currentPath !== HOME_ROUTE && window.innerWidth <= SCREEN_WIDTH.MOBILE;
  }

  public goBack(): void {
    if (this.customBackEvent) {
      this.backButtonClick.emit();
    } else {
      if (this.fromRoute && this.fromRoute === 'login') {
        this.router.navigate([`/${HOME_ROUTE}`]);
      } else {
        this.location.back();
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.currentPath = this.router.url.replace('/', '');
    this.showBackButton = this.showBackButtonValidation();
    if (this.hideMenu) {
      const menuElement = await this.menu.get();
      menuElement.swipeGesture = false;
    }
    if (!this.sessionLess) {
      if (window.innerWidth > SCREEN_WIDTH.MOBILE) {
        this.getCategoryDescription();
      }
    }
  }

  public async signOut(): Promise<void> {
    await this.authService.signOut();
    this.rewardPointsService.resetVariables();
    this.resetVariables();
    this.router.navigateByUrl(LOGIN_ROUTE, { replaceUrl: true });
  }

  public async openSignoutModal(): Promise<void> {
    this.googleAnalyticsService.gtagPushEventWithId(HOME_GA4_CONSTANTS.HOME_CLOSE_SESSIONS.EVENT, {
      path_destino: HOME_GA4_CONSTANTS.HOME_CLOSE_SESSIONS.PATH_DESTINO,
      product: HOME_GA4_CONSTANTS.HOME_CLOSE_SESSIONS.PRODUCT,
      action: HOME_GA4_CONSTANTS.HOME_CLOSE_SESSIONS.ACTION,
      category: HOME_GA4_CONSTANTS.HOME_CLOSE_SESSIONS.CATEGORY,
      sub_category: HOME_GA4_CONSTANTS.HOME_CLOSE_SESSIONS.SUB_CATEGORY[0]
    })
    this.signoutModal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: SIGNOUT_WARNING_MODAL_PROPS,
    });
    await this.signoutModal.present();
    const option = await this.signoutModal.onDidDismiss();
    if (option.data === 'primaryButtonPressed') {
      this.storage.remove(INACTIVITY_CLOSED_SESSION_STORAGE_KEY);
      this.signOut();
    }
  }

  private async getCategoryDescription(): Promise<void> {
    try {
      const response = await this.rewardPointsService.getSegmentDirection();
      if (response && (response as any).responseCode === 'X1000') {
        this.customerType = CUSTOMER_TYPE_SEGMENT_RIPLEY;
      } else {
        this.segmentDirection = response;
      }
    } catch (_err) {
      this.segmentDirection = null;
      this.customerType = CUSTOMER_TYPE_SEGMENT_RIPLEY;
      setTimeout(this.getCategoryDescription, DEFAULT_REQUEST_TIMEOUT);
    }

    if (this.segmentDirection && this.segmentDirection['retrieveSegmentStrategyCustomerResponse'] ) 
    {
      this.customerType = this.segmentDirection['retrieveSegmentStrategyCustomerResponse']['customer']['type']['description'].toString().toLowerCase();
      this.customerTypeLogo = `logo-ripleypoints-${this.customerType}`;      
    } 


  }

  public getString(value: any): string {
    if (value == undefined)
      return '';
    if (typeof value == typeof null) {
      return '';
    }
    return ("" + value).trim();
  }
  gtmRipleyPoint() {
    this.googleAnalyticsService.gtagPushEventWithId(HOME_GA4_CONSTANTS.HOME_RIPLEY_POINT.EVENT,
      {
        path_destino: HOME_GA4_CONSTANTS.HOME_RIPLEY_POINT.PATH_DESTINO,
        product: HOME_GA4_CONSTANTS.HOME_RIPLEY_POINT.PRODUCT,
        action: HOME_GA4_CONSTANTS.HOME_RIPLEY_POINT.ACTION,
        category: HOME_GA4_CONSTANTS.HOME_RIPLEY_POINT.CATEGORY,
        sub_category: HOME_GA4_CONSTANTS.HOME_RIPLEY_POINT.SUB_CATEGORY[0]
      })
  }


  private resetVariables(): void {
    this.showPoints = false;
    this.ripleyPoints = [];
    this.customerType = CUSTOMER_TYPE_SEGMENT_RIPLEY;
  }
}
