import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular';
declare var google: any;

@Component({
  selector: 'app-map-width-indication',
  templateUrl: './map-width-indication.component.html',
  styleUrls: ['./map-width-indication.component.scss'],
})
export class MapWithIndicationComponent implements OnInit {

  @Input() public title: string;
  @Input() public address: string;
  @Input() public userLatitude: number;
  @Input() public userLongitude: number;
  @Input() public focusDistance: number;
  @Input() public focusLatitude: number;
  @Input() public focusLongitude: number;
  public map: google.maps.Map;
  public loading: boolean;
  public googleService: google.maps.places.PlacesService;
  @ViewChild('map') public mapElement: ElementRef;

  public isTotem: boolean;

  constructor(private platform: Platform) {
    this.loading = true;
    this.isTotem = false;
  }

  public ngOnInit() {
    this.loadMap();
    this.createMarker();
    this.loading = false;
  }

  public loadMap() {
    const latLng = new google.maps.LatLng(this.userLatitude, this.userLongitude);
    const mapOptions = {
      center: latLng,
      zoom: 16 - this.focusDistance,
      disableDefaultUI: true,
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    this.googleService = new google.maps.places.PlacesService(this.map);
  }

  // tslint:disable: no-unused-expression
  public createMarker() {
    const iconActive = {
      url: 'assets/icons/pin-benefits-active.png',
      scaledSize: new google.maps.Size(29, 36),
      labelOrigin: null
    };
    new google.maps.Marker({
      position: { lat: this.focusLatitude, lng: this.focusLongitude },
      map: this.map,
      icon: iconActive,
    });
    new google.maps.Marker({
      position: { lat: this.userLatitude, lng: this.userLongitude },
      map: this.map,
    });
  }

  public openDirectionsInApp() {
    const geoCoords = this.focusLatitude + ',' + this.focusLongitude;
    if (this.platform.is('ios')) {
      window.open('https://maps.apple.com/?q=' + geoCoords, '_system');
    } else {
      window.open('https://maps.google.com/?q=' + geoCoords, '_system');
    }
  }

}