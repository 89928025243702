import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { EFEX_REAL_NAME, SEND_MY_NUMBER, SMS_ERROR, TYPE_IS_NOT_MY_NUMBER, TYPE_SMS_NOT_SENT } from '@common/constants/efex-automatic.constants';
import { ModalController } from '@ionic/angular';
import { HideTextPipe } from '@pipes/hide-text-number/hide-text-number.pipe';
import { CREDITS_ROUTE } from '@common/constants/routes.constants';
import { EFEX, RIPLEY_PHONE, SCREEN_WIDTH, SEF } from '@common/constants/misc.constants';
import { IEfexSefState } from '@common/interfaces/efex-sef-automatic.interface';
import { LoanLeadService } from '@services/loan-lead/loan-lead.service';
import { SEF_REAL_NAME } from '@common/constants/sef-automatic.constants';
import { LEAD_EXECUTION_CODE, ORIGIN_LOAN_PWA, PRODUCT_EFEX, PRODUCT_SEF } from '@common/constants/offerings.constants';
import { ILoanLead } from '@common/interfaces/loan-lead.interface';
import { DISBURSEMENT_FAILED_MOBILE_MODAL_PROPS } from '@common/constants/modal-props.constants';
import { LeadExistModalComponent } from '@components/leads-modal/lead-exist-modal/lead-exist-modal.component';
import { DisbursementErrorComponent } from '../disbursement-error/disbursement-error.component';
import { SUCCESS } from '@common/constants/error.constants';
import { LEAD_EXIST_ERROR } from '@common/constants/efex.constants';
import { HOME_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { GENERIC_TAG_BANCO_RIPLEY, GENERIC_TAG_DESGRAVAMEN, GENERIC_TAG_DESGRAVAMEN_CON_DEVOLUCION, GENERIC_TAG_OTRO_BANCO, GENERIC_TAG_POP_UP } from '@common/constants/tag/tag-generic.constants';
import { UtilsService } from '@services/utils/utils';

@Component({
  selector: 'app-alternative-offer',
  templateUrl: './alternative-offer.component.html',
  styleUrls: ['./alternative-offer.component.scss'],
})
export class AlternativeOfferComponent implements OnInit {

  @Input() productRealName: string;
  @Input() messageType: string;
  @Input() phoneNumber?: string = undefined;
  @Input() states: IEfexSefState;
  @Input() icon?: string;
  @Input() bankName: string = '';
  @Input() ensuranceType: boolean = false;
  newPhoneNumber:string = '';
  primaryButton: string;
  secondaryButton: string;
  isServiceLoading = false;
  constructor(private viewController: ModalController,
              private router: Router,
              private loanLeadService: LoanLeadService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private utilsService: UtilsService) {
  }

  ngOnInit() {
    this.loadButtonNames();
    const codeProduct = this.getCodeProduct(this.productRealName);
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_NOTMYNUMBER_SMS.sub_category = codeProduct == PRODUCT_EFEX ? EFEX : SEF;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_NOTMYNUMBER_SMS.ensurance_type = codeProduct == PRODUCT_EFEX ? '' : this.ensuranceType == false ? GENERIC_TAG_DESGRAVAMEN : GENERIC_TAG_DESGRAVAMEN_CON_DEVOLUCION;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_NOTMYNUMBER_SMS.amount = this.states.simulatorOption.amount;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_NOTMYNUMBER_SMS.fee = this.states.simulatorOption.term;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_NOTMYNUMBER_SMS.type = this.bankName == '' ? GENERIC_TAG_BANCO_RIPLEY : GENERIC_TAG_OTRO_BANCO;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_NOTMYNUMBER_SMS.destination_bank = this.bankName == '' ? '' : this.utilsService.TakeOffAccentMark(this.bankName.toLowerCase());
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_POP_UP, HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_NOTMYNUMBER_SMS);
  }

  get title() {
    switch (this.messageType) {
      case TYPE_IS_NOT_MY_NUMBER:
        return SEND_MY_NUMBER.title;
      case TYPE_SMS_NOT_SENT:
        return SMS_ERROR.title;
      default:
        return '';
    }
  }

  get label() {
    switch (this.messageType) {
      case TYPE_IS_NOT_MY_NUMBER:
        const label_not_my_number = SEND_MY_NUMBER.label;
        return label_not_my_number;
      case TYPE_SMS_NOT_SENT:
        const hideTextPipe = new HideTextPipe();
        const hideNumber = hideTextPipe.transform(this.phoneNumber, 3);
        const label_sms_not_sent = SMS_ERROR.label + `<b>${hideNumber}</b>` + SMS_ERROR.label_end;
        return label_sms_not_sent;
      default:
        return '';
    }
  }

  get isMobile() { return window.innerWidth < SCREEN_WIDTH.TABLET; }

  async sendNumber(){
    const codeProduct = this.getCodeProduct(this.productRealName);
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_BTN_NOTMYNUMBER_SMS.sub_category = codeProduct == PRODUCT_EFEX ? EFEX : SEF;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_BTN_NOTMYNUMBER_SMS.ensurance_type = codeProduct == PRODUCT_EFEX ? '' : this.ensuranceType == false ? GENERIC_TAG_DESGRAVAMEN : GENERIC_TAG_DESGRAVAMEN_CON_DEVOLUCION;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_BTN_NOTMYNUMBER_SMS.amount = this.states.simulatorOption.amount;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_BTN_NOTMYNUMBER_SMS.fee = this.states.simulatorOption.term;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_BTN_NOTMYNUMBER_SMS.type = this.bankName == '' ? GENERIC_TAG_BANCO_RIPLEY : GENERIC_TAG_OTRO_BANCO;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_BTN_NOTMYNUMBER_SMS.destination_bank = this.bankName == '' ? '' : this.utilsService.TakeOffAccentMark(this.bankName.toLowerCase());
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_POP_UP, HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_BTN_NOTMYNUMBER_SMS);

    const executionCode = sessionStorage.getItem(LEAD_EXECUTION_CODE);
    if (executionCode !== null && executionCode !== undefined) {
      const loanLead: ILoanLead = {
        codeProduct,
        executionCode,
        reference: window.location.href,
        callCenterReason: 'NO-IS-CELLPHONE',
        originLoanPwa: sessionStorage.getItem(ORIGIN_LOAN_PWA),
        cellPhoneContact: this.phoneNumber ? '' : this.newPhoneNumber
      };
      this.isServiceLoading = true;
      
      if (codeProduct === PRODUCT_SEF ||
        (codeProduct === PRODUCT_EFEX && !this.states.isEfexPromotional)) {
        loanLead.simulatorKey = this.states.keySimulator;
        loanLead.simulatorSelectedKey = this.states.loanSimulator.hashCode;
      } else {
        loanLead.simulatorKey = '';
        loanLead.simulatorSelectedKey = '';
      }
      try {
        const loanLeadResponse = await this.loanLeadService.registerLoanLead(loanLead);
        if (loanLeadResponse.code === SUCCESS) {
          this.isServiceLoading = false;
          this.viewController.dismiss();
          return this.router.navigateByUrl(`/${CREDITS_ROUTE}`);
        } else if (loanLeadResponse.code === LEAD_EXIST_ERROR) {
          this.isServiceLoading = false;
          this.showExistLeadModal();
        } else {
          this.isServiceLoading = false;
          this.openErrorServiceModal();
        }
      } catch (err) {
        this.isServiceLoading = false;
        this.openErrorServiceModal();
      }
    }
  }

  public getCodeProduct(offerType: string) {
    switch (offerType) {
      case SEF_REAL_NAME:
        return PRODUCT_SEF;
      case EFEX_REAL_NAME:
        return PRODUCT_EFEX;
    }
  }

  loadButtonNames() {
    switch (this.messageType) {
      case TYPE_IS_NOT_MY_NUMBER:
        this.primaryButton = SEND_MY_NUMBER.webButton;
        break;
      case TYPE_SMS_NOT_SENT:
        this.primaryButton = SMS_ERROR.webButton;
        break;
      default:
        return '';
    }
  }

  public openPhoneNumber() {
    document.location.href = `tel:${RIPLEY_PHONE}`;
  }

  dismissModal(val = '') {
    val != '' ? this.viewController.dismiss(val) : this.viewController.dismiss();
  }
  
  async showExistLeadModal() {
    const props = {
      productName: this.productRealName
    };

    const modal = await this.viewController.create({
      component: LeadExistModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();

    modal.onDidDismiss().then(() => {
      return this.router.navigateByUrl(`/${CREDITS_ROUTE}`);
    });
  }

  async openErrorServiceModal() {
    const productName = this.productRealName === SEF_REAL_NAME ? 'préstamo ' + this.productRealName + '.' : this.productRealName + '.';
    
    const codeProduct = this.getCodeProduct(this.productRealName);
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_ERROR.sub_category = codeProduct == PRODUCT_EFEX ? EFEX : SEF;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_ERROR.ensurance_type = codeProduct == PRODUCT_EFEX ? '' : this.ensuranceType == false ? GENERIC_TAG_DESGRAVAMEN : GENERIC_TAG_DESGRAVAMEN_CON_DEVOLUCION;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_ERROR.amount = this.states.simulatorOption.amount;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_ERROR.fee = this.states.simulatorOption.term;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_ERROR.type = this.bankName == '' ? GENERIC_TAG_BANCO_RIPLEY : GENERIC_TAG_OTRO_BANCO;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_ERROR.destination_bank = this.bankName == '' ? '' : this.utilsService.TakeOffAccentMark(this.bankName.toLowerCase());
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_ERROR.auth_method = 'sms';
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_POP_UP, HOME_GA4_CONSTANTS.HOME_LOANS_S5_POPUP_ERROR);

    if (!this.isMobile) {
      const props = DISBURSEMENT_FAILED_MOBILE_MODAL_PROPS;
      props.message = props.content + productName + '.';
      const modal = await this.viewController.create({
        component: DisbursementErrorComponent,
        cssClass: 'form-modal',
        componentProps: props,
      });
      await modal.present();
      modal.onDidDismiss().then(() => {
        return this.router.navigateByUrl(`/${CREDITS_ROUTE}`);
      });
    } else {
      const props_mb = DISBURSEMENT_FAILED_MOBILE_MODAL_PROPS;
      props_mb.message = props_mb.content + productName + '.';
      const modal = await this.viewController.create({
        component: DisbursementErrorComponent,
        cssClass: 'form-modal',
        componentProps: props_mb,
      });
      await modal.present();
      modal.onDidDismiss().then((button) => {
        if (button.data === 'primaryButtonPressed') {
          this.router.navigateByUrl(`/${CREDITS_ROUTE}`);
          this.openPhoneNumber();
          return;
        } else {
          return this.router.navigateByUrl(`/${CREDITS_ROUTE}`);
        }
      });
    }
  }

}