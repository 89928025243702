import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthWidgetV3Messages, AuthWidgetV3Status } from '@common/constants/digital-auth-form.constants';
import { GENERIC_MODAL_WAIT, TRANSFER_ERROR_GENERAL, TRANSFER_FAILED_MODAL_GENERAL_PROPS, TRANSFER_IMMEDIATE_ERROR_GENERAL } from '@common/constants/modal-props.constants';
import { HOME_ROUTE, R_PASS_INTRO_ROUTE } from '@common/constants/routes.constants';
import { AUTHORIZATION_CODE, ELEMENT_WIDGET_AUTHORIZED, ELEMENT_WIDGET_FAILED, ELEMENT_WIDGET_LOADING, ELEMENT_WIDGET_OK, ELEMENT_WIDGET_REJECTED, STATUS_CODE, TRANSACTION_INFO, TRANSFER_SUCCESS, VALIDATION_WIDGET_ERROR } from '@common/constants/transfers.constants';
import { IDataLoaded } from '@common/interfaces/default.interface';
import { IDetail } from '@common/interfaces/digital-auth.interface';
import { IElement } from '@common/interfaces/digital-card.interface';
import { IInfoDetailTransfer, ITransferGetTokenWidgetResponse, ITransferValidateWidgetResponse } from '@common/interfaces/transfers.interface';
import { IUserInformation } from '@common/interfaces/user.interface';
import { IValidityResponse } from '@common/interfaces/widget.interface';
import { GenericModalFooterComponent } from '@components/generic-modal-footer/generic-modal-footer.component';
import { GenericModalWaitComponent } from '@components/generic-modal-wait/generic-modal-wait.component';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { TransferErrorModalComponent } from '@components/transfer-error-modal/transfer-error-modal.component';
import { environment } from '@environments/environment';
import { ModalController, Platform } from '@ionic/angular';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { TransferService } from '@services/transfer/transfer.service';
import { UserService } from '@services/user/user.service';

@Component({
  selector: 'app-step-three-autorization-ob',
  templateUrl: './step-three-autorization-ob.component.html',
  styleUrls: ['./step-three-autorization-ob.component.scss'],
})
export class StepThreeAutorizationObComponent implements OnInit {

  isCTSOriginAccount: boolean;
  isNotDataLoading: boolean = false;
  typeAuth: string = '';
  targetClicked: string = '';
  transferTypeCtsW: string;
  trasnferTypeCodeW: string;
  transferSubTypeCodeW: string;
  transferTypeDesW: string;
  transfersSubTypeCode: string;
  transfersOptions: string;
  beneficiaryBank: string;
  beneficiaryAccount: string;
  beneficiaryDoc: string;
  sessionId: string;
  module:string = '';
  clientId: string = environment.WIDGET_CLIENT_ID;
  transactionInfo = TRANSACTION_INFO;
  userDataLoaded: IDataLoaded;
  errorModal: HTMLIonModalElement;  
  token: string;
  public currentElement: IElement; 
  @Input() isImmediate:boolean;
  @Input() transferInfo: IInfoDetailTransfer; 
  @Input() userInformation: IUserInformation;
  @Output() updateProgress = new EventEmitter();
  @Output() sendInfoValidateWidget = new EventEmitter<{transferInfo:IInfoDetailTransfer
                                                      ,response:ITransferValidateWidgetResponse
                                                      ,isLoaded:boolean}>();

  constructor(
    private modalErrorCtrl: ModalController,
    private platform: Platform,
    private transferService: TransferService,
    private userService: UserService,
    private router: Router,
    private modalController: ModalController,
    private formBuilder: FormBuilder, 
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    
    this.initializeVar();
 }


  async ngOnInit() {
   
  } 

  
 initializeVar(){   
  this.userDataLoaded = {
    isLoaded: false,
    hasErrors: false,
  };  
 }
 
  public getTransactionData(): IDetail[] {

    let keyCard ='WIDGET_TRANSFERS';
    let name = 'WIDGET_TRANSFERS'

    return [
      { key: 'S.Canal', value: this.platform.is('cordova') ? 'Aplicación móvil' : 'Plataforma Web' },
      { key: 'S.Operación', value: this.transferTypeDesW },
      { key: 'flow', value: 'TRNFRS' },      
      { key: 'amount', value: this.transferInfo.transferAmount},
      { key: 'PEN.Monto', value: this.transferInfo.transferAmount},
      { key: 'D.Fecha', value: new Date().toISOString() },
      { key: 'userId', value: this.userService.userInformation.documentNumber},
      { key: 'deviceType', value: this.platform.is('cordova') ? 'mobile' : 'desktop' },
      { key: 'TRNFRS.transferTypeCts', value: this.transferTypeCtsW},
      { key: 'TRNFRS.transferType', value: this.trasnferTypeCodeW},
      { key: 'TRNFRS.transferSubTypeCode', value: this.transferSubTypeCodeW},
      //nuevos parámetros
      { key: 'TRNFRS.TransferenciaTipo', value: '01' },//TransferenciaTipo
      { key: 'TRNFRS.transferenciaSubTipo', value: this.transfersSubTypeCode}, //transferenciaSubTipo
      { key: 'TRNFRS.transferenciaOpcion', value: this.transfersOptions }, //transferenciaOpcion
      { key: 'TRNFRS.origenCuentaTipo', value:  this.isCTSOriginAccount?'02':'01' }, //origenCuentaTipo
      { key: 'TRNFRS.beneficiarioBanco', value: this.beneficiaryBank }, //beneficiarioBanco
      { key: 'TRNFRS.beneficiarioCuenta', value: this.beneficiaryAccount }, //beneficiarioCuenta
      { key: 'TRNFRS.beneficiarioDoc', value: this.beneficiaryDoc }, //beneficiarioDoc  
      { key: 'TRNFRS.transferenciaMonto', value: this.transferInfo.transferAmount },//transferenciaMonto
      { key: 'TRNFRS.name', value: name },
      { key: 'TRNFRS.card', value: keyCard }

    ];
  }
  
  async verifyShowCard({ data }: IValidityResponse) {
    console.log("STATUS", data.status);
    console.log("MESSAGE", data.message);
   if (data.status === AuthWidgetV3Status.success) {      
      if (data.message === AuthWidgetV3Messages.initialized) {        
        this.currentElement = ELEMENT_WIDGET_OK; 
      } else if (data.message === AuthWidgetV3Messages.authorized) {
        console.log("Authorization selected", this.typeAuth);
          await this.validateWidget(
          this.sessionId,
          data.details.uuidTransaction,
          this.token,
          this.typeAuth,
          AuthWidgetV3Status.success,          
        );
      }else if (data.message === AuthWidgetV3Messages.activate_rpass) {
        this.router.navigateByUrl(R_PASS_INTRO_ROUTE);
      }
    } else if (data.status === AuthWidgetV3Status.failure) {
      let statuFailure:string;
      if (data.message === AuthWidgetV3Messages.canceled) {
        this.currentElement = VALIDATION_WIDGET_ERROR
        statuFailure = 'CANCELED';
        //this.dismissModal();
      } else if (data.message === AuthWidgetV3Messages.rejected) {
        this.currentElement = ELEMENT_WIDGET_REJECTED
        statuFailure = 'REJECTED';
      } 
    } else {
      this.currentElement = ELEMENT_WIDGET_FAILED;
    }
  }

  public async setPrivateKey():Promise<ITransferGetTokenWidgetResponse> {
    let optionModalWait:any;
    try {
       this.currentElement = ELEMENT_WIDGET_LOADING;
       this.isNotDataLoading = true;
      /* optionModalWait = await this.openGeneralWaitModal(GENERIC_MODAL_WAIT);
       optionModalWait.present();*/     
        let token = await this.getToken();
        if (Number(token.code)=== 0) {
          this.token = token.acccessToken;
          this.sessionId = token.sessionKey;
          this.currentElement = ELEMENT_WIDGET_OK;  
          //this.EventListenerDomInit();
        //await this.validateByPasswidget();
        }else {
          //Verificar cuando retorna vacío
          this.openTransferErrorModal(STATUS_CODE.find((code)=>(code.CODE == token.code.toString())).PROPMODAL);
        }       
        console.log("TOKEN ", token);
        return token;
   
    } catch (error) {      
      console.log(error);      
      return null;          
      
    }finally{
      this.isNotDataLoading = false;
      //optionModalWait.dismiss();
    }
  }

  public async  openGeneralWaitModal(prop): Promise<HTMLIonModalElement> {
    const modal = await this.modalErrorCtrl.create({
      component: GenericModalWaitComponent,
      cssClass: 'form-modal',
      componentProps: prop
    });
    return modal;
  } 
 
  public async openTransferGenericFailToHomeModal() {
      this.errorModal = await this.modalErrorCtrl.create({
        component: GenericModalComponent,
        cssClass: 'form-modal',
        componentProps: TRANSFER_FAILED_MODAL_GENERAL_PROPS
      });
      this.errorModal.onDidDismiss()
      .then((data) => {
        this.dismissModal();
        this.router.navigateByUrl(HOME_ROUTE);
       });

      this.errorModal.present();    
  }

  public async  openTransferErrorModal(prop) {
    const modal = await this.modalErrorCtrl.create({
      component: TransferErrorModalComponent,
      cssClass: 'form-modal',
      componentProps: prop
    });
    await modal.present();
  } 
  
  getvalueOptions(ownAccount:boolean, destinatarybankCode:string): string{ 
    switch (destinatarybankCode) {
      case '055':
        return ownAccount?'01':'03';          
      default:
        return ownAccount?'02':'04';
      }
}

  public async getToken(): Promise<ITransferGetTokenWidgetResponse>{

    try {

      let newToken;
        let transferRequestToken = {  
            baseCurrency: this.transferInfo.baseCurrency,
            amount: this.transferInfo.transferAmount,
            originAccount: this.transferInfo.originAccount,
            destinationAccount: this.transferInfo.destinationAccount,
            cCIDestinationAccount: this.transferInfo.destinationAccount,
            description: this.transferInfo.description,
            email: this.transferInfo.email,
            ownAccount: this.transferInfo.ownAccount,
            name: this.userInformation.names,
            lastName: this.userInformation.lastName,
            documentType: this.userInformation.documentType,
            documentNumber: this.userInformation.documentNumber,
            phoneNumber: this.transferInfo.phoneNumber,
            beneficiaryLastName: this.transferInfo.beneficiaryLastName,
            beneficiaryName: this.transferInfo.beneficiaryName,
            beneficiaryPhoneNumber: this.transferInfo.beneficiaryName,
            documentNumberBeneficiary: this.transferInfo.documentNumberBeneficiary,
            documentTypeBeneficiary: this.transferInfo.documentTypeBeneficiary,
            receiverDetailKey: this.transferInfo.receiverDetailKey
        }  
                  
        console.log("REQUEST ", transferRequestToken);
        this.transferTypeCtsW = this.isCTSOriginAccount ?'02':'01'; // 01: no es cuenta de origen CTS - 02: Si es cuenta de origen CTS
        this.beneficiaryBank = transferRequestToken.destinationAccount.slice(0, 3);
        this.transfersOptions = this.getvalueOptions(this.transferInfo.ownAccount, this.beneficiaryBank);
        this.beneficiaryAccount = transferRequestToken.destinationAccount;
        this.beneficiaryDoc = this.transferInfo.documentNumberBeneficiary;
        if (!this.isImmediate){     
          this.transferInfo.destinataryName = this.transferInfo.ownAccount?this.userInformation.names:
          (transferRequestToken.beneficiaryName + ' ' + transferRequestToken.beneficiaryLastName);       
          newToken = await this.transferService.getWidgetTokenInterbankTransfer(transferRequestToken); 
          
          if(newToken.code === '0'){
            this.transferInfo.itfAmount = newToken.itfAmount;
            this.transferInfo.commisionAmount = newToken.commisionAmount;
            this.trasnferTypeCodeW = this.transferInfo.ownAccount?'01':'02'; //01: cuentas propias - 02: cuentas de terceros 
            this.transferSubTypeCodeW = '01'; // 01: monto 9 000 como máximo
            this.transferTypeDesW = 'Transferencia Diferida';
            this.transfersSubTypeCode = '01';
          }            
        }else{
          newToken = await this.transferService.getWidgetTokenImmediateInterbankTransfer(transferRequestToken); 
          if (newToken.code === '0'){
            this.transferInfo.itfAmount = newToken.itfAmount;
            this.transferInfo.commisionAmount = newToken.commisionAmount;  
            this.transferInfo.destinataryName = newToken.itfAmount; 
            this.transferInfo.sameCustomerFlag = newToken.sameCustomerFlag;
            this.transferInfo.finantialInstitution =  (newToken.receiverBankName!==null)?newToken.receiverBankName.toUpperCase():null;  
            this.transferInfo.destinataryName = newToken.receiverCustomerName;       
            this.trasnferTypeCodeW = newToken.SameCustomerFlag?'01':'02'; //01: cuentas propias - 02: cuentas de terceros
            this.transferSubTypeCodeW = '02';  // 02: monto hasta 30 000 como máximo
            this.transferTypeDesW = 'Transferencia Inmediata';  
            this.transfersSubTypeCode = '02';
          }                            
        }
         
      return newToken;

    } catch (error) {
      console.log(error);
      return null;
    }
 }

 public async validateWidget(sessionId: string, 
                              uuidTransaction: string, 
                              authToken: string, 
                              authMethod, statusWg:string){
  try {

    this.sendInfoValidateWidget.emit({transferInfo:this.transferInfo,response:null,isLoaded:false});
    this.currentElement = ELEMENT_WIDGET_LOADING;
    let validateTokenRequest ={
      sessionId: sessionId,
      authWidgetToken: authToken , 
      uuidTransaction: uuidTransaction,
      TransferAuthorizationMethod: authMethod,
      StateWidget: statusWg
    }

    console.log("Request", validateTokenRequest);

    let dataCard: any;
    if(!this.isImmediate){
      dataCard = await this.transferService.validateWidgetTokenInterbankTransfer(validateTokenRequest);
    }else{            
      dataCard = await this.transferService.validateWidgetTokenInmediateInterbankTransfer(validateTokenRequest);
    }
        
    if (dataCard.code === TRANSFER_SUCCESS) {       
      this.transferInfo.transactionNumber = dataCard.transactionalSerialNumber;
     
      //Aquí tenemos que hacer un emit para mandar la información de que se tiene que llenar e ir al voucher final
     // this.fillTransferVoucher();  
      //this.router.navigate([`${VOUCHER_TRANSFER_ROUTE}`], { replaceUrl: true, state: this.voucher });

    }else{
      
      this.openTransferErrorModal(STATUS_CODE.find((code)=>(code.CODE == dataCard.code.toString())).PROPMODAL);
      this.currentElement = VALIDATION_WIDGET_ERROR
      this.userDataLoaded.hasErrors = true; 
      return;       
    }

    this.currentElement = ELEMENT_WIDGET_AUTHORIZED;
    this.sendInfoValidateWidget.emit({transferInfo:this.transferInfo,response:dataCard,isLoaded:true});
  
  } catch (error) {
    this.currentElement = VALIDATION_WIDGET_ERROR
    this.userDataLoaded.hasErrors = true;
    //Solo si la transferencia es inmediata hasta el momento, se envía el emit
    //Si es diferida, ya aparece el error general
    this.isImmediate? this.sendInfoValidateWidget.emit({transferInfo:this.transferInfo,response:null,isLoaded:true})
                    : this.openTransferGenericFailToHomeModal();   
  }
}

  public dismissModal() {
    this.modalErrorCtrl.dismiss();
  }

  setAuthAndTagValue(value:string){
    console.log("Option selected", value);
    this.typeAuth = AUTHORIZATION_CODE.find((item)=>(item.CODE==value)).DESCRIPTION;
    console.log("Authorization Selected", this.typeAuth);
  }

  @HostListener('click', ['$event']) onClick(e) {   
    if(e!==undefined) {   
      this.targetClicked = e.target.innerText;
      const parentClass = e.target.parentNode.parentElement.classList;
      console.log()
      //solo se compara cuando el evento se dió dentro del móduo del widget
      if (parentClass && (parentClass.contains('MuiButtonBase-root') 
          || parentClass.contains('MuiGrid-root')))
      {
        const value = e.target.parentNode.innerText.toUpperCase(); 
        if(value && (value.includes('SMS') || value.includes('RPASS'))){
          this.updateProgress.emit();
        }  
      }
    }
  }
  
}
