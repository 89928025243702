import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-see-fees',
  templateUrl: './see-fees.component.html',
  styleUrls: ['./see-fees.component.scss'],
})
export class SeeFeesComponent implements OnInit {

  @Input() data: [];
  final = [];
  
  constructor(private modalController: ModalController,) { }

  ngOnInit() {
    this.final = this.data.pop();
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
