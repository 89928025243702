export const LOTTIE_CONFIG = {
  path: 'assets/json/loading-spinner-pwa.json',
  autoplay: true,
  loop: true,
  renderer: 'svg',
  sizes: {
    big: {
      height: 120,
      width: 120
    },
    small: {
      height: 94,
      width: 94
    }
  }
};
