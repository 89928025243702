import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AHORRO_ROUTE, CONTACT_INFORMATION_ROUTE, CREDITS_ROUTE, ON_OF_ROUTE, PAYMENTS_ROUTE, PROMOTIONS_ROUTE, RIPLEY_POINTS_DASHBOARD_ROUTE, TRANSFERS_ROUTE } from '@common/constants/routes.constants';
import { IProductConsolidateRequest } from '@common/interfaces/product.interface';
import { ProductService } from '@services/product/product.service';
import { RuoffersService } from '@services/ruoffers/ruoffers.service';
import { SavingsService } from '@services/savings/savings.service';
import { UserService } from '@services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class HomePreloaderService {
  
  stopRequestsPropagation: boolean = false;
  routeAndLastRequest = [
    { route: `/${ON_OF_ROUTE}`, request: '/productshbk/product/active/v2/consolidate' }, //OK
    { route: `/${CONTACT_INFORMATION_ROUTE}`, request: '/pointsgo/ripley/consolidated' }, //NO HTTP CALLS IN COMPONENT 
    { route: `/${PROMOTIONS_ROUTE}`, request: 'admin/user/dataprofile' }, //NO HTTP CALLS IN COMPONENT
    { route: `/${AHORRO_ROUTE}`, request: '/productshbk/passive/openAccount/getDataClient' }, //OK
    { route: `/${TRANSFERS_ROUTE}`, request: '/productshbk/passive/detail/ctssaving' }, //NO HTTP CALLS IN COMPONENT
    { route: `/${PAYMENTS_ROUTE}`, request: '/product/loans/payment-information' }, //OK
    { route: `/${CREDITS_ROUTE}`, request: '/product/loans/information' }, //OK 
    { route: `/${RIPLEY_POINTS_DASHBOARD_ROUTE}`, request: '/hbk/pointsgo/ripley/consolidated' }, //NO HTTP CALLS IN COMPONENT
  ];

  constructor(private router: Router,
    private userService: UserService,
    private savingsService: SavingsService,
    private ruofferService: RuoffersService,
    private productService: ProductService) { 
  }

  public async preloadCallHomeRequests(actualRequestURL: string) {
    if(actualRequestURL.indexOf('/admin/user/dataprofile') != -1) return;
    if(this.routeAndLastRequest.find(el => el.route == this.router.url && actualRequestURL.indexOf(el.request) !=  -1)) {
      
      let userInformation =  await this.userService.getUserInformation()
      let consolidateRequest: IProductConsolidateRequest = {
        customerId: userInformation.customerId
      }

      let requestsArray: any = [
        this.savingsService.getDetailPassivesSaving(true),
        this.savingsService.getDetailPassivesCts(true),
        this.ruofferService.preloadRuoOffers(true)
      ];

      if(actualRequestURL.indexOf('/productshbk/product/active/v2/consolidate') == -1) {
        requestsArray.push(this.productService.getAuthenticatedConsolidatedProductsWebPwa(consolidateRequest, true));
      }
      
      await Promise.all(requestsArray);
    }
    
  }  
}
