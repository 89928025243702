import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-r-pass-activated',
  templateUrl: './r-pass-activated.component.html',
  styleUrls: ['./r-pass-activated.component.scss'],
})
export class RPassActivatedComponent {
  @Input() public isEnrolled: boolean = false;
  @Input() public enrolledInThis: boolean = false;
  @Output() public activateButtonEvent: EventEmitter<any> = new EventEmitter();
  @Output() public rpassUnsuscribe: EventEmitter<any> = new EventEmitter();
  @Output() public goBack: EventEmitter<any> = new EventEmitter();
  public isLoadingButton: boolean = false;

  public subscription: Subscription;

  ionViewWillEnter() {
    this.isLoadingButton = false;
  }

  public activateButtonEmit(): void {
    this.isLoadingButton = true;
    this.activateButtonEvent.emit(true);
  }

  public rpassUnsubscribe(): void {
    this.rpassUnsuscribe.emit();
  }

  public goBackEvent(): void {
    this.goBack.emit();
  }

}