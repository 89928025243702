export interface OfferDescription {
    promCode: string;
    amountDiscount: string;
  }
  
  export interface OfferDetail {
    priceToApplyDiscount: string;
    promotions: OfferDescription[];
  }
  
  export interface Offer {
    normal: OfferDetail;
    ripleyCard: OfferDetail;
  }
  
  export interface Product {
    ean13: string;
    quantity: string;
    description: string;
    currentPrice: string;
    promotion: Offer;
    messageNormal: string;
    messageRipleyCard: string;
    masterPrice: string;
    subLine: string;
    depto: string;
    line: string;
    eanStyle: string;
    event: string;
    season: string;
    extraGuarantee: any[];
  }

  export class ProductConfig {
    public hoursToReload: number;
    public productDetailErrorMessage: string;
    public productNotFoundError: string;
    public promoProductActive: boolean;
    public vendorId: string;
  }
  
  export class StoreAttributes {
    public value: string;
    public name: string;
    public displayValue: string;
  }
  
  export class RipleyStore {
    public name: string;
    public distance: string;
    public rawDistance?: number;
    public description: string;
    public latitude: string | number;
    public longitude: string | number;
    public state: string;
    public uniqueId: string;
    public address: string[];
    public attributes: StoreAttributes[];
  }