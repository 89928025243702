import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CustomAlertsService {

  constructor(
    private alertCtrl: AlertController
  ) { }

  public async promptForCustomAction(options: any, cssClass: string, button?: any) {
    const messageTemplate = typeof options === 'object' ? this.generateTemplate(options) : options;
    const alertOptions = {
      message: messageTemplate,
      buttons: button ? button : [{ text: button || 'Aceptar', handler: () => false }],
      cssClass: cssClass || '',
      enableBackdropDismiss: false,
      backdropDismiss: false
    };
    const alert = await this.alertCtrl.create(alertOptions);

    await alert.present();
  }

  private generateTemplate(options) {
    const optionalIcon = options.icon ? `
    <ion-row>
      <img class="center-image" src='./assets/icons/${options.icon}.svg'>
    </ion-row>` : '';
    return `<ion-grid>
        ${optionalIcon}
      <ion-row>
        <ion-col>
          <p class="bold-purple">${options.title}</p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <p>${options.message}</p>
        </ion-col>
      </ion-row>
     </ion-grid>`;
  }

}
