import { Platform } from '@ionic/angular';
import { JSONIP_MOCK, IDENTIFIER_MOCK } from '@common/mocks/device.mocks';
import { HttpService } from '@services/http/http.service';
import { Injectable } from '@angular/core';
import { TOKEN_KEYBOARD_RESPONSE } from '@common/mocks/tokenize-keyboard.mocks';
import { environment } from '@environments/environment';
import { UtilsService } from '@services/utils/utils';
import { IGetIP } from '@common/interfaces/device.interface';
import { deviceDetect } from 'mobile-device-detect';
import { IP_REGEX } from '@common/constants/misc.constants';
import { HttpHeaders } from '@angular/common/http';
import { Device } from '@ionic-native/device/ngx';
import { shareReplay } from 'rxjs/operators';
import { DeviceUUID } from 'device-uuid';
import { UAParser } from 'ua-parser-js';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  ipObj: IGetIP = null;
  ipObjv4: IGetIP = null;
  UniqueDeviceID: string;
  uaParser: any = null;
  constructor(
    private device: Device,
    private http: HttpService,
    private platform: Platform,
    private utilsService: UtilsService
  ) { }

  public async getTokenizedKeyboard(device: number) {
    const url = environment.BASE_BACKEND_WORKER + `/keyboard/get/${device}`;
    return await this.http.get(url, TOKEN_KEYBOARD_RESPONSE).toPromise();
  }

  public async getIP(): Promise<IGetIP> {
    if (this.ipObj == null) {
      const urls = environment.IP_PROVIDERS;
      let promiseArray: Promise<IGetIP>[] = [];
      for (let url of urls) {
        promiseArray.push(this.http.get(url, JSONIP_MOCK).pipe(shareReplay(1)).toPromise());
      }

      //manual implementation of Promise.any inverting Promise.all logic
      this.ipObj = await (Promise.all(promiseArray.map(async p => {
        return p.then(
          successValue => Promise.reject(successValue),
          error => Promise.resolve(error)
        );
      })).then(
        errors => Promise.reject(errors),
        successValue => Promise.resolve(successValue)
      ).catch(() => {
        return { ip: '', country: '', city: '', isp: '' }
      }));
    }

    return this.ipObj;
  };


  public async getIPv4(): Promise<IGetIP> {
    const url = 'https://ifconfig.me/all.json';
    if (this.ipObjv4 == null) {
      //manual implementation of Promise inverting toPromise logic
      this.ipObjv4 = await this.http.get(url, JSONIP_MOCK).toPromise().then(
        successValue => Promise.resolve({ ip: successValue.ip_addr, country: '', city: '', isp: '' }),
        errors => Promise.reject(errors)
      ).catch(() => {
        return { ip: '127.0.0.1', country: '', city: '', isp: '' }
      });
    }

    return this.ipObjv4;
  }

  public isIpValid(hostIPAddress: string): boolean {
    if (hostIPAddress !== undefined && hostIPAddress !== null) {
      if (hostIPAddress === '') { return true; }
      const match_result = hostIPAddress.match(IP_REGEX);
      if (match_result !== null) {
        return match_result.length > 0;
      }
    }
    return false;
  }

  public async createIdentifier(): Promise<number> {
    const res: IGetIP = await this.getIP()
      .catch(() => {
        return { ip: '', country: '', city: '', isp: '' };
      });
    const params = {
      generateAppId: UtilsService.getUUIDV4(),
      platform: this.getPlatformName(),
      ip: res.ip,
      navigator: window.navigator.userAgent,
      wildField1: this.getWildField1(),
      wildField2: ''
    };
    let headers = {} as HttpHeaders;
    headers = new HttpHeaders().set('Channel', this.utilsService.getChannelCode());

    const url = environment.BASE_BACKEND_WORKER + `/keyboard/deviceidentificator`;
    return await this.http.post(url, params, IDENTIFIER_MOCK, headers).toPromise();
  }

  public getPlatformName(): string {
    let platformName = navigator.platform;
    if (this.platform.is('android')) {
      platformName = 'android';
    } else if (this.platform.is('ios')) {
      platformName = 'ios';
    } else {
      platformName = this.getOSName();
    }
    return platformName;
  }

  public getWildField1() {
    const deviceInfo = deviceDetect();
    let wildField = 'desktop';
    if (deviceInfo.isMobile) {
      wildField = 'mobile';
    } else if (deviceInfo.isTablet) {
      wildField = 'tablet';
    }
    return wildField.toUpperCase();
  }

  public getDevice() {
    let deviceModel = "";
    if (this.device.model)
    {
      deviceModel = this.device.model;
    } else if (this.checkUAParser())
    {
      if (this.uaParser.getDevice().model)
      {
        deviceModel = this.uaParser.getDevice().model;
      }
    }
    return deviceModel;
  }

  public getDeviceManufacturer() {
    let deviceManufacturer = "";
    if (this.device.manufacturer)
    {
      deviceManufacturer = this.device.manufacturer;
    } else if (this.checkUAParser())
    {
      if (this.uaParser.getDevice().vendor)
      {
        deviceManufacturer = this.uaParser.getDevice().vendor;
      }
    }
    return deviceManufacturer;
  }

  public getDeviceId() {
    let uuid ="";
    if(this.device.uuid){
      uuid = this.device.uuid;
    } else if (DeviceUUID){
      // uuid = new DeviceUUID().get();
      uuid = this.genDeviceUUID();
    }
    return uuid;
  }

  public getDeviceVersion() {
    let version ="";
    if(this.device.version){
      version = this.device.version;
    } else {
      version = this.getOSVersion();
    }
    return version;
  }
  public getIMEI() {
    return this.device.version;
  }
  getNumberHardwareProcessor() {
    return window.navigator.hardwareConcurrency
  }
  getNumberMemoryRam() {
    // @ts-ignore
    const memory = window.navigator.deviceMemory;
    return memory ? memory : "";
  }

  public getVideoCardVendor(): string {
    const gl = document.createElement('canvas').getContext('webgl');
    if (!gl) {
      return '';
    }
    else {
      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      return debugInfo ? gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL) : '';
    }
  }

  public getVideoCardRenderer(): string {
    const gl = document.createElement('canvas').getContext('webgl');
    if (!gl) {
      return '';
    }
    else {
      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      return debugInfo ? gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) : "no WEBGL_debug_renderer_info";
    }
  }

  public async getCountry() {
    const res: IGetIP = await this.getIP()
      .catch(() => {
        return { ip: '', country: '', city: '', isp: '' };
      });
    return res ? res.country : '';
  }

  public async getCity() {
    const res: IGetIP = await this.getIP()
      .catch(() => {
        return { ip: '', country: '', city: '', isp: '' };
      });
    return res ? res.city : '';
  }

  public async getISP() {
    const res: IGetIP = await this.getIP()
      .catch(() => {
        return { ip: '', country: '', city: '', isp: '' };
      });
    return res ? res.isp : '';
  }

  public getMacAddress() {
    return  '';
  }
  public getImei() {
    return  '';
  }

  public getUA(): string {
    return navigator.userAgent || '';
  }

  public getNavegadorIdentifier() : string {
    let agentIdentifier = "";
    if (this.checkUAParser())
    {
      agentIdentifier = this.uaParser.getBrowser().name;
    }
    else
    {
      let userAgentString = window.navigator.userAgent;
      userAgentString = userAgentString.replace(/^\s*/, '').replace(/\s*$/, '');
      const ua = new DeviceUUID();
      agentIdentifier = ua.getBrowser(userAgentString);
    }
    return agentIdentifier;
  }

  private genDeviceUUID(): string {
    let uuid = "";
    const pref = 'b';
    if (DeviceUUID) {
      let du = new DeviceUUID().parse();
      let dua = [
        du.platform,
        du.os,
        du.isAndroid,
        du.isAuthoritative,
        du.silkAccelerated,
        du.isKindleFire,
        du.isCaptive,
        du.isDesktop,
        du.isMobile,
        du.isTablet,
        du.isWindows,
        du.isLinux,
        du.isLinux64,
        du.isMac,
        du.isChromeOS,
        du.isiPad,
        du.isiPhone,
        du.isiPod,
        du.isSmartTV,
        du.isTouchScreen
      ];
      const tmpUuid = du.hashMD5(dua.join(':'));
      let tUuid = [
          tmpUuid.slice(0,8),
          tmpUuid.slice(8,12),
          '4' + tmpUuid.slice(12,15),
          pref + tmpUuid.slice(15,18),
          tmpUuid.slice(20)
      ];
      uuid = tUuid.join('-');
    }
    return uuid;
  }

  public getNavegadorVersion() : string {
    let agentVersion = "";
    if (this.checkUAParser())
    {
      agentVersion = this.uaParser.getBrowser().version;
    }
    else
    {
      const userAgentString = window.navigator.userAgent;
      let ua = new DeviceUUID();
      ua.Agent.source = userAgentString.replace(/^\s*/, '').replace(/\s*$/, '');
      ua.Agent.browser = ua.getBrowser(ua.Agent.source);
      agentVersion = ua.getBrowserVersion(userAgentString);
    }
    return agentVersion;
  }

  public getNavegadorVersionMajor() : string {
    let agentVersionMajor = "";
    const agentVersion = this.getNavegadorVersion();
    if(agentVersion){
      agentVersionMajor = agentVersion.split(".")[0];
    }
    return agentVersionMajor;
  }

  public getEngineName() : string {
    const userAgentString = window.navigator.userAgent;
    let ua = new DeviceUUID();
    ua.Agent.source = userAgentString.replace(/^\s*/, '').replace(/\s*$/, '');
    ua.Agent.browser = ua.getBrowser(ua.Agent.source);
    ua.testCompatibilityMode();
    let agentEngineName = "";
    if (ua.Agent.isIE){
      agentEngineName = "Trident";
    } else if (ua.Agent.isFirefox) {
      agentEngineName = "Gecko";
    } else if (ua.Agent.source.includes("WebKit")){
      agentEngineName = "WebKit";
    }
    return agentEngineName;
  }

  public getEngineVersion() : string {
    const userAgentString = window.navigator.userAgent;
    let ua = new DeviceUUID();
    ua.Agent.source = userAgentString.replace(/^\s*/, '').replace(/\s*$/, '');
    ua.Agent.browser = ua.getBrowser(ua.Agent.source);
    ua.testCompatibilityMode();
    let agentEngineVersion = "";
    if (ua.Agent.isIE){
      agentEngineVersion = ua.Agent.source.split("Trident/")[1].split(";")[0];
    } else if (ua.Agent.isFirefox) {
      agentEngineVersion = ua.Agent.source.split("Gecko/")[1].split(" ")[0];
    } else if (ua.Agent.source.includes("WebKit")){
      agentEngineVersion = ua.Agent.source.split("AppleWebKit/")[1].split(" ")[0];
    }
    return agentEngineVersion;
  }

  public getArchitecture(): string {
    let architecture = "";
    if (this.checkUAParser())
    {
      architecture = this.uaParser.getCPU().architecture ? this.uaParser.getCPU().architecture : 'x64';
    }
    return architecture;
  }

  private getOSName() : string {
    let osName = "";
    let isDevice: boolean = false;
    if (this.checkUAParser())
    {
      isDevice = this.uaParser.getDevice().vendor ? true : false;
    }
    if (isDevice)
    {
      osName = this.uaParser.getOS().name;
    }
    else
    {
      let userAgentString = window.navigator.userAgent;
      userAgentString = userAgentString.replace(/^\s*/, '').replace(/\s*$/, '');
      const clientStrings = [
        {s:'Windows 3.11', r:/Win16/},
        {s:'Windows 95', r:/(Windows 95|Win95|Windows_95)/},
        {s:'Windows ME', r:/(Win 9x 4\.90|Windows ME)/},
        {s:'Windows 98', r:/(Windows 98|Win98)/},
        {s:'Windows CE', r:/Windows CE/},
        {s:'Windows NT 4.0', r:/(Windows NT 4\.0|WinNT4\.0|WinNT)/},
        {s:'Windows 2000', r:/(Windows 2000|Windows NT 5\.0)/},
        {s:'Windows XP', r:/(Windows XP|Windows NT 5\.1)/},
        {s:'Windows Server 2003', r:/Windows NT 5\.2/},
        {s:'Windows Vista', r:/Windows NT 6\.0/},
        {s:'Windows 7', r:/(Windows 7|Windows NT 6\.1)/},
        {s:'Windows 8', r:/(Windows 8|Windows NT 6\.2)/},
        {s:'Windows 8.1', r:/(Windows 8.1|Windows NT 6\.3)/},
        {s:'Windows 10', r:/(Windows 10|Windows NT 10\.0)/},
        {s:'Windows Phone 8', r:/(Windows Phone 8\.)/},
        {s:'Mac OS X Cheetah', r:/(OS X 10[._]0)/},
        {s:'Mac OS X Puma', r:/(OS X 10[._]1(\D|$))/},
        {s:'Mac OS X Jaguar', r:/(OS X 10[._]2)/},
        {s:'Mac OS X Panther', r:/(OS X 10[._]3)/},
        {s:'Mac OS X Tiger', r:/(OS X 10[._]4)/},
        {s:'Mac OS X Leopard', r:/(OS X 10[._]5)/},
        {s:'Mac OS X Snow Leopard', r:/(OS X 10[._]6)/},
        {s:'Mac OS X Lion', r:/(OS X 10[._]7)/},
        {s:'Mac OS X Mountain Lion', r:/(OS X 10[._]8)/},
        {s:'Mac OS X Mavericks', r:/(OS X 10[._]9)/},
        {s:'Mac OS X Yosemite', r:/(OS X 10[._]10)/},
        {s:'Mac OS X El Capitan', r:/(OS X 10[._]11)/},
        {s:'Mac OS X Sierra', r:/(OS X 10[._]12)/},
        {s:'Mac OS X High Sierra', r:/(OS X 10[._]13)/},
        {s:'Mac OS X Mojave', r:/(OS X 10[._]14)/},
        {s:'Mac OS X Catalina', r:/(OS X 10[._]15)/},
        {s:'Mac OS X Big Sur', r:/(OS X 11[._]6)/},
        {s:'Mac OS X Monterey', r:/(OS X 12[._]5)/},
        {s:'Mac OS X', r:/(OS X)/},
        {s:'Mac', r:/(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
        {s:'Linux', r:/(Linux|X11)/},
        {s:'Linux64', r:/(Linux X86_64)/},
        {s:'Sun OS', r:/SunOS/},
        {s:'QNX', r:/QNX/},
        {s:'UNIX', r:/UNIX/},
        {s:'BeOS', r:/BeOS/},
        {s:'OS/2', r:/OS\/2/},
        {s:'ChromeOS', r:/Cros/},
        {s:'Wii', r:/Wii/},
        {s:'PS3', r:/Playstation 3/},
        {s:'PSP', r:/Playstation portable/},
        {s:'Android', r:/Android/},
        {s:'iPad', r:/\(iPad.*os (\d+)[._](\d+)/},
        {s:'iPhone', r:/\(iPhone.*os (\d+)[._](\d+)/},
        {s:'Bada', r:/Bada\/(\d+)\.(\d+)/},
        {s:'Curl', r:/curl\/(\d+)\.(\d+)\.(\d+)/},
        {s:'Open BSD', r:/OpenBSD/},
        {s:'Search Bot', r:/(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
      ];
      for (let id in clientStrings) {
          let cs = clientStrings[id];
          if (cs.r.test(userAgentString)) {
              osName = cs.s;
              break;
          }
        }
    }
    return osName;
  }

  private getOSVersion() : string {
    let osVersion = "";
    let isDevice: boolean = false;
    if (this.checkUAParser())
    {
      isDevice = this.uaParser.getDevice().vendor ? true : false;
    }
    if (isDevice)
    {
      osVersion = this.uaParser.getOS().version;
    }
    else
    {
      let userAgentString = window.navigator.userAgent;
      userAgentString = userAgentString.replace(/^\s*/, '').replace(/\s*$/, '');
      const osName = this.getOSName();

      if (osName.includes('Windows Phone'))
        osVersion = /Windows Phone (.*)/.exec(osName)[1];
      else if (osName.includes('Windows'))
        osVersion = /Windows (.*)/.exec(osName)[1];
      else if (osName.match(/Mac OS X (.*)/))
        if (osName.includes('Big Sur'))
          osVersion = /Mac OS X (11[\.\_\d]+)/.exec(userAgentString)[1].replace(/\_/g, '.');
        else if (osName.includes('Monterey'))
          osVersion = /Mac OS X (12[\.\_\d]+)/.exec(userAgentString)[1].replace(/\_/g, '.');
        else
          osVersion = /Mac OS X (10[\.\_\d]+)/.exec(userAgentString)[1].replace(/\_/g, '.');
      else if (osName == 'Android')
          osVersion = /Android ([\.\_\d]+)/.exec(userAgentString)[1].replace(/\_/g, '.');
      else if (osName == 'iPad' || osName == 'iPhone') {
          const rgVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(userAgentString);
          osVersion = rgVersion[1] + '.' + rgVersion[2] + '.' + (rgVersion[3] ? rgVersion[3] : 0);
      }
    }
    return osVersion;
  }

  private checkUAParser() : boolean {
    if (this.uaParser == null){
      this.uaParser = new UAParser();
    }
    return (this.uaParser != null) ? true : false;
  }
}
