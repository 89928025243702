import { Animation } from '@ionic/core';

export function slideInAnimation(AnimationC: Animation, baseEl: HTMLElement): Promise<Animation> {

  const timeInMilliseconds = 400;
  const fromValue = 0.01;
  const toValue = 0.4;
  const baseAnimation = new AnimationC();
  const backdropAnimation = new AnimationC();
  backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

  const wrapperAnimation = new AnimationC();
  wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));

  wrapperAnimation.beforeStyles({ opacity: 1 })
      .fromTo('translateX', '100%', '0%');

  backdropAnimation.fromTo('opacity', fromValue, toValue);

  return Promise.resolve(baseAnimation
    .addElement(baseEl)
    .easing('cubic-bezier(0.36,0.66,0.04,1)')
    .duration(timeInMilliseconds)
    .beforeAddClass('show-modal')
    .add(backdropAnimation)
    .add(wrapperAnimation));
}
