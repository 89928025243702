import {
  Component,
  ViewChild,
  Input
} from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { IInformativeModal } from '@common/interfaces/promotion.interface';
import { SCREEN_WIDTH } from '@common/constants/misc.constants';
import { OnInit, HostListener } from '@angular/core';
import { UtilsService } from '@services/utils/utils';
import {
  HEIGHT_TEMPLATE_DESKTOP_3,
  HEIGHT_TEMPLATE_MOBILE_3,
  HEIGHT_TEMPLATE_MOBILE_4,
  FULL_BACKGROUND_TEMPLATES,
  BOX_TEMPLATES,
  DARK_BACKGROUND_TEMPLATES,
  NO_RESIZE_HEIGHT_TEMPLATES,
  HEIGHT_RESIZE_FULL_BACKGROUND,
  HEIGHT_RESIZE_MOBILE_3,
  RESIZE_PERCENT_HEIGHT,
  CENTERED_BACKGROUND_TEMPLATES,
  SMALL_MOBILE_TEMPLATES,
  MODAL_ORIGIN_RUO,
  MODAL_TYPE_VENTAS
} from '@common/constants/informative-modals.constants';
import { Router } from '@angular/router';
import { EFEX_AUTO_ROUTE, EFEX_ROUTE, SEF_ROUTE } from '@common/constants/routes.constants';
import { ILoan, IOffering, IOfferState } from '@common/interfaces/offerings.interface';
import { LEAD_EXECUTION_CODE, OFFERS_LOAN, PRODUCT_NAMES, PRODUCT_SEF, PRODUCT_EFEX, NAME_SEF, NAME_EFEX, NAME_SEF_REENGANCHE, ORIGIN_LOAN_PWA, ORIGIN_LOAN_PWA_OPT, PRODUCT_SEF_REENGANCHE } from '@common/constants/offerings.constants';
import { OfferingsService } from '@services/offerings/offerings.service';
import {
  END_TO_END_OFF_MODAL_PROPS, LOAN_LEAD_FAILED_MODAL_PROPS,
  OFFER_EFEX_INACTIVE_MODAL_PROPS, OFFER_RSEF_INACTIVE_MODAL_PROPS, OFFER_SEF_INACTIVE_MODAL_PROPS
} from '@common/constants/modal-props.constants';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { ILeadModalBody, ILoanLead } from '@common/interfaces/loan-lead.interface';
import { LeadGenericModalComponent } from '@components/leads-modal/lead-generic-modal/lead-generic-modal.component';
import { OfferingModalComponent } from '@components/offering-modal/offering-modal.component';
import { StorageService } from '@services/storage/storage.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { USER_INFO } from '@common/constants/auth.constants';
import { SUCCESS } from '@common/constants/error.constants';

@Component({
  selector: 'app-informative-modal',
  templateUrl: './informative-modal.component.html',
  styleUrls: ['./informative-modal.component.scss'],
})
export class InformativeModalComponent implements OnInit {
  fadeOut: boolean;
  hide: boolean;
  slideIsMoving: boolean;
  currentIndex: number;
  fullBackgroundTemplates: string[];
  darkBackgroundTemplates: string[];
  boxTemplates: string[];
  noResizeHeightTemplates: string[];
  centeredBackgroundTemplates: string[];
  smallMobileTemplates: string[];
  slideOptions: object;
  isActive: boolean;
  @Input() allInformativeElements: IInformativeModal[];
  @Input() offerings: IOffering[] = [];
  offer: IOffering[];
  @ViewChild(IonSlides) slides: IonSlides;
  mobileElements: IInformativeModal[];
  desktopElements: IInformativeModal[];
  informativeElements: IInformativeModal[] = [];
  screenHeight: number;
  isModalInformative = false;
  loadingStatusClass: string;
  isLoading: boolean;

  constructor(private router: Router,
              private modalCtrl: ModalController,
              private startLoans: OfferingsService,
              private firebaseService: FirebaseService,
              private storage: StorageService) {
    this.fadeOut = false;
    this.hide = false;
    this.slideIsMoving = false;
    this.currentIndex = 0;
    this.fullBackgroundTemplates = FULL_BACKGROUND_TEMPLATES;
    this.darkBackgroundTemplates = DARK_BACKGROUND_TEMPLATES;
    this.boxTemplates = BOX_TEMPLATES;
    this.noResizeHeightTemplates = NO_RESIZE_HEIGHT_TEMPLATES;
    this.centeredBackgroundTemplates = CENTERED_BACKGROUND_TEMPLATES;
    this.smallMobileTemplates = SMALL_MOBILE_TEMPLATES;
    this.loadingStatusClass = '';
    this.isLoading = false;
    this.createSliderOptions();
  }

  ngOnInit() {
    this.isActive = true;
    this.setModalElements();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setModalElements();
  }

  get isLastElement(): boolean {
    return (this.informativeElements.length - 1) === this.currentIndex;
  }

  get isFirstElement(): boolean {
    return this.currentIndex === 0;
  }

  get currentElement(): IInformativeModal {
    return this.informativeElements[this.currentIndex];
  }

  get isBoxTemplate(): boolean {
    return this.boxTemplates.includes(this.informativeElements[this.currentIndex].template_Code);
  }

  get isMobile() {
    return window.innerWidth <= SCREEN_WIDTH.MOBILE;
  }

  get isActiveModal() {
    return this.isActive;
  }

  public updateElement(amount: number) {
    const newElement = this.currentIndex + amount;
    if (newElement < this.informativeElements.length && newElement >= 0) {
      this.currentIndex = newElement;
    }
  }

  public async changeSlideManually(action: string) {
    if (!this.slideIsMoving) {
      if (action === 'right' && !this.isLastElement) {
        this.slideIsMoving = true;
        await this.slides.slideNext();
      } else if (action === 'left' && !this.isFirstElement) {
        this.slideIsMoving = true;
        await this.slides.slidePrev();
      }
    }
  }

  public async changeSlide() {
    const [previous, current] = await Promise.all([
      this.slides.getPreviousIndex(),
      this.slides.getActiveIndex(),
    ]);
    const direction = previous - current;
    direction < 0 ? this.updateElement(1) : this.updateElement(-1);
    this.slideIsMoving = false;
  }

  public dismissModal() {
    const second = 1000;
    this.fadeOut = true;
    setTimeout(() => {
      this.hide = true;
    }, second);
    this.isActive = false;
    UtilsService.setInformativeModalsViewed();
  }

  private createSliderOptions() {
    this.slideOptions = {
      breakpointsInverse: true,
      allowTouchMove: true,
      touchReleaseOnEdges: true,
      breakpoints: {
        901: {
          allowTouchMove: false
        }
      }
    };
  }

  private isFullBackground(index: number): boolean {
    return this.fullBackgroundTemplates.includes(this.informativeElements[index].template_Code);
  }

  public imageUrl(index: number): string {
    return this.informativeElements[index].image_Url;
  }

  public isDarken(index: number): boolean {
    return this.darkBackgroundTemplates.includes(this.informativeElements[index].template_Code);
  }

  private isNoResizeHeight(index: number): boolean {
    return this.noResizeHeightTemplates.includes(this.informativeElements[index].template_Code);
  }

  private resizeHeight(index: number) {
    const resizePercentHeight = RESIZE_PERCENT_HEIGHT;
    return this.informativeElements[index].size_Y = resizePercentHeight * this.screenHeight;
  }

  public isCenteredBackground(index: number): boolean {
    return this.centeredBackgroundTemplates.includes(this.informativeElements[index].template_Code);
  }

  public isSmallMobile(index: number): boolean {
    return this.smallMobileTemplates.includes(this.informativeElements[index].template_Code);
  }

  public getHeight(index: number): number {
    if (this.isFullBackground(index)) {
      if (this.screenHeight >= HEIGHT_RESIZE_FULL_BACKGROUND || this.isNoResizeHeight(index)) {
        return this.informativeElements[index].size_Y;
      } else { return this.resizeHeight(index); }
    } else {
      switch (this.informativeElements[index].template_Code) {
        case 'Web_Template_3':
          return this.informativeElements[index].size_Y = HEIGHT_TEMPLATE_DESKTOP_3;
        case 'Mobile_Template_3':
          if (this.screenHeight >= HEIGHT_RESIZE_MOBILE_3) {
            return this.informativeElements[index].size_Y = HEIGHT_TEMPLATE_MOBILE_3;
          } else {
            return this.resizeHeight(index);
          }
        case 'Mobile_Template_4':
          return this.informativeElements[index].size_Y = HEIGHT_TEMPLATE_MOBILE_4;
      }
    }
  }

  private setModalElements() {
      this.screenHeight = screen.height;
      const informativeElementsOffer = [];

      this.allInformativeElements.forEach(function (i) {
          if (i.product == null) {
              informativeElementsOffer.push(i);
          }

      });

      this.isModalInformative = true;

      if (this.offerings.length > 0) {
          const offersCode = this.offerings.map(o => o.nameProduct);

          this.allInformativeElements.forEach(function (i) {
              if (i.modal_Type === MODAL_TYPE_VENTAS && i.origin === MODAL_ORIGIN_RUO) {
                  if (offersCode.includes(i.product)) {
                      informativeElementsOffer.push(i);
                  }
              }
          });
      }

      informativeElementsOffer.sort((a, b) => {
          var aorden = a.orden;
          var borden = b.orden;
          if (aorden >= borden) {
              return 1;
          }
          else if (aorden < borden) {
              return -1;
          }
      })

      const mobileElements = informativeElementsOffer.filter(element => element.isMobile === true);
      const desktopElements = informativeElementsOffer.filter(element => element.isMobile === false);
      this.isMobile ? this.informativeElements = mobileElements : this.informativeElements = desktopElements;

      if (informativeElementsOffer.length == 0) {
          this.isActive = false;
          this.isModalInformative = false;
      }
  }

  // CAMBIO_POPUP
  public async selectImagePopup(element) {
    if (element.modal_Type === MODAL_TYPE_VENTAS && element.origin === MODAL_ORIGIN_RUO) {
      this.isLoading = true;
      this.loadingStatusClass = 'disable-pointer';
      const userInfo = await this.storage.get(USER_INFO);
      const dniSearchFormat =  userInfo.documentType + '-' + userInfo.documentNumber;
      if (element.product === NAME_SEF) {
        this.offer = this.offerings.filter(i => i.nameProduct === NAME_SEF);
        await this.checkStorage(this.offer);
        this.isLoading = false;
        this.loadingStatusClass = '';
        this.dismissModal();

        // FLUJO SEMI-AUTOMATICO
        if (this.offer[0].loansV1) {
          this.firebaseService.registerStepSefEfex('sef modal', {origen: 'InformativeModalComponent', dniSearchFormat});
          this.showSefModal(this.offer[0]);
          return ;
        }

        // FLUJO AUTOMATICO
        if (!this.offer[0].endToEnd) {
          this.firebaseService.registerStepSefEfex('sef endToEnd', {origen: 'InformativeModalComponent', dniSearchFormat});
          this.showEndToEndOffModal(PRODUCT_SEF);
          return ;
        }
        if (this.offer[0].offerInactive) {
          this.firebaseService.registerStepSefEfex('sef inactive', {origen: 'InformativeModalComponent', dniSearchFormat});
          this.showOfferInactiveModal(this.offer[0].codeProduct);
        } else {
          const routerStateData: IOfferState = {
            offeringData: this.offer[0],
            isEfexPromotional: false
          };
          this.firebaseService.registerStepSefEfex('sef automatico', {origen: 'InformativeModalComponent', dniSearchFormat});
          this.router.navigate([SEF_ROUTE], { state: routerStateData });
        }
      } else if (element.product === NAME_EFEX) {
        this.offer = this.offerings.filter(i => i.nameProduct === NAME_EFEX);
        await this.checkStorage(this.offer);
        this.isLoading = false;
        this.loadingStatusClass = '';
        this.dismissModal();

        // FLUJO SEMI-AUTOMATICO
        if (this.offer[0].loansV1) {
          return this.router.navigate([EFEX_ROUTE], { state: this.offer[0] });
        }

        // FLUJO AUTOMATICO
        if (!this.offer[0].endToEnd) {
          this.firebaseService.registerStepSefEfex('efex endToEnd', {origen: 'InformativeModalComponent', dniSearchFormat});
          this.showEndToEndOffModal(PRODUCT_EFEX);
          return;
        }
        if (this.offer[0].offerInactive) {
          this.firebaseService.registerStepSefEfex('efex inactive', {origen: 'InformativeModalComponent', dniSearchFormat});
          this.showOfferInactiveModal(this.offer[0].codeProduct);
        } else {
          const routerStateData: IOfferState = {
            offeringData: this.offer[0],
            isEfexPromotional: false
          };
          this.firebaseService.registerStepSefEfex('efex automatico', {origen: 'InformativeModalComponent', dniSearchFormat});
          this.router.navigate([EFEX_AUTO_ROUTE], { state: routerStateData });
        }
      } else if (element.product === NAME_SEF_REENGANCHE){
        this.offer = this.offerings.filter(i => i.nameProduct === NAME_SEF_REENGANCHE);
        await this.checkStorage(this.offer);
        this.isLoading = false;
        this.loadingStatusClass = '';
        this.dismissModal();

        // FLUJO SEMI-AUTOMATICO
        this.firebaseService.registerStepSefEfex('sef reenganche modal', {origen: 'InformativeModalComponent', dniSearchFormat});
        this.showSefReengancheModal(this.offer[0]);
        return ;
        
      }
    } else {
      if (element.redirect_Url !== '' && element.redirect_Url !== null) {
        if (element.isUrl_External === true) {
          window.open(element.redirect_Url);
        } else {
          this.dismissModal();
          this.router.navigateByUrl(element.redirect_Url);
        }
      }
    }
  }


  private async checkStorage(offer) {
    const offers_loan = sessionStorage.getItem(OFFERS_LOAN);
    if (offers_loan === null || offers_loan === undefined) {
      await this.startLoan(offer[0].codeProduct);
    }
  }

  private async startLoan(codePorduct: string) {
    sessionStorage.setItem(ORIGIN_LOAN_PWA, ORIGIN_LOAN_PWA_OPT.POPUP);
    try {
      const loanRequest: ILoan = {
        codeProduct: codePorduct,
        reference: window.location.href,
        utmCampaing: '',
        utmContent: '',
        utmMedium: '',
        utmSource: '',
        isFeedback : false,
        originLoanPwa: ORIGIN_LOAN_PWA_OPT.POPUP
      };

      const result = await this.startLoans.startLoanRegister(loanRequest);
      sessionStorage.setItem(OFFERS_LOAN, 'true');
      sessionStorage.setItem(LEAD_EXECUTION_CODE, result.executionCode);
    } catch (err) { }
  }

  public openPhoneNumber(phoneNumber: string) {
    document.location.href = `tel:${phoneNumber}`;
  }

  public formattedTCA(offer: IOffering) {
    const timeMonth = Math.max(offer.minimumTimeMonth, offer.maximumTimeMonth);
    return 'TCEA ' + Number(offer.rateTCA).toFixed(2) + '% a ' + timeMonth + ' cuotas.';
  }

  public getPrettyName(productCode: string) {
    return PRODUCT_NAMES[productCode].prettyName;
  }

  public async showOfferInactiveModal(codeProduct) {
    let props = {};
    switch (codeProduct) {
      case PRODUCT_EFEX:
        props = OFFER_EFEX_INACTIVE_MODAL_PROPS;
        break;
      case PRODUCT_SEF:
        props = OFFER_SEF_INACTIVE_MODAL_PROPS;
        break;
      case PRODUCT_SEF_REENGANCHE:
        props = OFFER_RSEF_INACTIVE_MODAL_PROPS;
        break;
    }
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

  public async showGenericErrorModal() {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: LOAN_LEAD_FAILED_MODAL_PROPS,
    });
    modal.present();
  }

  public async showEndToEndOffModal(codeProduct: string) {
    const executionCode = sessionStorage.getItem(LEAD_EXECUTION_CODE);
    if (executionCode !== null && executionCode !== undefined) {
      const loanLead: ILoanLead = {
        codeProduct,
        executionCode,
        reference: window.location.href,
        callCenterReason: ''
      };

      const leadModalBody: ILeadModalBody = END_TO_END_OFF_MODAL_PROPS;
      const props = {
        leadModalBody,
        loanLeadData: loanLead
      };
      const modal = await this.modalCtrl.create({
        component: LeadGenericModalComponent,
        cssClass: 'form-modal',
        componentProps: props,
      });
      await modal.present();
    }
  }

  private async showSefModal(offer: IOffering) {
    const props = {
      title: 'Detalles de tu ' + this.getPrettyName(this.offer[0].codeProduct),
      subtitle: 'Monto a desembolsar',
      primaryButtonText: 'Volver',
      offer: this.offer[0],
      amountIcon: 'i-money-bill-pink',
      tca: this.formattedTCA(this.offer[0]),
      disclaimer: offer.customerOfferReference
    };
    const modal = await this.modalCtrl.create({
      component: OfferingModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

  private async showSefReengancheModal(offer: IOffering) {
    const props = {
      title: 'Detalles de tu Renovación de Súper Efectivo',
      subtitle: 'Monto a desembolsar',
      primaryButtonText: 'Volver',
      offer: offer,
      amountIcon: 'i-money-bill-pink',
      tca: this.formattedTCA(offer),
      disclaimer: offer.customerOfferReference
    };
    const modal = await this.modalCtrl.create({
      component: OfferingModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

}
