import { Component, Input, OnInit } from '@angular/core';
import { LOTTIE_CONFIG } from '@common/utils/utils.constants';

@Component({
  selector: 'app-ripley-loader',
  templateUrl: './ripley-loader.component.html',
  styleUrls: ['./ripley-loader.component.scss'],
})
export class RipleyLoaderComponent implements OnInit {
  @Input() public size: 'big' | 'small' = 'small'

  public lottieConfig: object = LOTTIE_CONFIG;
  public lottieHeight: number = LOTTIE_CONFIG.sizes.small.height;
  public lottieWidth: number = LOTTIE_CONFIG.sizes.small.width;

  constructor() { }

  ngOnInit() {
    const sizeConfig = LOTTIE_CONFIG.sizes[this.size]
    this.lottieHeight = sizeConfig.height;
    this.lottieHeight = sizeConfig.width;
  }

}
