import {
  ENCRYPTION_BITS_BLOCK_LENGTH,
  ENCRYPTION_BYTES_BLOCK_LENGTH,
  DEFAULT_INITIALIZATION_VECTOR
} from '@common/constants/encryption.constants';
import { environment } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as forge from 'node-forge';
import { ROUTES_WITH_HEADER_CHANNEL, ROUTES_WITH_HEADER_TOKEN, ROUTES_WITHOUT_ENCRYPTION } from '@common/constants/auth.constants';
import { AuthService } from '@services/auth/auth.service';
import { UtilsService } from '@services/utils/utils';
import { CHANNEL_ID } from '@common/constants/misc.constants';

@Injectable()
export class EncryptionInterceptor implements HttpInterceptor {

  constructor(public router: Router, private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    const matches = ROUTES_WITH_HEADER_TOKEN.filter(route => request.url.includes(route));
    const matches_channel = ROUTES_WITH_HEADER_CHANNEL.filter(route => request.url.includes(route));
    const matchesWithoutEncrypttionBody = ROUTES_WITHOUT_ENCRYPTION.filter(route => request.url.includes(route));
   
    
    if (request.method === 'POST' || request.method === 'PUT') {
      if (matches.length !== 0) {
        let authorizationValue = this.selectAuthorizationType(null);
        const tokenAuth = this.authService.getSessionToken();
        authorizationValue = this.selectAuthorizationType(tokenAuth.accessToken);
        if (matches_channel.length === 0) {
          request = request.clone({
            setHeaders: {
              'Content-Type':  'application/json',
              Authorization: authorizationValue
            },
            body: this.encryptBody(request.body),
          });

        } else {
          request = request.clone({
            setHeaders: {
              'Content-Type':  'application/json',
               Authorization: authorizationValue,
               channelId: CHANNEL_ID
            },
            body: this.encryptBody(request.body),
          });

        }

      } else if (matchesWithoutEncrypttionBody.length !== 0) {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(request.body),
        }); 

      }
      else {

        
        request = request.clone({
            setHeaders: {
              'Content-Type':  'application/json',
            },
            body: this.encryptBody(request.body),
          });
        }
      }
    return next.handle(request).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

 private selectAuthorizationType(token: string): string {
    return token ? `Bearer ${token}` : `Basic ${this.getBasicCredentials()}`;
  }

  private getBasicCredentials(): string {
    return btoa(environment.CLIENT_ID + ':' + environment.CLIENT_SECRET);
  }

  private encryptBody(body: object): string {
    const pem = environment.PUBLIC_KEY_PEM;
    const salt = forge.random.getBytesSync(ENCRYPTION_BITS_BLOCK_LENGTH);
    const randomString = UtilsService.getRandomString(ENCRYPTION_BYTES_BLOCK_LENGTH);
    const binaryEncodedKey = forge.pkcs5.pbkdf2(randomString, salt, ENCRYPTION_BYTES_BLOCK_LENGTH, ENCRYPTION_BYTES_BLOCK_LENGTH);
    const key = forge.util.encode64(binaryEncodedKey).substring(0, ENCRYPTION_BYTES_BLOCK_LENGTH);
    const encryptedContent = this.encryptContent(body, key);
    const encryptedKey = this.encryptKey(pem, key);
    return encryptedKey + encryptedContent;
  }

  private encryptContent(body: any, key: string): string {
    const cipher = forge.cipher.createCipher('AES-CBC', key);
    cipher.start({iv: DEFAULT_INITIALIZATION_VECTOR});
    if (typeof body === 'string') {
      cipher.update(forge.util.createBuffer(body));
    } else {
      cipher.update(forge.util.createBuffer(JSON.stringify(body),'utf8'));
    }
    cipher.finish();
    const encryptedAES = cipher.output.getBytes();
    return forge.util.encode64(encryptedAES);
  }

  private encryptKey(pem: string, key: string): string {
    const publicKey = forge.pki.publicKeyFromPem(pem) as forge.pki.rsa.PublicKey;
    const buffer = forge.util.createBuffer(key, 'utf8');
    const bytes = buffer.getBytes();
    const encrypted = publicKey.encrypt(bytes, 'RSA-OAEP');
    return forge.util.encode64(encrypted);
    
  }

}
