import { Component, OnDestroy, OnInit } from "@angular/core";
import { PAYMENTS_DISCLAIMER } from "@common/constants/payments.constants";
import { PaymentService } from "@services/payment/payment.service";

@Component({
  selector: "app-cut-off-disclaimer",
  templateUrl: "./cut-off-disclaimer.component.html",
  styleUrls: ["./cut-off-disclaimer.component.scss"],
})
export class CutOffDisclaimerComponent implements OnInit, OnDestroy {
  constructor(private paymentService: PaymentService) {}

  async ngOnInit(): Promise<void> {
    await this.initCoutOffTime();
  }
  
  cutOffDate: Date;
  midnightDate: Date;
  isCutOffTime = false;
  cutOffTime = "22:00:00";
  nowDate = new Date(Date.now());
  intervalUpdateNowDate: NodeJS.Timeout;
  MINUTE_IN_MS = 1000 * 60;
  HOUR_IN_MS = 1000 * 60 * 60;
  paymentsDisclaimer = PAYMENTS_DISCLAIMER;

  extractDate(date: Date) {
    return date.toISOString().substring(0, 10);
  }

  getPeruvianDate() {
    const peruvianOffset = -this.HOUR_IN_MS * 5;
    const peruvianDate = new Date(Date.now() + peruvianOffset);
    return this.extractDate(peruvianDate);
  }

  async initCoutOffTime(): Promise<void> {
    const { value: time } = await this.paymentService.getCutOffTime();
    this.cutOffTime = time;
    this.paymentsDisclaimer = PAYMENTS_DISCLAIMER.replace(
      "22:00",
      this.cutOffTime.substring(0, 5)
    );
    this.updateDates();
    this.intervalUpdateNowDate = setInterval(
      this.updateDates,
      this.MINUTE_IN_MS
    );
  }

  updateDates() {
    this.nowDate = new Date(Date.now());
    this.cutOffDate = new Date(
      Date.parse(`${this.getPeruvianDate()}T${this.cutOffTime}-05:00`)
    );
    this.midnightDate = new Date(
      Date.parse(`${this.getPeruvianDate()}T23:59:59-05:00`)
    );
    this.isCutOffTime = this.checkIsCutOffTime();
  }

  checkIsCutOffTime() {
    if (this.cutOffDate) {
      return (
        this.cutOffDate <= this.nowDate && this.nowDate<= this.midnightDate
      );
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    if (this.intervalUpdateNowDate) {
      clearInterval(this.intervalUpdateNowDate);
    }
  }
}
