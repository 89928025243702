import {
  IBinesQueryRequest,
  IBinesQueryResponse,
  INiubizWaitTime,
  IParameterHBK,
  IPaymentExecutionRequest,
  IPaymentExecutionResponse,
  IPaymentInformationRequest,
  IPaymentInformationResponse,
  IPaymentValidationRequest,
  IPaymentValidationResponse
} from '@common/interfaces/payment.interface';
import {
  ILoanDataRequest,
  ILoanDataResponse,
  IQuotaPendingRequest,
  IQuotaPendingResponse,
  IValidateNiubizRequest,
  IValidateNiubizResponse,
  IValidatePaymentRequest,
  IValidatePaymentResponse
} from '@common/interfaces/loan.interface';
import {
  PAYMENT_INFORMATION_MOCK,
  PAYMENT_VALIDATION_MOCK,
  PAYMENT_EXECUTION_MOCK,
  PAYMENT_NIUBIZ_WAIT_TIME,
  PAYMENT_CUT_OFF_TIME
} from '@common/mocks/payment.mocks';
import {
  LOAN_PAYMENT_DATA,
  QUOTA_PENDING_DATA,
  VALIDATE_NIUBIZ_PROCESS,
  VALIDATE_PAYMENT_PROCESS
} from '@common/mocks/loan.mocks';
import { HttpService } from '@services/http/http.service';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IDigitalCardMonitorInput } from '@common/interfaces/digital-card.interface';
import { DeviceService } from '@services/device/device.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  public isSavingAccountPayment: boolean;
  public paymentStyleElement: HTMLLinkElement;
  public paymentScriptElement: HTMLScriptElement;

  constructor(
    private http: HttpService,
    private deviceService: DeviceService
  ) { }
  
  //modal niubiz
  public async getNiubizWaitTime(): Promise<INiubizWaitTime> {
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/CTPAGNIUB';
    return this.http.get(url, PAYMENT_NIUBIZ_WAIT_TIME).toPromise();
  }

  public async getCutOffTime(): Promise<IParameterHBK> {
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/HRCPTS';
    return this.http.get(url, PAYMENT_CUT_OFF_TIME).toPromise();
  }

  // pagos tc y sef
  public getPaymentInformation(request: IPaymentInformationRequest): Promise<IPaymentInformationResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/product/payment/get-information';
    return this.http.post(url, request, PAYMENT_INFORMATION_MOCK).toPromise();
  }
  
  public validatePaymentProcess(request: IPaymentValidationRequest): Promise<IPaymentValidationResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/product/payment/validate-process';
    return this.http.post(url, request, PAYMENT_VALIDATION_MOCK).toPromise();
  }

  public valitadeBin(request: IBinesQueryRequest): Promise<IBinesQueryResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/product/payment/validate-card-bin';
    return this.http.post(url, request, PAYMENT_VALIDATION_MOCK).toPromise();
  }

  public async executePayment(request: any): Promise<IPaymentExecutionResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/product/payment/execution';
    const monitor = await this.GetMonitorInputFront();
    console.log('monitor', monitor);
    const newRequest = {
      identifierId: request.identifierId,
      tokenId: request.tokenId,
      requestPaymentExecution: {
        accountService: {
          accountNumber: request.account
        },
        insurance: {
          paymentMethodCode: request.entityIdentification
        },
        digitalAuthentication: {
          deviceType: monitor.device,
          country: monitor.country
        },
        systemService: {
          operatingSystemName: monitor.firmware,
          browserName: monitor.navegadorIdenticator,
          browserMajorVersion: monitor.navegadorVersionMajor,
          deviceId: monitor.deviceId,
          deviceModelCode: monitor.deviceVersion,
          deviceName: monitor.deviceName,
          internetServiceProvider: monitor.isp,
          osArchitecture: monitor.architecture,
          browserLanguage: monitor.language,
          deviceCity: monitor.city,
          operatingSystemVersion: monitor.deviceVersion
        },
        payment: {
          numberHostIpAddress: request.ip
        }
      }
    } as IPaymentExecutionRequest;
    return this.http.post(url, newRequest, PAYMENT_EXECUTION_MOCK).toPromise();
  }

  //Loans
  public getLoanPaymentInformation(request: ILoanDataRequest): Promise<ILoanDataResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/product/loans/payment-information';
    return this.http.post(url, request, LOAN_PAYMENT_DATA).toPromise();
  }

  public getLoanInformation(request: ILoanDataRequest): Promise<ILoanDataResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/product/loans/information';
    return this.http.post(url, request, LOAN_PAYMENT_DATA).toPromise();
  }

  public getLoanQuotaPending(request: IQuotaPendingRequest): Promise<IQuotaPendingResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/product/loans/quota-pending';
    return this.http.post(url, request, QUOTA_PENDING_DATA).toPromise();
  }

  public validateLoanNiubizProcess(request: IValidateNiubizRequest): Promise<IValidateNiubizResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/product/loans/payment/validate-process';
    return this.http.post(url, request, VALIDATE_NIUBIZ_PROCESS).toPromise();
  }

  public async executionLoanPayments(request: IValidatePaymentRequest): Promise<IValidatePaymentResponse> {
    request.monitorInputFront = await this.GetMonitorInputFront();
    const url = environment.BASE_BACKEND_WORKER + '/product/loans/payment/execution';
    return this.http.post(url, request, VALIDATE_PAYMENT_PROCESS).toPromise();
  }

  //Obtener información necesaria para Monitor
  public initPaymentSources() {
    const existingScript = document.querySelector(`script[src="${environment.PAYMENTS_CONFIG.ENDPOINT_JS}"]`);
    
    if(!existingScript) {
      this.paymentStyleElement = document.createElement('link');
      this.paymentStyleElement.rel = 'stylesheet';
      this.paymentStyleElement.type = 'text/css';
      this.paymentStyleElement.href = environment.PAYMENTS_CONFIG.ENDPOINT_CSS;
  
      this.paymentScriptElement = document.createElement('script');
      this.paymentScriptElement.src = environment.PAYMENTS_CONFIG.ENDPOINT_JS;
      this.paymentScriptElement.defer = true;
      
      document.head.append(this.paymentStyleElement, this.paymentScriptElement);
    }
  }

  public removePaymentSourcer() {
    this.paymentStyleElement.remove();
    this.paymentScriptElement.remove();
  }

  //Obtener información necesaria para Monitor
  private async GetMonitorInputFront() : Promise<IDigitalCardMonitorInput> {
    return {
      url : window.location.host,
      device : this.deviceService.getWildField1(),
      firmware : this.deviceService.getPlatformName(),
      docName : window.location.pathname,
      cpuAbi : '',
      finger : '0',
      mobileNumber : '',
      ip : (await (this.deviceService.getIP())).ip,
      navegadorIdenticator: this.deviceService.getNavegadorIdentifier(),
      deviceVersion: this.deviceService.getDeviceVersion(),
      deviceId: this.deviceService.getDeviceId(),
      deviceName: this.deviceService.getDeviceManufacturer(),
      deviceModel: this.deviceService.getDevice(),
      imei: this.deviceService.getImei(),
      numberHardwareProcessor:this.deviceService.getNumberHardwareProcessor(),
      memoryRam:this.deviceService.getNumberMemoryRam(),
      videoCardVendor: this.deviceService.getVideoCardVendor(),
      videoCardRenderer: this.deviceService.getVideoCardRenderer(),
      country: await this.deviceService.getCountry(),
      city: await this.deviceService.getCity(),
      isp: await this.deviceService.getISP(),
      macAddress: this.deviceService.getMacAddress(),
      navegadorVersion: this.deviceService.getNavegadorVersion(),
      navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor(),
      engineName: this.deviceService.getEngineName(),
      engineVersion: this.deviceService.getEngineVersion(),
      language: navigator.language,
      architecture: this.deviceService.getArchitecture()
    }
  }
}
