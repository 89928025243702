import { Injectable } from '@angular/core';
import { Coordinates, Geolocation } from '@ionic-native/geolocation/ngx';
import { Platform } from '@ionic/angular';
import { UtilsService } from '@services/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  public isNative: boolean;

  constructor(
    private geolocation: Geolocation,
    private platform: Platform,
  ) {
    this.isNative = this.platform.is('cordova');
  }

  public async getPosition(defaultValue: Coordinates = null, timeout = 3500): Promise<Coordinates> {
    if (this.isNative) {
      return this.geolocation.getCurrentPosition({timeout, enableHighAccuracy: true})
        .then((position) => {
          return {longitude: position.coords.longitude, latitude: position.coords.latitude} as Coordinates;
        }).catch((err) => {
          return defaultValue;
        });
    }
    return new Promise<Coordinates>((resolve) => {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve({longitude: position.coords.longitude, latitude: position.coords.latitude} as Coordinates);
      }, (err) => {
        resolve(defaultValue);
      },
      {
        timeout,
        enableHighAccuracy: true
      });
    });
  }

  public getCurrentDistance(firstPosition: Coordinates, secondPosition: Coordinates) {
    const distance = UtilsService.getDistanceFromLatLonInKm(firstPosition, secondPosition);
    return { distance: UtilsService.addDistanceUnit(distance), value: distance };
  }
}
