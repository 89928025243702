import { Injectable } from '@angular/core';
import { LOGIN_ERROR_MAP, LOGIN_OK } from '@common/constants/auth.constants';
import { LOGIN_PASSWORD_MAX_LENGTH } from '@common/constants/misc.constants';
import { DOC_NUMBER_STORAGE_KEY, DOC_TYPE_STORAGE_KEY, SAVED_IDENTIFIER_KEY, USER_NAME_KEY } from '@common/constants/remember-user.constants';
import { ILoginResult, IOAuthTokens, IPassword6, IUserLogin, IUserLoginInfo } from '@common/interfaces/auth.interface';
import { ITokenizedKeyboard } from '@common/interfaces/device.interface';
import { Storage } from '@ionic/storage';
import { AccountManagerService } from '@services/account-manager/account-manager.service';
import { AuthService } from '@services/auth/auth.service';
import { DeviceService } from '@services/device/device.service';
import { UserService } from '@services/user/user.service';
import { UtilsService } from '@services/utils/utils';

@Injectable()
export class LoginService {
  private isNative: boolean;
  private deviceId: number;
  private hasBiometricEnabled: boolean;
  private savedDocumentNumber: string;
  private savedDocumentType: string;
  private savedUserName: string;

  public get documentNumber() { return this.savedDocumentNumber; }
  public get documentType() { return this.savedDocumentType; }
  public get userName() { return this.savedUserName; }
  public get identifier(): number { if (!this.deviceId) { this.generateDeviceId(); } return this.deviceId; }

  constructor(
    private accountService: AccountManagerService,
    private authService: AuthService,
    private deviceService: DeviceService,
    private storage: Storage,
    private userService: UserService,
    private utils: UtilsService,
  ) { }

  public async simpleLogin(documentType: string, documentNumber: string, password: string, remember = false): Promise<ILoginResult> {
    const { keyboardId, hashCodes } = await this.hashPassword(password);
    const userInfo: IUserLoginInfo = { documentType, documentNumber, remember, keyboardId }
    const passwordInfo: IPassword6 =  { keyboardId, hashCodes }
    return await this.login(userInfo, passwordInfo);
  }

  public async login(userInfo: IUserLoginInfo, passwordInfo: IPassword6): Promise<ILoginResult> {
    const { documentType, documentNumber, remember } = userInfo;
    const userLogin = { documentType, documentNumber, password6: passwordInfo } as IUserLogin;
    let oauthTokens: IOAuthTokens;
    try {
      oauthTokens = await this.authService.signIn(userLogin, this.identifier);
      if (this.isNative || remember) {
        this.userService.getUserInformation().then((user) => {
          this.saveUserInfo(documentType, documentNumber, user.names);
        })
          .catch((error) => {
            console.error('getUserInformation error', error);
            this.clearUserInfo();
          });
      } else {
        this.clearUserInfo();
      }
      this.registerAccount(userLogin);
    } catch (err) {
      const error = { code: null, message: null };
      if (err.error) {
        error.code = err.error;
        if (Object.keys(LOGIN_ERROR_MAP).includes(error.code)) {
          error.message = LOGIN_ERROR_MAP[error.code];
        }
      } else {
        error.code = err;
        if (Object.keys(LOGIN_ERROR_MAP).includes(error.code)) {
          error.message = LOGIN_ERROR_MAP[error.code];
        }
      }
      throw error;
    }
    return { code: LOGIN_OK, message: null, jwt: oauthTokens.accessToken };
  }

  public async logoutWithoutRedirection() : Promise<void> {
    if (this.authService.isAuthenticated()) {
      return await this.authService.signOut();
    }
  }

  public async hashPassword(password: string): Promise<IPassword6> {
    const keyboard = await this.getTokenizedKeyboard();
    return {
      keyboardId: keyboard.id,
      hashCodes: this.getPasswordHash(keyboard, password)
    };
  }

  public async getTokenizedKeyboard(): Promise<ITokenizedKeyboard> {
    try {
      const response = await this.deviceService.getTokenizedKeyboard(this.identifier);
      return response;
    } catch (err) {
      return null;
    }
  }

  public getPasswordHash(tokenizedKeyboard: ITokenizedKeyboard, password: string): Array<string> {
    const passwordHash = [];
    for (let index = 0; index < LOGIN_PASSWORD_MAX_LENGTH; index++) {
      passwordHash.push(
        tokenizedKeyboard.keys.find(p => p.numericalValue === Number(password.charAt(index))).hashCode
      );
    }
    return passwordHash;
  }

  public async registerAccount(loginInfo: IUserLogin): Promise<void> {
    if (this.isNative && !this.hasBiometricEnabled) {
      const userData = {
        keyboardId: loginInfo.password6.keyboardId,
        documentType: loginInfo.documentType
      };

      if (this.utils.nativeAndroid) { await this.accountService.accountManager.unregister(); }
      await this.accountService.accountManager.registerAccount(loginInfo.documentNumber, 'pass', userData);
    }
  }

  public async generateDeviceId(force = false): Promise<number> {
    try {
      const savedIdentifier = await this.storage.get(SAVED_IDENTIFIER_KEY);
      if (savedIdentifier && !force) {
        this.deviceId = savedIdentifier;
      } else {
        this.deviceId = await this.deviceService.createIdentifier();
        this.storage.set(SAVED_IDENTIFIER_KEY, this.deviceId);
      }
    } catch (_err) {
      return null;
    }
    return this.deviceId;
  }

  private saveUserInfo(documentType: string, documentNumber: string, name: string): void {
    this.storage.set(DOC_TYPE_STORAGE_KEY, documentType);
    this.storage.set(DOC_NUMBER_STORAGE_KEY, documentNumber);
    this.storage.set(USER_NAME_KEY, name);
  }

  private async setSavedUserInfo(): Promise<void> {
    this.savedDocumentType = await this.storage.get(DOC_TYPE_STORAGE_KEY);
    this.savedDocumentNumber = await this.storage.get(DOC_NUMBER_STORAGE_KEY);
    this.savedUserName = await this.storage.get(USER_NAME_KEY);
    if (this.savedUserName) {
      this.savedUserName = UtilsService.capitalizeWords(this.savedUserName);
    }
  }

  private clearUserInfo(): void {
    this.storage.remove(DOC_TYPE_STORAGE_KEY);
    this.storage.remove(DOC_NUMBER_STORAGE_KEY);
    this.storage.remove(USER_NAME_KEY);
    this.setSavedUserInfo();
  }

}
