import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IDetail } from '@common/interfaces/digital-auth.interface';
import * as jwt from 'jsonwebtoken';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  public qrCodeJson: Promise<any>;
  public getDeviceStatus: Promise<any>;
  public initEnrollment: Promise<any>;
  public processInitEnrollment: Promise<any>;
  public finalAnalizeDNIFront: Promise<any>;
  public finalAnalizeDNIBack: Promise<any>;
  public finishEnrollment: Promise<any>;
  public processFinishEnrollment: Promise<any>;
  public retrieveZoom: Promise<any>;
  public uuidDevice: Promise<any>;
  public os: Promise<any>;
  public enrollmentType: string;
  public faceMap: string;
  public sessionId: string;
  public uuidTransaction: string;
  public pathIdZoom: string;
  public pathIdDNI: string;
  public pathIdLate: string;
  public documentType: string;
  public rut: string;
  public appId: string;
  public country: string;
  public ip: Promise<any>;
  public token: string;
  public tokenAuth: string;
  public userAgent: Promise<any>;
  public notify = new Subject<any>();
  public notifyObservable$ = this.notify.asObservable();
  public jwToken;
  public access_token;
  public stepId: string;
  public definitionWorkflow: string;
  public sequenceNumber: string;
  public deviceFingerprint: any;
  constructor() { }

  public notifyAgain(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  public encryptTransactionData(transactionData: IDetail[]) {
    return jwt.sign(JSON.stringify(transactionData), environment.JWT_SECRET_WID_AUTH_V3);
    //TODO: Temporary solution, replace this when the definitve solution is ready.
    //return this.http.post(`${SV}/widget/v3/encrypt-transaction-data`, transactionData);
  }

}
