import { Component, Input } from '@angular/core';
import { CARD_NOT_AUTHORIZED, ENABLE_SHOPPING_PURCHARSE_ERROR } from '@common/constants/digital-card.constants';
import { VDT_CONSTANTS } from '@common/constants/tag/tag-visualizacion-tc.constans';
import { IConsolidatedProductAccount, IMovementDetailInfo } from '@common/interfaces/product.interface';
import { DigitalCardModalComponent } from '@components/digital-card-modal/digital-card-modal.component';
import { ModalController } from '@ionic/angular';
import { AlertService } from '@services/alert/alert.service';
import { DatacardService } from '@services/data-card/data-card.service';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-ripley-card-button-show',
  templateUrl: './ripley-card-button-show.component.html',
  styleUrls: ['./ripley-card-button-show.component.scss'],
})
export class RipleyCardButtonShowComponent {
  @Input() public cardDetails: IConsolidatedProductAccount
  public sessionId: string
  public disabledButton = false

  constructor(
    private datacardService: DatacardService,
    private alertService: AlertService,
    private modalCtrl: ModalController,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  get showButton(): boolean {
    if(this.datacardService.data && 
      (this.datacardService.data.sessionId !== this.cardDetails.sessionId)) {
        if(this.datacardService.data.code == 0) {
          this.cardDetails.showVDT = true;
          this.cardDetails.sessionId = this.datacardService.data.sessionId;
        } else {
          this.cardDetails.sessionId = 
          this.datacardService.data.code == ENABLE_SHOPPING_PURCHARSE_ERROR ? 
          ENABLE_SHOPPING_PURCHARSE_ERROR.toString() : CARD_NOT_AUTHORIZED;
          this.cardDetails.showVDT = false;
        }
    }
    return this.cardDetails.showVDT;
  }

  public async showCardData($event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    try {
      this.googleAnalyticsService.gtagPushEventWithId(VDT_CONSTANTS.SHOW_VDT_HOME.EVENT,
        VDT_CONSTANTS.SHOW_VDT_HOME.TAGS);
      this.disabledButton = true
      const modal = await this.modalCtrl.create({
        component: DigitalCardModalComponent,
        cssClass: "digital-card-modal",
        componentProps: {
          originalCardNumber: this.cardDetails.cardNumber,
          keyCard: this.cardDetails.keyCard,
          sessionId: this.cardDetails.sessionId
        },
      });
      await modal.present();
    } catch (e) {
      await this.alertService.openErrorAlert();
    } finally {
      this.disabledButton = false
    }
  }
}
