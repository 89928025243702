import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar-control',
  templateUrl: './progress-bar-control.component.html',
  styleUrls: ['./progress-bar-control.component.scss']
})
export class ProgressBarControlComponent implements OnInit {

  @Input() public totalStep: number = 0;
  @Input() public totalActiveStep: number = 0;
  public arraySteps = [];

  constructor() { }

  ngOnInit() {
    this.createArraySteps();
  }

  public createArraySteps(): void {
    for (let index = 0; index < this.totalStep; index++) {
      console.log("Progress total step ", this.totalStep);
      this.arraySteps.push(index);
    }
  }
}