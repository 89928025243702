export const RESCHEDULING_QUESTIONS_MOCK = {
  success: [],
  failures: [],
};

export const RESCHEDULING_INFO_MOCK = {
  success: [],
  failures: [],
};

export const RESCHEDULING_SIMULATE_MOCK = {
  success: [],
  failures: [],
};

export const SEND_OTP_MOCK = {
  success: [],
  failures: [],
};

export const VALIDATE_OTP_MOCK = {
  success: [],
  failures: [],
};
