import { CanLoad } from '@angular/router';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

declare const IRoot;

@Injectable({
  providedIn: 'root'
})
export class TamperedDeviceGuard implements CanLoad {

  constructor(
    private platform: Platform,
  ) { }

  async canLoad(): Promise<boolean> {
    if (await this.platform.ready() && this.platform.is('cordova')) {
      return !await this.hasDeviceBeenTampered();
    }
    return true;
  }

  private hasDeviceBeenTampered = (): Promise<boolean> => {
    return new Promise((res, rej)  => {
      IRoot.isRooted(
        (tampered: boolean) => { res(tampered); },
        () => { rej(true); }
      );
    });
  }
}
