import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CREDITS_ROUTE } from '@common/constants/routes.constants';

@Component({
  selector: 'app-altefexernative-',
  templateUrl: './alternative-efex.component.html',
  styleUrls: ['./alternative-efex.component.scss'],
})
export class AlternativeEfexComponent implements OnInit {

  constructor(
    private viewController: ModalController,
    private router: Router
    ) { }

  ngOnInit() {}

 public dismissModal() {
    this.viewController.dismiss();
  }

  public async GoToHome() {
    this.dismissModal();
    return this.router.navigate([CREDITS_ROUTE], { replaceUrl: true });
  }
}
