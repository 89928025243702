export const GET_CARD_DATA = {
    success: [],
    failures: [],
  };
  
  export const RESPONSE_OTP = {
    success: [],
    failures: [],
  };
  
  export const VALIDATE_OTP_DISPLAY_DATA = {
    success: [],
    failures: [],
  };
  
  export const GET_PRIVATE_KEY = {
    success: [],
    failures: [],
  };
  
  export const WIDGET_TOKEN_MOCK = {
    success: [],
    failures: [],
  };
  