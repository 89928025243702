// menu
export const MENU_OPTIONS = 'MENU_OPTIONS';

export const MAP_SIDE_MENU_CODE_ACTIVATION = new Map([
  ["ON_OFF_MENU_CODE", "9"]]);
// No auth routes
export const LOGIN_ROUTE = 'login';

export const ENROLLMENT_ROUTE = 'enrollment';

export const MAINTENANCE_ROUTE = 'maintenance';

export const PASSWORD_RESET_ROUTE = 'password-reset';

export const EMERGENCY_ROUTE = 'emergency';

// Auth routes

export const HOME_ROUTE = 'home';

export const addPrefix = (route: string = '') => `${HOME_ROUTE}/${route}`;

export const VOUCHER_ROUTE = 'voucher';

export const VOUCHER_TRANSFER_ROUTE_BASELESS = "voucher-transfer"

export const VOUCHER_TRANSFER_ROUTE = addPrefix(VOUCHER_TRANSFER_ROUTE_BASELESS);

export const CREDIT_CARDS_DETAILS_ROUTE_BASELESS = 'credit-card-details';

export const CREDIT_CARDS_DETAILS_ROUTE = addPrefix(CREDIT_CARDS_DETAILS_ROUTE_BASELESS);

export const SEF_DETAILS_ROUTE_BASELESS = 'sef-card-details';

export const SEF_DETAILS_ROUTE = addPrefix(SEF_DETAILS_ROUTE_BASELESS);

export const RIPLEY_POINTS_ROUTE_BASELESS = 'ripley-points';

export const RIPLEY_POINTS_ROUTE = addPrefix(RIPLEY_POINTS_ROUTE_BASELESS);

export const RIPLEY_POINTS_DASHBOARD_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/ripley-points-dashboard`;

export const RIPLEY_POINTS_DASHBOARD_ROUTE = addPrefix(RIPLEY_POINTS_DASHBOARD_ROUTE_BASELESS);

export const RIPLEY_POINTS_GOLD_SILVER_BENEFITS_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/gold-silver-benefits`;

export const RIPLEY_POINTS_GOLD_SILVER_BENEFITS_ROUTE = addPrefix(RIPLEY_POINTS_GOLD_SILVER_BENEFITS_ROUTE_BASELESS);

export const RIPLEY_POINTS_GOLD_BENEFITS_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/gold-benefits`;

export const RIPLEY_POINTS_GOLD_BENEFITS_ROUTE = addPrefix(RIPLEY_POINTS_GOLD_BENEFITS_ROUTE_BASELESS);

export const RIPLEY_POINTS_SILVER_BENEFITS_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/silver-benefits`;

export const RIPLEY_POINTS_SILVER_BENEFITS_ROUTE = addPrefix(RIPLEY_POINTS_SILVER_BENEFITS_ROUTE_BASELESS);

export const RIPLEY_POINTS_PLUS_BENEFITS_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/plus-benefits`;

export const RIPLEY_POINTS_PLUS_BENEFITS_ROUTE = addPrefix(RIPLEY_POINTS_PLUS_BENEFITS_ROUTE_BASELESS);

export const RIPLEY_POINTS_DETAILS_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/details`;

export const RIPLEY_POINTS_DETAILS_ROUTE = addPrefix(RIPLEY_POINTS_DASHBOARD_ROUTE_BASELESS);

export const RIPLEY_POINTS_GIFTCARD_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/gift-card`;

export const RIPLEY_POINTS_GIFTCARD_ROUTE = addPrefix(RIPLEY_POINTS_GIFTCARD_ROUTE_BASELESS);

export const RIPLEY_POINTS_REDEEM_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/${'redeem'}`;

export const RIPLEY_POINTS_REDEEM_ROUTE = addPrefix(RIPLEY_POINTS_REDEEM_ROUTE_BASELESS);

export const RIPLEY_POINTS_REDEEM_VOUCHER_ROUTE_BASELESS = `${RIPLEY_POINTS_REDEEM_ROUTE_BASELESS}/${VOUCHER_ROUTE}`;

export const RIPLEY_POINTS_REDEEM_VOUCHER_ROUTE = addPrefix(RIPLEY_POINTS_REDEEM_VOUCHER_ROUTE_BASELESS);

export const EXTEND_CREDIT_LINE_ROUTE_BASELESS = 'extend-credit-line';

export const EXTEND_CREDIT_LINE_ROUTE = addPrefix(EXTEND_CREDIT_LINE_ROUTE_BASELESS);

export const TRANSFER_ROUTE_BASELESS = 'transfer';

export const TRANSFER_ROUTE = addPrefix(TRANSFER_ROUTE_BASELESS);

export const TRANSFER_INTEROP_TO_NUMBER_ROUTE_BASELESS = 'transferinter';

export const TRANSFER_INTEROP_TO_NUMBER_ROUTE = addPrefix(TRANSFER_INTEROP_TO_NUMBER_ROUTE_BASELESS);

export const TRANSFER_OLD_ROUTE_BASELESS = 'transfero';

export const TRANSFER_OLD_ROUTE = addPrefix(TRANSFER_OLD_ROUTE_BASELESS);

export const VOUCHER_TRANSFER_NEW_ROUTE_BASELESS = "voucher-transfer-new"

export const VOUCHER_TRANSFER_NEW_ROUTE = addPrefix(VOUCHER_TRANSFER_NEW_ROUTE_BASELESS);

export const TRANSFERS_OTHER_BANK_ROUTE_BASELESS = 'other-bank'

export const TRANSFERS_OTHER_BANK_ROUTE = addPrefix(TRANSFERS_OTHER_BANK_ROUTE_BASELESS);

export const TRANSFER_INTEROP_AFFILIATION_ROUTE_BASELESS = 'affiliation';

export const TRANSFER_INTEROP_AFFILIATION_ROUTE = addPrefix(TRANSFER_INTEROP_AFFILIATION_ROUTE_BASELESS);

export const TRANSFER_INTEROP_AFFILIATION_INVITATION_ROUTE_BASELESS = 'affiliation-inv';

export const TRANSFER_INTEROP_AFFILIATION_INVITATION_ROUTE = addPrefix(TRANSFER_INTEROP_AFFILIATION_INVITATION_ROUTE_BASELESS);

export const CONTACT_INFORMATION_ROUTE_BASELESS = 'contact-information';

export const CONTACT_INFORMATION_ROUTE = addPrefix(CONTACT_INFORMATION_ROUTE_BASELESS);

export const EFEX_ROUTE_BASELESS = 'efex';

export const EFEX_AUTO_ROUTE_BASELESS = 'efex-auto';

export const EFEX_ROUTE = addPrefix(EFEX_ROUTE_BASELESS);

export const EFEX_AUTO_ROUTE = addPrefix(EFEX_AUTO_ROUTE_BASELESS);

export const EFEX_DETAIL_ROUTE_BASELESS = 'detail';

export const EFEX_DETAIL_ROUTE = addPrefix(EFEX_DETAIL_ROUTE_BASELESS);

export const EFEX_SWORN_DECLARATION_ROUTE_BASELESS = 'sworn-declaration';

export const EFEX_SWORN_DECLARATION_ROUTE = addPrefix(EFEX_SWORN_DECLARATION_ROUTE_BASELESS);

export const EFEX_SUCCESS_ROUTE_BASELESS = 'success';

export const EFEX_SUCCESS_ROUTE = addPrefix(EFEX_SUCCESS_ROUTE_BASELESS);

export const SEF_ROUTE_BASELESS = 'sef';

export const SEF_ROUTE = addPrefix(SEF_ROUTE_BASELESS);

export const SEF_DETAIL_ROUTE_BASELESS = 'detail';

export const SEF_DETAIL_ROUTE = addPrefix(SEF_DETAIL_ROUTE_BASELESS);

export const SEF_SWORN_DECLARATION_ROUTE_BASELESS = 'sworn-declaration';

export const SEF_SWORN_DECLARATION_ROUTE = addPrefix(SEF_SWORN_DECLARATION_ROUTE_BASELESS);

export const SEF_SUCCESS_ROUTE_BASELESS = 'success';

export const SEF_SUCCESS_ROUTE = addPrefix(SEF_SUCCESS_ROUTE_BASELESS);

export const RIPLEY_AGENCIES_ROUTE_BASELESS = 'ripley-agencies';

export const RIPLEY_AGENCIES_ROUTE = addPrefix(RIPLEY_AGENCIES_ROUTE_BASELESS);

export const AHORRO_ROUTE_BASELESS = 'ahorro';

export const AHORRO_ROUTE = addPrefix(AHORRO_ROUTE_BASELESS);

export const PROMOTIONS_ROUTE_BASELESS = 'promotions';

export const PROMOTIONS_ROUTE = addPrefix(PROMOTIONS_ROUTE_BASELESS);

export const TRANSFERS_ROUTE_BASELESS = 'transfers';

export const TRANSFERS_ROUTE = addPrefix(TRANSFERS_ROUTE_BASELESS);

export const PAYMENTS_ROUTE_BASELESS = 'payments';

export const PAYMENTS_ROUTE = addPrefix(PAYMENTS_ROUTE_BASELESS);

export const ON_OF_ROUTE_BASELESS = 'on-of-cards';

export const ON_OF_ROUTE = addPrefix(ON_OF_ROUTE_BASELESS);

export const INVEST_ROUTE_BASELESS = 'invest';

export const INVEST_ROUTE = addPrefix(INVEST_ROUTE_BASELESS);

export const CREDITS_ROUTE_BASELESS = 'credit';

export const CREDITS_ROUTE = addPrefix(CREDITS_ROUTE_BASELESS);

export const OFFERS_ROUTE_BASELESS = 'offers';

export const OFFERS_ROUTE = addPrefix(OFFERS_ROUTE_BASELESS);

export const SAVING_ACCOUNTS_ROUTE_BASELESS = 'saving-accounts';

export const SAVING_ACCOUNTS_ROUTE = addPrefix(SAVING_ACCOUNTS_ROUTE_BASELESS);

export const OPTIONS_ROUTE_BASELESS = 'options';

export const OPTIONS_ROUTE = addPrefix(OPTIONS_ROUTE_BASELESS);

export const SAVING_PLUS_ACCOUNT_BASELESS = 'saving-plus';

export const SAVING_PLUS_ACCOUNT_ROUTE = addPrefix(SAVING_PLUS_ACCOUNT_BASELESS);

export const ACCOUNT_OPENNING_OFFERS_BASELESS = 'account-offers';

export const ACCOUNT_OPENNING_OFFERS_ROUTE = addPrefix(ACCOUNT_OPENNING_OFFERS_BASELESS);

export const BENEFITS_ACCOUNT_BASELESS = 'benefits';

export const BENEFITS_ACCOUNT_ROUTE = addPrefix(BENEFITS_ACCOUNT_BASELESS);

export const SALARY_INFO_BASELESS = 'salary-info';

export const SALARY_INFO_ROUTE = addPrefix(SALARY_INFO_BASELESS);

export const SUMMARY_ACCOUNT_BASELESS = 'summary';

export const SUMMARY_ACCOUNT_ROUTE = addPrefix(SUMMARY_ACCOUNT_BASELESS);

export const SWORN_DECLARATION_ACCOUNT_BASELESS = 'sworn-declaration';

export const SWORN_DECLARATION_ROUTE = addPrefix(SWORN_DECLARATION_ACCOUNT_BASELESS);

export const SUCCESS_OPEN_ACCOUNT_BASELESS = 'success';

export const SUCCESS_OPEN_ACCOUNT_ROUTE = addPrefix(SUCCESS_OPEN_ACCOUNT_BASELESS);

export const RESCHEDULING_ROUTE_BASELESS = 'rescheduling';

export const RESCHEDULING_ROUTE = addPrefix(RESCHEDULING_ROUTE_BASELESS);

export const RESCHEDULING_ROUTE_SEF_BASELESS = 'rescheduling-sef';

export const RESCHEDULING_ROUTE_SEF = addPrefix(RESCHEDULING_ROUTE_SEF_BASELESS);

export const SITE_RIPLEY_PUNTOS_GO = 'https://www.ripleypuntos.com.pe/';

export const R_PASS_AUTH_FORM_ROUTE = 'rpass-auth';

export const R_PASS_INTRO_ROUTE = 'intro-rpass';

export const R_PASS_AUTH_ROUTE = 'digital-auth';

export const R_PASS_QR_REDIRECT_APP = 'rpass-qr-redirect';

export const R_PASS_UNSUBSCRIBE_BASELESS = 'unsubscribe-rpass';

export const R_PASS_HELP_ROUTE_BASELESS = 'help-rpass';

export const R_PASS_CHANGE_RPASS_ROUTE_BASELESS = 'change-rpass-password';

export const R_PASS_UNSUBSCRIBE = addPrefix(R_PASS_UNSUBSCRIBE_BASELESS);

export const HOME_MENU_BUTTON = {
  title: 'Inicio',
  route: `/${HOME_ROUTE}`,
  icon: 'i-menu-home',
  class: '',
  activationCode: '0'
};

export const CONTACT_INFORMATION_MENU_BUTTON = {
  title: 'Tus datos',
  route: `/${CONTACT_INFORMATION_ROUTE}`,
  icon: 'i-menu-contact-information',
  class: '',
  activationCode: '0'
};

export const TRANSFER_MENU_BUTTON = {
  title: 'Transferir',
  route: `/${HOME_ROUTE}/4`,
  icon: 'i-menu-transfer',
  class: '',
  activationCode: '0'
};

export const PAY_MENU_BUTTON = {
  title: 'Pagar',
  route: `/${PAYMENTS_ROUTE}`,
  icon: 'i-menu-payment',
  class: '',
  activationCode: '0'
};

export const AGENCY_MAP = addPrefix(RIPLEY_AGENCIES_ROUTE_BASELESS);

// export const SEF_MENU_BUTTON = {
//   title: 'Ofertas para ti',
//   route: `/${OFFERS_ROUTE}`,
//   icon: 'i-menu-advances',
//   class: '',
//   activationCode: '0'
// };

export const RIPLEY_POINTS_MENU_BUTTON = {
  title: 'RipleyPuntos Go',
  route: `/${RIPLEY_POINTS_DASHBOARD_ROUTE}`,
  icon: 'i-menu-points',
  class: '',
  activationCode: '0'
};

export const ON_OFF_MENU_BUTTON = {
  title: 'Tarjeta On/Off',
  route: `/${HOME_ROUTE}/4`,
  icon: 'i-menu-points',
  class: '',
  activationCode: '0'
};

export const AHORRO_MENU_BUTTON = {
  title: 'Ahorros',
  route: `/${AHORRO_ROUTE}`,
  icon: 'i-money-icon',
  class: '',
  activationCode: '0'
};

export const BENEFITS_MENU_BUTTON = {
  title: 'Promociones',
  route: `/${PROMOTIONS_ROUTE}`,
  icon: 'i-menu-benefits',
  class: '',
  activationCode: '0'
};

export const TRANSFERS_MENU_BUTTON = {
  title: 'Transferencias',
  route: `/${TRANSFERS_ROUTE}`,
  icon: 'i-menu-transfer',
  class: '',
  activationCode: '0'
};

export const PAYMENTS_MENU_BUTTON = {
  title: 'Pagos',
  route: `/${PAYMENTS_ROUTE}`,
  icon: 'i-menu-payment',
  class: '',
  activationCode: '0'
};


export const ON_OFF_CARDS_BUTTON = {
  title: 'Tarjeta on/off',
  route: `/${ON_OF_ROUTE}`,
  icon: 'i-menu-onoff',
  class: '',
  activationCode: '9'
};

export const INVEST_MENU_BUTTON = {
  title: 'Deposito a plazos',
  route: `/${INVEST_ROUTE}`,
  icon: 'i-menu-money',
  class: '',
  activationCode: '0'
};

export const CREDIT_MENU_BUTTON = {
  title: 'Préstamos',
  route: `/${CREDITS_ROUTE}`,
  icon: 'i-menu-advances',
  class: '',
  activationCode: '0'
};

export const AGENCIES_MENU_BUTTON = {
  title: 'Red de agencias',
  route: `/${RIPLEY_AGENCIES_ROUTE}`,
  icon: 'i-light-pin-purple ',
  class: 'hidden-desktop',
  activationCode: '0'
};

export const KASNET_MENU_BUTTON = {
  title: 'Agencias KasNet',
  route: `KASNET`,
  icon: 'i-light-pin-purple ',
  class: 'hidden-desktop',
  activationCode: '0'
};

export const OPTIONS_MENU_BUTTON = {
  title: 'Ajustes',
  route: `/${OPTIONS_ROUTE}`,
  icon: 'i-light-pin-purple ',
  class: 'hidden-desktop',
  activationCode: '0'
};

export const R_PASS_MENU_BUTTON = {
  title: 'R Pass',
  route: `/${R_PASS_UNSUBSCRIBE}`,
  icon: 'i-menu-rpass-purple',
  class: '',
  activationCode: '0'
};


export const SIDE_MENU_ROUTES_BUTTONS = {
  HOME_MENU_BUTTON,
  CONTACT_INFORMATION_MENU_BUTTON,
  // SEF_MENU_BUTTON,
  BENEFITS_MENU_BUTTON,
  INVEST_MENU_BUTTON,
  AHORRO_MENU_BUTTON,
  TRANSFERS_MENU_BUTTON,
  PAYMENTS_MENU_BUTTON,
  ON_OFF_CARDS_BUTTON,
  CREDIT_MENU_BUTTON,
  RIPLEY_POINTS_MENU_BUTTON,
  R_PASS_MENU_BUTTON,
  OPTIONS_MENU_BUTTON,
  AGENCIES_MENU_BUTTON,
  KASNET_MENU_BUTTON,
};

export const MOBILE_HOME_BUTTONS = [
  TRANSFERS_MENU_BUTTON, // transferencias
  PAYMENTS_MENU_BUTTON, // pagos
  // ON_OFF_CARDS_BUTTON, // tarjeta on/off
  CREDIT_MENU_BUTTON, // prestamos
  // SEF_MENU_BUTTON, // ofertas para ti    
  BENEFITS_MENU_BUTTON, // promociones
];
