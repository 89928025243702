import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FACTOR_KEYS, RPASS_INITIATIVES } from '@common/constants/digital-auth.constants';
import { GENERIC_ERROR_RPASS } from '@common/constants/modal-props.constants';
import { USER_NAME_KEY } from '@common/constants/remember-user.constants';
import { HOME_ROUTE, LOGIN_ROUTE, R_PASS_CHANGE_RPASS_ROUTE_BASELESS, R_PASS_HELP_ROUTE_BASELESS } from '@common/constants/routes.constants';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { FirebaseService } from '@services/firebase/firebase.service';
import { LoginService } from "@services/login/login.service";
import { RPassService } from '@services/rpass/rpass.service';
import { StorageService } from '@services/storage/storage.service';
import { UtilsService } from '@services/utils/utils';


@Component({
  selector: 'app-rpass-menu',
  templateUrl: './rpass-menu.component.html',
  styleUrls: ['./rpass-menu.component.scss'],
})
export class RpassMenuComponent implements OnInit {
  @Input() public showMenu: boolean = false;
  @Output() public rpassUnsubscribe: EventEmitter<any> = new EventEmitter();

  public savedUserName: string;
  public factorRPass: FACTOR_KEYS;
  public menuItems: any = [
    { id: 2, name: 'Dar de baja' },
  ];

  constructor(
    private navCtrl: NavController,
    private firebaseService: FirebaseService,
    private loginService: LoginService,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private router: Router,
    private rpassService: RPassService,
    private storageService: StorageService,
  ) { }

  public get userIsSaved() { return !!this.savedUserName; }

  async ngOnInit(): Promise<void> {
    this.factorRPass = await this.rpassService.getRPassFactor();
    await this.addChangeRPassPasswordOption();
    this.setSavedUserInfo();
  }

  public onClickItem(item: number): void {
    switch (item) {
      case 1:
        this.navigateToChangeRPassPassword();
        break;
      case 2:
        this.closeMenuAndEmitUnsubscribeEvent();
        break;
    }
  }

  public navigateToChangeRPassPassword(): void {
    this.menuCtrl.close();
    this.router.navigateByUrl(R_PASS_CHANGE_RPASS_ROUTE_BASELESS, { replaceUrl: true });
  }

  public closeMenuAndEmitUnsubscribeEvent(): void {
    this.menuCtrl.close();
    this.rpassUnsubscribe.emit();
  }

  public navigateToRPassHelp(): void {
    this.menuCtrl.close();
    this.navCtrl.navigateForward(R_PASS_HELP_ROUTE_BASELESS);
  }

  public async quitRPass() {
    await this.menuCtrl.close()
    this.loginService.logoutWithoutRedirection().then(
      () => this.router.navigateByUrl(`/${LOGIN_ROUTE}`, { replaceUrl: true })
    );
  }

  private async addChangeRPassPasswordOption(): Promise<void> {
    if (this.factorRPass !== FACTOR_KEYS.pin) return;
    const { title: initiativeTitle, enabled: isInitiativeEnabled } = await this.firebaseService.getInitiativeRpass(RPASS_INITIATIVES.CAMBIAR_CLAVE);
    if (isInitiativeEnabled) this.menuItems = [...this.menuItems, { id: 1, name: initiativeTitle }]
    this.menuItems = UtilsService.sortArrayByKey(this.menuItems, 'id');
  }

  private async setSavedUserInfo(): Promise<void> {
    this.savedUserName = await this.storageService.get(USER_NAME_KEY);
    if (this.savedUserName) { this.savedUserName = UtilsService.capitalizeWords(this.savedUserName); }
  }

  public async showModalError(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: GENERIC_ERROR_RPASS
    });
    await modal.present();
    await modal.onDidDismiss().then(() => {
      return this.router.navigate([HOME_ROUTE], { replaceUrl: true });
    });
  }

}
