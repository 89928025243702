import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-store-message',
  templateUrl: './store-message.component.html',
  styleUrls: ['./store-message.component.scss'],
})
export class StoreMessageComponent {

  @Input() buttonDownload: any;
  @Input() disclaimerText: string;
  constructor() { }

  storeRedirect() {
    window.location.href = this.buttonDownload.url;
  }
}
