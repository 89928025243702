import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MAIN_CATEGORY } from '@common/constants/misc.constants';
import { UtilsService } from '@services/utils/utils';
import { FirebaseService } from '@services/firebase/firebase.service';

@Component({
  selector: 'app-redeem-details-modal',
  templateUrl: './redeem-details-modal.component.html',
  styleUrls: ['./redeem-details-modal.component.scss'],
})
export class RedeemDetailsModalComponent implements OnInit {
  @Input() public code: any;
  @Input() public category: any;
  public redeemedDate = '';
  public expirationDate = '';
  public fullName: string;
  public data: any;
  public product: any;
  public showButton = false;
  public textButton: string;
  public urlButton: string;

  constructor(
    private viewController: ModalController,
    private utils: UtilsService,
    private firebase: FirebaseService,
  ) { }

  public get isCodeCategoryGiftCard(): boolean {
    return this.code.category === MAIN_CATEGORY.GIFTCARD;
  }

  public ngOnInit() {
    this.redeemedDate = this.utils.formatDate(this.code.createdAt.toDate(), 'dd/MM/yyyy, hh:mm');
    this.expirationDate = this.getExpirationDate();
    this.setButtonOptions(this.code.sku);
  }

  public dismissModal() {
    this.viewController.dismiss();
  }

  public getExpirationDate() {
    if (this.code.expirationDate) {
      if (this.code.expirationDate.hasOwnProperty('seconds')) {
        return this.utils.formatDate(new Date(this.code.expirationDate.toDate()), 'dd/MM/yyyy');
      }
      return this.utils.formatDate(new Date(this.code.expirationDate), 'dd/MM/yyyy');
    }
  }

  public copy() {
    this.utils.shareData(this.code.code);
  }

  public redeemImgClass(imgType: string) {
    if (imgType === MAIN_CATEGORY.GIFTCARD) {
      return 'giftcard-img title';
    } else {
      return 'card-img';
    }
  }

  public async setButtonOptions(sku) {
    this.product = (await this.firebase.getFirebaseCollection('products').doc(sku).get()).data();
    if (this.product && this.product.button) {
      this.showButton = this.product.button.show;
      this.textButton = this.product.button.text;
      this.urlButton = this.product.button.url;
    }
  }

  public goToProduct() {
    if (this.urlButton.includes('https://') || this.urlButton.includes('http://')) {
      window.location.href = this.urlButton;
    }
    console.error('Error: bad url', this.urlButton);
  }
}
