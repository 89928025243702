import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CREDITS_ROUTE } from '@common/constants/routes.constants';
import { ModalController } from '@ionic/angular';
import { HOME_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { GENERIC_TAG_DESGRAVAMEN, GENERIC_TAG_DESGRAVAMEN_CON_DEVOLUCION, GENERIC_TAG_POP_UP } from '@common/constants/tag/tag-generic.constants';
import { SEF } from '@common/constants/misc.constants';

@Component({
  selector: 'app-leave-offer',
  templateUrl: './leave-offer.component.html',
  styleUrls: ['./leave-offer.component.scss'],
})
export class LeaveOfferComponent implements OnInit {

  @Input() message: string;
  @Input() primaryButtonText: string;
  @Input() secondaryButtonText: string;
  @Input() icon:string;
  @Input() amount:string;
  @Input() fee:string;
  @Input() product:string;
  @Input() ensuranceType:boolean = false;

  constructor(
    private viewController: ModalController,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService) {

  }

  ngOnInit() {
    HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_LOAD.sub_category = this.product;
    HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_LOAD.ensurance_type = this.product == SEF ? this.ensuranceType == false ? GENERIC_TAG_DESGRAVAMEN : GENERIC_TAG_DESGRAVAMEN_CON_DEVOLUCION : '';
    HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_LOAD.amount = this.amount;
    HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_LOAD.fee = this.fee;
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_POP_UP, HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_LOAD);
  }

  goToOffers() {
    HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_SALIR.sub_category = this.product;
    HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_SALIR.ensurance_type = this.product == SEF ? this.ensuranceType == false ? GENERIC_TAG_DESGRAVAMEN : GENERIC_TAG_DESGRAVAMEN_CON_DEVOLUCION : '';
    HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_SALIR.amount = this.amount;
    HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_SALIR.fee = this.fee;
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_POP_UP, HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_SALIR);
    this.viewController.dismiss();
    return this.router.navigate([`/${CREDITS_ROUTE}`], { replaceUrl : true });
  }

  dismissModal() {
    HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_CANCELAR.sub_category = this.product;
    HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_CANCELAR.ensurance_type = this.product == SEF ? this.ensuranceType == false ? GENERIC_TAG_DESGRAVAMEN : GENERIC_TAG_DESGRAVAMEN_CON_DEVOLUCION : '';
    HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_CANCELAR.amount = this.amount;
    HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_CANCELAR.fee = this.fee;
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_POP_UP, HOME_GA4_CONSTANTS.HOME_LOANS_CANCELAR_MODAL_CANCELAR);
    this.viewController.dismiss();
  }

}
