import { DEFAULT_LOCALE } from '@common/constants/misc.constants';
import { ICardMessage } from '@common/interfaces/reward-points.interface';
import {CUSTOMER_POINTS_TO_BE_PLUS,CUSTOMER_POINTS_TO_BE_GOLD, CUSTOMER_POINTS_TO_BE_SILVER} from '@common/constants/reward-points.constants';
let client = [];
const POINTS_SILVER_TO_GOLD = 8500;
const POINTS_PLUS_TO_SILVER = 2500;

export function pursuitSilverGold(pointsToBeSilver: number): ICardMessage[] {
  client = [
    {
      message: 'Para esta categoría te faltan <span class="category-points-amount plus-category">' + transformToAmount(CUSTOMER_POINTS_TO_BE_PLUS-pointsToBeSilver)
        + ' Puntos GO</span>',
      category: 'plus',
      showBenefitsButton: true,
      isCongrats: false
    },
    {
      message: 'Para esta categoría te faltan <span class="category-points-amount silver-category">' + transformToAmount(CUSTOMER_POINTS_TO_BE_SILVER-pointsToBeSilver)
          + ' Puntos GO</span>',
      category: 'silver',
      showBenefitsButton: true,
      isCongrats: false
    },
    {
      message: 'Para esta categoría te faltan <span class="category-points-amount gold-category">' + transformToAmount(CUSTOMER_POINTS_TO_BE_GOLD-pointsToBeSilver)
        + ' Puntos GO</span>',
      category: 'gold',
      showBenefitsButton: true,
      isCongrats: false
    },
  ];
  return client;
}

export function maintainSilverPursuitGold(pointsToBeSilver: number, pointsToBeGold: number): ICardMessage[] {
  client = [
    {
      message: 'Te faltan <span class="category-points-amount">' + transformToAmount(pointsToBeSilver)
        + '</span> puntos para mantener tu categoría <span class="category-text silver-category">silver</span>',
      category: 'silver',
      showBenefitsButton: false,
      isCongrats: false
    },
    {
      message: 'Te faltan <span class="category-points-amount">' + transformToAmount(pointsToBeGold)
        + '</span> puntos para subir a categoría <span class="category-text gold-category">gold</span>',
      category: 'gold',
      showBenefitsButton: true,
      isCongrats: false
    },
  ];
  return client;
}

export function maintainGold(pointsToBeGold: number): ICardMessage[] {
  client = [
    {
      message: 'Te faltan <span class="category-points-amount">' + transformToAmount(pointsToBeGold)
        + '</span> puntos para mantener tu categoría <span class="category-text gold-category">gold</span>',
      category: 'gold',
      showBenefitsButton: false,
      isCongrats: false
    },
  ];
  return client;
}

export function congratsSoonToBeGold(date: any): ICardMessage[] {
  if (calculateMessage(date)) {
    client = [
      {
        message: 'Estamos actualizando tu categoría',
        category: 'gold',
        showBenefitsButton: false,
        isCongrats: true
      },
    ];
  } else {
    client = [
      {
        message: '¡Felicidades! Serás <span class="category-text gold-category">gold</span> a partir de '
          + getNextMonth(date),
        category: 'gold',
        showBenefitsButton: false,
        isCongrats: true
      },
    ];
  }
  return client;
}

export function congratsGold(): ICardMessage[] {
  client = [
    {
      message: '¡Felicidades! Eres categoría <span class="category-text gold-category">gold</span>',
      category: 'gold',
      showBenefitsButton: false,
      isCongrats: true
    },
  ];
  return client;
}

export function pursuitGold(pointsToBeGold: number): ICardMessage[] {
  client = [
    {
      message:
        'Para esta categoría te faltan <span class="category-points-amount">' + transformToAmount(CUSTOMER_POINTS_TO_BE_GOLD-pointsToBeGold)
        + ' Puntos GO </span>',
      category: 'gold',
      showBenefitsButton: true,
      isCongrats: false
    },
  ];
  return client;
}

export function pursuitSilver(pointsToBeSilver: number): ICardMessage[] {
  client = [
    {
      message: 'Te faltan <span class="category-points-amount">' + transformToAmount(pointsToBeSilver)
        + '</span> puntos para subir a categoría <span class="category-text plus-category">plus</span>',
      category: 'plus',
      showBenefitsButton: true,
      isCongrats: false
    },
  ];
  return client;
}

export function congratsSilverPursuitGold(pointsToBeGold: number, date: any): ICardMessage[] {
  if (calculateMessage(date)) {
    client = [
      {
        message: 'Estamos actualizando tu categoría',
        category: 'silver',
        showBenefitsButton: false,
        isCongrats: true
      },
      {
        message: 'Te faltan <span class="category-points-amount">' + transformToAmount(pointsToBeGold)
          + '</span> puntos para subir a categoría <span class="category-text gold-category">gold</span>',
        category: 'gold',
        showBenefitsButton: true,
        isCongrats: false
      },
    ];
  } else {
    client = [
      {
        message: '¡Felicidades! Serás <span class="category-text silver-category">silver</span> a partir de '
          + getNextMonth(date),
        category: 'silver',
        showBenefitsButton: false,
        isCongrats: true
      },
      {
        message: 'Te faltan <span class="category-points-amount">' + transformToAmount(pointsToBeGold)
          + '</span> puntos para subir a categoría <span class="category-text gold-category">gold</span>',
        category: 'gold',
        showBenefitsButton: true,
        isCongrats: false
      },
    ];
  }
  return client;
}

export function tagUpdateSilver(date: any) {
  let tagMessage = '';
  if (calculateMessage(date)) {
    tagMessage = 'Estamos actualizando tu categoría';
  } else {
    tagMessage = 'Serás <span class="silver-category">Silver</span> a partir de ' + getNextMonth(date);
  }
  return tagMessage;
}

export function tagUpdateGold(date: any) {
  let tagMessage = '';
  if (calculateMessage(date)) {
    tagMessage = 'Estamos actualizando tu categoría';
  } else {
    tagMessage = 'Serás <span class="gold-category">Gold</span> a partir de ' + getNextMonth(date);
  }
  return tagMessage;
}

export function transformToAmount(amount: number) {
  if (amount !== 0 && !amount) { return 'N/D'; }
  amount = amount < 0 ? amount * -1 : amount;
  return amount.toLocaleString('en-us');
}

export function getNextMonth(date: any) {
  const newMonth = new Date(new Date(date).setDate(1));
  newMonth.setMonth(newMonth.getMonth() + 1);
  const text = newMonth.toLocaleDateString(DEFAULT_LOCALE, { month: 'long' });
  return text[0].toUpperCase() + text.slice(1).toLowerCase();
}

function calculateMessage(date: any) {
  const monthToShowPlusOne = new Date(date).getMonth() + 1;
  const actualMonth = new Date().getMonth();
  return monthToShowPlusOne === actualMonth;
}

export function pursuitSilverGoldSinPlus(pointsToBeSilver: number): ICardMessage[] {
  client = [
    {
      message: 'Para esta categoría te faltan <span class="category-points-amount silver-category">' + transformToAmount(CUSTOMER_POINTS_TO_BE_SILVER-pointsToBeSilver)
        + ' Puntos GO </span>',
      category: 'silver',
      showBenefitsButton: true,
      isCongrats: false
    },
    {
      message: 'Para esta categoría te faltan <span class="category-points-amount gold-category">' + transformToAmount(CUSTOMER_POINTS_TO_BE_GOLD - pointsToBeSilver)
        + ' Puntos GO</span>',
      category: 'gold',
      showBenefitsButton: true,
      isCongrats: false
    },
  ];
  return client;
}