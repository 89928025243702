export const EFEX_CUALIFICATIONS_MOCK = {
  success: [],
  failures: [],
};

export const EFEX_SAVE_MOCK = 1;

export const EFEX_TERMS_AND_CONDITIONS = {
  success: [],
  failures: [],
};

export const EFEX_ACCOUNT_AUTHORIZATION = {
  success: [],
  failures: [],
};

export const EFEX_PROMOTIONALS_MOCK = {
  success: [],
  failures: [],
};
