export const LOGIN_OK = '0';

export const NOT_ENROLLED_CODE = '3';

export const INVALID_CREDENTIALS_CODE = '6';

export const LOCKED_USER_CODE = '15';

export const INVALID_CREDENTIALS_MESSAGE = 'Usuario y/o clave incorrectas';

export const NOT_ENROLLED_MESSAGE = 'No te encuentras afiliado a Banco Ripley';

export const ROUTES_WITH_BASIC_AUTHENTICATION = [
  'admin/user/connect/token'
];

export const EXTERNAL_ROUTE = [
  'jsonip.com'

];

export const REFRESH_TOKEN_REQUEST_DIFFERENTIAL = (3 / 4);

export const SECONDS_TO_MILLISECONDS = 1000;

export const CREATE_TOKEN = 1;

export const REFRESH_TOKEN = 2;

export const CREATE_TOKEN_PATH = 'connect';

export const REFRESH_TOKEN_PATH = 'refresh';

export const RESCUE_SESSION_TOKEN = 'rescueSessionToken';

export const RESCUE_REFRESH_TOKEN = 'rescueRefreshToken';

export const FIREBASE_TOKEN_ERROR = 'No valid token';

export const MULTIPLE_SESSIONS_CODE = '20';

export const MULTIPLE_SESSIONS_MESSAGE = 'Tu sesión está activa';

export const RESCUE_IDENTIFIER = 'rescueIdentifier';

export const RESCUE_TOKEN_EXPIRES = 'rescueTokenExpires';

export const USER_ID = 'user_id';

export const USER_INFO = 'userInfo';

export const ENROLL_DOCUMENT_NUMBER = 'enroll_document';

export const FACE_ID_PLATFORM_ID = 'face';

export const FACE_ID_NAME = 'Face ID';

export const LOCKED_USER_BY_ADMIN_CODE = '16';

export const UNKNOWN_ERROR_CODE = '17';

export const LOCKED_USER_BY_ADMIN_TITLE = 'Tu acceso a la Banca en línea se encuentra temporalmente bloqueado';

export const LOCKED_USER_BY_ADMIN_MESSAGE = 'Por favor, acércate a una de nuestras Agencias para orientarte con la reactivación. '
+ 'Puedes realizar tus pagos haciendo click aquí:';

// Tarjetas adicionales
export const ROUTES_WITH_HEADER_TOKEN = [
  '/productshbk/product/active/consolidate',
  '/productshbk/product/active/detail/tc',
  '/productshbk/product/active/detail/sef',
  '/productshbk/product/active/movements',
  '/product/creditcardoperation/restrictions/retrieve',
  '/product/creditcardoperation/restrictions/update',
  '/product/creditcardoperation/temporary-blocking/retrieve',
  '/product/creditcardoperation/temporary-blocking/update'
];

//routes with header channel
export const ROUTES_WITH_HEADER_CHANNEL = [
  '/product/creditcardoperation/restrictions/retrieve',
  '/product/creditcardoperation/restrictions/update',
  '/product/creditcardoperation/temporary-blocking/retrieve',
  '/product/creditcardoperation/temporary-blocking/update',
  '/productshbk/product/active/movements'
];

export const ROUTES_WITHOUT_ENCRYPTION = [
  '/api/v1/autorizar-transaccion',
  '/api/v1/rechazar-transaccion',
  '/get-device-status',
  '/block-device',
];

export const LOGIN_ERROR_MAP = {
  [NOT_ENROLLED_CODE]: NOT_ENROLLED_MESSAGE,
  [INVALID_CREDENTIALS_CODE]: INVALID_CREDENTIALS_MESSAGE,
  [LOCKED_USER_CODE]: LOCKED_USER_BY_ADMIN_TITLE,
  [LOCKED_USER_BY_ADMIN_CODE]: LOCKED_USER_BY_ADMIN_TITLE,
  [MULTIPLE_SESSIONS_CODE]: MULTIPLE_SESSIONS_MESSAGE
};