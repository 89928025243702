// EFEX

/**
 *  NA: NEW ACCOUNT
 */

// STEP 1
export const EFEX_NA_S1 = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_1',
    event_label: 'desplegar_seccion_1'
};

export const EFEX_NA_S1_SIMULATE = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_1.1',
    event_label: 'simular_en_ingreso_monto_efectivo_express'
};

export const EFEX_NA_S1_SHOW_BENEFITS = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_1.2',
    event_label: 'ver_beneficios_sin_cuenta'
};

export const EFEX_NA_S1_SIMULATE_INFO = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_1.3',
    event_label: ''
};

// STEP 2
export const EFEX_NA_S2 = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_2',
    event_label: 'desplegar_seccion_2'
};

export const EFEX_NA_S2_TERM_SELECTED = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_2.1',
    event_label: 'ingreso_de_otra_cuota_sin_cuenta'
};

export const EFEX_NA_S2_SHOW_FEEDBACK_MODAL = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_2.2',
    event_label: 'no_me_convence_monto_sin_cuenta'
};

export const EFEX_NA_S2_NEXT = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_2.3',
    event_label: 'continuar_en_seccion_2_sin_cuenta'
};

export const EFEX_NA_S2_NEW_SIMULATION = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_2.4',
    event_label: 'volver_a_simular_sin_cuenta'
};

// FEEDBACK MODAL
export const EFEX_NA_FEEDBACK_MODAL_INIT = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_2.5',
    event_label: 'modal_no_deseo_prestamo_sin_cuenta'
};

export const EFEX_NA_OPTION_SELECTED = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_2.5',
    event_label: 'modal_ultima_seleccion_sin_cuenta'
};

export const EFEX_NA_SEND_FEEDBACK = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_2.5',
    event_label: 'enviar_en_modal_no_deseo_prestamo'
};

// STEP 3
export const EFEX_NA_S3 = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_3',
    event_label: 'desplegar_seccion_3'
};

export const EFEX_NA_S3_BENEFITS = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_3.1',
    event_label: 'beneficios_ahorro_simple_sin_cuenta'
};

export const EFEX_NA_S3_NEW_SIMULATION = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_3.1',
    event_label: 'simulacion_otro_monto_sin_cuenta'
};

export const EFEX_NA_S3_NEXT = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_3.3',
    event_label: 'continuar_en_elegir_cuenta_sin_cuenta'
};

export const EFEX_NA_S3_CANCEL = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_3.4',
    event_label: 'cancelar_en_elegir_cuenta_sin_cuenta'
};

// STEP 4
export const EFEX_NA_S4 = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_4',
    event_label: 'desplegar_seccion_4'
};

export const EFEX_NA_S4_NEXT = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_4.1',
    event_label: 'continuar_abrir_cuenta_ahorro_en_seccion_4'
};

export const EFEX_NA_S4_CANCEL = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_4.2',
    event_label: 'cancelar_abrir_cuenta_ahorro_en_seccion_4'
};

// STEP 5 & 6
export const EFEX_NA_S5 = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_5',
    event_label: 'desplegar_seccion_5'
};

export const EFEX_NA_S6 = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_6',
    event_label: 'desplegar_seccion_6'
};

export const EFEX_NA_S6_NOT_MY_NUMBER = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_6.1',
    event_label: 'no_es_mi_numero_sms_sin_cuenta'
};

export const EFEX_NA_S6_CODE_ENTERED = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_6.2',
    event_label: 'ingresar_codigo_sms_sin_cuenta'
};

export const EFEX_NA_S6_SMS_NOT_SENT = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_6.4',
    event_label: 'no_llega_codigo_sms_sin_cuenta'
};

export const EFEX_NA_S6_DISBURSEMENT = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_6.5',
    event_label: 'abonar_prestamo_sin_cuenta'
};

export const EFEX_NA_S6_CANCEL = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_6.6',
    event_label: 'cancelar_abono_prestamo_sin_cuenta'
};

export const EFEX_NA_SUCCESS = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_7',
    event_label: 'exito_efex_abono_prestamo_sin_cuenta'
};

/**
 *  EA: EXISTING ACCOUNT
 */

// STEP 1
export const EFEX_EA_S1 = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_1',
    event_label: 'desplegar_seccion_1_con_cuenta'
};

export const EFEX_EA_S1_SIMULATE = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_1.1',
    event_label: 'simular_efectivo_express_con_cuenta'
};

export const EFEX_EA_S1_SHOW_BENEFITS = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_1.2',
    event_label: 'ver_beneficios_con_cuenta'
};

export const EFEX_EA_S1_SIMULATE_INFO = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfex',
    event_action: 'step_1.3',
    event_label: ''
};

// STEP 2
export const EFEX_EA_S2 = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_2',
    event_label: 'desplegar_seccion_2_con_cuenta'
};

export const EFEX_EA_S2_TERM_SELECTED = {
    event: 'efexSinCuenta_event',
    event_category: 'flujoEfexSinCuenta',
    event_action: 'step_2.1',
    event_label: 'ingreso_de_otra_cuota_con_cuenta'
};

export const EFEX_EA_S2_SHOW_FEEDBACK_MODAL = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_2.2',
    event_label: 'no_me_convence_monto_con_cuenta'
};

export const EFEX_EA_S2_NEXT = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_2.3',
    event_label: 'continuar_en_seccion_2_con_cuenta'
};

export const EFEX_EA_S2_NEW_SIMULATION = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_2.4',
    event_label: 'volver_a_simular_con_cuenta'
};

// STEP 3
export const EFEX_EA_S3 = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_3',
    event_label: 'desplegar_seccion_3_con_cuenta'
};

export const EFEX_EA_S3_OTHER_BANK = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_3.1',
    event_label: 'agregar_otro_destinatario_con_cuenta'
};

export const EFEX_EA_S3_NEW_SIMULATION = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_3.2',
    event_label: 'simular_otro_monto_con_cuenta'
};

export const EFEX_EA_S3_NEXT = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_3.3',
    event_label: 'continuar_en_elegir_cuenta_con_cuenta'
};

export const EFEX_EA_S3_CANCEL = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_3.4',
    event_label: 'cancelar_en_elegir_cuenta_con_cuenta'
};

// ADD ACCOUNT MODAL
export const EFEX_EA_ADD_ACCOUNT_MODAL = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_3.5',
    event_label: 'desplega_pantalla_nueva_cuenta_otro_banco'
};

export const EFEX_EA_ADD_ACCOUNT_NEXT = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_3.6',
    event_label: 'continuar_en_pantalla_nueva_cuenta_otro_banco'
};

export const EFEX_EA_ADD_ACCOUNT = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_3.7',
    event_label: 'agregar_nueva_cuenta_otro_banco'
};

export const EFEX_EA_ADD_ACCOUNT_BACK = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_3.8',
    event_label: 'regresar_desde_nueva_cuenta_otro_banco'
};

// STEP 4 & 5
export const EFEX_EA_S4 = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_4',
    event_label: 'desplegar_seccion_4_con_cuenta'
};

export const EFEX_EA_S5 = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_5',
    event_label: 'desplegar_seccion_5_con_cuenta'
};

export const EFEX_EA_S5_NOT_MY_NUMBER = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_5.1',
    event_label: 'no_es_mi_numero_sms_con_cuenta'
};

export const EFEX_EA_S5_CODE_ENTERED = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_5.2',
    event_label: 'ingresar_codigo_sms_con_cuenta'
};

export const EFEX_EA_S5_SMS_NOT_SENT = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_5.4',
    event_label: 'no_llega_codigo_sms_con_cuenta'
};

export const EFEX_EA_S5_DISBURSEMENT = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_5.5',
    event_label: 'abonar_prestamo_con_cuenta'
};

export const EFEX_EA_S5_CANCEL = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_5.6',
    event_label: 'cancelar_abono_prestamo_con_cuenta'
};

export const EFEX_EA_SUCCESS = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_6',
    event_label: 'exito_efex_abono_prestamo'
};

/**
 * OTHER BANK: OB
 */

 // STEP 4 & 5
export const EFEX_OB_S4 = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_4',
    event_label: 'desplegar_seccion_4_otro_banco'
};

export const EFEX_OB_S5 = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_5',
    event_label: 'desplegar_seccion_5_otro_banco'
};

export const EFEX_OB_S5_NOT_MY_NUMBER = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_5.1',
    event_label: 'no_es_mi_numero_sms_otro_banco'
};

export const EFEX_OB_S5_CODE_ENTERED = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_5.2',
    event_label: 'ingresar_codigo_sms_otro_banco'
};

export const EFEX_OB_S5_SMS_NOT_SENT = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_5.4',
    event_label: 'no_llega_codigo_sms_otro_banco'
};

export const EFEX_OB_S5_DISBURSEMENT = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_5.5',
    event_label: 'abonar_prestamo_otro_banco'
};

export const EFEX_OB_S5_CANCEL = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_5.6',
    event_label: 'cancelar_abono_prestamo_otro_banco'
};

export const EFEX_OB_SUCCESS = {
    event: 'efex_event',
    event_category: 'flujoEfex',
    event_action: 'step_6',
    event_label: 'exito_efex_abono_prestamo_otro_banco'
};
