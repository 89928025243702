import { Injectable } from '@angular/core';
import { ITraceability } from '@common/interfaces/traceability.interface';
import { TRACEABILITY_DETAIL_MOCK } from '@common/mocks/traceablity.mocks';
import { environment } from '@environments/environment';
import { HttpService } from '@services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class TraceabilityService {

  constructor(
    private http: HttpService
  ) { }

  public traceablityEnrollment(params: ITraceability) {
    const url = environment.BASE_BACKEND_WORKER + '/enrollment/accountuserhb/Traceability';
    return this.http.post(url, params, TRACEABILITY_DETAIL_MOCK).toPromise();
  }
}
