export const CONSOLIDATED_CARDS_PRODUCTS_MOCK = {
  success: [],
  failures: [],
};

export const CONSOLIDATED_AUTHENTICATED_PRODUCTS_MOCK = {
  success: [],
  failures: [],
};

export const PRODUCTS_CARDS_DETAILS_MOCK = {
  success: [],
  failures: [],
};

export const CONSOLIDATED_PRODUCTS_MOCK = {
  success: [],
  failures: [],
};

export const CREDIT_CARD_TYPE_MOCK = {
  success: [],
  failures: [],
};

export const SEF_TYPE_MOCK = {
  success: [],
  failures: [],
};

export const STATEMENT_MONTH_LIST_MOCK = {
  success: [],
  failures: [],
};

export const STATEMENT_DOCUMENT_MOCK = {
  success: [],
  failures: [],
};

export const CREDIT_CARD_DETAILS_MOCK = {
  success: [],
  failures: [],
};

export const SEF_DETAILS_MOCK = {
  success: [],
  failures: [],
};

export const PRODUCT_MOVEMENTS_MOCK = {
  success: [],
  failures: [],
};

// Tarjetas adicionales
export const PRODUCT_MOVEMENTS_DETAIL_MOCK = {
  success: [],
  failures: [],
};
