export const CONTACT_DETAIL_MOCK = {
  success: [],
  failures: [],
};

export const CHANGE_PASSWORD_MOCK = {
  success: [],
  failures: [],
};

export const UPDATE_CONTACT_DATA_MOCK = {
  success: [],
  failures: [],
};
