import { GENERIC_TAG_OTRO_BANCO } from "./tag/tag-generic.constants";

export const IDNOWSTEPS = {
  INIT_ENROLLEMNT_RESPONSE: 'MobileProcessInitEnrollmentResponse',
  DEVICE_STATUS: 'MobileGetDeviceStatus',
  NOT_ENROLLMENT: 'NOTENROLLMENT',
  INITENROLLMENT: 'MobileInitEnrollment',
  PREPARE_INITENROLLMENT : 'PREPARE_INITENROLLMENT',
  REGISTERPATH: 'MobileGetRegisterPath',
  ENROLLMENT_BY_CONTEXT: 'ENROLLMENTBYCONTEXT',
  OCR_DNI: 'MobileFinalAnalizeDNI',
  PREPARE_OCR_DNI : 'PREPARE_OCR',
  FACE_AUTH: 'FACE_AUTH',
  PREPARE_BIOMETRIA : 'PREPARE_BIOMETRIA',
  BIOMETRIA: 'MobileFinalMatchFace',
  FINISH_ENROLLEMENT: 'SISTEMA',
  FINISH: 'FINISH',
  FINISHENROLLMENTCOMPLETED: 'FINISHENROLLMENT_COMPLETE',
  RPASS: 'OTP',
  PREPARE_RPASS : 'PREPARE_OTP',
  PROCESS_FINISHENROLLMENT: 'FINISHENROLLMENT',
  FINISH_ENROLLMENT_CANCEL: 'FINISH_ENROLLMENT_CANCEL',
  CAMERA_ERROR: 'CameraError',
  INVALID_REQUEST: 'InvalidRequest',
  UNHANDLED_ERROR: 'UnhandledError',
  InitAuthorization: 'InicioAutenticacion',
};

export const IDNOW = {
  STATUS: 'status',
  VALUE_STATUS: 'RULE_RESULT',
  ENROLLMENT_TYPE: 'enrollmentType',
  FLOW_CODE: 'flowCode',
  APP_ID: 'appId',
  VALUE_KEY: '00',
  CODE: 'code'
};

export const SECOND_IN_MS = 1000;
export const APP_ID = 'RIPLEY PERU';
export const COUNTRY = 'PERU';
export const PIN_RPASS_MAX_LENGTH = 6;

export const FACE_BACKEND = 'ZOOM_9';
export const JWT_SECRET_KEY = 'sfkPwGF7W9AeI81Zv0tdyT4lN2aqLVQHhcgup3BUYmi5OzSCED6jboxRJKMnrX';
export const JWT_SECRET_V2 = 'X_8PP_o0A3Ez8i_wnlAjay4bz7lPna_0L0i_OCLkVT0';

export const FACE_ID_FACTOR = 'face';
export const FINGER_FACTOR = 'finger';
export const BIOMETRIC_FACTOR = 'biometric';
export const PIN_FACTOR = 'pin';
export const NOT_ENROLLED_FACTOR = 'BIOMETRIC_NOT_ENROLLED';

export const FACE_ID_TITLE = 'Face ID';
export const FINGERPRINT_TITLE = 'Huella digital';
export const PIN_TITLE = 'Clave R Pass';

export const ONLY_NUMBERS_REGEX = /\d+/g;
export const SPECIAL_CHARACTER_KEY = 'Unidentified';
export const ENTER_KEY = 'Enter';
export const BACKSPACE_KEY = 'Backspace';
export const PIN_RPASS_LENGTH_DEFAULT = 6;
export const NON_CONSECUTIVE_RPASS_PIN_REGEX = /^(?!012345|123456|234567|345678|456789|987654|876543|765432|654321|543210)\d{6}$/;
export const NON_REPEATED_RPASS_PIN_REGEX = /^(\d)(?!\1{5})\d{5}$/;

export const RPASS_FACTORS = [
  {
    title: FACE_ID_TITLE,
    icon: '../../../assets/icons/i-rpass-faceid-factor.svg',
    type: '',
    isSelected: false,
    isAvailable: false,
    isSupports: false,
  },
  {
    title: FINGERPRINT_TITLE,
    icon: '../../assets/icons/i-rpass-fingerprint-factor.svg',
    type: '',
    isSelected: false,
    isAvailable: false,
    isSupports: false,
  },
  {
    title: PIN_TITLE,
    icon: '../../assets/icons/i-rpass-pin-factor.svg',
    type: '',
    isSelected: false,
    isAvailable: false,
    isSupports: false,
  },
];

export enum FACTOR_KEYS {
  factor = 'valorFACTOR',
  face = 'valorFACE',
  finger = 'valorFINGER',
  biometric = 'valorBIOMETRIC',
  pin = 'valorPIN',
}

export enum MONITOR_AUTH_RESULT {
  aprobado = 'aprobado',
  maxIntento = 'maxIntento',
  rechazado = 'rechazado',
  tiempo = 'tiempo-vencido',
}

export const RPASS_FACTORS_TITLES = {
  pin : 'Clave R Pass',
  finger: 'Huella digital',
  biometric : 'Huella digital',
  face : 'Face ID'
}

export const BIOMETRIC_RPASS_RESULT_CODES = {
  SUCESSFUL: '0',
  DISMISSED : '-108',
  LOCKED_OUT: '-111',
  LOCKED_OUT_PERMANENT: '-112'
}

export const ENROLLMENT_KEY = 'ENROLLMENT';

export const DEVICE_ACTIVE = 'DEVICE_ACTIVE';

export const DEVICE_INACTIVE = 'DEVICE_INACTIVE';

export const BIOMETRIC_RPASS_AUTH_CODES = {
  valorBIOMETRIC: {
    '0': 'pendingServiceAuthorization',
    '-108': 'pending',
    '-111': 'pendingServiceRejection',
    '-112': 'pendingServiceRejection',
  },
  valorFINGER: {
    '0': 'pendingServiceAuthorization',
    '-102': 'pendingServiceRejection',
    '-108': 'pending',
    '-111': 'pendingServiceRejection',
  },
  valorFACE: {
    '0': 'pendingServiceAuthorization',
    '-108': 'pending',
    '-111': 'pendingServiceRejection',
  },
};

export const PREPARE_ENROLLMENT_STEPS = {
  dniSelecction : 1,
  dniInstruction: 2,
}

export const DOCUMENT_TYPES = [
  {
    label: 'DNI azul',
    img: '../assets/img/rpass-dni-old.svg',
    value: 'CPA',
  },
  {
    label: 'DNI Electrónico',
    img: '../assets/img/rpass-dni-new.svg',
    value: 'CP',
  },
];

export const DNI_TYPES = {
  CPA: 'CPA',
  CP: 'CP'
};

export const DNI_TYPES_FLOW = {
  CPA: 'POCPRCD_2',
  CP: 'POCPRCD'
};

export const FLIP_DNI_ICON = {
  CPA: 'i-rpass-dni-old',
  CP: 'i-rpass-dni-new',
};

export const INSTRUCTIONS_DNI_IMG = {
  CPA: '../assets/img/rpass_dni_blue_instructions.gif',
  CP: '../assets/img/rpass_dni_white_instructions.gif'
}

export const ERROR_GENERIC = {
  icon: false,
  title: '¡UPS!',
  message: 'Algo ha salido mal. Inténtelo más tarde.',
};
export const ERROR_READ_DOCUMENT = {
  icon: false,
  title: '¡UPS!',
  message: 'Tenemos problemas leyendo tu DNI. Por favor vuelve a intentarlo',
};
export const ERROR_READ_FACE = {
  icon: false,
  title: '¡UPS!',
  message: 'Tenemos problemas con tu videoselfie. Por favor vuelve a intentarlo',
};
export const ERROR_INITIALIZATION_FAILED = {
  icon: false,
  title: '¡UPS!',
  message: 'No hemos podido realizar su solicitud, vuelva a intentar más tarde',
};

export const LOADING_PROPS = {
  default: {
    title: 'Por favor espera',
    message: 'Estamos procesando tus datos, esto podría tardar unos segundos.',
  },
  initEnrollment: {
    title: 'Estamos validando los datos entregados',
    message: 'No actualices ni cierres la pantalla.',
  },
  analizeDNI: {
    title: 'Estamos ingresando tu DNI',
    message: 'Esto podría tardar unos segundos,' + '\n' + 'no actualices ni cierres la pantalla.',
  },
  matchFace: {
    title: 'Faltan solo unos segundos',
    message: 'Estamos corroborando que tu video selfie coincida con el DNI.',
  },
  mobileFinishEnrollment: {
    title: '¡Ya casi! danos unos segundos más',
    message: 'Estamos guardando el método' + '\n' + 'de seguridad elegido.',
  }
};

export const PERSONAL_DATA_PROCESSING_POLICY_TEXT =
  'Los presentes términos y condiciones regulan el uso del proceso y servicio de identificación y autenticación digital ofrecidos por Banco Ripley Perú S.A. (en adelante, el “Banco”) a través de los canales digitales. Quien use dicho servicio adquiere inmediatamente la condición de usuario del mismo (en adelante, el “Usuario”) y, por tanto acepta sin reserva las disposiciones contenidas en los presentes términos y condiciones de uso, así como en cualquier otro aviso legal o documento que pueda encontrarse en los canales digitales y que los sustituyan, complementen y/o modifiquen, considerándose efectivos estos cambios desde la publicación de los mismos en los canales digitales.' +
  '<br><br> <strong>1. IDENTIFICACIÓN Y AUTENTICACIÓN DIGITAL </strong>' +
  '<br><br> <strong>1.1 </strong> Con el objeto de iniciar el proceso de identificación y autenticación digital, los Usuarios deberán tener que ingresar en cada oportunidad ciertos datos personales tales como su documento de identidad, número de teléfono y correo electrónico, para así comenzar el proceso de contratación de productos y servicios de Banco Ripley y/o ingreso de solicitudes, o bien para otras funciones que podrían incorporarse durante la vigencia del presente documento.' +
  '<br><br> <strong>1.2 </strong> Con esto se dará inicio al proceso de identificación y autenticación digital, lo cual requerirá del procesamiento de datos personales de los Usuarios incluyendo, pero no limitado a nombres, documento de identidad y datos biométricos tales como su imagen personal, lo que se regirá de acuerdo a la Política de Privacidad vigente de Banco Ripley.' +
  '<br><br> <strong>2. EL PROCESO </strong>' +
  '<br><br> <strong>2.1 </strong> Será responsabilidad del Usuario contar durante el proceso de identificación y autenticación digital con la información que será solicitada (como por ejemplo su documento de identidad), así como con los medios necesarios tales como el equipo o dispositivo móvil (incluyendo el correcto funcionamiento de la cámara del dispositivo móvil del Usuario), los programas o aplicaciones que permiten el funcionamiento de estos y el correspondiente acceso a internet.' +
  '<br><br> <strong>2.2 </strong> El Usuario conoce y acepta que etapas de este proceso podrían estar a cargo o ser provistos por terceros o integrados a equipos, programas o redes de terceros. ' +
  '<br><br> <strong>2.3 </strong> El Usuario reconoce y acepta que las aplicaciones móviles, programas, equipos y redes computacionales son herramientas para el procesamiento y transmisión de datos que por su naturaleza pueden estar sujetas a determinadas fallas que afectan su normal funcionamiento, contingencia a que se está expuesto cualquiera sea el administrador o proveedor de dichas herramientas y que no desaparece en virtud de la aceptación de los presentes términos y condiciones.' +
  '<br><br> <strong>2.4 </strong> Por lo tanto el Banco no se responsabiliza frente a usuarios o terceros por los daños y perjuicios que sean consecuencia directa o indirecta de la interrupción, suspensión o finalización del proceso regulado en este documento.' +
  '<br><br> <strong>3. LEYES APLICABLES Y JURISDICCIÓN </strong>' +
  '<br><br> <strong>3.1 </strong> Los términos y condiciones se regirán por la legislación peruana. En caso de cualquier controversia entre el Banco y el Usuario, ambos se someterán a los Juzgados y Tribunales del Distrito Judicial de Lima, renunciado expresamente al fuero o jurisdicción de sus domicilios.';

export const PRIVACY_POLICY_URL = 'https://www.bancoripley.com.pe/politica-de-privacidad.html';


export const DEFAULT_WIDGET_CONFIG_DATA = {
  appId: 'RIPLEY',
  userType: 'CPA',
  country: 'PERU',
  channel: 'HOMEBANKING',
  enterpriseCode: '',
};

export const RPASS_REDIRECT = {
  deepLinkUrl: null,
  playStore: "https://play.google.com/store/apps/details?id=com.ripley.banco.peru",
  appStore: "https://apps.apple.com/pe/app/banco-ripley-perú/id1425352352",
}

export const USER_RPASS_ACTIVATION_KEY = 'userRpassActivation';

export const TRX_KEY = 'transactionType';

export const RPASS_INITIATIVES = {
  DAR_DE_BAJA_RPASS: 'DarDeBajaRpass',
  NEW_CREATION_PINCODE: 'NewPinCode',
  CAMBIAR_CLAVE: 'CambiarClave',
  VALIDAR_PERMISO_NOTIFICACION: 'validarPermisoNotificacion',
  DISPOSITIVO_NUEVO: 'RPassDispositivoNuevo',
  NEW_DEVICE_IMPROVEMENT: 'MejoraDispositivoNuevo',
}

export const CHANGE_RPASS_PASSWORD = {
  INPUT_CURRENT_BOX: 'inputCurrentBox',
  INPUT_NEW_BOX: 'inputNewBox'
}

// GA4 RPASS

//activacion rpass

export const VDT_TRX = 'VISUALIZACIONTARJETA';

export const VIEW_CARD = 'ver tarjeta digital';

export const RPASS_GA4_CONSTANTS = {
  INTRO_RPASS: {
    EVENT: 'virtual_page',
    PRODUCT: 'intro R Pass',
    ACTION: 'pageview',
    STEP: 'step activa tu rpass',
    STEP_PART: 'step inicio activacion',
    CATEGORY: 'rpass'
  },
  INTRO_RPASS_BTN: {
    EVENT: 'btn_click',
    PRODUCT: 'activar',
    ACTION: 'click',
    CATEGORY: 'rpass'
  },
  LOGIN_RPASS: {
    EVENT: 'virtual_page',
    PRODUCT: 'login R Pass',
    ACTION: 'pageview',
    STEP: 'step activa tu rpass',
    STEP_PART: 'step ingreso cuenta',
    CATEGORY: 'rpass'
  },
  LOGIN_RPASS_BTN: {
    EVENT: 'btn_click',
    ACTION: 'click',
    CATEGORY: 'rpass'
  },
  SELECT_DNI: {
    EVENT: 'virtual_page',
    PRODUCT: 'seleccion tipo dni',
    ACTION: 'pageview',
    STEP: 'step validacion dni',
    STEP_PART: 'step seleccion tipo dni',
    CATEGORY: 'rpass'
  },
  SELECT_DNI_BTN: {
    EVENT: 'btn_click',
    PRODUCT: 'continuar',
    ACTION: 'click',
    CATEGORY: 'rpass'
  },
  DNI_INSTRUCTION: {
    EVENT: 'virtual_page',
    PRODUCT: 'validaremos tu identidad',
    ACTION: 'pageview',
    STEP: 'step validacion dni',
    STEP_PART: 'step validar identidad',
    CATEGORY: 'rpass'
  },
  DNI_INSTRUCTION_BTN: {
    EVENT: 'btn_click',
    PRODUCT: 'empecemos',
    ACTION: 'click',
    CATEGORY: 'rpass'
  },
  FRONT_DNI: {
    EVENT: 'virtual_page',
    PRODUCT: 'validacion dni',
    ACTION: 'pageview',
    STEP: 'step validacion dni',
    STEP_PART: 'step documento frontal',
    CATEGORY: 'rpass'
  },
  BACK_DNI: {
    EVENT: 'virtual_page',
    PRODUCT: 'validacion dni',
    ACTION: 'pageview',
    STEP: 'step validacion dni',
    STEP_PART: 'step documento trasero',
    CATEGORY: 'rpass'
  },
  MODAL_BUTTON: {
    EVENT: 'btn_click',
    PRODUCT: 'continuar',
    ACTION: 'click',
    STEP: 'step validacion dni',
    STEP_PART: 'step documento trasero',
    CATEGORY: 'rpass'
  },
  FACE_INSTRUCTION: {
    EVENT: 'virtual_page',
    PRODUCT: 'validaremos tu identidad',
    ACTION: 'pageview',
    STEP: 'step validacion face id',
    STEP_PART: 'step validar identidad',
    CATEGORY: 'rpass'
  },
  FACE_INSTRUCTION_BTN: {
    EVENT: 'btn_click',
    PRODUCT: 'empecemos',
    ACTION: 'click',
    CATEGORY: 'rpass'
  },
  MATCH_FACE: {
    EVENT: 'virtual_page',
    PRODUCT: 'validacion face',
    ACTION: 'pageview',
    STEP: 'step validacion face id',
    STEP_PART: 'step selfie inicio',
    CATEGORY: 'rpass'
  },
  FINAL_MATCH_FACE: {
    EVENT: 'virtual_page',
    PRODUCT: 'validacion face',
    ACTION: 'pageview',
    STEP: 'step biometria confirmada',
    CATEGORY: 'rpass'
  },
  SELECT_METHODS: {
    EVENT: 'virtual_page',
    PRODUCT: 'seleccion metodo de seguridad',
    ACTION: 'pageview',
    STEP: 'step metodo de seguridad',
    STEP_PART: 'step seleccion metodo',
    CATEGORY: 'rpass'
  },
  SELECT_METHODS_BTN: {
    EVENT: 'btn_click',
    ACTION: 'click',
    CATEGORY: 'rpass'
  },
  FACEID_MODAL: {
    EVENT: 'virtual_page',
    PRODUCT: 'permitir face id',
    ACTION: 'pageview',
    STEP: 'step metodo de seguridad',
    STEP_PART: 'step permiso face id inicio',
    CATEGORY: 'rpass'
  },
  FACEID_MODAL_BTN: {
    EVENT: 'btn_click',
    PRODUCT: 'permitir',
    ACTION: 'click',
    CATEGORY: 'rpass'
  },
  FINGER_MODAL: {
    EVENT: 'virtual_page',
    PRODUCT: 'permitir face id',
    ACTION: 'pageview',
    STEP: 'step metodo de seguridad',
    STEP_PART: 'step permiso huella digital inicio',
    CATEGORY: 'rpass'
  },
  FINGER_MODAL_BTN: {
    EVENT: 'btn_click',
    PRODUCT: 'permitir',
    ACTION: 'click',
    CATEGORY: 'rpass'
  },
  CREATE_PIN: {
    EVENT: 'virtual_page',
    PRODUCT: 'crea clave rpass',
    ACTION: 'pageview',
    STEP: 'step metodo de seguridad',
    STEP_PART: 'step creacion clave inicio',
    CATEGORY: 'rpass'
  },
  CREATE_PIN_BTN: {
    EVENT: 'btn_click',
    PRODUCT: 'crear rpass',
    ACTION: 'click',
    CATEGORY: 'rpass'
  },
  FINAL_SCREEN: {
    EVENT: 'virtual_page',
    PRODUCT: 'listo',
    ACTION: 'pageview',
    STEP: 'step metodo de seguridad',
    STEP_PART: 'step creacion clave final',
    CATEGORY: 'rpass'
  },
  FINAL_SCREEN_TOUCHID: {
    STEP_PART: 'step creacion huella digital final',
    CATEGORY: 'rpass'
  },
  FINAL_SCREEN_FACEID: {
    STEP_PART: 'step permiso face id final',
    CATEGORY: 'rpass'
  }
}

// uso rpass

export const EVENT_TYPES = {
  VIRTUAL_EVENT: 'virtual_page',
  CLICK_EVENT: 'btn_click',
  INPUT_EVENT: 'input',
  POP_UP: 'pop_up',
  ERROR: 'functional_error',
  END_EVENT: 'end_flow'
}

export const EVENT_ACTION = {
  PAGE_ACTION: 'pageview',
  CLICK_ACTION: 'click'
}

export const USE_RPASS_GA4 = {
  MAIN_AUTH: {
    STEP_PAGEVIEW: {
      EVENT_TYPE: EVENT_TYPES.VIRTUAL_EVENT,
      EVENT_PARAMS: {
        product:'ingresa tu rpass para autorizar',
        action: EVENT_ACTION.PAGE_ACTION,
        status: '0',
        step: 'step ingresar rpass',
        auth_method: 'rpass'
      },
    },
    STEP_INPUT: {
      EVENT_TYPE: EVENT_TYPES.INPUT_EVENT,
      EVENT_PARAMS: {
        product:'ingresar rpass ',
        action: EVENT_ACTION.CLICK_ACTION,
        status: '0',
        step: 'step ingresar rpass',
        auth_method: 'rpass'
      },
    },
    STEP_AUTHORIZE: {
      EVENT_TYPE: EVENT_TYPES.CLICK_EVENT,
      EVENT_PARAMS: {
        product:'autorizar',
        action: EVENT_ACTION.CLICK_ACTION,
        status: '0',
        step: 'step ingresar rpass',
        auth_method: "rpass",
      },
    },
    STEP_CLICK_REJECT: {
      EVENT_TYPE: EVENT_TYPES.CLICK_EVENT,
      EVENT_PARAMS: {
        product:'rechazar',
        action: EVENT_ACTION.CLICK_ACTION,
        status: '0',
        step: 'step ingresar rpass',
        auth_method: "rpass",
      },
    },
    STEP_FORGOT: {
      EVENT_TYPE: EVENT_TYPES.CLICK_EVENT,
      EVENT_PARAMS: {
        product:'olvidé mi rpass',
        action: EVENT_ACTION.CLICK_ACTION,
        status: '0',
        step: 'step ingresar rpass',
        auth_method: "rpass",
      },
    },
    STEP_FINAL_AUTH: {
      EVENT_TYPE: EVENT_TYPES.VIRTUAL_EVENT,
      EVENT_PARAMS: {
        product:'autorizacion exitosa',
        action: EVENT_ACTION.PAGE_ACTION,
        status: '0',
        step: 'step autorizacion exitosa rpass',
        auth_method: "rpass",
      },
    },
    STEP_FINAL_REJECTED: {
      EVENT_TYPE: EVENT_TYPES.VIRTUAL_EVENT,
      EVENT_PARAMS: {
        product:'autorizacion rechazada',
        action: EVENT_ACTION.PAGE_ACTION,
        status: '0',
        step: 'step autorizacion rechazada rpass',
        auth_method: "rpass",
      },
    },
    STEP_FINAL_CLICK: {
      EVENT_TYPE: EVENT_TYPES.CLICK_EVENT,
      EVENT_PARAMS: {
        product:'volver al inicio',
        action: EVENT_ACTION.CLICK_ACTION,
        status: '0',
        step: 'step volver al inicio',
        auth_method: "rpass",
      },
    },
    STEP_FORGOT_PAGEVIEW: {
      EVENT_TYPE: EVENT_TYPES.VIRTUAL_EVENT,
      EVENT_PARAMS: {
        product:'olvidaste tu rpass',
        action: EVENT_ACTION.PAGE_ACTION,
        status: '0',
        step: 'step olvido rpass',
        auth_method: "rpass",
      },
    },
    STEP_FORGOT_CLICK: {
      EVENT_TYPE: EVENT_TYPES.CLICK_EVENT,
      EVENT_PARAMS: {
        product:'volver a activar',
        action: EVENT_ACTION.CLICK_ACTION,
        status: '0',
        step: 'step olvido rpass',
        auth_method: "rpass",
      },
    },
    STEP_CANCEL_CLICK: {
      EVENT_TYPE: EVENT_TYPES.CLICK_EVENT,
      EVENT_PARAMS: {
        product:'cancelar',
        action: EVENT_ACTION.CLICK_ACTION,
        status: '0',
        step: 'step olvido rpass',
        auth_method: "rpass",
      },
    },
    STEP_REJECTED_MODAL: {
      EVENT_TYPE: EVENT_TYPES.POP_UP,
      EVENT_PARAMS: {
        product:'rechazar transaccion',
        action: EVENT_ACTION.PAGE_ACTION,
        status: '0',
        step: 'step modal rechazar transaccion',
        auth_method: "rpass",
      },
    },
    STEP_RMODAL_CLICK: {
      EVENT_TYPE: EVENT_TYPES.CLICK_EVENT,
      EVENT_PARAMS: {
        product:'rechazar',
        action: EVENT_ACTION.CLICK_ACTION,
        status: '0',
        step: 'step rechazar transaccion',
        auth_method: "rpass",
      },
    },
    STEP_GOBACK_CLICK: {
      EVENT_TYPE: EVENT_TYPES.CLICK_EVENT,
      EVENT_PARAMS: {
        product:'volver',
        action: EVENT_ACTION.CLICK_ACTION,
        status: '0',
        step: 'step rechazar transaccion',
        auth_method: "rpass",
      },
    },
    STEP_REJECTED_ERROR: {
      EVENT_TYPE: EVENT_TYPES.ERROR,
      EVENT_PARAMS: {
        product:'rechazar transaccion',
        action: EVENT_ACTION.PAGE_ACTION,
        status: '2',
        step: 'step transaccion rechazada',
        step_error: 'step error rechazar transaccion',
        auth_method: "rpass",
      },
    },
    STEP_BIOMETRIC: {
      EVENT_TYPE: EVENT_TYPES.VIRTUAL_EVENT,
      EVENT_PARAMS: {
        product:'autorizar con faceid',
        action: EVENT_ACTION.PAGE_ACTION,
        status: '0',
        step: 'step autorizacion faceid',
        auth_method: "rpass",
      },
    },
    STEP_LIMIT_PIN: {
      EVENT_TYPE: EVENT_TYPES.ERROR,
      EVENT_PARAMS: {
        product:'limite de intentos',
        action: EVENT_ACTION.PAGE_ACTION,
        status: '2',
        step: 'step transaccion rechazada',
        step_error: 'step limite pin',
        auth_method: "rpass",
      },
    },
    STEP_LIMIT_FACTORID: {
      EVENT_TYPE: EVENT_TYPES.ERROR,
      EVENT_PARAMS: {
        product:'limite de intentos',
        action: EVENT_ACTION.PAGE_ACTION,
        status: '2',
        step: 'step transaccion rechazada',
        step_error: 'step limite faceid',
        auth_method: "rpass",
      },
    },
    STEP_ERROR_MODAL: {
      EVENT_TYPE: EVENT_TYPES.ERROR,
      EVENT_PARAMS: {
        product:'no se ha podido completar la autorizacion',
        action: EVENT_ACTION.PAGE_ACTION,
        status: '4',
        step: 'step transaccion rechazada',
        step_error: 'step no se ha podido completar la autorizacion',
        auth_method: "rpass",
      },
    },
  }
};

// END RPASS CONSTANTS

export const HOME_GA4_CONSTANTS = {
  HOME_ACTDATOS_BTN: {
    PATH_DESTINO:'/home/contact-information',
    EVENT: 'btn_click',
    PRODUCT: 'actualiza tus datos',
    ACTION: 'click',
    CATEGORY: 'homepwa',
    SUB_CATEGORY: 'menu_header'
  },
  HOME_INICIO_MENU: {
    PATH_DESTINO:'/home',
    EVENT: 'menu',
    PRODUCT: 'inicio',
    ACTION: 'click',
    CATEGORY: 'homepwa',
    SUB_CATEGORY: 'menu'
  },
  HOME_DATOS_BTN: {
    PATH_DESTINO:'/home/contact-information',
    EVENT: 'menu',
    PRODUCT: 'tus datos',
    ACTION: 'click',
    CATEGORY: 'homepwa',
    SUB_CATEGORY: 'menu'
  },
  HOME_PROMOTIONS_MENU: {
    PATH_DESTINO:'/home/promotions',
    EVENT: 'menu',
    PRODUCT: 'promociones',
    ACTION: 'click',
    CATEGORY: 'homepwa',
    SUB_CATEGORY: 'menu'
  },
  HOME_AHORRO_MENU: {
    PATH_DESTINO:'/home/ahorro',
    EVENT: 'menu',
    PRODUCT: 'ahorros',
    ACTION: 'click',
    CATEGORY: 'homepwa',
    SUB_CATEGORY: 'menu'
  },
  HOME_TRANSFERS_MENU: {
    PATH_DESTINO:'/home/transfers',
    EVENT: 'menu',
    PRODUCT: 'transferencias',
    ACTION: 'click',
    CATEGORY: 'homepwa',
    SUB_CATEGORY: 'menu'
  },
  HOME_PAYMENTS_MENU: {
    PATH_DESTINO:'/home/payments',
    EVENT: 'menu',
    PRODUCT: 'pagos',
    ACTION: 'click',
    CATEGORY: 'homepwa',
    SUB_CATEGORY: 'menu'
  },
  HOME_ONOFCARDS_MENU: {
    PATH_DESTINO:'/home/on-of-cards',
    EVENT: 'menu',
    PRODUCT: 'tarjeta on/off',
    ACTION: 'click',
    CATEGORY: 'homepwa',
    SUB_CATEGORY: 'menu'
  },
  HOME_CREDIT_MENU: {
    PATH_DESTINO:'/home/credit',
    EVENT: 'menu',
    PRODUCT: 'prestamos',
    ACTION: 'click',
    CATEGORY: 'homepwa',
    SUB_CATEGORY: 'menu'
  },
  HOME_RIPLEYPUNTOSGO_MENU: {
    PATH_DESTINO:'/home/ripley-points/ripley-points-dashboard',
    EVENT: 'menu',
    PRODUCT: 'ripleypuntosgo',
    ACTION: 'click',
    CATEGORY: 'homepwa',
    SUB_CATEGORY: 'menu'
  },
  HOME_AJUSTES_MENU: {
    PATH_DESTINO:'/home/options',
    EVENT: 'menu',
    PRODUCT: 'ajustes',
    ACTION: 'click',
    CATEGORY: 'homepwa',
    SUB_CATEGORY: 'menu_footer'
  },
  HOME_REDDEAGENCIAS_MENU: {
    PATH_DESTINO:'/home/ripley-agencies',
    EVENT: 'menu',
    PRODUCT: 'red de agencias',
    ACTION: 'click',
    CATEGORY: 'homepwa',
    SUB_CATEGORY: 'menu_footer'
  },
  HOME_AGENCIASKASNET_MENU: {
    PATH_DESTINO:'www.bancoripley.com.pe/pdf/direcciones-pago-tcripley.pdf',
    EVENT: 'menu',
    PRODUCT: 'agencias kasnet',
    ACTION: 'click',
    CATEGORY: 'homepwa',
    SUB_CATEGORY: 'menu_footer',
  },
  HOME_EMERGENCY_MENU: {
    PATH_DESTINO:'/home/emergency',
    EVENT: 'menu',
    PRODUCT: 'emergencias',
    ACTION: 'click',
    CATEGORY: 'homepwa',
    SUB_CATEGORY: 'menu_footer',
  },
  HOME_PAGE: {
    PATH_DESTINO:'null',
    EVENT: "virtual_page",
    PRODUCT: "home",
    ACTION: "pageview",
    CATEGORY: "homepwa"
  },
  HOME_CLOSE_SESSIONS: {
    PATH_DESTINO:'/login',
    EVENT: "btn_click",
    PRODUCT: "cerrar sesion",
    ACTION: "click",
    CATEGORY: "homepwa",
    SUB_CATEGORY: ["header", "menu"]
  },
  HOME_RIPLEY_POINT: {
    PATH_DESTINO:'/home/ripley-points/ripley-points-dashboard',
    EVENT: "btn_click",
    PRODUCT: "tienes ripley puntos",
    ACTION: "click",
    CATEGORY: "homepwa",
    SUB_CATEGORY: ["header", "footer"]
  },
  HOME_PAYMENT_TC: {
    PATH_DESTINO:'/home/payments/ripley-card/',
    EVENT: "btn_click",
    PRODUCT: "pagar tarjeta",
    ACTION: "click",
    CATEGORY: "inicio",
    SUB_CATEGORY: "tarjeta ripley mastercard"
  },
  HOME_SEE_MOVEMENTS: {
    PATH_DESTINO:'home/credit-card-details ',
    EVENT: "btn_click",
    PRODUCT: "ver movimientos",
    ACTION: "click",
    CATEGORY: "inicio",
    SUB_CATEGORY: "tarjeta ripley mastercard"
  },
  HOME_STATE_COUNT: {
    PATH_DESTINO:'home/credit-card-details/1',
    EVENT: "btn_click",
    PRODUCT: "estado de cuenta",
    ACTION: "click",
    CATEGORY: "inicio",
    SUB_CATEGORY: "tarjeta ripley mastercard"
  },
  HOME_AHORRO_PLUS: {
    PATH_DESTINO:'/home/transfers',
    EVENT: "btn_click",
    PRODUCT: "transferir",
    ACTION: "click",
    CATEGORY: "inicio",
    SUB_CATEGORY: "ahorro plus"
  },
  HOME_SEF_BANNER: {
    PATH_DESTINO:'/home/sef',
    EVENT: "banner",
    PRODUCT: "solicitar",
    ACTION: "click",
    CATEGORY: "homepwa",
    SUB_CATEGORY: "cintillo_superefectivo"
  },
  HOME_EFEX_BANNER: {
    PATH_DESTINO:'/home/efex-auto',
    EVENT: "banner",
    PRODUCT: "solicitar",
    ACTION: "click",
    CATEGORY: "homepwa",
    SUB_CATEGORY: "cintillo_efectivoexpress"
  },
  HOME_SIMULAR_INICIO: {
    EVENT: "btn_click",
    PRODUCT: "simular",
    ACTION: "click",
    CATEGORY: "inicio",
    SUB_CATEGORY: "tarjeta ripley mastercard"
  },
  HOME_NOTIFICATIONS: {
    PATH_DESTINO:'null',
    EVENT: "btn_click",
    PRODUCT: "notificaciones",
    ACTION: "click",
    CATEGORY: "homepwa",
    SUB_CATEGORY: "menu_header"
  },


  HOME_LOANS: {
    product: "prestamos",
    action: "pageview",
    category: "prestamos",
    sub_category: "",
    step: 'step home prestamos'
  },
  HOME_LOANS_BTN: {
    product: "simular",
    action: "click",
    category: "prestamos",
    sub_category: "",
    step: 'step home prestamos'
  },
  HOME_LOANS_START: {
    product: "ingresa el monto",
    action: "pageview",
    category: "prestamos",
    sub_category: '',
    sub_category2: '',
    step: 'step ingresar monto'
  },
  HOME_LOANS_BTN_SIMULAR: {
    product: "simular",
    action: "click",
    category: "prestamos",
    sub_category: "",
    step: 'step ingresar monto',
    ensurance_type : '',
    amount: 0,
    fee: 0
  },
  HOME_LOANS_S2_LOAD: {
    product: "vizualiza tu cuota",
    action: "pageview",
    category: "prestamos",
    sub_category: "",
    step: 'step visualizar cuota',
    ensurance_type: '',
    amount: 0,
    fee: 0
  },
  HOME_LOANS_CANCELAR_MODAL_CANCELAR: {
    product: "cancelar",
    action: "click",
    category: "prestamos",
    sub_category: "",
    step: 'step cancelar proceso',
    ensurance_type: '',
    amount: '',
    fee: ''
  },
  HOME_LOANS_CANCELAR_MODAL_SALIR: {
    product: "si, salir",
    action: "click",
    category: "prestamos",
    sub_category: "",
    step: 'step cancelar proceso',
    ensurance_type: '',
    amount: '',
    fee: ''
  },
  HOME_LOANS_CANCELAR_MODAL_LOAD: {
    product: "prestamos",
    action: "pageview",
    category: "prestamos",
    sub_category: "",
    step: 'step cancelar proceso',
    ensurance_type: '',
    amount: '',
    fee: ''
  },
  HOME_LOANS_S3_LOAD: {
    product: "selecciona cuenta destino",
    action: "pageview",
    category: "prestamos",
    sub_category: "",
    step: 'step seleccion cuenta destino',
    ensurance_type: '',
    amount: 0,
    fee: 0
  },
  HOME_LOANS_ENDFLOW_LOAD: {
    product: "prestamos",
    action: "pageview",
    category: "prestamos",
    sub_category: "",
    sub_category2: "",
    step: 'step exito',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: '',
    auth_method: ''
  },
  HOME_LOANS_ENDFLOW_BTN: {
    product: "",
    action: "click",
    category: "prestamos",
    sub_category: "",
    sub_category2: "",
    step: 'step exito',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: '',
    auth_method: ''
  },
  HOME_LOANS_PURCHASE: {
    currency: "PEN",
    transaction_id: "",
    value: 0,
    items: [
      {
        item_id : "",
        item_name: "",
        currency: "PEN",
        price: 0
      }
    ]
  },
  HOME_LOANS_S3_BTN_CONTINUAR: {
    product: "continuar",
    action: "click",
    category: "prestamos",
    sub_category: "",
    step: 'step seleccion cuenta destino',
    step_part: '',
    ensurance_type: '',
    amount: 0,
    fee: 0,
    type: '',
    destination_bank: ''
  },
  HOME_LOANS_S5_LOAD_RPASS: {
    product: "autorizacion",
    action: "pageview",
    category: "prestamos",
    sub_category: "",
    step: 'step autorizacion',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: '',
    auth_method: ''
  },
  HOME_LOANS_S5_LOAD_SMS: {
    product: "prestamos",
    action: "pageview",
    category: "prestamos",
    sub_category: "",
    step: 'step autorizacion',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: '',
    auth_method : ''
  },
  HOME_LOANS_S5_NOTMYNUMBER_SMS: {
    product: "¿no es tu numero?",
    action: "click",
    category: "prestamos",
    sub_category: "",
    step: 'step autorizacion',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: '',
    auth_method: ''
  },
  HOME_LOANS_S5_BTN_AQUI_SMS: {
    product: "otra opcion",
    action: "click",
    category: "prestamos",
    sub_category: "",
    step: 'step autorizacion',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: '',
    auth_method : ''
  },
  HOME_LOANS_S5_POPUP_NOTMYNUMBER_SMS: {
    product: "ingresa tu numero",
    action: "pageview",
    category: "prestamos",
    sub_category: "",
    step: 'step autorizacion',
    step_part: 'ingresa tu numero',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: '',

  },
  HOME_LOANS_S5_POPUP_BTN_NOTMYNUMBER_SMS: {
    product: "quiero que me contacten",
    action: "click",
    category: "prestamos",
    sub_category: "",
    step: 'step autorizacion',
    step_part: 'ingresa tu numero',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: ''
  },
  HOME_LOANS_S5_POPUP_ERROR: {
    product: "tuvimos un problema con tu sms",
    action: "pageview",
    category: "prestamos",
    sub_category: "",
    step: 'step autorizacion',
    step_part: 'otro metodo de autorizacion',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: '',
    auth_method: ''
  },
  HOME_LOANS_S4_LOAD: {
    product: "prestamos",
    action: "pageview",
    category: "prestamos",
    sub_category: "",
    step: 'step detalle prestamo',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: ''
  },
  HOME_LOANS_S4_S5_BTN_CANCELAR: {
    product: "cancelar",
    action: "click",
    category: "prestamos",
    sub_category: "",
    sub_category2: "",
    step: '',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: '',
    auth_method: ''
  },
  HOME_LOANS_S5_BTN_AUTH: {
    product: "",
    action: "click",
    category: "prestamos",
    sub_category: "",
    sub_category2: "",
    step: 'step seleccion metodo autorizacion',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: '',
    auth_method: ''
  },
  HOME_LOANS_S5_BTN_AUTORIZAR_SMS: {
    product: "autorizar",
    action: "click",
    category: "prestamos",
    sub_category: "",
    sub_category2: "",
    step: 'step autorizacion',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: '',
    auth_method: ''
  },
  HOME_LOANS_S5_LOAD: {
    product: "autorizacion",
    action: "pageview",
    category: "prestamos",
    sub_category: "",
    step: 'step seleccion metodo autorizacion',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: ''
  },
  HOME_LOANS_S4_BTN_CONTINUAR: {
    product: "continuar",
    action: "click",
    category: "prestamos",
    sub_category: "",
    step: 'step detalle prestamo',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: ''
  },
  HOME_LOANS_TRANSFER_TYPE: {
    action: "pageview",
    product: "",
    category: "prestamos",
    sub_category: "",
    step: 'step tipo de transferencia',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: 'otro banco'
  },
  HOME_LOANS_S5_ERROR_POPUP: {
    product: "autorizacion",
    action: "pop up",
    category: "prestamos",
    sub_category: "",
    sub_category2: "",
    step: 'step seleccion metodo autorizacion',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: '',
    destination_bank: '',
    step_error: ''
  },
  HOME_LOANS_BTN_CONTINUAR_CUOTA: {
    product: "continuar",
    action: "click",
    category: "prestamos",
    sub_category: "",
    step: 'step visualizar cuota',
    ensurance_type: '',
    amount: 0,
    fee: 0
  },
  HOME_LOANS_RADIOBUTTON_CCI: {
    product: "agregar cuenta de otro banco",
    action: "click",
    category: "prestamos",
    sub_category: "",
    step: 'step seleccion cuenta destino',
    step_part: 'agregar cuenta otro banco',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: GENERIC_TAG_OTRO_BANCO
  },
  HOME_LOANS_POPUP_CCI_ABRIRCUENTA_LOAD: {
    product: "",
    action: "pageview",
    category: "prestamos",
    sub_category: "",
    step: '',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: ''
  },
  HOME_LOANS_POPUP_BTN_CCI_AGREGARCUENTA: {
    product: "",
    action: "click",
    category: "prestamos",
    sub_category: "",
    step: 'step ingresar cuenta otro banco',
    ensurance_type: '',
    amount: 0,
    fee: '',
    type: ''
  },
  HOME_LOANS_BTN_CANCELAR_CUOTA: {
    product: "cancelar",
    action: "click",
    category: "prestamos",
    sub_category: "",
    step: 'step visualizar cuota',
    step_part : '',
    ensurance_type: '',
    amount: 0,
    fee: 0,
    type: '',
    destination_bank: ''
  },
  LOANS_SEMIAUTOMATICO_SEF_POPUP: {
    product: "",
    action: "pageview",
    category: "prestamos",
    sub_category: "sef",
    sub_category2: "",
    class: '',
    step: '',
    amount: 0,
    fee: 0,
  },
  LOANS_SEMIAUTOMATICO_SEF_BTN: {
    product: "",
    action: "click",
    category: "prestamos",
    sub_category: "sef",
    sub_category2: "",
    class: '',
    step: '',
    amount: 0,
    fee: 0,
  },
  LOANS_SEMIAUTOMATICO_EFEX: {
    product: "",
    action: "pageview",
    category: "prestamos",
    sub_category: "efex",
    class: 'flujo semiautomatico',
    step: '',
    amount: 0,
    fee: 0,
    destination_bank: '',
    auth_method: ''
  },
  LOANS_SEMIAUTOMATICO_BTN_EFEX: {
    product: "",
    action: "click",
    category: "prestamos",
    sub_category: "efex",
    class: 'flujo semiautomatico',
    step: '',
    amount: 0,
    fee: 0,
    destination_bank: '',
    auth_method: ''
  },
  HOME_LOANS_POPUPS: {
    product: "",
    action: "click",
    category: "prestamos",
    sub_category: "",
    step: 'step home popups'
  }
}

export const LOGIN_GA4_CONSTANTS = {
  LOGIN_PAGE: {
    PATH_DESTINO:'null',
    EVENT: "virtual_page",
    PRODUCT: "login",
    CATEGORY: "login"
  },
  LOGIN_INGRESAR: {
    PATH_DESTINO:'/home',
    EVENT: "btn_click",
    PRODUCT: "ingresar",
    ACTION: "click",
    CATEGORY: "login",
    SUB_CATEGORY: "login",
  },
  LOGIN_NO_SOY: {
    PATH_DESTINO:'/login',
    EVENT: "btn_click",
    PRODUCT: "no soy",
    ACTION: "click",
    CATEGORY: "login",
    SUB_CATEGORY: "login",
  },
  LOGIN_CREAR_RECUPERAR_CLAVE: {
    PATH_DESTINO:'/enrollment',
    EVENT: "btn_click",
    PRODUCT: "crear o recuperar clave",
    ACTION: "click",
    CATEGORY: "login",
    SUB_CATEGORY: "login",
  }
}

export const ENROLL_GA4_CONSTANTS ={
  CREATE_PAGE: {
     EVENT: 'virtual_page',
     PRODUCT: 'crear o recuperar clave',
     ACTION: 'pageview',
     CATEGORY: 'crear recuperar clave',
     STEP: 'Step inicio crear recuperar clave',
  },

  CREATE_INPUT: {
   EVENT: 'input',
   PRODUCT: 'Input ingresa número de documento"',
   ACTION: 'input',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step numero de DNI crear recuperar clave',
 },

 CREATE_BOTTON_CONT: {
   EVENT: 'btn_click',
   PRODUCT: 'continuar',
   ACTION: 'click',
   CATEGORY: 'Crear o recuperar clave',
   STEP: 'step boton continuar crear o recuperar clave',
 },

 CREATE_BOTTON_CANC: {
   EVENT: 'btn_click',
   PRODUCT: 'cancelar',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step boton cancelar',
 },

 CREATE_SELECC_PAGE: {
   EVENT: 'virtual_page',
   PRODUCT: 'recuperar clave',
   ACTION: 'pageview',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step selecciona metodo de autorización',
 },

 CREATE_BOTTON_PINPAN: {
   EVENT: 'btn_click',
   PRODUCT: 'número de tarjeta y pin de 4 dígitos ',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step selecciona metodo de pin+pan',
 },


 CREATE_BOTTON_SMS: {
   EVENT: 'btn_click',
   PRODUCT: 'SMS',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step selecciona metodo de sms',
 },

 CREATE_BOTTON_BIOMETRIA: {
   EVENT: 'btn_click',
   PRODUCT: 'reconocimiento facial',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step selecciona metodo de biometria',
 },

 CREATE_BOTTON_UNIFICADO: {
   EVENT: 'btn_click',
   PRODUCT: ' SMS + número de tarjeta y pin de 4 dígitos',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step selecciona metodo de sms + pinpan',
 },

 // Taggueos en Pin Pan

 CREATE_PINPAN_PAGE: {
   EVENT: 'virtual_page',
   PRODUCT: 'pantalla pin + pan',
   ACTION: 'pageview',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step pantalla pin + pan',
 },

 CREATE_INPUT_PIN: {
   EVENT: 'input',
   PRODUCT: 'Input ingresa pin de 4 dígitos',
   ACTION: 'input',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step ingresa el pin',
 },

 CREATE_INPUT_PAN: {
   EVENT: 'input',
   PRODUCT: 'Input ingresa número de tarjeta',
   ACTION: 'input',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step ingresa el pan',
 },

 CREATE_BOTTON_CONT_PINPAN: {
   EVENT: 'btn_click',
   PRODUCT: 'continuar',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step boton continuar pinpan',
 },

 CREATE_BOTTON_CANC_PINPAN: {
   EVENT: 'btn_click',
   PRODUCT: 'cancelar',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step boton cancelar pinpan',
 },

 // taggueos SMS


 CREATE_SMS_PAGE: {
   EVENT: 'virtual_page',
   PRODUCT: 'pantalla sms',
   ACTION: 'pageview',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step pantalla sms',
 },

 CREATE_INPUT_SMS: {
   EVENT: 'input',
   PRODUCT: 'input ingresa sms',
   ACTION: 'input',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step ingresa sms',
 },

 CREATE_BOTTON_CONT_SMS: {
   EVENT: 'btn_click',
   PRODUCT: 'autorizar',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step boton autorizar sms',
 },

 CREATE_BOTTON_RESEND_SMS: {
   EVENT: 'btn_click',
   PRODUCT: 'reenviar código',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step boton reenviar sms',
 },

 CREATE_BOTTON_NONUMB_SMS: {
   EVENT: 'btn_click',
   PRODUCT: '¿no es tu número de telefono?',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step boton no es tu numero sms',
 },


 CREATE_BOTTON_METHOD_SMS: {
   EVENT: 'btn_click',
   PRODUCT: 'otro método de autorización',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step boton otro método sms',
 },

 //taggueos clave de 6 digitos
 CREATE_6DIGITS_PAGE: {
   EVENT: 'virtual_page',
   PRODUCT: 'clave de 6 digitos',
   ACTION: 'pageview',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step crea clave digital',
 },

 CREATE_POLITICS_6DIGITS: {
   EVENT: 'checkbox',
   PRODUCT: 'checkbox politicas de privacidad',
   ACTION: 'checkbox',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step checkbox politicas',
 },

 CREATE_TERMS_6DIGITS: {
   EVENT: 'checkbox',
   PRODUCT: 'checkbox términos y condiciones',
   ACTION: 'checkbox',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step checkbox términos',
 },

 CREATE_INPUT_EMAIL_6DIGITS: {
   EVENT: 'input',
   PRODUCT: 'input ingresa email',
   ACTION: 'input',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step ingresa email',
 },

 CREATE_BUTTON_CONFIRM_6DIGITS: {
   EVENT: 'btn_click',
   PRODUCT: 'Crear o recuperar clave',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step boton',
 },

 CREATE_BUTTON_CANCEL_6DIGITS: {
   EVENT: 'btn_click',
   PRODUCT: 'cancelar',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step boton cancelar',
 },

 CREATE_SUCCESS_PAGE: {
   EVENT: 'virtual_page',
   PRODUCT: 'pantalla de éxito',
   ACTION: 'pageview',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step pantalla de exito',
 },

 CREATE_SUCCESS_BOTTON: {
  PATH_DESTINO:'/login',
   EVENT: 'btn_click',
   PRODUCT: 'ingresar',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step boton ingresar pantalla de exito',
 },


 //POP UPS
 CREATE_POP_USER_SERVER: {
  EVENT: 'virtual_page',
  PRODUCT: 'los sentimos',
  ACTION: 'pageview',
  CATEGORY: 'crear recuperar clave',
  STEP: 'step pop up no se puede realizar tu solicitud',

},
CREATE_POP_SMS_BLOCK: {
  EVENT: 'virtual_page',
  PRODUCT: 'lo sentimos',
  ACTION: 'pageview',
  CATEGORY: 'crear recuperar clave',
  STEP: 'step pop up has sobrepasado el limite de intentos',
},

 CREATE_POP_SMS_TIME: {
   EVENT: 'virtual_page',
   PRODUCT: 'sigues ahí',
   ACTION: 'pageview',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step pop up sigues ahí',
 },

 CREATE_POP_SMS_TIME_CONT: {
   EVENT: 'btn_click',
   PRODUCT: 'continuar con otp sms',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step boton continuar con otp sms',
 },

 CREATE_POP_SMS_TIME_METHOD: {
   EVENT: 'btn_click',
   PRODUCT: 'intentar con otro metodo',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step boton intentar con otro metodo',
 },

 CREATE_POP_SMS_TIME_HERE: {
  EVENT: 'virtual_page',
  PRODUCT: 'sigues ahi',
  ACTION: 'pageview',
  CATEGORY: 'crear recuperar clave',
  STEP: 'step pop up se agoto el tiempo de espera',
},

 //pop up no es tu numero de telefono
 CREATE_POP_NO_NUMBER: {
   EVENT: 'virtual_page',
   PRODUCT: '¿tus datos no son correctos?',
   ACTION: 'pageview',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step pop up ¿tus datos no son correctos?',
 },


CREATE_POP_NO_USER: {
  EVENT: 'virtual_page',
  PRODUCT: 'aun no eres parte de banco ripley',
  ACTION: 'pageview',
  CATEGORY: 'crear recuperar clave',
  STEP: 'ingresar documento',
  STEP_ERROR:'error-datos incorrectos' ,
},

 CREATE_POP_NO_NUMBER_METHOD: {
   EVENT: 'btn_click',
   PRODUCT: 'intentar con otro metodo',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step boton intentar con otro metodo',
 },

 CANCEL_POP_RETURN:{
   EVENT: 'virtual_page',
   PRODUCT: 'Perderas el progreso que llevas hasta ahora',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step pop up Perderas el progreso que llevas hasta ahora ',
 },

 CANCEL_POP_YES:{
  PATH_DESTINO:'/login',
   EVENT: 'btn_click',
   PRODUCT: 'sí, estoy seguro',
   ACTION: 'click',
   CATEGORY: 'Crear recuperar clave',
   STEP: 'step boton si, estoy seguro',
 },

 CREATE_POP_PIN_INCORRECT: {
  EVENT: 'virtual_page',
  PRODUCT: 'los sentimos',
  ACTION: 'pageview',
  CATEGORY: 'crear recuperar clave',
  STEP: 'step pop up los datos ingresados no son correctos',
  STEP_ERROR:'error-datos incorrectos' ,
},

CREATE_POP_PIN_TIME_HERE: {
  EVENT: 'virtual_page',
  PRODUCT: 'sigues ahi',
  ACTION: 'pageview',
  CATEGORY: 'crear recuperar clave',
  STEP: 'step pop up se agoto el tiempo de espera',
  STEP_ERROR:'error-tiempo caducado' ,

},

CREATE_POP_PIN_BLOCK: {
  EVENT: 'virtual_page',
  PRODUCT: 'los sentimos',
  ACTION: 'pageview',
  CATEGORY: 'crear recuperar clave',
  STEP: 'step pop up acceso bloqueado',
  STEP_ERROR:'error-acceso bloqueado' ,
},

CREATE_POP_PIN_SERVER: {
  EVENT: 'virtual_page',
  PRODUCT: 'los sentimos',
  ACTION: 'pageview',
  CATEGORY: 'crear recuperar clave',
  STEP: 'step pop up no se puede realizar tu solicitud',
  STEP_ERROR: 'error-servicio caido' ,
},

 }
export const FOOTER_GA4_CONSTANTS = {
  FOOTER_AGENCIA: {
    PATH_DESTINO:'/ripley-agencies',
    EVENT: "btn_click",
    PRODUCT: "agencia",
    ACTION: "click",
    CATEGORY: "login",
    SUB_CATEGORY: "footer",
  },
  FOOTER_RPASS: {
    PATH_DESTINO:'/intro-rpass',
    EVENT: "btn_click",
    PRODUCT: "r pass",
    ACTION: "click",
    CATEGORY: "login",
    SUB_CATEGORY: "footer",
  },
  FOOTER_EMERGENCIA: {
    PATH_DESTINO:'/emergency',
    EVENT: "btn_click",
    PRODUCT: "emergencia",
    ACTION: "click",
    CATEGORY: "login",
    SUB_CATEGORY: "footer",
  }
}

export const ERROR_GA4_CONSTANTS = {
  DISBURMENT_ERROR: {
    EVENT: "functional_error",
    TITLE: '',
    MESSAGE: '',
    ERROR: '',
  },
  GENERIC_MODAL: {
    EVENT: "functional_error",
    TITLE: '',
    MESSAGE: '',
    ERROR: '',
  }
}