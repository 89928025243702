export const DOC_TYPE_STORAGE_KEY = 'documentType';

export const DOC_NUMBER_STORAGE_KEY = 'documentNumber';

export const REMEMBER_TOOLTIP_TEXT = 'Guardaremos tu tipo y número de documento para la siguiente sesión.';

export const INACTIVITY_CLOSED_SESSION_STORAGE_KEY = 'inactivityClosedSession';

export const SAVED_IDENTIFIER_KEY = 'savedIdentifier';

export const USER_NAME_KEY = 'userName';

export const CUSTOMER_ID = 'customerId';

export const CHANNEL_FUNCTIONALITY = 'channelFunctionality';

export const USER_PERMISSION = 'userPermissions';