import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-lead-exist-modal',
  templateUrl: './lead-exist-modal.component.html',
  styleUrls: ['./lead-exist-modal.component.scss'],
})
export class LeadExistModalComponent implements OnInit {

  @Input() productName: string;

  constructor(private modalController: ModalController) { }

  dismissModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {}

}
