
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/remote-config';
import 'firebase/analytics';
import 'firebase/auth';

import { environment } from '@environments/environment';
import { IQuery } from '@common/interfaces/query-firebase.interface';
import { Query } from '@firebase/firestore-types';
import { REMOTE_CONFIG_SETTINGS, REMOTE_CONFIG_MAIN_KEY } from '@common/constants/misc.constants';
import { IRemoteConfig } from '@common/interfaces/remote-config.interface';
import {
  IPWANotificationData,
  IAppNotificationData,
  INotificationInformation,
  INotificationCategory,
  IUserNotificationConfig,
  INotificationConfigurationElementEdition,
  INotificationTapped,
  IAppNotificationDataRPass,
} from '@common/interfaces/notifications.interface';
import { IServiceConfig } from '@common/interfaces/config.interface';
import { IRegisterLatamDetail } from '@common/interfaces/latamMiles.interface';
import { IMaintenanceModalConfig } from '@common/interfaces/firebase-maintenance-modal-config.interface';
import { IDataEmergency } from '@common/interfaces/IDataEmergency';
import { CONFIG_COLLECTION, MAINTENANCE_MODAL_DEFAULT_VALUE, NOTIFICATION_TOKENS_DOCUMENT, RPASS_CONFIG_DOCUMENT } from '@common/constants/firebase.constants';
import {
  RPassPolitic,
  RPassFAQ,
  RPassEnrollStep,
  RPassUnrollAlert,
  RPassInitiative,
  RPassEnrollQR,
  RpassConfig,
  InvalidRpassConfiguration,
} from '@common/models/digital-auth';
import { IRPassDataConfig } from '@common/interfaces/firebase-rpass-config.interface';
import { Storage } from '@ionic/storage';
import { IDataSwitchCampaing } from '@common/interfaces/IDataSwitchCampaing';
import { DynamicObject, PropsModalError } from '@common/models/dynamic-object';
import { tryCatch } from 'rxjs/internal-compatibility';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  firestore: firebase.firestore.Firestore;
  remoteConfig: firebase.remoteConfig.RemoteConfig;
  firebaseAnalytics: firebase.analytics.Analytics;
  firebaseAuth: firebase.auth.Auth;
  simulationReference: string;
  stepReference: string;

  constructor(private storage: Storage) {
    this.initializeFirebase();
  }

  public getFirestoreInstance() {
    return this.firestore;
  }

  initializeFirebase() {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.FIREBASE_CONFIG);
    }
    this.firestore = firebase.firestore();
    this.remoteConfig = firebase.remoteConfig();
    this.firebaseAnalytics = firebase.analytics();
    this.firebaseAuth = firebase.auth();
  }

  async queryInitConfig() {
    // Catch the firebase error due to bad initialization (oldest version)
    try {
      const docs = await this.firestore.collection('appData').doc('initConfig').get();
      // Obteniendo los datos del initConfig
      const data = docs.data();
      console.log("DATAA ", data);
      this.storage.set('anniversaryMsg', data.anniversaryMsg); // Configuración anniversaryMsg
      this.storage.set('errorMessages', data.errorMessages); // Mensajes de error */
    }
    catch(e) {
      console.log(e);
    }
  }

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  getCollection(collection: string): firebase.firestore.CollectionReference {
    return this.firestore.collection(collection);
  }

  getAllDocuments(collection: firebase.firestore.CollectionReference): Promise<firebase.firestore.QuerySnapshot> {
    return collection.get();
  }

  getDocument(collection: firebase.firestore.CollectionReference, doc: string): Promise<firebase.firestore.DocumentSnapshot> {
    return collection.doc(doc).get();
  }

  public getFirebaseCollection(collection: string): firebase.firestore.CollectionReference {
    return this.firestore.collection(collection);
  }

  public async getErrorTransferMessageFromFirebase(docSnapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    ,code:string, flow: string, innerFlow?:string): Promise<DynamicObject | PropsModalError> {
        let objectFromFirebase: DynamicObject = {};   
        console.log(docSnapshot);
      try {
        let data = docSnapshot[flow][innerFlow];    
        if (data){
          data = data[code];
          if(data && data['interface']){
              console.log(data['interface']);
              data['interface'].forEach((value:string) =>
              objectFromFirebase[value] = data[value]);
              return objectFromFirebase
          } else {
              let generalErrorModal: PropsModalError= {
              title:'Ocurrió un error',
              message: data['message'],
              primaryButtonText: 'Entendido',
              icon: 'i-alert-purple'
            };
           return generalErrorModal;
          }
        }
      } catch (error) {//Si no encuentra el código
        return null;
      }
  }

  public async getAlertMessageFromFirebase(document:string ): Promise<DynamicObject> {
    let objectFromFirebase: DynamicObject = {};
    const docRef =  this.firestore.collection('alertMessages').doc(document);
    const docSnapshot = await docRef.get();
    if (docSnapshot.exists) {
      const data = docSnapshot.data();
      data['interface'].forEach((value:string) =>
      objectFromFirebase[value] = data[value]);
      return objectFromFirebase;
    } else {
      console.log('document not found')
      return null;
    }
  }

  async getRowDocument(collection: string, id: string) {
    return (await this.firestore.collection(collection).doc(id).get()).data();
  }

  async initializeRemoteConfig(): Promise<IRemoteConfig> {
    this.remoteConfig.settings = REMOTE_CONFIG_SETTINGS;
    await this.remoteConfig.fetchAndActivate();
    const realConfig = this.remoteConfig.getAll();
    return JSON.parse(realConfig[REMOTE_CONFIG_MAIN_KEY].asString());
  }

  getDocumentsQuery(
    collection: firebase.firestore.CollectionReference,
    argumentsQuery: IQuery[]
  ): Promise<firebase.firestore.QuerySnapshot> {
    let query: Query = collection;
    for (const argument of argumentsQuery) {
      query = query.where(argument.param, argument.queryType, argument.value);
    }
    return query.get();
  }

  public fetchNewUserNotifications(documentType: string, documentNumber: string) {
    const notificationsRef = this.firestore.collection('pushTray')
      .where('documentType', '==', documentType)
      .where('documentNumber', '==', documentNumber)
      .where('isDeleted', '==', false)
      .where('expirationDate', '>', new Date());
    return notificationsRef;
  }

  public async addNotificationToken(
    dType: string,
    dNumber: string,
    notificationData: IPWANotificationData | IAppNotificationData | IAppNotificationDataRPass,
    platformKey: string,
    extraPlatformKey: string,
  ): Promise<void> {
    const notificationDocument = this.firestore.collection(NOTIFICATION_TOKENS_DOCUMENT).doc(`${dType}-${dNumber}`);
    const documentSnapshot = await notificationDocument.get();
    if ((notificationData as IAppNotificationDataRPass).uuidDevice) {
      (notificationData as IAppNotificationDataRPass).enrolledAt = this.getCurrentTimestamp();
      return await notificationDocument.update({
        [platformKey]: notificationData,
        updatedAt: this.getCurrentTimestamp()
      });
    }

    if (documentSnapshot.exists) {
      return notificationDocument.update({
        [platformKey]: [notificationData],
        updatedAt: this.getCurrentTimestamp()
      });
    }
    const notificationEntry = {
      documentType: dType,
      documentNumber: dNumber,
      updatedAt: this.getCurrentTimestamp(),
      createdAt: this.getCurrentTimestamp(),
      [platformKey]: [notificationData],
      [extraPlatformKey]: []
    };
    return notificationDocument.set(notificationEntry);
  }

  public async getNotificationsToken(dType: string, dNumber: string) {
    const notificationDocument = this.firestore.collection(NOTIFICATION_TOKENS_DOCUMENT).doc(`${dType}-${dNumber}`);
    const documentSnapshot = await notificationDocument.get();
    return documentSnapshot.data();
  }

  public async removeNotificationToken(
    dType: string,
    dNumber: string,
    token: string,
    platformKey: string,
  ): Promise<void> {
    const notificationDocument = this.firestore.collection(NOTIFICATION_TOKENS_DOCUMENT).doc(`${dType}-${dNumber}`);
    const searchedUser = await notificationDocument.get();
    const userTokens = await searchedUser.data()[platformKey];
    const deviceToken = userTokens.filter(
      (tokenData: IAppNotificationData | IPWANotificationData) => tokenData.fcmToken === token
    );
    if (deviceToken && deviceToken.length > 0) {
      return notificationDocument.update({
        updatedAt: this.getCurrentTimestamp(),
        [platformKey]: firebase.firestore.FieldValue.arrayRemove(deviceToken[0])
      });
    }
  }

  public markNotificationAsRead(notification: INotificationInformation) {
    this.firestore.collection('pushTray').doc(notification.inboxMessageId).update({ isRead: true });
  }

  public deleteNotification(notification: INotificationInformation) {
    this.firestore.collection('pushTray').doc(notification.inboxMessageId).update({ isDeleted: true });
  }

  public async getNotificationTypes(): Promise<INotificationCategory[]> {
    const res = await this.firestore.collection('pushTransactionalConfig').get()
      .then((snapshot) => snapshot.docs.map(doc => doc.data()));
    return res as INotificationCategory[];
  }

  public async getUserNotificationConfig(dType: string, dNumber: string): Promise<IUserNotificationConfig> {
    const res = await this.firestore.collection('pushUserConfig').doc(`${dType}-${dNumber}`).get();
    return await res.data() as IUserNotificationConfig;
  }

  public async updateUserNotificationConfig(
    dType: string,
    dNumber: string,
    notifications: INotificationConfigurationElementEdition[]
  ): Promise<void> {
    const notificationDocument = this.firestore.collection('pushUserConfig').doc(`${dType}-${dNumber}`);
    const documentSnapshot = await notificationDocument.get();
    const notificationEntry = {
      updatedAt: this.getCurrentTimestamp(),
      configurations: []
    };
    if (documentSnapshot.exists) {
      notificationEntry.configurations = documentSnapshot.data().configurations;
      for (const notif of notifications) {
        const categoryIndex = notificationEntry.configurations.findIndex(
          (el) => el.enabled === !notif.status && el.typeId === notif.typeId);
        if (categoryIndex !== -1) {
          notificationEntry.configurations[categoryIndex] = {
            enabled: notif.status,
            typeId: notif.typeId
          };
        } else {
          notificationEntry.configurations.push({
            enabled: notif.status,
            typeId: notif.typeId
          });
        }
      }
      return notificationDocument.update(notificationEntry);
    }
    notificationEntry['documentType'] = dType;
    notificationEntry['documentNumber'] = dNumber;
    notificationEntry['createdAt'] = this.getCurrentTimestamp();
    for (const notif of notifications) {
      notificationEntry['configurations'].push({
        enabled: notif.status,
        typeId: notif.typeId
      });
    }
    return notificationDocument.set(notificationEntry);
  }

  public getCurrentTimestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  public logEvent(tag: string, data: any = {}) {
    data.app_version = document.querySelector('meta[name="version"]')['content'];
    this.firebaseAnalytics.logEvent(tag, data);
  }

  public getRipleyPointsBenefits(doc: string) {
    return this.firestore.collection('PWA-benefits-ripley-points-go').doc(doc).get();
  }

  public async getData(data: string) {
    const appData = await this.firestore.doc(`appData/${data}`).get();
    return appData.data();
  }

  public async getWelcomePackPromotions(promotions: [any]): Promise<any[]> {
    const promotionList = [];
    await Promise.all(promotions
      .sort((a, b) => a.priority - b.priority)
      .map(async (promotion) => {
        const ref = await this.firestore.collection('promotions').doc(promotion.benefitId).get();
        promotionList.push({ ...ref.data(), id: promotion.benefitId });
      }));
    return promotionList;
  }

  public async setPromotionDni(dni: string, promotionId: string): Promise<void> {
    const promotionsDnisRef = this.firestore.collection('promotionDnis');
    const promotionDni = promotionsDnisRef.where('userId', '==', dni).where('promotionId', '==', promotionId);
    await promotionDni.get().then((snap) => {
      if (snap.empty) {
        const data = {
          userId: dni,
          promotionId,
        };
        promotionsDnisRef.add(data);
      }
    });
  }

  public addToFirebaseCollection(collection: string, params): Promise<firebase.firestore.DocumentReference> {
    return this.firestore.collection(collection).add(params);
  }

  public async checkPromotionSubscription(userId: string, promotionId: string): Promise<boolean> {
    const subscriptions = this.getFirebaseCollection('promotionSubscriptions');
    const userSubscription = await subscriptions
      .where('userId', '==', userId)
      .where('promotionId', '==', promotionId)
      .get();
    return !userSubscription.empty;
  }

  public async addPromotionSubscription(userId: string, promotionId: string): Promise<boolean> {
    const exists = await this.checkPromotionSubscription(userId, promotionId);
    if (exists) { return false; }
    const subscriptions = this.getFirebaseCollection('promotionSubscriptions');
    await subscriptions.add({
      userId,
      promotionId,
      subscribedAt: new Date(),
    });
    return true;
  }

  public async signInWithCustomToken(jwt: string) {
    return this.firebaseAuth.signInWithCustomToken(jwt);
  }

  public async getLatamConfig(): Promise<IRegisterLatamDetail> {
    const latamDetails = await this.firestore.doc('config/latamConfig').get();
    return latamDetails.data() as IRegisterLatamDetail;
  }
  public async getUsersSefEfex(data: string) {
    const usersSefEfex = await this.firestore.doc(`sefEfexUsersOnOffV2/${data}`).get();
    return usersSefEfex.data();
  }

  public async addSefEfexServiceResponse(data: any, collection: string) {
    const sefEfexCollection = this.firestore.collection(collection);
    this.simulationReference = (await sefEfexCollection.add(data)).id;
  }

  public async confirmSimulation(step: string, data: any, collection: string) {
    const simulationDoc = this.firestore.doc(collection + `/${this.simulationReference}`);
    simulationDoc.update({ confirmed: true, selected: data });
    this.registerStepSefEfex('confirmed ' + step, { simReference: this.simulationReference });
  }

  public async registerStepSefEfex(stepName: string, data?: any, collection: string = 'StepList') {

    if (!this.stepReference) {
      const sefEfexCollection = this.firestore.collection(collection);
      const updateBody = {
        ...data,
      };
      updateBody[stepName] = {
        ...data,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      const stepReference = (await sefEfexCollection.add(updateBody)).id;
      this.stepReference = stepReference;
      return;
    }

    const docRef = this.firestore.doc(`${collection}/${this.stepReference}`);
    const time = Date.now();
    const timedInstance = {
      ...data,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    const updateBody = {
      [stepName + '.' + time]: timedInstance,
    };
    if (data && data.origen) {
      updateBody['origen'] = data.origen;
    }
    docRef.update(updateBody);
  }

  public async getConfig(data: string): Promise<IServiceConfig> {
    let config;
    await this.firestore.doc(`config/${data}`).get().then((result) => {
      config = result.data() as IServiceConfig;
    }).catch((err) => {
      return;
    });
    return config;
  }

  public async getNotificationInformation(notification: INotificationTapped): Promise<INotificationInformation> {
    const readNotification = await this.firestore.collection('pushTray').doc(notification.trayId).get();
    return readNotification.data() as INotificationInformation;
  }

  public markNotificationAsReadById(notificationId: string) {
    this.firestore.collection('pushTray').doc(notificationId).update({ isRead: true });
  }

  public async getLastTwoTransactionByUserId(userId: string) {
    return await this.getFirebaseCollection('productTransactions')
      .orderBy('createdAt', 'desc')
      .where('userId', '==', userId)
      .limit(2)
      .get();
  }

  public async getProducts(active: string, categoryId: string) {
    return await this.getFirebaseCollection('products')
      .where(active, '==', true)
      .where('category', '==', categoryId)
      .orderBy('points')
      .get();
  }

  public async getTotalTransactions(sku: string, userId: string, db: any) {
    const today = new Date();
    return (await db.collection('productTransactions')
      .where('sku', '==', sku)
      .where('userId', '==', userId)
      .where('status', '==', 'completed')
      .where('yearRedeem', '==', today.getFullYear()).get()).docs.length;
  }

  private sameDay(d1: Date, d2: Date): boolean {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  public async canRedeemProduct(userId: string, category: string, sku: string): Promise<boolean> {
    const products = await this.firestore.collection('productTransactions')
      .where('userId', '==', userId)
      .where('category', '==', category)
      .where('sku', '==', sku)
      .where('status', '==', 'completed')
      .get();

    return !products.docs.some((transaction) => this.sameDay((transaction.data().updatedAt).toDate(), new Date()));
  }

  public async canRedeemGiftcard(sku: string, userId: string): Promise<boolean> {
    const db = this.getFirestoreInstance();

    const product = db.doc(`products/${sku}`).get();
    const linkedProducts = (await db.collection('linkedProducts').where('products', 'array-contains', sku).get()).docs;
    let totalTransactions = 0;
    if (linkedProducts.length > 0) {
      for (const productSku of linkedProducts[0].data().products) {
        totalTransactions += await this.getTotalTransactions(productSku, userId, db);
      }
    } else {
      totalTransactions = await this.getTotalTransactions(sku, userId, db);
    }
    return product.then((snapshot) => {
      return totalTransactions < snapshot.data().limit;
    });
  }

  async getMaintenanceModalConfig(): Promise<IMaintenanceModalConfig> {
    try {
      const modalConfig = await this.firestore.collection('appData').doc('maintenanceModalConfig').get();
      return modalConfig.data() as IMaintenanceModalConfig;
    } catch (error) {
      return MAINTENANCE_MODAL_DEFAULT_VALUE as IMaintenanceModalConfig;
    }
  }

  async getRPassDataConfig(): Promise<IRPassDataConfig> {
    const rpassConfig = await this.getDocument(this.getCollection(CONFIG_COLLECTION), RPASS_CONFIG_DOCUMENT);
    return rpassConfig.data() as IRPassDataConfig;
  }

  async getDataEmergency(): Promise<IDataEmergency> {
    const dataEmergency = await this.firestore.collection('appData').doc('data-emergency').get();
    return dataEmergency.data() as IDataEmergency;
  }

  public updateProductStock(id: string, stock: number) {
    this.firestore.collection('products').doc(id).update({ stock: stock });
  }

   //#region "R Pass"
  //#region "CurrentTokenRpass"
  public async getCurrentTokenRpass({ dType, dNumber }): Promise<IAppNotificationDataRPass> {
    const notificationCollection = this.getCollection(NOTIFICATION_TOKENS_DOCUMENT);
    const notificationDocument = await this.getDocument(notificationCollection, `${dType}-${dNumber}`);
    const notificationToken = notificationDocument.data();
    if (!notificationToken.currentTokenRpass) return null;
    const appNotificationDataRpass: IAppNotificationDataRPass = {
      fcmToken: notificationToken.currentTokenRpass.fcmToken,
      id: notificationToken.currentTokenRpass.id,
      manufacturer: notificationToken.currentTokenRpass.manufacturer,
      model: notificationToken.currentTokenRpass.model,
      uuidDevice: notificationToken.currentTokenRpass.uuidDevice,
      isAuthorizer: notificationToken.currentTokenRpass.isAuthorizer,
      userAgent: notificationToken.currentTokenRpass.userAgent,
      enrolledAt: notificationToken.currentTokenRpass.enrolledAt,
    };
    return appNotificationDataRpass;
  }

  public async deleteCurrentTokenRpass({ dType, dNumber }): Promise<void> {
    const notificationDocument = this.getCollection(NOTIFICATION_TOKENS_DOCUMENT).doc(`${dType}-${dNumber}`);
    const searchedUser = await notificationDocument.get();
    const notificationToken = searchedUser.data();
    if (!notificationToken) return;
    await notificationDocument.update({
      currentTokenRpass: null,
      updatedAt: this.getCurrentTimestamp()
    });
  }

  /**
  * Create/Update currentTokenRpass
  * @param dType userType - DocumentType
  * @param dNumber userId - DNI/DocumentNumber
  * @param appNotificationDataRPass curreentTokenRpass
  * @param platformKey platform key -> debe apuntar a FIRESTORE_CURRENT_TOKEN_RPASS
  * @param extraPlatformKey extra platform key -> debe apuntar a FIRESTORE_PWA_TOKEN_KEY
  */
  public async createCurrentTokenRpass(
    dType: string,
    dNumber: string,
    appNotificationDataRPass: IAppNotificationDataRPass,
    platformKey: string,
    extraPlatformKey: string
  ): Promise<void> {
    this.addNotificationToken(dType, dNumber, appNotificationDataRPass, platformKey, extraPlatformKey);
  }
  //#endregion

  //#region "R Pass Config"
  public async getRpassConfig(): Promise<RpassConfig> {
    const configRPass = await this.getRPassDataConfig();
    const faqList = await this.getFaqRpass(configRPass);
    const lastPolitic = await this.getRpassPolitics(configRPass);
    const stepsList = await this.getStepsToActivateRpass(configRPass);
    const unrollAlert = await this.getUnrollRpassAlert(configRPass);
    const enrollQR = await this.getUrlQrRpass(configRPass);
    const initiatives = await this.getInitiativesRpass(configRPass);
    const invalidRpassConfiguration = await this.getInvalidRpassConfiguration(configRPass);
    const rpassConfig = new RpassConfig(
      faqList,
      stepsList,
      lastPolitic,
      unrollAlert,
      enrollQR,
      initiatives,
      invalidRpassConfiguration,
    );
    return rpassConfig;
  }

  public async getFaqRpass(config?: IRPassDataConfig): Promise<RPassFAQ[]> {
    const configRpass = config || await this.getRPassDataConfig();
    const faqList: RPassFAQ[] = configRpass.faq.map((faq: RPassFAQ) => (new RPassFAQ(
      faq.order,
      faq.question,
      faq.answer
    ))).sort((a: RPassFAQ, b: RPassFAQ) => a.order - b.order);
    return faqList || null;
  }

  public async getRpassPolitics(config?: IRPassDataConfig): Promise<RPassPolitic> {
    const configRpass = config || await this.getRPassDataConfig();
    const politicsArray = configRpass.politics.sort((a: any, b: any) => b.version - a.version);
    return politicsArray[0] || null;
  }

  public async getUrlQrRpass(config?: IRPassDataConfig): Promise<RPassEnrollQR> {
    const configRpass = config || await this.getRPassDataConfig();
    const qrRpass = configRpass.qrRpass.sort((a: any, b: any) => b.version - a.version);
    return qrRpass[0] || null;
  }

  public async getStepsToActivateRpass(config?: IRPassDataConfig): Promise<RPassEnrollStep[]> {
    const configRpass = config || await this.getRPassDataConfig();
    const stepList = configRpass.steps.map((step: RPassEnrollStep) => (new RPassEnrollStep(
      step.order,
      step.step,
    ))).sort((a: RPassEnrollStep, b: RPassEnrollStep) => a.order - b.order);
    return stepList || null;
  }

  public async getUnrollRpassAlert(config?: IRPassDataConfig): Promise<RPassUnrollAlert> {
    const configRpass = config || await this.getRPassDataConfig();
    return configRpass.unrollAlert || null;
  }

  public async getInitiativesRpass(config?: IRPassDataConfig): Promise<RPassInitiative[]> {
    const configRpass = config || await this.getRPassDataConfig();
    return configRpass.initiatives || null;
  }

  public async getInitiativeRpass(featureKey: string): Promise<RPassInitiative> {
    const configRpass = await this.getRPassDataConfig();
    const initiative = configRpass.initiatives.find((initiative: RPassInitiative) => initiative.featureKey === featureKey);
    return initiative || null;
  }

  public async getInvalidRpassConfiguration(config?: IRPassDataConfig): Promise<InvalidRpassConfiguration> {
    const { invalidRpassConfiguration } = config || await this.getRPassDataConfig();
    if (!invalidRpassConfiguration) return null;
    return invalidRpassConfiguration;
  }
  //#endregion
  //#endregion

  public async getDataSwitchCampaing(): Promise<IDataSwitchCampaing> {
    const dataSwitchCampaing = await this.firestore.collection('appData').doc('switchCMS').get();
    return dataSwitchCampaing.data() as IDataSwitchCampaing;
  }

  //Get document of collection notification tokens acoustic (NTA) by client BR.
  public async getDocumentNTA({ dType, dNumber }): Promise<any> {
    return this.firestore.collection('notificationTokensAcoustic').doc(`${dType}-${dNumber}`);
  }


  // Set document of collection notification tokens acoustic (NTA) by client BR if don't exist, the create and if exist, the update with propery merge equal true.

  public async setDocumentNTA({ dType, dNumber }, userIdAcoustic: string, document: any, documentSnap: any): Promise<void> {
    if(documentSnap.exists) {
      return await document.update({
        updatedAt: this.getCurrentTimestamp(),
        userId: userIdAcoustic
      })
    } else {
      const newDocument = {
        documentType: dType,
        documentNumber: dNumber,
        userId: userIdAcoustic,
        createdAt: this.getCurrentTimestamp(),
        updatedAt: this.getCurrentTimestamp()
      };
      return await document.set(newDocument);
    }
  }


}
