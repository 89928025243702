export const RIPLEY_POINTS_CONSOLIDATED_MOCK = {
  success: [],
  failures: [],
};

export const RIPLEY_POINTS_DETAILS_MOCK = {
  success: [],
  failures: [],
};

export const RIPLEY_POINTS_SEGMENT_DIRECTION_MOCK = {
  success: [],
  failures: [],
};

export const RIPLEY_POINTS_PENDING_MOCK = {
  success: [],
  failures: [],
};
