import { Injectable } from '@angular/core';
import {
  IReschedulingQuestionsResponse,
  IReschedulingConsultRequest,
  IReschedulingSendOtpRequest,
  IReschedulingSendOtpResponse,
  IReschedulingSimulationRequest,
  IReschedulingSimulationResponse,
  IReschedulingValidateOtpRequest,
  IReschedulingValidateOtpResponse,
  IReschedulingConsultResponse,
  IReschedulingQuestionsRequest,
} from '@common/interfaces/rescheduling';
import {
  RESCHEDULING_INFO_MOCK,
  RESCHEDULING_QUESTIONS_MOCK,
  RESCHEDULING_SIMULATE_MOCK,
  SEND_OTP_MOCK,
  VALIDATE_OTP_MOCK,
 } from '@common/mocks/rescheduling.mocks';
import { environment } from '@environments/environment';
import { HttpService } from '@services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ReschedulingService {

  email: string;
  debtAmount: number;
  constructor(
    private http: HttpService
  ) { }

  public getReschedulingQuestions(request: IReschedulingQuestionsRequest): Promise<IReschedulingQuestionsResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/product/rescheduling/tc/questions-list';
    return this.http.post(url, request, RESCHEDULING_QUESTIONS_MOCK).toPromise();
  }

  public getReschedulingConsult(request: IReschedulingConsultRequest): Promise<IReschedulingConsultResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/product/rescheduling/tc/consult';
    return this.http.post(url, request, RESCHEDULING_INFO_MOCK).toPromise();
  }

  public simulateRescheduling(request: IReschedulingSimulationRequest): Promise<IReschedulingSimulationResponse> {
    this.email = request.email;
    this.debtAmount = request.debtAmount;
    const url = environment.BASE_BACKEND_WORKER + '/product/rescheduling/tc/simulate';
    return this.http.post(url, request, RESCHEDULING_SIMULATE_MOCK).toPromise();
  }

  public sendOTP(request: IReschedulingSendOtpRequest): Promise<IReschedulingSendOtpResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/product/rescheduling/tc/send-otp';
    return this.http.post(url, request, SEND_OTP_MOCK).toPromise();
  }

  public validateOTP(request: IReschedulingValidateOtpRequest): Promise<IReschedulingValidateOtpResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/product/rescheduling/tc/validate-otp-execute';
    return this.http.post(url, request, VALIDATE_OTP_MOCK).toPromise();
  }
}
