import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { WelcomeCampaignComponent } from './welcome-campaign.component';
import { CommonModule } from '@angular/common';
import { CardsWelcomepackPageModule } from './screens/cards-welcomepack/cards-welcomepack.module';
import { FlexPageModule } from './screens/flex/flex.module';
import { PromotionsWelcomepackPageModule } from './screens/promotions-welcomepack/promotions-welcomepack.module';
import { OfferModalPageModule } from './screens/offer-modal/offer-modal.module';

@NgModule({
  declarations: [
    WelcomeCampaignComponent,
  ],
  entryComponents: [
    WelcomeCampaignComponent,
  ],
  imports: [
    CardsWelcomepackPageModule,
    FlexPageModule,
    OfferModalPageModule,
    PromotionsWelcomepackPageModule,
    CommonModule
  ],
  exports: [
    WelcomeCampaignComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WelcomeCampaignModule {}
