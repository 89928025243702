import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss'],
})
export class BenefitsComponent implements OnInit {
  @Input() primaryButtonText: string;
  @Input() title: string;
  constructor(
    private viewController: ModalController,
  ) {
    this.primaryButtonText = 'Regresar';
    this.title = 'Beneficios del Efectivo Express';
  }

  ngOnInit() {}

  dismissModal() {
    this.viewController.dismiss();
  }

  onPrimaryClick() {
    this.viewController.dismiss('primaryButtonPressed');
  }
}
