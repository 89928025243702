import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonAF } from '@common/models/ButtonAF.model';


@Component({
  selector: 'app-generic-bottom-sheet-control',
  templateUrl: './generic-bottom-sheet-control.component.html',
  styleUrls: ['./generic-bottom-sheet-control.component.scss']
})
export class GenericBottomSheetControlComponent implements OnInit {
  @Input() public genericButtons: ButtonAF[];
  @Input() public isDisabled: boolean;
  @Input() public isMobile: boolean;
  @Input() public isSpinner: boolean;
  @Output() goTo: EventEmitter<string> = new EventEmitter();
  @Output() openModal: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onGoTo(path: string): void {
    this.goTo.emit(path);
  }

  public onOpenModal(): void {
    this.openModal.emit();
  }

}