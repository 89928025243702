import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cvv-helper-card',
  templateUrl: './cvv-helper-card.component.html',
  styleUrls: ['./cvv-helper-card.component.scss'],
})
export class CvvHelperCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
