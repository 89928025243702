import { Component, Input } from '@angular/core';
import { SCREEN_WIDTH } from '@common/constants/misc.constants';
@Component({
  selector: 'app-banner-campaign',
  templateUrl: './banner-campaign.component.html',
  styleUrls: ['./banner-campaign.component.scss'],
})
export class BannerCampaignComponent {

  @Input() strikingLabel: string;
  @Input() saleSubtitle: string;
  @Input() buttonLabel: string;
  @Input() showSaleBanner: boolean;
  @Input() tagManagerActionLabel: string;

  constructor() { }

  get isMobile() {
    return window.innerWidth < SCREEN_WIDTH.TABLET;
  }
}
