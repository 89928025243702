import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup,  Validators } from '@angular/forms';
import { CCI_ACCOUNT_NUMBER_MAX_LENGTH, CCI_ACCOUNT_NUMBER_MIN_LENGTH, NUMBER_PATTERN_OTHER_BANK, PEN_SEGMENT, USD_SEGMENT } from '@common/constants/transfers.constants';
import { IFinancialInstitution } from '@common/interfaces/transfers.interface';
import { ModalController } from '@ionic/angular';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { TransferService } from '@services/transfer/transfer.service';
import { UtilsService } from '@services/utils/utils';

@Component({
  selector: 'app-step-one-info-account-ob',
  templateUrl: './step-one-info-account-ob.component.html',
  styleUrls: ['./step-one-info-account-ob.component.scss'],
})
export class StepOneInfoAccountObComponent implements OnInit {

  accountNumberLength: number = 20;
  isValidAccount: boolean; 
  isKeyEnterDown: boolean;
  financialInstitutionCode: string;
  patternAccount: RegExp;
  utils = UtilsService;
  accountForm: FormGroup;
  errorModal: HTMLIonModalElement;  
  @Output() setDestinationAccount = new EventEmitter<{cci:string,valid:boolean}>();

  constructor(
    private modalErrorCtrl: ModalController,
    private formBuilder: FormBuilder, 
    private transferService: TransferService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    
    this.initializeVar();
 }


 initializeVar(){   
  this.isValidAccount = true;
  this.financialInstitutionCode = ''; 
  this.patternAccount = NUMBER_PATTERN_OTHER_BANK;   

 }

  async ngOnInit() {
    this.createContactForm();  
    console.log("Form");
  } 

  isValidCciAccount():boolean{
    this.isValidAccount =  (!(this.accountLengthError 
      && !this.destinationAccount.hasError('pattern')) 
      && !this.destinationAccount.hasError('pattern')
      && !this.sameFinancialInstitution);
    return this.isValidAccount;
  }

   get accountLengthError(): boolean {   
    return this.destinationAccount.dirty &&
      (this.destinationAccount.hasError('minlength') || this.destinationAccount.hasError('maxlength'));
  }

  get accountFinancialInstitutionError(): boolean {  
    if (!this.destinationAccount.value || this.accountLengthError) { return false; }
    return this.destinationAccount.dirty && (this.financialInstitution===null);
  }

  get sameFinancialInstitution(): boolean {
      return this.destinationAccount.value  && this.destinationAccount.value.toString().substring(0, 3) === '055';
  }

  get financialInstitution() { return this.accountForm.controls.financialInstitution; }
  
  get destinationAccount() { return this.accountForm.controls.destinationAccount; }
  set destinationAccountSet(value:any) { this.accountForm.controls.destinationAccount.value(value); }

  get destinationAccountNumberMinLength(): number {
    return CCI_ACCOUNT_NUMBER_MIN_LENGTH;
  }
  get destinationAccountNumberMaxLength(): number {
    return CCI_ACCOUNT_NUMBER_MAX_LENGTH;
  }

  private async setFinancialInstitution(){
    try {   
      
      console.log(this.destinationAccount);
      if (this.destinationAccount.value.lenght === this.accountNumberLength) {
       
        const code = (this.destinationAccount.value as string).slice(0, 3);
        if (this.financialInstitutionCode === code) { return; }
        this.financialInstitutionCode = code;        
        const financialInstitution: IFinancialInstitution = await this.transferService.getFinancialInstitution(this.financialInstitutionCode);
        this.financialInstitution.setValue(financialInstitution.name);   
      }         
    } catch (err) {
      this.financialInstitution.setValue(null); 
      //this.openTransferGenericFailModal(); 
      throw new Error("cci inválido");      
    }
  }

  public minMaxAccountLength(): string {
    if (this.destinationAccountNumberMaxLength === this.destinationAccountNumberMinLength) {
      return this.destinationAccountNumberMaxLength.toString();
    } else {
      return `entre ${this.destinationAccountNumberMinLength} y ${this.destinationAccountNumberMaxLength}`;
    }
  }

  private createContactForm() {
    this.accountForm = this.formBuilder.group({
      destinationAccount: [null, [
        Validators.required,
        Validators.minLength(this.destinationAccountNumberMinLength),
        Validators.maxLength(this.destinationAccountNumberMaxLength),
        Validators.pattern(this.patternAccount),
      ]],
      financialInstitution: [{ value: '', disabled: true }],
    });

    this.destinationAccount.setValue("");
    this.subscribeTodestinationAccount();
   
  }

  private subscribeTodestinationAccount() {
    console.log("Subscribe");
    this.destinationAccount.valueChanges.subscribe((cciValue: string) => {   
      console.log("hola",cciValue);
      this.isValidCciAccount();
      this.setDestinationAccount.emit({cci:cciValue
        ,valid:this.isValidAccount});   
    });
    //this.setFinancialInstitution();
  }
 
  public dismissModal() {
    this.modalErrorCtrl.dismiss();
  }

  @HostListener('keydown.enter', ['$event']) onEnter(e) {  
    this.isKeyEnterDown = true;
    console.log("Key down enter");
  }
  @HostListener('click', ['$event']) onClick(e) {  
    this.isKeyEnterDown = false;
    console.log("Key down enter");
  }
  
}
