import { ISegmentSelector } from '@common/interfaces/default.interface';
import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { ISelectSearch } from '@common/interfaces/control.interface';

@Component({
  selector: 'app-select-search-control',
  templateUrl: './select-search-control.component.html',
  styleUrls: ['./select-search-control.component.scss'],
})
export class SelectSearchComponent {

  selectSearchForm: FormGroup;
 // dataListCopy: ISelectSearch[];
 valueChangesEvent: Subscription;
  dataListAux: ISelectSearch[];
  isSearchTextChange:boolean;  
  isFirsData: boolean;
  isNoResult:boolean;
  @Input() inputMessage: string;
  @Input() dataList: ISelectSearch[]; 
  @Input() isVisibleData: boolean;
  @Input() placeHolder:string;
  @Output() setIsVisibleData: EventEmitter<boolean>;
  @Output() selectedItem: EventEmitter<ISelectSearch>;
  @Output() clickEvent: EventEmitter<any>;

  constructor( private formBuilder: FormBuilder,) {
    this.initializeVar();
    this.InitializeControlForm();
  }


  get dataListIni() {return this.dataList;}
  get selectControlSearch() { return this.selectSearchForm.controls.selectSearch; }
  set selectControlSearch(value) { this.selectSearchForm.controls.selectSearch.setValue(value) }
  get dataListCopy() {
    if(this.dataList!==undefined && this.dataListAux.length==0 && this.selectControlSearch.value==null){  
      this.dataListAux = this.dataList;
      if(this.isFirsData){
        this.selectControlSearch.setValue(this.dataList[0].shortName);        
      }

    }
    return this.dataListAux;
  }

  InitializeControlForm(){

   /* this.selectSearch = new FormGroup({
      selectSearch: new FormControl()
   });*/
    this.selectSearchForm = this.formBuilder.group({
      selectSearch: [null, [
        Validators.required,
      ]]   
    });

    this.subscribeToChangeDestinationAccount();
    
  }
  
  initializeVar(){
    this.selectedItem = new EventEmitter();
    this.setIsVisibleData = new EventEmitter();
    this.isSearchTextChange = false;
    this.dataListAux=[];
    this.isFirsData = true;
    this.isNoResult = false;
  }

  private UnSuscribeToChangeDestinationAccount(){
    this.valueChangesEvent.unsubscribe();
  }
  private subscribeToChangeDestinationAccount(){
   this.valueChangesEvent =  this.selectSearchForm.valueChanges.subscribe((result) => {
     
      if(!this.isFirsData){
        this.dataListAux = [];     
        this.dataListAux = this.dataList.filter((item)=> item.shortName.toLowerCase().includes(result.selectSearch.toLowerCase()));
        this.isNoResult = (this.dataListAux.length>0)?false:true; 
        this.isSearchTextChange = true;
        this.isVisibleData = true;
        this.setIsVisibleData.emit(true);
        this.selectedItem.emit(null);
      }else{
        this.isVisibleData = false;
        this.isFirsData = false;
        let item = {
          code: this.dataList[0].code,
          name: this.dataList[0].name,
          shortName: this.dataList[0].shortName
        }
        this.selectedItem.emit(item);
      }     
    });       
  }

  itemSelected(item: ISelectSearch){
    this.selectControlSearch.setValue(item.shortName);  
    let index = this.dataList.indexOf(item);    
      this.dataList.splice(index,1);
      this.dataList = this.dataList.reverse();
      this.dataList.push(item);
      this.dataList = this.dataList.reverse(); 
    this.isSearchTextChange=false;
    this.isVisibleData = false;
    this.setIsVisibleData.emit(false);
    this.selectedItem.emit(item);
  }

  iconListSelected(){      
    if(!this.isNoResult){      
      let result = this.dataList.find((element)=> (element.shortName == this.selectControlSearch.value));     
      this.dataListAux = (result==undefined)?this.dataListAux:this.dataList;
    }   
    this.isSearchTextChange= this.isSearchTextChange?false:true;
    this.isVisibleData = this.isVisibleData?false:true;
    this.setIsVisibleData.emit(this.isVisibleData);
   
  }

  @HostListener('click', ['$event']) onClick(e) {   
    if(e!==undefined) {   
      /*if(e.target.localName.toLowerCase()!='input' && e.target.localName.toLowerCase()!='ion-icon'){
        console.log("click event");
        this.isVisibleData=false;
      }*/
    }}

}
