import { Component, Input, ViewChild, Output, EventEmitter, OnInit, ElementRef } from '@angular/core';
import {
  COMPANY_NAME,
  CREDIT_CARD,
  DEFAULT_PROMOTION_IMAGE,
  RIPLEY_MASTERCARD_EXTENDED_NAME,
} from '@common/constants/welcomepack.constants';
import { IPromotion } from '@common/interfaces/promotion.interface';
import { FirebaseService } from '@services/firebase/firebase.service';
import { PromotionService } from '@services/promotion/promotion.service';
import { UtilsService } from '@services/utils/utils';

@Component({
  selector: 'page-promotions-welcomepack',
  templateUrl: 'promotions-welcomepack.html',
  styleUrls: ['./promotions-welcomepack.scss'],
})
export class PromotionsWelcomepackPage implements  OnInit {
  public static readonly pageName = 'PromotionsWelcomepackPage';
  @Input() public user: any;
  @Input() public finalScreen: boolean;
  @Input() public screen: any;
  @Input() public triggerAction: boolean;
  @Output() public goalCompleted: EventEmitter<boolean>;
  @Output() public goTo: EventEmitter<any>;
  @Output() public exit: EventEmitter<any>;
  @ViewChild('promotionSlides') public slides: ElementRef;
  public campaign: any;
  public promotions: any[];
  public defaultPromotions: any[];
  public nextButtonText: string;
  public activeIndex: number;
  public slideOptions: any;

  constructor(
    private firebase: FirebaseService,
    private promotionService: PromotionService,
    public utils: UtilsService,
  ) {
    this.goalCompleted = new EventEmitter();
    this.goTo = new EventEmitter();
    this.exit = new EventEmitter();
    this.activeIndex = 0;
    this.slideOptions = { allowTouchMove: true, zoom: { toggle: false } };
  }

  get showFixedButton() {
    return !!this.screen.button.show && !!this.screen.button.fixed;
  }

  public async ngOnInit() {
    if (this.shouldShowTRPromotions()) {
      this.promotions = await this.firebase.getWelcomePackPromotions(this.screen.promotionsForTR);
      this.assignPromotionsToUser(this.promotions);
    } else if (this.shouldShowTRMPromotions()) {
      this.promotions = await this.firebase.getWelcomePackPromotions(this.screen.promotionsForTRM);
      this.assignPromotionsToUser(this.promotions);
    } else {
      this.promotions = await this.firebase.getWelcomePackPromotions(this.screen.normalBenefits);
    }
    this.defaultPromotions = await this.firebase.getWelcomePackPromotions(this.screen.defaultBenefits);
  }

  public assignPromotionsToUser(promotions: any[]): void {
    const userId: string = UtilsService.formatUserId(this.user.dNumber, this.user.dType);
    this.promotionService.assignPromotionsToUser(promotions, userId);
  }

  public getCardsMessage() {
    const message = this.screen.mainTitle.text;
    let cards = ' <b>';
    if (this.user.newAccounts.length > 0) {
      cards += ((this.user.newAccounts[0].type === CREDIT_CARD) && UtilsService.isClosedMastercard(this.user.newAccounts[0].subProductCode))
        ? COMPANY_NAME : RIPLEY_MASTERCARD_EXTENDED_NAME;
    }
    cards += '!</b>';
    return message + cards;
  }

  public shouldShowTRPromotions() {
    const isClosedMastercard = UtilsService.isClosedMastercard(this.user.userAccounts.subProductCode);
    return this.user.userAccounts.hasCreditCard && this.screen.exclusiveBenefits.forTR && isClosedMastercard;
  }

  public shouldShowTRMPromotions() {
    const isClosedMastercard = UtilsService.isClosedMastercard(this.user.userAccounts.subProductCode);
    return this.user.userAccounts.hasCreditCard && this.screen.exclusiveBenefits.forTRM && !isClosedMastercard;
  }

  public getListTitle(promotion: IPromotion): string {
    return promotion.details.currentSale;
  }

  public isSlideActive(index: number) {
    return index === this.activeIndex;
  }

  public async onChangeSlide() {
    this.activeIndex = await this.slides.nativeElement.getActiveIndex();
  }

  public getPromotionImage(promotion: IPromotion) {
    return promotion.details.image ? promotion.details.image : DEFAULT_PROMOTION_IMAGE;
  }

  public getPromotionMiniImage(promotion: IPromotion) {
    return promotion.list.image ? promotion.list.image : DEFAULT_PROMOTION_IMAGE;
  }

  public triggerGoTo(page, params) {
    if (page === 'promotion-detail' && !this.screen.redirectBenefits) {
      return;
    }
    this.goTo.emit({page, params});
  }

  public triggerExit() {
    this.exit.emit();
  }
}
