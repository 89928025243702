import {
  IPromotion,
  IPromotionType,
  IExtendCreditLineDetails,
  IOtpValidateLineIncrement,
  IInformativeModal,
  ICurrentCompany
} from '@common/interfaces/promotion.interface';
import { FirebaseService } from '@services/firebase/firebase.service';
import {
  EXTEND_CREDIT_LINE_CREATION_MOCK,
  EXTEND_LINE_TERMS_AND_CONDITIONS,
  INFORMATIVE_MODALS_MOCK,
  EXTEND_CREDIT_LINE_DETAILS_MOCK
} from '@common/mocks/promotion.mocks';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpService } from '@services/http/http.service';
import { IQuery } from '@common/interfaces/query-firebase.interface';
import { IGenericNameValueResponse } from '@common/interfaces/default.interface';
import { DEFAULT_ADDRESS, DEFAULT_VICINITY } from '@common/constants/maps.constants';
import { LocationService } from '@services/location/location.service';
import { IServiceConfig } from '@common/interfaces/config.interface';
import { Coordinates } from '@ionic-native/geolocation/ngx';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  serviceConfig: IServiceConfig;

  constructor(
    private http: HttpService,
    private firebaseService: FirebaseService,
    private locationService: LocationService,
  ) { }

  public async getExtendCreditLineDetails(): Promise<IExtendCreditLineDetails> {
    const url = environment.BASE_BACKEND_WORKER + '/hbk/promotion/il/detail';
    return this.http.get(url, EXTEND_CREDIT_LINE_DETAILS_MOCK).toPromise();
  }

  public async createCreditLineExtension(extendCreditLine: IOtpValidateLineIncrement): Promise<any> {
    const url = environment.BASE_BACKEND_WORKER + '/hbk/promotion/incrementlinehb/validateOTPlineIncrement';
    return this.http.post(url, extendCreditLine, EXTEND_CREDIT_LINE_CREATION_MOCK).toPromise();
  }

  public async getExtensionTermsAndConditions(): Promise<IGenericNameValueResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/TCIL';
    return this.http.get(url, EXTEND_LINE_TERMS_AND_CONDITIONS).toPromise();
  }

  public async getExtensionAccountAuthorizationConditions(): Promise<IGenericNameValueResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/TCIL';
    return this.http.get(url, EXTEND_LINE_TERMS_AND_CONDITIONS).toPromise();
  }

  public async getActivePromotions(): Promise<IPromotion[]> {
    const today = new Date();
    const collection = this.firebaseService.getCollection('promotions');
    const promotions = [];
    const argument: IQuery[] = [
      { param: 'active', queryType: '==', value: true },
      { param: 'expirationDate', queryType: '>=', value: today },
      { param: 'isGlobal', queryType: '==', value: true },
    ];
    let promotionsSnapshot;
    try {
      promotionsSnapshot = await this.firebaseService.getDocumentsQuery(collection, argument);
    } catch (error) { return; }
    promotionsSnapshot.docs.forEach(doc => {
      const promotion = doc.data();
      if (promotion.promotionType) {
        promotion.promotionType = promotion.promotionType.id;
        promotion.id = doc.id;
        promotions.push(promotion);
      }
    });
    return promotions;
  }

  public async getPersonalPromotions(userId: string): Promise<IPromotion[]> {
    const personalPromotionsIds = await this.getPersonalPromotionsIds(userId);
    const promotions = await Promise.all(
      personalPromotionsIds.map((id) => this.getPromotion(id))
    );
    return promotions.filter((promotion) => promotion);
  }

  public async getPersonalPromotionsIds(userId: string): Promise<string[]> {
    const promotionsIds = [];
    const promotionDnisColl = this.firebaseService.getCollection('promotionDnis');
    const promotionDnisArgument: IQuery[] = [
      { param: 'userId', queryType: '==', value: userId },
    ];
    let promotionsDnisSnapshot;
    try {
      promotionsDnisSnapshot = await this.firebaseService.getDocumentsQuery(promotionDnisColl, promotionDnisArgument);
    } catch (error) { return; }
    promotionsDnisSnapshot.docs.forEach(doc => {
      const promotionId = doc.data().promotionId;
      promotionsIds.push(promotionId);
    });
    return promotionsIds;
  }

  public async getPromotion(id: string): Promise<IPromotion> {
    const today = new Date();
    const collection = this.firebaseService.getCollection('promotions');
    const doc = await this.firebaseService.getDocument(collection, id);
    const promotion = doc.data();
    if (promotion.promotionType && promotion.active && promotion.expirationDate.toDate() >= today) {
      promotion.promotionType = promotion.promotionType.id;
      promotion.id = doc.id;
      return promotion as IPromotion;
    }
  }

  public async getPromotionsType(): Promise<IPromotionType> {
    const collection = this.firebaseService.getCollection('promotionsType');
    const promotionsType = {};
    const promotionsSnapshot = await this.firebaseService.getAllDocuments(collection);
    promotionsSnapshot.docs.forEach(doc => {
      promotionsType[doc.id] = {
        ...doc.data(),
        promotions: [],
      };
    });
    return promotionsType;
  }

  public async getInformativeModals(): Promise<IInformativeModal[]> {
    const url = environment.BASE_BACKEND_WORKER + '/modaltemplate/getmodaltemplates';
    return this.http.get(url, INFORMATIVE_MODALS_MOCK).toPromise();
  }

  public checkPromotionSubscription(userRut: string, benefitId: string): Promise<boolean> {
    return this.firebaseService.checkPromotionSubscription(userRut, benefitId);
  }

  public addPromotionSubscription(userRut: string, benefitId: string): Promise<boolean> {
    return this.firebaseService.addPromotionSubscription(userRut, benefitId);
  }

  public async setServiceConfig() {
    this.serviceConfig = await this.firebaseService.getConfig('promotions');
  }

  public loadCompanyInfo(data, company: ICurrentCompany, currentPosition: Coordinates, isRegional: boolean) {
    if (isRegional) {
      company.name = data.list.title || 'Sin nombre';
      company.position = {
        latitude: Number(data.location.lat),
        longitude: Number(data.location.lng),
      } as Coordinates;
      company.distance = this.locationService.getCurrentDistance(company.position, currentPosition).distance;
      company.address = data.location.address || 'Sin dirección';

    } else {
      company.name = data.name || 'Sin nombre';
      if (data.vicinity) {
        if (DEFAULT_ADDRESS.indexOf(data.vicinity) > -1) {
          company.address = DEFAULT_VICINITY;
        } else {
          company.address = data.vicinity;
        }
      } else {
        company.address = 'Sin dirección';
      }
      company.position = {
        latitude: data.geometry.location.lat(),
        longitude: data.geometry.location.lng(),
      } as Coordinates;
      company.distance = this.locationService.getCurrentDistance(company.position, currentPosition).distance;
    }
  }

  public assignPromotionsToUser(promotions: any[], userId: string): void {
    promotions.forEach(promotion => {
      this.firebaseService.setPromotionDni(userId, promotion.id);
    });
  }
}
