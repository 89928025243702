import { DEFAULT_REQUEST_TIMEOUT, SCREEN_WIDTH } from '@common/constants/misc.constants';
import { IConsolidatedRewardPoints, IRipleySegmentDirection } from '@common/interfaces/reward-points.interface';
import { Component, OnInit, HostListener } from '@angular/core';
import { RewardPointsService } from '@services/reward-points/reward-points.service';
import { RIPLEY_POINTS_DASHBOARD_ROUTE, RIPLEY_POINTS_ROUTE ,SITE_RIPLEY_PUNTOS_GO} from '@common/constants/routes.constants';
import { CUSTOMER_TYPE_SEGMENT_RIPLEY } from '@common/constants/reward-points.constants';
import { HOME_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-reward-points-footer',
  templateUrl: './reward-points-footer.component.html',
  styleUrls: ['./reward-points-footer.component.scss'],
})
export class RewardPointsFooterComponent implements OnInit {
  public segmentDirection: IRipleySegmentDirection;
  public showPoints = false;
  public ripleyPoints: IConsolidatedRewardPoints[];
  public customerType: string;
  public customerTypeLogo: string;
  constructor(
    private rewardPointsService: RewardPointsService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.customerType = CUSTOMER_TYPE_SEGMENT_RIPLEY;
    this.customerTypeLogo = `logo-ripleypoints-${this.customerType}`;
    this.ripleyPoints = [];
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.loadRipleyPoints();
    this.getCategoryDescription();
  }

  ngOnInit() {
    if (window.innerWidth <= SCREEN_WIDTH.MOBILE) {
      this.loadRipleyPoints();
      this.getCategoryDescription();
    }
  }

  public get obtainAvailableRipleyPoints(): number {
    if (this.ripleyPoints && this.ripleyPoints[0] && this.ripleyPoints[0].availablePoints) {
      return this.ripleyPoints[0].availablePoints;
    }
    return 0;
  }

//   public  ripleyPointsRoute() { 
//     // alert('asdasd')
//     // return `/${RIPLEY_POINTS_ROUTE}`;
//     // return   window.open(SITE_RIPLEY_PUNTOS_GO, '_blank')
//     window.open(SITE_RIPLEY_PUNTOS_GO, '_blank')
//  }

public get ripleyPointsRoute() { return `/${RIPLEY_POINTS_DASHBOARD_ROUTE}`; }

  private async loadRipleyPoints() {
    try {
      if (!this.showPoints) {
        this.ripleyPoints = await this.rewardPointsService.getConsolidatedPoints();
        if (this.ripleyPoints[0] && this.ripleyPoints[0].availablePoints) {
          this.showPoints = true;
          return;
        }
        this.showPoints = false;
      }
    } catch (err) {
      this.showPoints = false;
    }
  }

  gtmRipleyPoint() {
    this.googleAnalyticsService.gtagPushEventWithId(HOME_GA4_CONSTANTS.HOME_RIPLEY_POINT.EVENT,
      {
        path_destino: HOME_GA4_CONSTANTS.HOME_RIPLEY_POINT.PATH_DESTINO,
        product: HOME_GA4_CONSTANTS.HOME_RIPLEY_POINT.PRODUCT,
        action: HOME_GA4_CONSTANTS.HOME_RIPLEY_POINT.ACTION,
        category: HOME_GA4_CONSTANTS.HOME_RIPLEY_POINT.CATEGORY,
        sub_category: HOME_GA4_CONSTANTS.HOME_RIPLEY_POINT.SUB_CATEGORY[1]
      })
  }

  private async getCategoryDescription(): Promise<void> {
    try {
      const response = await this.rewardPointsService.getSegmentDirection();
      if (response && (response as any).responseCode === 'X1000') {
        this.customerType = CUSTOMER_TYPE_SEGMENT_RIPLEY;
      } else {
        this.segmentDirection = response;
      }
    } catch (_err) {
      this.segmentDirection = null;
      this.customerType = CUSTOMER_TYPE_SEGMENT_RIPLEY;
      setTimeout(this.getCategoryDescription, DEFAULT_REQUEST_TIMEOUT);
    }
    if (this.segmentDirection) {
      this.customerType =
        this.segmentDirection['retrieveSegmentStrategyCustomerResponse']['customer']['type']['description'].toString().toLowerCase();
      this.customerTypeLogo = `logo-ripleypoints-${this.customerType}`;
    }
  }
}
