export const SHOW_NOT_ENROLLED_USER_MODAL_PROP = {
  title: '¡Ups! No estás registrado a los canales digitales',
  message: 'Regístrate y sé parte de la transformación digital de Banco Ripley.',
  primaryButtonText: 'Regístrate',
  secondaryButtonText: 'Ir a bancoripley.com.pe',
  icon: 'i-alert-purple'
};

export const SHOW_PASSWORD_CHANGED_MODAL_PROP = {
  title: '¡Listo! Cambiaste tu clave de ingreso',
  message: 'Ahora podrás entrar con tu nueva clave.',
  primaryButtonText: 'Ingresar',
  secondaryButtonText: 'Ir a bancoripley.com.pe',
  icon: 'i-confirm-purple'
};

export const ERROR_UPDATE_DATA = {
  title: '¿No es tu número?',
  message: '¡No te preocupes! Actualiza tu celular en las Agencias de Banco Ripley a nivel nacional.',
  primaryButtonText: 'Llamar ahora',
  secondaryButtonText: 'Cerrar',
  icon: 'without-icon'
};

export const ERROR_CODE_DATA = {
  title: '¿No llega tu código?',
  message: '¡No te preocupes! Puedes realizar  tu canje llamando al RipleyFono (01)611-5757 opción 6, o acércate a un de nuestras agencias Banco Ripley a nivel nacional.',
  primaryButtonText: 'Llamar ahora',
  secondaryButtonText: 'Cerrar',
  icon: 'without-icon'
};

export const ERROR_NO_PRODUCT_DATA = {
  title: 'No hay productos',
  message: 'Aún no se encuentran productos disponibles para esta categoría, favor intenta con otra categoría'
};

export const ERROR_NO_POINTS_DATA = {
  title: 'No hay puntos',
  message: 'Aún no se obtienen tus puntos disponibles, favor intenta con otra categoría'
};

export const ERROR_NO_POINTS_DATA_CUSTOMER = {
  title: 'Atención',
  message: 'Aún no se obtienen tus puntos disponibles, para mayor información, por favor contáctate al número (01) 611-5757 o acércate a cualquier agencia de Banco Ripley a nivel nacional.'
};
