import { ITransactionInfo } from "@common/interfaces/digital-auth.interface";
import { TRANSFER_INTEROP_TO_NUMBER_ROUTE, TRANSFER_OLD_ROUTE, TRANSFER_ROUTE, TRANSFERS_OTHER_BANK_ROUTE } from "./routes.constants";
import { TRANSFER_ERROR_ACCOUNT_CURRENCY, TRANSFER_ERROR_ACCOUNT_DESTINATION
  , TRANSFER_ERROR_INACTIVEACCOUNT_DESTINATION
  , TRANSFER_ERROR_INSUFFICIENT_BALANCE
  , TRANSFER_ERROR_INSUFFICIENT_BALANCE_ITF, 
  TRANSFER_ERROR_INVALID_PHONE_NUMBER, 
  TRANSFER_ERROR_NO_BANK_PHONE_NUMBER, 
  TRANSFER_ORIGIN_ERROR_ACCOUNT} from "./modal-props.constants";

export const TRANSFER_FLOW = 'transfer'
export const INTEROP_SUCCESS = '0'
export const INTEROP_VALIDATE_SUCCESS = '00'
export const TRANSFER_SUCCESS = '00';
export const WIDGET_BUTTON_PRESS_SMS = 'SMS';
export const WIDGET_BUTTON_PRESS_RPASS = 'RPASS';
export const WIDGET_BUTTON_CHANGE_OPTION = 'CAMBIAR MÉTODO';
export const PEN_BASE_CURRENCY = 'PEN';
export const USD_BASE_CURRENCY = 'USD';
export const CTS_CODE = 'CTS';
export const MANCOMUNO_CODE = 'Mancomuno';
export const OPERATION_PLATFORM_MOBILE = 'Aplicación';
export const OPERATION_PLATFORM_WEB = 'Plataforma Web';
export const OPERATION_PLATFORM_MOBILE_CODE = 'APP';
export const OPERATION_PLATFORM_WEB_CODE = 'WEB';
export const IMMEDIATE_TRANSFER_CODE = 'WTM08';
export const INTEROP_TRANSFER_CODE = 'TRC09';
export const RPASS_TRANSFER_CODE = 'RPA07';

export const TRANSFER_SERVICE_INFO = {
  GET_DIRECTORY_IOP: 'getDirectoryIop',
  GET_WIDGET_TOKEN_IOP: 'getWidgetTokenIop',
  VALIDATE_TOKEN: 'validateTokenIop'
}

export const PEN_SEGMENT = {
  label: 'Soles',
  label_lc: 'soles',
  symbol: 'S/',
  value: PEN_BASE_CURRENCY,
};

export const USD_SEGMENT = {
  label: 'Dólares',
  label_lc: 'dólares',
  symbol: '$',
  value: USD_BASE_CURRENCY,
};

export const PEN_CODE_CURRENCY = '001';
export const USD_CODE_CURRENCY = '002';
export const CTS_ERROR_TRANSFER_CODE = 723;
export const EXCHANGE_RATE = 3.35;
export const TRANSFER_MIN_AMOUNT = 1;
export const POSITIVE_DECIMAL_NUMBER_PATTERN = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
export const NUMBER_PATTERN_FOR_RIPLEY_ACCOUNT = /^[0-9]*/; //Comienza en 100 o 101
export const NUMBER_PATTERN_OTHER_BANK = /^[0-9]*/;
export const TRANSFER_PROGRESS_ADDITION = 0.5;
export const TRANSFER_PROGRESS_INTEROP_ADDITION = 0.33;
export const TOTAL_STEP_INTEROP = 3;
export const DEFERRED_TRANSFER = "Transferencia diferida"
export const IMMEDIATE_TRANSFER = "Transferencia inmediata"
export const TRANSFER_OWN_ACCOUNT = "A una cuenta propia"
export const TRANSFER_OTHER_ACCOUNT = "A la cuenta de otra persona"
export const TRANSFER_BETWEEN_MY_ACCOUNTS = 'Transferencia entre mis cuentas';
export const TRANSFER_THIRD_PARTIES = 'Transferencia a otra cuenta Banco Ripley';
export const TRANSFER_OTHER_BANKS = 'Transferencia a cuenta de otro banco';
export const TRANSFER_BETWEEN_MY_ACCOUNTS_G4 = 'entre mis cuentas';
export const TRANSFER_THIRD_PARTIES_G4 = 'cuenta banco ripley';
export const TRANSFER_OTHER_BANKS_G4 = 'interbancaria';
export const TRANSFER_BETWEEN_MY_ACCOUNTS_CODE = 'MYACCOUNTS';
export const TRANSFER_THIRD_PARTIES_CODE = 'THIRDACCOUNTS';
export const TRANSFER_OTHER_BANKS_CODE = 'OTHERBANKS';
export const TITLE_TRANSFER_BETWEEN_MY_ACCOUNTS = 'entre mis cuentas Banco Ripley';
export const TITLE_TRANSFER_ANOTHER_RIPLEY_ACCOUNT= 'a otra cuenta Banco Ripley';
export const TITLE_TRANSFER_ANOTHER_BANK = 'a cuenta de otro banco';
export const SUBTITLE_TRANSFER_BETWEEN_MY_ACCOUNTS = '';
export const SUBTITLE_TRANSFER_ANOTHER_RIPLEY_ACCOUNT= 'Ten en cuenta que tu operación puede estar afecta al ITF';
export const SUBTITLE_TRANSFER_ANOTHER_BANK = 'Ten en cuenta que tu operación puede estar afecta al ITF';
export const INPUT_LABEL_TRANSFER_BETWEEN_MY_ACCOUNTS = 'Cuenta de destino';
export const INPUT_LABEL_TRANSFER_ANOTHER_RIPLEY_ACCOUNT= 'Nº cuenta de destino';
export const INPUT_LABEL_TRANSFER_ANOTHER_BANK = 'Código de cuenta interbancaria (CCI)';
export const ACCOUNT_NUMBER_MIN_LENGTH = 10;
export const ACCOUNT_NUMBER_MAX_LENGTH = 10;
export const CCI_ACCOUNT_NUMBER_MIN_LENGTH = 20;
export const CCI_ACCOUNT_NUMBER_MAX_LENGTH = 20;
export const OTP_CODE_LENGTH = 6;
export const PHONE_NUMBER_LENGTH = 9;
export const PHONE_NUMBER_AFFILIATION_EXIST_TITLE = 'Confirma tu número de teléfono';
export const PHONE_NUMBER_AFFILIATION_NOT_EXIST_TITLE = 'Ingresa un nuevo número';
export const PHONE_NUMBER_AFFILIATION_SEND_OTP_TITLE = 'Confirma con el código SMS';
export const PHONE_NUMBER_AFFILIATION_EXIST_SUBTITLE = 'Este será el número que usarás para enviar y recibir dinero';
export const PHONE_NUMBER_AFFILIATION_NOT_EXIST_SUBTITLE = 'Este número solo quedará registrado para que realices transferencias a un contacto';
export const MAX_TRANSFER_AMOUNT_PEN = 9000;
export const MAX_TRANSFER_AMOUNT_USD = 2600;
export const MAX_TRANSFER_IMMEDIATE_AMOUNT_PEN = 30000;
export const MAX_TRANSFER_IMMEDIATE_AMOUNT_USD = 10000;
export const MAX_TRANSFER_INTEROP_AMOUNT_PEN = 500;
export const TRANSFER_MAX_AMOUNT_CODE_PEN = 'WEBMAPEN';
export const TRANSFER_MAX_AMOUNT_CODE_USD = 'WEBMAUSD';
export const TRANSFER_MAX_AMOUNT_CODE_APP_PEN = 'APPMAPEN';
export const TRANSFER_MAX_AMOUNT_CODE_APP_USD = 'APPMAUSD';
export const TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_PEN = 'WEBTINMAPEN';
export const TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_USD = 'WEBTINMAUSD';
export const TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_APP_PEN = 'APPTINMAPEN';
export const TRANSFER_MAX_AMOUNT_IMMEDIATE_CODE_APP_USD = 'WEBTINMAUSD';
export const TRANSFER_MAX_AMOUNT_INTEROP_CODE_APP_PEN = 'APPINTOPMAPEN';
export const MAX_AMOUNT_ABSOLUTE = 9999999999.99;
export const TRANSFER_ACTIVATION_CODE = '5';
export const LABEL_FORM_DESCRIPTION = 'descripcion';
export const LABEL_FORM_AMOUNT = 'amount';
export const LABEL_FORM_ORIGIN_ACCOUNT = 'originAccount';
export const LABEL_FORM_DESTINATION_ACCOUNT = 'destinationAccount';
export const LABEL_FORM_OWN_ACCOUNT = 'ownAccount';
export const LABEL_FORM_EMAIL = 'email';
export const LABEL_FORM_DOC_NUMBER_BENEFICIARY = 'documentNumberBeneficiary';
export const LABEL_FORM_DOC_LAST_NAME_BENEFICIARY = 'beneficiaryLastName';
export const LABEL_FORM_DOC_NAME_BENEFICIARY = 'beneficiaryName';
export const ELEMENT_WIDGET_LOADING = 'loading'
export const ELEMENT_WIDGET_OK = 'widget';
export const ELEMENT_WIDGET_AUTHORIZED = 'authorized';
export const ELEMENT_WIDGET_REJECTED = 'rejected';
export const ELEMENT_WIDGET_FAILED = 'failed';
export const VALIDATION_WIDGET_ERROR = 'error';
export const TRANSFER_OPTIONS_ROUTE = 'transfer';

export const TRANSACTION_INFO: ITransactionInfo = {
  operation: 'TRANSFERENCIAS',
  transactionType: 'TRANSFERENCIAS',
  transactionName: 'TRANSFERENCIAS',
  subTypeTransaction: 'TRANSFERENCIAS',
};

export const TRANSFER_OLD_OPTIONS = [
  {
    id:1,
    title: 'Cuenta de otro banco',
    label: 'A un tercero',
    labelTag: 'a cuenta de otro banco',    
    route: TRANSFER_OLD_ROUTE,
    enabled: true,
    tagManagerActionLabel: TRANSFER_OTHER_BANKS,
    tagG4: TRANSFER_OTHER_BANKS_G4
  },
  {
    id:2,
    title: 'Entre mis cuentas',
    label: 'Banco Ripley',
    labelTag: 'entre mis cuentas',
    route: TRANSFER_OLD_ROUTE,
    enabled: true,
    tagManagerActionLabel: TRANSFER_BETWEEN_MY_ACCOUNTS,
    tagG4: TRANSFER_BETWEEN_MY_ACCOUNTS_G4
  },
  {
    id:3,
    title: 'A otra cuenta',
    label: 'Banco Ripley',
    labelTag: 'a otra cuenta banco ripley',
    route: TRANSFER_OLD_ROUTE,
    enabled: true,
    tagManagerActionLabel: TRANSFER_THIRD_PARTIES,
    tagG4: TRANSFER_THIRD_PARTIES_G4
  }
  
];

export const TRANSFER_OPTIONS = [
  {
    id:1,
    icon:'i-build-purple',
    title: 'Cuenta de otro banco',
    label: 'A un tercero',   
    labelTag: 'a cuenta de otro banco',
    route: TRANSFERS_OTHER_BANK_ROUTE,
    enabled: true,
    tagManagerActionLabel: TRANSFER_OTHER_BANKS,
    tagG4: TRANSFER_OTHER_BANKS_G4
  },
  {
    id:2,
    title: 'Entre mis cuentas',
    label: 'Banco Ripley',
    labelTag: 'entre mis cuentas',
    route: TRANSFER_ROUTE,
    enabled: true,
    tagManagerActionLabel: TRANSFER_BETWEEN_MY_ACCOUNTS,
    tagG4: TRANSFER_BETWEEN_MY_ACCOUNTS_G4
  },
  {
    id:3,
    title: 'A otra cuenta',
    label: 'Banco Ripley',
    labelTag: 'a otra cuenta banco ripley',
    route: TRANSFER_ROUTE,
    enabled: true,
    tagManagerActionLabel: TRANSFER_THIRD_PARTIES,
    tagG4: TRANSFER_THIRD_PARTIES_G4
  },
  {
    id:4,
    title: 'A un contacto',
    label: 'Telefónico',
    labelTag: 'a un contacto telefonico',
    route: TRANSFER_INTEROP_TO_NUMBER_ROUTE,
    enabled: true,
    tagManagerActionLabel: TRANSFER_THIRD_PARTIES,
    tagG4: TRANSFER_THIRD_PARTIES_G4
  }
  
  
];

export const TRANSFER_OTHER_BANK_OPTIONS = [
  {
    id:1,
    title: 'A una cuenta propia',
    value: true,
    tagManagerActionLabel: TRANSFER_OWN_ACCOUNT,
  },
  {
    id:2,
    title: 'A la cuenta de otra persona',
    value: true,
    tagManagerActionLabel: TRANSFER_OTHER_ACCOUNT,
  }  
];

export const TRANSFER_DOCUMENT_TYPE = [
  {
    title: 'DNI',
    value: 'C',
    tagManagerActionLabel: 'DNI',
  },
  {
    title: 'Carné de extranjería',
    value: '2',
    tagManagerActionLabel: 'Carné de extranjería',
  }  
];

export const TRANSFER_OTHER_BANKS_TITLE = [
  {
    title:"Cuenta de destino"
  },
  {
    title:"Ingresa un monto"
  },
  {
    title: "Autorización"
  } 
]

//Interoperabilidad

export const TRANSFER_INTEROP_TITLE = [
  {
    title:"Tus contactos"
  },
  {
    title:"Ingresar datos"
  },
  {
    title: "Autorización"
  } 
]

export const STATUS_CODE_GET_INFO_NUMBER = [
  {
    CODE: '751',
    PROPMODAL: TRANSFER_ERROR_NO_BANK_PHONE_NUMBER
  },
  {
    CODE: '700',
    PROPMODAL: TRANSFER_ERROR_NO_BANK_PHONE_NUMBER
  }
]

export const STATUS_CODE = [
  {
    CODE: '736',
    PROPMODAL: TRANSFER_ERROR_INSUFFICIENT_BALANCE
  },
  {
    CODE: '737',
    PROPMODAL: TRANSFER_ERROR_INSUFFICIENT_BALANCE_ITF
  },
  {
    CODE: '716',
    PROPMODAL: TRANSFER_ERROR_INACTIVEACCOUNT_DESTINATION
  },
  {
    CODE: '706',
    PROPMODAL: TRANSFER_ERROR_ACCOUNT_DESTINATION
  },
  {
    CODE: '712',
    PROPMODAL: TRANSFER_ERROR_ACCOUNT_DESTINATION
  },
  {
    CODE: '718',
    PROPMODAL: TRANSFER_ERROR_ACCOUNT_CURRENCY
  },
  {
    CODE: '740',
    PROPMODAL: TRANSFER_ORIGIN_ERROR_ACCOUNT
  }

]

export const AUTHORIZATION_CODE = [
  {
    CODE: 'SMS',
    DESCRIPTION: 'SMS'
  },
  {
    CODE: 'PASS',
    DESCRIPTION: 'RPASS'
  },
  {
    CODE: 'REENVIAR',
    DESCRIPTION: 'REENVIAR'
  },
  {
    CODE: 'ACTIVAR',
    DESCRIPTION: 'ACTIVAR'
  },
  {
    CODE: 'OLVID',
    DESCRIPTION: 'OLVID'
  },
  {
    CODE: 'RECHAZAR',
    DESCRIPTION: 'RECHAZAR'
  },
]

// GA4 CONSTANTS

export const TRANSFER_GA4_CONSTANTS ={
  TRANSFER_OPTIONS_PGE: {
    event: 'virtual_page',
    TAG: {      
      action: 'pageview',
      step: 'step seleccion tipo transferencia',
      category: 'transferencias'
    }
  }, 
  TRANSFER_OPTION_SELECTED_PGE: {
    event: 'virtual_page',
    TAG: {      
      action: 'pageview',
      step: 'step realizar transferencia',
      category: 'transferencias',
      sub_category: ''
    },
    TAG1: {      
      action: 'pageview',
      step: 'step ingresar cuenta destino',
      category: 'transferencias',
      sub_category: ''
    }
  }, 
  TRANSFER_INSERT_DATA_PGE: {
    event: 'virtual_page',
    TAG: {      
      action: 'pageview',
      step: 'step ingresar datos',
      category: 'transferencias',
      tipo_transferencia: 'diferida',
      sub_category: ''
    },
    TAG1: {      
      action: 'pageview',
      step: 'step ingresar datos',
      tipo_transferencia: 'inmediata',
      category: 'transferencias',
      sub_category: ''
    }
  }, 
  TRANSFER_MODAL_TRANSFER_TYPE_PGE: {
    event: 'virtual_page',
    TAG: {      
      action: 'pageview',
      step: 'step tipo de transferencia',
      category: 'transferencias',
      sub_category: ''
    }    
  }, 
  TRANSFER_OPTION_SELECTED_OLD_PGE: {
    event: 'virtual_page',
    TAG: {      
      action: 'pageview',
      step: 'step realizar transferencia',
      category: 'transferencias',
      sub_category: ''
    }
  }, 
  TRANSFER_MODAL_SMS_PGE: {
    event: 'virtual_page',
    TAG: {      
      action: 'pageview',
      step: 'step confirmar transferencia',
      category: 'transferencias',
      sub_category: ''
    },
    TAG1: {      
      action: 'pageview',
      step: 'step confirmar transferencia',
      category: 'transferencias',
      sub_category: '',
      tipo_trasnferencia: 'diferida', 
      destination_bank: ''
    }
  },   
  VOUCHER_OPTION_SELECTED_OLD_PGE: {
    event: 'end_flow',
    TAG0: {      
      action: 'pageview',
      step: 'step transferencia exitosa',
      category: 'transferencias',
      sub_category: ''
    },
    TAG1: {      
      action: 'pageview',
      step: 'step transferencia exitosa',
      category: 'transferencias',
      sub_category: '',
      destination_bank: ''
    }
  }, 
  VOUCHER_OPTION_SELECTED_PGE: {
    event: 'end_flow',
    TAG0: {      
      action: 'pageview',
      step: 'step transferencia exitosa',
      category: 'transferencias',
      sub_category: '',
      metodo_autorizacion: ''
    },
    TAG1: {      
      action: 'pageview',
      step: 'step transferencia exitosa',
      category: 'transferencias',
      sub_category: '',
      metodo_autorizacion: '', 
      destination_bank: ''
    }
  }, 
  TRANSFER_OPTION_ACCOUNT_BTN: {
    event: 'btn_click',
    TAG:{     
      action: 'click',
      label: 'entre mis cuentas',
      step: 'step seleccion tipo transferencia',
      sub_category: 'entre mis cuentas',
      category: 'transferencias'
    }
  },
  TRANSFER_MODAL_TRANSFER_TYPE_BTN: {
    event: 'btn_click',
    TAG:{     
      action: 'click',
      label: 'continuar',
      step: 'step tipo de transferencia',
      sub_category: '',
      tipo_transferencia: '',
      category: 'transferencias'
    } 
  }, 
  CONTINUE_TRANSFER_ACC_THIRD_BTN: {
    event: 'btn_click',
    TAG:{     
      action: 'click',
      label: 'continuar',
      step: 'step ingresar monto',
      sub_category: '',
      category: 'transferencias'
    }   
  },
  CONTINUE_TRANSFER_ACC_BTN: {
    event: 'btn_click',
    TAG:{     
      action: 'click',
      label: 'continuar',
      step: 'step realizar transferencia',
      sub_category: '',
      category: 'transferencias'
    }   
  },
  CONTINUE_TRANSFER_STEP_ACC_BTN: {
    event: 'btn_click',   
    TAG:{     
      action: 'click',
      label: 'continuar',
      step: 'step ingresar cuenta destino',
      sub_category: '',
      category: 'transferencias'
    }
  },
  CONTINUE_TRANSFER_STEP_DATA_ACC_BTN: {
    event: 'btn_click',   
    TAG:{     
      action: 'click',
      label: 'continuar',
      step: 'step ingresar monto',
      sub_category: '',
      category: 'transferencias',
      tipo_transferencia: ''
    },
    TAG1:{     
      action: 'click',
      label: 'continuar',
      step: 'step ingresar monto',
      sub_category: '',
      category: 'transferencias',
      tipo_transferencia: '',
      destination_bank: ''
    }
  },
  CONTINUE_TRANSFER_STEP_DATA_AMOUNT_THIRD_PGE: {      
    TAG:{     
      event: 'virtual_page', 
      action: 'pageview',
      step: 'step ingresar monto',
      sub_category: '',
      category: 'transferencias'
    },
    TAG1:{
      event: 'btn_click', 
      action: 'click',
      label: 'continuar',
      step: 'step ingresar monto',
      sub_category: '',
      category: 'transferencias'
    },  
  },
  CONTINUE_TRANSFER_STEP_DATA_THIRD_PGE: {      
    TAG:{     
      event: 'virtual_page', 
      action: 'pageview',
      step: 'step ingresar datos titular',
      sub_category: '',
      category: 'transferencias'
    },
    TAG1:{
      event: 'btn_click', 
      action: 'click',
      label: 'continuar',
      step: 'step ingresar datos titular',
      sub_category: '',
      category: 'transferencias'
    },  
  },
  CONTINUE_TRANSFER_STEP_DATA_DIF_AMMOUNT_PGE: {      
    TAG:{     
      event: 'virtual_page', 
      action: 'pageview',
      step: 'step ingresar monto',
      sub_category: '',
      category: 'transferencias',
      tipo_transferencia: '',
    },
    TAG1:{
      event: 'btn_click', 
      action: 'click',
      label: 'continuar',
      step: '',
      sub_category: '',
      category: 'transferencias',
      tipo_transferencia: '',
      destination_bank: ''
    }   
  },
  CONTINUE_TRANSFER_STEP_DATA_DIF_OWN_ACCOUNT_PGE: {      
    TAG:{     
      event: 'virtual_page', 
      action: 'pageview',
      step: 'step ingresar datos titular',
      sub_category: '',
      category: 'transferencias',
      tipo_transferencia: '',
    },
    TAG1:{
      event: 'btn_click', 
      action: 'click',
      label: 'continuar',
      step: 'step ingresar datos titular',
      sub_category: '',
      category: 'transferencias',
      tipo_transferencia: '',
      destination_bank: ''
    },
    TAG2:{
      event: 'btn_click', 
      action: 'click',
      label: 'continuar',
      step: 'step ingresar cuenta destino',
      sub_category: '',
      category: 'transferencias',
    }
  },
  CONTINUE_TRANSFER_STEP_DATA_DIF_PGE: {
    event: 'btn_click',   
    TAG:{     
      action: 'pageview',
      step: 'step selección tipo de cuenta',
      sub_category: '',
      category: 'transferencias',
      tipo_transferencia: '',
    },
    TAG1:{     
      action: 'pageview',
      step: 'step selección tipo de cuenta',
      sub_category: '',
      category: 'transferencias',
      tipo_transferencia: '',
      destination_bank: ''
    }
  },
  CONTINUE_TRANSFER_STEP_DATA_DIF_OPTION_BTN: {
    event: 'btn_click',   
    TAG:{     
      action: 'click',
      label: 'continuar',
      step: 'step selección tipo de cuenta',
      sub_category: '',
      category: 'transferencias',
      tipo_transferencia: '',
    },
    TAG1:{     
      action: 'click',
      label: 'continuar',
      step: 'step selección tipo de cuenta',
      sub_category: '',
      category: 'transferencias',
      tipo_transferencia: '',
      destination_bank: ''
    }
  },
  CONTINUE_TRANSFER_OLD_ACC_BTN: {
    event: 'btn_click',
    TAG:{     
      action: 'click',
      label: 'continuar',
      step: 'step realizar transferencia',      
      sub_category: '',
      category: 'transferencias'
    }, 
    TAG1: {      
      action: 'click',
      label: 'continuar',
      step: 'step realizar transferencia',
      category: 'transferencias',
      sub_category: '',
      tipo_trasnferencia: 'diferida',
      destination_bank: ''
    }
  },
  TRANFER_MODAL_SMS_BTN: {
    event: 'btn_click',
    TAG:{     
      action: 'click',
      label: 'transferir',
      step: 'step confirmar transferencia',
      sub_category: '',
      category: 'transferencias'
    },
    TAG1: {      
      action: 'click',
      label: 'continuar',
      step: 'step realizar transferencia',
      category: 'transferencias',
      sub_category: '',
      tipo_trasnferencia: 'diferida',
      destination_bank: ''
    }
  }
}

export const RPASS_SMS_GA4_CONSTANTS = {
  RPASS_BTN: {
    event: 'btn_click',
    TAG:{     
      action: 'click',
      label: 'rpass',
      step: 'step seleccion metodo de autorizacion',
      sub_category: '',
      category: 'transferencias',
      metodo_autorizacion: 'rpass'
    }
  },
  SMS_BTN: {
    event: 'btn_click',
    TAG:{     
      action: 'click',
      label: 'codigo sms',
      step: 'step seleccion metodo de autorizacion',
      sub_category: '',
      category: 'transferencias',
      metodo_autorizacion: 'sms'
    }
  },
  RPASS_ACTIVE_BTN: {
    event: 'btn_click',
    TAG:{     
      action: 'click',
      label: 'activar rpass',
      step: 'step seleccion metodo de autorizacion',
      sub_category: '',
      category: 'transferencias'
    }
  },
  FORGOT_RPASS_BTN: {
    event: 'btn_click',
    TAG:{     
      action: 'click',
      label: 'olvide mi rpass',
      step: 'step autorizar',
      sub_category: '',
      category: 'transferencias'
    }
  },
  REFUSE_RPASS_BTN: {
    event: 'btn_click',
    TAG:{     
      action: 'click',
      label: 'rechazar',
      step: 'step autorizar',
      sub_category: '',
      category: 'transferencias',
      metodo_autorizacion: 'rpass',
    }
  },
  AUTHORIZE_RPASS_BTN: {
    event: 'btn_click',
    TAG:{     
      action: 'click',
      label: 'autorizar',
      step: 'step autorizar',
      sub_category: '',
      category: 'transferencias',
      metodo_autorizacion: 'rpass',
    }
  },
  RESEND_SMS_BTN: {
    event: 'btn_click',
    TAG:{     
      action: 'click',
      label: 'reenviar codigo',
      step: 'step autorizar',
      sub_category: '',
      category: 'transferencias',
      metodo_autorizacion: 'sms',
    }
  },  
  AUTHORIZE_OPTIONS_PGE: {
    event: 'virtual_page',
    TAG: {      
      action: 'pageview',
      step: 'step seleccion metodo de autorizacion',
      sub_category:'',
      category: 'transferencias'
    }  
  }, 
  RPASS_METHOD_PGE: {
    event: 'virtual_page',
    product: 'login R Pass',
    action: 'pageview',
    step: 'step activa tu rpass',
    step_PART: 'step ingreso cuenta',
    category: 'rpass'
  },
  SMS_METHOD_PGE: {
    event: 'virtual_page',
    product: 'login R Pass',
    action: 'pageview',
    step: 'step activa tu rpass',
    step_PART: 'step ingreso cuenta',
    category: 'rpass'
  },
}