import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CutOffDisclaimerComponent } from './cut-off-disclaimer/cut-off-disclaimer.component';
import { ComponentsModule } from '@components/components.module';
import { BottomSheetComponent } from './bottom-sheet/bottom-sheet.component';
import { NewGlobalHeaderComponent } from './new-global-header/new-global-header.component';
import { CardPaymentListComponent } from './card-payment-list/card-payment-list.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '@pipes/pipes.module';

@NgModule({
  declarations: [
    CutOffDisclaimerComponent,
    BottomSheetComponent,
    NewGlobalHeaderComponent,
    CardPaymentListComponent
  ],
  imports: [
    ComponentsModule,
    CommonModule,
    IonicModule.forRoot(),
    FormsModule,
    PipesModule
  ],
  exports:[
    CutOffDisclaimerComponent,
    BottomSheetComponent,
    NewGlobalHeaderComponent,
    CardPaymentListComponent
  ],
  entryComponents: [
    BottomSheetComponent,
    NewGlobalHeaderComponent,
    CardPaymentListComponent
  ]
})
export class PayRipleyModule { }
