import { Injectable } from '@angular/core';
import { INotificationConfigurationElementEdition, INotificationEnabled } from '@common/interfaces/notifications.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NotificationsServiceMock {

  public hasNewNotifications: BehaviorSubject<boolean>;
  public notificationList: BehaviorSubject<any[]>;

  constructor() {
    this.hasNewNotifications = new BehaviorSubject(false);
    this.notificationList = new BehaviorSubject([]);
  }

  public get isBrowserNotificationDefault(): boolean {
    return false;
  }
  public get isBrowserNotificationsSupported(): boolean {
    return false;
  }
  public get isNative(): boolean {
    return false;
  }
  public initializePWANotifications(): Promise<void> {
    return Promise.resolve();
  }
  public async removeNotificationToken(_dType: string, _dNumber: string): Promise<void> {
    return Promise.resolve();
  }
  public removeNotificationSubscription() {
    return;
  }
  public listenToTappedNotifications() {
    return;
  }

  public async requestPermission(_dType: string, _dNumber: string) {
    return Promise.resolve();
  }

  public async fetchUserNotificationConfiguration() {
    return Promise.resolve({
      version: '',
      data: []
    });
  }

  public async fetchUserNotificationSettings(_dType: string, _dNumber: string) {
    const res: INotificationEnabled = {
        version: '',
        notificationEnable: true,
    };
    return Promise.resolve(res);
  }

  public async updateUserNotificationConfiguration(_notificationConfigs:
    INotificationConfigurationElementEdition[]): Promise<object> {
    return Promise.resolve([]);
  }

  public async fetchNewNotifications() {
    return Promise.resolve();
  }

  public deleteNotification() {
    return;
  }
  public markNotificationAsReadById(_notificationId: string) {
    return;
  }

  public markNotificationAsRead() {
    return;
  }
  public onTokenRefresh() {
    return;
  }

  public hasPermission() {
    return;
  }

  public storeNotificationTokenRPass() {
    return Promise.resolve();
  }

}
