import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ITransferOTPRequest,
  ITransferRequest,
  ITransferResendOTPRequest,
  ITransferResponse,
  ITransferOTPResponse
} from '@common/interfaces/transfers.interface';
import { IUserInformation } from '@common/interfaces/user.interface';
import { IVoucherPage } from '@common/interfaces/voucher.interface';
import { MILLISECONDS_TO_SECOND } from '@common/constants/misc.constants';
import { ModalController } from '@ionic/angular';
import {
  OTP_CODE_LENGTH,
  PHONE_NUMBER_AFFILIATION_EXIST_SUBTITLE,
  PHONE_NUMBER_AFFILIATION_EXIST_TITLE,
  PHONE_NUMBER_AFFILIATION_NOT_EXIST_SUBTITLE,
  PHONE_NUMBER_AFFILIATION_NOT_EXIST_TITLE,
  PHONE_NUMBER_AFFILIATION_SEND_OTP_TITLE,
  PHONE_NUMBER_LENGTH,
  TRANSFER_BETWEEN_MY_ACCOUNTS,
  TRANSFER_GA4_CONSTANTS,
  TRANSFER_OTHER_BANKS,
  TRANSFER_PROGRESS_ADDITION,
  TRANSFER_THIRD_PARTIES
} from '@common/constants/transfers.constants';
import { OTP_MAX_TIME } from '@common/constants/enrollment.constants';
import { Router } from '@angular/router';
import { TRANSFER_ROUTE, VOUCHER_ROUTE } from '@common/constants/routes.constants';
import { TransferService } from '@services/transfer/transfer.service';
import { UserService } from '@services/user/user.service';
import { TransferErrorModalComponent } from '@components/transfer-error-modal/transfer-error-modal.component';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { EFEX_FAILED_MODAL_PROPS, TRANFER_CTS_FAILED_MODAL_PROPS, TRANSFER_ERROR_GENERAL, TRANSFER_ERROR_INSUFFICIENT_BALANCE, TRANSFER_ERROR_INSUFFICIENT_BALANCE_ITF } from '@common/constants/modal-props.constants';
import { UtilsService } from '@services/utils/utils';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { IDetailsSavingAccount } from '@common/interfaces/savings.interface';
import { IDataLoaded } from '@common/interfaces/default.interface';
import { SavingsService } from '@services/savings/savings.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-send-otp',
  templateUrl: './send-otp.component.html',
  styleUrls: ['./send-otp.component.scss'],
})
export class SendOtpComponent implements OnInit {
  
  @Input() sessionId: string;
  @Input() phoneNumber: string;
  @Output() stepOneSuccess = new EventEmitter<boolean>(); 

  showTimer: boolean;
  sendingOtp: boolean; 
  isVisible: boolean; 
  isMyNumber: boolean;
  isSubStepOneValid: boolean;
  isSubStepTwoValid: boolean;
  maxTime: number;
  subStep:number;
  phoneNumberLength:number;
  otpCodeLength:number;
  subtitle: string;
  userInformation: IUserInformation;
  savingAccountsInformation: IDetailsSavingAccount[];
  userDataLoaded: IDataLoaded;
  timer: NodeJS.Timeout;
  phoneNumberForm: FormGroup;
  

  constructor(
    private modalController: ModalController,
    private modalErrorCtrl: ModalController,
    private transferService: TransferService,
    private savingsService: SavingsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private utilsService: UtilsService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    
      this.subStep = 0;
      this.isMyNumber = true;
      this.isVisible = true;
      this.showTimer = true;
      this.sendingOtp = false;
      this.isSubStepOneValid = false;
      this.isSubStepTwoValid = false;
      this.otpCodeLength = OTP_CODE_LENGTH;
      this.phoneNumberLength = PHONE_NUMBER_LENGTH;
      this.maxTime = OTP_MAX_TIME;
      this.savingAccountsInformation = [];
      this.userDataLoaded = {
        isLoaded: false,
        hasErrors: false,
      };
      
 }

 public get percentageProgress(): number { return TRANSFER_PROGRESS_ADDITION; }
 get otpCode() { return this.phoneNumberForm.controls.otpCode; }
 get contactNumber() { return this.phoneNumberForm.controls.contactNumber;}
 set contactNumberSet(value) {  this.phoneNumberForm.controls.contactNumber.setValue(value); }
 
 get tittle(){
  if(this.isMyNumber && this.subStep == 0){
    return PHONE_NUMBER_AFFILIATION_EXIST_TITLE;
  }else if(!this.isMyNumber && this.subStep == 0){
    return PHONE_NUMBER_AFFILIATION_NOT_EXIST_TITLE;
  }else if(this.subStep == 1){
    return PHONE_NUMBER_AFFILIATION_SEND_OTP_TITLE;
  }
 }
 get subTittle(){
  if(this.isMyNumber && this.subStep == 0){
    return PHONE_NUMBER_AFFILIATION_EXIST_SUBTITLE;
  }else if(!this.isMyNumber && this.subStep == 0){
    return PHONE_NUMBER_AFFILIATION_NOT_EXIST_SUBTITLE;
  }else if(this.subStep == 1){
    return '';
  }
 }

 get isValidSubStepOne(){
    if(this.contactNumber.value !== '' || this,this.contactNumber !== undefined){
      return true;
    }
 }
 get isValidSubStepTwo(){
     if (this.otpCode.value !=='' && this.otpCode.value.lenght == 6){
       return true;
     }
 }

  async ngOnInit() {
    this.createContactForm();
    Promise.all([ 
      this.getUserInformation(),
      this.getAccountDetails()])
   
  }

  ionViewWillEnter() {
    UtilsService.showRecaptchaBadge();
  }

  ionViewWillLeave() {
    UtilsService.hideRecaptchaBadge();
  }

  private createContactForm() {
    this.phoneNumberForm = this.formBuilder.group({    
      contactNumber: [
        { value: null},
        [Validators.required, Validators.minLength(this.phoneNumberLength), Validators.maxLength(this.phoneNumberLength)]
      ],
      otpCode: [
        { value: null, disabled: false},
        [Validators.required, Validators.minLength(this.otpCodeLength), Validators.maxLength(this.otpCodeLength)]
      ]
    });
  }

  async nextSubStep(){
    
    //
   if(this.validateSubStepOne()){
      this.subStep+=1;
      this.isVisible = false;
    }else if(this.isMyNumber && this.validateSubStepTwo()){   
      this.stepOneSuccess.emit(true);
    }
       
  }

  validateSubStepOne(){
    this.isSubStepOneValid = this.isValidSubStepOne;
    return this.isSubStepOneValid;
  }
  validateSubStepTwo(){
    this.isSubStepTwoValid = this.isValidSubStepTwo;
    return this.isSubStepTwoValid;
  }


/*private get otpValidation(): ITransferOTPRequest {
    return {
     sessionId: this.sessionId,
     PIN: this.otpCode.value
   };
 }*/
 private get otpResend(): ITransferResendOTPRequest {
   return { sessionId: this.sessionId };
 }

 notMyNumber(){
  this.isMyNumber = false;
  this.subStep-=1;
  this.isSubStepOneValid = false;
 }

 private async getAccountDetails() {
  try {
    this.savingAccountsInformation = await this.savingsService.getDetailPassivesCtsSaving();
    this.savingAccountsInformation = this.savingAccountsInformation.filter(item => !item.hasRestrictions);
    
  } catch (err) {
    this.userDataLoaded.hasErrors = true;
    //this.openTransferGenericFailModal();
  }
  this.userDataLoaded.isLoaded = true;
}
 private async getUserInformation(){

  try {
    this.userInformation =  await this.userService.getUserInformation();   
    this.contactNumberSet(this.userInformation.mobileNumber);
   
    //this.email.setValue('');
  } catch (error) {  
    this.userDataLoaded.hasErrors = true;
    //this.openTransferGenericFailModal();    
  }
  
}
 private setOtpTimer() {
   clearTimeout(this.timer);
   this.timer = setTimeout(() => {
     if (this.maxTime <= 0) {
       this.showTimer = false;
     }
     this.maxTime -= 1;
     if (this.maxTime > 0) {
       this.showTimer = true;
       this.setOtpTimer();
     } else {
       this.showTimer = false;
     }
   }, MILLISECONDS_TO_SECOND);
 }

 public async sendOTP(resend: boolean = false) {
   this.sendingOtp = true;
   try {
     if (!resend || !this.sessionId) {
       const otpResponse: ITransferOTPResponse = await this.sendOtpCall();
       if (Number(otpResponse.code) === 1) {
         this.sessionId = otpResponse.sessionId;
         this.phoneNumber = otpResponse.phoneNumber;
       }
     } else {
      await this.resendOtpCall();
     }
     this.maxTime = OTP_MAX_TIME;
     this.showTimer = true;
   } catch (err) {
     let message = null;
     const alertMessage = null;
     //await this.openTransferFailedModal(message);    
   } finally{
    this.setOtpTimer();
    this.sendingOtp = false;
   }
 }

 private async sendOtpCall() {
    return null; //await this.transferService.sendOTPThirdParties(this.transferFormValue);
 }

 private async resendOtpCall() {
    return null; // await this.transferService.resendOTPThirdParties(this.otpResend);
 }

 confirmNumber(){
 // console.log("confirmar número");
 }

 public prettifyMaskedPhoneNumber(maskedPhoneNumber: string = ''): string {
   return maskedPhoneNumber && maskedPhoneNumber.substr(2).replace(/(.{3})(.{3})(.{3})/gi, '$1 $2 $3');
 }
 /* async getDevicePhoneContacts(){   
      const projection = {
        // Specify which fields should be retrieved.
        name: true,
        phones: true,
        postalAddresses: false,
      };
    
      const result = await Contacts.getContacts({
        projection,
      });
      
      this.contacts = result.contacts;
   
  }*/
}
