import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-simple-tooltip',
  templateUrl: './simple-tooltip.component.html',
  styleUrls: ['./simple-tooltip.component.scss'],
})
export class SimpleTooltipComponent implements OnInit {

  @Input() text: string;
  @Input() classType: string;

  constructor() { }

  ngOnInit() {}

}
