import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {

  @Input() termsHighlight: string;
  @Input() termsExample: string;
  @Input() termsDescription: string;
  @Input() conditions: string;

  constructor() { }

  ngOnInit() {}

}
