import { Observable, Subscription } from 'rxjs';

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ALREADY_ENROLLED_PROPS, CONTACT_WITH_CALL_CENTER_MODAL_PROPS, NOT_RIPLEY_USER_PROPS
} from '@common/constants/modal-props.constants';
import {
  ENROLL_CHECK_FOR_CARD, ENROLL_FORGOT_PASS, ENROLL_GO_RIPLEY, ENROLL_LOGIN
} from '@common/constants/tag/tag-enrollment.constants';
import { ModalController } from '@ionic/angular';
import { UtilsService } from '@services/utils/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { ERROR_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() message: string;
  @Input() primaryButtonText: string;
  @Input() secondaryButtonText: string;
  @Input() icon: string;
  @Input() dynamicSource: Observable<string>;
  @Input() extraClass?: string;
  @Input() showCloseButton: boolean;
  @Input() dataString: string;
  @Input() flow: string;
  @ViewChild('secondButton') secondButton: ElementRef;
  subscription: Subscription;
  fadeOut: boolean;
  isActiveModal: boolean;
  hide: boolean;
  fromRoute;
  constructor(
    private viewController: ModalController,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.title =  '';
    this.message = '';
    this.primaryButtonText = '';
    this.secondaryButtonText = '';
    this.icon = this.icon ? this.icon : 'i-smartphone-purple';
    this.showCloseButton = true;
    this.fromRoute = this.route.snapshot.queryParams;
  }

  ngAfterViewInit(): void {
    if(this.router.url.split('?')[0] === '/login') {
      if(this.secondButton) {
        this.secondButton.nativeElement.hidden = true
      }
    }
  }

  ngOnInit() {
    if (this.flow) {
      this.googleAnalyticsService.gtagPushEventWithId(ERROR_GA4_CONSTANTS.GENERIC_MODAL.EVENT, { 
        title: !this.title ? ERROR_GA4_CONSTANTS.GENERIC_MODAL.TITLE : this.title,
        message: !this.message ? ERROR_GA4_CONSTANTS.GENERIC_MODAL.MESSAGE : this.message, 
        error: !this.dataString ? ERROR_GA4_CONSTANTS.GENERIC_MODAL.ERROR : this.dataString, 
        flow: this.flow,
      });      
    }

    this.subscription = new Subscription();
    if (this.dynamicSource) {
      this.subscription.add(
        this.dynamicSource.subscribe((message) => {
          this.message = message;
        })
      );
    }
  }

  dismissModal() {
    this.viewController.dismiss();
    const second = 1000;
    this.fadeOut = true;
    setTimeout(() => {
      this.hide = true;
    }, second);
    this.isActiveModal = false;
  }

  onPrimaryClick() {
    this.tag();
    this.viewController.dismiss('primaryButtonPressed');
  }

  onSecondaryClick() {
    this.viewController.dismiss('secondaryButtonPressed');
  }

  tag() {
      switch (this.primaryButtonText) {
        case CONTACT_WITH_CALL_CENTER_MODAL_PROPS.primaryButtonText:
          const informAditional: any[] = [];
          informAditional[0] = ENROLL_GO_RIPLEY.info1;
          informAditional[1] = ENROLL_GO_RIPLEY.info2;
          break;
        case NOT_RIPLEY_USER_PROPS.primaryButtonText:
          break;
        case ALREADY_ENROLLED_PROPS.primaryButtonText:
          break;
      }
  }
  tagSecond() {
      switch (this.secondaryButtonText) {
        case ALREADY_ENROLLED_PROPS.secondaryButtonText:
          break;
      }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
