import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SCREEN_WIDTH } from '@common/constants/misc.constants';
import { PopoverController } from '@ionic/angular';
import { DisabledVirtualCardPopoverComponent } from './disabled-virtual-card-popover/disabled-virtual-card-popover.component';

@Component({
  selector: 'app-card-details-header',
  templateUrl: './card-details-header.component.html',
  styleUrls: ['./card-details-header.component.scss'],
})
export class CardDetailsHeaderComponent {

  documentlength: number;
  @Input() headerLabel: string;
  @Input() availableAmount: string;
  @Input() availableLabel: string;
  @Input() withProgressBar: boolean;
  @Input() isDataStateLoaded: boolean;
  @Input() cardNumber: string;
  @Input() usedAmount: string;
  @Input() totalAmount: string;
  @Input() progressBarValue: number;
  @Input() iconClass: string;
  @Input() isNotAditional: boolean;
  @Input() aditionalUser: string;
  @Input() aditionalUserDni: string;
  @Output() accountInfoEvent = new EventEmitter<string>();
  @Input() disabledButtonVirtualCard: boolean;
  @Input() showButtonVirtualCard: boolean = false;
  @Output() clickButtonVirtualCard = new EventEmitter<string>();

  constructor(
    public popoverController: PopoverController
  ) { }

  ngOnInit() {
    this.documentlength = (this.aditionalUserDni == null)?this.aditionalUserDni.length:8;
  }

  async presentPopover(ev: Event) {
    const popover = await this.popoverController.create({
      component: DisabledVirtualCardPopoverComponent,
      cssClass: 'pop-over-style',
      event: ev,
      mode: 'ios',
      showBackdrop: true
    });
    await popover.present();
    await popover.onDidDismiss();
  }

  callParent(): void {
    this.accountInfoEvent.next('openSelect');
  }
  
  callShowVirtualCard($event: Event): void {
    if (this.disabledButtonVirtualCard) {
      this.presentPopover($event)
    } else {
      this.clickButtonVirtualCard.next('clickButtonVirtualCard');
    }
  }

  get isMobile() {
    return window.innerWidth < SCREEN_WIDTH.TABLET;
  }
}
